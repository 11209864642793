import {Column} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {GetUser, MeID, Me} from "../Store/firebase/users";
import {Observer, PageContainer} from "web-vcore";
import {JournalUI} from "./Journal/JournalUI";

export const columnWidths = [.64, .06, .12, .18];

@Observer
export class JournalsUI extends BaseComponent<{}, {}> {
	render() {
		const user = Me();
		if (user == null) return <PageContainer>You must be signed in to access this page.</PageContainer>;

		return (
			<>
				<JournalUI user={user}/>
			</>
		);
	}
}