import {O} from "web-vcore";
import {ignore} from "mobx-sync";
import {TestResult} from "../../UI/@Shared/Processors/TestSegmentRunner";
import {makeObservable} from "mobx";

export enum TestingTab {
	general = "general",
	session = "session",
	eyeMoveDetection = "eyeMoveDetection",
}

export class TestingState {
	constructor() { makeObservable(this); }
	
	@O expanded = false;
	@O tab = TestingTab.general;

	// general tab
	// ==========

	@O errorMessage = "Custom error message from testing-panel.";

	// session tab
	// ==========

	// eye-move detection tab
	// ==========

	@O selectedUser: string;
	@O xRange = 30;
	@O yRange = 15;
	//@O normalize = true;
	//@O smooth = true;
	@O falseDetectionPenalty = .25;
	@O showDetections = true;

	@O @ignore runTests = false;
	//@O testResults = [] as TestResult[];
	@O @ignore averageTestScore: number;
}