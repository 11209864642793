import {Column, Switch} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {Observer, SubNavBar, SubNavBarButton} from "web-vcore";
import {HasAdminPermissions} from "../Store/firebase/users/$user";
import {store} from "../Store/index.js";
import {MeID, GetUsers} from "../Store/firebase/users";
import {AdminUI} from "./More/Admin";
import {LinksUI} from "./More/Links";
import {TestsUI} from "./More/Tests";
import {SignInHelper} from "./More/SignInHelper";
import {DownloadUI} from "./More/Download";

@Observer
export class MoreUI extends BaseComponentPlus({}, {}) {
	render() {
		const page = "more";
		const currentSubpage = store.main.more.subpage;
		const admin = HasAdminPermissions(MeID());
		return (
			<>
				{/*<SubNavBar_Auto page={page}/>*/}
				<SubNavBar>
					<SubNavBarButton {...{page}} subpage="download" text="Download"/>
					<SubNavBarButton {...{page}} subpage="links" text="Links"/>
					{admin && <SubNavBarButton {...{page}} subpage="admin" text="Admin"/>}
					{/*<SubNavBarButton {...{page}} subpage="tests" text="Tests"/>*/}
				</SubNavBar>
				<Switch>
					<DownloadUI/>
					{currentSubpage == "links" && <LinksUI/>}
					{/*currentSubpage == "tasks" && <TasksUI/>*/}
					{currentSubpage == "admin" && <AdminUI/>}
					{currentSubpage == "tests" && <TestsUI/>}
					{currentSubpage == "sign-in-helper" && <SignInHelper/>}
				</Switch>
			</>
		);
	}
}