import {BaseComponent} from "react-vextensions";
import {TextInput, Button, Row, Column, CheckBox, Spinner, Text, TextArea, Select} from "react-vcomponents";
import {ShowMessageBox} from "react-vmessagebox";
import {TriggerSet, TriggerSet_ToString, SequenceItem, Sequence, TriggerType_values, TriggerType, ScreenState_values, NewSequenceItemForType} from "../../Store/firebase/fbaConfigs/@TriggerSet";
import {ToJSON, Clone, FromJSON, WaitXThenRun, Timer, IsBool, ToJSON_Advanced, AddSpacesAt_Options, DelIfFalsy, GetEntries} from "js-vextensions";
import {InfoButton, Link} from "web-vcore";
import {keyNames_normal, keyNames_withSides} from "../../Utils/General/Keys";
import keycode from "keycode";
import {DialogStyle} from "../../Utils/UI/GlobalStyles.js";
import {ScrollView} from "react-vscrollview";
import {AlarmsPhase} from "../../Engine/FBASession/Components/AlarmsComp.js";

export type TriggerSetChangerFunc = (triggerSet: TriggerSet)=>any;

export class ControlInput extends BaseComponent<{enabled: boolean, value: TriggerSet, onChange: (val: TriggerSet)=>any}, {newValue: TriggerSet}> {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.value != this.props.value) { // if base-data changed
			this.SetState({newValue: Clone(props.value) || new TriggerSet({})});
		}
	}

	render() {
		const {enabled, value, onChange} = this.props;

		// in the text-box, continue displaying the base-value until the user presses "OK" in the dialog
		return (
			<Row style={{flex: 1}}>
				<TextInput editable={false} style={{flex: 1}} value={TriggerSet_ToString(value)}/>
				<Button text="Trigger" style={{fontSize: 12, padding: "5px 7px", borderRadius: "0 5px 5px 0"}} onClick={()=>{
					const error = null;

					/*const ChangeConfig = (changerFunc: TriggerSetChangerFunc)=>{
						const newTriggerSet = Clone(this.state.newValue);
						changerFunc(newTriggerSet);
						/*this.SetState({newData: newTriggerSet}, ()=>{
							boxController.UpdateUI();
						});*#/
						this.SetState({newValue: newTriggerSet});
					};*/
					const boxController = ShowMessageBox({
						title: "Edit controls used for action", cancelButton: true,
						message: ()=>{
							const {newValue} = this.state;
							boxController.UpdateOptions({okButtonProps: {enabled: enabled && error == null}});
							return <TriggerSetEditor enabled={enabled} value={newValue} onChange={val=>{
								/*ChangeConfig(c=>{
									//c.VSet(E(Object.keys(c).ToMapObj(a=>a, undefined), val), {deleteUndefined: true});
									Object.keys(c).forEach(key=>Reflect.deleteProperty(c, key));
									c.VSet(val);
								});*/
								this.SetState({newValue: val});
							}}/>;
						},
						extraButtons: ()=>{
							return (
								<Row style={{display: "inline-flex", float: "right"}}>
									<Text>Show:</Text>
									<Button ml={5} text="KeyNames" onClick={()=>{
										ShowMessageBox({
											message: ()=>{
												return (
													<Column style={DialogStyle({width: 800, height: 500})}>
														<Text>For unicode characters like letters and numbers, just type the character directly. For the rest, use the list below.</Text>
														<TextArea mt={5} style={{flex: 1}} value={keyNames_normal.concat(keyNames_withSides).join(", ")}/>
													</Column>
												);
											},
											extraButtons: ()=>{
												return (
													<>
														<Link style={{float: "right"}} text="See latest online list" to="https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values"/>
													</>
												);
											},
										});
										//alert(keyNames_customized.join(", "));
									}}/>
									{/*<Button ml={5} text="KeyNamesWithSides" onClick={()=>{
										ShowMessageBox({
											message: ()=>{
												return <TextArea style={{width: 800, height: 500}} value={`
												This list is the same as KeyNames, except with a few replacements (eg. Control -> ControlLeft) listed below.
												==========
												${keyNames_withSides.join(", ")}
											`.AsMultiline(0)}/>;
											},
										});
										//alert(keyNames_withSides.join(", "));
									}}/>*/}
									<Button ml={5} text="KeyCodes" onClick={()=>{
										ShowMessageBox({
											message: ()=>{
												return (
													<Column style={DialogStyle({width: 800, height: 500})}>
														<Text>Only the numbers in this list actually matter; the names are just so you know what keys yield them.</Text>
														<TextArea mt={5} style={{flex: 1}} value={ToJSON(keycode.codes, undefined, 4)}/>
													</Column>
												);
											},
										});
										//alert(ToJSON(keycode.codes));
									}}/>
								</Row>
							);
						},

						onOK: ()=>{
							// once OK is pressed, send new-value to comp's onChange, triggering base-value update for this component
							if (onChange) onChange(this.GetNewData());
						},
						onCancel: ()=>{
							this.SetState({newValue: Clone(value) || {}});
						},
					});
				}}/>
			</Row>
		);
	}

	GetNewData() {
		const {newValue: newData} = this.state;
		return Clone(newData) as TriggerSet;
	}
}

export class TriggerSetEditor extends BaseComponent<{enabled: boolean, value: TriggerSet, onChange: (newValue: TriggerSet, ui: TriggerSetEditor)=>any}, {newValue: TriggerSet, jsonError: Error|n}> {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.value != this.props.value) { // if base-value changed
			this.SetState({newValue: Clone(props.value) || {}});
		}
	}

	resetErrorTimer = new Timer(5000, ()=>this.SetState({jsonError: null}), 1);
	render() {
		const {enabled, onChange} = this.props;
		const {newValue, jsonError} = this.state;
		//const controlsJSON = `{${""}"keySequence": {}}`; // todo

		/*const Change = (changerFunc: TriggerSetChangerFunc)=>{
			const newTriggerSet = Clone(this.state.newValue);
			changerFunc(newTriggerSet);
			this.SetState({newValue: newTriggerSet});
		};*/
		const Change = (..._)=>{
			// simplify object
			/*newValue.Pairs().forEach(pair=>{
				// if prop-val is "false", we can represent the same thing by just omitting the prop; do so for simpler json
				if (pair.value == false) {
					delete newValue[pair.key];
				}
			});*/

			if (onChange) onChange(this.GetNewValue(), this);
			this.Update();
		};

		const newValueJSON_simplified = ToJSON_Advanced(newValue, {addSpacesAt: new AddSpacesAt_Options()});
		return (
			<ScrollView style={DialogStyle({width: 800})}>
				<Column /*pb={10}*/>
					<Row center>
						<Text>Enabled:</Text>
						<CheckBox ml={5} enabled={enabled} value={!newValue.disabled} onChange={newEnabled=>{
							if (newEnabled) delete newValue.disabled;
							else newValue.disabled = true;
							Change();
						}}/>
					</Row>
					<Row center>
						<Text>Max trigger frequency: once per </Text>
						<Spinner ml={5} enabled={enabled} step={.1} min={0} max={1000} value={newValue.minTriggerInterval} onChange={val=>{
							//Change(newValue.VSet("minTriggerInterval", DelIfFalsy(val)));
							Change(newValue.minTriggerInterval = val); // for now, always set -- mobx-sync otherwise doesn't overwrite the store-defaults for that slot/property (mobx-sync bug)
						}}/>
						<Text> seconds</Text>
						<InfoButton ml={5} text="If this trigger is activated more than once per X seconds, those extra activations will be ignored."/>
					</Row>

					<Text mt={10}>Sequences:</Text>
					{newValue.sequences.map((sequence, sequenceIndex)=>{
						return <SequenceRow key={sequenceIndex} {...{sequence, sequences: newValue.sequences, enabled, Change}}/>;
					})}
					<Row mt={5}>
						<Button enabled={enabled} text="Add sequence" p="3.5px 5px" style={{fontSize: 12}} onClick={()=>{
							const newSequence = new Sequence([]);
							newValue.sequences.push(newSequence);
							Change();
						}}/>
					</Row>

					<Text mt={10} mb={5}>Raw JSON:</Text>
					<TextArea enabled={enabled} autoSize={true} value={newValueJSON_simplified} onChange={val=>{
						try {
							const newData = FromJSON(val);
							Object.keys(newValue).forEach(key=>Reflect.deleteProperty(newValue, key));
							newValue.VSet(newData);
						} catch (error) {
							this.SetState({jsonError: error});
							this.resetErrorTimer.Start();
						}
						Change();
					}}/>
					{jsonError && <Text mt={5}>Error parsing JSON: {jsonError.toString()}</Text>}
				</Column>
			</ScrollView>
		);
	}

	GetNewValue() {
		const {newValue} = this.state;
		return Clone(newValue) as TriggerSet;
	}
}

class SequenceRow extends BaseComponent<{sequence: Sequence, sequences: Sequence[], enabled, Change: (..._)=>any}, {panelOpen: boolean}> {
	render() {
		const {sequence, sequences, enabled, Change} = this.props;
		const {panelOpen} = this.state;
		const sequenceIndex = sequences.indexOf(sequence);
		return (
			<>
				<Row key={sequenceIndex} mt={5} style={{
					//flexWrap: "wrap",
					whiteSpace: "pre",
				}}>
					<Button text={`Seq. ${sequenceIndex + 1}: `} p="3.5px 5px" style={{fontSize: 12, borderRadius: panelOpen ? "5px 5px 0 0" : 5}} onClick={()=>this.SetState({panelOpen: !panelOpen})}/>
					{sequence.items.map((item, itemIndex)=>{
						const keyNames = ["@KeyName", "@KeyCode", "VolumeUp", "VolumeDown"];
						const keyNames_plural = [{name: "@KeyNames", value: "@KeyName"}, {name: "@KeyCodes", value: "@KeyCode"}];

						return (
							<Row key={itemIndex} ml={7} style={{border: "solid hsla(0,0%,100%,.3)", borderWidth: "1px 0", borderRadius: 5}}>
								<Select enabled={enabled} options={TriggerType_values} style={{borderRadius: "5px 0 0 5px", fontSize: 12}} value={item.type} onChange={(val: TriggerType)=>{
									const newSequenceItemWithThisType = NewSequenceItemForType(val);
									sequence.items[itemIndex] = newSequenceItemWithThisType;
									Change();
								}}/>
								{item.key_name &&
								<>
									<Select enabled={enabled} options={item.type == "Keys" ? keyNames_plural : keyNames} style={{fontSize: 12}} value={item.key_name} onChange={val=>Change(item.key_name = val)}/>
									{/*["@KeyName", "@KeyCode"].includes(item.key_name) &&*/}
									{item.key_name == "@KeyName" &&
									<TextInput enabled={enabled} style={{width: 80, fontSize: 12}} value={item.key_extra} onChange={val=>Change(item.key_extra = val)}/>}
								</>}
								{item.noKey != null &&
								<>
									<Spinner enabled={enabled} style={{width: 50}} value={item.noKey} onChange={val=>Change(item.noKey = val)} step={.1} min={0} max={1000}/>
									<Text title="seconds">s</Text>
								</>}
								{item.micLoudness_loudness &&
								<>
									<Spinner enabled={enabled} value={(item.micLoudness_loudness * 100).RoundTo(.01)} onChange={val=>Change(item.micLoudness_loudness = val / 100)} min={0} max={100}/>
									<Text>%</Text>
								</>}
								{item.screenChange_state &&
								<>
									<Select enabled={enabled} options={[{name: "On", value: "on"}, {name: "Off", value: "off"}]} style={{fontSize: 12}} value={item.screenChange_state} onChange={val=>Change(item.screenChange_state = val)}/>
								</>}
								{item.checkWindow != null &&
								<>
									<Text ml={5} title="Check window. (ie. size of window in seconds, for average-motion to be evaluated for; must be multiple of 0.5, and <=10)">Window:</Text>
									<Spinner ml={5} enabled={enabled} style={{width: 50}} value={item.checkWindow} onChange={val=>Change(item.checkWindow = val)} min={0} max={10} step={.5} enforceRange={true}/>
									<Text title="seconds">s</Text>
									<Text ml={5} title="Minimum average motion required for this trigger to activate.">Min avg motion:</Text>
									<Spinner ml={5} enabled={enabled} style={{width: 50}} value={item.minAverageMotion} onChange={val=>Change(item.minAverageMotion = val)} min={0} step={.1}/>
								</>}
								<Button enabled={enabled} text="X" p="2px 4px" style={{borderRadius: "0 5px 5px 0"}} onClick={()=>{
									sequence.items.Remove(item);
									Change();
								}}/>
							</Row>
						);
					})}
					<Button ml={7} enabled={enabled} text="+" p="2px 4px" onClick={()=>{
						const newItem = new SequenceItem({type: "Key", key_name: "VolumeUp"});
						sequence.items.push(newItem);
						Change();
					}}/>
				</Row>
				{panelOpen &&
				<>
					<Row center p={5} style={{background: "hsla(0,0%,100%,.2)", borderRadius: "0 5px 5px 5px", flexWrap: "wrap"}}>
						<Text>Screen state:</Text>
						<InfoButton ml={5} text={`If set to "On" or "Off", causes any item-activations that occur when screen-state doesn't match to be ignored. (only works on Android)`}/>
						<Select ml={5} enabled={enabled} options={[{name: "Any", value: null}, {name: "On", value: "on"}, {name: "Off", value: "off"}]} value={sequence.screenState} onChange={val=>{
							//Change(sequence.VSet("screenState", DelIfFalsy(val)));
							Change(sequence.screenState = val); // for now, always set -- mobx-sync otherwise doesn't overwrite the store-defaults for that slot/property (mobx-sync bug)
						}}/>
						<Text ml={10}>Journey phase:</Text>
						<InfoButton ml={5} text={`If has 1+ entries, causes any item-activations that occur when journey-phase doesn't match one of the values to be ignored.`}/>
						{sequence.journeyPhases?.length && sequence.journeyPhases.map((phase, i)=>{
							return <Select key={i} ml={5} enabled={enabled} options={GetEntries(AlarmsPhase, "ui")} value={phase} onChange={val=>{
								const newPhases = sequence.journeyPhases!.slice();
								newPhases[i] = val;
								Change(sequence.journeyPhases = newPhases);
							}}/>;
						})}
						<Button ml={5} enabled={enabled} text="+" p="3.5px 5px" style={{fontSize: 12}} onClick={()=>{
							const newPhases = (sequence.journeyPhases ?? []).concat(AlarmsPhase.NotStarted);
							Change(sequence.journeyPhases = newPhases); // for now, always set -- mobx-sync otherwise doesn't overwrite the store-defaults for that slot/property (mobx-sync bug)
						}}/>
						<Button ml={5} enabled={enabled} text="-" p="3.5px 5px" style={{fontSize: 12}} onClick={()=>{
							const newPhases = sequence.journeyPhases!.slice(0, -1);
							Change(sequence.journeyPhases = newPhases.length ? newPhases : null); // for now, always set -- mobx-sync otherwise doesn't overwrite the store-defaults for that slot/property (mobx-sync bug)
						}}/>
						<Button ml={10} enabled={enabled} text={`Delete sequence #${sequenceIndex + 1}`} p="3.5px 5px" style={{fontSize: 12}} onClick={()=>{
							sequences.Remove(sequence);
							Change();
						}}/>
					</Row>
				</>}
			</>
		);
	}
}