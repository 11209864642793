import Recorder from "opus-recorder";
import {BlobToString, secondInMS} from "web-vcore";
import {Assert, CloneWithPrototypes, Range, Timer} from "js-vextensions";
import {FBAConfig_Memory, OptionPoolLayer, OptionPoolOrdering, OptionPoolSource} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Memory.js";
import {GetSounds_WithUserTag} from "../../../Store/firebase/sounds.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {SoundPlayer} from "../../../Utils/EffectPlayers/SoundPlayer.js";
import {GetContinentQuadrantsIntersectedByCountry, GetCountries, GetCountryContinent, GetNearbyCountries} from "../../../Utils/Geography/CountryInfo.js";
import {ConvertSpeechToText} from "../../../Utils/Services/DialogFlow.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {SessionEvent} from "../SessionEvent.js";
import {AlarmsComp} from "./AlarmsComp.js";
import {AlarmComp} from "./AlarmComps/@AlarmComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";

export class MemoryComp extends EngineSessionComp<FBAConfig_Memory> {
	constructor(session: FBASession, config: FBAConfig_Memory, hostConfig: FBAConfig_Memory) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
		this.hc = hostConfig;
		// comp has functionality if local, or if on remote with remote_recordAudioOnRemote enabled
		this.behaviorEnabled = this.triggersEnabled && (this.s.IsLocal() || config.speechMatchSubmission!.remote_recordAudioOnRemote);

		this.promptIntervalTimer = new Timer(this.c.stepSize * 1000, ()=>{
			//this.intensity = (this.intensity + this.c.intensityIncreasePerStep).KeepAtMost(this.c.maxIntensity);
			this.PlayPrompt();
		}).SetContext(this.s.timerContext);
	}
	hc: FBAConfig_Memory;

	promptIntervalTimer: Timer;

	GetTriggerPackages() {
		return [
			new TriggerPackage("SnoozeAndPrompts_MemoryPrompt_NextHint", this.c.nextHint_triggerSet, this, {}, triggerInfo=>{
				this.NextHint();
			}),
			new TriggerPackage("SnoozeAndPrompts_MemoryPrompt_PreviousHint", this.c.previousHint_triggerSet, this, {}, triggerInfo=>{
				this.PreviousHint();
			}),
			new TriggerPackage("SnoozeAndPrompts_MemoryPrompt_GiveUp", this.c.giveUp_triggerSet, this, {}, triggerInfo=>{
				this.GiveUpPick();
			}),
			// enumeration submission
			...(()=>{
				if (this.c.enumerationSubmission == null) return [];
				return [
					new TriggerPackage("SnoozeAndPrompts_MemoryPrompt_SubmitPick", this.c.enumerationSubmission.submitPick_triggerSet, this, {}, triggerInfo=>{
						if (!this.c.enumerationSubmission!.enabled) return;
						this.Enum_SubmitPick();
					}),
					new TriggerPackage("SnoozeAndPrompts_MemoryPrompt_NextOption", this.c.enumerationSubmission.nextOption_triggerSet, this, {}, triggerInfo=>{
						if (!this.c.enumerationSubmission!.enabled) return;
						this.Enum_NextOption();
					}),
					new TriggerPackage("SnoozeAndPrompts_MemoryPrompt_PreviousOption", this.c.enumerationSubmission.previousOption_triggerSet, this, {}, triggerInfo=>{
						if (!this.c.enumerationSubmission!.enabled) return;
						this.Enum_PreviousOption();
					}),
				];
			})(),
			// speech-match submission
			...(()=>{
				if (this.c.speechMatchSubmission == null) return [];
				return [
					new TriggerPackage("SnoozeAndPrompts_MemoryPrompt_ListenOrSubmitPick", this.c.speechMatchSubmission.listenOrSubmitPick_triggerSet, this, {},
						triggerInfo=>{
							if (!this.c.speechMatchSubmission!.enabled) return;
							this.Speech_ListenOrSubmitPick();
						},
						triggerInfo=>{
							if (!this.c.speechMatchSubmission!.enabled) return;
							if (this.c.speechMatchSubmission!.remote_recordAudioOnRemote) {
								this.Speech_ListenOrSubmitPick();
							} else {
								this.s.AsClient!.RunTriggerActionOnHost("SnoozeAndPrompts_MemoryPrompt_ListenOrSubmitPick", triggerInfo);
							}
						},
					),
				];
			})(),
		];
	}

	// host<>remote communication
	// ==========

	HostToRemote_NotifyPromptingStarted() {
		if (!this.triggersEnabled) return;
		if (this.c.speechMatchSubmission!.remote_recordAudioOnRemote) {
			this.StartListening();
		}
	}
	RemoteToHost_SpeechMatchComp_ReceiveSpeechSubmission(speechText: string) {
		if (!this.triggersEnabled) return;
		return this.SpeechMatch_ReceiveSpeechSubmission(speechText);
	}

	// general
	// ==========

	speechPlayer_snoozable = new SoundPlayer();
	speechPlayer_unsnoozable = new SoundPlayer();
	speechPlayer_listening = new SoundPlayer();

	//@AlwaysCall OnStart() {
	OnStart() {
		//if (!this.enabled && !this.c.speechMatchSubmission.remote_recordAudioOnRemote) return;

		// speech-match submission
		/*this.waitForNextItemActivationTimer = new Timer(this.c.speechMatchSubmission.maxDelayBetweenItems * secondInMS, ()=>{
			this.ResetSequence();
		}, 1).SetContext(this.s.timerContext);*/
		// have it cut off a bit before 60s mark, since if it goes over at all, DialogFlow rejects the whole submission
		this.stopListeningAfter60sTimer = new Timer(59000, ()=>{
			this.StopMicRecorder();
		}, 1).SetContext(this.s.timerContext);

		if (this.s.IsLocal()) {
			this.StartNextMemoryCycle(null);
			/*if (this.c.speechMatchSubmission.enabled) {
				this.StartSpeechMatcher();
			}*/
		}
	}
	//@AlwaysCall OnStop() {
	OnStop() {
		//if (!this.enabled && !this.c.speechMatchSubmission.remote_recordAudioOnRemote) return;

		/*if (this.c.speechMatchSubmission.enabled) {
			this.StopSpeechMatcher();
		}*/
		if (this.micRecorder) this.StopMicRecorder();
		this.speechPlayer_snoozable.Stop();
		this.speechPlayer_unsnoozable.Stop();
	}

	override OnLeavePhase_Alarm() {
		//let isMicroSnooze = options.resetPromptStates == false;
		this.volume = this.c.intensityStart;

		// if memory-voice should be reset (either from currently being active, or always-reset-externals being enabled), reset memory-voice
		if (this.promptIntervalTimer.Enabled) {
			this.promptIntervalTimer.Stop();
			this.StopPrompt();
		}
	}

	StartNextMemoryCycle(lastCycleResult: "success" | "gave up" | null) {
		const snoozeDurationInS = lastCycleResult == null ? 0 : this.targetHitSnoozeDuration;

		// construct option pool
		this.optionPool = [];
		let layers: OptionPoolLayer[];
		if (this.c.optionPoolSource == OptionPoolSource.Manual) {
			layers = this.c.optionPoolLayers!.filter(a=>a.enabled);
		} else if (this.c.optionPoolSource == OptionPoolSource.Geography) {
			// todo: maybe make-so user can reorder the countries (to make the IndexInList sorting option have a use)
			layers = [{enabled: true, items: GetCountries().filter(a=>this.c.optionPool_countriesEnabled!.Contains(a.names.en)).map(a=>a.names.en)}];
		}
		let optionPoolSize_final = this.c.optionPoolSize;
		// if only one layer, limit option-pool size to the number of items in it, since otherwise we'd be adding exact duplicates
		if (layers!.length == 1) optionPoolSize_final = optionPoolSize_final!.KeepAtMost(layers![0].items.length);

		for (let i = 0; i < optionPoolSize_final!; i++) {
			const newOption = layers!.map((layer, layerIndex)=>{
				const layerItemsTaken = this.optionPool.map(option=>option[layerIndex]);
				const layerItemsNotTaken = layer.items.Exclude(...layerItemsTaken);
				return layerItemsNotTaken.length ? layerItemsNotTaken.Random() : layer.items.Random();
			});
			this.optionPool.push(newOption);
		}
		this.optionPool = this.optionPool.OrderBy((option, index)=>{
			if (this.c.optionPoolOrdering == OptionPoolOrdering.Alphabetically) return option.join(", ");
			if (this.c.optionPoolOrdering == OptionPoolOrdering.IndexInList) return option.map((layerItem, layerItemIndex)=>layers[layerItemIndex].items.indexOf(layerItem)).join(".");
			if (this.c.optionPoolOrdering == OptionPoolOrdering.StringLength) return option.join(", ").length;
			if (this.c.optionPoolOrdering == OptionPoolOrdering.Random) return index; // just keep the old order (which is random)
		});

		this.optionPool_targetIndex = Range(0, this.optionPool.length - 1).Random();
		this.optionPool_pickIndex = -1;
		this.targetHitSnoozeDuration = this.c.startPoint;
		this.readyForPickSubmission = false;
		this.ResetHintsState();
		this.ResetSubmitPickState();

		//this.s.Comp(AlarmsComp).Snooze({adjustSnoozeDelaysSoFirstRunsIn: snoozeDurationInS! * 1000}); // todo
		throw new Error("Implementation incomplete.");
		const lastCycleResult_str = {success: "Correct. ", "gave up": "Gave up. "}[lastCycleResult as any] || "";
		this.SpeakText(`${lastCycleResult_str}New target is: ${this.TargetAsString}`);
		this.Log(`New target selected: ${this.TargetAsString}`, LogType.Event_Large);
	}

	volume = 0;
	optionPool: string[][] = [];
	optionPool_targetIndex = -1;
	get TargetOption() { return this.optionPool[this.optionPool_targetIndex]; }
	get TargetAsString() { return this.TargetOption ? this.TargetOption.join(", ") : null; }
	optionPool_pickIndex = -1;
	get PickedOption() { return this.optionPool[this.optionPool_pickIndex]; }
	get PickedOptionAsString() { return this.PickedOption == null ? null : this.PickedOption.join(", "); }
	readyForPickSubmission = false;

	SpeakText(text: string, snoozable = false) {
		if (this.c.intensityStart == 0 && this.c.intensityMax == 0) return;
		const baseSound = GetSounds_WithUserTag(this.c.voiceSoundTag).Random();
		if (baseSound == null) return; // todo: show message or something
		const player = snoozable ? this.speechPlayer_snoozable : this.speechPlayer_unsnoozable;
		player.sound = CloneWithPrototypes(baseSound).VSet({text, name: text});
		player.Play(this.volume);
	}
	SayStillSnoozing() {
		this.SpeakText(`Still snoozing. Target is: ${this.TargetAsString}`, true);
	}

	StartPrompting() {
		this.Log("Starting prompting (memory)", LogType.Event_Large);
		this.readyForPickSubmission = true;
		//if (this.c.speechMatchSubmission.enabled && !fromMicroSnooze) {
		if (this.c.speechMatchSubmission!.enabled) {
			// when ready for pick-submission, auto-start-listening for speech-match (so user can just hear the prompt, speak his pick, and tap once to submit it [assuming done within 60s of prompt/listen-start])
			this.StartListening();
		}
		this.TryCallOnClientComp("HostToRemote_NotifyPromptingStarted");

		this.PlayPrompt();
		this.promptIntervalTimer.Start();
	}
	PlayPrompt() {
		this.SpeakText("Find the target.");
		//this.intensityAtLastPrompt = this.intensity;
	}
	UpdatePrompt_ForReducedIntensity() {
		// do nothing; function only called for eeg group, and memory-prompt is never part of eeg group
	}
	StopPrompt() {
		this.speechPlayer_snoozable.Stop();
		//this.speechPlayer_unsnoozable.Stop();
	}

	targetHitSnoozeDuration: number|n = -1; // seconds
	NotifyTargetHit(logOverride?: string) {
		this.s.AsLocal!.AddEvent({type: "MemoryPrompt.TargetHit"});
		this.Log(logOverride || `Hit the memory-prompt target! Target: ${this.TargetAsString}`, LogType.Action);
	}
	NotifyTargetMiss(logOverride?: string) {
		this.s.AsLocal!.AddEvent({type: "MemoryPrompt.TargetMiss"});
		this.Log(logOverride || `Missed the memory-prompt target. Pick: ${this.PickedOptionAsString} Target: ${this.TargetAsString}`, LogType.Action);
		this.targetHitSnoozeDuration = this.targetHitSnoozeDuration != null && this.c.targetMiss_snoozePenalty != null && this.c.targetMiss_snoozeMin
			? (this.targetHitSnoozeDuration - this.c.targetMiss_snoozePenalty).KeepAtLeast(this.c.targetMiss_snoozeMin)
			: this.c.targetMiss_snoozeMin;
	}

	hints: string[] = [];
	lastHintGenerationTime = 0;
	currentHintIndex = -1;
	ResetHintsState() {
		this.hints = [];
		this.lastHintGenerationTime = 0;
		this.currentHintIndex = -1;
	}
	TryToGenerateNewHint() {
		const timeWhenCanGenerateNewHint = this.lastHintGenerationTime + (this.c.minHintInterval! * secondInMS);
		if (Date.now() < timeWhenCanGenerateNewHint) {
			//return `Wait ${((timeWhenCanGenerateNewHint - Date.now()) / 1000).RoundTo(1)}`;
			return `Next hint not ready`;
		}

		if (this.c.optionPoolSource == OptionPoolSource.Manual) {
			// todo
		} else if (this.c.optionPoolSource == OptionPoolSource.Geography) {
			const country = this.TargetOption[0];
			let newHint: string;
			if (this.hints.length == 0) {
				newHint = `in ${GetCountryContinent(country)!.name}`;
			} else if (this.hints.length == 1) {
				newHint = `in ${GetContinentQuadrantsIntersectedByCountry(country).join(" and ")} ${GetCountryContinent(country)!.name}`;
			} else {
				const additionalNearbyCountries = GetNearbyCountries(country).filter(a=>!this.hints.Contains(`near ${a.names.en}`));
				if (additionalNearbyCountries.length) {
					newHint = `near ${additionalNearbyCountries.Random().names.en}`;
				} else if (!this.hints.Any(a=>a.startsWith("starts with the letter"))) {
					newHint = `starts with the letter ${country[0]}`;
				} else {
					return "No further hints available";
				}
			}

			Assert(newHint, "If no error, function must return a new hint.");
			this.hints.push(newHint);
			this.lastHintGenerationTime = Date.now();
			return null;
		}
	}
	SayCurrentHint() {
		const hint = this.hints[this.currentHintIndex];
		if (hint == null) return;
		this.SpeakText(`${this.currentHintIndex + 1}: ${hint}`);
		this.Log(`Speaking hint ${this.currentHintIndex + 1}: ${hint}`, LogType.Action);
	}
	NextHint() {
		if (!this.readyForPickSubmission) return void this.SayStillSnoozing();

		// if we need to generate a new hint, try to generate one
		if (this.currentHintIndex >= this.hints.length - 1) {
			// if generating new hint failed, alert user then return
			const error = this.TryToGenerateNewHint();
			if (error) {
				if (error == "Next hint not ready") this.SayCurrentHint();
				else this.SpeakText(error);
				return;
			}
		}
		this.currentHintIndex++;
		this.SayCurrentHint();
	}
	PreviousHint() {
		if (!this.readyForPickSubmission) return void this.SayStillSnoozing();

		// allowing going down to index -1 (indicates that user reached edge, and tried to go past)
		//if (this.currentHintIndex > -1) {
		if (this.currentHintIndex > 0) {
			this.currentHintIndex--;
		}
		this.SayCurrentHint();
	}

	GiveUpPick() {
		if (!this.readyForPickSubmission) return void this.SayStillSnoozing();

		this.s.AsLocal!.AddEvent({type: "MemoryPrompt.TargetGiveUp"});
		this.Log(`Gave up on memory-prompt target. Target: ${this.TargetAsString}`, LogType.Action);
		this.targetHitSnoozeDuration = this.c.targetMiss_snoozeMin;
		this.StartNextMemoryCycle("gave up");
	}

	// enumeration submission
	// ==========

	lastPickSubmitTime = 0;
	ResetSubmitPickState() {
		this.lastPickSubmitTime = 0;
	}
	Enum_SubmitPick(failSpeechOverride?: string) {
		if (!this.readyForPickSubmission) return void this.SayStillSnoozing();
		const timeWhenCanSubmitNewPick = this.lastPickSubmitTime + (this.c.enumerationSubmission!.minPickInterval * secondInMS);
		if (Date.now() < timeWhenCanSubmitNewPick) {
			//this.SpeakText("Wait");
			this.SpeakText(`Wait ${((timeWhenCanSubmitNewPick - Date.now()) / 1000).RoundTo(1)}`);
			return;
		}

		if (this.optionPool_pickIndex == this.optionPool_targetIndex) {
			this.NotifyTargetHit();
			this.StartNextMemoryCycle("success");
		} else {
			this.NotifyTargetMiss();
			if (this.c.enumerationSubmission!.changePick_snooze_enabled) this.Enum_ApplyMicroSnooze();
			this.SpeakText(failSpeechOverride || "Not correct. Find the target.");
		}
		this.lastPickSubmitTime = Date.now();
	}
	Enum_NextOption() {
		if (!this.readyForPickSubmission) return void this.SayStillSnoozing();

		this.optionPool_pickIndex = (this.optionPool_pickIndex + 1).KeepBetween(0, this.optionPool.length - 1);
		if (this.c.enumerationSubmission!.changePick_snooze_enabled) this.Enum_ApplyMicroSnooze();
		this.SpeakText(this.PickedOptionAsString!);
	}
	Enum_PreviousOption() {
		if (!this.readyForPickSubmission) return void this.SayStillSnoozing();

		this.optionPool_pickIndex = (this.optionPool_pickIndex - 1).KeepBetween(0, this.optionPool.length - 1);
		if (this.c.enumerationSubmission!.changePick_snooze_enabled) this.Enum_ApplyMicroSnooze();
		this.SpeakText(this.PickedOptionAsString!);
	}
	Enum_ApplyMicroSnooze() {
		throw new Error("Implementation incomplete.");
		/*this.s.Comp(AlarmsComp).Snooze({
			//snoozeType: SnoozeType.Micro,
			logTypeOverride: LogType.Event_Small, // this micro-snooze is usually only a few seconds, so not worth spamming the action/event-large logs with it
			resetPromptStates: false, // since this is a micro-snooze, we're only pausing prompt-effects, not resetting them
			adjustSnoozeDelaysSoPromptEffectsArePausedFor: this.c.enumerationSubmission!.changePick_snooze_duration * 1000,
		});*/
	}

	// speech-match submission (DialogFlow)
	// ==========

	/*micRecorder = new SoundRecorder(function(stream) {
		const workerOptions = {
			/*encoderWorkerFactory() {
				// UMD should be used if you don't use a web worker bundler for this.
				return new Worker("/FromNodeModules/encoderWorker.umd.js");
			},*#/
			encoderWorkerFactory: ()=>new ORM_EncoderWorker(),
			// in "Resources" folder
			OggOpusEncoderWasmPath: "/FromNodeModules/OggOpusEncoder.wasm",
			WebMOpusEncoderWasmPath: "/FromNodeModules/WebMOpusEncoder.wasm",
		};
		/*const workerOptions = {
			encoderWorkerFactory: ()=>new OMR_Worker(),
			//OggOpusEncoderWasmPath: OMR_OggOpusWasm,
			//WebMOpusEncoderWasmPath: OMR_WebMOpusWasm,
		};*#/
		//return new OpusMediaRecorder(stream, {mimeType: "audio/ogg"}, workerOptions);
		return new OpusMediaRecorder(stream, {mimeType: "audio/wav"}, workerOptions);
	} as any as new (..._) => any);*/
	/*async Speech_ListenOrSubmitPick() {
		if (!this.micRecorder.IsRecording()) {
			await this.micRecorder.StartRecording(GetMainMicrophoneID());
		}

		this.micRecorder.recorder.addEventListener("dataavailable", e=>{
			const audioData = e.data as Blob;
			//const audioData = new Blob([audioData_orig], {type: "audio/ogg;codecs=opus"});
			let audioDataStr = await BlobToString(audioData, "readAsDataURL");

			// only use base64-encoded data, i.e. remove meta-data from beginning:
			//var audioDataStr = audioDataStr.replace(/^data:audio\/flac;base64,/, "");
			//var audioDataStr = audioDataStr.replace(/^data:audio\/webm;codecs=opus;base64,/, "");
			//var audioDataStr = audioDataStr.replace(/^data:audio\/ogg;codecs=opus;base64,/, "");
			//var audioDataStr = audioDataStr.replace(/^data:audio\/ogg;base64,/, "");
			let audioDataStr_trimmed = audioDataStr.replace(/^data:.+?base64,/, "");

			const text = await ConvertSpeechToText(audioDataStr_trimmed);
			console.log(`Got speech!: ${text}`);
		});
	}*/

	async Speech_ListenOrSubmitPick() {
		// only care about this stuff when on the host (since on remote, it's too hard to sync state with host)
		if (this.s.IsLocal()) {
			if (!this.readyForPickSubmission) return void this.SayStillSnoozing();
		}

		// if the recorder is not currently active, start it
		if (this.micRecorder == null) {
			this.StartListening();
			this.SpeakText(`Speech-listener restarted`);
		}
		// if the recorder is already active, stop it, get its contents, then submit the contents as pick; if pick is not correct, restart recorder
		else {
			const pickCorrect = await this.StopSpeechRecorderAndSubmitPick();
			if (!pickCorrect) {
				this.StartListening();
			}
		}
	}

	micRecorder: Recorder|n;
	stopListeningAfter60sTimer: Timer;
	StartListening() {
		//if (!this.readyForPickSubmission) return void this.SpeakText(`Still snoozing. Target is: ${this.TargetAsString}`);
		//Assert(this.micRecorder == null, "micRecorder should be null when starting listening.");
		// if recorder is already active, discard that instance so we can start the next one
		//if (this.micRecorder != null) this.StopMicRecorder();
		// if recorder is already active, no need to start it again (this happens eg. when a micro-snooze completes, after having pressed "start listening")
		//		(this can cause disruption, if 60s timeout ends while user is speaking -- but it's better than alternative, of always causing cutoff-from-restart whenever StartListening() is called during an existing record, eg. from micro-snooze)
		if (this.micRecorder != null) return;

		var options = {
			//encoderSampleRate: 48000,
			encoderSampleRate: 16000,
			originalSampleRateOverride: 16000, // necessary due to Google bug? (https://github.com/chris-rudmin/opus-recorder/issues/191#issuecomment-509426093)
			encoderPath: "/FromNodeModules/encoderWorker.min.js",
		};
		//if (encoderBitRate.value) Object.assign(options, {encoderBitRate: parseInt(encoderBitRate.value, 10)});
		//if (encoderApplication.value) Object.assign(options, {encoderApplication: parseInt(encoderApplication.value, 10)});
		//if (encoderComplexity.value) Object.assign(options, {encoderComplexity: parseInt(encoderComplexity.value, 10)});

		this.micRecorder = new Recorder(options);
		this.micRecorder.onstart = ()=>SessionLog("Recorder is started");
		this.micRecorder.onstop = ()=>SessionLog("Recorder is stopped");
		this.micRecorder.onpause = ()=>SessionLog("Recorder is paused");
		this.micRecorder.onresume = ()=>SessionLog("Recorder is resuming");
		//this.micRecorder.ondataavailable = this.Speech_OnRecorderDataAvailable;

		this.micRecorder.start();
		this.stopListeningAfter60sTimer.Start();

		this.StartListenBackgroundSound();
	}
	async StartListenBackgroundSound() {
		this.speechPlayer_listening.sound = GetSounds_WithUserTag(this.c.speechMatchSubmission!.listen_backgroundSoundTag).Random();
		if (this.speechPlayer_listening.sound == null) return;

		await this.speechPlayer_listening.PrepareToPlay();
		// if loopable, set up looping
		if (this.speechPlayer_listening.youtubePlayer) {
			this.speechPlayer_listening.youtubePlayer.loop = true;
		}

		this.speechPlayer_listening.Play(this.c.speechMatchSubmission!.listen_backgroundSoundVolume);
	}
	StopListenBackgroundSound() {
		this.speechPlayer_listening.Stop();
	}

	StopMicRecorder() {
		this.micRecorder!.stop();
		this.micRecorder = null;
		this.StopListenBackgroundSound();
	}
	StopMicRecorderAndGetData(): Promise<ArrayBuffer> {
		return new Promise((resolve, reject)=>{
			this.micRecorder!.ondataavailable = resolve;
			this.StopMicRecorder();
		});
	}
	async StopSpeechRecorderAndSubmitPick() {
		this.stopListeningAfter60sTimer.Stop(); // we're stopping the recorder and its submitting contents now, so no need to stop the recorder at the 60s mark

		// calling stop triggers the ondataavailable() listener above
		const typedArray = await this.StopMicRecorderAndGetData();
		this.micRecorder = null;

		// stop it here (after the recorder gets stopped) instead of earlier, because we *want* the recorder to get restarted, even if still-snoozing (so that 60s timeout doesn't occur when we do end up talking)
		//if (!this.readyForPickSubmission) return void this.SpeakText(`Still snoozing. Target is: ${this.TargetAsString}`);

		SessionLog("Data received");
		const audioData = new Blob([typedArray], {type: "audio/ogg"});
		const audioData_dataURL = await BlobToString(audioData, "readAsDataURL");
		const audioData_str = audioData_dataURL.replace(/^data:.+?base64,/, "");

		const allEnabledTerms = this.hc.optionPoolLayers!.filter(a=>a.enabled).SelectMany(a=>a.items).Distinct();
		const speechText = await ConvertSpeechToText(audioData_str, this.hc.speechMatchSubmission!.validTermSensitivity <= 0 ? undefined : {
			speechContexts: [
				{
					//phrases: ["$OOV_CLASS_DIGIT_SEQUENCE"],
					phrases: allEnabledTerms,
					boost: this.hc.speechMatchSubmission!.validTermSensitivity,
				},
			],
		});
		console.log(`Got speech!: ${speechText}`);

		if (this.s.IsClient() && this.c.speechMatchSubmission!.remote_recordAudioOnRemote) {
			/*this.TryCallOnHostComp("RemoteToHost_SpeechMatchComp_ReceiveSpeech", speechText);
			return false; // treat as though a miss (so that listener gets started again immediately)*/
			return await this.TryCallOnHostComp("RemoteToHost_SpeechMatchComp_ReceiveSpeechSubmission", speechText) as boolean;
		}

		return this.SpeechMatch_ReceiveSpeechSubmission(speechText);
	}
	SpeechMatch_ReceiveSpeechSubmission(speechText: string) {
		// needed in case called from remote
		if (!this.readyForPickSubmission) {
			this.SayStillSnoozing();
			return false;
		}
		//if (this.c.speechMatchSubmission.listenOrSubmitPick_snooze_enabled) this.SpeechMatch_ApplyMicroSnooze();

		// normalize
		let speechText_normalized = speechText.toLowerCase();
		// since digits are a common target, count words that are close to the digits as also being those digits (eg. "to" is replaced with "to 2")
		const normalizationMappings = {
			0: ["zero"],
			1: ["one", "won", "juan"],
			2: ["two", "to", "too"],
			3: ["three"],
			4: ["four", "for", "fore"],
			5: ["five"],
			6: ["six"],
			7: ["seven"],
			8: ["eight", "ate"],
			9: ["nine"],
		};
		for (const pair of normalizationMappings.Pairs()) {
			const toStr = pair.key;
			const fromStrings = pair.value;
			/*for (const fromStr of fromStrings) {
				speechText_normalized = speechText_normalized.replace(new RegExp(`(^| )${fromStr}( |$)`, "g"), toStr);
			}*/
			//speechText_normalized = speechText_normalized.replace(new RegExp(`(?<=^| )${fromStrings.join("|")}(?= |$)`, "g"), toStr);
			//speechText_normalized = speechText_normalized.replace(new RegExp(`(^| )${fromStrings.join("|")}(?= |$)`, "g"), `$1${toStr}`);
			// avoid look-behind assertion, since not yet supported in some browsers, eg. firefox (and avoid look-ahead assertion, for consistency); instead, use capturing groups, and include in result
			speechText_normalized = speechText_normalized.replace(new RegExp(`(^| )(${fromStrings.join("|")})( |$)`, "g"), `$1$2 ${toStr}$3`);
		}

		/*let target_flat = this.TargetAsString.replace(/[^a-zA-Z0-9]/g, "");
		let speech_flat = text.replace(/[^a-zA-Z0-9]/g, "");*/

		/*const targetItemsFound = [];
		let searchFromPos = 0;
		for (const targetItem of this.TargetOption) {
			const targetItemStr_normalized = targetItem.toLowerCase();
			const speechTextLeft = speechText_normalized.slice(searchFromPos);
			const searchFindPos = speechTextLeft.indexOf(targetItemStr_normalized);
			if (searchFindPos != -1 && searchFindPos <= this.c.speechMatchSubmission.maxCharsBetweenItems) {
				targetItemsFound.push(targetItem);
				searchFromPos += (searchFindPos + targetItemStr_normalized.length);
			}
		}*/

		const targetItemsFound = FindLongestChain(this.TargetOption.map(a=>a.toLowerCase()), speechText_normalized, this.c.speechMatchSubmission!.maxCharsBetweenItems);

		const pickCorrect = targetItemsFound.length == this.TargetOption.length;
		if (pickCorrect) {
			/*this.optionPool_pickIndex = this.optionPool_targetIndex;
			this.Enum_SubmitPick();*/

			this.NotifyTargetHit(`Hit the memory-prompt target! Target: ${this.TargetAsString} Speech: ${speechText}`);
			this.StartNextMemoryCycle("success");
		} else {
			/*let oldPick = this.optionPool_pickIndex;
			this.optionPool_pickIndex = -1;
			this.Enum_SubmitPick();
			this.optionPool_pickIndex = oldPick;*/

			this.NotifyTargetMiss(`Missed the memory-prompt target. Target: ${this.TargetAsString} Speech: ${speechText}`);
			// if some text matches, only repeat the matching text
			/*if (targetItemsFound.length) {
				this.SpeakText(`"${speechText}" is not correct. Find the target.`);
			} else {
				// nothing matches, so repeat entire speech-text
				this.SpeakText(`"${speechText}" is not correct. Find the target.`);
			}*/
			this.SpeakText(`"${speechText}" is not correct. Find the target.`);
		}

		/*const fileName = `${new Date().toISOString()}.opus`;
		const url = URL.createObjectURL(audioData);
		const audio = document.createElement("audio");
		audio.controls = true;
		audio.src = url;
		const link = document.createElement("a");
		link.href = url;
		link.download = fileName;
		link.innerHTML = link.download;
		const li = document.createElement("li");
		li.appendChild(link);
		li.appendChild(audio);
		document.querySelector("main").prepend(li);*/

		return pickCorrect;
	}
}

// Note: Chars considered "between" items A and B does not include A and B themselves.
function FindLongestChain(targetChain: string[], textToSearch: string, maxCharsBetweenItems: number, maxCharsBetweenItems_applyToChainStart = false) {
	const targetItem = targetChain[0];
	let lastCharIndexToCheck = textToSearch.length - 1;
	if (maxCharsBetweenItems_applyToChainStart) {
		lastCharIndexToCheck = lastCharIndexToCheck.KeepAtMost(maxCharsBetweenItems);
	}

	const chains = [] as string[][];
	for (let i = 0; i <= lastCharIndexToCheck; i++) {
		const textFromHereForward = textToSearch.slice(i);
		if (textFromHereForward.startsWith(targetItem)) {
			const chain = [targetItem];
			const nextPartOfChain = FindLongestChain(targetChain.slice(1), textFromHereForward.slice(targetItem.length), maxCharsBetweenItems, true);
			//if (nextPartOfChain) chain.push(...nextPartOfChain);
			chain.push(...nextPartOfChain);
			chains.push(chain);
		}
	}
	return chains.OrderByDescending(a=>a.length)[0] || [];
}