import html2canvas from "html2canvas";

export async function TakeScreenshot(el): Promise<string> {
	//$("#fb-root").css("display", "none");
	let oldIFrame = HTMLIFrameElement;
	g.HTMLIFrameElement = function(){}; // temp fix for that html2canvas tries (and fails) to get contents of iframes
	let canvas = await html2canvas(el, {logging: true, useCORS: true, allowTaint: true}) as HTMLCanvasElement;
	g.HTMLIFrameElement = oldIFrame;
	//$("#fb-root").css("display", "");

	return canvas.toDataURL("image/png");
	/*return new Promise<string>((resolve, reject)=> {
		html2canvas(el).then(resolve).catch(reject);
	});*/
}

export function dataURItoBlob(dataURI) {
	var byteString = atob(dataURI.split(",")[1]);
	var ab = new ArrayBuffer(byteString.length);
	var ia = new Uint8Array(ab);
	for (var i = 0; i < byteString.length; i++) {
		 ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], {type: "image/png"});
}