import {BaseComponent, SimpleShouldUpdate} from "react-vextensions";
import {PageContainer, VReactMarkdown_Remarkable} from "web-vcore";

const pageText = `
### Dreaming-related

[Dream Views](https://www.dreamviews.com): Large forum on all things dream-related; dream journals; lucid-dreaming competitions.
`;

@SimpleShouldUpdate
export class LinksUI extends BaseComponent<{}, {}> {
	render() {
		return (
			<PageContainer scrollable={true}>
				<article className="selectableAC">
					{/*<VReactMarkdown className="selectable" source={pageText}/>*/}
					<VReactMarkdown_Remarkable source={pageText}/>
				</article>
			</PageContainer>
		);
	}
}