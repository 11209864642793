export const zIndexes = {
	//infoButton: 1,
	dropDown: 2,
	draggable: 10,
	//scrollBars: 10, // for ref; in react-vscrollview
	subNavBar: 11,
	actionBar: 11,
	navBar: 12, // since has dropdowns over sub-nav-bar/action-bar
	screenLight: 13,
	//messageBox: 15, // for ref; in Main.scss
};