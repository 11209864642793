import {AddSchema} from "mobx-firelink";
import {Assert} from "react-vextensions/Dist/Internals/FromJSVE";
import {GetRawKeyName} from "../../../Utils/General/Keys";
import {AlarmsPhase} from "../../../Engine/FBASession/Components/AlarmsComp.js";

export class TriggerSet {
	constructor(initialData: Partial<TriggerSet>) {
		//this.Extend({minTriggerInterval: 1, sequences: []});
		this.Extend({sequences: []});
		this.Extend(initialData);
	}

	_key?: string; // maybe temp; atm mobx-firelink provides this, even for not-at-top-level structures, but may not in future

	disabled?: true|n;
	minTriggerInterval: number; // in seconds
	sequences: Sequence[];
}
AddSchema("TriggerSet", {
	properties: {
		disabled: {type: ["null", "boolean"]},
		minTriggerInterval: {type: "number"},
		sequences: {items: {$ref: "Sequence"}},
	},
});

export const ScreenState_values = ["on", "off"] as const;
export type ScreenState = typeof ScreenState_values[number];
AddSchema("ScreenState", {
	oneOf: ScreenState_values.map(a=>({const: a})),
});

export class Sequence {
	constructor(items: SequenceItem[], otherProps?: Omit<Partial<Sequence>, "items">) {
		this.Extend(otherProps);
		this.items = items;
	}
	items: SequenceItem[];
	screenState: ScreenState|n;
	journeyPhases: AlarmsPhase[]|n;
}
AddSchema("Sequence", {
	properties: {
		items: {$ref: "SequenceItem"},
		screenState: {"oneOf": [{"type": "null"}, {$ref: "ScreenState"}]},
		journeyPhases: {"oneOf": [{"type": "null"}, {items: {$ref: "AlarmsPhase"}}]},
	},
});

// todo: rename these to be more clear
// Keys -> HaveKeysDown
// Key -> KeyPress?
// NoKey -> ?
export const TriggerType_values = [
	"KeyDown", // triggers on key first being downed/physically-depressed (unlike web keyDown event, which keeps triggering while held down) 
	"KeyUp", // triggers on key being released
	"Key", // triggers on ???
	"Keys", // triggers on all keys in list being down at once
	"KeyHold", // triggers after X ms of being held down
	"NoKey", // triggers after X ms of no key being pressed???

	"MouseDown", // triggers on mouse button being downed/physically-depressed
	"MouseUp", // triggers on mouse button being released
	"MouseClick", // triggers on mouse button being clicked (downed then released)???
	"MouseMove", // triggers on mouse being moved

	"MicLoudness", // triggers on mic loudness reaching a certain level???
	"ScreenChange", // triggers on screen turning on/off
	"PhoneMotion", // triggers on phone being moved
] as const;
export type TriggerType = typeof TriggerType_values[number];
AddSchema("TriggerType", {
	oneOf: TriggerType_values.map(a=>({const: a})),
});

export class SequenceItem {
	/*static GetTriggerType(item: SequenceItem) {
		if (item.key_name) return "Key";
		if (item.mouseClick) return "MouseClick";
		if (item.mouseMove) return "MouseMove";
		if (item.micLoudness_loudness != null) return "MicLoudness";
		return "NoKey";
	}*/

	constructor(initialData: Partial<SequenceItem>) {
		Object.assign(this, initialData);
	}

	type: TriggerType;

	// KeyDown, KeyUp, Key, Keys, KeyHold
	//key: boolean;
	key_name: string;
	key_extra: string; // when key_name is @KeyName or @KeyCode, this contains the actual key name/code (or comma-separated names/codes, if trigger-type is Keys)
	GetNormalKeyNames() {
		const keyNames = this.key_name == "@KeyName" ? this.key_extra.split(",").map(a=>a.trim()) : [this.key_name];
		if (this.type != "Keys") Assert(keyNames.length <= 1, "Only the Keys type can have more than one key-name specified!");
		return keyNames;
	}
	GetRawKeyNames() {
		return this.GetNormalKeyNames().map(a=>GetRawKeyName(a));
	}

	// NoKey
	noKey: number;

	// MouseDown, MouseUp, and MouseClick
	mouseDown: boolean;
	mouseUp: boolean;
	mouseClick: boolean;

	// MouseMove
	mouseMove: boolean;

	// MicLoudness
	//micLoudness: boolean;
	micLoudness_loudness: number;

	// ScreenChange
	screenChange_state: ScreenState;

	// PhoneMotion
	checkWindow: number; // in seconds (max is 10, since that's the buffer-limit we set on backend)
	minAverageMotion: number;
}
AddSchema("SequenceItem", {
	properties: {
		type: {$ref: "TriggerType"},

		key_name: {type: "string"},
		key_extra: {type: "string"},
		noKey: {type: "number"},

		mouseDown: {type: "boolean"},
		mouseUp: {type: "boolean"},
		mouseClick: {type: "boolean"},
		mouseMove: {type: "boolean"},

		micLoudness_loudness: {type: "number"},
		screenChange_state: {$ref: "ScreenState"},
		checkWindow: {type: "number"},
		minAverageMotion: {type: "number"},
	},
});

export function TriggerSet_ToString(set: TriggerSet) {
	if (set == null) return "";
	//return ToJSON(WithoutHelpers(triggerSet), null, 1).replace(/\n/g, "").replace(/{ /, "{"); // json-stringifying is temp for here anyway, so don't prettify
	//return ToJSON(WithoutHelpers(triggerSet));
	const sequenceStrings = set.sequences.map(sequence=>{
		const itemStrings = sequence.items.map(item=>{
			if (["KeyDown", "KeyUp", "Key", "Keys", "KeyHold"].includes(item.type)) {
				const keyStr = ["@KeyName", "@KeyCode"].includes(item.key_name) ? item.key_extra : item.key_name;
				return `${item.type}(${keyStr})`;
			}
			if (item.type == "NoKey") return `NoKey(${item.noKey})`;

			if (item.type == "MouseDown") return "MouseDown";
			if (item.type == "MouseUp") return "MouseUp";
			if (item.type == "MouseClick") return "MouseClick";
			if (item.type == "MouseMove") return "MouseMove";

			if (item.type == "MicLoudness") return `MicLoudness(${item.micLoudness_loudness})`;
			if (item.type == "ScreenChange") return `ScreenChange(${item.screenChange_state})`;
			if (item.type == "PhoneMotion") return `PhoneMotion(${item.checkWindow}s, ${item.minAverageMotion})`;

			return null; // shouldn't happen
		});
		let result = "";
		if (sequence.screenState) result += `[Screen(${sequence.screenState})] `;
		if (sequence.journeyPhases?.length) result += `[JPhases(${sequence.journeyPhases.map(a=>AlarmsPhase[a]).join(",")})] `;
		result += itemStrings.join(" + ");
		return result;
	});
	return [
		set.disabled && "[disabled]",
		sequenceStrings.join(" OR "),
	].filter(a=>a).join(" ");
}

export function NewSequenceItemForType(type: TriggerType) {
	const newData: Partial<SequenceItem> = {type};
	
	if (type == "KeyDown") newData.VSet({key_name: "VolumeUp"});
	else if (type == "KeyUp") newData.VSet({key_name: "VolumeUp"});
	else if (type == "Key") newData.VSet({key_name: "VolumeUp"});
	else if (type == "Keys") newData.VSet({key_name: "@KeyName", key_extra: "VolumeDown,VolumeUp"});
	else if (type == "KeyHold") newData.VSet({key_name: "VolumeUp"});
	else if (type == "NoKey") newData.VSet({noKey: 1});

	else if (type == "MouseDown") newData.VSet({mouseDown: true});
	else if (type == "MouseUp") newData.VSet({mouseUp: true});
	else if (type == "MouseClick") newData.VSet({mouseClick: true});
	else if (type == "MouseMove") newData.VSet({mouseMove: true});

	else if (type == "MicLoudness") newData.VSet({micLoudness_loudness: .5});
	else if (type == "ScreenChange") newData.VSet({screenChange_state: "on"});
	else if (type == "PhoneMotion") newData.VSet({checkWindow: 1, minAverageMotion: 1});

	return new SequenceItem(newData);
}