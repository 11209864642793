import {InfoButton, Observer, RunInAction_Set} from "web-vcore";
import {StartDownload} from "js-vextensions";
import moment from "moment";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Row, Spinner, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {JournalEntry} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../../Store/index.js";
import {zIndexes} from "../../../Utils/UI/ZIndexes.js";
import {GetEntriesToShow_EntryList} from "../EntryList.js";

@Observer
export class EntryListSettingsDropdown extends BaseComponent<{entries: JournalEntry[]}, {}> {
	render() {
		let {entries} = this.props;
		const uiState = store.main.journal;
		const filter = uiState.filter;
		const entriesToShow = GetEntriesToShow_EntryList(entries);

		return (
			<DropDown style={{marginLeft: 3}}>
				<DropDownTrigger>
					<Button mdIcon="cog" size={32}/>
				</DropDownTrigger>
				<DropDownContent style={{zIndex: zIndexes.dropDown, right: 0, width: 350, background: "rgba(0,0,0,.9)", borderRadius: 5}}><Column>
					<Row>Entries, total: {entries.length} Filtered: {entriesToShow.length}</Row>
					<Row>
						<Button text="Export all" onClick={()=>{
							const output = JSON.stringify(entries.map(a=>({...a, _id: a._key})), null, "\t");
							StartDownload(output, `JournalExport_${moment().format("YYYY-MM-DD-HH-mm-ss")}_Count${entries.length}.json`);
						}}/>
					</Row>
					<Row mt={3} style={{fontWeight: "bold"}}>Filtering</Row>
					<CheckBox mt={3} text="Filter within journal-entry" value={filter.filterWithinJournalEntry} onChange={val=>RunInAction_Set(this, ()=>filter.filterWithinJournalEntry = val)}/>
					<Row>
						<CheckBox mt={3} text="Only integratable" value={filter.showOnlyIntegratable} onChange={val=>RunInAction_Set(this, ()=>filter.showOnlyIntegratable = val)}/>
						<InfoButton ml={5} text={`Only show segments that are not marked as "No integration".`}/>
					</Row>
					<Text mt={3}>Segment-level filters:</Text>
					<Column ml={10} mt={3}>
						<Row>
							<CheckBox text="Dream events, min:" value={filter.dreamEvents_enabled} onChange={val=>RunInAction_Set(this, ()=>filter.dreamEvents_enabled = val)}/>
							<Spinner ml={5} min={-1} value={filter.dreamEvents_min} onChange={val=>RunInAction_Set(this, ()=>filter.dreamEvents_min = val)}/>
							<Text ml={5}>Max:</Text>
							<Spinner ml={5} min={-1} value={filter.dreamEvents_max} onChange={val=>RunInAction_Set(this, ()=>filter.dreamEvents_max = val)}/>
						</Row>
						<Row mt={3}>
							<CheckBox text="Words, min:" value={filter.words_enabled} onChange={val=>RunInAction_Set(this, ()=>filter.words_enabled = val)}/>
							<Spinner ml={5} min={-1} value={filter.words_min} onChange={val=>RunInAction_Set(this, ()=>filter.words_min = val)}/>
							<Text ml={5}>Max:</Text>
							<Spinner ml={5} min={-1} value={filter.words_max} onChange={val=>RunInAction_Set(this, ()=>filter.words_max = val)}/>
						</Row>
					</Column>
				</Column></DropDownContent>
			</DropDown>
		);
	}
}