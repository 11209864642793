import {P, TextSpeaker} from "web-vcore";
import {FBAConfig_StoryExplore} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_StoryExplore.js";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {CategorizeLine, GenerateNextMessage, GenerateNextMessageOptions, GetMessageInfo} from "../../../Utils/Services/Anthropic.js";
import {GetUserEntityTags, MeID} from "../../../Store/firebase/users.js";
import {Assert, CE, E, SleepAsync} from "js-vextensions";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {GetJournalEntries, SortJournalEntries} from "../../../Store/firebase/journalEntries.js";
import {GetEntity} from "../../../Store/firebase/entities.js";
import {FindMessagesInChain, StoryMessage, StoryMessageProto} from "../../../Store/firebase/storyMessages.js";
import {AddStoryMessage} from "../../../Server/Commands/AddStoryMessage.js";
import {GetStory, Story} from "../../../Store/firebase/stories.js";
import {AddStory} from "../../../Server/Commands/AddStory.js";
import {GetAsync} from "mobx-firelink";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";

export function GetAllTextSegmentsInStorylines(messages: StoryMessageProto[]) {
	return GetAllTextSegmentsInStorylineTexts(messages.map(a=>a.content));
}
//export const GetAllTextSegmentsInStorylineTexts = StoreAccessor(s=>(messageTexts: string[])=>{
export const GetAllTextSegmentsInStorylineTexts = (messageTexts: string[])=>{
	const textSegments = [] as string[];
	const textSegments_messageIndexes = [] as number[];
	for (const [messageIndex, messageText] of messageTexts.entries()) {
		const segments = messageText.split("\n");
		for (const [segmentIndex, segment] of segments.entries()) {
			// exclude any segment if it is empty, *unless* it is the only segment of a given message
			// (reason: a single empty segment presumably means it's a message that is still being populated, and we want a "slot" to target)
			if (segment.trim().length == 0 && segments.length > 1) continue;

			textSegments.push(segment);
			textSegments_messageIndexes.push(messageIndex);
		}
	}
	return [textSegments, textSegments_messageIndexes] as const;
};

export class StoryExploreComp extends EngineSessionComp<FBAConfig_StoryExplore> {
	constructor(session: FBASession, config: FBAConfig_StoryExplore) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("StoryExplore_PrevText", this.c.prevText_triggerSet, this, {}, async triggerInfo=>{
				this.PrevText();
			}),
			new TriggerPackage("StoryExplore_NextText", this.c.nextText_triggerSet, this, {}, async triggerInfo=>{
				this.NextText();
			}),
			new TriggerPackage("StoryExplore_SelectText", this.c.selectText_triggerSet, this, {}, async triggerInfo=>{
				this.SelectText();
			}),
			new TriggerPackage("StoryExplore_StopVoice", this.c.stopVoice_triggerSet, this, {}, async triggerInfo=>{
				this.StopTextSpeaker();
			}),
			new TriggerPackage("StoryExplore_NewStory", this.c.newStory_triggerSet, this, {}, async triggerInfo=>{
				this.NewStory();
			}),
		];
	}

	OnStart() {}
	OnStop() {
		this.StopTextSpeaker();
	}

	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}

	textSpeaker = new TextSpeaker();

	async LoadStoryForChain(story: Story, messages: StoryMessage[], targetMessage: StoryMessage) {
		const messageChain = FindMessagesInChain(messages, targetMessage);
		await this.LoadStory(story, messageChain);
	}
	async LoadStory(story: Story, messageChain: StoryMessage[]) {
		this.story = story;
		this.storyProtoMessages = messageChain.map(a=>new StoryMessageProto({
			role: a.role,
			content: a.content,
			isSummary: a.summary_chainEnd != null,
			uploadedId: a._key,
		}));
		this.UpdateCurrentStoryMessageTextSegments();
		this.storyProtoMessages_textSegments_index = this.GetIndexOfFirstTextSegmentFromMessage(this.storyProtoMessages.Last())
		this.SpeakCurrentText();
	}

	story: Story|n;
	storyProtoMessages = [] as StoryMessageProto[];
	storyProtoMessages_textSegments = [] as string[];
	storyProtoMessages_textSegments_messageIndexes = [] as number[];
	storyProtoMessages_textSegments_index = -1;
	UpdateCurrentStoryMessageTextSegments() {
		const [textSegments, textSegments_messageIndexes] = GetAllTextSegmentsInStorylines(this.storyProtoMessages);
		this.storyProtoMessages_textSegments = textSegments;
		this.storyProtoMessages_textSegments_messageIndexes = textSegments_messageIndexes;
	}
	GetIndexOfFirstTextSegmentFromMessage(message: StoryMessageProto) {
		const messageIndex = this.storyProtoMessages.indexOf(message);
		Assert(messageIndex != -1, "Message not found in currentStoryMessages.");
		return this.storyProtoMessages_textSegments_messageIndexes.findIndex(a=>a == messageIndex);
	}
	GetCurrentSegment(clean: boolean) {
		let result = this.storyProtoMessages_textSegments[this.storyProtoMessages_textSegments_index] as string|n;
		if (result != null && clean) {
			const dynamicFocusChoiceRegex = new RegExp(`((${this.c.dynamicListNumbers.join("|")}).{1,3})Focus( Protagonist| them| him| her)? on(\\.\\.\\.)? ?`, "gi");
			result = result
				.replace(/\*/g, "")
				.replace(dynamicFocusChoiceRegex, "$1");
		}
		return result;
	}
	GetCurrentMessageIndex() {
		return this.storyProtoMessages_textSegments_messageIndexes[this.storyProtoMessages_textSegments_index] as number|n;
	}
	GetCurrentMessage(offset?: number): StoryMessageProto|n {
		let messageIndex = this.GetCurrentMessageIndex();
		if (messageIndex == null) return null;
		return this.storyProtoMessages[messageIndex + (offset ?? 0)];
	}
	GetCurrentMessageSegments() {
		return this.GetCurrentMessage()?.content.split("\n") ?? [];
	}

	PrevText() {
		const oldIndex = this.storyProtoMessages_textSegments_index;
		// search backward till a valid new "current segment" is found
		for (let offset = -1; (oldIndex + offset) >= 0; offset--) {
			const newIndex = oldIndex + offset;
			const newMessageIndex = this.storyProtoMessages_textSegments_messageIndexes[newIndex];
			const newMessage = this.storyProtoMessages[newMessageIndex];

			// if we landed a new valid segment, set it as the new "current segment"
			if (newMessage.role == "assistant") {
				this.storyProtoMessages_textSegments_index = newIndex;
				this.SpeakCurrentText();
				return;
			}
		}
	}
	NextText() {
		const oldIndex = this.storyProtoMessages_textSegments_index;
		// search forward till a valid new "current segment" is found
		for (let offset = 1; (oldIndex + offset) <= this.storyProtoMessages_textSegments.length - 1; offset++) {
			const newIndex = oldIndex + offset;
			const newMessageIndex = this.storyProtoMessages_textSegments_messageIndexes[newIndex];
			const newMessage = this.storyProtoMessages[newMessageIndex];

			// if we landed a new valid segment, set it as the new "current segment"
			if (newMessage.role == "assistant") {
				this.storyProtoMessages_textSegments_index = newIndex;
				this.SpeakCurrentText();
				return;
			}
		}
	}
	async SelectText() {
		const currentMessageIndex = this.GetCurrentMessageIndex();
		const currentMessage = this.GetCurrentMessage();
		const segmentText = this.GetCurrentSegment(true);
		if (currentMessageIndex == null || currentMessage == null || segmentText == null) return;

		const segmentInfo = CategorizeLine(segmentText)
		if (segmentInfo.listNumber != null) {
			this.SetLatestStoryInput(currentMessageIndex, segmentInfo.listNumber.toString(), true, newMessage_firstSegmentIndex=>{
				if (this.storyProtoMessages_textSegments_index != newMessage_firstSegmentIndex) return; // if user has already moved away from the current segment, don't voice it
				this.SpeakCurrentText();
			});
			return;
		}

		// the current segment didn't match any number-choice patterns; check if the list of choices was present; if not, prompt the LLM to present the list
		//const listWasPresented = this.GetCurrentMessageSegments().filter(a=>CategorizeLine(a).listNumber != null).length >= this.c.minNumberedListSize; // require at least X lines to be recognized as numbered-list entries
		const info = GetMessageInfo(currentMessage, this.c.minNumberedListSize);
		if (!info.hasNumberedList) {
			this.SetLatestStoryInput(currentMessageIndex, "I need a full numbered list in order to enter my choice.", true, newMessage_firstSegmentIndex=>{
				if (this.storyProtoMessages_textSegments_index != newMessage_firstSegmentIndex) return; // if user has already moved away from the current segment, don't voice it
				this.SpeakCurrentText();
			});
		}
	}
	async NewStory() {
		const journalEntries = GetJournalEntries(MeID());
		const sortedJournalEntries = SortJournalEntries(journalEntries, true);
		const journalEntrySegments = sortedJournalEntries.SelectMany(a=>a.segments);
		const lastSegmentWithAnchor = journalEntrySegments.LastOrX(a=>a.anchorEntity != null);
		const lastAnchorEntity = GetEntity(lastSegmentWithAnchor?.anchorEntity);
		const lastAnchorEntityName = lastAnchorEntity?.name ?? "unknown";

		const storyStartPrompt_final = this.c.startPrompt.replace(/\$lastAnchorEntityName/g, lastAnchorEntityName);
		this.storyProtoMessages_textSegments_index = -1;
		this.SetLatestStoryInput(-1, storyStartPrompt_final, true, newMessage_firstSegmentIndex=>{
			if (this.storyProtoMessages_textSegments_index != newMessage_firstSegmentIndex) return; // if user has already moved away from the current segment, don't voice it
			this.SpeakCurrentText();
		});
	}

	SpeakCurrentText() {
		const segment_cleaned = this.GetCurrentSegment(true);
		if (segment_cleaned == null) return;
		this.NarrateText(segment_cleaned);
	}
	async SetLatestStoryInput(prevMessageIndex: number, inputText: string, storeToDb: boolean, onFirstSegmentReady?: (firstSegmentIndex: number)=>void, useSystemPrompt = true) {
		let onFirstSegmentReady_called = false;
		const callOnFirstSegmentReady_ifNotAlready = (firstSegmentIndex: number)=>{
			if (onFirstSegmentReady_called) return;
			onFirstSegmentReady_called = true;
			onFirstSegmentReady?.(firstSegmentIndex);
		};
		
		this.storyProtoMessages = this.storyProtoMessages.slice(0, prevMessageIndex + 1);
		const messagesBeforeInputMessage = this.storyProtoMessages.slice();

		const userInputMessage = new StoryMessageProto({role: "user", content: inputText});
		this.storyProtoMessages.push(userInputMessage);
		const messagesBeforeResponseMessage = this.storyProtoMessages.slice();
		
		const responseMessage = new StoryMessageProto({role: "assistant", content: "", partial: true});
		this.storyProtoMessages.push(responseMessage);
		this.UpdateCurrentStoryMessageTextSegments();
		//const responseMessageIndex = this.currentStoryMessages.length - 1;
		const responseMessage_firstSegment_index = this.GetIndexOfFirstTextSegmentFromMessage(responseMessage);
		this.storyProtoMessages_textSegments_index = responseMessage_firstSegment_index;

		const options = new GenerateNextMessageOptions({
			...CE(this.c).IncludeKeys("minNumberedListSize", "dynamicListNumbers", "llmHistory_keepLastXNumberedLists", "temperature"),
			useSystemPrompt,
		});
		await GenerateNextMessage(messagesBeforeResponseMessage, options, nextTextSegment=>{
			responseMessage.content += nextTextSegment;
			this.UpdateCurrentStoryMessageTextSegments();
			if (responseMessage.content.includes("\n")) {
				//this.SpeakCurrentText();
				callOnFirstSegmentReady_ifNotAlready?.(responseMessage_firstSegment_index);
			}
		});
		callOnFirstSegmentReady_ifNotAlready?.(responseMessage_firstSegment_index);
		SessionLog(`Sent input: ${userInputMessage.content}`);
		SessionLog(`Generated new story message: ${responseMessage.content}`);

		if (storeToDb) {
			// mobx-firelink's Command.Run() function can stall forever in some cases; until core issue fixed (with standard timeout), use this workaround
			let raceResult = await Promise.race([
				SleepAsync(5000).then(()=>false),
				(async()=>{
					// if no story entry has been created in db, or if user ran the "NewStory" command, create a new story entry (and set it as the current)
					if (this.story == null || prevMessageIndex == -1) {
						const storyId = await new AddStory({story: new Story()}).Run();
						this.story = await GetAsync(()=>GetStory(storyId));
						SessionLog(`Uploaded new story: ${storyId}`);
					}
					
					// now try to upload the two new messages
					const userInputMessage_final_id = await new AddStoryMessage({message: new StoryMessage({
						// proto data
						role: userInputMessage.role,
						content: userInputMessage.content,
						// extra data
						story: this.story!._key,
						parent: messagesBeforeInputMessage.LastOrX()?.uploadedId,
					})}).Run();
					userInputMessage.uploadedId = userInputMessage_final_id;
					const responseMessage_final_id = await new AddStoryMessage({message: new StoryMessage({
						// proto data
						role: responseMessage.role,
						content: responseMessage.content,
						// extra data
						story: this.story!._key,
						parent: userInputMessage_final_id,
					})}).Run();
					responseMessage.uploadedId = responseMessage_final_id;
					SessionLog(`Uploaded new story messages: ${userInputMessage_final_id}, ${responseMessage_final_id}`);
					return true;
				})()
			]);
			if (raceResult == false) {
				SessionLog(`Failed to upload new story messages within 5 seconds.`, LogType.Event_Large);
				this.NarrateText("Error uploading new story messages. App restart probably needed.");
			}
		}

		return responseMessage_firstSegment_index;
		/*if (this.currentStoryMessages_textSegments_index == responseMessage_firstSegment_index) {
			this.SpeakCurrentText();
		}*/
	}

	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}
}