import {Assert, E, IsNaN, Timer, ToJSON, ToNumber} from "js-vextensions";
import {AppendSessionHeadbandGyroSamplesForSecond, AppendSessionGyroMotion} from "../../../Utils/Bridge/Bridge_Preload.js";
import {Text} from "react-vcomponents";
import {GyroProcessor} from "../../../UI/@Shared/Processors/GyroProcessor.js";
import {GyroMotionProcessor} from "../../../UI/@Shared/Processors/GyroMotionProcessor.js";
import {FBAConfig_Gyro} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Gyro.js";
import {currentMuse} from "../../../UI/Tools/@Shared/MuseInterface.js";
import {GyroSample, Muse_gyroSamplesPerSecond_raw} from "../../../UI/Tools/@Shared/MuseInterface/GyroStructs.js";
import {FBASession} from "../../../Engine/FBASession.js";
import {EEGComp} from "./EEGComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogEntry, LogType} from "../../../UI/Tools/@Shared/LogEntry.js";

export class GyroComp extends EngineSessionComp<FBAConfig_Gyro> {
	constructor(session: FBASession, config: FBAConfig_Gyro) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
		this.InitProcessors();
	}

	GetStatusUI() {
		const triggerSamplePercent = this.gyroProcessor.samples.LastOrX()?.triggerSamplePercent;
		return <Text style={{whiteSpace: "pre"}}>{`
			Motion triggering: ${triggerSamplePercent != null ? triggerSamplePercent >= this.c.motion_motionTrigger_minTriggerSamplePercent : "n/a"}
		`.AsMultiline(1)}</Text>;
	}

	OnStart_Early() {
		currentMuse.EnsureDeviceObtained();
	}
	OnStart() {
		currentMuse.gyroListeners.push(this.gyroListener);
		currentMuse.AddConnectRequester(this);
	}
	gyroListener = (sample: GyroSample)=>{
		if (this.c.useSampleRounding) {
			sample = E(sample, {
				x: Math.round(sample.x),
				y: Math.round(sample.y),
				z: Math.round(sample.z),
			});
		}
		this.gyroProcessor.AddAndProcessGyroSample(sample);
		this.gyroMotionProcessor.RespondToSample(this.gyroProcessor.samples.length - 1);
	};
	OnStop() {
		// commit any uncommitted log-entries
		this.currentMotionTrigger_log = null;

		currentMuse.gyroListeners.Remove(this.gyroListener);
		currentMuse.RemoveConnectRequester(this);
	}

	gyroProcessor: GyroProcessor;
	gyroMotionProcessor: GyroMotionProcessor;
	InitProcessors() {
		this.gyroProcessor = new GyroProcessor({options: E(this.c, {
			samplesProcessedPerSecond: Muse_gyroSamplesPerSecond_raw,
			calc_normalize: true,
			calc_smooth: true,
			calc_combinedDeviation: true,
			calc_triggerSamplePercent: true,

			// GyroComp doesn't use uplotData, so set true (so customized arrays aren't created)
			uplotData_normalize: true,
			uplotData_smooth: true,

			clearOutOfRangeSamples: true,
		})});
		this.gyroMotionProcessor = new GyroMotionProcessor({
			gyroProcessor: this.gyroProcessor,
			postRespondToNewSample: (sample, index, motionTriggering)=>{
				//const sampleTime = sample.time_uplot_raw * 1000;
				const triggerSamplePercent_fraction = this.gyroProcessor.samples[index].triggerSamplePercent / 100; // sample's prop is as percentage, for display purposes

				if (motionTriggering) {
					function GetMotionTriggerLogMessage(triggerSamplePercent_peak: number) {
						return `Gyro motion detected. @triggerSamplePercent_peak:${triggerSamplePercent_peak.ToPercentStr(0)}`;
					}
					const logEntry = this.currentMotionTrigger_log;
					if (logEntry) {
						logEntry.VSet("triggerSamplePercent_peak", // store non-enumerably, avoiding schema reject
							Math.max(triggerSamplePercent_fraction, ToNumber(logEntry["triggerSamplePercent_peak"], 0)));
						logEntry.message = GetMotionTriggerLogMessage(logEntry["triggerSamplePercent_peak"]);
					}
				} else {
					this.currentMotionTrigger_log = null;
				}

				// if camera-comp is supposed to record periods of gyro-motion, notify it when the specified conditions are met
				/*const camComp = this.s.Comp(CameraComp);
				if (camComp.behaviorEnabled && camComp.c.eegMotion_record && this.eegActivityProcessor.EEGActivity >= camComp.c.eegMotion_minActivity) {
					camComp.OnMotionTrigger();
				}*/
			},
			postSamplesBySecondEntryCompleted: (secondTime, secondSamples)=>{
				// record each second's samples to disk once it's over
				if (this.c.recordData_raw) {
					AppendSessionHeadbandGyroSamplesForSecond(this.s.folderName, secondTime, secondSamples);
				}
			},
			postMotionTrigger: sampleTime=>{
				this.currentMotionTrigger_log = SessionLog("Gyro motion detected. [details pending]", LogType.Event_Small, {blockAutoCommit: true});
				//this.currentMotionTrigger_log.VSet("eegActivity", this.eegActivityProcessor.EEGActivity); // store non-enumerably, avoiding schema reject

				// store gyro motion-trigger events (could be derived, but they're visible in seek-bar, so we want them cached)
				if (this.c.recordData_motions) {
					AppendSessionGyroMotion(this.s.folderName, sampleTime);
				}
			},
		});
	}

	_currentMotionTrigger_log: LogEntry|n;
	get currentMotionTrigger_log() { return this._currentMotionTrigger_log; }
	set currentMotionTrigger_log(val: LogEntry|n) {
		if (this._currentMotionTrigger_log && this._currentMotionTrigger_log != val) {
			this._currentMotionTrigger_log?.Commit();
		}
		this._currentMotionTrigger_log = val;
	}

	// maybe temp; used by some scripters atm
	get lastMotionTriggerTime() {
		return this.gyroMotionProcessor.motionTriggerTimes.LastOrX(undefined, 0);
	}
}