import {Button, CheckBox, Column, Pre, Row, RowLR, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {store} from "../../../Store/index.js";
import {ES} from "../../../Utils/UI/GlobalStyles";
import {InfoButton, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {BaseComponent} from "react-vextensions";
import React, {useEffect} from "react";
import {ScrollView} from "react-vscrollview";
import {RootUIWrapper} from "../../../UI/Root.js";
import {nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {ModifyString, SleepAsync} from "js-vextensions";
import {onCurrentDirChanged_listeners, RunLD1ConsoleInput, SendCommandToLD1} from "./LD1Panel.js";
import {SendLD1ConfigToDevice} from "../../../Utils/AutoRuns/SendLD1ConfigToDevice.js";

// Called from arduino. (through "OnLD1LogReceived" handler, in LD1Panel.tsx)
export function StoreMagValueAverageForDirection(direction: string, value: number) {
	const uiState = store.main.tools.monitor.ld1;
	RunInAction_Set(this, ()=>uiState.config1["magValFor" + ModifyString(direction, m=>[m.startLower_to_upper])] = value);
}
type Direction = "north" | "east" | "south" | "west";
function StartMagValueAveragingForDirection(direction: Direction, duration: number) {
	SendCommandToLD1({startMagValueAveragingForDirection: {direction, duration}});
}

// Called from arduino. (through "OnLD1LogReceived" handler, in LD1Panel.tsx)
export function StoreMagRangeValues(xMin: number, xMax: number, yMin: number, yMax: number, zMin: number, zMax: number) {
	const uiState = store.main.tools.monitor.ld1;
	RunInAction("StoreMagRangeValues", ()=>{
		const c = uiState.config1;
		if (uiState.magRangeCollecting_expandOnly) {
			c.magRanges_xMin = Math.min(c.magRanges_xMin, xMin);
			c.magRanges_xMax = Math.max(c.magRanges_xMax, xMax);
			c.magRanges_yMin = Math.min(c.magRanges_yMin, yMin);
			c.magRanges_yMax = Math.max(c.magRanges_yMax, yMax);
			c.magRanges_zMin = Math.min(c.magRanges_zMin, zMin);
			c.magRanges_zMax = Math.max(c.magRanges_zMax, zMax);
		} else {
			c.magRanges_xMin = xMin;
			c.magRanges_xMax = xMax;
			c.magRanges_yMin = yMin;
			c.magRanges_yMax = yMax;
			c.magRanges_zMin = zMin;
			c.magRanges_zMax = zMax;
		}
	});
}

async function TempVibrateMotor(pin: number, strength = 1, duration = 1000) {
	const uiState = store.main.tools.monitor.ld1;
	SendCommandToLD1({setMotorPower: {motor: pin, power: strength, period: uiState.motor_period}});
	await SleepAsync(duration);
	SendCommandToLD1({setMotorPower: {motor: pin, power: 0, period: uiState.motor_period}});
}

@Observer
export class LD1ConfigPanel extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.monitor.ld1;

		const [dir, setDir] = React.useState(0);
		useEffect(()=>{
			let listener = (currentDir: number)=>setDir(currentDir);
			onCurrentDirChanged_listeners.push(listener);
			return ()=>void onCurrentDirChanged_listeners.Remove(listener);
		});

		const [magRangeCollecting, setMagRangeCollecting] = React.useState(false);
		
		const splitAt = 50;
		return (
			<ScrollView contentStyle={{padding: 5}}>
				<Row style={{fontSize: 18}}>Calibration</Row>

				<Pre mt={10} allowWrap={true} style={{fontSize: 12}}>{`
					Steps:
					1) If not done before, a "base mag. calibration" can help: connect device, press "Start collecting", rotate thoroughly in all directions (incl. vertical), then stop.
					2) Place LD1 device in your chosen "standard placement" on body (see readme for recommendations), and ensure it's connected in the Lucid Frontier app.
					3) Install a compass app, supporting your choice of either magnetic north or true/geographic north. ("Digital Compass" by Axiomatic Inc supports both)
					4) Launch the compass app, and observe what direction is north; stand and face it, holding your position.
					5) Switch back to Lucid Frontier; next to the "North" input-box, press a "use current, avg over" button (eg. 7s), then wait X seconds (till the value is assigned).
					6) Repeat substeps 4-5 for east, south, and west; this corrects for interference between earth's magnetic field and nearby components, for each direction faced.
				`.AsMultiline(0)}</Pre>

				<Row mt={10}>
					<Text>Base magnetometer calibration:</Text>
					<Text ml={5} style={{fontSize: 12}}>(minimums and maximums along each axis, for raw magnetometer readings)</Text>
				</Row>
				<Column style={{background: "rgba(255,255,255,.2)", padding: 5}}>
					<Row mb={5}>
						<Button text={magRangeCollecting ? "Stop collecting" : "Start collecting"} onClick={()=>{
							const newCollecting = !magRangeCollecting;
							setMagRangeCollecting(newCollecting);
							if (newCollecting) {
								//SendCommandToLD1({startMagRangeCollecting: {expandOnly: uiState.magRangeCollecting_expandOnly}});
								SendCommandToLD1({startMagRangeCollecting: true});
							} else {
								SendCommandToLD1({stopMagRangeCollecting: true});
							}
						}}/>
						<CheckBox ml={5} text="Expand only" value={uiState.magRangeCollecting_expandOnly} onChange={val=>RunInAction_Set(this, ()=>uiState.magRangeCollecting_expandOnly = val)}/>
					</Row>
					<RowLR splitAt={splitAt}>
						<Text>X, min:</Text>
						<Spinner ml={5} min={Number.MIN_SAFE_INTEGER} step={.001} value={uiState.config1.magRanges_xMin} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magRanges_xMin = val)}/>
						<Text ml={5}>max:</Text>
						<Spinner ml={5} min={Number.MIN_SAFE_INTEGER} step={.001} value={uiState.config1.magRanges_xMax} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magRanges_xMax = val)}/>
					</RowLR>
					<RowLR splitAt={splitAt}>
						<Text>Y, min:</Text>
						<Spinner ml={5} min={Number.MIN_SAFE_INTEGER} step={.001} value={uiState.config1.magRanges_yMin} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magRanges_yMin = val)}/>
						<Text ml={5}>max:</Text>
						<Spinner ml={5} min={Number.MIN_SAFE_INTEGER} step={.001} value={uiState.config1.magRanges_yMax} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magRanges_yMax = val)}/>
					</RowLR>
					<RowLR splitAt={splitAt}>
						<Text>Z, min:</Text>
						<Spinner ml={5} min={Number.MIN_SAFE_INTEGER} step={.001} value={uiState.config1.magRanges_zMin} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magRanges_zMin = val)}/>
						<Text ml={5}>max:</Text>
						<Spinner ml={5} min={Number.MIN_SAFE_INTEGER} step={.001} value={uiState.config1.magRanges_zMax} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magRanges_zMax = val)}/>
					</RowLR>
				</Column>

				<Row mt={10}>
					<Text>Direction mappings:</Text>
					<Text ml={5} style={{fontSize: 12}}>(raw magnetometer values found for the four main facing directions)</Text>
				</Row>
				<Column style={{background: "rgba(255,255,255,.2)", padding: 5}}>
					<Row>
						<Text>Current magnetometer value: {dir}</Text>
					</Row>
					<RowLR splitAt={splitAt}>
						<Text>North:</Text>
						<Spinner ml={5} min={-360} max={360} value={uiState.config1.magValForNorth} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magValForNorth = val)}/>
						<Text ml={5}>Use current, avg over:</Text>
						<Button ml={3} p="0px 5px" text="1s" onClick={()=>StartMagValueAveragingForDirection("north", 1000)}/>
						<Button ml={3} p="0px 5px" text="3s" onClick={()=>StartMagValueAveragingForDirection("north", 3000)}/>
						<Button ml={3} p="0px 5px" text="7s" onClick={()=>StartMagValueAveragingForDirection("north", 7000)}/>
					</RowLR>
					<RowLR splitAt={splitAt}>
						<Text>East:</Text>
						<Spinner ml={5} min={-360} max={360} value={uiState.config1.magValForEast} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magValForEast = val)}/>
						<Text ml={5}>Use current, avg over:</Text>
						<Button ml={3} p="0px 5px" text="1s" onClick={()=>StartMagValueAveragingForDirection("east", 1000)}/>
						<Button ml={3} p="0px 5px" text="3s" onClick={()=>StartMagValueAveragingForDirection("east", 3000)}/>
						<Button ml={3} p="0px 5px" text="7s" onClick={()=>StartMagValueAveragingForDirection("east", 7000)}/>
					</RowLR>
					<RowLR splitAt={splitAt}>
						<Text>South:</Text>
						<Spinner ml={5} min={-360} max={360} value={uiState.config1.magValForSouth} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magValForSouth = val)}/>
						<Text ml={5}>Use current, avg over:</Text>
						<Button ml={3} p="0px 5px" text="1s" onClick={()=>StartMagValueAveragingForDirection("south", 1000)}/>
						<Button ml={3} p="0px 5px" text="3s" onClick={()=>StartMagValueAveragingForDirection("south", 3000)}/>
						<Button ml={3} p="0px 5px" text="7s" onClick={()=>StartMagValueAveragingForDirection("south", 7000)}/>
					</RowLR>
					<RowLR splitAt={splitAt}>
						<Text>West:</Text>
						<Spinner ml={5} min={-360} max={360} value={uiState.config1.magValForWest} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.magValForWest = val)}/>
						<Text ml={5}>Use current, avg over:</Text>
						<Button ml={3} p="0px 5px" text="1s" onClick={()=>StartMagValueAveragingForDirection("west", 1000)}/>
						<Button ml={3} p="0px 5px" text="3s" onClick={()=>StartMagValueAveragingForDirection("west", 3000)}/>
						<Button ml={3} p="0px 5px" text="7s" onClick={()=>StartMagValueAveragingForDirection("west", 7000)}/>
					</RowLR>
				</Column>
				
				<Row mt={10}>
					<Text>Additional offset:</Text>
					<InfoButton ml={5} text={`
						Effect: At runtime, read all direction-mapping values above as though they were X higher.
						Purpose: To quickly adjust for a change to the placement/rotation of device on the body.
					`.AsMultiline(0)}/>
					<Spinner ml={5} min={-360} max={360} value={uiState.config1.additionalMagTargetOffset} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.additionalMagTargetOffset = val)}/>
				</Row>

				<Row mt={10} style={{fontSize: 18}}>General options</Row>
				<Row mt={5}>
					<Text>Log:</Text>
					<CheckBox ml={10} text="Sensor readings" value={uiState.config1.logSensorReadings} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.logSensorReadings = val)}/>
					<CheckBox ml={10} text="Direction" value={uiState.config1.logDirection} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.logDirection = val)}/>
				</Row>
				<Row mt={5}>
					<Text>Vibrate strength:</Text>
					<Spinner ml={5} min={0} enforceRange={true} style={{width: 50}}
						value={uiState.config1.vibrateStrength.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.vibrateStrength = val.FromPercent())}/>
					<Text>%</Text>
				</Row>
				<Row mt={5}>
					<Text>Min turn amount for vibrate:</Text>
					<Spinner ml={5} value={uiState.config1.minTurnAmountForVibrate} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.minTurnAmountForVibrate = val)}/>
					<Text ml={5}>degrees</Text>
				</Row>
				<Row mt={5}>
					<Text>Max angle from vertical for vibrate:</Text>
					<Spinner ml={5} value={uiState.config1.maxAngleFromVerticalForVibrate} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.maxAngleFromVerticalForVibrate = val)}/>
					<Text ml={5}>degrees</Text>
				</Row>
				<Row mt={5}>
					<Text>Min time since bad angle for vibrate:</Text>
					<Spinner ml={5} value={uiState.config1.minTimeSinceBadAngleForVibrate} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.minTimeSinceBadAngleForVibrate = val)}/>
					<Text ml={5}>ms</Text>
				</Row>
				<Row mt={5}>
					<Text>Average heading over X milliseconds:</Text>
					<Spinner ml={5} step={50} value={uiState.config1.averageHeadingOverXMilliseconds} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.averageHeadingOverXMilliseconds = val)}/>
					<Text ml={5}>ms</Text>
				</Row>
				<Row mt={5}>
					<Text>Use board lights:</Text>
					<CheckBox ml={5} value={uiState.config1.useBoardLights} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.useBoardLights = val)}/>
				</Row>

				<Row mt={10} style={{fontSize: 18}}>Motor pins</Row>
				<RowLR splitAt={splitAt}>
					<Text>Front:</Text>
					<Spinner ml={5} min={0} max={21} value={uiState.config1.motorPin_front} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.motorPin_front = val)}/>
					<Text ml={5}>Vibrate multiplier:</Text>
					<Spinner ml={5} min={0} enforceRange={true} style={{width: 50}}
						value={uiState.config1.vibrateMultiplier_front.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.vibrateMultiplier_front = val.FromPercent())}/>
					<Text ml={3}>%</Text>
					<Button ml={5} text="Test" onClick={()=>TempVibrateMotor(uiState.config1.motorPin_front, uiState.config1.vibrateStrength * uiState.config1.vibrateMultiplier_front)}/>
				</RowLR>
				<RowLR splitAt={splitAt}>
					<Text>Right:</Text>
					<Spinner ml={5} min={0} max={21} value={uiState.config1.motorPin_right} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.motorPin_right = val)}/>
					<Text ml={5}>Vibrate multiplier:</Text>
					<Spinner ml={5} min={0} enforceRange={true} style={{width: 50}}
						value={uiState.config1.vibrateMultiplier_right.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.vibrateMultiplier_right = val.FromPercent())}/>
					<Text ml={3}>%</Text>
					<Button ml={5} text="Test" onClick={()=>TempVibrateMotor(uiState.config1.motorPin_right, uiState.config1.vibrateStrength * uiState.config1.vibrateMultiplier_right)}/>
				</RowLR>
				<RowLR splitAt={splitAt}>
					<Text>Back:</Text>
					<Spinner ml={5} min={0} max={21} value={uiState.config1.motorPin_back} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.motorPin_back = val)}/>
					<Text ml={5}>Vibrate multiplier:</Text>
					<Spinner ml={5} min={0} enforceRange={true} style={{width: 50}}
						value={uiState.config1.vibrateMultiplier_back.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.vibrateMultiplier_back = val.FromPercent())}/>
					<Text ml={3}>%</Text>
					<Button ml={5} text="Test" onClick={()=>TempVibrateMotor(uiState.config1.motorPin_back, uiState.config1.vibrateStrength * uiState.config1.vibrateMultiplier_back)}/>
				</RowLR>
				<RowLR splitAt={splitAt}>
					<Text>Left:</Text>
					<Spinner ml={5} min={0} max={21} value={uiState.config1.motorPin_left} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.motorPin_left = val)}/>
					<Text ml={5}>Vibrate multiplier:</Text>
					<Spinner ml={5} min={0} enforceRange={true} style={{width: 50}}
						value={uiState.config1.vibrateMultiplier_left.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.config1.vibrateMultiplier_left = val.FromPercent())}/>
					<Text ml={3}>%</Text>
					<Button ml={5} text="Test" onClick={()=>TempVibrateMotor(uiState.config1.motorPin_left, uiState.config1.vibrateStrength * uiState.config1.vibrateMultiplier_left)}/>
				</RowLR>

				<Row mt={10} style={{fontSize: 18}}>Actions</Row>
				<Row mt={5}>
					<Button text="Send config to device" onClick={()=>SendLD1ConfigToDevice()}/>
					<Text ml={5}>Note: Config gets auto-sent to device (if it's connected), whenever one of the config settings is changed.</Text>
				</Row>
			</ScrollView>
		);
	}
}