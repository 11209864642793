import {Switch} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../Store/index.js";
import {Observer, SubNavBar_Auto} from "web-vcore";
import {BundlesUI} from "./Effects/Bundles";
import {EntitiesUI} from "./Content/Entities";
import {LightsUI} from "./Effects/Lights";
import {ScriptsUI} from "./Effects/Scripts";
import {ShakesUI} from "./Effects/Shakes";
import {SoundsUI} from "./Effects/Sounds";

export const columnWidths = [.64, .06, .12, .18];

@Observer
export class EffectsUI extends BaseComponentPlus({}, {}) {
	render() {
		//const user = Me();
		const currentSubpage = store.main.effects.subpage;
		//if (user == null) return null;
		const page = "effects";

		return (
			<>
				<SubNavBar_Auto page={page}/>
				<Switch>
					<SoundsUI/>
					{currentSubpage == "shakes" && <ShakesUI/>}
					{currentSubpage == "lights" && <LightsUI/>}
					{currentSubpage == "scripts" && <ScriptsUI/>}
					{/*currentSubpage == "bundles" && <BundlesUI/>*/}
				</Switch>
			</>
		);
	}
}