import {Assert, SleepAsync, SleepAsyncUntil, GetStackTraceStr} from "js-vextensions";
import {GetLight, GetLights_WithUserTag} from "../../../Store/firebase/lights";
import {kasa_hueForPureWhiteAt0Sat, Light, LightType} from "../../../Store/firebase/lights/@Light";
import {store} from "../../../Store/index.js";
import {nativeBridge} from "../Bridge_Native.js";
import {RunUserScript_CallArgsObj} from "../../UserScripts/ScriptRunner";
import {GetAsync} from "mobx-firelink";
import {LightPlayController} from "../../EffectPlayers/LightPlayer.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {liveFBASession} from "../../../Engine/FBASession.js";
import {AddErrorMessage} from "web-vcore";

export class CancelInfo {
	cancel = false;
}

export const kasa_lightReset_defaultConfig = new Light({
	type: LightType.KasaBulb,
	// note: make sure the "power off" call is last (once power is off, light seems to ignore calls other than "turn on" -- or perhaps this is just a consequence of something like "restoring to last power-on state")
	effectJSON_functionStr: `
		return {
			calls: [
				{method: "setBrightness", args: {brightness: 0}},
				{method: "setColor", args: {hue: ${kasa_hueForPureWhiteAt0Sat}, saturation: 0}},
				{method: "setPowered", args: {powered: false}}
			]
		};
	`.AsMultiline(0),
});
/*export const kasa_fullWhiteConfig = new Light({
	type: LightType.KasaBulb,
	effectJSON_functionStr: `
		return {
			calls: [
				{method: "setPowered", args: {powered: true}},
				{method: "setBrightness", args: {brightness: 100}},
				{method: "setColor", args: {hue: ${kasa_hueForPureWhiteAt0Sat}, saturation: 0}}
			]
		};
	`.AsMultiline(0),
});*/
export function ResetLight_Kasa(allowUserOverride = true, allowLowLevelReapplies = true, cancelInfo = new CancelInfo()) {
	const uiState = store.main.effects.lights;
	const lightTagForReset = store.main.settings.lightTagForReset;
	if (allowUserOverride && lightTagForReset && lightTagForReset.trim().length) {
		const lightForReset = GetLights_WithUserTag(lightTagForReset).filter(a=>a.type == LightType.KasaBulb).Random();
		if (lightForReset) {
			PlayLightConfig_Kasa(lightForReset, .99999, allowLowLevelReapplies, cancelInfo);
		}
	} else {
		PlayLightConfig_Kasa(kasa_lightReset_defaultConfig, store.main.effects.lights.lifxConnection, allowLowLevelReapplies, cancelInfo);
	}
}

export async function PlayLightConfig_Kasa(light: Light, brightness: number, allowLowLevelReapplies: boolean, cancelInfo: CancelInfo) {
	//SessionLog(`Playing kasa light config:"${light.name}" Brightness:${brightness}`);

	// wrap with try, so that buggy user-script doesn't break engine execution
	let command;
	try {
		command = await RunUserScript_CallArgsObj(light.effectJSON_functionStr, {brightness});
		Assert(command != null && typeof command == "object", "Script did not return an object.");
	} catch (ex) {
		const message = "Failed to generate light command.";
		console.warn(message, ex);
		// only show notification if user is the config's creator (else, user-script could throw error that gives malicious instructions)
		//if (light.creator == MeID() || light.creator == null) AddNotificationMessage(`${message} ${ex}`);
		//if (light.creator == MeID()) AddNotificationMessage(`${message} ${ex}`);
		AddErrorMessage(`${message} ${ex}`, ex?.stack ?? GetStackTraceStr());
		return null;
	}

	await RunLightCommand_Kasa_Enhanced(command, {origLight: light, origBrightness: brightness, allowLowLevelReapplies}, cancelInfo);
}

interface KasaCommand_Enhanced {
	calls?: KasaCall[];
	set1_calls?: KasaCall[];
	set2_calls?: KasaCall[];
	sets_cycleInterval?: number | number[] | {min: number, max: number}; // Examples (each in seconds): 1, [1, 2, 3], {min: 1, max: 2}
	//sets_cycleOptions?: number[][]; // example: [[1, 4], [2, 4], [3, 6], [3, 6]] means there's a 25% chance of {int:1s,dur:4s}, 25% of {int:2s,dur:4s}, and 50% of {int:3s,dur:6s}
	evolve_lightId?: string;
	//evolve_lightBrightness?: string;
	evolve_delay?: number; // in seconds
}
export async function RunLightCommand_Kasa_Enhanced(command: KasaCommand_Enhanced, extraInfo: {origLight: Light, origBrightness: number, allowLowLevelReapplies: boolean}, cancelInfo: CancelInfo) {
	if (command.calls) {
		RunLightCommand_Kasa({calls: command.calls}, extraInfo.allowLowLevelReapplies); // fire and forget; interruption of re-applies is achieved by just calling func again
	}

	let evolveHappened = false;
	if (command.set1_calls || command.set2_calls || command.sets_cycleInterval) {
		Assert(command.set1_calls && command.set2_calls && command.sets_cycleInterval, "When using set1_calls/set2_calls/sets_cycleInterval, all must be provided.");
		const {set1_calls, set2_calls, sets_cycleInterval} = command as Required<KasaCommand_Enhanced>;

		/*let doSet1Next = true;
		// use a timer that's attach to the live-session's timer-context; this way we're certain it'll be ended at session end, *and* it allows the "js-wake timer" system to force-trigger it
		const timer = new Timer(sets_cycleInterval * 1000, ()=>{
			if (cancelInfo.cancel || evolveHappened) {
				timer.Stop();
				return;
			};
			RunLightCommand_Kasa({calls: doSet1Next ? set1_calls : set2_calls}, extraInfo.allowLowLevelReapplies); // fire and forget; interruption of re-applies is achieved by just calling func again
			doSet1Next = !doSet1Next;
		});
		if (liveFBASession?.timerContext) {
			timer.SetContext(liveFBASession?.timerContext);
		}
		timer.Start();*/

		// this just starts a new async execution context, which will end on its own when its while loop ends (ie. when evolveTime is reached), or when cancelInfo.cancel becomes true
		const getNextCycleInterval = ()=>{
			if (typeof sets_cycleInterval == "number") return sets_cycleInterval;
			if (Array.isArray(sets_cycleInterval)) return sets_cycleInterval[Math.floor(Math.random() * sets_cycleInterval.length)];
			if (typeof sets_cycleInterval == "object") return sets_cycleInterval.min + ((sets_cycleInterval.max - sets_cycleInterval.min) * Math.random());
			throw new Error("Invalid cycle-interval; must be number, array, or object.");
		};
		new Promise<void>(async()=>{
			while (true) {
				RunLightCommand_Kasa({calls: set1_calls}, extraInfo.allowLowLevelReapplies); // fire and forget; interruption of re-applies is achieved by just calling func again
				await SleepAsync(getNextCycleInterval() * 1000);
				if (cancelInfo.cancel || evolveHappened) break;

				RunLightCommand_Kasa({calls: set2_calls}, extraInfo.allowLowLevelReapplies); // fire and forget; interruption of re-applies is achieved by just calling func again
				await SleepAsync(getNextCycleInterval() * 1000);
				if (cancelInfo.cancel || evolveHappened) break;
			}
		});
	}

	
	if (command.evolve_lightId || command.evolve_delay) {
		Assert(command.evolve_lightId && command.evolve_delay, "When using evolve_lightId/evolve_delay, all must be provided.");
		const {evolve_lightId, evolve_delay} = command as Required<KasaCommand_Enhanced>;
		const evolveTime = Date.now() + (evolve_delay * 1000);

		const evolveLight = GetLight(evolve_lightId) ?? (await GetAsync(()=>GetLight(evolve_lightId)));
		if (cancelInfo.cancel) return;
		if (evolveLight == null) {
			SessionLog(`Failed to find evolve-light with ID "${evolve_lightId}". Terminating run of root-light with ID "${extraInfo.origLight._key}".`);
			return;
		}

		await SleepAsyncUntil(evolveTime);
		evolveHappened = true;
		if (cancelInfo.cancel) return;

		await PlayLightConfig_Kasa(evolveLight, extraInfo.origBrightness, extraInfo.allowLowLevelReapplies, cancelInfo);
	}
}

interface KasaCommand {
	calls: KasaCall[];
}
interface KasaCall {
	method: string;
	args: any[];
}

let RunLightCommand_Kasa_lastCallID = 0;
export async function RunLightCommand_Kasa(command: KasaCommand, allowReapply: boolean) {
	const callID = ++RunLightCommand_Kasa_lastCallID;

	const uiState = store.main.effects.lights;
	nativeBridge.Call("RunKasaCommand_LAN", uiState.kasa_lights, command);

	// if this light command is still the last one run, re-apply it (according to the user settings), to ensure it gets applied
	if (allowReapply && RunLightCommand_Kasa_lastCallID == callID) {
		for (const delay of store.main.settings.lightReapplyDelays) {
			await SleepAsync(delay * 1000);
			if (RunLightCommand_Kasa_lastCallID != callID) break;
			RunLightCommand_Kasa(command, false); // don't allow these re-apply ops to themselves cause re-applies
		}
	}
}