import {Shake} from "../../Store/firebase/shakes/@Shake.js";
import {PlayShakeConfig, StopShake} from "../Bridge/Bridge_Native/SonicBomb.js";
import {SleepAsync, WaitXThenRun, Timer} from "js-vextensions";
import {LogWarning} from "web-vcore";
import {PlayState, PlayStateListener} from "./SoundPlayer.js";
import {EffectPlayExtras, EffectPlayer} from "./EffectPlayer.js";

export let lastActiveShakePlayer: ShakePlayer;
export class ShakePlayer extends EffectPlayer {
	constructor(shake: Shake|n = null) {
		super();
		this.shake = shake;
	}

	private shake_: Shake|n;
	get shake() { return this.shake_; }
	set shake(val: Shake|n) {
		if (val == this.shake_) return;
		this.Stop();
		this.shake_ = val;
		/*this.loopTimer = new Timer(val.loopInterval, ()=>{
			this.Play();
		});*/
	}

	_playState: PlayState = "stopped"; // flag is not necessarily real-world accurate (effect may be overridden by other code)
	get playState() { return this._playState; }
	set playState(val: PlayState) { this._playState = val; this.stateListeners.forEach(a=>a(val, ()=>this.stateListeners.Remove(a))); }
	stateListeners = [] as PlayStateListener[];
	WaitTillState(...waitForStates: PlayState[]) {
		return new Promise<void>((resolve, reject)=>{
			this.stateListeners.push((state, removeListener)=>{
				if (waitForStates.includes(state)) {
					resolve();
					removeListener();
				}
			});
		});
	}

	loopTimer: Timer;
	//stopTimer: Timer;
	async Play(intensity = 1, extras?: Partial<EffectPlayExtras>) {
		const {shake_stopPrevious} = new EffectPlayExtras(extras);
		if (this.shake == null) return; //void LogWarning("Failed to play shake, since it is null.");
		if (shake_stopPrevious && lastActiveShakePlayer && lastActiveShakePlayer.playState != "stopped") {
			lastActiveShakePlayer.Stop(false); // don't reset externals, as we're about to send new command anyway (which could cause race conditions)
		}
		this.playState = "playing";
		lastActiveShakePlayer = this;

		PlayShakeConfig(this.shake, intensity);

		if (this.shake.loop) {
			this.loopTimer = new Timer(this.shake.loopInterval * 1000, ()=>this.Play(intensity, {shake_stopPrevious: false}), 1).Start();
			await this.WaitTillState("stopped");
		} else {
			// since we don't know true shake duration, mark state as unknown
			this.playState = "unknown";
			await this.WaitTillState("stopped");
		}
	}
	Stop(resetExternals = true) {
		if (this.playState == "stopped") return;

		this.loopTimer?.Stop();
		this.playState = "stopped";
		if (resetExternals) StopShake();
	}
}