import {Assert, AssertV, AssertValidate, Command, GenerateUUID} from "mobx-firelink";
import {Activity} from "../../Store/firebase/activities/@Activity";

//@UserEdit
export class AddActivity extends Command<{activity: Activity}> {
	activityID: string;
	oldSectionOrder: number[];
	Validate() {
		const {activity} = this.payload;

		this.activityID = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		activity.creator = this.userInfo!.id;
		//activity.createdAt = Date.now();

		this.returnData = this.activityID;
		AssertValidate("Activity", activity, "Activity invalid");
	}

	GetDBUpdates() {
		const {activity} = this.payload;

		const updates = {};
		updates[`activities/${this.activityID}`] = activity;
		return updates;
	}
}