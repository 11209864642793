import React from "react";
import {AssertUnreachable, InfoButton, minuteInMS, Observer, RunInAction_Set, TextPlus, VDateTime} from "web-vcore";
import {Clone} from "js-vextensions";
import Moment from "moment";
import {CheckBox, Button, Column, Row, RowLR, RowLRProps, Select, Spinner, Text, TextArea, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Setting, SettingValue} from "./Setting.js";
import {FBAConfig} from "../../@FBAConfig.js";

export type SettingUI_Props<T> = {
	enabled: boolean, text: string, info?: string,
	displayType: "number_basic" | "number_percent",
	data: Setting<T>, dataSetter: (newData: Setting<T>)=>any,
} & RowLRProps;
export class SettingUI<T> extends BaseComponent<SettingUI_Props<T>, {}> {
	render() {
		let {enabled, text, info, displayType, data, dataSetter, ...rest} = this.props;

		/*const ChangeValue_ByIndex = (index: number, valueChanger: (value: SettingValue<T>)=>any)=>{
			const newData = Clone(data) as Setting<T>;
			valueChanger(newData.values[index]);
			dataSetter(newData);
		};*/

		return (
			<RowLR {...rest}>
				<TextPlus info={info}>{text}</TextPlus>
				<Column>
					{data.values.map((value, index)=>{
						function ChangeValue<T2>(valueChanger: (valueToChange: SettingValue<T2>)=>any) {
							const newData = Clone(data) as Setting<T>;
							valueChanger(newData.values[index] as any);
							dataSetter(newData);
						};

						return (
							<Row key={index}>
								<CheckBox enabled={enabled} value={!value.disabled} onChange={val=>{
									ChangeValue(a=>{
										if (val) {
											delete a.disabled;
										} else {
											a.disabled = true;
										}
									});
								}}/>
								
								{displayType == "number_basic" &&
									<Spinner ml={5} enabled={enabled}
										value={value.value as number}
										onChange={val=>ChangeValue<number>(a=>a.value = val)}/>}
								{displayType == "number_percent" && <>
									<Spinner ml={5} enabled={enabled} step="any"
										value={(value.value as number * 100).RoundTo(.01)}
										onChange={val=>ChangeValue<number>(a=>a.value = val as number / 100)} min={0}/>
									<Text>%</Text>
								</>}
								
								<Button ml={5} mdIcon="delete" enabled={enabled && data.values.length > 1} onClick={()=>{
									const newData = Clone(data) as Setting<T>;
									newData.values.RemoveAt(index);
									dataSetter(newData);
								}}/>
								<Button ml={5} mdIcon="plus" enabled={enabled} onClick={()=>{
									const newData = Clone(data) as Setting<T>;
									newData.values.Insert(index + 1, Clone(data.values[index]));
									dataSetter(newData);
								}}/>
							</Row>
						);
					})}
					{data.values.length == 0 &&
					<Button enabled={enabled} mdIcon="plus" onClick={()=>{
						const newData = Clone(data) as Setting<T>;
						let newVal: SettingValue<T>;
						if (displayType == "number_basic") {
							newVal = {value: 0 as T};
						} else if (displayType == "number_percent") {
							newVal = {value: 0 as T};
						} else {
							AssertUnreachable(displayType);
						}
						newData.values.push(newVal);
						dataSetter(newData);
					}}/>}
				</Column>
			</RowLR>
		);
	}
}