import {GetRandomNumber, SleepAsync, Timer} from "js-vextensions";
import {TextSpeaker} from "web-vcore";
import {Text} from "react-vcomponents";
import {FBASession, TriggerPackage} from "../../../Engine/FBASession.js";
import {IsMetaEntity} from "../../../Store/firebase/entities.js";
import {FBAConfig_JourneyGrid} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyGrid.js";
import {GetUserEntityTags, MeID} from "../../../Store/firebase/users.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {SessionEvent} from "../../FBASession/SessionEvent.js";
import {DreamRecallComp} from "./DreamRecallComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {AlarmsComp, AlarmsPhase} from "./AlarmsComp.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";

export class JourneyGridComp extends EngineSessionComp<FBAConfig_JourneyGrid> {
	constructor(session: FBASession, config: FBAConfig_JourneyGrid) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("JourneyGrid_CycleStart", this.c.listenStart_triggerSet, this, {}, async triggerInfo=>{
				this.StartListen();
			}),
			new TriggerPackage("JourneyGrid_CycleEnd", this.c.listenEnd_triggerSet, this, {}, async triggerInfo=>{
				this.StopListen();
			}),
		];
	}
	GetStatusUI() {
		return <Text style={{whiteSpace: "pre"}}>{`TODO`.AsMultiline(1)}</Text>;
	}

	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}

	// players
	//soundPlayer = new SoundPlayer(); // we use SoundPlayer (not TextSpeaker), since it's project-specific so understands Sound-objects directly
	textSpeaker = new TextSpeaker();

	// in-session timers
	speakTimer: Timer;

	cycle_targetReached = false;
	cycle_targetReached_lastTime = 0;

	GetEntitiesMatchingAnyTag(tags: string[]) {
		const dreamRecallComp = this.s.Comp(DreamRecallComp);
		return dreamRecallComp.entities_all.filter(entity=>{
			// never return a meta-entity
			if (IsMetaEntity(entity)) return false;

			// exclude entities that don't have any of the listed tags
			const userTags = GetUserEntityTags(MeID(), "entities", entity._key);
			const tagsMatching = tags.filter(tag=>userTags?.includes(tag));
			if (tagsMatching.length == 0) return false;

			return true;
		});
	}
	SpeakNextTarget() {
		const tagsToUseRightNow = this.cycle_targetReached ? this.c.entityTags_target : this.c.entityTags_regular;
		const validEntities = this.GetEntitiesMatchingAnyTag(tagsToUseRightNow);
		if (validEntities.length == 0) return void this.NarrateText("No valid targets.");

		const currentEntity = validEntities.Random();
		this.NarrateText([
			currentEntity.name.split("[")[0].trim(),
			//this.c.voiceEntityNumberInCycle ? `${this.currentCycle_entitySpeakCount}` : null,
		].filter(a=>a).join(" "))
	}
	StartListen() {
		const alarmsComp = this.s.Comp(AlarmsComp);
		const phaseIsCompatible = alarmsComp.PhaseIs(AlarmsPhase.Alarm);
		if (!phaseIsCompatible) return;

		this.Log("Listen started.", LogType.Event_Large);
		this.s.AsLocal!.AddEvent({type: "Journey.ListenStart"});
		alarmsComp.SetPhase(AlarmsPhase.Solving);
		this.currentCycle_targetReachTime = Date.now() + GetRandomNumber({min: this.c.targetDelay_minTime, max: this.c.targetDelay_maxTime});
		this.speakTimer.Start();
	}
	async StopListen() {
		const alarmsComp = this.s.Comp(AlarmsComp);
		const phaseIsCompatible = alarmsComp.PhaseIs(AlarmsPhase.Solving);
		if (!phaseIsCompatible) return;

		// if user caught the target...
		if (this.cycle_targetReached) {
			const targetJustReached = Date.now() - this.cycle_targetReached_lastTime <= this.c.userTargetDetection_maxDelay;
			// ...and did so in time, reward them with an alarm-wait
			if (targetJustReached) {
				this.OnTargetDetectedInTime();
			}
			// ...but did so too late, don't alarm-wait; instead, restart cycle (going clockwise), and tell them they were too late
			else {
				this.Log("User stopped listen too late.", LogType.Event_Large);
				this.NarrateText("Too late");
				this.StopCycle(false, {type: "Journey.CycleFail"});
				this.StartCycle(true);
			}
		}
		// else...
		else {
			// else, they let go of the button too early; set lights to bright again, to have them try again
			this.Log("User stopped listen too early / without match.", LogType.Event_Large);
			//this.NarrateText("Too early");
			this.StopCycle(false, {type: "Journey.CycleFail"});
			this.StartCycle(true);
		}
	}
	OnTargetDetectedInTime(eventTextOverride?: string) {
		this.Log(eventTextOverride ?? "User caught target; starting alarm-wait.", LogType.Event_Large);

		//this.NarrateText("Remember lucid");
		const validEntities = this.GetEntitiesMatchingAnyTag(this.c.entityTags_success);
		const successEntityText = validEntities.length ? validEntities.Random().name.split("[")[0].trim() : "Remember lucid";
		this.NarrateText(successEntityText);
		
		this.StopCycle(true, {type: "Journey.CycleSuccess"});
	}

	currentCycle_targetReachTime = 0;
	override OnStartPhase(oldPhase: AlarmsPhase, newPhase: AlarmsPhase) {
		// as per note in UI: if not in entryWait_dim or entryWait_bright phases, don't do the speaking
		const newPhase_active = newPhase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving);
		const lastPhase_active = oldPhase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving);
		if (newPhase_active && !lastPhase_active) {
			if (!this.cycleActive) this.StartCycle(false);
		} else if (!newPhase_active && lastPhase_active) {
			if (this.cycleActive) this.StopCycle();
		}
	}

	cycleActive = false;
	StartCycle(isRestartAfterFail: boolean) {
		this.Log("Cycle started.", LogType.Event_Large);
		this.s.AsLocal!.AddEvent({type: "Journey.CycleStart"});
		this.cycleActive = true;
		this.cycle_targetReached = false;
		this.speakTimer.Stop();

		const alarmsComp = this.s.Comp(AlarmsComp);
		if (alarmsComp.GetPhase() != AlarmsPhase.Alarm) {
			alarmsComp.SetPhase(AlarmsPhase.Alarm);
		}
	}
	StopCycle(changeAlarmsPhase = true, cycleEndEvent?: Partial<SessionEvent>) {
		this.Log("Cycle stopped.", LogType.Event_Large);
		if (cycleEndEvent) this.s.AsLocal!.AddEvent(cycleEndEvent);
		this.cycleActive = false;
		this.speakTimer.Stop();

		if (changeAlarmsPhase) {
			const alarmsComp = this.s.Comp(AlarmsComp);
			alarmsComp.SetPhase(AlarmsPhase.Sleep);
		}
	}

	override OnStart() {
		this.speakTimer = new Timer(this.c.speakInterval, ()=>{
			// check if target is reached just before speaking next target (we don't consider target actually reached until the point where target is "voiced")
			if (Date.now() > this.currentCycle_targetReachTime && !this.cycle_targetReached) {
				this.cycle_targetReached = true;
				this.cycle_targetReached_lastTime = Date.now();
				this.Log("Target reached.", LogType.Event_Large);
				this.s.AsLocal!.AddEvent({type: "Journey.TargetReached"});
			}

			this.SpeakNextTarget();
		}).SetContext(this.s.timerContext);
	}
	override OnStop() {
		this.StopTextSpeaker();
	}

	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}
}