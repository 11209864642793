import {store} from "../../Store/index.js";
import {useExpandedNavBar} from "../../UI/@Shared/NavBar";
import {ES} from "../../Utils/UI/GlobalStyles";
import {Observer, PageContainer} from "web-vcore";
import {E} from "js-vextensions";
import {BaseComponent} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {FancySwitch} from "./Pest/FancySwitch.js";
import React, {useState} from "react";
import {Row} from "react-vcomponents";

@Observer
export class PestUI extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.pest;
		const largeVersion = useExpandedNavBar();

		const [switchOn, setSwitchOn] = useState(false);

		return (
			<PageContainer preset="text" style={E(
				{padding: 0},
				!largeVersion && {margin: "0 auto 0 auto"},
			)}>
				<ScrollView style={ES({flex: 1, padding: 10})}>
					<Row>
						<FancySwitch value={switchOn} onChange={val=>setSwitchOn(val)}/>
					</Row>
				</ScrollView>
			</PageContainer>
		);
	}
}