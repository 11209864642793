import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export class FBAConfig_MicroSnooze {
	constructor(data: Partial<FBAConfig_MicroSnooze>) { Object.assign(this, data); }

	enabled: boolean;
	
	// triggers
	microSnooze_triggerSet: TriggerSet;

	tiltSnooze_enabled: boolean;
	tiltSnooze_minAngleFromFlat: number;
	tiltSnooze_maxTiltDuration: number;
	tiltSnooze_soundPath: string;
	tiltSnooze_soundVolume: number;

	/*onSolveStart_soundTagOverride: string;
	onSolveStart_lightTagOverride: string;*/
	// Why do we call this "max time for progress", when progress is not even possible for micro-snoozes? Reason: Just to have consistent field-naming with other solvers.
	maxTimeForProgress: number; // in seconds
}
AddSchema("FBAConfig_MicroSnooze", {
	properties: {
		enabled: {type: "boolean"},
		microSnooze_triggerSet: {$ref: "TriggerSet"},

		tiltSnooze_enabled: {type: "boolean"},
		tiltSnooze_minAngleFromFlat: {type: "number"},
		tiltSnooze_maxTiltDuration: {type: "number"},
		tiltSnooze_soundPath: {type: "string"},
		tiltSnooze_soundVolume: {type: "number"},
		
		/*onSolveStart_soundTagOverride: {type: "string"},
		onSolveStart_lightTagOverride: {type: "string"},*/
		maxTimeForProgress: {type: "number"},
	},
});