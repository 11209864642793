import {store} from "../../../../Store/index.js";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";
import {InfoButton, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {CopyText, E, IsString, ToJSON_Advanced} from "js-vextensions";
import {Button, CheckBox, Column, Row, Text, TextInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {liveFBASession, FBASession, GetAllFBASessions, NotifyFBASessionLogChanged} from "../../../../Engine/FBASession.js";
import {SessionlessLogPeriod, sessionlessLogPeriods, SessionLog} from "./SessionLog.js";
import {ReturnBar_Android} from "../../../@Shared/ReturnBar_Android.js";
import {JourneyTab} from "../../../../Store/main/tools/journey.js";
import {LogEntry, LogType} from "../LogEntry.js";

for (const funcName of ["log", "info", "warn", "error"]) {
	Intercept(funcName);
}
function Intercept(funcName: string) {
	var original = console[funcName];
	console[funcName] = function(...args) {
		const hasBeenSessionLogged = args.length == 1 && IsString(args[0]) && args[0].startsWith("[SessionLog] ");
		if (!hasBeenSessionLogged) {
			const messageStr = args.map(arg=>{
				if (IsString(arg)) return arg.toString();
				return ToJSON_Advanced(arg, {catchErrors: true});
			}).join(" ");
			SessionLog(messageStr, funcName == "error" ? LogType.App_Error : LogType.App_Log, {alsoLogToConsole: false});
		}

		return original.apply(this, args);
	};
}

/*export function GetLogString(sessionsAndLogPeriods: (FBASession|SessionlessLogPeriod)[], logTypesToShow: LogType[], allowTimestampTrim = true) {
	sessionsAndLogPeriods = sessionsAndLogPeriods.OrderBy(a=>a.startTime);

	let result = "";

	for (const set of sessionsAndLogPeriods) {
		if (result.length) result += "\n\n\n\n";
			if (set instanceof FBASession) {
			const typeStr = {FBASession_Local: "fba local", FBASession_Remote: "fba remote"}.Pairs().find(a=>set.constructor.name == a.key).value;
			//result += `========== Start of session (${Moment(session.startTime).format("YYYY-MM-DD HH:mm:ss")}) ==========\n`;
			result += `========== Start of session (${typeStr}) ==========\n`;
		} else {
			result += `========== Start of sessionless log-period ==========\n`;
		}

		const matchingLogEntries = set.logEntries.filter(a=>logTypesToShow.Contains(a.type));
		result += matchingLogEntries.map((entry, index)=> {
			return entry.toString(allowTimestampTrim ? matchingLogEntries[index - 1] : null);
		}).join("\n");
	}
	return result;
}*/

@Observer
export class SessionLogPanel extends BaseComponentPlus({}, {}) {
	/*ComponentDidMount() { fbaLogListeners.push(this.OnLogChange); }
	ComponentWillUnmount() { fbaLogListeners.Remove(this.OnLogChange); }
	OnLogChange = ()=>this.Update();*/

	render() {
		const uiState = store.main.tools.engine; // todo: move these fields to a store location shared by both session-types
		const logTypesToShow = uiState.logTypesToShow;
		const tracker1 = uiState.liveFBASession_setOrLoggedAt;

		const allFBASessions = GetAllFBASessions().OrderBy(a=>a.coreData.startTime);
		let sessionsAndLogPeriods = ([] as (FBASession|SessionlessLogPeriod)[]).concat(allFBASessions).concat(sessionlessLogPeriods);
		sessionsAndLogPeriods = sessionsAndLogPeriods.OrderBy(a=>a instanceof FBASession ? a.coreData.startTime : a.startTime);
		//const logString = GetLogString(sessionsAndLogPeriods, logTypesToShow);

		const setShowLogType = (logType: LogType, show: boolean)=>{
			RunInAction("SessionLogPanel.setShowLogType", ()=>{
				uiState.logTypesToShow = show ? logTypesToShow.concat(logType) : logTypesToShow.Exclude(logType);
			});
		};

		function IsFilterTextRegex(filterText: string) { return filterText.startsWith("/") && filterText.endsWith("/"); }
		function DoesFilterTextMatch(filterText: string, str: string) {
			const regex = IsFilterTextRegex(filterText);
			if (regex) {
				try {
					return new RegExp(filterText.slice(1, -1)).test(str);
				} catch (ex) {
					return false;
				}
			}
			return str.includes(filterText);
		}
		function ShouldLogEntryBeShown(entry: LogEntry) {
			if (!logTypesToShow.Contains(entry.type)) return false;
			//const entryStr = entry.message;
			const entryStr = entry.toString();
			if (uiState.include_enabled && uiState.include_text && !DoesFilterTextMatch(uiState.include_text, entryStr)) return false;
			if (uiState.exclude_enabled && uiState.exclude_text && DoesFilterTextMatch(uiState.exclude_text, entryStr)) return false;
			return true;
		}
		function FilterLogEntries(entries: LogEntry[]) {
			return entries.filter(a=>ShouldLogEntryBeShown(a));
		}
		function GetLogEntriesAsString(filterEntries: boolean) {
			return sessionsAndLogPeriods.map(group=>{
				const headerText = GetSessionOrLogPeriodHeaderText(group);
				const entriesToShow = filterEntries ? FilterLogEntries(group.logEntries) : group.logEntries;
				return `${headerText}\n==========\n${entriesToShow.map((entry, index)=>{
					return entry.toString(entriesToShow[index - 1]);
				}).join("\n")}`;
			}).join("\n\n\n\n");
		}

		const gap = InAndroid(0) ? 3 : 7;
		const fontSize = InAndroid(0) ? 11 : undefined;
		return (
			<>
				<ReturnBar_Android text="Log" onBack={()=>RunInAction_Set(this, ()=>store.main.tools.journey.tab = JourneyTab.engine)}/>
				<Row mb={5} center style={{flexWrap: "wrap", gap}}>
					<Row style={{fontSize: 13, height: 50, flexWrap: "wrap", gap}}>
						<Text>Types:</Text>
						<CheckBox style={{fontSize}} text="Actions" value={logTypesToShow.Contains(LogType.Action)} onChange={val=>setShowLogType(LogType.Action, val)}/>
						<CheckBox style={{fontSize}} text="Events (large)" value={logTypesToShow.Contains(LogType.Event_Large)} onChange={val=>setShowLogType(LogType.Event_Large, val)}/>
						<CheckBox style={{fontSize}} text="Events (small)" value={logTypesToShow.Contains(LogType.Event_Small)} onChange={val=>setShowLogType(LogType.Event_Small, val)}/>
						<CheckBox style={{fontSize}} text="App log" value={logTypesToShow.Contains(LogType.App_Log)} onChange={val=>setShowLogType(LogType.App_Log, val)}/>
						<CheckBox style={{fontSize}} text="App error" value={logTypesToShow.Contains(LogType.App_Error)} onChange={val=>setShowLogType(LogType.App_Error, val)}/>
					</Row>
					<Column style={{flex: 1, height: 50}}>
						<Row center>
							<CheckBox style={{fontSize, width: 75}} text="Include:" value={uiState.include_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.include_enabled = val)}/>
							<InfoButton ml={3} text="If enabled, log entries not containing the given text are hidden. (for regex: /.../)"/>
							<TextInput ml={5} style={{flex: 1}} enabled={uiState.include_enabled}
								value={uiState.include_text} onChange={val=>RunInAction_Set(this, ()=>uiState.include_text = val)}/>
						</Row>
						<Row center>
							<CheckBox style={{fontSize, width: 75}} text="Exclude:" value={uiState.exclude_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.exclude_enabled = val)}/>
							<InfoButton ml={3} text="If enabled, log entries containing the given text are hidden. (for regex: /.../)"/>
							<TextInput ml={5} style={{flex: 1}} enabled={uiState.exclude_enabled}
								value={uiState.exclude_text} onChange={val=>RunInAction_Set(this, ()=>uiState.exclude_text = val)}/>
						</Row>
					</Column>
					<Column style={{height: 50}}>
						<Row>
							<Text>Copy:</Text>
							<Button ml={5} text="All" p="3px 10px" style={{fontSize: 12}} onClick={()=>{
								CopyText(GetLogEntriesAsString(false));
							}}/>
							<Button ml={3} text="Filtered" p="3px 10px" style={{fontSize: 12}} onClick={()=>{
								CopyText(GetLogEntriesAsString(true));
							}}/>
						</Row>
						<Row mt="auto">
							<Text>Clear:</Text>
							<Button ml={5} text="All" p="3px 10px" style={{fontSize: 12}} onClick={()=>{
								ShowMessageBox({
									title: "Clear log?", cancelButton: true,
									message: "Clear all messages in the log?",
									onOK: async()=>{
										for (const session of allFBASessions) {
											//session.ClearLog();
											session.logEntries.Clear(); // call this instead of ClearLog, so we call the Notify... function only once (see below)
										}
										for (const period of sessionlessLogPeriods) {
											period.logEntries.Clear();
										}
										// call these once at end
										NotifyFBASessionLogChanged();
									},
								});
							}}/>
						</Row>
					</Column>
				</Row>
				{/*<TextArea style={{flex: 1}} editable={false} value={logString}/>*/}
				<ScrollView>
					{sessionsAndLogPeriods.map((group, groupIndex)=>{
						const headerText = GetSessionOrLogPeriodHeaderText(group);
						const entriesToShow = FilterLogEntries(group.logEntries);
						return <Column sel key={groupIndex} style={{wordBreak: "break-word"}}>
							<Row key={groupIndex} mt={groupIndex == 0 ? 0 : 100} style={{paddingBottom: "7px", borderBottom: "3px solid rgba(255,255,255,.7)", fontSize: 18, fontWeight: "bold"}}>
								{headerText}
							</Row>
							{entriesToShow.map((entry, index)=>{
								return <LogEntryUI key={index} entry={entry} index={index} entries={entriesToShow}/>;
							})}
						</Column>;
					})}
				</ScrollView>
			</>
		);
	}
}

function GetSessionOrLogPeriodHeaderText(group: FBASession|SessionlessLogPeriod) {
	let headerText: string;
	if (group instanceof FBASession) {
		const typeStr = {FBASession_Local: "fba local", FBASession_Client: "fba client"}.Pairs().find(a=>group.constructor.name == a.key)!.value;
		//result += `========== Start of session (${Moment(session.startTime).format("YYYY-MM-DD HH:mm:ss")}) ==========\n`;
		headerText = `Start of fba-session (${typeStr})`;
	} else {
		headerText = `Start of sessionless log-period`;
	}
	return headerText;
}

class LogEntryUI extends BaseComponentPlus({} as {entry: LogEntry, index: number, entries: LogEntry[]}, {}) {
	render() {
		const {entry, index, entries} = this.props;
		const text = entry.toString(entries[index - 1]);
		return (
			<Row mt={10} style={E(
				{color: "rgba(255,255,255,.7)"},
				//index % 2 == 0 && {backgroundColor: HSLA(0, 0, 1, .2)},
				//index % 2 != 0 && {backgroundColor: HSLA(0, 0, 0, .3)},
			)}>
				{text}
			</Row>
		);
	}
}