import {FBAConfig_Gyro, GyroChannel} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Gyro";
import {Muse_gyroSamplesPerSecond_raw} from "../../@Shared/MuseInterface/GyroStructs";
import {InfoButton, TextPlus} from "web-vcore";
import {GetEntries} from "js-vextensions";
import {CheckBox, Column, Row, RowLR, Select, Spinner, Text, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.gyro, (c, v)=>c.gyro = v);

export class Gyro_ConfigUI extends BaseComponent<{client?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		const splitAt = 260;
		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Gyro" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info="Round individual samples to integers, to save on storage space. (due to window-based averaging, this shouldn't impact behavior significantly)">Round samples:</TextPlus>
						<CheckBox ml={15} enabled={enabled} value={config.useSampleRounding} onChange={val=>ChangeConfig(c=>c.useSampleRounding = val)}/>
					</RowLR>

					<RowLR mt={5} splitAt={splitAt}>
						<Text>Record:</Text>
						<CheckBox text="Raw data" ml={15} enabled={enabled} value={config.recordData_raw} onChange={val=>ChangeConfig(c=>c.recordData_raw = val)}/>
						<InfoButton ml={5} text="The history of raw gyro samples. Storage cost (with rounding): ~0.5mb/hour (unrounded is ~3x that)"/>
						<CheckBox text="Motions" ml={10} enabled={enabled} value={config.recordData_motions} onChange={val=>ChangeConfig(c=>c.recordData_motions = val)}/>
						<InfoButton ml={5} text="The history of times that a gyro-motion was detected. Storage cost: ~0.6kb per hour"/>
					</RowLR>

					<Row mt={5}>
						<CheckBox text="Detect head moves" enabled={enabled} value={config.detectMotion} onChange={val=>ChangeConfig(c=>c.detectMotion = val)}/>
					</Row>

					{config.detectMotion &&
					<Column ml={15}>
						<Row mt={5}>
							<Text style={{fontWeight: "bold"}}>Sample trigger (level 1):</Text>
						</Row>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>1) Normalized-deviation window:</Text>
								<InfoButton ml={5} text={`
									Size of window of raw gyro-values, from which the baseline is determined (through averaging).
									
									(Baseline is used for calculating each sample's raw deviation value: it's the sample's raw gyro-value minus the baseline.)

									Note: Although gyro values should theoretically normalize on their own, in practice, they seems not to (for my Muse S anyway).
								`.AsMultiline(0)}/>
							</Row>
							<TimeSpanInput enabled={enabled}
								value={config.motion_sampleTrigger_normalizationWindowSize} onChange={val=>ChangeConfig(c=>c.motion_sampleTrigger_normalizationWindowSize = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>2) Smoothed-deviation window:</Text>
								<InfoButton ml={5} text={`
									How many samples to use, for averaging the last X samples' raw deviation values, to determine its final deviation value. (0 for no smoothing)
									
									(The Muse S device outputs ${Muse_gyroSamplesPerSecond_raw} gyro samples per second.)
								`.AsMultiline(0)}/>
							</Row>
							<Spinner enabled={enabled}
								value={config.motion_sampleTrigger_smoothing} onChange={val=>ChangeConfig(c=>c.motion_sampleTrigger_smoothing = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>3) Combined-deviation basis:</Text>
								<InfoButton ml={5} text={`
									All_Sum: Sum of the absolute-deviations of the three channels.
									All_Max: Max absolute-deviation of the three channels.
									X: Absolute-deviation of x-channel. (X+: head tilt left, X-: head tilt right)
									Y: Absolute-deviation of y-channel. (Y+: head look down, Y-: head look up)
									Z: Absolute-deviation of z-channel. (Z+: head turn left, Z-: head turn right)
								`.AsMultiline(0)}/>
							</Row>
							<Select options={GetEntries(GyroChannel, "ui")} enabled={enabled} value={config.motion_channel} onChange={val=>ChangeConfig(c=>c.motion_channel = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>4) Min combined-deviation:</Text>
								<InfoButton ml={5} text={`
									How many degrees of combined-deviation (see setting above for basis) is required for a sample to qualify/"trigger".
								`.AsMultiline(0)}/>
							</Row>
							<Spinner enabled={enabled}
								value={config.motion_sampleTrigger_minDeviation} onChange={val=>ChangeConfig(c=>c.motion_sampleTrigger_minDeviation = val)}/>
						</RowLR>

						<Row mt={5}>
							<Text style={{fontWeight: "bold"}}>Motion trigger (level 2):</Text>
						</Row>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Window size:</Text>
								<InfoButton ml={5} text={`
									Size of window in which to check for the target percentage of above-min-deviation samples.

									(If you want gyro-motion to trigger earlier than eeg-motion, set to lower than the eeg window-size, eg. 0.05s)
								`.AsMultiline(0)}/>
							</Row>
							<TimeSpanInput enabled={enabled}
								value={config.motion_motionTrigger_windowSize} onChange={val=>ChangeConfig(c=>c.motion_motionTrigger_windowSize = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Min trigger-sample percent:</Text>
								<InfoButton ml={5} text={`The percentage of samples within the time-window which must be triggered-samples, for motion to be detected/"triggered".`}/>
							</Row>
							<Spinner enabled={enabled}
								value={config.motion_motionTrigger_minTriggerSamplePercent.ToPercent(.01)}
								onChange={val=>ChangeConfig(c=>c.motion_motionTrigger_minTriggerSamplePercent = val.FromPercent())}/>
							<Text> %</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Max trigger-rate:</Text>
								<InfoButton ml={5} text={`How much time must past from the start of one motion-trigger, for the next to be possible.`}/>
							</Row>
							<TimeSpanInput enabled={enabled}
								value={config.motion_motionTrigger_maxTriggerRate} onChange={val=>ChangeConfig(c=>c.motion_motionTrigger_maxTriggerRate = val)}/>
						</RowLR>

						<Row mt={5} center>
							<CheckBox text="Block eeg motion-triggers" enabled={enabled} value={config.motion_disableEEGMotion_enabled} onChange={val=>ChangeConfig(c=>c.motion_disableEEGMotion_enabled = val)}/>
							<InfoButton ml={5} text={`
								Whether to block eeg motion-triggers for a while, when a gyro motion triggers.
							`.AsMultiline(0)}/>
						</Row>
						<RowLR mt={5} splitAt={splitAt}>
							<Text ml={15}>EEG motion-block duration: </Text>
							<TimeSpanInput enabled={enabled}
								value={config.motion_disableEEGMotion_duration} onChange={val=>ChangeConfig(c=>c.motion_disableEEGMotion_duration = val)}/>
						</RowLR>

						<Row mt={5} center>
							<CheckBox text="Reset eeg-activity" enabled={enabled} value={config.motion_resetEEGActivity_enabled} onChange={val=>ChangeConfig(c=>c.motion_resetEEGActivity_enabled = val)}/>
							<InfoButton ml={5} text={`
								Whether to reset/cap the eeg-activity counter, when a gyro motion triggers (and throughout the next second).
							`.AsMultiline(0)}/>
						</Row>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Reset target (max value): </Text>
								<InfoButton text="The value that the eeg-activity counter will be reset to (if value was over it), at time of gyro motion-trigger."/>
							</Row>
							<Spinner enabled={enabled}
								value={config.motion_resetEEGActivity_maxValue} onChange={val=>ChangeConfig(c=>c.motion_resetEEGActivity_maxValue = val)}/>
						</RowLR>
					</Column>}
				</>}
			</Column>
		);
	}
}