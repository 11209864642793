import {GetEntries, ModifyString} from "js-vextensions";
import {runInAction} from "mobx";
import {CheckBox, Column, Row, Select} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../Store/index.js";
import {MuseSubtab} from "../../../Store/main/tools/monitor.js";
import {ES} from "../../../Utils/UI/GlobalStyles.js";
import {Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {currentMuse} from "../@Shared/MuseInterface.js";
import {EEGPanel, EEGPanel_ControlUI} from "./MusePanel/EEGPanel.js";
import {GyroPanel, GyroPanel_ControlUI} from "./MusePanel/GyroPanel.js";
import {OthersPanel} from "./MusePanel/OthersPanel.js";

@Observer
export class MusePanel extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.tools.monitor;
		return (
			<Column p={10} style={ES({flex: 1})}>
				<Row center>
					<CheckBox text={ModifyString(currentMuse.museStatus, m=>[m.startLower_to_upper])}
						value={uiState.eeg_monitor}
						onChange={async val=>{
							if (val) {
								if (currentMuse.museStatus == "connected") return;
								RunInAction("MusePanel_Connect", ()=>{
									uiState.eeg_monitor = true;
									currentMuse.museStatus = "connecting";
									currentMuse.AddConnectRequester("MusePanel");
								});
							} else {
								RunInAction("MusePanel_Disconnect", ()=>{
									uiState.eeg_monitor = false;
									currentMuse.RemoveConnectRequester("MusePanel");
								});
							}
						}}/>
					<Select ml={10} displayType="button bar" options={GetEntries(MuseSubtab)} value={uiState.muse_subtab} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.muse_subtab = val);
					}}/>
					{uiState.muse_subtab == MuseSubtab.EEG && <EEGPanel_ControlUI/>}
					{uiState.muse_subtab == MuseSubtab.Gyro && <GyroPanel_ControlUI/>}
					{/*uiState.muse_subtab == MuseSubtab.Others && <OthersPanel_ControlUI/>*/}
				</Row>
				{uiState.muse_subtab == MuseSubtab.EEG && <EEGPanel/>}
				{uiState.muse_subtab == MuseSubtab.Gyro && <GyroPanel/>}
				{uiState.muse_subtab == MuseSubtab.Others && <OthersPanel/>}
			</Column>
		);
	}
}