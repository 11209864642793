import {E} from "js-vextensions";
import {GenerateUUID, GetAsync, GetDocs, MergeDBUpdates} from "mobx-firelink";

/*
Basic db-upgrade procedure:
1) Retrieve list of db-updates by running "dbUpdates = await RR().GetDBUpdatesFor_XXX()" for the target function below, from console.
2) Make "quick backup" of the data at the paths where those db-updates will be applied, using: RR.MakeQuickBackupForDBUpdates({}, dbUpdates)
3) Check the quick-backup file downloaded to your computer, to make sure the old and new values look correct.
4) Apply the given db-updates by running: RR.ApplyDBUpdates({}, dbUpdates)

Reminders:
* For large updates, usually perform process on subset of data before doing whole thing.
*/

// helpers
export function StoreTempData(data: Object) {
	g.tempData = g.tempData || [];
	g.tempData.push(E({_time: Date.now()}, data));
}

// temp (for in-console db-upgrades and such)
// ==========

export async function GetDBUpdatesFor_CompleteUUIDs() {
	const soundsToFix = (await GetAsync(()=>GetDocs({}, a=>a.sounds))).filter(a=>a._key.length < 5);
	const lightsToFix = (await GetAsync(()=>GetDocs({}, a=>a.lights))).filter(a=>a._key.length < 5);

	let dbUpdates = {};
	for (const sound of soundsToFix) {
		dbUpdates = MergeDBUpdates(dbUpdates, {
			[`sounds/${sound._key}`]: null,
			[`sounds/${GenerateUUID()}`]: sound,
		});
	}
	for (const light of lightsToFix) {
		dbUpdates = MergeDBUpdates(dbUpdates, {
			[`lights/${light._key}`]: null,
			[`lights/${GenerateUUID()}`]: light,
		});
	}

	StoreTempData({soundsToFix, lightsToFix, dbUpdates});
	return dbUpdates;
}