import {InfoButton, Observer} from "web-vcore";
import React from "react";
import {Button, CheckBox, Column, Row, RowLR, Select, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {SwitchableCompID_values} from "../../../../Engine/FBASession/Components/ModeSwitcherComp.js";
import {LaunchType_values} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_ModeSwitcher.js";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.modeSwitcher, (c, v)=>c.modeSwitcher = v);

const splitAt = 180;

@Observer
export class ModeSwitcher_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Mode Switcher" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Cycle active comp:</Text>
						<ControlInput enabled={enabled} value={config.cycleActiveComp_triggerSet} onChange={val=>ChangeConfig(c=>c.cycleActiveComp_triggerSet = val)}/>
					</RowLR>
					<Row mt={5}>
						<Text>Effects:</Text>
						<InfoButton ml={5} text="Note: These mode-switches/component-activations are silent, ie. do not announce the switch."/>
					</Row>
					<Column mt={5} ml={10}>
						<RowLR splitAt={splitAt}>
							<Text>On alarm-wait end, activate:</Text>
							<Select enabled={enabled}
								options={[
									{name: "None", value: null},
									...SwitchableCompID_values,
								]}
								value={config.onAlarmWaitEnd_setActiveMode} onChange={val=>ChangeConfig(c=>c.onAlarmWaitEnd_setActiveMode = val)}/>
						</RowLR>
					</Column>
					<Row mt={5}>
						<Text>Start states:</Text>
						<Button ml={5} mdIcon="plus" enabled={enabled} onClick={()=>{
							ChangeConfig(c=>c.startStates.push({compID: SwitchableCompID_values[0], startState_day: {enabled: true}, startState_night: {enabled: true}}));
						}}/>
					</Row>
					{config.startStates.map((entry, index)=>{
						return (
							<Row key={index} mt={5}>
								<Text style={{minWidth: 12}}>{index + 1}:</Text>
								<Select ml={5} options={SwitchableCompID_values} value={entry.compID} onChange={val=>ChangeConfig(c=>c.startStates[index].compID = val)}/>
								<Text ml={5}>For day:</Text>
								<CheckBox ml={5} enabled={enabled} value={entry.startState_day.enabled} onChange={val=>ChangeConfig(c=>c.startStates[index].startState_day.enabled = val)}/>
								<Text ml={5}>For night:</Text>
								<CheckBox ml={5} enabled={enabled} value={entry.startState_night.enabled} onChange={val=>ChangeConfig(c=>c.startStates[index].startState_night.enabled = val)}/>
								<Button ml={5} mdIcon="delete" enabled={enabled} onClick={()=>ChangeConfig(c=>c.startStates.RemoveAt(index))}/>
							</Row>
						);
					})}
					<Row mt={5}>
						<Text>Cycle entries:</Text>
						<Button ml={5} mdIcon="plus" enabled={enabled} onClick={()=>ChangeConfig(c=>c.cycleEntries.push({compID: SwitchableCompID_values[0]}))}/>
					</Row>
					{config.cycleEntries.map((entry, index)=>{
						return (
							<Row key={index} mt={5}>
								<Text style={{minWidth: 12}}>{index + 1}:</Text>
								<Select ml={5} options={SwitchableCompID_values} value={entry.compID} onChange={val=>ChangeConfig(c=>c.cycleEntries[index].compID = val)}/>
								<Text ml={5}>For launch type:</Text>
								<Select ml={5} enabled={enabled}
									options={[
										{name: "any", value: null},
										...LaunchType_values,
									]} 
									value={entry.requiredLaunchType}
									onChange={val=>ChangeConfig(c=>c.cycleEntries[index].requiredLaunchType = val)}/>
								<Button ml={5} mdIcon="delete" enabled={enabled} onClick={()=>ChangeConfig(c=>c.cycleEntries.RemoveAt(index))}/>
							</Row>
						);
					})}
				</>}
			</Column>
		);
	}
}