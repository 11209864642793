import {AddSchema} from "mobx-firelink";
import {TriggerSet} from "../@TriggerSet";
import {FBAConfig_LightAlarm, FBAConfig_ScriptAlarm, FBAConfig_ShakeAlarm, FBAConfig_SoundAlarm} from "./Alarms/@EC_BasicAlarms";
import {FBAConfig_CountdownAlarm} from "./Alarms/@EC_CountdownAlarm.js";
import {AlarmSequence} from "./Alarms/@AlarmConfig.js";

export type FBAConfig_XAlarm = FBAConfig_SoundAlarm | FBAConfig_CountdownAlarm | FBAConfig_LightAlarm | FBAConfig_ShakeAlarm | FBAConfig_ScriptAlarm;
export const FBAConfig_XAlarm_schema = {anyOf: [
	{$ref: "FBAConfig_SoundAlarm"},
	{$ref: "FBAConfig_CountdownAlarm"},
	{$ref: "FBAConfig_LightAlarm"},
	{$ref: "FBAConfig_ShakeAlarm"},
	{$ref: "FBAConfig_ScriptAlarm"},
]};

export class FBAConfig_Alarms {
	constructor(data: Partial<FBAConfig_Alarms>) { Object.assign(this, data); }

	enabled: boolean;

	// triggers
	/*snooze_triggerSet: TriggerSet;
	microSnooze_triggerSet: TriggerSet;*/
	goPhaseSleep_triggerSet: TriggerSet;
	goPhaseAlarm_triggerSet: TriggerSet;
	goPhaseSolving_triggerSet: TriggerSet;
	signalAwakeness_triggerSet: TriggerSet;

	voiceSoundTag: string;
	volumeMultiplier: number;

	// phase: sleep
	phaseSleep_soundTag: string;
	phaseSleep_lightTag: string;
	// may add the other effect-types later
	alarmDelay_pool: number[]; // in minutes
	alarmDelay_poolRates: {[key: string]: number};
	alarmDelay_lockPerSession: boolean;
	alarmDelay_optionCatchUpRate: number;

	// phase: alarm
	phaseAlarm_sequences: AlarmSequence[];
	phaseAlarm_sequences_lockPerSession: boolean;

	// phase: solving
	phaseSolving_soundTag: string;
	phaseSolving_lightTag: string;
	solveSuccess_message: string;
	solveSuccess_soundPath: string;
	solveSuccess_soundVolume: number;
}
AddSchema("FBAConfig_Alarms", {
	properties: {
		enabled: {type: "boolean"},
		/*snooze_triggerSet: {$ref: "TriggerSet"},
		microSnooze_triggerSet: {$ref: "TriggerSet"},*/
		goPhaseSleep_triggerSet: {$ref: "TriggerSet"},
		goPhaseAlarm_triggerSet: {$ref: "TriggerSet"},
		goPhaseSolving_triggerSet: {$ref: "TriggerSet"},
		signalAwakeness_triggerSet: {$ref: "TriggerSet"},

		sessionUI_hideStatusBar: {type: "boolean"},
		sessionUI_hideNavBar: {type: "boolean"},
		lockOverlay_hideStatusBar: {type: "boolean"},
		lockOverlay_hideNavBar: {type: "boolean"},

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},

		phaseSleep_soundTag: {type: "string"},
		phaseSleep_lightTag: {type: "string"},

		alarmDelay_pool: {items: {type: "number"}},
		alarmDelay_poolRates: {patternProperties: {"^[0-9]+$": {type: "number"}}},
		alarmDelay_lockPerSession: {type: "boolean"},
		alarmDelay_optionCatchUpRate: {type: "number"},
		phaseAlarm_sequences: {items: {$ref: "AlarmSequence"}},
		phaseAlarm_sequences_lockPerSession: {type: "boolean"},
		
		phaseSolving_soundTag: {type: "string"},
		phaseSolving_lightTag: {type: "string"},
		solveSuccess_message: {type: "string"},
		solveSuccess_soundPath: {type: "string"},
		solveSuccess_soundVolume: {type: "number"},
	},
});