import {GetEntries} from "js-vextensions";
import React from "react";
import {GetVoices, InfoButton, Observer, RunInAction_Set, TextPlus} from "web-vcore";
import {Button, CheckBox, Column, Row, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {store} from "../../../../Store/index.js";
import {InAndroid, nativeBridge} from "../../../../Utils/Bridge/Bridge_Native.js";
import {AudioSettingsSubpanel} from "../../../../Store/main/settings.js";
import {useEffect} from "react";
import {GetSelectedFBAConfig} from "../../../../Store/firebase/fbaConfigs.js";
import {ScrollView} from "react-vscrollview";
import {AudioFileEntry, PlaySound_ByContentUri, PlaySound_ByContentUri_ForLiveSession, PlaySound_StopAll} from "../../../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {GetAudioFilesWithSubpath, GetCache} from "../../../../Store/main/cache.js";

@Observer
export class SettingsUI_Audio extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.settings.audio;
		return (
			<>
				<Row mt={5}>
					<Select displayType="button bar" options={GetEntries(AudioSettingsSubpanel, "ui")} value={uiState.subpanel} onChange={val=>uiState.subpanel = val}/>
					{uiState.subpanel == AudioSettingsSubpanel.files &&
					<Row ml="auto">
						<TextInput placeholder="Filter..." value={uiState.audioFilesFilter} onChange={val=>RunInAction_Set(this, ()=>uiState.audioFilesFilter = val)}/>
						<Button ml={5} mdIcon="refresh" onClick={()=>{
							RunInAction_Set(this, ()=>GetCache().audioFiles_updatedRequestedAt = Date.now());
						}}/>
						<Button ml={5} mdIcon="stop" onClick={()=>{
							PlaySound_StopAll();
						}}/>
					</Row>}
				</Row>
				{uiState.subpanel == AudioSettingsSubpanel.config && <ConfigSubpanel/>}
				{uiState.subpanel == AudioSettingsSubpanel.files && <FilesSubpanel/>}
			</>
		);
	}
}

@Observer
class ConfigSubpanel extends BaseComponentPlus({}, {devices: [] as MediaDeviceInfo[]}) {
	async ComponentDidMount() {
		this.RefreshDevices();
	}
	async RefreshDevices() {
		const devices = navigator.mediaDevices ? await navigator.mediaDevices.enumerateDevices() : [];
		this.SetState({devices});
	}
	
	render() {
		const uiState = store.main.settings.audio;

		const {devices} = this.state;
		const microphoneOptions = ([] as {name: string, value: string|null}[])
			.concat({name: "(computer default)", value: null})
			.concat(devices.filter(a=>a.kind == "audioinput").map((device, index)=>({name: `${device.label} [${index}]`, value: device.deviceId})));
		const voiceOptions = [{name: "", value: null} as any].concat(GetVoices().map(a=>a.name));
		return (
			<>
				<Row mt={5}>
					<Text>Main microphone:</Text>
					<Select ml={5} options={microphoneOptions} value={uiState.mainMicrophoneID} onChange={val=>RunInAction_Set(this, ()=>uiState.mainMicrophoneID = val)}/>
				</Row>
				<Row center mt={5}>
					<Text>Main voice:</Text>
					<Select ml={5} options={voiceOptions} value={uiState.mainVoiceName} onChange={val=>RunInAction_Set(this, ()=>uiState.mainVoiceName = val)}/>
					<InfoButton ml={5} text={`
						This voice is used whenever the target voice is unspecified or missing. (eg. for sound-effects created by others)

						Important note: The Google voices appear to have a duration limit! If for long text (eg. dream narration), use the native/offline voices instead.
					`.AsMultiline(0)}/>
				</Row>
				<Row center mt={5}>
					<Text>Voice speed multiplier:</Text>
					<Spinner ml={5} min={0} max={1000} value={uiState.voiceSpeedMultiplier.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.voiceSpeedMultiplier = val.FromPercent())}/>
					<Text> %</Text>
					<InfoButton ml={5} text={`Useful in case this device's text-to-speech engine talks faster/slower than that of your other device(s).`}/>
				</Row>
				<Row center mt={5}>
					<CheckBox text="Use ringtone channel for voice." value={uiState.voiceOnRingtoneChannel} onChange={val=>RunInAction_Set(this, ()=>uiState.voiceOnRingtoneChannel = val)}/>
				</Row>

				{InAndroid(1) &&
				<>
					<Row mt={10}>Sessions: System start volume (one per channel)...</Row>
					<Column mt={5} ml={15}>
						<Row center>
							<CheckBox text="Media (eg. touch beeps, voice channel opt-1):" value={uiState.sessions_systemStartVolume_media_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.sessions_systemStartVolume_media_enabled = val)}/>
							<Spinner ml={5} min={0} max={100} enabled={uiState.sessions_systemStartVolume_media_enabled}
								value={(uiState.sessions_systemStartVolume_media * 100).RoundTo(1)} onChange={val=>RunInAction_Set(this, ()=>uiState.sessions_systemStartVolume_media = val / 100)}/>
							<Text> %</Text>
							<InfoButton ml={5} text={`Note: Media stream is used for the web-based synthesized sounds; and the TTS voice, if "Use ringtone channel for voice" is disabled.`}/>
						</Row>
						<Row center mt={5}>
							<CheckBox text="Call (eg. yt videos):" value={uiState.sessions_systemStartVolume_call_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.sessions_systemStartVolume_call_enabled = val)}/>
							<Spinner ml={5} min={0} max={100} enabled={uiState.sessions_systemStartVolume_call_enabled}
								value={(uiState.sessions_systemStartVolume_call * 100).RoundTo(1)} onChange={val=>RunInAction_Set(this, ()=>uiState.sessions_systemStartVolume_call = val / 100)}/>
							<Text> %</Text>
							<InfoButton ml={5} text="Note: Call stream is used for youtube videos, for some reason. (on my old phone anyway)"/>
						</Row>
						<Row center mt={5}>
							<CheckBox text="Ringtone (eg. voice channel opt-2):" value={uiState.sessions_systemStartVolume_ringtone_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.sessions_systemStartVolume_ringtone_enabled = val)}/>
							<Spinner ml={5} min={0} max={100} enabled={uiState.sessions_systemStartVolume_ringtone_enabled}
								value={(uiState.sessions_systemStartVolume_ringtone * 100).RoundTo(1)} onChange={val=>RunInAction_Set(this, ()=>uiState.sessions_systemStartVolume_ringtone = val / 100)}/>
							<Text> %</Text>
							<InfoButton ml={5} text={`Note: Ringtone channel is used for the TTS voice, if "Use ringtone channel for voice" is enabled.`}/>
						</Row>
					</Column>
				</>}

				<Row mt={10}>
					<TextPlus info={loudnessCurveHelp}>Loudness curves:</TextPlus>
					<Text ml={5}>MediaPlayer:</Text>
					<Spinner ml={5} min={0} max={10} step={.1} value={uiState.loudnessCurve_mediaPlayer} onChange={val=>RunInAction_Set(this, ()=>uiState.loudnessCurve_mediaPlayer = val)}/>
					<Text ml={5}>SystemTTS:</Text>
					<Spinner ml={5} min={0} max={10} step={.1} value={uiState.loudnessCurve_systemTTS} onChange={val=>RunInAction_Set(this, ()=>uiState.loudnessCurve_systemTTS = val)}/>
				</Row>
			</>
		);
	}
}

export const loudnessCurveHelp = `
	Many audio APIs have a "volume" parameter that actually represents energy (eg. loudness^2) rather than perceptual-loudness.
	Fix: We apply a transformation, of Math.pow(volume, curve), just before sending a volume (ie. loudness) value to the API.
	Result: A linear increase in the volume value, results in a linear increase in the perceived loudness.

	Determining the curve:
	1 = make no adjustment (ie. the api's volume parameter already represents perceptual-loudness)
	<1 = use if perceptual-loudness was "changing faster" near 100% (rare, if ever, that an api/device actually does this!)
	>1 = use if perceptual-loudness was "changing faster" near 0% (common; for this case, a value of 2 is generally used)
	See: https://ux.stackexchange.com/a/116300
`.AsMultiline(0);

@Observer
class FilesSubpanel extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.settings.audio;
		const fbaConfig = GetSelectedFBAConfig();

		return (
			<>
				<ScrollView>
					{GetAudioFilesWithSubpath(uiState.audioFilesFilter).map((file, index)=>{
						return (
							<Row key={index} mt={5}>
								<Row style={{width: "calc(100% - 70px)"}}>
									<Text style={{wordBreak: "break-word"}}>{file.filePath}</Text>
									<InfoButton ml={5} text={`ContentUri: ${file.contentUri}`}/>
								</Row>
								<Row ml="auto" center>
									<Button mdIcon="play" onClick={async()=>{
										// todo: see if there's a better way to set defaults than by reading from the dream-quiz comp
										PlaySound_ByContentUri(file.contentUri, fbaConfig?.dreamQuiz?.soundVolume ?? 1, fbaConfig?.dreamQuiz?.soundDurationLimit ?? -1, false);
									}}/>
									<InfoButton ml={5} text="Note that the play button uses the volume and duration-limit settings from the selected engine-config's dream-quiz section. (if set)"/>
								</Row>
							</Row>
						);
					})}
				</ScrollView>
			</>
		);
	}
}