import {store} from "../../../../Store/index.js";
import {GetActivities} from "../../../../Store/firebase/activities.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {InDesktop} from "../../../../Utils/Bridge/Bridge_Native.js";
import {processID} from "../../../../Utils/Bridge/Bridge_RemoteUserProcesses.js";
import {InfoButton, minuteInMS, Observer, RunInAction_Set} from "web-vcore";
import {Timer} from "js-vextensions";
import {Button, CheckBox, Column, Row, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {GetLiveFBASession_Reactive, liveFBASession, StartHostSession, StopHostSession} from "../../../../Engine/FBASession.js";
import {AlarmsComp, AlarmsPhase} from "../../../../Engine/FBASession/Components/AlarmsComp.js";
import {FBASessionPanels_SharedProps} from "../FBAConfigPanel_Local.js";

@Observer
export class SessionManagementPanel_Local extends BaseComponentPlus({} as FBASessionPanels_SharedProps, {}) {
	statusRefreshTimer = new Timer(500, ()=>{
		if (liveFBASession && this.mounted) {
			this.Update();
		}
	});
	ComponentDidMount() {
		document.addEventListener("pointerlockchange", this.onPointerLockChange);
		document.addEventListener("pointerlockerror", this.onPointerLockError);
		this.statusRefreshTimer.Start();
	}
	ComponentWillUnmount() {
		document.removeEventListener("pointerlockchange", this.onPointerLockChange);
		document.removeEventListener("pointerlockerror", this.onPointerLockError);
		this.statusRefreshTimer.Stop();
	}
	onPointerLockChange = ()=>this.Update();
	onPointerLockError = ()=>this.Update();

	emptyBox: HTMLDivElement|n;
	render() {
		const {active, config_full: config} = this.props;
		const uiState = store.main.tools.engine;
		const liveSession = GetLiveFBASession_Reactive();
		const liveAlarmsComp = liveSession?.Comp(AlarmsComp);
		const activities = GetActivities(MeID());

		const largeVersion = window.outerWidth >= 1024;
		const remoteSessionRunning = liveSession?.IsClient();
		return (
			<Column mb={40} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row>
					<Text style={{fontSize: 18}}>Session management</Text>
					<Text ml={5} sel style={{fontSize: 12, opacity: .5}}>(pid: {processID})</Text>
				</Row>

				<Row mt={5}>
					{!active && <>
						<Button text="Start (local)" enabled={config != null && !remoteSessionRunning} onClick={async()=>{
							StartHostSession();
						}}/>
						<Button ml={5} text="Start (local, daytime-mode)" enabled={config != null && !remoteSessionRunning} onClick={async()=>{
							StartHostSession("night");
						}}/>
						<InfoButton ml={5} text={`
							Daytime-mode disables certain features, like alarms. (affected options tinted light blue)
						`.AsMultiline(0)}/>
					</>}
					{active &&
					<>
						{InDesktop() &&
						<>
							<Button text="Stop (save local + online)" enabled={config != null && MeID() != null && !remoteSessionRunning} onClick={async()=>{
								StopHostSession(true, true);
							}}/>
							<Button ml={5} text="Stop (save local)" enabled={config != null && !remoteSessionRunning} onClick={async()=>{
								StopHostSession(true, false);
							}}/>
						</>}
						{!InDesktop() &&
						<>
							<Button text="Stop (save online)" enabled={config != null && MeID() != null && !remoteSessionRunning} onClick={async()=>{
								StopHostSession(false, true);
							}}/>
						</>}
						<Button ml={5} text="Stop (discard)" enabled={config != null && !remoteSessionRunning} onClick={async()=>{
							/*ShowMessageBox({
								title: "Delete session folder?", cancelButton: true,
								message: "Place this session's folder into the trash?",
								onOK: ()=>{
									StopHostSession(false, false);
								},
							});*/
							// this only puts it into the trash, so no need for confirm-box
							StopHostSession(false, false);
						}}/>
					</>}
					<CheckBox ml={10} text="Allow client connections" value={uiState.allowClientConnections} onChange={val=>RunInAction_Set(this, ()=>uiState.allowClientConnections = val)}/>
				</Row>

				{liveFBASession &&
					<>
						{liveFBASession.components.map((comp, index)=>{
							if (comp.GetStatusUI == null) return null;
							return <Column mt={5} key={index}>
								{comp.constructor.name}: {comp.GetStatusUI()}
							</Column>;
						})}
						<CheckBox mt={50} text="Lock mouse (esc to end)" value={document.pointerLockElement != null} onChange={val=>{
							if (val) {
								this.emptyBox!.requestPointerLock();
							} else {
								document.exitPointerLock();
							}
						}}/>
						<Button mt={5} text="Snooze (5m)" style={{height: 200}} enabled={liveAlarmsComp != null} onClick={()=>{
							//liveFBASession!.Comp(AlarmsComp).Snooze({logOverride: "Snoozing (on-screen button)"});
							//liveAlarmsComp!.SetSolveWaitDuration(5 * minuteInMS);
							liveAlarmsComp!.SetPhase(AlarmsPhase.Solving);
							liveAlarmsComp!.NotifySolveProgress(5 * 60);
						}}/>
						<div ref={c=>this.emptyBox = c}/>
					</>}
			</Column>
		);
	}
}