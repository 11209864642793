import {Clone, Timer} from "js-vextensions";
import {default as Moment, default as moment} from "moment";
import React, {useEffect} from "react";
import {Button, Column, Row, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {Link, Observer} from "web-vcore";
import {UpdateJournalEntry} from "../../Server/Commands/UpdateJournalEntry.js";
import {SessionPeriod} from "../../Store/firebase/@Shared/SessionPeriod.js";
import {GetSessionPeriods} from "../../Store/firebase/@Shared/SessionPeriodUtils.js";
import {GetEntities_WithUserTag} from "../../Store/firebase/entities.js";
import {GetDreamEvents, GetJournalEntries} from "../../Store/firebase/journalEntries.js";
import {GetTermsInDreamSegmentText, JournalEntry, JournalSegment} from "../../Store/firebase/journalEntries/@JournalEntry.js";
import {GetSessions} from "../../Store/firebase/sessions.js";
import {MeID} from "../../Store/firebase/users.js";
import {store} from "../../Store/index.js";
import {JournalFilterState} from "../../Store/main/journal.js";
import {GetSegmentAllText as GetJournalSegmentAllText} from "./EntryList.js";
import {SegmentUI_Dream, SegmentUI_Wake} from "./JournalEntry/SegmentUI.js";
import {SessionEventsUI} from "./JournalEntry/SessionEventsUI.js";

export const GetNextJournalEntryChronologically = (entry: JournalEntry)=>{
	const entries_sortedByDate = GetJournalEntries(MeID(), "asc");
	const index = entries_sortedByDate.indexOf(entry);
	if (index == -1) return null;
	return entries_sortedByDate[index + 1] as JournalEntry|n;
};

export const DoesSegmentMatchFilter = (segment: JournalSegment, filter: JournalFilterState, applyGeneralTextFiltering = true)=>{
	if (applyGeneralTextFiltering) {
		const filterWords = filter.text_include.toLowerCase().split(" ").filter(a=>a.trim().length);
		if (filterWords.length) {
			const allText = GetJournalSegmentAllText(segment);
			if (!filterWords.every(word=>allText.toLowerCase().includes(word))) return false;
		}
	}
	if (filter.showOnlyIntegratable && segment.excludeFromIntegration) return false;
	const dreamEvents = GetDreamEvents(segment);
	if (filter.dreamEvents_enabled && filter.dreamEvents_min != -1 && dreamEvents.length < filter.dreamEvents_min) return false;
	if (filter.dreamEvents_enabled && filter.dreamEvents_max != -1 && dreamEvents.length > filter.dreamEvents_max) return false;
	const words = GetTermsInDreamSegmentText(segment.longText);
	if (filter.words_enabled && filter.words_min != -1 && words.length < filter.words_min) return false;
	if (filter.words_enabled && filter.words_max != -1 && words.length > filter.words_max) return false;
	return true;
};

@Observer
export class JournalEntryPreview extends BaseComponent<{entry: JournalEntry, entriesToShow: JournalEntry[], journalPage: boolean}, {}> {
	render() {
		const {entry, entriesToShow, journalPage} = this.props;
		const index = entriesToShow.indexOf(entry);
		const nextEntryChronologically: JournalEntry|n = GetNextJournalEntryChronologically(entry);
		const uiState1 = store.main.journal;
		const filter = uiState1.filter;
		const uiState2 = store.main.tools.journey;
		const nextEntry = entriesToShow[entriesToShow.indexOf(entry) + 1];

		// if last entry, and on Journey page, we may be for the live-session; so add tracker on live-session events (well, logs)
		// commented; mobx-accesses within GetSessionPeriods() call-tree makes this unnecessary
		/*if (nextEntry == null && !journalPage) {
			const tracker1 = store.main.tools.engine.liveFBASession_loggedAt; // line's effect: refresh this comp when session-logs are added

			// also auto-refresh once per second (eg. to update the displayed wait-period, if last event is a wait-period)
			/*useEffect(()=>{
				const timer = new Timer(1000, ()=>this.Update()).Start();
				return ()=>timer.Stop();
			});*#/
		}*/

		const sleepTimeStr = entry.sleepTime ? moment(entry.sleepTime).format("YYYY-MM-DD HH:mm:ss") : "(unknown)";
		const wakeTimeStr = entry.wakeTime
			? moment(entry.wakeTime).format(`${sleepTimeStr == "(unknown)" ? "YYYY-MM-DD " : ""}HH:mm:ss`)
			: "(unknown)";

		let showEventBoxesSection = false;

		let sessionPeriodForJournalEntry: SessionPeriod|n;
		if (uiState2.showEventBoxes) {
			//const aggregationData = JStatsUI_GetAggregationData_Standard();
			const sessions = GetSessions(MeID());
			const dreams = GetJournalEntries(MeID());
			const sessionPeriods = GetSessionPeriods(dreams, sessions);
			sessionPeriodForJournalEntry = sessionPeriods.find(a=>a.journalEntries.includes(entry));
			if (sessionPeriodForJournalEntry && sessionPeriodForJournalEntry.eventGroups.length > 0) {
				showEventBoxesSection = true;
			}
		}

		const segmentsToShow = entry.segments.filter(segment=>{
			if (journalPage && filter.filterWithinJournalEntry && !DoesSegmentMatchFilter(segment, filter)) return false;
			return true;
		});

		const anchorEntities = GetEntities_WithUserTag("anchor");
		return (
			<Column mt={30} pl={10} style={{position: "relative"}}>
				{journalPage &&
				<Link style={{display: "flex", fontSize: 13}}
					actionFunc={s=>s.main.journal.selectedJournalEntry = entry._key}
					onContextMenu={e=>e.nativeEvent["passThrough"] = true}
				>
					<span style={{fontSize: 17}}>
						{entry.title.trim().length ? entry.title : "(unnamed)"}
						<span style={{marginLeft: 5, fontSize: 11}}>{`(${sleepTimeStr} -> ${wakeTimeStr})`}</span>
					</span>
				</Link>}
				<div style={{position: "absolute", left: 0, top: 0, bottom: 0, width: 10, background: "gray"}}/>
				<Row>
					<Text>Sleep start: {
						entry.sleepTime ? Moment(entry.sleepTime).format("YYYY-MM-DD HH:mm:ss") :
						entry.wakeTime ? `(unknown; wake time: ${Moment(entry.wakeTime).format("YYYY-MM-DD HH:mm:ss")})` :
						"(unknown)"
					}</Text>
				</Row>
				{showEventBoxesSection &&
				<SessionEventsUI entry={entry} nextEntry={nextEntryChronologically} period={sessionPeriodForJournalEntry!}/>}

				{segmentsToShow.map((segment, index)=>{
					// rerender on details expand/collapse (current way for user to manually refresh the session-events ui) // probably temp
					const onToggleExpanded = ()=>this.Update();
					if (segment.wakeTime != null) {
						return <SegmentUI_Wake key={index} {...{entry, segment, segmentIndex: index, onToggleExpanded}}/>;
					}
					return <SegmentUI_Dream key={index} {...{entry, entriesToShow, segment, segmentIndex: index, journalPage, onToggleExpanded}}/>;
				})}
				{entry.wakeTime &&
				<Row>
					<Text onClick={()=>{
						// if this is the last journal-entry, let user click the wake-time label to remove the wake-time
						if (nextEntry == null) {
							ShowMessageBox({
								title: `Convert sleep-wake into wake-period?`, cancelButton: true,
								message: `
									Remove wake-time for this journal entry, and add a wake-period in its place?
									* Base effect: It's sort of like "resuming"/"recontinuing" the existing entry.
									* Motivation: For example, to enable journey system's transcription into its dream-segment.
								`.AsMultiline(0),
								onOK: ()=>{
									const segments_new = Clone(entry.segments) as JournalSegment[];
									segments_new.push(new JournalSegment({wakeTime: entry.wakeTime}));
									new UpdateJournalEntry({id: entry._key, updates: {
										wakeTime: null,
										segments: segments_new,
									}}).Run();
								},
							});
						}
					}}>
						Sleep end: {Moment(entry.wakeTime).format("YYYY-MM-DD HH:mm:ss")}
					</Text>
				</Row>}
				<Row mt={5}>
					<Text>Add:</Text>
					<Button p="3px 7px" ml={5} text="Dream" onClick={async()=>{
						const segments_new = Clone(entry.segments) as JournalSegment[];
						segments_new.push(new JournalSegment({
							anchorEntity: anchorEntities.Random()?._key,
						}));
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
					<Button p="3px 7px" ml={5} text="Wake" onClick={async()=>{
						const segments_new = Clone(entry.segments) as JournalSegment[];
						segments_new.push(new JournalSegment({
							wakeTime: Date.now(),
						}));
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
					<Button p="3px 7px" ml={5} text="Wake + dream" onClick={async()=>{
						const segments_new = Clone(entry.segments) as JournalSegment[];
						segments_new.push(new JournalSegment({
							wakeTime: Date.now(),
						}));
						segments_new.push(new JournalSegment({
							anchorEntity: anchorEntities.Random()?._key,
						}));
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
					{/*<Button ml={5} p="1px 7px" text="Delete last segment" enabled={entry.segments.length > 0} onClick={async()=>{
						ShowMessageBox({
							title: `Delete last segment`, cancelButton: true,
							message: `Delete last segment?`,
							onOK: ()=>{
								const segments_new = Clone(entry.segments) as JournalSegment[];
								segments_new.RemoveAt(segments_new.length - 1);
								new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
							},
						});
					}}/>*/}
				</Row>
			</Column>
		);
	}
}