import {EEGPattern, FBAConfig_EEG} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_EEG.js";
import {Muse_eegSamplesPerSecond_raw} from "../../@Shared/MuseInterface/EEGStructs.js";
import {InfoButton, TextPlus} from "web-vcore";
import {Button, CheckBox, Column, Row, RowLR, Spinner, Text, TimeSpanInput} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {AlarmSequenceUI} from "./Alarms_ConfigUI.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.eeg, (c, v)=>c.eeg = v);

export class EEG_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		const splitAt = 260;
		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="EEG" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info="Round individual samples to integers, to save on storage space. (due to window-based averaging, this shouldn't impact behavior significantly)">Round samples:</TextPlus>
						<CheckBox ml={15} enabled={enabled} value={config.useSampleRounding} onChange={val=>ChangeConfig(c=>c.useSampleRounding = val)}/>
					</RowLR>

					<RowLR mt={5} splitAt={splitAt}>
						<Text>Record:</Text>
						<CheckBox text="Base L+R" ml={15} enabled={enabled} value={config.recordData_raw} onChange={val=>ChangeConfig(c=>c.recordData_raw = val)}/>
						<InfoButton ml={5} text="The history of raw eeg samples for the left-forehead and right-forehead channels. Storage cost (with rounding): ~3mb/hour (unrounded is ~3x that)"/>
						<CheckBox text="Extended L+R" ml={10} enabled={enabled} value={config.recordData_extended} onChange={val=>ChangeConfig(c=>c.recordData_extended = val)}/>
						<InfoButton ml={5} text="The history of raw eeg samples for the left-ear and right-ear channels. Needed for sleep-stage classifier. Storage cost (with rounding): ~3mb/hour (unrounded is ~3x that)"/>
						<CheckBox text="EEG-activity" ml={10} enabled={enabled} value={config.recordData_activity} onChange={val=>ChangeConfig(c=>c.recordData_activity = val)}/>
						<InfoButton ml={5} text="The history of eeg-activity values. Storage cost: ~1.7kb per hour"/>
					</RowLR>

					<Row mt={5}>
						<CheckBox text="Detect eye moves" enabled={enabled} value={config.detectMotion} onChange={val=>ChangeConfig(c=>c.detectMotion = val)}/>
					</Row>

					{config.detectMotion &&
					<Column ml={15}>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text>Normalized-deviation window:</Text>
								<InfoButton ml={5} text={`
									Application: Sets size of window used for calculating the normalized-deviation. (by averaging over window, then subtracting that average from the sample's raw eeg-value)${""/*

									Also used for calculating the floored-deviation (step 4), by retrieving percentile-X of combined-deviations (step 3) over window.*/}
								`.AsMultiline(0)}/>
							</Row>
							<TimeSpanInput enabled={enabled}
								value={config.motion_sampleTrigger_normalizationWindowSize} onChange={val=>ChangeConfig(c=>c.motion_sampleTrigger_normalizationWindowSize = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text>Smoothed-deviation window:</Text>
								<InfoButton ml={5} text={`
									Application: Sets how many samples to use, for averaging the last X samples' normalized-deviation values, to determine its smoothed-deviation value. (1 for no smoothing)
									
									(The Muse S device outputs ${Muse_eegSamplesPerSecond_raw} eeg samples per second.)
								`.AsMultiline(0)}/>
							</Row>
							<Spinner enabled={enabled} min={1}
								value={config.motion_sampleTrigger_smoothing} onChange={val=>ChangeConfig(c=>c.motion_sampleTrigger_smoothing = val)}/>
						</RowLR>
						<Row mt={5}>
							<Text>EEG patterns for eye-move detection:</Text>
							<Button ml={5} enabled={enabled && !client} text="Add pattern" onClick={()=>{
								ChangeConfig(c=>c.motion_eegPatterns.push(new EEGPattern({
									enabled: true,
									activityIncrease: 1,
								})));
							}}/>
						</Row>
						{config.motion_eegPatterns.map((pattern, index)=>{
							return <EEGPatternUI key={index} {...sharedProps} pattern={pattern} index={index} enabled={enabled}/>;
						})}

						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text>Max eeg-activity:</Text>
								<InfoButton ml={5} text={`
									Not a true/raw max; rather, decay reduces from it, as if max.
									
									(avoids eeg-activity counter increasing so much that prompts persist far past actual movements)
								`.AsMultiline(0)}/>
							</Row>
							<Spinner enabled={enabled}
								value={config.motion_maxActivity} onChange={val=>ChangeConfig(c=>c.motion_maxActivity = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info="Every Y minutes/seconds, the eeg-activity value is automatically decreased by X amount.">EEG-activity decay:</TextPlus>
							<Text>by: </Text>
							<Spinner enabled={enabled}
								value={config.motion_activityDecayAmount} onChange={val=>ChangeConfig(c=>c.motion_activityDecayAmount = val)}/>
							<Text>, per: </Text>
							<TimeSpanInput enabled={enabled}
								value={config.motion_activityDecayInterval} onChange={val=>ChangeConfig(c=>c.motion_activityDecayInterval = val)}/>
							<CheckBox ml={10} enabled={enabled} text="Motions interrupt"
								value={config.motion_activityDecay_motionTriggersInterrupt} onChange={val=>ChangeConfig(c=>c.motion_activityDecay_motionTriggersInterrupt = val)}/>
							<InfoButton ml={5} text="If enabled, whenever a motion-trigger occurs, the timer for the next eeg-activity decay is reset. (eg. preventing eeg-activity decay during series of motion-triggers)"/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info="Whenever the eeg-activity value hits the first level, it is reset to the second value. (if first value is zero, resetting is disabled)">EEG-activity reset:</TextPlus>
							<Text>when reaches: </Text>
							<Spinner enabled={enabled}
								value={config.motion_activityReset_at} onChange={val=>ChangeConfig(c=>c.motion_activityReset_at = val)}/>
							<Text>, reset to: </Text>
							<Spinner enabled={enabled}
								value={config.motion_activityReset_to} onChange={val=>ChangeConfig(c=>c.motion_activityReset_to = val)}/>
						</RowLR>

						<AlarmSequenceUI {...sharedProps} group="eeg" sequence={config.alarmSequence} sequenceIndex={0}/>
					</Column>}
				</>}
			</Column>
		);
	}
}

class EEGPatternUI extends BaseComponentPlus({} as {pattern: EEGPattern, index: number, enabled: boolean} & FBASessionPanels_SharedProps, {}) {
	render() {
		const {pattern, index, enabled, config_full, ChangeConfig_Full} = this.props;
		const ChangePattern = (changerFunc: (pattern: EEGPattern)=>any)=>{
			ChangeConfig_Full(c=>changerFunc(c.eeg.motion_eegPatterns[index]));
		};

		const splitAt = 245;
		return (
			<Column mt={index == 0 ? 0 : 5} p={5} style={{background: "rgba(0,0,0,.3)", borderRadius: 5}}>
				<Row style={{fontSize: 15}}>
					<CheckBox text={`#${index + 1}`} enabled={enabled} value={pattern.enabled} onChange={val=>ChangePattern(c=>c.enabled = val)}/>
					<Button ml={5} faIcon="arrow-up" title="Move up" enabled={enabled && index > 0} onClick={()=>{
						ChangeConfig_Full(c=>{
							const entries = c.eeg.motion_eegPatterns;
							entries.Move(entries[index], index - 1);
						});
					}}/>
					<Button ml={5} faIcon="arrow-down" title="Move down" enabled={enabled && index < config_full.eeg.motion_eegPatterns.length - 1} onClick={()=>{
						ChangeConfig_Full(c=>{
							const entries = c.eeg.motion_eegPatterns;
							entries.Move(entries[index], index + 1);
						});
					}}/>
					<Button ml={5} faIcon="trash" title="Delete" enabled={enabled} onClick={val=>{
						ShowMessageBox({
							title: "Delete EEG pattern?", cancelButton: true,
							message: `Permanently delete this pattern?`,
							onOK: ()=>{
								ChangeConfig_Full(c=>{
									const entries = c.eeg.motion_eegPatterns;
									entries.RemoveAt(index);
								});
							},
						});
					}}/>
				</Row>
				{pattern.enabled &&
				<Column ml={15}>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Width:</Text>
						<CheckBox text="Min:" value={pattern.width_min != null} onChange={val=>ChangePattern(p=>p.width_min = val ? 0 : null)}/>
						<Spinner ml={5} enabled={enabled} step={.1} value={pattern.width_min!} onChange={val=>ChangePattern(p=>p.width_min = val)}/>
						<CheckBox ml={5} text="Max:" value={pattern.width_max != null} onChange={val=>ChangePattern(p=>p.width_max = val ? 0 : null)}/>
						<Spinner ml={5} enabled={enabled} step={.1} value={pattern.width_max!} onChange={val=>ChangePattern(p=>p.width_max = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Max-height:</Text>
						<CheckBox text="Min:" value={pattern.height_min != null} onChange={val=>ChangePattern(p=>p.height_min = val ? 0 : null)}/>
						<Spinner ml={5} enabled={enabled} step={.1} value={pattern.height_min!} onChange={val=>ChangePattern(p=>p.height_min = val)}/>
						<CheckBox ml={5} text="Max:" value={pattern.height_max != null} onChange={val=>ChangePattern(p=>p.height_max = val ? 0 : null)}/>
						<Spinner ml={5} enabled={enabled} step={.1} value={pattern.height_max!} onChange={val=>ChangePattern(p=>p.height_max = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Area:</Text>
						<CheckBox text="Min:" value={pattern.area_min != null} onChange={val=>ChangePattern(p=>p.area_min = val ? 0 : null)}/>
						<Spinner ml={5} enabled={enabled} step={.1} value={pattern.area_min!} onChange={val=>ChangePattern(p=>p.area_min = val)}/>
						<CheckBox ml={5} text="Max:" value={pattern.area_max != null} onChange={val=>ChangePattern(p=>p.area_max = val ? 0 : null)}/>
						<Spinner ml={5} enabled={enabled} step={.1} value={pattern.area_max!} onChange={val=>ChangePattern(p=>p.area_max = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<CheckBox text="Max dist from baseline:" value={pattern.maxDistFromBaseline != null} onChange={val=>ChangePattern(p=>p.maxDistFromBaseline = val ? 0 : null)}/>
						<Spinner enabled={enabled} step={.1} min={undefined} value={pattern.maxDistFromBaseline!} onChange={val=>ChangePattern(p=>p.maxDistFromBaseline = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Activity increase:</Text>
							<InfoButton ml={5} text={`How much to increase the eeg-activity variable, per motion-trigger.`}/>
						</Row>
						<Spinner enabled={enabled} value={pattern.activityIncrease} onChange={val=>ChangePattern(p=>p.activityIncrease = val)}/>
					</RowLR>
				</Column>}
			</Column>
		);
	}
}