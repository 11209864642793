import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export class FBAConfig_JourneyEntry {
	constructor(initialData: Partial<FBAConfig_JourneyEntry>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	prevEntity_triggerSet: TriggerSet;
	nextEntity_triggerSet: TriggerSet;
	// todo: add customization of the other triggers
	/*moreActions_triggerSet: TriggerSet;
	mainAction_triggerSet: TriggerSet;*/

	voiceSoundTag: string;
	volumeMultiplier: number;
}
AddSchema("FBAConfig_JourneyEntry", {
	properties: {
		enabled: {type: "boolean"},

		prevEntity_triggerSet: {$ref: "TriggerSet"},
		nextEntity_triggerSet: {$ref: "TriggerSet"},
		/*moreActions_triggerSet: {$ref: "TriggerSet"},
		mainAction_triggerSet: {$ref: "TriggerSet"},*/

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},
	},
});