import {Lerp, Timer, Vector2, VRect, Clone, GetStackTraceStr} from "js-vextensions";
import {autorun, makeObservable} from "mobx";
import {store} from "../../../Store/index.js";
import {GetSelectedFBAConfig} from "../../../Store/firebase/fbaConfigs.js";
import {FBAConfig} from "../../../Store/firebase/fbaConfigs/@FBAConfig.js";
import {CameraConfig, CameraType} from "../../../Store/main/tools/@CameraConfig.js";
import {OnStoreLoaded} from "../../../Utils/General/GlobalHooks.js";
import {FitSourceSizeIntoContainerSize, CopyImageData} from "../../../Utils/UI/CanvasUtils.js";
import {AddErrorMessage, AddNotificationMessage, O, RunInAction_Set} from "web-vcore";
import {IPCamera_StartStreaming, IPCamera_StopStreaming} from "../../../Utils/Bridge/Bridge_Preload.js";
import {CameraPanel_Monitor} from "../Monitor/CameraPanel.js";
import {SessionLog} from "./BetweenSessionTypes/SessionLog.js";
import {CameraComp} from "../../../Engine/FBASession/Components/CameraComp.js";
import {LogType} from "./LogEntry.js";

//export type FrameBufferListener = (frameData: Buffer) => void;
//export type FrameListener = (frameData: HTMLImageElement) => void;
//export type FrameWithExtrasListener = (frameData: HTMLImageElement, ) => void;

export class FrameSummary {
	time: number;
	totalDelta: number;
	triggered: boolean;
}

export class CameraInterface {
	constructor(config: CameraConfig) {
		makeObservable(this);
		this.cameraConfig = config;
		//this.cameraConfig = Clone(config); // use clone, avoiding mobx-getters (improves perf in pixel-processing loop)
		this.StartEngineConfigMirrorer();
		this.StartStreamToggler();
	}
	StartEngineConfigMirrorer() {
		autorun(()=>{
			this.engineConfig = GetSelectedFBAConfig();
		});
	}
	StartStreamToggler() {
		autorun(()=>{
			this.MaybeToggleStreaming();
		}, {name: "CameraInterface.StreamToggler"});
	}

	streaming = false;
	streamStartTime: number;
	MaybeToggleStreaming() {
		let newStreaming = false;
		//if (fbaCurrentSession && fbaCurrentSession.IsLocal() && fbaCurrentSession.running && fbaCurrentSession.c.camera.enabled && fbaCurrentSession.c.camera.recordMotion) {
		if (this.engineCameraComp?.c.camMotion_record) {
			newStreaming = true;
		}
		if (store.main.tools.monitor.preview) {
			newStreaming = true;
		}
		if (newStreaming == this.streaming) return;
		this.streaming = newStreaming;
		this.streamStartTime = Date.now();

		if (newStreaming) {
			this.bufferCanvas = document.createElement("canvas");
			// buffer-canvas size may change once actual data comes in (due to down-scaling-to-fit)
			this.bufferCanvas.width = store.main.tools.monitor.cameraConfig.resolution_width;
			this.bufferCanvas.height = store.main.tools.monitor.cameraConfig.resolution_height;
			//this.bufferCanvasContext = this.bufferCanvas.getContext("2d", {alpha: false}); // disabling alpha was said to give perf boost
			this.bufferCanvasContext = this.bufferCanvas.getContext("2d")!; // using regular with-alpha config, since alpha:false apparently breaks MediaRecorder recording
			this.bufferCanvasStream = null; // populated and used by CameraComponent (we just clear it here)

			if (this.cameraConfig.type == CameraType.Webcam) {
				this.Webcam_StartStreaming().then(success=>{
					if (!success) {
						const uiState = store.main.tools.monitor;
						RunInAction_Set(this, ()=>uiState.preview = false);
					}
				});
			} else {
				this.IPCam_StartStreaming().then(success=>{
					if (!success) {
						const uiState = store.main.tools.monitor;
						RunInAction_Set(this, ()=>uiState.preview = false);
					}
				});
			}

			this.frameSummaries.length = 0; // clear frame-summaries from previous stream
			// only ip-cams can have their stream freeze (afaik)
			if (this.cameraConfig.type == CameraType.IPCamera) {
				this.streamFreezeChecker.Start();
			}
		} else {
			if (this.cameraConfig.type == CameraType.Webcam) {
				this.Webcam_StopStreaming();
			} else {
				this.IPCam_StopStreaming();
				this.reconnectWaitTimer?.Stop(); // if we had reconnection-attempt going, cancel it
			}

			// close buffer canvas' stream as well, since it's possible it has perf-impact // commenting until confirmed
			/*if (this.bufferCanvasStream) {
				this.bufferCanvasStream.getTracks().forEach(a=>a.stop());
				this.bufferCanvasStream = null;
			}*/

			this.streamFreezeChecker.Stop();
		}
	}

	cameraConfig: CameraConfig;
	engineConfig: FBAConfig|n;

	// buffers (for pathways, webcam: MediaStream->video->canvas->context->ImageData, ip-cam: Buffer[backend]->Uint8Array[frontend]->image->canvas->context->ImageData)
	bufferVideo = document.createElement("video"); // if webcam
	bufferCanvas: HTMLCanvasElement;
	bufferCanvasContext: CanvasRenderingContext2D;
	bufferCanvasStream: MediaStream|n; // populated and used by CameraComponent

	// webcam streaming
	webcamStream_frameChecker: Timer|n;
	webcamStream_frameChecker_lastVideoTime: number;
	Webcam_StartStreaming() {
		return new Promise((resolve, reject)=>{
			(navigator as any).getUserMedia({video: true}, stream=>{
				this.bufferVideo.srcObject = stream;
				this.bufferVideo.play();
				this.webcamStream_frameChecker = new Timer(1000 / this.cameraConfig.framerate, ()=>{
					const newTime = this.bufferVideo.currentTime;
					if (newTime != this.webcamStream_frameChecker_lastVideoTime) {
						this.webcamStream_frameChecker_lastVideoTime = newTime;

						//this.bufferCanvasContext.drawImage(this.bufferVideo, 0, 0);
						const videoSize = new Vector2(this.bufferVideo.videoWidth, this.bufferVideo.videoHeight);
						const targetSize = new Vector2(this.cameraConfig.resolution_width, this.cameraConfig.resolution_height);
						const frameDrawRect = FitSourceSizeIntoContainerSize(videoSize, targetSize);

						// if final frame size differs from buffer-canvas size, change the buffer-canvas size to match (so that recording from it doesn't have gaps)
						this.EnsureBufferCanvasSizeCorrect(frameDrawRect.width, frameDrawRect.height);

						// draw to top-left, with frame-draw-rect size
						this.bufferCanvasContext.drawImage(this.bufferVideo,
							0, 0, this.bufferVideo.videoWidth, this.bufferVideo.videoHeight,
							0, 0, frameDrawRect.width, frameDrawRect.height);
						const imgData = this.bufferCanvasContext.getImageData(0, 0, frameDrawRect.width, frameDrawRect.height);
						this.OnFrameData(imgData, this.bufferVideo);
					}
				}).Start();
				resolve(true);
			}, error=>{
				//reject(error);
				//SessionLog(JSON.stringify(error), LogType.Event_Large);
				console.warn(error);
				resolve(false);
			});
		});
	}
	Webcam_StopStreaming() {
		if (this.webcamStream_frameChecker) {
			this.webcamStream_frameChecker.Stop();
			this.webcamStream_frameChecker = null;
			if (this.bufferVideo.srcObject) {
				(this.bufferVideo.srcObject as MediaStream).getTracks().forEach(a=>a.stop());
				this.bufferVideo.srcObject = null;
				//this.videoEl.pause();
			}
		}
	}

	// ip-cam streaming
	async IPCam_StartStreaming() {
		// IPCamera_StartStreaming is defined in IPCamera.ts of lucid-frontier-desktop
		try {
			await IPCamera_StartStreaming({
				config: Clone(this.cameraConfig), // make plain object, so can pass to backend
				//onFrameData: async(frameData: Uint8Array)=>{
				// the argument received here is not technically an ImageData instance, but it has the same shape, and can be used as one (see ImageUtils.ts in lf-desktop)
				onFrameData: async(imgData: ImageData)=>{
					// jpg
					//this.imgEl.src = `data:image/jpeg;base64,${frameData.toString("base64")}`;
					/*var blob = new Blob([frameData], {type: "application/octet-binary"});
					var url = URL.createObjectURL(blob);*/

					// png
					/*//var blob = new Blob([`data:image/jpeg;base64,${frameData.toString("base64")}`], {type: "application/octet-binary"});
					//var url = `data:image/jpeg;base64,${frameData.toString("base64")}`;
					var url = `data:image/png;base64,${frameData.toString("base64")}`;*/

					/*var img = new Image();
					img.onload = ()=>{ ... };
					img.src = url;*/

					/*imageLoaderPool.Load(url, img=>{
						URL.revokeObjectURL(url);

						// if final frame size differs from buffer-canvas size, change the buffer-canvas size to match (so that recording from it doesn't have gaps)
						if (this.bufferCanvas.width != img.width) this.bufferCanvas.width = img.width;
						if (this.bufferCanvas.height != img.height) this.bufferCanvas.height = img.height;

						// draw to top-left, with raw (ffmpeg scaled-down-to-fit) size
						this.bufferCanvasContext.drawImage(img, 0, 0);
						const imgData = this.bufferCanvasContext.getImageData(0, 0, img.width, img.height);
						this.OnFrameData(img, imgData);
					});*/

					/*var img = new Image();
					img.onload = ()=>{
						// if final frame size differs from buffer-canvas size, change the buffer-canvas size to match (so that recording from it doesn't have gaps)
						if (this.bufferCanvas.width != img.width) this.bufferCanvas.width = img.width;
						if (this.bufferCanvas.height != img.height) this.bufferCanvas.height = img.height;

						// draw to top-left, with raw (ffmpeg scaled-down-to-fit) size
						this.bufferCanvasContext.drawImage(img, 0, 0);
						const imgData = this.bufferCanvasContext.getImageData(0, 0, img.width, img.height);
						this.OnFrameData(imgData);
					};
					img.src = `data:image/jpeg;base64,${frameData.toString("base64")}`;*/

					// the object returned by ConvertJPGBufferToRawBuffer is not technically an ImageData instance, but it has the same shape, and can be used as one
					//const imgData = await ConvertJPGBufferToRawBuffer(frameData) as ImageData;

					// if final frame size differs from buffer-canvas size, change the buffer-canvas size to match (so that recording from it doesn't have gaps)
					this.EnsureBufferCanvasSizeCorrect(imgData.width, imgData.height);

					// if recording is enabled, always draw image-data to buffer-canvas (since that is the source for the MediaRecorders, which run in background)
					if (this.engineCameraComp?.c.camMotion_record) {
						// cpu-usage is slightly (~15%) lower if you keep an ImageData instance, and call data.set on it, rather than calling CopyImageData() each frame
						this.EnsureImgDataBufferSizeCorrect(imgData.width, imgData.height);
						this.imgDataBuffer.data.set(imgData.data);
						this.bufferCanvasContext.putImageData(this.imgDataBuffer, 0, 0);
					}

					this.OnFrameData(imgData);
				},
			});
			return true;
		} catch (err) {
			AddErrorMessage(`Error streaming from ip-cam: ${err}`, err?.stack ?? GetStackTraceStr());
			return false;
		}
	}
	IPCam_StopStreaming() {
		IPCamera_StopStreaming();
	}

	// if this is set, it means a dream-engine session is in progress, with camera-comp active
	@O engineCameraComp: CameraComp|n;
	ConnectEngineCameraComp(comp: CameraComp) {
		RunInAction_Set(this, ()=>this.engineCameraComp = comp);
	}
	DisconnectEngineCameraComp() {
		RunInAction_Set(this, ()=>this.engineCameraComp = null);
	}

	// if this is set, it means the Monitor->Camera page is open/visible
	@O monitorPanel: CameraPanel_Monitor|n;
	ConnectMonitorCameraPanel(panel: CameraPanel_Monitor) {
		RunInAction_Set(this, ()=>this.monitorPanel = panel);
	}
	DisconnectMonitorCameraPanel() {
		RunInAction_Set(this, ()=>this.monitorPanel = null);
	}

	EnsureBufferCanvasSizeCorrect(targetWidth: number, targetHeight: number) {
		if (this.bufferCanvas.width != targetWidth) {
			this.bufferCanvas.width = targetWidth;
		}
		if (this.bufferCanvas.height != targetHeight) {
			this.bufferCanvas.height = targetHeight;
		}
	}

	lastImgData: ImageData;
	imgDataBuffer: ImageData;
	EnsureImgDataBufferSizeCorrect(targetWidth: number, targetHeight: number) {
		if (this.imgDataBuffer == null || this.imgDataBuffer.width != targetWidth || this.imgDataBuffer.height != targetHeight) {
			//this.imgData_temp = this.monitorPanel.canvasContext.createImageData(width, height);
			this.imgDataBuffer = this.bufferCanvasContext.createImageData(targetWidth, targetHeight);
		}
	}

	OnFrameData = (imgData_orig: ImageData, sourceVideo?: HTMLVideoElement)=>{
		const monitorUIState = store.main.tools.monitor;
		//const monitorUIState = Clone(store.main.tools.monitor); // use clone, avoiding mobx-getters (improves perf in pixel-processing loop)
		const targetSize = new Vector2(this.cameraConfig.resolution_width, this.cameraConfig.resolution_height);
		const monitorPreviewing = monitorUIState.preview && this.monitorPanel && this.monitorPanel.mounted && this.monitorPanel.canvasEl!.width > 0;

		// rawImageOrVideoSize will be larger than image-data size, if the source was a video (from webcam), and down-scaling occurred
		const imgDataSize = new Vector2(imgData_orig.width, imgData_orig.height);
		const rawImageOrVideoSize = sourceVideo ? new Vector2(sourceVideo.videoWidth, sourceVideo.videoHeight) : imgDataSize;
		if (imgDataSize.x == 0 && imgDataSize.y == 0) return; // if no real data yet, ignore

		const frameDrawRect = FitSourceSizeIntoContainerSize(imgDataSize, targetSize);
		const ignoreRects_inPixels = this.cameraConfig.ignoreRects.map(rect=>{
			return new VRect(rect.x * imgDataSize.x, rect.y * imgDataSize.y, rect.width * imgDataSize.x, rect.height * imgDataSize.y);
		});

		// if imgData_orig is not real ImageData instance (as from ConvertJPGBufferToRawBuffer), or we're changing pixel colors, create/use a "buffer" image-data
		const imgDataWillBeModified = !(imgData_orig instanceof ImageData) || (monitorPreviewing && monitorUIState.overlay_pixelTriggers);
		//const imgData = imgDataWillBeModified ? CopyImageData(this.monitorPanel.canvasContext, imgData_orig) : imgData_orig;
		let imgData = imgData_orig;
		if (imgDataWillBeModified) {
			// cpu-usage is slightly (~15%) lower if you keep an ImageData instance, and call data.set on it, rather than calling CopyImageData() each frame
			this.EnsureImgDataBufferSizeCorrect(imgDataSize.x, imgDataSize.y);
			this.imgDataBuffer.data.set(imgData_orig.data);
			imgData = this.imgDataBuffer;
		}
		function DrawPixel(x: number, y: number, r: number, g: number, b: number, a: number) {
			//var index = (x + y * imageOrVideoSize.x) * 4;
			var index = (x + (y * imgData.width)) * 4;
			// rather than set pixel-data to exact RGBA specified, we use the specified alpha to alpha-blend with existing pixel-data
			imgData.data[index + 0] = (imgData.data[index + 0] * (1 - a)) + (r * a);
			imgData.data[index + 1] = (imgData.data[index + 1] * (1 - a)) + (g * a);
			imgData.data[index + 2] = (imgData.data[index + 2] * (1 - a)) + (b * a);
			//canvasData.data[index + 3] = a;
		}

		let totalDelta = 0;
		if (this.lastImgData && this.engineConfig) {
			// copy/cache these, to minimize read-cost in pixel-processing loop (especially important for mobx-getters)
			const length = imgData.data.length;
			const minValueDelta = this.engineConfig.camera.camMotion_pixelTrigger_minValueDelta;
			const channelOffset =
				this.cameraConfig.channels == "r" ? 0 :
				this.cameraConfig.channels == "g" ? 1 :
				this.cameraConfig.channels == "b" ? 2 :
				null;
			const channels = this.cameraConfig.channels;
			const overlay_pixelTriggers = monitorUIState.overlay_pixelTriggers;

			const ctx = this.monitorPanel!.canvasContext!;

			let i = 0;
			let triggeredPixelCount = 0;
			while (i < length) {
				if (channels == "rgb") {
					/*const average = (imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2]) / 3;
					const lastAverage = (this.lastImgData.data[i] + this.lastImgData.data[i + 1] + this.lastImgData.data[i + 2]) / 3;
					//const pixelDelta = average.Distance(lastAverage) / 255;
					var pixelDelta = Math.abs(average - lastAverage);*/

					const value = imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2];
					const lastValue = this.lastImgData.data[i] + this.lastImgData.data[i + 1] + this.lastImgData.data[i + 2];
					var pixelDelta = Math.abs(value - lastValue) / 3;
				} else {
					const average = imgData.data[i + channelOffset!];
					const lastAverage = this.lastImgData.data[i + channelOffset!];
					var pixelDelta = Math.abs(average - lastAverage);
				}

				totalDelta += pixelDelta;
				if (pixelDelta >= minValueDelta) {
					const x = ((i / 4) % imgData.width);
					const y = Math.floor((i / 4) / imgData.width);
					const inIgnoreRect = ignoreRects_inPixels.find(rect=>{
						return rect.Left <= x && rect.Right >= x && rect.Top <= y && rect.Bottom >= y;
					}) != null;

					if (!inIgnoreRect) {
						triggeredPixelCount++;
						if (monitorPreviewing && overlay_pixelTriggers) {
							//ctx.fillStyle = HSLA(0, 1, .5, .5);
							/*ctx.fillStyle = `hsla(0, 100%, 50%, .5)`;
							ctx.fillRect(x, y, 1, 1);*/
							DrawPixel(x, y, 0, 255, 0, .5);
						}
					}
				}

				i += 4;
			}

			if (monitorPreviewing) {
				ctx.clearRect(0, 0, targetSize.x, targetSize.y);

				// if image, it's from ffmpeg, so we know the resolution is correct already (or at least so close it's not worth rescaling)
				/*if (imageOrVideo instanceof HTMLImageElement || imageOrVideoSize.Equals(targetSize)) {
					ctx.drawImage(imageOrVideo, 0, 0);
				} else {*/
				//DrawImage_Fit(ctx, imageOrVideo, new VRect(Vector2.zero, targetSize));
				// drawImage is faster than putImageData, so when the raw source-media is all we're showing, just draw it directly
				if (sourceVideo != null && !imgDataWillBeModified) {
					ctx.drawImage(sourceVideo,
						0, 0, rawImageOrVideoSize.x, rawImageOrVideoSize.y,
						frameDrawRect.x, frameDrawRect.y, frameDrawRect.width, frameDrawRect.height);
				} else {
					ctx.putImageData(imgData, frameDrawRect.x, frameDrawRect.y);
				}

				if (monitorUIState.overlay_ignoreRects) {
					for (const rect of ignoreRects_inPixels) {
						ctx.fillStyle = `hsla(0, 0%, 100%, .1)`;
						ctx.fillRect(frameDrawRect.x + rect.x, frameDrawRect.y + rect.y, rect.width, rect.height);
					}
				}

				if (monitorUIState.graph_triggerPixelCount) {
					// when recording, just use the chart-canvas as a buffer, which gets drawn onto the main canvas; the rest of the time, it's just displayed directly (sharper)
					if (monitorUIState.record) {
						//ctx.drawImage(this.chart.line.chartInstance.canvas, 0, 0);
						// TODO: (after replacing chart lib)
						/*ctx.drawImage(this.monitorPanel!.chart!.line!.chartInstance.canvas,
							0, 0, this.monitorPanel!.previewContainerSize.width, this.monitorPanel!.previewContainerSize.height,
							0, 0, targetSize.x, targetSize.y);*/
					}

					this.monitorPanel!.chart!.ProcessNextTriggeredPixelCount(triggeredPixelCount);
				}
			}

			// only record frame-summaries if stream-freeze-detection or motion-detection are enabled (that's all that frame-summaries are needed for)
			if (this.streamFreezeChecker.Enabled || this.engineCameraComp?.c.camMotion_record) {
				const isTriggerFrame = triggeredPixelCount >= this.engineConfig.camera.camMotion_frameTrigger_minTriggerPixelCount;
				const now = Date.now();
				const summary: FrameSummary = {
					time: now,
					totalDelta,
					triggered: isTriggerFrame,
				};
				this.frameSummaries.push(summary);
				//this.CheckForStreamFreeze();
				if (this.engineCameraComp?.c.camMotion_record) {
					this.CheckForMotionTrigger(now);
				}
			} /*else {
				if (this.frameSummaries.length) {
					this.frameSummaries.length = 0;
				}
			}*/

			//console.log(`Total delta:${totalDelta}`);
		}

		this.lastImgData = imgData_orig;
	};

	frameSummaries = [] as FrameSummary[];
	CheckForMotionTrigger(now: number) {
		if (this.engineConfig == null) return;

		/*const now = Date.now();
		let windowFrames: FrameSummary[];
		if (this.engineConfig.camera.recordMotion_motionTrigger_windowDuration == 0) {
			windowFrames = this.frameSummaries.slice(-1);
		} else {
			const windowStartTime = now - (this.engineConfig.camera.recordMotion_motionTrigger_windowDuration * 1000);
			const firstFrameInWindow_index = this.frameSummaries.findIndex(frame=>frame.time >= windowStartTime);
			windowFrames = this.frameSummaries.slice(firstFrameInWindow_index);
		}*/
		const windowStartTime = now - (this.engineConfig.camera.camMotion_motionTrigger_windowDuration * 1000);
		//const firstFrameInWindow_index = this.frameSummaries.findIndex(frame=>frame.time >= windowStartTime);
		let firstFrameInWindow_index;
		// start from end, working way back to find earliest frame within window (faster than searching from start, since cutoff always near end)
		for (let i = this.frameSummaries.length - 1; i >= 0; i--) {
			if (this.frameSummaries[i].time >= windowStartTime) {
				firstFrameInWindow_index = i;
			} else {
				break;
			}
		}
		const windowFrames = this.frameSummaries.slice(firstFrameInWindow_index);

		const windowFramesTriggered = windowFrames.filter(a=>a.triggered);
		const windowFramesTriggeredPercent = windowFramesTriggered.length / windowFrames.length;
		if (windowFramesTriggeredPercent >= this.engineConfig.camera.camMotion_motionTrigger_minTriggerFramePercent) {
			this.engineCameraComp!.OnMotionTrigger();
		}
	}

	streamFreezeChecker = new Timer(1000, ()=>{
		this.CheckForStreamFreeze();
	});
	CheckForStreamFreeze() {
		/*let firstFrameInFrozenSegment_index;
		// start from end, working way back to find earliest frame that was frozen / had no delta
		for (let i = this.frameSummaries.length - 1; i >= 0; i--) {
			if (this.frameSummaries[i].totalDelta == 0) {
				firstFrameInFrozenSegment_index = i;
			} else {
				break;
			}
		}
		if (firstFrameInFrozenSegment_index == null) return;

		const streamFreezeDuration = Date.now() - this.frameSummaries[firstFrameInFrozenSegment_index].time;
		if (streamFreezeDuration >= this.cameraConfig.reconnectTime * 1000) {
			this.AttemptIPCameraReconnection();
		}*/

		let lastFrameWithDelta_index;
		// start from end, working way back to find most recent frame that had a delta (ie. was not frozen)
		for (let i = this.frameSummaries.length - 1; i >= 0; i--) {
			if (this.frameSummaries[i].totalDelta > 0) {
				lastFrameWithDelta_index = i;
				break;
			}
		}

		let freezeStartTime =
			lastFrameWithDelta_index != null ? this.frameSummaries[lastFrameWithDelta_index].time :
			// if there hasn't been a single frame with a delta (ie. change from the previous frame), consider stream frozen since the first frame
			this.frameSummaries.length ? this.frameSummaries[0].time :
			// if no frames have been received at all, consider stream to have been frozen since the stream was started (attempted anyway)
			this.streamStartTime;
		// if stream was restarted, ignore frozen frames prior to the restart (else, new stream has no chance to provide new frames)
		freezeStartTime = freezeStartTime.KeepAtLeast(this.lastReconnectAttemptTime);

		const freezeDuration = Date.now() - freezeStartTime;
		if (freezeDuration >= this.cameraConfig.freezeDetectTime * 1000) {
			this.NotifyStreamFrozen(freezeDuration);
		}
	}

	//lastReconnectPrepareTime = 0;
	lastReconnectAttemptTime = 0;
	reconnectWaitTimer: Timer;
	NotifyStreamFrozen(freezeDuration: number) {
		if (this.reconnectWaitTimer?.Enabled) return; // if currently in reconnection attempt, don't start another
		//if (Date.now() - this.lastReconnectAttemptTime < 30 * 1000) return; // wait at least 30s between reconnect attempts

		SessionLog(`IP-camera freeze/disconnection detected (frozen for ${(freezeDuration / 1000).RoundTo(1)}s). Disconnecting stream, to attempt reconnection after ${this.cameraConfig.reconnectDelay}s.`, LogType.Event_Large);
		//this.lastReconnectPrepareTime = Date.now();
		this.IPCam_StopStreaming();
		this.reconnectWaitTimer = new Timer(this.cameraConfig.reconnectDelay * 1000, ()=>{
			SessionLog(`Wait-time (${this.cameraConfig.reconnectDelay}s) completed. Attempting to reconnect to ip-camera.`, LogType.Event_Large);
			this.lastReconnectAttemptTime = Date.now();
			this.IPCam_StartStreaming();
		}, 1).Start();
	}
}
export let currentCamera: CameraInterface;
OnStoreLoaded(()=>{
	autorun(()=>{
		if (currentCamera) {
			currentCamera.cameraConfig = store.main.tools.monitor.cameraConfig;
		} else {
			currentCamera = new CameraInterface(store.main.tools.monitor.cameraConfig);
		}
	});
});