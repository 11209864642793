import {Assert} from "js-vextensions";
import {AddSchema, AssertValidate, Command, AssertV} from "mobx-firelink";
import {GetTimelineEvent} from "../../Store/firebase/timelineEvents";

//@UserEdit
export class StopTimelineEvent extends Command<{id: string}> {
	newEndTime: number;
	Validate() {
		AssertValidate({
			properties: {
				id: {type: "string"},
			},
			required: ["id"],
		}, this.payload, `Payload invalid`);

		const {id} = this.payload;
		//const oldData = await GetAsync(()=>GetTimelineEvent(id));
		this.newEndTime = Date.now();

		const oldEndTime = GetTimelineEvent(id)?.endTime;
		AssertV(oldEndTime == null, "Cannot stop an event that is already stopped. (ie. has an end time)");
	}

	GetDBUpdates() {
		const {id} = this.payload;

		const updates = {};
		updates[`timelineEvents/${id}/.endTime`] = this.newEndTime;
		return updates;
	}
}