import {GetDoc, GetDocs, IsAuthValid, StoreAccessor, WhereOp} from "mobx-firelink";
import {GetAuth} from "../../Store/firebase";
import {User} from "../../Store/firebase/users/@User";
import {EffectGroup, TagTargetGroup} from "../../Server/Commands/SetUserEntityTags";
import {ea, emptyArray} from "js-vextensions";

/* export function GetAuth(state: RootState) {
	return state.firebase.auth;
} */
export const MeID = StoreAccessor(s=>(): string|n=>{
	// return state.firebase.data.auth ? state.firebase.data.auth.uid : null;
	// return GetData(state.firebase, "auth");
	/* var result = helpers.pathToJS(firebase, "auth").uid;
	return result; */
	/* let firebaseSet = State().firebase as Set<any>;
	return firebaseSet.toJS().auth.uid; */
	// return State(a=>a.firebase.auth) ? State(a=>a.firebase.auth.uid) : null;
	return IsAuthValid(GetAuth()) ? GetAuth()!.id : null;
});
export const Me = StoreAccessor(s=>()=>{
	return GetUser(MeID());
});

export const GetUser = StoreAccessor(s=>(userID: string|n): User|n=>{
	return GetDoc({}, a=>a.users.get(userID!));
});
export const GetUsers = StoreAccessor(s=>(): User[]=>{
	return GetDocs({}, a=>a.users) ?? ea;
});

export const GetSelectedUserID = StoreAccessor(s=>()=>{
	return s.main.users.selectedUserID;
});
export const GetSelectedUser = StoreAccessor(s=>()=>{
	const selectedID = GetSelectedUserID();
	return GetDoc({}, a=>a.users.get(selectedID!));
});

// NOTE: The "entity" here is meant generically; it's not the "entities" collection in the database.
export const GetUserEntityTagCounts = StoreAccessor(s=>(entityGroup: TagTargetGroup, entityID: string|n)=>{
	/*const usersWithEntityTags = GetDocs({
		queryOps: [new WhereOp(`entityTags.${entityGroup}.${entityID}`, ">", "")],
	}, a=>a.users);*/
	const usersWithEntityTags = GetUsers().filter(a=>a.entityTags?.[entityGroup]?.[entityID as any] != null);

	const tagCounts = {} as {[key: string]: number};
	for (const user of usersWithEntityTags) {
		const userEntityTags = user.entityTags[entityGroup][entityID as any];
		for (const tag of userEntityTags) {
			tagCounts[tag] = (tagCounts[tag] ?? 0) + 1;
		}
	}
	return tagCounts;
});
export const GetUserEntityTags = StoreAccessor(s=>(userID: string|n, entityGroup: TagTargetGroup, entityID: string|n)=>{
	const user = GetUser(userID);
	return user?.entityTags?.[entityGroup]?.[entityID as any] ?? emptyArray;
});