import {Assert, GetPercentFromXToY, IsNaN, Lerp, ToInt, ToJSON, ToNumber, Range} from "js-vextensions";
import {EngineSessionInfo} from "../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {EEGSampleFile} from "../../../Store/main/timeline.js";
import {FBAConfig_EEG, EEGChannel} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_EEG.js";
import {FindIndex_BinarySearch} from "../../../Utils/General/General.js";
import {RecreateEEGSamplesInSecond} from "../../../UI/Tools/@Shared/MuseInterface/SampleHelpers.js";
import {EEGSample} from "../../../UI/Tools/@Shared/MuseInterface/EEGStructs.js";
import {SessionDataProcessor} from "./SessionDataProcessor.js";

function GetMaxEEGSampleDependencyDist(options: EEGProcessorOptions) {
	const normalizationWindowSize = options.motion_sampleTrigger_normalizationWindowSize * options.samplesProcessedPerSecond;
	const smoothingWindowSize = options.motion_sampleTrigger_normalizationWindowSize * options.samplesProcessedPerSecond;
	//const motionTriggerWindowSize = options.motion_motionTrigger_windowSize * options.samplesProcessedPerSecond;
	const cacheKeepSizeForEEGShapes = Math.max(options.motion_sampleTrigger_normalizationWindowSize, 10) * options.samplesProcessedPerSecond; // eeg-shapes will very rarely be longer than normalizationWindowSize/10s
	return Math.ceil(normalizationWindowSize + smoothingWindowSize + cacheKeepSizeForEEGShapes); //+ motionTriggerWindowSize);
}

/**
* Levels: (each level has some dependency on previous level; see info-text in EEGPanel.tsx for more context)
*
* -1: processing completed, but cache-data since-deleted  
* 0: left, right, time_uplot  
* 1: + dev.norm  
* 2: + dev.smoothed  
* 3: + dev.display  
*/
export type EEGProcessingLevel = -1 | 0 | 1 | 2 | 3; // see EEGSample.processingLevel for details
export const EEGProcessingLevel_max = 3;
export type EEGSampleCache = {
	processingLevel: EEGProcessingLevel;
	//time_uplot: number; // stored as seconds (not ms), since that's what uplot expects
	// the below are arrays, with val per channel (left, right, left_ear, right_ear -- saved sessions only have first two channels)
	norm: number[];
	smoothed: number[];
	// low-level cache data
	sumOverNormalizationWindow: number[];
	baseline: number[]; // used by DetailChart, during creation of test-segment
	sumForSmoothing: number[];

	// commented; now stored in standalone arrays in EEGProcessor (since that's what uplot expects)
	/*left_final: number;
	right_final: number;
	combinedDeviation: number;
	triggerSamplePercent: number;*/

	// added during android/ML processing
	/*dist_moveNone: number;
	dist_moveMicro: number;
	dist_moveMacro: number;*/
};

export type EEGProcessorOptions = FBAConfig_EEG & {
	samplesProcessedPerSecond: number,
	calc_normalize: boolean,
	calc_smooth: boolean,
	calc_combinedDeviation: boolean,
	calc_triggerSamplePercent: boolean,

	uplotData_normalize: boolean;
	uplotData_smooth: boolean;

	clearOutOfRangeSamples: boolean;
};
export class EEGProcessor {
	constructor(opt: Partial<EEGProcessor>) {
		this.VSet(opt);
		if (this.options) {
			this.maxSampleDependencyDist = GetMaxEEGSampleDependencyDist(this.options);
		}
	}
	parent: SessionDataProcessor;
	//get StaticArrays() { return this.parent != null; }
	options: EEGProcessorOptions;
	// options derivatives
	maxSampleDependencyDist: number;

	//rawSamples = [] as EEGSample[];
	//samples: EEGSample[];
	get SampleCount() { return this.samples_left.length; }
	get StaticArrays() { return this.samples_left instanceof Float64Array; }
	eegChannels: number[]; // [0, 1] + [2, 3] (if live session, having left_ear and right_ear channels)

	samples_left: number[] | Float64Array;
	samples_right: number[] | Float64Array;
	samples_left_ear: number[]; // for live-sessions only (atm) // note: when no value is provided for frame/data-set, entry gets inserted with value Number.MIN_SAFE_INTEGER
	samples_right_ear: number[]; // for live-sessions only (atm) // note: when no value is provided for frame/data-set, entry gets inserted with value Number.MIN_SAFE_INTEGER
	samples_processingLevel: number[] | Int8Array;
	samples_cache: EEGSampleCache[];

	// displayed in uplot
	samples_time: number[] | Float64Array; // time in uplot format (regular ms-since-epoch / 1000)
	samples_left_display: number[] | Float64Array;
	samples_right_display: number[] | Float64Array;
	samples_left_ear_display: number[]; // for live-sessions only (atm)
	samples_right_ear_display: number[]; // for live-sessions only (atm)

	// helpers for saved-sessions only (for now)
	minTime_floored: number;
	maxTime_ceilinged: number;
	//samplesBySecond
	secondTimes = [] as number[];
	sampleIndexesForSeconds = {} as {[key: number]: number};

	Init_LiveSession() {
		this.eegChannels = [0, 1, 2, 3];

		this.samples_left = [];
		this.samples_right = [];
		this.samples_left_ear = [];
		this.samples_right_ear = [];
		this.samples_processingLevel = [];
		this.samples_cache = [];

		this.samples_time = [];
		this.samples_left_display = [];
		this.samples_right_display = [];
		this.samples_left_ear_display = [];
		this.samples_right_ear_display = [];
		return this; // return instance, for convenience chaining (in EEGPanel/EEGComp)
	}
	Init_SavedSession(session: EngineSessionInfo, eegSampleFiles: {left: EEGSampleFile, right: EEGSampleFile}) {
		this.eegChannels = [0, 1];

		const samples_baseData = [] as EEGSample[];

		/*if (!this.options.uplotData_normalize || !this.options.uplotData_smooth) {
			this.samples_left_customized.Clear();
			this.samples_right_customized.Clear();
			(this.samples_uplotData as any)[1] = this.samples_left_customized;
			(this.samples_uplotData as any)[2] = this.samples_right_customized;
		}*/

		this.secondTimes = eegSampleFiles.left.samplesBySecond.VKeys().map(a=>ToInt(a));
		if (this.secondTimes.length == 0) return;
		/*this.minX = this.secondTimes[0];
		this.maxX = this.secondTimes.Last();*/

		for (const secondKey of Object.keys(eegSampleFiles.left.samplesBySecond)) {
			const secondTime = ToNumber(secondKey);
			this.sampleIndexesForSeconds[secondKey as any] = samples_baseData.length;

			const samplesInSecond = RecreateEEGSamplesInSecond(eegSampleFiles.left.samplesBySecond, eegSampleFiles.right.samplesBySecond, secondTime);
			for (const sample of samplesInSecond) {
				if (DEV) Assert(samples_baseData.length == 0 || sample.time > samples_baseData[samples_baseData.length - 1].time, "Sample-times must only increase.");
				samples_baseData.push(sample);

				// fine to set here, since not a fixed-size array
				this.minTime_floored = Math.min(this.minTime_floored ?? sample.time, sample.time);
				this.maxTime_ceilinged = Math.max(this.maxTime_ceilinged ?? sample.time, sample.time);
			}
		}
		this.minTime_floored = this.minTime_floored.FloorTo(1000);
		this.maxTime_ceilinged = this.maxTime_ceilinged.CeilingTo(1000);

		this.samples_left = new Float64Array(samples_baseData.length);
		this.samples_right = new Float64Array(samples_baseData.length);
		/*this.samples_left_ear = new Float64Array(samples_baseData.length);
		this.samples_right_ear = new Float64Array(samples_baseData.length);*/
		this.samples_processingLevel = new Int8Array(samples_baseData.length);
		this.samples_cache = [];

		this.samples_time = new Float64Array(samples_baseData.length);
		this.samples_left_display = new Float64Array(samples_baseData.length);
		this.samples_right_display = new Float64Array(samples_baseData.length);

		for (let i = 0; i < samples_baseData.length; i++) {
			const sample = samples_baseData[i];
			this.samples_left[i] = sample.left;
			this.samples_right[i] = sample.right;
			/*this.samples_left_ear[i] = sample.left_ear;
			this.samples_right_ear[i] = sample.right_ear;*/
			this.samples_time[i] = sample.time / 1000;
		}
	}

	/** Finds index of first sample whose time is <= the provided time. (-1 if no sample satisfies that condition) */
	GetSampleIndexForTime(time: number, findClosestIfDataMissingForSecond: true): number;
	GetSampleIndexForTime(time: number, findClosestIfDataMissingForSecond?: false): number|n;
	GetSampleIndexForTime(time: number, findClosestIfDataMissingForSecond = false) {
		Assert(this.StaticArrays, "Cannot call GetSampleIndexForTime unless Init_SavedSession was called beforehand.");
		const rangeStart = this.minTime_floored;
		const rangeEnd = this.maxTime_ceilinged;

		let preSecond_time = time.FloorTo(1000);
		if (findClosestIfDataMissingForSecond) {
			preSecond_time = preSecond_time.KeepBetween(rangeStart, rangeEnd);
			while (this.sampleIndexesForSeconds[preSecond_time] == null && preSecond_time >= rangeStart) {
				preSecond_time -= 1000;
				//Assert(secondTime >= rangeStart);
			}
		}
		const preSecond_startIndex = this.sampleIndexesForSeconds[preSecond_time]; //|| 0;
		if (preSecond_startIndex == null) {
			if (!findClosestIfDataMissingForSecond) return null;
			Assert(false, "secondStartIndex should never be null, with findClosestIfOutsideRange enabled.");
		}

		const postSecond_time = this.secondTimes[this.secondTimes.indexOf(preSecond_time) + 1];
		const postSecond_startIndex = postSecond_time ? this.sampleIndexesForSeconds[postSecond_time] : this.samples_left.length;

		/*return FindIndex_BinarySearch(this.samples_time, sampleTime_uplot=>sampleTime_uplot * 1000 >= time, true, {
			firstCheckIndex: Math.floor(preSecond_startIndex, postSecond_startIndex),
			minI: preSecond_startIndex,
			maxI: postSecond_startIndex,
		});*/

		//const percentThroughSecond = GetPercentFromXToY(secondTime, secondTime + 1000, time);
		const percentThroughSecond = ((time - preSecond_time) / 1000).KeepAtLeast(0); // inlined
		//return Lerp(secondStartIndex, nextSecondStartIndex, percentThroughSecond).FloorTo(1);
		let indexEstimate = Math.floor(preSecond_startIndex + ((postSecond_startIndex - preSecond_startIndex) * percentThroughSecond)); // inlined
		while (this.samples_time[indexEstimate] * 1000 < time) indexEstimate++;
		while (this.samples_time[indexEstimate] * 1000 > time) indexEstimate--;
		if (!indexEstimate.IsBetween(0, this.SampleCount - 1)) indexEstimate = -1;
		return indexEstimate; //.KeepBetween(0, this.SampleCount - 1);
	}

	AddAndProcessEEGSample(sample: EEGSample, slotIndex = this.samples_left.length) {
		this.AddEEGSample(sample, slotIndex);
		this.ProcessEEGSample(slotIndex);
	}
	AddEEGSample(sample: EEGSample, slotIndex = this.samples_left.length) {
		if (DEV) {
			Assert(this.samples_left instanceof Array, "AddEEGSample should only be called for live sessions!");
			Assert(sample.time != null, "Sample's time must be specified!");
			Assert(sample.left != null && sample.right != null, ()=>`Sample cannot be empty! Sample: ${ToJSON(sample)}`);
			Assert(!IsNaN(sample.left) && !IsNaN(sample.right), ()=>`Sample cannot have NaN values! Sample: ${ToJSON(sample)}`);
		}
		this.samples_processingLevel[slotIndex] = this.samples_processingLevel[slotIndex] ?? 0;
		this.samples_left[slotIndex] = sample.left;
		this.samples_right[slotIndex] = sample.right;
		this.samples_left_ear[slotIndex] = sample.left_ear ?? Number.MIN_SAFE_INTEGER;
		this.samples_right_ear[slotIndex] = sample.right_ear ?? Number.MIN_SAFE_INTEGER;
		this.samples_time[slotIndex] = sample.time / 1000;
	}

	get SamplesPerNormalizationWindow() {
		return this.options.motion_sampleTrigger_normalizationWindowSize * this.options.samplesProcessedPerSecond;
	}
	GetPartialNormalizationSumFromPrecalcedSegment(newSegmentEndI: number, precalcedSegmentEndI: number) {
		const newSegmentStartI = (newSegmentEndI - this.SamplesPerNormalizationWindow).KeepAtLeast(0);
		const precalcedSegmentStartI = (precalcedSegmentEndI - this.SamplesPerNormalizationWindow).KeepAtLeast(0);
		const precalcedSegmentEndSampleCache = this.samples_cache[precalcedSegmentEndI - 1];
		const sumsToAdd = precalcedSegmentEndSampleCache.sumOverNormalizationWindow.slice();

		// subtract entries from the precalced-segment, which are out-of-range for the new segment
		const [subtract_startI, subtract_endI] = precalcedSegmentEndI < newSegmentEndI ? [precalcedSegmentStartI, newSegmentStartI] : [newSegmentEndI, precalcedSegmentEndI];
		for (let i = subtract_startI; i < subtract_endI; i++) {
			for (const ch of this.eegChannels) sumsToAdd[ch] -= this.GetEEGSampleVal(i, ch);
		}

		return {sumsToAdd};
	}
	GetEEGSampleVal(index: number, channelIndex: number) {
		if (channelIndex == 0) return this.samples_left[index];
		if (channelIndex == 1) return this.samples_right[index];
		/*if (channelIndex == 2) return this.samples_left_ear?.[index] ?? 0;
		if (channelIndex == 3) return this.samples_right_ear?.[index] ?? 0;*/
		if (channelIndex == 2) return this.samples_left_ear[index];
		if (channelIndex == 3) return this.samples_right_ear[index];
		Assert(false);
	}
	// helper for caller in DetailChart.tsx (removes need for caching [left/right]_baseline values)
	SamplesInNormalizationWindowFor(index: number) {
		const segmentEndI = index + 1; // end-index is bound-exclusive
		const segmentStartI_preFix = segmentEndI - this.SamplesPerNormalizationWindow;
		const segmentStartI = segmentStartI_preFix.KeepAtLeast(0);
		return segmentEndI - segmentStartI;
	}
	Sample_CalculateNormalizedValues(index: number) {
		Assert(this.samples_processingLevel[index] <= 0); // 0 or -1
		const segmentSums = this.eegChannels.map(()=>0);
		const segmentEndI = index + 1; // end-index is bound-exclusive
		const segmentStartI_preFix = segmentEndI - this.SamplesPerNormalizationWindow;
		const segmentStartI = segmentStartI_preFix.KeepAtLeast(0);

		// if sample just after this one has sumOverNormalizationWindow data (eg. eeg-processing saved session-data), use it to shortcut our own calc
		if (this.samples_cache[index + 1]?.sumOverNormalizationWindow?.[0] != null) {
			const {sumsToAdd} = this.GetPartialNormalizationSumFromPrecalcedSegment(segmentEndI, index + 2);
			for (const ch of this.eegChannels) segmentSums[ch] += sumsToAdd[ch];

			// if segment's start entry is outside of precalced-segment, add its value (the only time not outside, is near session-start boundary)
			if (segmentStartI_preFix >= 0) {
				for (const ch of this.eegChannels) segmentSums[ch] += this.GetEEGSampleVal(segmentStartI, ch);
			}
		} else {
			const midPoint = (segmentStartI + segmentEndI) / 2;
			// iterate backward, so if we find a sumOverNormalizationWindow value, we get maximum benefit from it
			for (let i = segmentEndI - 1; i >= segmentStartI; i--) {
				// if we found a "sumOverNormalizationWindow" value prior to halfway point [else not worth], use it to shortcut our own calc
				if (this.samples_cache[i]?.sumOverNormalizationWindow?.[0] != null && i > midPoint) {
					const {sumsToAdd} = this.GetPartialNormalizationSumFromPrecalcedSegment(segmentEndI, i + 1);
					for (const ch of this.eegChannels) segmentSums[ch] += sumsToAdd[ch];
					break;
				}

				for (const ch of this.eegChannels) segmentSums[ch] += this.GetEEGSampleVal(i, ch);
			}
		}

		// validate against no-shortcuts calculated sums
		/*if (DEV) {
			let manualLeftSum = sample.left;
			let manualRightSum = sample.right;
			for (let i = segmentEndI - 2; i >= segmentStartI; i--) {
				const otherSample = this.samples[i];
				manualLeftSum += otherSample.left;
				manualRightSum += otherSample.right;
			}
			const startEdgeEntries = this.SamplesSlice(segmentStartI - 3, segmentStartI + 3);
			const endEdgeEntries = this.SamplesSlice(segmentEndI - 3, segmentEndI + 3);
			//console.log("Test1", startEdgeEntries, endEdgeEntries);
			Assert(manualLeftSum == segmentLeftSum && manualRightSum == segmentRightSum,
				`Shortcut norm-value calc (${segmentLeftSum}) differs from flat calc (${manualLeftSum})!
				StartEdge:${ToJSON(startEdgeEntries)} EndEdge:${ToJSON(endEdgeEntries)}`);
		}*/

		const baselines = segmentSums.map(sum=>sum / (segmentEndI - segmentStartI));
		this.samples_cache[index] = Object.assign(this.samples_cache[index] ?? {}, {
			sumOverNormalizationWindow: segmentSums,
			//baseline,
			norm: baselines.map((baseline, ch)=>this.GetEEGSampleVal(index, ch) - baseline),
		}) as EEGSampleCache;
		// extra catch for first block (for easier debugging)
		if (DEV) Assert(!IsNaN(baselines[0]), `baselines.left should not be NaN!`);

		// for use by DetailChart, in creating test-segment
		//return {left_baseline, right_baseline};
	}
	ProcessSample_L1(index: number) {
		Assert(this.samples_processingLevel[index] < 1, `Cannot re-perform l1-processing. Found: ${this.samples_processingLevel[index]}`);

		if (this.options.calc_normalize) { //&& sample.left_norm == null) { // normalized values maybe already calculated, if was needed by to-the-right sample's "..._smoothed" calculation
			this.Sample_CalculateNormalizedValues(index);
		}
		this.samples_processingLevel[index] = 1;
	}

	/*get SamplesPerSmoothingWindow() {
		return this.options.motion_sampleTrigger_normalizationWindowSize * this.options.samplesProcessedPerSecond;
	}*/
	// todo: share code with normalization equivalent
	GetPartialSmoothingSumFromPrecalcedSegment(newSegmentEndI: number, precalcedSegmentEndI: number, ext: {smoothingWindowSize: number, useNormalizedValues: boolean}) {
		const newSegmentStartI = (newSegmentEndI - ext.smoothingWindowSize).KeepAtLeast(0);
		const precalcedSegmentStartI = (precalcedSegmentEndI - ext.smoothingWindowSize).KeepAtLeast(0);
		const precalcedSegmentEndSampleCache = this.samples_cache[precalcedSegmentEndI - 1];
		const sumsToAdd = precalcedSegmentEndSampleCache.sumForSmoothing.slice(0);

		// subtract entries from the precalced-segment, which are out-of-range for the new segment
		const [subtract_startI, subtract_endI] = precalcedSegmentEndI < newSegmentEndI ? [precalcedSegmentStartI, newSegmentStartI] : [newSegmentEndI, precalcedSegmentEndI];
		for (let i = subtract_startI; i < subtract_endI; i++) {
			if (this.samples_processingLevel[i] < 1) this.ProcessSample_L1(i);
			const cacheData = this.samples_cache[i];
			for (const ch of this.eegChannels) sumsToAdd[ch] -= ext.useNormalizedValues ? cacheData.norm[ch] : this.GetEEGSampleVal(i, ch);
		}

		return {sumsToAdd};
	}
	GetSampleSmoothedValues(index: number, ext: {smoothingWindowSize: number, useNormalizedValues: boolean}) {
		const segmentSums = this.eegChannels.map(()=>0);
		const segmentEndI = index + 1;
		const segmentStartI_preFix = segmentEndI - ext.smoothingWindowSize;
		const segmentStartI = segmentStartI_preFix.KeepAtLeast(0);

		// see "opts used for cache" in Sample_CalculateSmoothedValues()
		const matchesOptsUsedForCache = ext.smoothingWindowSize == this.options.motion_sampleTrigger_smoothing && ext.useNormalizedValues == this.options.calc_normalize;

		// if sample just after this one has sumForSmoothing data (eg. eeg-processing saved session-data), use it to shortcut our own calc
		if (matchesOptsUsedForCache && this.samples_cache[index + 1]?.sumForSmoothing?.[0] != null) {
			const {sumsToAdd} = this.GetPartialSmoothingSumFromPrecalcedSegment(segmentEndI, index + 2, ext);
			for (const ch of this.eegChannels) segmentSums[ch] += sumsToAdd[ch];

			// if segment's start entry is outside of precalced-segment, add its value (the only time not outside, is near session-start boundary)
			if (segmentStartI_preFix >= 0) {
				if (this.samples_processingLevel[segmentStartI] < 1) this.ProcessSample_L1(segmentStartI);
				const cacheData = this.samples_cache[segmentStartI];
				for (const ch of this.eegChannels) segmentSums[ch] += ext.useNormalizedValues ? cacheData.norm[ch] : this.GetEEGSampleVal(segmentStartI, ch);
			}
		} else {
			const midPoint = (segmentStartI + segmentEndI) / 2;
			// iterate backward, so if we find a sumForSmoothing value, we get maximum benefit from it
			for (let i = segmentEndI - 1; i >= segmentStartI; i--) {
				// if we found a "left_sumForSmoothing" value prior to halfway point [else not worth], use it to shortcut our own calc
				if (matchesOptsUsedForCache && this.samples_cache[i]?.sumForSmoothing?.[0] != null && i > midPoint) {
					const {sumsToAdd} = this.GetPartialSmoothingSumFromPrecalcedSegment(segmentEndI, i + 1, ext);
					for (const ch of this.eegChannels) segmentSums[ch] += sumsToAdd[ch];
					break;
				}

				if (this.samples_processingLevel[i] < 1) this.ProcessSample_L1(i);
				for (const ch of this.eegChannels) segmentSums[ch] += ext.useNormalizedValues ? this.samples_cache[i].norm[ch] : this.GetEEGSampleVal(i, ch);
			}
		}

		return {
			sumForSmoothing: segmentSums,
			smoothed: segmentSums.map(sum=>sum / (segmentEndI - segmentStartI)),
		};
	}
	Sample_CalculateSmoothedValues(index: number) {
		Assert(this.samples_processingLevel[index] == 1);
		const smoothedValues = this.GetSampleSmoothedValues(index, {
			smoothingWindowSize: this.options.motion_sampleTrigger_smoothing,
			useNormalizedValues: this.options.calc_normalize,
		});
		Object.assign(this.samples_cache[index], smoothedValues);
	}
	ProcessSample_L2(index: number) {
		Assert(this.samples_processingLevel[index] < 2, `Cannot re-perform l2-processing. Found: ${this.samples_processingLevel[index]}`);
		if (this.samples_processingLevel[index] < 1) this.ProcessSample_L1(index);

		if (this.options.calc_smooth) { //&& sample.left_smoothed == null) {
			this.Sample_CalculateSmoothedValues(index);
		}
		this.samples_processingLevel[index] = 2;
	}

	ProcessSample_L3(index: number) {
		Assert(this.samples_processingLevel[index] < 3, `Cannot re-perform l3-processing. Found: ${this.samples_processingLevel[index]}`);
		if (this.samples_processingLevel[index] < 2) this.ProcessSample_L2(index);

		const opt = this.options;
		const cacheData = this.samples_cache[index];
		const vals_forDerivatives = cacheData.smoothed ?? cacheData.norm ?? this.eegChannels.map(ch=>this.GetEEGSampleVal(index, ch));

		// below inlined, so that afterward, processingLevel flag lets us know the processing was done (since data stored in no-null-values array)
		// if uplot-data settings match calculation settings, just use for-derivatives values directly
		let sourceArray: number[];
		if (opt.uplotData_normalize == opt.calc_normalize && opt.uplotData_smooth == opt.calc_smooth) {
			sourceArray = vals_forDerivatives;
		} else {
			if (opt.uplotData_normalize && opt.uplotData_smooth) {
				const smoothedValues = this.GetSampleSmoothedValues(index, {smoothingWindowSize: this.options.motion_sampleTrigger_smoothing, useNormalizedValues: true});
				sourceArray = smoothedValues.smoothed;
			} else if (opt.uplotData_normalize) {
				sourceArray = cacheData.norm;
			} else if (opt.uplotData_smooth) {
				const smoothedValues = this.GetSampleSmoothedValues(index, {smoothingWindowSize: this.options.motion_sampleTrigger_smoothing, useNormalizedValues: false});
				sourceArray = smoothedValues.smoothed;
			} else {
				sourceArray = this.eegChannels.map(ch=>this.GetEEGSampleVal(index, ch));
			}
		}
		this.samples_left_display[index] = sourceArray[0];
		this.samples_right_display[index] = sourceArray[1];
		if (this.eegChannels.length == 4) {
			this.samples_left_ear_display[index] = sourceArray[2];
			this.samples_right_ear_display[index] = sourceArray[3];
		}

		this.samples_processingLevel[index] = 3;
	}

	scannerPositions = new Map<string, number>();
	ProcessEEGSample(index: number) {
		// final-level processing will also run any missed earlier levels
		this.ProcessSample_L3(index);

		if (this.options.clearOutOfRangeSamples) {
			this.scannerPositions.set("r_process", index);
			this.ClearJustOutOfRangeData(this.scannerPositions, "all");
		}

		// todo: recreate this check
		//if (DEV && index % 1000 == 0) Assert(sample.VValues().every(a=>!IsNaN(a)), ()=>`No value resulting from processing should be NaN! Data:${ToJSON(sample)}`);
	}

	ClearJustOutOfRangeData(scannerPositions: Map<string, number>, dataGroup: "all" | "cache") {
		const scanPoints = Array.from(scannerPositions.values());
		const checkPoints = Array.from(scannerPositions.entries()).map(entry=>{
			//if (entry[0].startsWith("left_"))
			if (entry[0][0] == "l") return entry[1] + (this.maxSampleDependencyDist + 1); // if scanner moving left
			return entry[1] - (this.maxSampleDependencyDist + 1); // if scanner moving right
		});
		for (const checkPoint of checkPoints) {
			// check for scan-point within max-sample-dependency-dist; if none found, sample's cache is not needed anymore!
			const pointStillInScanRange = scanPoints.Any(a=>a.Distance(checkPoint) <= this.maxSampleDependencyDist);
			if (!pointStillInScanRange) {
				if (dataGroup == "all") { // only enabled for cases with dynamic-arrays, so delete-ops will work
					delete this.samples_left[checkPoint];
					delete this.samples_right[checkPoint];
					delete this.samples_processingLevel[checkPoint];
					delete this.samples_cache[checkPoint];

					delete this.samples_time[checkPoint];
					delete this.samples_left_display[checkPoint];
					delete this.samples_right_display[checkPoint];
					//console.log("Deleted sample at:", checkPoint);
				} else {
					const checkPointFullyProcessed = this.samples_processingLevel[checkPoint] == EEGProcessingLevel_max;
					//if (DEV) Assert(checkPointFullyProcessed, ()=>`Can only delete cache for sample that was fully processed! @index:${checkPoint}`);

					// check-point can be not-yet-processed if, eg. scanning just started (in which case, samples found from "looking back" is actually just looking at unprocessed ranges)
					// (this check is not currently need for the dataGroup:all case, since that's only for dynamic-arrays, where "looking back" always looks at either processed ranges, or array-out-of-bounds)
					if (checkPointFullyProcessed) {
						delete this.samples_cache[checkPoint];
						this.samples_processingLevel[checkPoint] = -1;
						//console.log("Deleted cache at:", checkPoint);
					}
				}
			}
		}
	}
}