import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export enum TranscribeTarget {
	panel = "panel",
	dreamSegment = "dreamSegment",
}
AddSchema("TranscribeTarget", {oneOf: GetValues_ForSchema(TranscribeTarget)});

export const VibrationStrength_values = ["light", "medium", "heavy", "doubleClick", "default"] as const;
export type VibrationStrength = typeof VibrationStrength_values[number];
AddSchema("VibrationStrength", {oneOf: VibrationStrength_values.map(a=>({const: a}))});

export class FBAConfig_DreamTranscribe {
	constructor(data: Partial<FBAConfig_DreamTranscribe>) { Object.assign(this, data); }

	enabled: boolean;

	// triggers
	transcribeStart_triggerSet: TriggerSet;
	transcribeEnd_triggerSet: TriggerSet;
	
	background_enabled: boolean;
	transcribeTarget: TranscribeTarget;
	bracketWordsNearTTS: number; // in seconds
	bracketWordsNearTTS_noSpeakCountOrVibrate: boolean;
	bracketWordsNearTTS_noDJ: boolean;
	onTranscribe_speakWordCount: boolean; // if true, will speak the number
	onTranscribe_vibrateDuration: number; // in seconds
	onTranscribe_vibrateStrength: VibrationStrength;

	// solver
	solver_enabled: boolean;
	solver_minWords: number;
	solver_ignoreWordsNearTTS: number; // in seconds
	solver_wordSequence: string;
	solver_minTime: number; // in seconds
	solver_maxTimeValuePerWord: number; // in seconds
	solver_markProgressBeforeCompletion: boolean;
	solver_maxTimeForProgress: number; // in seconds
}
AddSchema("FBAConfig_DreamTranscribe", {
	properties: {
		enabled: {type: "boolean"},
		transcribeStart_triggerSet: {$ref: "TriggerSet"},
		transcribeEnd_triggerSet: {$ref: "TriggerSet"},
		
		background_enabled: {type: "boolean"},
		transcribeTarget: {$ref: "TranscribeTarget"},
		bracketWordsNearTTS: {type: "number"},
		bracketWordsNearTTS_noSpeakCountOrVibrate: {type: "boolean"},
		bracketWordsNearTTS_noDJ: {type: "boolean"},
		onTranscribe_speakWordCount: {type: "boolean"},
		onTranscribe_vibrateDuration: {type: "number"},
		onTranscribe_vibrateStrength: {$ref: "VibrationStrength"},

		solver_enabled: {type: "boolean"},
		solver_minWords: {type: "number"},
		solver_ignoreWordsNearTTS: {type: "number"},
		solver_wordSequence: {type: "string"},
		solver_minTime: {type: "number"},
		solver_maxTimeValuePerWord: {type: "number"},
		solver_markProgressBeforeCompletion: {type: "boolean"},
		solver_maxTimeForProgress: {type: "number"},
	},
});