import {Shake, ShakeSubtype, ShakeLevel} from "../../../Store/firebase/shakes/@Shake";
import {nativeBridge, InAndroid} from "../Bridge_Native";
import {clientConnection} from "../../../Engine/Remoting/ClientConnection";
import {SleepAsync} from "js-vextensions";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";

// to test out, using adb shell:
// adb shell am broadcast -a com.sonicalert.v2.intent.test_shake --ei \"current strength m\" 100 --es \"current pattern\" !WTRG0TALALALALALAL --ez \"current alarm audio\" false

export function PlayShakeConfig(shake: Shake, intensity = 1) {
	if (shake.subtype == ShakeSubtype.Simple) {
		PlayShakeLevel(shake.levels[0], intensity);
	} else {
		const currentLevel = shake.levels.LastOrX(a=>a.fromStrengthMin <= intensity);
		if (currentLevel) {
			//PlayShakeLevel(currentLevel, 1);
			PlayShakeLevel(currentLevel);
		} else {
			SessionLog(`Could not find a shake-level in #${shake._key} for strength-multiplier: ${intensity}`);
		}
	}
}
export function PlayShakeLevel(shakeLevel: ShakeLevel, strengthMultiplier = 1) {
	// we only currently know how to trigger the sonic-bomb on android, so if not on android, tell client to try to do so (hoping it's on android)
	if (InAndroid(0)) {
		/*const callCount = screenOn ? 1 : 3; // when screen is off, call multiple times; for some reason, this increases reliability and decreases delay
		for (let i = 0; i < callCount; i++) {
			if (i > 0) await SleepAsync(100);*/
		nativeBridge.Call("SonicBomb_Shake", (shakeLevel.effectStrength * strengthMultiplier).KeepBetween(0, 1), shakeLevel.pattern);
	} else if (clientConnection) {
		clientConnection.bridge.Call("SonicBomb_PlayShakeLevel_OnRemote", shakeLevel, strengthMultiplier);
	}
}
export function StopShake() {
	if (InAndroid(0)) {
		/*const callCount = screenOn ? 1 : 3; // when screen is off, call multiple times; for some reason, this increases reliability and decreases delay
		for (let i = 0; i < callCount; i++) {
			if (i > 0) await SleepAsync(100);*/
		nativeBridge.Call("SonicBomb_StopShake");
	} else if (clientConnection) {
		clientConnection.bridge.Call("SonicBomb_StopShake_OnRemote");
	}
}