import {GetValues_ForSchema, Vector2} from "js-vextensions";
import {AddSchema} from "mobx-firelink";
import {name_general} from "../../../Utils/General/SharedPatterns";

export enum EntryVisibility {
	private = "private",
	public = "public",
}

export class Entity {
	constructor(initialData: Partial<Entity>) {
		this.VSet(initialData);
	}

	_key: string;
	creator: string;
	createdAt: number;

	name: string;
	//visibility: EntryVisibility;
	// if we wanted to avoid the array-contains-any operator in query, we could use a "visibleTo_widestGroup" field instead (combined with a simpler array-contains operator)
	visibleToGroups: string[];
	tags: string[];

	url: string|n; // todo: probably rename to image_url
	image_embedded: string|n;
	/** as {x: percentOfWidth, y: percentOfHeight}, from bottom-left */
	square_center = new Vector2(.5, .5);
	/** 1: target-square maximally-fits into source-image, 0.5: target-square's width/height is set to half of source-image's (whichever dimension is smaller) */
	square_size = 1;
}
AddSchema("Entity", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},

		name: {type: "string", pattern: name_general},
		//visibility: {oneOf: GetValues_ForSchema(EntryVisibility)},
		visibleToGroups: {items: {type: "string"}},
		tags: {items: {type: "string"}},

		url: {type: "string"},
		image_embedded: {type: "string"},
		square_center: {$ref: "Vector2"},
		square_size: {type: "number"},
	},
});

export function VisibleToGroups_AsSimpleVisibility(visibleToGroups: string[]) {
	return visibleToGroups.includes("public") ? EntryVisibility.public : EntryVisibility.private;
}
export function SimpleVisibility_AsVisibleToGroup(visibility: EntryVisibility, userID: string) {
	return visibility == EntryVisibility.public ? [userID, "public"] : [userID]
}