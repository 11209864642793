import {currentCamera} from "../../../UI/Tools/@Shared/CameraInterface";
import moment from "moment";
import {Assert, Timer} from "js-vextensions";
import {VMediaRecorder, BlobToArrayBuffer} from "web-vcore";
import {Text} from "react-vcomponents";
import {InDesktop} from "../../../Utils/Bridge/Bridge_Native";
import {SaveSessionRecording} from "../../../Utils/Bridge/Bridge_Preload";
import {FBAConfig_Camera} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Camera";
import {FBASession, GetSessionSubpath} from "../../../Engine/FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp";

export class CameraComp extends EngineSessionComp<FBAConfig_Camera> {
	constructor(session: FBASession, config: FBAConfig_Camera) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetStatusUI() {
		let currentMotionRecordingLength: number|n, currentMotionRecording_timeSinceFirstMotion: number|n, currentMotionRecording_timeSinceLastMotion: number|n;
		if (this.motionRecorder_forCurrentMotion) {
			currentMotionRecordingLength = Date.now() - this.motionRecorder_forCurrentMotion.recordingStartTime;
			currentMotionRecording_timeSinceFirstMotion = Date.now() - this.motionRecorder_forCurrentMotion["firstMotionTriggerTime"];
			currentMotionRecording_timeSinceLastMotion = Date.now() - this.motionRecorder_forCurrentMotion["lastMotionTriggerTime"];
		}
		return <Text style={{whiteSpace: "pre"}}>{`
			Motion recordings: ${this.motionRecordingsSaved} 
			Current motion recording: ${!this.motionEndWaiter.Enabled ? "none" :
				`active (time since...${""
				} record start: ${currentMotionRecordingLength != null ? Math.floor(currentMotionRecordingLength / 1000) : "n/a"
				}s, first motion: ${currentMotionRecording_timeSinceFirstMotion != null ? Math.floor(currentMotionRecording_timeSinceFirstMotion / 1000) : "n/a"
				}s, last motion: ${currentMotionRecording_timeSinceLastMotion != null ? Math.floor(currentMotionRecording_timeSinceLastMotion / 1000) : "n/a"
				}s)`}
		`.AsMultiline(1)}</Text>;
	}

	// Since we don't know when a motion-trigger will occur, we have to create two "buffers", which hold camera data on the chance that motion will be detected.
	// Each recorder starts as a short-recorder, lasting as such until it reaches the buffer duration; then it becomes a long-recorder -- to then be used if motion is detected.
	motionRecorder_short: VMediaRecorder;
	motionRecorder_long: VMediaRecorder|n;
	GraduateRecorders_IfMotionNotActive() {
		//if (this.motionEndWaiter.Enabled) return; // if motion-recording in progress, keep long-recorder running
		/*console.log(`Graduating recorders... @oldShort_length:${Math.floor((Date.now() - this.motionRecorder_short?.recordingStartTime) / 1000)
			}s) @oldLong_length:${Math.floor((Date.now() - this.motionRecorder_long?.recordingStartTime) / 1000)}s)`);*/

		// end current long-recorder, since it ended up not being needed
		if (this.motionRecorder_long) {
			this.motionRecorder_long.StopRecording();
		}

		// graduate short-recorder to be new long-recorder
		this.motionRecorder_long = this.motionRecorder_short;

		// create new short-recorder
		//let canvasStream = currentCamera.bufferCanvas["captureStream"]() as MediaStream; // leave out framerate, to capture every frame (ie. each time the canvas changes)
		// to avoid memory leak in chrome/electron, always use the same capture stream (apparently chrome can't properly garbage-collect MediaStream instances)
		if (currentCamera.bufferCanvasStream == null) {
			currentCamera.bufferCanvasStream = currentCamera.bufferCanvas["captureStream"]() as MediaStream;
		}
		const canvasStream = currentCamera.bufferCanvasStream;

		this.motionRecorder_short = new VMediaRecorder();
		this.motionRecorder_short.StartRecording_Video(canvasStream, {mimeType: "video/webm;codecs=vp9"});
	}
	//recorderGraduator = new Timer(this.s.c.camera.recordMotion_preMotionBuffer * 1000, ()=>{
	recorderGraduator = new Timer(1000, ()=>{
		const shortRecorder_length = Date.now() - this.motionRecorder_short.recordingStartTime;
		//const longRecorder_length = Date.now() - this.motionRecorder_long.recordingStartTime;
		// if short-recorder is long enough to fulfill window-duration requirement, try to graduate it to be the long-recorder
		if (shortRecorder_length >= this.c.preMotionBuffer * 1000) {
			this.GraduateRecorders_IfMotionNotActive();
		}
	}).SetContext(this.s.timerContext);

	OnStart() {
		currentCamera.ConnectEngineCameraComp(this);
		//currentCamera.MaybeToggleStreaming(); // auto-called by mobx autorun

		if (this.c.camMotion_record) {
			// start two recorders right away, so if one gets "claimed" by a motion-detection, we have another ready (even if not staggered yet)
			this.GraduateRecorders_IfMotionNotActive();
			this.GraduateRecorders_IfMotionNotActive();
			this.recorderGraduator.Start();
		}
	}
	OnStop(saveLocalData: boolean) {
		if (this.c.camMotion_record) {
			this.recorderGraduator.Stop();

			// if motion-recording is active, stop and save its contents
			//if (this.motionEndWaiter.Enabled) {
			if (this.motionRecorder_forCurrentMotion) {
				this.EndCurrentMotionRecorder(saveLocalData);
			}
		}

		currentCamera.DisconnectEngineCameraComp();
		//currentCamera.MaybeToggleStreaming(); // auto-called by mobx autorun
	}

	motionRecordingsSaved = 0;
	motionRecorder_forCurrentMotion: VMediaRecorder|n;
	motionEndWaiter = new Timer(this.s.c.camera.postMotionBuffer * 1000, ()=>{
		this.EndCurrentMotionRecorder(true);
	}, 1).SetContext(this.s.timerContext);
	maxVideoLengthWaiter: Timer;
	OnMotionTrigger() {
		// if no motion-recorder is active, "claim" the long-recorder as our own, then graduate short-recorder to fill the long-recorder slot
		if (this.motionRecorder_forCurrentMotion == null) {
			this.motionRecorder_forCurrentMotion = this.motionRecorder_long;
			this.motionRecorder_forCurrentMotion!["firstMotionTriggerTime"] = Date.now();
			this.motionRecorder_long = null;
			this.GraduateRecorders_IfMotionNotActive();

			const videoLengthAlreadyCaptured = Date.now() - this.motionRecorder_forCurrentMotion!.recordingStartTime;
			const maxVideoLengthLeft = (this.s.c.camera.maxVideoLength * 1000) - videoLengthAlreadyCaptured;
			this.maxVideoLengthWaiter = new Timer(maxVideoLengthLeft, ()=>{
				this.EndCurrentMotionRecorder(true);
			}, 1).SetContext(this.s.timerContext);
			this.maxVideoLengthWaiter.Start();
		}

		this.motionRecorder_forCurrentMotion!["lastMotionTriggerTime"] = Date.now();
		this.motionEndWaiter.Start();
	}
	async EndCurrentMotionRecorder(saveToDisk: boolean) {
		//Assert(this.motionEndWaiter.Enabled && this.maxVideoLengthWaiter.Enabled);
		this.motionEndWaiter.Stop();
		this.maxVideoLengthWaiter.Stop();

		const motionRecorder = this.motionRecorder_forCurrentMotion;
		Assert(motionRecorder != null, "No motion-recorder is active.");
		this.motionRecorder_forCurrentMotion = null;

		// unfortunately, you need to stop the recorder to obtain all the data-chunks (I think)
		await motionRecorder.StopRecording();
		if (!saveToDisk) return;

		const recordingStopTime = Date.now();
		const recordingBlob = new Blob(motionRecorder.dataChunks, {type: "video/webm"});
		const recordingArrayBuffer = await BlobToArrayBuffer(recordingBlob);
		//const contents_withSeekFix = await FixMediaBlobMetadata(contents); // not using this approach, since duration is sometimes off by several seconds

		if (InDesktop()) {
			//await SaveBlobToFile(contents, path);
			//await SaveVideoBlobToFile_WithSeekFix(contents, path);
			SaveSessionRecording(this.s.folderName, motionRecorder.recordingStartTime, recordingStopTime, motionRecorder["firstMotionTriggerTime"], motionRecorder["lastMotionTriggerTime"], recordingArrayBuffer);
		} else {
			console.log(`Failed to save session video-recording, since only supported in the desktop app for now.`);
			// todo: find way to save to file in browser, without popup
		}
		this.motionRecordingsSaved++;
	}
}