import {autorun} from "mobx";
import {StoreAccessor} from "mobx-firelink";
import {store} from "../../../Store/index.js";
import {GetSelectedFBAConfig} from "../../../Store/firebase/fbaConfigs";
import {GetOpenSession} from "../../../Store/firebase/sessions";
import {GetSelectedSessionData, IsSessionUIOpen} from "../../../Store/main/timeline";
import {OnStoreLoaded} from "../../../Utils/General/GlobalHooks";
import {SessionDataProcessor} from "./SessionDataProcessor";

export let sessionProcessor_orig = new SessionDataProcessor();
export let sessionProcessor_resim = new SessionDataProcessor();
export const GetSessionProcessor_Active = StoreAccessor(s=>()=>{
	const uiState = store.main.timeline.sessions;
	const useResim = uiState.processing_useCurrentConfig;
	return useResim ? sessionProcessor_resim : sessionProcessor_orig;
});
OnStoreLoaded(()=>{
	// keep detail-panel processors up-to-date with engine-config
	autorun(()=>{
		const uiState = store.main.timeline.sessions;
		const session = GetOpenSession();
		if (session == null) {
			// clear processor data, when leaving/closing session-ui
			sessionProcessor_orig.Init_Empty();
			sessionProcessor_resim.Init_Empty();
			return;
		}
		if (!session._local) return; // wait till local-data for session comes in (and if non-existent, we can't do processing anyway)

		const currentConfig = GetSelectedFBAConfig();
		if (currentConfig == null) return;
		const sessionData = GetSelectedSessionData();
		if (sessionData == null) return;
		// if we're not on a page that cares about the detail-panel eeg-processor, delay the effects (updating options, and starting/resetting processing)
		if (!IsSessionUIOpen()) return;

		// if selected-session changed, recreate session-data-processors
		if (sessionProcessor_orig && sessionProcessor_orig.loadedSessionData?.session && session != sessionProcessor_orig.loadedSessionData?.session) {
			//sessionProcessor_orig.ResetData();
			sessionProcessor_orig = /*session == null ? null :*/ new SessionDataProcessor();
		}
		if (sessionProcessor_resim.loadedSessionData?.session && session != sessionProcessor_resim.loadedSessionData?.session) {
			//sessionProcessor_resim.ResetData();
			sessionProcessor_resim = /*session == null ? null :*/ new SessionDataProcessor();
		}

		// tell active processor to use current settings (can trigger clearing of cached data)
		const useResim = uiState.processing_useCurrentConfig;
		const sessionProcessor_active = useResim ? sessionProcessor_resim : sessionProcessor_orig;
		sessionProcessor_active.UpdateConfig(useResim ? currentConfig : session.config, {
			performResim: useResim,
			eegAndGyro_normalize: uiState.eeg_normalize,
			eegAndGyro_smooth: uiState.eeg_smooth,
			clearOutOfRangeCache: true,
		});
		//sessionProcessor_active.EnsureInit();

		// if active processor has not yet had its raw-data populated, do so
		//if (sessionProcessor_active.loadedSessionData?.session == null) {
		if (sessionProcessor_active.loadedSessionData == null) {
			sessionProcessor_active.Init_SavedSession({
				session,
				eegSampleFiles: sessionData.eegSampleFiles,
				gyroSampleFiles: sessionData.gyroSampleFiles,
				eeg_activityByTime: sessionData.generalHistory.eegActivity.activityByTime,
				gyro_motionsByTime: sessionData.generalHistory.gyroMotion.motionsByTime,
			});
		}
	});
	// keep active processor up-to-date with current-time (and update processorTimer.Enabled states)
	autorun(()=>{
		const uiState = store.main.timeline.sessions;
		const {viewRange_startTime, viewRange_endTime} = uiState; // access these vals before check, so we always react to their changes

		const useResim = uiState.processing_useCurrentConfig;
		const sessionProcessor_active = useResim ? sessionProcessor_resim : sessionProcessor_orig;
		const sessionProcessor_inactive = sessionProcessor_active == sessionProcessor_orig ? sessionProcessor_resim : sessionProcessor_orig;
		// tell active processor to enable its processing-timer, and inactive one to disable its
		sessionProcessor_active.processingTimer.Enabled = !sessionProcessor_active.processingComplete;
		sessionProcessor_inactive.processingTimer.Enabled = false;

		const sessionData = GetSelectedSessionData();
		//if (detailPanelEEGProcessor.sampleIndexesForSeconds == null) return; // data not yet loaded
		if (sessionData == null) return; // data not yet loaded
		if (sessionProcessor_active.loadedSessionData?.session?.id != sessionData.sessionID) return; // if in middle of loaded-session update

		const visibleRange_startSampleIndex = sessionProcessor_active.eegProcessor.GetSampleIndexForTime(viewRange_startTime, true);
		const visibleRange_endSampleIndex = sessionProcessor_active.eegProcessor.GetSampleIndexForTime(viewRange_endTime, true);
		const visibleRange_centerSampleIndex = Math.floor((visibleRange_startSampleIndex + visibleRange_endSampleIndex) / 2);

		sessionProcessor_active.SetViewCenter(visibleRange_centerSampleIndex);
	});
});