import {AssertWarn, StoreAccessor} from "mobx-firelink";
import {dayInMS, hourInMS, minuteInMS} from "web-vcore";
import {liveFBASession} from "../../../Engine/FBASession.js";
import {SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import type {FBASession_Local} from "../../../Engine/FBASession_Local.js"; // import just type, to avoid cycle
import {FBAConfig} from "../fbaConfigs/@FBAConfig.js";
import {EstimateDreamEndTime, EstimateDreamStartTime, EstimateSegmentStartTime, GetJournalEntries} from "../journalEntries.js";
import {JournalEntry} from "../journalEntries/@JournalEntry.js";
import {GetSessions} from "../sessions.js";
import {EngineSessionInfo} from "../sessions/@EngineSessionInfo.js";
import {MeID} from "../users.js";
import {EventGroup, SessionPeriod, SleepCycle} from "./SessionPeriod.js";
import {untracked} from "mobx";

// this is just for debugging from dev-tools
export function GetSessionPeriods_Me() {
	const journalEntries = GetJournalEntries(MeID());
	const sessions = GetSessions(MeID());
	return GetSessionPeriods(journalEntries, sessions);
}

export function GetSessionPeriods(journalEntries: JournalEntry[], sessions: EngineSessionInfo[], appendLiveSessionInfo = true, warnIf5PlusSessionsInRange = false): SessionPeriod[] {
	const result = [] as SessionPeriod[];

	const sessions_unclaimed = sessions.slice();
	if (appendLiveSessionInfo && liveFBASession && liveFBASession.constructor.name == "FBASession_Local") {
		//const sessionInfoSoFar = (liveFBASession as FBASession_Local).GetSessionInfo();
		//const eventCount = untracked(()=>liveFBASession?.coreData.events.length);
		const sessionInfoSoFar = ConstructLiveSessionInfoSoFar();
		if (sessionInfoSoFar) {
			sessions_unclaimed.push(sessionInfoSoFar);
		}
	}

	// start by looping through all journal-entries, and creating a session-period for each (including intersecting sessions)
	for (const [i, entry] of journalEntries.entries()) {
		const nextEntry = journalEntries[i + 1];
		const sessionsInRange = sessions_unclaimed.filter(session=>{
			const sessionStartsAtOrAfterThisSegment = entry.sleepTime && session.startTime >= entry.sleepTime;
			const sessionIsBeforeNextSegment = nextEntry == null || (nextEntry.sleepTime && session.startTime < nextEntry.sleepTime);
			return sessionStartsAtOrAfterThisSegment && sessionIsBeforeNextSegment;
		});
		if (sessionsInRange.length > 5 && warnIf5PlusSessionsInRange) {
			console.warn("More than 5 sessions were found in range for a single journal-entry. This *might* indicate a bug, or bad data.");
			console.log("Journal-entry start-time:", entry.sleepTime ? new Date(entry.sleepTime).toLocaleString("sv") : "n/a");
			console.log("Next journal-entry start-time:", nextEntry?.sleepTime ? new Date(nextEntry.sleepTime).toLocaleString("sv") : "n/a");
			for (const [i, session] of sessionsInRange.entries()) {
				console.log(`#${i + 1} session start-time:`, new Date(session.startTime).toLocaleString("sv"));
			}
			debugger;
		}

		const period = ConstructSessionPeriodForJournalEntry(entry, sessionsInRange);
		result.push(period);

		for (const session of sessionsInRange) {
			sessions_unclaimed.Remove(session);
		}
	}

	// then, for each session that is not part of any journal-entry, create a session-period for it
	for (const session of sessions_unclaimed) {
		const period = ConstructSessionPeriodForUnclaimedSession(session);
		result.push(period);
	}

	// then return the session-periods, sorted by start-time
	return result.OrderBy(a=>a.startTime);
}

/** Use this as a cache-point for constructing the live-session info-so-far.
 * It only is re-calced when the live-session event-count changes, OR when one of the mobx fields accessed in GetSessionInfo() is modified. */
export const ConstructLiveSessionInfoSoFar = StoreAccessor(s=>()=>{
	const tracker1_eventCount = liveFBASession?.coreData.events.length;
	return liveFBASession?.AsLocal?.GetSessionInfo();
});

export const ConstructSessionPeriodForJournalEntry = StoreAccessor(s=>(entry: JournalEntry, sessionsInRange: EngineSessionInfo[])=>{
	const eventGroups = GetEventGroupsInSessions(sessionsInRange);
	const sleepCycles = DiscernSleepCyclesFromJournalEntry(entry, sessionsInRange, eventGroups);
	
	/*if (liveFBASession && sessionsInRange.Any(a=>a.startTime == liveFBASession!.coreData.startTime)) {
		console.log("Reconstructing session-period for live-session. EventGroups:", eventGroups);
	}*/

	return new SessionPeriod({
		startTime: EstimateDreamStartTime(entry),
		endTime: EstimateDreamEndTime(entry),
		sessions: sessionsInRange,
		eventGroups,
		journalEntries: [entry],
		journalSegments: entry.segments,
		sleepCycles,
	});
});

export const ConstructSessionPeriodForUnclaimedSession = StoreAccessor(s=>(session: EngineSessionInfo)=>{
	const sleepCycles = [ConstructFakeSleepCycleForStandaloneSession(session)];
	return new SessionPeriod({
		startTime: session.startTime,
		endTime: session.endTime,
		sessions: [session],
		eventGroups: GetEventGroupsInSessions([session]),
		journalEntries: [],
		journalSegments: [],
		sleepCycles,
	});
});

export function GetEventGroupsInSessions(sessions: EngineSessionInfo[]): EventGroup[] {
	const sessionEventsInRange = sessions.SelectMany(a=>{
		return [
			// add synthetic event, for start of session
			new SessionEvent({date: a.startTime, type: "General.SessionStart", _isSynthetic: true}),
			...a.events,
			// add synthetic event, for end of session
			new SessionEvent({date: a.endTime ?? Date.now(), type: "General.SessionEnd", _isSynthetic: true}),
		];
	}).OrderBy(a=>a.date);
	
	const eventGroups = [] as EventGroup[];
	
	type EventGroup_BeingConstructed = PartialBy<EventGroup, "sessionInfo" | "type" | "duration">;
	let currentGroup = {events: [] as SessionEvent[]} as EventGroup_BeingConstructed;
	const endCurrentGroup = ()=>{
		currentGroup.duration = currentGroup.events.Last().date - currentGroup.events.First().date;
		const groupFirstNonSyntheticEvent = currentGroup.events.find(a=>!a._isSynthetic);
		currentGroup.sessionInfo = sessions.find(session=>{
			//return session.events.ContainsAny(...currentGroup.events);
			return session.events.includes(groupFirstNonSyntheticEvent as any);
		});
		eventGroups.push(currentGroup as EventGroup);
		currentGroup = {events: []} as EventGroup_BeingConstructed;
	};
	for (const [i, event] of sessionEventsInRange.entries()) {
		//const prevEvent: SessionEvent|n = sessionEventsInRange[i - 1];
		
		let isGroupEnder = false;
		// events for group-type: special
		if (event.type == "General.SessionRecovered") {
			// end previous group (session-recovery should be its own group)
			if (currentGroup.events.length) endCurrentGroup();
			currentGroup.type = "special";
			// end new group (session-recovery should be its own group)
			isGroupEnder = true;
		}
		// events for group-type: wait-period
		if (event.type == "General.SessionStart") {
			// if a group is already in-progress, end it now (can't have groups span multiple sessions)
			if (currentGroup.events.length) endCurrentGroup();
		} else if (event.type == "General.SessionEnd") {
			isGroupEnder = true;
		} else if (event.type == "General.InitialDelayEnd") {
			currentGroup.type = "wait-period";
			isGroupEnder = true;
		} else if (event.type == "Journey.ResleepStart") {
			currentGroup.type = "wait-period";
		} else if (event.type == "Journey.ResleepSkip") {
			isGroupEnder = true;
		} else if (event.type == "Journey.ResleepEnd") {
			isGroupEnder = true;
		}
		// events for group-type: cycle
		else if (event.type == "Journey.CycleStart") {
			currentGroup.type = "cycle";
		} else if (event.type == "Journey.CycleFail") {
			isGroupEnder = true;
		} else if (event.type == "Journey.CycleSuccess") {
			isGroupEnder = true;
		}

		currentGroup.events.push(event);
		if (isGroupEnder) endCurrentGroup();
	}
	if (currentGroup.events.length) endCurrentGroup();
	return eventGroups;
}

export function ConstructFakeSleepCycleForStandaloneSession(session: EngineSessionInfo) {
	return new SleepCycle({
		cycleNumber: 0, // this marks the sleep-cycle as "fake"
		startTime: session.startTime,
		endTime: session.endTime,
		associatedDreamSegments: [],
		associatedSessions: [session],
		associatedEventGroups: GetEventGroupsInSessions([session]),
		alarmDelay: 0,
		alarmWaitEndTime: 0,
		responseTime: 0,
	});
}

export function DiscernSleepCyclesFromJournalEntry(
	dream: JournalEntry,
	sessionsInRange: EngineSessionInfo[], eventGroups: EventGroup[],
) {
	const result = [] as SleepCycle[];

	const newCycle = ()=>new SleepCycle({
		cycleNumber: result.length + 1,
		startTime: 0,
		endTime: 0,
		// these arrays all start out empty, but can get populated by the two loops below (through dream-segments, and through sessions)
		associatedDreamSegments: [],
		associatedSessions: [],
		associatedEventGroups: [],
		alarmDelay: 0,
		alarmWaitEndTime: 0,
		responseTime: 0,
	});

	if (dream.segments.length) {
		let lastCycle: SleepCycle|n;
		let currentCycle = newCycle();
		for (const segment of dream.segments) {
			currentCycle.startTime = currentCycle.startTime || lastCycle?.endTime || EstimateSegmentStartTime(dream, segment);

			currentCycle.associatedDreamSegments.push(segment)
			if (segment.wakeTime != null && currentCycle.associatedDreamSegments.filter(a=>a.wakeTime == null).length) {
				currentCycle.endTime = segment.wakeTime;
				result.push(currentCycle);
				currentCycle = newCycle();
			}
		}
		if (currentCycle.associatedDreamSegments.length) {
			//currentCycle.endTime = EstimateSegmentEndTime(dream, currentCycle.associatedDreamSegments.Last());
			result.push(currentCycle);
		}
	}

	//Assert(!infoForAssociation?.segments.Any(a=>a.wakeTime != null), "FindCycleNumberForDreamSegment should only be called for non-wake segments.");
	const groupIsAlarmWait = group=>group.type == "wait-period" && group.events.Any(b=>b.type == "Journey.ResleepEnd");

	let alarmWaitsReached = 0;
	let lastAlarmWait_delay = 0;
	let lastAlarmWait_endTime: number|n;
	const eventGroupsForNextSleepCycleAugment = [] as EventGroup[];
	for (const [i, group] of eventGroups.entries()) {
		//const priorGroups = eventGroups.slice(0, i);
		const lastEvent = group.events.Last();
		eventGroupsForNextSleepCycleAugment.push(group);

		if (groupIsAlarmWait(group)) {
			alarmWaitsReached++;
			// if the session had the alarm-delay locked to a single value, prefer that over duration inferred from the session-events
			// (the session-events can be complicated by eg. manual resets of sleep-timer, so a known locked alarm-delay is more reliable)
			lastAlarmWait_delay = group.sessionInfo.alarmDelay ?? group.duration;
			lastAlarmWait_endTime = lastEvent.date;
		} else if (group.type == "cycle" && group.events.Any(a=>a.type == "Journey.CycleSuccess") && lastAlarmWait_endTime != null) {
			const cycleNumber = alarmWaitsReached; // the "first real cycle" comes after the first alarm-wait
			const graphGroup_alarmDelay = (lastAlarmWait_delay / minuteInMS).RoundTo(1) ?? 0;

			const userResponseEvent = group.events.find(a=>a.type == "Journey.ListenStart") ?? lastEvent;
			const responseTime = userResponseEvent.date - lastAlarmWait_endTime;
			if (responseTime > dayInMS) {
				AssertWarn(false, `Response-time for cycle ${cycleNumber} is too long: ${responseTime / hourInMS}h (discarding)`);
				continue;
			}

			// journal-segment gets added by engine first; so find the newest segment that ended before this "Journey.CycleSuccess" event
			const bestSleepCycleMatch = result.findLast(a=>a.endTime && a.endTime < lastEvent.date);
			if (bestSleepCycleMatch) {
				bestSleepCycleMatch.alarmDelay ||= graphGroup_alarmDelay;
				bestSleepCycleMatch.alarmWaitEndTime ||= lastAlarmWait_endTime;
				bestSleepCycleMatch.responseTime ||= responseTime;
				bestSleepCycleMatch.associatedEventGroups.push(...eventGroupsForNextSleepCycleAugment);
				eventGroupsForNextSleepCycleAugment.Clear();
			}
		}
	}
	// any event-groups at end, apply to last sleep-cycle
	if (eventGroupsForNextSleepCycleAugment.length && result.length) {
		result.Last().associatedEventGroups.push(...eventGroupsForNextSleepCycleAugment);
	}

	return result;
}

export function Legacy_GetInitialDelay(config: FBAConfig, fallbackValue = 0) {
	return config.general?.initialDelay ?? config["initialDelay"] ?? fallbackValue;
}
export function Legacy_FirstCycleCountsAsRehearsalForConfig(config: FBAConfig) {
	return Legacy_GetInitialDelay(config) <= 60000;
};

export function GetMaxPossibleCycleSuccesses(sessionInfosInRange: EngineSessionInfo[], eventGroups: EventGroup[]) {
	if (sessionInfosInRange.length == 0) return 0;
	// fallback for ancient sessions with configs that miss needed data
	const session1Conf = sessionInfosInRange[0].config;
	if (session1Conf.journeyVisualization?.cycleReverse_minTime == null) return 0;
	if (session1Conf.alarms?.alarmDelay_pool == null) return 0;
	if (session1Conf.journeyGrid?.targetDelay_minTime == null) return 0;
	
	const totalTime = sessionInfosInRange.map(a=>a.endTime ?? Date.now()).Max() - sessionInfosInRange.map(a=>a.startTime).Min();
	const timeForInitDelays = sessionInfosInRange.map(a=>Legacy_GetInitialDelay(a.config)).Sum();
	// number of sessions that were "possible to have had a successful initial-cycle in", ie. those which completed their initial-delay
	const nonRehearsalInitCycles = sessionInfosInRange.filter(a=>!Legacy_FirstCycleCountsAsRehearsalForConfig(a.config) && a.events.Any(a=>a.type == "Journey.CycleStart")).length;
	
	const timeForAlarmWaitPlusCyclePairs = totalTime - timeForInitDelays;

	const cycleType = sessionInfosInRange.Any(a=>a.events.Any(b=>b.type == "Journey.CycleReverse")) ? "visualization" : "grid";
	// Should the alarm-delay "min" be used here, or the "average"? Not sure...
	const alarmWaitPlusCyclePair_minLength = cycleType == "visualization"
		? sessionInfosInRange[0].config.journeyVisualization.cycleReverse_minTime + sessionInfosInRange[0].config.alarms.alarmDelay_pool.Min()
		: sessionInfosInRange[0].config.journeyGrid.targetDelay_minTime + sessionInfosInRange[0].config.alarms.alarmDelay_pool.Min();

	return nonRehearsalInitCycles + (timeForAlarmWaitPlusCyclePairs / alarmWaitPlusCyclePair_minLength).FloorTo(1);
}

export function EventGroupWasRehearsalCycle(group: EventGroup, allGroups: EventGroup[]) {
	// if this event-group is not even a cycle group (eg. instead an alarm-wait period), then it can't be a rehearsal-cycle
	//if (!group.events.Any(a=>a.type == "Journey.CycleStart")) return false;
	if (group.type != "cycle") return false;
	
	const session = group.sessionInfo;
	const groupsInSession = allGroups.filter(a=>a.sessionInfo == session);
	const priorGroupsInSession = groupsInSession.slice(0, groupsInSession.indexOf(group));
	const priorGroupsInSession_lastIndexOfRehearsallyInitDelayOrAlarmWaitSkip = priorGroupsInSession.SelectMany(a=>a.events).findLastIndex(a=>{
		return (Legacy_FirstCycleCountsAsRehearsalForConfig(session.config) && a.type == "General.InitialDelayEnd") || a.type == "Journey.ResleepSkip";
	});
	const priorGroupsInSession_lastIndexOfAlarmWaitEnd = priorGroupsInSession.SelectMany(a=>a.events).findLastIndex(a=>a.type == "Journey.ResleepEnd");
	// if initial-delay or last-alarm-wait-skip (before target cycle-group) was closer to target cycle-group than the last alarm-wait-end, then the cycle was a "rehearsal"
	// (the idea of a "rehearsal cycle" is one that was the first of the night [if initial delay was <1m], or it was skipped to -- ie. not organic / interacted with after actual awakening)
	return priorGroupsInSession_lastIndexOfRehearsallyInitDelayOrAlarmWaitSkip > priorGroupsInSession_lastIndexOfAlarmWaitEnd;
};