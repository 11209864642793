import {AddSchema, AssertValidate, Command, GetSchemaJSON, NewSchema} from "mobx-firelink";
import {GetStory, Story} from "../../Store/firebase/stories.js";

type MainType = Story;
const MTName = "Story";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.IncludeKeys("title"),
		}),
	},
	required: ["id", "updates"],
}));

export class UpdateStory extends Command<{id: string, updates: Partial<MainType>}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = GetStory.NN(id);
		this.newData = {...this.oldData, ...updates} as MainType;
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`stories/${id}`] = this.newData;
		return updates;
	}
}