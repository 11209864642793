import { registerPlugin } from '@capacitor/core';

export abstract class GeneralPlugin {
	SendToNative(channelMessage: any) {}
	StartSessionHelpers(options) {}
	StopSessionHelpers() {}
	StartPartialWakeLock() {}
	StartWifiLock() {}
	DisableBatteryOptimizations() {}
	DisableWebViewOptimizations() {}
	StopPartialWakeLock() {}
	StopWifiLock() {}
	EnableBatteryOptimizations() {}
	EnableWebViewOptimizations() {}
	async RobustFetch(options): Promise<{fetchResponseStr: string|n}> {
		return {fetchResponseStr: null};
	}
	async ImageToText(options: {imageData: string}): Promise<{text: string}> {
		return {text: ""};
	}
}

export const PluginGeneral = registerPlugin<GeneralPlugin>("General", {
	web: async()=>{
		return new GeneralPlugin_Web();
	},
});

// web version of plugin just leaves all empty; no behavior should take place for these calls, for web/non-android
class GeneralPlugin_Web extends GeneralPlugin {}