import {AssertV, AssertValidate, Command, GenerateUUID} from "mobx-firelink";
import {StoryMessage} from "../../Store/firebase/storyMessages.js";
import {UserEdit} from "../CommandMacros";

@UserEdit
export class AddStoryMessage extends Command<{message: StoryMessage}, string> {
	//allowCustomCreatedAt = false;
	
	id: string;
	Validate() {
		const {message} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		message.creator = this.userInfo!.id;
		//if (!this.allowCustomCreatedAt || message.createdAt == null) {
		message.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("StoryMessage", message, `StoryMessage invalid`);
	}

	GetDBUpdates() {
		const {message} = this.payload;

		const updates = {};
		updates[`storyMessages/${this.id}`] = message;
		return updates;
	}
}