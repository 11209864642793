import {AlarmSequence} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {FBAConfig_CountdownAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@EC_CountdownAlarm.js";
import {NarrateText_ForEngineComp} from "../../../../Utils/Services/TTS.js";
import {FBASession} from "../../../FBASession";
import {AlarmComp} from "./@AlarmComp";

export class CountdownAlarmComp extends AlarmComp<FBAConfig_CountdownAlarm> {
	constructor(session: FBASession, sequence: AlarmSequence, config: FBAConfig_CountdownAlarm) {
		super(session, sequence, config);
	}
	
	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.s.c.general.defaultVoice_volumeMultiplier * volumeMultiplier);
	}

	// Let's say effect-interval is 1s, and auto-end is 10s. Here's what we want:
	// * At t0 (time of alarm-phase start), "10" is immediately narrated.
	// * At t9, "1" is narrated.
	// * At t10, "0" is narrated.
	CountdownStartNumber() {
		if (this.c.autoEndAfter <= 0 || this.c.effectInterval <= 0) return 1;
		return Math.ceil(this.c.autoEndAfter / this.c.effectInterval);
	}

	override async PlayAlarmEffect() {
		const countdownNumber = this.CountdownStartNumber() - (this.effectApplyAttempts_sinceAlarmStart - 1);
		// Not sure how to best handle cases of countdownNumber being 0, or -1 (eg. due to race-condition between autoEndTimer and effectApplyTimer).
		// For now, we just ignore any countdown-number less than 1. (reasoning: avoid narration of "0" potentially conflicting with other sounds racing at that moment)
		if (countdownNumber <= 0) return;
		
		await this.NarrateText(countdownNumber + "", this.intensity);
	}
	override UpdatePrompt_ForReducedIntensity() {}
	/*override StopAlarm(forAutoEnd = false) {
		super.StopAlarm(forAutoEnd);
		StopAllSpeech();
	}*/
}