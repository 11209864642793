import {E, emptyArray} from "js-vextensions";
import {runInAction} from "mobx";
import moment from "moment";
import React from "react";
import {Button, Column, Row, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {VMenuItem, VMenuStub} from "react-vmenu";
import {ScrollView} from "react-vscrollview";
import {store} from "../../Store/index.js";
import {JournalEntry} from "../../Store/firebase/journalEntries/@JournalEntry.js";
import {GetSession, GetSessions, UpdateLocalSessionsMap} from "../../Store/firebase/sessions.js";
import {EngineSessionInfo} from "../../Store/firebase/sessions/@EngineSessionInfo.js";
import {MeID} from "../../Store/firebase/users.js";
import {GetSelectedSessionData, SetCurrentTime} from "../../Store/main/timeline.js";
import {VerifySessionGeneralData} from "../../Utils/Bridge/Bridge_Preload.js";
import {ES, styles} from "../../Utils/UI/GlobalStyles.js";
import {Observer, PageContainer, RunInAction} from "web-vcore";
import {moment_local} from "../../Utils/General/General.js";
import {SessionUI} from "./Sessions/SessionUI.js";
import {SessionsUISettingsDropdown} from "./Sessions/SessionsUISettingsDropdown.js";

const columnWidths = [.07, .4, .03, .4];

@Observer
export class SessionsUI extends BaseComponent<{}, {}> {
	render() {
		if (MeID() == null) return <PageContainer>You must be signed in to access this page.</PageContainer>;

		const uiState = store.main.timeline.sessions;
		const selectedSession = GetSession(uiState.selectedSession);
		const sessionData = selectedSession ? GetSelectedSessionData() : null;
		if (selectedSession && sessionData) {
			return <PageContainer style={{padding: 0, height: "100%", flex: "0 1 100%", margin: "40px 10px 10px", maxHeight: "calc(100% - 50px)"}}>
				<SessionUI session={selectedSession}/>
			</PageContainer>;
		}

		const sessions = MeID() ? GetSessions(MeID()) : emptyArray;
		return (
			<PageContainer style={{padding: 0}}>
				<Column className="clickThrough" style={{height: 70, background: "rgba(0,0,0,.7)", borderRadius: "10px 10px 0 0"}}>
					<Row style={{height: 40, padding: 10}}>
						<span style={{position: "absolute", left: "50%", transform: "translateX(-50%)", fontSize: 18}}>Sessions</span>
						<Row ml="auto">
							<Button size={32} mdIcon="refresh" title="Refreshes list of local sessions" onClick={()=>{
								UpdateLocalSessionsMap(true);
							}}/>
							<SessionsUISettingsDropdown sessions={sessions}/>
						</Row>
					</Row>
					<Row style={{height: 30, padding: 10}}>
						<span style={{flex: columnWidths[0], fontWeight: 500, fontSize: 15}} title="Where session-data is stored: web, local. (eeg samples and other large data only stored locally)">Storage</span>
						<span style={{flex: columnWidths[1], fontWeight: 500, fontSize: 15}}>Period</span>
						<span style={{flex: columnWidths[2], fontWeight: 500, fontSize: 15}} title="Whether has dream-journal entry associated. (star for lucid one)">DJ</span>
						<span style={{flex: columnWidths[3], fontWeight: 500, fontSize: 15}} title="Number and lengths of REM-sleep segments.">REM</span>
					</Row>
				</Column>
				<ScrollView>
					{sessions.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
					{sessions.OrderByDescending(a=>a.startTime).map((activity, index)=>{
						return <SessionRow key={index} session={activity} index={index} last={index == sessions.length - 1}/>;
					})}
				</ScrollView>
			</PageContainer>
		);
	}
}

export function GetSessionPeriodStr(session: EngineSessionInfo) {
	const startTimeStr = moment_local(session.startTime, session.localOffsetFromUTC).format("YYYY-MM-DD HH:mm:ss");
	const timeRangeStr = `${startTimeStr}-${moment_local(session.endTime, session.localOffsetFromUTC).format("HH:mm:ss")}`;

	const durationInMinutes = (session.endTime - session.startTime) / 60000;
	const duration_hours = Math.floor(durationInMinutes / 60);
	const duration_minutes = durationInMinutes % 60;
	const durationStr = `${duration_hours}:${duration_minutes.RoundTo(1).toString().padStart(2, "0")}`;

	let result = `${timeRangeStr} (duration: ${durationStr})`;
	if (session.localOffsetFromUTC != null && session.localOffsetFromUTC != moment().utcOffset()) {
		result += " [shown times are creator-local]";
	}
	return result;
}

let lastSelectedSessionID: string;
export function SelectSession(id: string) {
	const uiState = store.main.timeline.sessions;
	uiState.selectedSession = id;
	lastSelectedSessionID = id;
}
export class SessionRow extends BaseComponent<{session: EngineSessionInfo, index: number, last: boolean}, {}> {
	render() {
		const {session, index, last} = this.props;
		const uiState = store.main.timeline.sessions;
		const journalEntries = [] as JournalEntry[];

		return (
			<Row p="7px 10px" style={ES(
				{background: index % 2 == 0 ? "rgba(30,30,30,.7)" : "rgba(0,0,0,.7)", cursor: "pointer"},
				last && {borderRadius: "0 0 10px 10px"},
			)} onClick={()=>{
				RunInAction("SessionRow_click", ()=>{
					if (session.id != lastSelectedSessionID) {
						const viewRangeInMS = uiState.initialViewRange * 1000;
						SetCurrentTime(session.startTime + (viewRangeInMS * uiState.viewAnchorPosForTime), session, {adjustViewRange: "force", viewRangeOverride: viewRangeInMS});
					}
					SelectSession(session.id);
				});
			}}>
				<VMenuStub>
					<VMenuItem text="Verify session data" style={styles.vMenuItem} onClick={()=>{
						VerifySessionGeneralData(session._folderName);
					}}/>
				</VMenuStub>
				<span style={{flex: columnWidths[0]}}>
					<Row pr={10} style={{justifyContent: "space-evenly"}}>
						<Text title="Web (partial)" style={E(!session._online && {opacity: 0, pointerEvents: "none"})}>🌐</Text>
						<Text title="Local" style={E(!session._local && {opacity: 0, pointerEvents: "none"})}>💻</Text>
					</Row>
				</span>
				<span style={{flex: columnWidths[1]}}>{GetSessionPeriodStr(session)}</span>
				<span style={{flex: columnWidths[2]}}>
					{journalEntries.length == 0 && <Text>❌</Text>}
					{journalEntries.length > 0 && !journalEntries.Any(a=>a.segments.Any(b=>b.lucid)) && <Text>✔</Text>}
					{journalEntries.length > 0 && journalEntries.Any(a=>a.segments.Any(b=>b.lucid)) && <Text>⭐</Text>}
				</span>
				<span style={{flex: columnWidths[3]}}>
				</span>
			</Row>
		);
	}
}