import {UserEdit} from "../../Server/CommandMacros";
import {AssertValidate, GenerateUUID, Command, Validate, AssertV} from "mobx-firelink";
import {Bundle} from "../../Store/firebase/bundles/@Bundle";
import {RecordingSession} from "../../Store/firebase/recordingSessions/@RecordingSession";

@UserEdit
export class AddRecordingSession extends Command<{session: RecordingSession}> {
	id: string;
	Validate() {
		const {session} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		session.creator = this.userInfo!.id;
		session.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("RecordingSession", session, `RecordingSession invalid`);
	}

	GetDBUpdates() {
		const {session} = this.payload;

		const updates = {};
		updates[`recordingSessions/${this.id}`] = session;
		return updates;
	}
}