import {collection, doc, getFirestore, setDoc} from "firebase/firestore";
import {Bridge, Clone, Timer, WaitXThenRun} from "js-vextensions";
import {autorun} from "mobx";
import {DBPath, GenerateUUID} from "mobx-firelink";
import {JustBeforeUI_listeners} from "../../Main";
import {ClearOldUserProcessMessages} from "../../Server/Commands/ClearOldUserProcessMessages";
import {MeID} from "../../Store/firebase/users";
import {GetUserProcessMessages} from "../../Store/firebase/users/$user";
import {ProcessMessage} from "../../Store/firebase/users/@ProcessMessage";
import {OnStoreLoaded} from "../../Utils/General/GlobalHooks";
import {SendUserProcessesBridgeMessage_RobustFirebaseSet} from "../../Utils/Services/RobustFirebaseSet";
import {InAndroid} from "./Bridge_Native";

// Note: A "remote user process" is just any remote instance (so a remote site, desktop, or android instance -- though atm we only actually use it to send messages between site instances)

export const userProcessMessage_expireTime = 10000;
export const userProcessMessage_minClearInterval = 5000; // how often it's reasonable to allow old user-process-message clearing (the lower this is, the more individual clearings/calls occur)

export let lastOldUserProcessMessagesClearTime = 0;
export function TryClearOldUserProcessMessages() {
	if (Date.now() - lastOldUserProcessMessagesClearTime < userProcessMessage_minClearInterval) return;
	if (MeID() == null) return;
	new ClearOldUserProcessMessages({}).Run().catch(ex=>{
		// ignore; could occur from, eg. user sign-out completing during command run
	});
	lastOldUserProcessMessagesClearTime = Date.now();
}

const userProcessMessages_processed = new Set<string>();
function ProcessUserProcessMessages() {
	const userID = MeID();
	if (userID == null) return;
	const processMessages = GetUserProcessMessages(userID);
	for (const processMessage of processMessages) {
		if (userProcessMessages_processed.has(processMessage._key!)) continue;
		userProcessMessages_processed.add(processMessage._key!);

		if (processMessage.fromProcessID == processID) {
			//console.log(`Sent user-process-message${stillActive ? "" : " (expired)"}: `, processMessage);
			return;
		}

		const channelMessage = processMessage.message;
		const stillActive = Date.now() - processMessage.sendTime < userProcessMessage_expireTime;
		console.log(`Received user-process-message${stillActive ? "" : " (expired)"}: `, processMessage);
		// if message sent within the last X seconds, process; else ignore
		if (stillActive) {
			remoteUserProcessesBridge.receiveChannelMessageFunc(channelMessage);
		}
		WaitXThenRun(userProcessMessage_expireTime + 1000, ()=>TryClearOldUserProcessMessages()); // wait X+1 seconds, then clear any old-process-messages (which should include the current ones)
	}
}
const waitForUserIDTimer = new Timer(500, ()=>{
	try {
		if (MeID() != null) {
			waitForUserIDTimer.Stop();
			autorun(()=>ProcessUserProcessMessages());
		}
	} catch {}
});
// only start wait-timer after store is loaded (MS if startup-error blocks site init, the timer doesn't keep triggering errors in MeID(), obstructing dev-tool debugging)
OnStoreLoaded(()=>waitForUserIDTimer.Start());

export const processID = Date.now();
export const remoteUserProcessesBridge = new Bridge({
	receiveChannelMessageFunc_addImmediately: false,
	receiveChannelMessageFunc_adder: receiveChannelMessageFunc=>{
		/*store.firelink.subs.firestoreDB.collection(DBPath({}, `userExtras/${MeID()}/processMessages`))
			.onSnapshot(snapshot=>{
				snapshot.docChanges().forEach(change=>{
					if (change.type === "added") {
						const processMessage = change.doc.data() as ProcessMessage;
					if (change.type === "modified") {
					if (change.type === "removed") {
				});
			});*/
	},
	sendChannelMessageFunc: channelMessage=>{
		const processMessage = new ProcessMessage({user: MeID()!, fromProcessID: processID, sendTime: Date.now(), message: channelMessage});
		const processMessage_stripped = Clone(processMessage); // clone using json stringify/parse, so we lose class/prototype linkings (firebase demands simple prototype-less objects)
		console.log(`Sending user-process-message: `, processMessage_stripped);

		// if in android, try to send channel-message using the special function below, because it works even when the screen is off
		if (InAndroid(0) && SendUserProcessesBridgeMessage_RobustFirebaseSet(processMessage)) return;

		//const documentID = processMessage.sendTime.toString();
		const documentID = GenerateUUID();
		/*if (channelMessage instanceof Object && channelMessage["functionCall_name"] == "Remote_SnoozeFail") {
			// todo: maybe make the below generic, and have it be used for *all* document-writes, that go through this bridge (though maybe only have this http-api used when on Android)
			// for the remote-snooze command, use http api, since it works reliably even when screen is off
			const fetchOptions: RequestInit = {
				method: "POST",
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {},
				body: JSON.stringify({
					fields: {
						fromProcessID: {integerValue: processMessage.fromProcessID},
						sendTime: {integerValue: processMessage.sendTime},
						message: {
							mapValue: {
								fields: {
									functionCall_callID: {doubleValue: channelMessage["functionCall_callID"]},
									functionCall_name: {stringValue: channelMessage["functionCall_name"]},
									functionCall_args: {arrayValue: {values: []}}, // no need to add args, since we know is empty for Remote_SnoozeFail
								},
							},
						},
					},
				}),
			};
			const collectionPath = DBPath(`userExtras/${MeID()}/processMessages`);
			const apiKey = DB == "dev" ? "AIzaSyCY7FcGPiLgtOMm2EIhf40Vly_eowOQrgE" : "AIzaSyBnAXJ8pM-zwC_lwAjOcS8sT1Oo1Djtu64"; // from Firebase-console settings page
			fetch(`https://firestore.googleapis.com/v1beta1/projects/lucid-frontier-${DB}/databases/(default)/documents/${collectionPath}?documentId=${documentID}&key=${apiKey}`, fetchOptions);
		} else {*/
		//const collectionRef = collection(getFirestore(), DBPath({}, `userExtras/${MeID()}/processMessages`));
		const collectionRef = collection(getFirestore(), DBPath({}, `userProcessMessages`));
		setDoc(doc(collectionRef, documentID), processMessage_stripped);
	},
	channel_wrapBridgeMessage: false,
	//channel_stringifyChannelMessageObj: false, // commented for now, so that RobustFirebaseSet only has to submit a (much simpler) string channelMessage to the http-api
	channel_safeCallbacks: true,
	requireMainFuncForCalls: false, // this bridge may have >2 instances connected at once, so just ignore calls to functions that are missing (some are only attached when eg. session is started)
});
JustBeforeUI_listeners.push(()=>{
	const waitTimer = new Timer(1000, ()=>{
		if (MeID() == null) return;
		waitTimer.Stop();
		remoteUserProcessesBridge.SetUpReceiver();
	}).Start();
});