import {CachedTransform} from "js-vextensions";
import {JournalEntry} from "../../Store/firebase/journalEntries/@JournalEntry";
import {StoreAccessor, GetDoc, GetDocs, WhereOp, QueryOp} from "mobx-firelink";
import {FBAConfig} from "./fbaConfigs/@FBAConfig";

export const GetFBAConfig = StoreAccessor(s=>(id: string): FBAConfig|n=>{
	return GetDoc({}, a=>a.fbaConfigs.get(id));
});
export const GetFBAConfigs = StoreAccessor(s=>(userID?: string): FBAConfig[]=>{
	return GetDocs({
		queryOps: [userID && new WhereOp("creator", "==", userID)].filter(a=>a) as QueryOp[],
	}, a=>a.fbaConfigs);
});

export const GetSelectedFBAConfigID = StoreAccessor(s=>()=>{
	return s.main.tools.engine.selectedConfig;
});
export const GetSelectedFBAConfig = StoreAccessor(s=>()=>{
	const selectedID = GetSelectedFBAConfigID();
	return GetFBAConfig(selectedID);
});