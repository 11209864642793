import {O} from "web-vcore";
import {string} from "js-vextensions";
import {makeObservable, observable} from "mobx";
import {ignore} from "mobx-sync";
import {RecordingSession} from "../../../Store/firebase/recordingSessions/@RecordingSession";

export enum TrainingTab {
	Recording = 10,
	Sessions = 20,
	Training = 30,
	Models = 40,
}

// workaround for `"X" as "Y"` not causing TS error (probably temp -- till fixed)
/*function Check<T>(val: T) {
	return val;
}*/

export const SessionPropTypes = {
	// default props - required
	//{key: "eyeMoveType", name: "Eye-move type", values: EyeMoveType_values, defaultValue: Check<EyeMoveType>("None")},
	eyeMoveType: {name: "Eye-move type", values: ["None", "Micro", "Macro"] as const, required: true},
	eyeOpenState: {name: "Eye-open state", values: ["Closed", "Open"] as const, required: true},
	bodyPosition: {name: "Body position", values: ["OnBack", "OnLeft", "OnRight", "OnFront", "Sitting", "Standing"] as const, required: true},
	eegPlacementCare: {name: "EEG placement care", values: ["Careful", "Careless"] as const, required: true},
	fanState: {name: "Fan state", values: ["Off", "OnAway", "OnToward"] as const, required: true},
	// default props - optional
	eegSignalQuality_left: {name: "EEG signal (left)", values: ["Good", "Bad", "Ignore"] as const},
	eegSignalQuality_right: {name: "EEG signal (right)", values: ["Good", "Bad", "Ignore"] as const},
};
export type EyeMoveType = typeof SessionPropTypes.eyeMoveType.values[number];
export type EyeOpenState = typeof SessionPropTypes.eyeOpenState.values[number];
export type BodyPosition = typeof SessionPropTypes.bodyPosition.values[number];
export type EEGPlacementCare = typeof SessionPropTypes.eegPlacementCare.values[number];
export type FanState = typeof SessionPropTypes.fanState.values[number];
export type EEGSignalQuality = typeof SessionPropTypes.eegSignalQuality_left.values[number];

export class SessionProps {
	// default props - required
	eyeMoveType: EyeMoveType = "None";
	eyeOpenState: EyeOpenState = "Closed";
	bodyPosition: BodyPosition = "OnBack";
	eegPlacementCare: EEGPlacementCare = "Careful";
	fanState: FanState = "Off";

	// default props - optional
	eegSignalQuality_left: EEGSignalQuality = "Good";
	eegSignalQuality_right: EEGSignalQuality = "Good";

	// custom props
	[key: string]: string;
}
//export const SessionProps_defaultProps = Object.keys(new SessionProps());

export class RecordingSessionInfo {
	webCopy?: boolean;
	localCopy?: boolean;
	//eyeMoveType: EyeMoveType;
	createdAt_toSecond: number;
	sampleCount: number;

	// if has web-copy
	session?: RecordingSession;

	// if has local-copy
	fileName?: string;
}

export class TrainingState {
	constructor() { makeObservable(this); }
	
	@O tab = TrainingTab.Recording;

	// recording
	// ==========

	@O @ignore recordWithVolumeKeys = false;
	@O @ignore recording = false;
	@O @ignore sampleCount = 0;
	@O @ignore draftRecordingSessions = [] as RecordingSession[];
	@O @ignore uploadedRecordingSessions = new Set();
	@O @ignore showGraphForSession: RecordingSession|n;

	@O sessionProps = new SessionProps().Strip() as SessionProps;
	@O sessionProps_disabled = ["eegSignalQuality_left", "eegSignalQuality_right"] as string[];

	// training
	// ==========

	@O averageLeftAndRightAsSingleChannel = true;

	@O sessionStorageFolder = "./Training";
	//@O @ignore downloadedSessionDateStrings = new Set();
	@O @ignore localSessionInfos = [] as RecordingSessionInfo[];
}