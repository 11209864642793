import {UserEdit} from "../../Server/CommandMacros";
import {AssertValidate, GenerateUUID, Command, AssertV} from "mobx-firelink";
import {Entity} from "../../Store/firebase/entities/@Entity";
import {TestSegment} from "../../Store/firebase/testSegments/@TestSegment";

export class AddTestSegment extends Command<{entry: TestSegment}, string> {
	id: string;
	Validate() {
		const {entry} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		entry.creator = this.userInfo!.id;
		entry.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("TestSegment", entry, `TestSegment invalid`);
	}

	GetDBUpdates() {
		const {entry} = this.payload;

		const updates = {};
		updates[`testSegments/${this.id}`] = entry;
		return updates;
	}
}