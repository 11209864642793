import {SleepAsync} from "js-vextensions";
import { CallExtensionFunction } from "./ExtensionLink";

/*async function GetAlbums(token, retriesLeft = 2) {
	let hit401 = false;
	let response = await $.ajax({
		url: "https://picasaweb.google.com/data/feed/api/user/default?alt=json",
		type: "GET",
		processData: false,
		dataType: "json",
		contentType: false,
		cache: false,
		beforeSend: function (xhr) {
			xhr.setRequestHeader("Authorization", "Bearer " + token);
		},
		error: function(jqXHR) {
			if (jqXHR.status === 401) hit401 = true;
		}
	});
	if (hit401 && retriesLeft > 0) {
		await chrome.identity.removeCachedAuthToken({token});
		return GetAlbums(token, retriesLeft - 1);
	}

	let albums = response.feed.entry;
	for (let album of albums) {
		album.name = album.title.$t;
		album.id = album.gphoto$id.$t;
	}
	return albums;
}*/
export async function PostImageToGooglePlus(imageDataURI: string, message) {
	/*let token = "test"; //await chrome.identity.getAuthToken({interactive: true});
	let albums = await GetAlbums(token);
	let targetAlbum = albums.find(a=>a.name == "3000 Weeks");
	alert("Found album:" + JSON.stringify(targetAlbum));*/

	CallExtensionFunction("PostImageAndMessageToGooglePlus", imageDataURI, message);

	// for now, just wait some seconds to make sure google-plus is done with post (needed for fb on-blur even to not misfire)
	await SleepAsync(10000);

	// todo: have these functions actually wait for call back from extension saying that process is complete
}