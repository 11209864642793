import {AddSchema, NewSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";

export class TestSegment {
	constructor(initialData?: Partial<TestSegment>) { this.VSet(initialData); }

	_key: string;
	creator: string;
	createdAt: number;
	sourceSession: string;
	bufferStartTime: number;
	startTime: number;
	endTime: number;
	enabled: boolean;

	// channel's "baseline" value is the baseline calculated for the first buffer-sample
	channelBaselines: {
		eeg_left: number;
		eeg_right: number;
	};
	// first sample's time will be the latest sample whose time is <= bufferStartTime
	channelSamples: {
		eeg_left: number[];
		eeg_right: number[];
	};
	eyeMoveDeclarations: EyeMoveDeclaration[];
}
AddSchema("TestSegment", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},
		sourceSession: {type: "string"},
		bufferStartTime: {type: "number"},
		startTime: {type: "number"},
		endTime: {type: "number"},
		enabled: {type: "boolean"},

		channelBaselines: NewSchema({
			properties: {
				eeg_left: {type: "number"},
				eeg_right: {type: "number"},
			},
		}),
		channelSamples: NewSchema({
			properties: {
				eeg_left: {items: {type: "number"}},
				eeg_right: {items: {type: "number"}},
			},
		}),
		eyeMoveDeclarations: {items: {$ref: "EyeMoveDeclaration"}},
	},
});

export type SamplesBySecond = {
	// second time (in ms) -> sample-value array
	[key: number]: number[];
};
AddSchema("SamplesBySecond", {patternProperties: {
	"\\d+": {items: {type: "number"}},
}});

export enum EyeMoveDeclarationType {
	Required = 10,
	Optional = 20,
	//No = 30,
}
AddSchema("EyeMoveDeclarationType", {oneOf: GetValues_ForSchema(EyeMoveDeclarationType)});
export class EyeMoveDeclaration {
	constructor(data?: Partial<EyeMoveDeclaration>) { this.VSet(data); }
	type: EyeMoveDeclarationType;
	time: number; // in ms
}
AddSchema("EyeMoveDeclaration", {
	properties: {
		type: {$ref: "EyeMoveDeclarationType"},
		time: {type: "number"},
	},
});