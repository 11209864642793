import {BaseComponentPlus, SimpleShouldUpdate} from "react-vextensions";
import {Row, Button} from "react-vcomponents";
import {Observer} from "web-vcore";
import useResizeObserver from "use-resize-observer";
import {store} from "../../../Store/index.js";
import {useEffect, useRef} from "react";
import {Timer, SleepAsync, E} from "js-vextensions";
import {runInAction} from "mobx";
import {SetScreenBrightness, SetFullScreen} from "../../../Utils/Bridge/Bridge_Preload";
import {zIndexes} from "../../../Utils/UI/ZIndexes";
import {ES} from "../../../Utils/UI/GlobalStyles";
import keycode from "keycode";
import {ClearScreenLight} from "../../../Store/main/effects/lights";
import {liveFBASession} from "../../../Engine/FBASession.js";

@Observer
export class ScreenLightOverlay extends BaseComponentPlus({}, {}) {
	//canvas: HTMLCanvasElement;
	//ctx: CanvasRenderingContext2D;
	/*canvasRef = (c: HTMLCanvasElement)=>{
		this.canvas = c;
		//this.ctx = c ? c.getContext("2d") : null;
		console.log("Test1");
		/*if (c) {
			//this.forceUpdate();
			this.lastFillFunc?.();
			console.log("Filling...", this.lastFillFunc);
		}*#/
	};*/
	lastFillFunc: ()=>void;
	render() {
		let {} = this.props;
		const uiState = store.main.effects.lights;
		const {callbackRef: rootRef, width = null, height = null} = useResizeObserver<HTMLDivElement>();
		const player = uiState.screenLight_currentPlayer!;
		const promptInfo = uiState.screenLight_currentPromptInfo;
		//console.log("Size:", width, height);

		const canvasRef = useRef<HTMLCanvasElement>();

		// add escape-key hotkey (to close overlay)
		useEffect(()=>{
			const listener = (e: KeyboardEvent)=>{
				if (e.which == keycode.codes.esc) {
					//this.StopPlayer();
					ClearScreenLight();
				}
			};
			document.addEventListener("keydown", listener);
			return ()=>document.removeEventListener("keydown", listener);
		}, []);

		useEffect(()=>{
			if (promptInfo == null) return;

			let frameIndex = -1;
			const useRAF = promptInfo.frameInterval > 1 / 61 && promptInfo.frameInterval < 1 / 59;
			let frameTimer: Timer;
			let raf_handle: number;
			const stopFrameTimer = ()=>{
				if (frameTimer) {
					frameTimer.Stop();
				} else {
					cancelAnimationFrame(raf_handle);
				}
			};

			const frameTimer_tick = ()=>{
				//if (this.canvas != null) {
				//if (this.ctx == null) this.ctx = this.canvas.getContext("2d");
				frameIndex++;
				let frame = promptInfo.frames[frameIndex];
				if (frame == null) {
					if (promptInfo.framesLoop && promptInfo.frames.length) {
						frameIndex = 0;
						frame = promptInfo.frames[frameIndex];
					} else {
						stopFrameTimer();
						return;
					}
				}

				this.lastFillFunc = ()=>{
					//console.log("Filling:", frame.color, canvasRef.current.width, canvasRef.current.height);
					const canvas = canvasRef.current;
					const ctx = canvas?.getContext("2d");
					if (canvas == null || ctx == null) return;
					ctx.fillStyle = frame.color;
					//ctx.fillRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
					ctx.fillRect(0, 0, canvas.width, canvas.height);
				};
				this.lastFillFunc();

				if (useRAF) raf_handle = requestAnimationFrame(frameTimer_tick);
			};

			// if user specified 60fps, use requestAnimationFrame for better consistency
			if (useRAF) {
				//frameTimer_tick(); // start by calling immediately
				raf_handle = requestAnimationFrame(frameTimer_tick);
			} else {
				//frameTimer_tick(); // call once immediately
				requestAnimationFrame(frameTimer_tick); // call once (basically) immediately
				frameTimer = new Timer(promptInfo.frameInterval * 1000, frameTimer_tick).Start();
			}

			if (player.light?.temp) {
				var endTimer = new Timer(player.light.tempLength * 1000, ()=>{
					stopFrameTimer();
					//this.StopPlayer();
					// if session active, don't clear screen-light; just stop current player (reset handled by LightPromptComp)
					if (liveFBASession != null) {
						player.Stop();
					} else {
						ClearScreenLight();
					}
				}, 1).Start();
			}
			return ()=>{
				stopFrameTimer();
				if (endTimer && endTimer.callCount_total == 0) {
					endTimer.Stop();
					endTimer.func();
				}
			};
		}, [player, promptInfo]);
		useEffect(()=>{
			// if resize possibly just occurred, and overlay is open, recall fill-func (canvas resize clears contents)
			if (promptInfo) {
				this.lastFillFunc?.();
			}
		});

		const promptInfoHasFrames = promptInfo != null && promptInfo.frames.length > 0;
		SetFullScreen(promptInfoHasFrames); // only make full-screen, if effect provides frames

		//if (promptInfo == null) return null;
		return (
			<div style={ES(
				{position: "fixed", left: 0, top: 0, right: 0, bottom: 0, zIndex: zIndexes.screenLight},
				//promptInfo == null && {display: "none"}, // better than "return null", since doesn't cause extra resize-observer triggerings
				!promptInfoHasFrames && {pointerEvents: "none", opacity: 0}, // better than "return null", since doesn't cause extra resize-observer triggerings
			)} ref={c=>{
				if (c) {
					// requestFullscreen errors fsr :/
					/*(async()=>{
						try {
							//await SleepAsync(100);
							await c.requestFullscreen();
						} catch {
							// ignore; it can "fail", while still seemingly working
						}
					})();*/
					//SetFullScreen(true);
				}
			}}>
				<div ref={rootRef} style={{position: "relative", width: "100%", height: "100%"}}>
					{width != null && height != null &&
						<canvas ref={canvasRef as any} width={width} height={height}/>}
					<Button text="X" style={{position: "absolute", top: 10, right: 10, width: 50, height: 50}} onClick={()=>{
						//this.StopPlayer();
						ClearScreenLight();
					}}/>
				</div>
			</div>
		);
	}
}