import {AssertV, AssertValidate, Command, GenerateUUID} from "mobx-firelink";
import {UserEdit} from "../../Server/CommandMacros";
import {Shake} from "../../Store/firebase/shakes/@Shake";

@UserEdit
export class AddShake extends Command<{shake: Shake}, string> {
	id: string;
	Validate() {
		const {shake} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		shake.creator = this.userInfo!.id;
		shake.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("Shake", shake, `Shake invalid`);
	}

	GetDBUpdates() {
		const {shake} = this.payload;

		const updates = {};
		updates[`shakes/${this.id}`] = shake;
		return updates;
	}
}