import {Clone, GetEntries, GetErrorMessagesUnderElement, CloneWithPrototypes, E} from "js-vextensions";
import Moment from "moment";
import {Column, Pre, RowLR, Select, Spinner, TextInput, Text, Row, Button} from "react-vcomponents";
import {BaseComponent, BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {Sound, SoundType} from "../../../Store/firebase/sounds/@Sound";
import {InfoButton, YoutubeSpeed, YoutubeQuality, GetVoices, Observer, observer_simple, RunInAction} from "web-vcore";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {User} from "../../../Store/firebase/users/@User";
import {IDAndCreationInfoUI} from "../../@Shared/CommonPropUIs/IDAndCreationInfoUI";
import {BoxController, ShowMessageBox} from "react-vmessagebox";
import {AddSound} from "../../../Server/Commands/AddSound";
import {AddEntity} from "../../../Server/Commands/AddEntity";
import {Entity, EntryVisibility, SimpleVisibility_AsVisibleToGroup, VisibleToGroups_AsSimpleVisibility} from "../../../Store/firebase/entities/@Entity";
import {TagsInput} from "../../../Utils/ReactComponents/TagsInput";
import {GetUser, MeID} from "../../../Store/firebase/users";
import {PreviewImageSquare} from "../../@Shared/PreviewImageSquare.js";
import {DialogStyle} from "../../../Utils/UI/GlobalStyles.js";
import {store} from "../../../Store/index.js";
import {useEffect} from "react";

export function ShowAddEntityDialog(initialData?: Partial<Entity>, postAdd?: (data: Entity, id: string)=>any) {
	let newEntry = new Entity(E({
		name: "",
		//visibleToGroups: [MeID(), "public"],
		visibleToGroups: SimpleVisibility_AsVisibleToGroup(store.main.content.lastEntityVisibility, MeID()!),
	}, initialData));
	const getCommand = ()=>new AddEntity({entity: newEntry});

	const boxController: BoxController = ShowMessageBox({
		title: "Add entity", cancelButton: true,
		message: observer_simple(()=>{
			const tempCommand = getCommand();
			boxController.UpdateOptions({okButtonProps: {
				enabled: tempCommand.Validate_Safe() == null,
				title: tempCommand.validateError,
			}});
			return (
				<Column style={DialogStyle({width: 600})}>
					<EntityDetailsUI baseData={newEntry} forNew={true}
						onChange={(val, error)=>{
							newEntry = val;
							boxController.UpdateUI();
						}}/>
				</Column>
			);
		}),
		onOK: async()=>{
			RunInAction("ShowAddEntityDialog.onOK", ()=>{
				store.main.content.lastEntityVisibility = VisibleToGroups_AsSimpleVisibility(newEntry.visibleToGroups);
			});
			const id = await getCommand().Run();
			if (postAdd) postAdd(newEntry, id);
		},
	});
}

const splitAt = 120, width = "100%";

@Observer
export class EntityDetailsUI extends BaseComponentPlus(
	{enabled: true} as {baseData: Entity, forNew: boolean, enabled?: boolean, style?, onChange?: (newData: Entity, ui: EntityDetailsUI)=>void},
	{newData: null as any as Entity, previewing: false},
) {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.baseData != this.props.baseData) { // if base-data changed
			this.SetState({newData: CloneWithPrototypes(props.baseData)});
		}
	}

	render() {
		const {baseData, forNew, enabled, style, onChange} = this.props;
		const {newData, previewing} = this.state;
		const Change = (..._)=>{
			if (onChange) onChange(this.GetNewData(), this);
			this.Update();
		};
		const creator = !forNew && GetUser(newData.creator);

		useEffect(()=>{
			const listener = (e: ClipboardEvent)=>{
				var items = (e.clipboardData || e["originalEvent"]?.clipboardData).items;
				//console.log(JSON.stringify(items)); // might give you mime types
				for (const item of items) {
					if (item.kind == "file") {
						const blob = item.getAsFile();
						const reader = new FileReader();
						reader.onload = event=>{
							const imageDataStr = event.target!.result as string;
							//console.log(imageDataStr); // data url!
							Change(newData.image_embedded = imageDataStr);
						};
						reader.readAsDataURL(blob);
					}
				}
			};
			document.addEventListener("paste", listener);
			return ()=>document.removeEventListener("paste", listener);
		});

		const propsEnhanced = {...this.props, Change, ...this.state, SetState: this.SetState};
		return (
			<Column style={style}>
				{!forNew &&
					<IDAndCreationInfoUI id={baseData._key} creatorID={newData.creator} createdAt={newData.createdAt}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Name:</Text>
					<TextInput pattern={name_general} required
						enabled={enabled} style={{width: "100%"}}
						value={newData.name} onChange={val=>Change(newData.name = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Visibility:</Text>
					<Select options={GetEntries(EntryVisibility)} enabled={enabled}
						value={VisibleToGroups_AsSimpleVisibility(newData.visibleToGroups)}
						onChange={val=>Change(newData.visibleToGroups = SimpleVisibility_AsVisibleToGroup(val, MeID()!))}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Suggested tags:</Text>
					<TagsInput enabled={enabled} style={{width: "100%"}} value={newData.tags} onChange={val=>Change(newData.tags = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>URL:</Text>
					<TextInput
						enabled={enabled} style={{width: "100%"}}
						value={newData.url} onChange={val=>Change(newData.url = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Image (embedded):</Text>
					<TextInput
						enabled={enabled} style={{width: "100%"}}
						value={newData.image_embedded} onChange={val=>Change(newData.image_embedded = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Square:</Text>
					<Row style={{flexWrap: "wrap"}}>
						<Row>
							<Text>Center (x, y):</Text>
							<Spinner ml={5} enabled={enabled} min={0} max={100}
								value={(newData.square_center.x * 100).RoundTo(1)} onChange={val=>Change(newData.square_center.x = val / 100)}/>
							<Spinner enabled={enabled} min={0} max={100}
								value={(newData.square_center.y * 100).RoundTo(1)} onChange={val=>Change(newData.square_center.y = val / 100)}/>
						</Row>
						<Row>
							<Text>Size:</Text>
							<Spinner ml={5} enabled={enabled} min={1} max={100}
								value={(newData.square_size * 100).RoundTo(1)} onChange={val=>Change(newData.square_size = val / 100)}/>
						</Row>
					</Row>
				</RowLR>
				<Button text={previewing ? "Hide preview" : "Preview entity"} mt={5} width={200} onClick={()=>{
					this.SetState({previewing: !previewing});
				}}/>
				{previewing &&
				<div style={{position: "relative"}}>
					<PreviewImageSquare entry={newData} style={{width: "100%", paddingBottom: "100%"}}/>
				</div>}
			</Column>
		);
	}
	GetValidationError() {
		return GetErrorMessagesUnderElement(this.DOM)[0];
	}

	GetNewData() {
		const {newData} = this.state;
		return CloneWithPrototypes(newData) as Entity;
	}
}