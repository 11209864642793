import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export class FBAConfig_JourneyGrid {
	constructor(initialData: Partial<FBAConfig_JourneyGrid>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	listenStart_triggerSet: TriggerSet;
	listenEnd_triggerSet: TriggerSet;

	// listening
	entityTags_regular: string[];
	entityTags_target: string[];
	entityTags_success: string[];

	voiceSoundTag: string;
	volumeMultiplier: number;
	speakInterval: number;

	targetDelay_minTime: number;
	targetDelay_maxTime: number;
	userTargetDetection_maxDelay: number;
}
AddSchema("FBAConfig_JourneyGrid", {
	properties: {
		enabled: {type: "boolean"},
		listenStart_triggerSet: {$ref: "TriggerSet"},
		listenEnd_triggerSet: {$ref: "TriggerSet"},

		entityTags_regular: {items: {type: "string"}},
		entityTags_target: {items: {type: "string"}},
		entityTags_success: {items: {type: "string"}},

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},
		speakInterval: {type: "number"},

		targetDelay_minTime: {type: "number"},
		targetDelay_maxTime: {type: "number"},
		userTargetDetection_maxDelay: {type: "number"},
	},
});