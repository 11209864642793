import {AddTestSegment} from "../../../../../Server/Commands/AddTestSegment.js";
import {store} from "../../../../../Store/index.js";
import {EngineSessionInfo} from "../../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {GetTestSegments} from "../../../../../Store/firebase/testSegments.js";
import {EyeMoveDeclarationType, TestSegment} from "../../../../../Store/firebase/testSegments/@TestSegment.js";
import {MeID} from "../../../../../Store/firebase/users.js";
import {GetSelectedSessionData, GetSelectedSessionData_AllowingResim, SetCurrentTime} from "../../../../../Store/main/timeline.js";
import {ShowSignInPopup} from "../../../../@Shared/NavBar/UserPanel.js";
import {SessionDataProcessor} from "../../../../@Shared/Processors/SessionDataProcessor.js";
import {EEGSample, Muse_eegSamplesPerSecond_raw} from "../../../../Tools/@Shared/MuseInterface/EEGStructs.js";
import useResizeObserver from "use-resize-observer";
import {InAndroid} from "../../../../../Utils/Bridge/Bridge_Native.js";
import {moment_local, Moment_ToDate_WithTimeZonePreApplied} from "../../../../../Utils/General/General.js";
import {ES, styles} from "../../../../../Utils/UI/GlobalStyles.js";
import {createRef_withHooks} from "../../../../../Utils/UI/ReactHelpers.js";
import {uplotDefaults} from "../../../../../Utils/UI/UPlotDefaults.js";
import {RunInAction} from "web-vcore";
import {Assert, GetPropChanges, IsNaN, Vector2} from "js-vextensions";
import moment from "moment";
import {UPlot} from "react-uplot";
import {BaseComponentPlus, SimpleShouldUpdate} from "react-vextensions";
import {ShowVMenu, VMenuItem} from "react-vmenu";
import {ShowMessageBox} from "react-vmessagebox";
import {useEffect, useMemo} from "react";
import {Annotation, AnnotationsPlugin, PanAndZoomPlugin} from "uplot-vplugins";
import uPlot from "uplot";
import {currentSeekChart} from "../SeekBar.js";
import {GetPromptThresholdColor, sessionChartColors} from "../SessionChartColors.js";

export const EEGChart_percentOfSamplesGraphed = InAndroid(0) ? .1 : 1;
export const EEGChart_graphedSamplesPerSecond = Muse_eegSamplesPerSecond_raw * EEGChart_percentOfSamplesGraphed;

export const EEGChart_lineTypes: (uPlot.Series & {key: string})[] = [
	{
		value: (self, rawValue)=>{
			const timeInMS = rawValue * 1000;
			const time = moment_local(timeInMS, self.session?.localOffsetFromUTC); // session set in EEGChart.chart hook's postSet handler
			//return `${time.format("YYYY-MM-DD HH:mm:ss")}.${(timeInMS % 1000).FloorTo(1).toString().padStart(3, "0")}`;
			return `${time.format("HH:mm:ss")}.${(timeInMS % 1000).FloorTo(1).toString().padStart(3, "0")}`; // we don't need date, it's known from overall session
		},
	} as any,
	{
		label: "Left",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_left,
	},
	{
		label: "Right",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_right,
	},
	{
		label: "EEG activity",
		value: (self, rawValue)=>{
			if (rawValue == null) {
				// todo: interpolate
				return null;
			}
			return rawValue;
		},
		stroke: sessionChartColors.eegActivity,
		width: 1.5,

		spanGaps: true,

		scale: "z", // makes align to eeg-activity scale (right side)
		auto: false,
		//paths: paths_stepped,
	},
	{
		label: "Gyro (CD)",
		value: (self, rawValue)=>{
			if (rawValue == null) {
				// todo: interpolate
				return null;
			}
			return rawValue.toFixed(2);
		},
		stroke: sessionChartColors.gyro_combinedDeviation,

		spanGaps: true,
	},
	/*InAndroid(0) && {key: "dist_moveNone", name: "Dist_MoveNone", color: HSL(0, 1, .5)},
	InAndroid(0) && {key: "dist_moveMicro", name: "Dist_MoveMicro", color: HSL(120, 1, .5)},
	InAndroid(0) && {key: "dist_moveMacro", name: "Dist_MoveMacro", color: HSL(240, 1, .5)},*/
];

type ExtInfoForRedraw = ReturnType<typeof DetailChart.prototype.GetExtInfoForRedraw>;

@SimpleShouldUpdate
export class DetailChart extends BaseComponentPlus({overlay: false} as {
	processor: SessionDataProcessor,
	overlay?: boolean, session: EngineSessionInfo,
	initialViewRange: number,
	maxViewRange: number,
	//startTime: number, endTime: number,
	//startSample?: number, endSample?: number, processedExtent?: number, // commented; retrieve these values ourselves, so we can just update what we need
}, {}) {
	GetExtInfoForRedraw() {
		const {processor} = this.props;
		const uiState = store.main.timeline.sessions;
		const sessionData = GetSelectedSessionData_AllowingResim()!;
		const testSegments = GetTestSegments(store.main.testing.selectedUser, sessionData.sessionID);
		//const sessionProcessor = GetSessionProcessor_Active();
		return {
			//processor,
			currentTime: uiState.currentTime,
			viewRange_startTime: uiState.viewRange_startTime,
			viewRange_endTime: uiState.viewRange_endTime,
			processingExtent: processor.rawProcessing_nextOffsetFromCenter,
			simulationExtent: processor.simulation_nextOffsetFromStart,
			sessionData,
			testSegments,
		};
	}
	lastUpdate_infoForRedraw: ExtInfoForRedraw;

	annotations = [] as Annotation[];
	// todo: clean up how annotations are stored/created/updated
	UpdateAnnotations() {
		const uiState = store.main.timeline.sessions;
		const sessionData = GetSelectedSessionData_AllowingResim()!;
		const testSegments = GetTestSegments(store.main.testing.selectedUser, sessionData.sessionID);

		this.annotations.Clear();
		this.annotations.push({
			/*strokeStyle: "rgba(255,255,255,.7)",
			lineWidth: 1,*/
			type: "line",
			//x: {type: "valueOnAxis", value: uiState.currentTime / 1000, finalize: "round"},
			x: {value: uiState.currentTime / 1000},
			//color: `rgba(255,255,255,.7)`,
			color: sessionChartColors.currentTimeLine,
			lineWidth: 1,
			drawType: "destination-over",
		});

		const enabledPrompts = sessionData.config.eeg.alarmSequence.alarms.filter(a=>a.enabled);
		this.annotations.AddRange(enabledPrompts.map((prompt, index)=>{
			/*return {
				type: "line",
				//y: prompt.startPoint,
				z: prompt.startPoint,
				/*strokeStyle: promptColors[index],
				lineWidth: 1,*#/
				color: promptColors[index],
				lineWidth: 1,
			} as const;*/
			return {
				type: "box",
				fillStyle: GetPromptThresholdColor(index, enabledPrompts.length),
				xMin: {pixel: "0%"},
				xMax: {pixel: "100%"},
				yMin: {value_axis: "z", value: prompt.startOffset},
				ySize: {pixel: 1},
				drawType: "destination-over",
			} as const;
		}));

		for (const timeStr of sessionData!.generalHistory.gyroMotion.motionsByTime.VKeys()) {
			//const time = ToInt(timeStr);
			const time = Number(timeStr);
			this.annotations.push({
				type: "box",
				// rendering optimization (significant if user has gyro motion-detection very sensitive, eg. to pick up breathing) [reduced paint-time from ~75ms to ~40ms, in 2020-11-23 test session]
				shouldRender: ({chart})=>{
					const time_xVal = time / 1000;
					return time_xVal >= chart.scales.x.min! && time_xVal <= chart.scales.x.max!;
				},

				xMin: {value: time / 1000, finalize: a=>Math.floor(a)},
				//xMax: time / 1000,
				xSize: {pixel: 1},
				//yMax: 3,
				//yMin: {type: "valueOnAxis", axisKey: "z", value: 0},
				yMin: {pixel: "50%"},
				//ySize: {type: "pixelOnCanvas", value: 3},
				ySize: {pixel: "100%"},
				fillStyle: sessionChartColors.gyroMotionColor,
				//drawType: "destination-over", // commented; should show over eeg-activity line, else can be hidden
			});
		}

		for (const segment of testSegments) {
			this.annotations.push({
				type: "box",
				xMin: {value: segment.startTime / 1000},
				xMax: {value: segment.endTime / 1000},
				yMin: {pixel: "0%"},
				yMax: {pixel: "50%"},
				//fillStyle: HSLA(60, 1, .5, .1),
				//fillStyle: HSLA(60, .5, .5, .1),
				fillStyle: sessionChartColors.testSegmentRegion,
				drawType: "destination-over",
			});

			this.annotations.AddRange(segment.eyeMoveDeclarations.map(declaration=>{
				return {
					/*type: "line",
					x: {value: declaration.time / 1000},
					color: declaration.type == EyeMoveDeclarationType.Required ? sessionChartColors.eyeMoveDeclaration_required : sessionChartColors.eyeMoveDeclaration_optional,
					lineWidth: 1,*/
					type: "box",
					xMin: {value: declaration.time / 1000, finalize: a=>Math.floor(a)},
					xSize: {pixel: 1},
					yMin: {pixel: "0%"},
					yMax: {pixel: "50%"},
					fillStyle: declaration.type == EyeMoveDeclarationType.Required ? sessionChartColors.eyeMoveDeclaration_required : sessionChartColors.eyeMoveDeclaration_optional,
					drawType: "destination-over",
				} as const;
			}));
		}
	}
	UpdateLineVisibilities(chartOpts?: uPlot.Options) {
		const chart = this.chart.current;
		const series = chartOpts?.series ?? chart?.series;

		// load stored visibility/enabledness for lines
		const uiState = store.main.timeline.sessions;
		for (let i = 0; i < series.length; i++) {
			const newShow = !uiState.disabledSeries.includes(i);
			if (chartOpts) {
				chartOpts.series[i].show = newShow;
			} else {
				if (chart && chart.series[i].show != newShow) {
					chart.setSeries(i, {show: newShow});
				}
			}
		}
	}

	render() {
		const {processor, overlay, session, initialViewRange, maxViewRange} = this.props;
		let {ref: rootRef, width = 100, height = 100} = useResizeObserver();
		//let {ref: rootRef, width = 100, height = 100} = {ref: null, width: 300, height: 300}; // test
		if (width < 300) width = 300; // temp fix for crash issue (see: https://github.com/leeoniya/uPlot/issues/360)
		//const uiState = store.main.timeline.sessions;

		const uiState = store.main.timeline.sessions;
		const sessionData = GetSelectedSessionData_AllowingResim();
		const maxEEGActivity = sessionData!.generalHistory.eegActivity.activityByTime.VValues().concat(1).Max();
		//const maxEEGActivity = sessionData.config.eeg.motion_maxActivity;

		const chartOptions: uPlot.Options = useMemo(()=>{
			//console.log("chartOptions.setViewRange:", {min: uiState.viewRange_startTime / 1000, max: uiState.viewRange_endTime / 1000});
			return {
				id: `chart_detailPanel`,
				class: "eeg-chart",
				width,
				height,
				plugins: [
					overlay && PanAndZoomPlugin({
						//zoomFactor_x: 0.75,
						zoomFactor_y: 0,
						clamp: true,
						xMin: processor.eegProcessor.minTime_floored / 1000, xMax: processor.eegProcessor.maxTime_ceilinged / 1000,
						xRangeMax: maxViewRange / 1000,
						yMin: -100, yMax: 100,
					}),
					// update view-range based on pan/zoom
					(()=>{
						return {
							hooks: {
								setScale: (self, scaleKey)=>{
									// update view-range based on pan/zoom (perhaps throttle this)
									if (scaleKey == "x" && overlay) {
										const currentXScale = self.scales.x.IncludeKeys("min", "max");
										RunInAction("EEGChart_updateViewRange", ()=>{
											/*uiState.viewRange_startTime = KeepTimeInSessionRange(currentXScale.min * 1000, session);
											uiState.viewRange_endTime = KeepTimeInSessionRange(currentXScale.max * 1000, session);*/
											uiState.viewRange_startTime = currentXScale.min! * 1000;
											uiState.viewRange_endTime = currentXScale.max! * 1000;
										});
										// optimization, so that "info changed" checker below does not detect our just-stored data as differing from previous (and thus requiring a chart-update)
										this.lastUpdate_infoForRedraw?.VSet(this.GetExtInfoForRedraw().IncludeKeys("viewRange_startTime", "viewRange_endTime"));
									}
								},
							},
						} as uPlot.Plugin;
					})(),
					// update visibility/enabledness of series/lines
					(()=>{
						return {
							hooks: {
								setSeries: (chart, index: number, opts)=>{
									RunInAction("EEGChart_LineVisibilityChanged", ()=>{
										if (opts.show) {
											uiState.disabledSeries.Remove(index);
										} else {
											if (!uiState.disabledSeries.includes(index)) {
												uiState.disabledSeries.push(index);
												uiState.disabledSeries.sort(); // keep sorted (not necessary, but nice)
											}
										}
									});
								},
							},
						} as uPlot.Plugin;
					})(),
					// draw current-time
					overlay && AnnotationsPlugin({
						annotations: this.annotations,
					}),
				].filter(a=>a) as uPlot.Plugin[],
				cursor: {
					drag: {x: false, setScale: false},
				},
				tzDate: rawValue=>{
					const timeInMS = rawValue * 1000;
					const time_creatorLocal = moment_local(timeInMS, session?.localOffsetFromUTC); // session set in EEGChart.chart hook's postSet handler
					return Moment_ToDate_WithTimeZonePreApplied(time_creatorLocal);
				},
				axes: [
					{
						...uplotDefaults.axes_props,
						size: 50,
						/*values: (self, vals)=>vals.map(rawValue=>{
							const timeInMS = rawValue * 1000;
							const time = moment_local(timeInMS, self["session"]?.localOffsetFromUTC); // session set in EEGChart.chart hook's postSet handler
							return time.format();
						}),*/

						// needed to avoid crash in setScale("...")!
						//incrs: [100000],
						//splits: [100000],
					},
					{
						...uplotDefaults.axes_props,
						size: 50,

						// needed to avoid crash in setScale("...")!
						//incrs: [1000],
						//splits: [1000],
					},
					{
						...uplotDefaults.axes_props,
						size: 50,
						grid: {show: false},
						// special for z-scale
						scale: "z",
						side: 1,
						//space: 1,
						//range: (u, min, max)=>[Math.ceil(min), Math.max(1, Math.ceil(max))], // keep max at least 1

						// needed to avoid crash in setScale("...")!
						incrs: [1, 5, 10, 50, 100, 500, 1000],
						//splits: [1],
					},
				],
				scales: {
					x: {
						//time: false
						auto: false,
						min: uiState.viewRange_startTime / 1000,
						max: uiState.viewRange_endTime / 1000,
					},
					y: {
						auto: false,
						//min: -100, max: 100,
						range: (u, dataMin, dataMax)=>{ // use func, since stable
							return [-100, 100] as [number, number];
						},
					},
					z: {
						auto: false,
						//from: 'y',
						range: (u, min, max)=>{
							//console.log("Test1", u["v_newZMax"], maxEEGActivity);
							return [0, u["v_newZMax"] ?? maxEEGActivity];
						},
						// to match with UpdateChart
						/*min: 0,
						max: maxEEGActivity,*/
					},
				},
				series: EEGChart_lineTypes,
			};
		}, [
			width, height, processor.eegProcessor.minTime_floored, processor.eegProcessor.maxTime_ceilinged, maxViewRange,
			// don't worry about adding these; render() is only re-called when prop changes anyway (eg. processor, which triggers data change anyway)
			uiState.viewRange_startTime, uiState.viewRange_endTime, maxEEGActivity, overlay, session, uiState.disabledSeries,
		]);
		const data = processor.detailChart_uplotData;

		this.UpdateAnnotations();
		//this.UpdateEEGActivityData(data);
		this.UpdateLineVisibilities(chartOptions);

		useEffect(()=>{
			let lastUpdate_infoForRedraw = {} as ExtInfoForRedraw|n;
			type ExtraInfo = {maxEEGActivity: number};
			let lastUpdate_extraInfo = {} as ExtraInfo;
			const targetInterval = 250; // 250ms is preferred, as it appears to align with current-time update-frequency of video-player (at least after our 50ms diff-thresholding)

			let timerID: number;
			const tick = ()=>{
				const startTime = Date.now();

				const newInfo = this.GetExtInfoForRedraw();
				const newExtraInfo = {...lastUpdate_extraInfo} as ExtraInfo; // extra-info stays the same, until manual updates
				const changes = GetPropChanges(newInfo, lastUpdate_infoForRedraw);
				if (changes.length) {
					const newXScale = {min: newInfo.viewRange_startTime / 1000, max: newInfo.viewRange_endTime / 1000};

					// todo: make this DRY
					newExtraInfo.maxEEGActivity = newInfo.sessionData!.generalHistory.eegActivity.activityByTime.VValues().concat(1).Max();
					if (newExtraInfo.maxEEGActivity != lastUpdate_extraInfo?.maxEEGActivity) {
						//console.log({newMaxEEGActivity});
						//const newZScale = this.chart.current?.scales.z;
						var newZScale = {
							//auto: false,
							//from: 'y',
							//range: (u, min, max)=>[0, newMaxEEGActivity + Math.round(Math.random() * 30)],
							min: 0,
							max: newExtraInfo.maxEEGActivity,
						};
					}

					this.UpdateChart(newXScale, newZScale! ?? undefined); //, changes.Any(a=>a.key == "sessionData"));
					// also update seek-bar, if visible, and simulation data may have changed (due to resim being active)
					//if (currentSeekChart && uiState.processing_useCurrentConfig && !sessionProcessor_resim.processingComplete) {
					if (currentSeekChart && uiState.processing_useCurrentConfig && changes.Any(a=>a.key == "simulationExtent")) {
						currentSeekChart.Update();
					}
					lastUpdate_infoForRedraw = newInfo;
					lastUpdate_extraInfo = newExtraInfo;
				}

				const runTime = Date.now() - startTime;
				let waitTime = targetInterval - runTime;

				// while processing data, use at most X% of cpu-time for chart updating (we'd rather load data fast than refresh fast)
				if (!processor.processingComplete) {
					//const maxCPUTime = .1;
					const maxCPUTime = uiState.rendering_cpuLimit;
					const waitDurationRelToRunTime = (1 - maxCPUTime) / maxCPUTime;
					const waitTimeRequiredForCPUTimeCap = runTime * waitDurationRelToRunTime;
					waitTime = waitTime.KeepAtLeast(waitTimeRequiredForCPUTimeCap);
					// update chart at least once per 5s, else certain issues arise (eg. chart not updating z-axis scale/max, dev-tools pause making tick never tick again)
					waitTime = waitTime.KeepAtMost(5000);
				}

				timerID = setTimeout(tick, waitTime) as any;
			};
			/*const timer = new Timer(250, tick);
			return ()=>timer.Stop();*/
			tick();
			return ()=>{
				clearTimeout(timerID);
				lastUpdate_infoForRedraw = null; // fixes memory leak (path is similar to that in: https://github.com/facebook/react/issues/18790)
			};
		});

		return (
			<div ref={rootRef as any} style={ES({position: "relative", width: "100%", height: "100%"}, overlay && {left: 0, top: 0, height: "calc(100% - 75px)"})}>
				<UPlot chartRef={this.chart} options={chartOptions} data={data} placeLegendBelowContainer={true}/>
			</div>
		);
	}
	chart = createRef_withHooks<uPlot>({
		postSet: chart=>{
			const {processor, session} = this.props;
			if (chart == null) return;
			chart["session"] = session;
			//this.UpdateLineVisibilities();

			const uplotDiv = chart.root as HTMLDivElement;
			const doubleClickStartDiv = uplotDiv.querySelector(".u-over") as HTMLDivElement;
			// prevent uplot's double-click-to-reset-zoom behavior
			uplotDiv.addEventListener("dblclick", e=>{
				e.stopPropagation();
			}, {capture: true});

			//uplotDiv.addEventListener("click", e=>{
			doubleClickStartDiv.addEventListener("click", e=>{
				const {session} = this.props;
				const clickedTime = chart.posToVal(e.offsetX, "x") * 1000;
				if (e.button == 0) {
					if (clickedTime.IsBetween(session.startTime, session.endTime)) {
						SetCurrentTime(clickedTime, this.props.session, {adjustViewRange: "none"});
					}
				}
			});
			doubleClickStartDiv.addEventListener("contextmenu", e=>{
				const {session} = this.props;
				const clickedTime = chart.posToVal(e.offsetX, "x") * 1000;
				const times = [
					store.main.timeline.sessions.currentTime,
					clickedTime,
				];
				const startTime_estimate = times.Min();
				const endTime_estimate = times.Max();
				const bufferStartTime_estimate = startTime_estimate - (store.main.settings.testSegmentStartBuffer * 1000);
				const menuUI = (
					<>
						<VMenuItem text="Create test-segment from yellow-line to here" style={styles.vMenuItem} onClick={()=>{
							if (MeID() == null) return ShowSignInPopup();
							const sessionData = GetSelectedSessionData();
							if (sessionData == null) return void ShowMessageBox({message: "Session data invalid or not fully loaded."});
							if ((sessionData.eegSampleFiles.left?.samplesBySecond.VKeys().length ?? 0) == 0) return void ShowMessageBox({message: "Session data has no EEG samples."});

							/*const secondTimes = sessionData.eegSampleFiles.left.samplesBySecond.VKeys().map(a=>ToNumber(a));
							const samplesInSegment = [] as EEGSample[];
							const samplesBySecondMap = new Map<number, EEGSample[]>();
							for (const time of secondTimes) {
								if (time >= startTime.FloorTo(1000) && time < endTime.CeilingTo(1000)) {
									/*eeg_left_samplesBySecond[time] = sessionData.eegSampleFiles.left.samplesBySecond[time];
									eeg_right_samplesBySecond[time] = sessionData.eegSampleFiles.right.samplesBySecond[time];*#/
									const samplesInSecond = RecreateEEGSamplesInSecond(sessionData.eegSampleFiles.left.samplesBySecond, sessionData.eegSampleFiles.right.samplesBySecond, time);
									for (const sample of samplesInSecond) {
										if (sample.time >= startTime && time <= endTime) {
											samplesInSegment.push(sample);
											UpdateSamplesBySecondMap(samplesBySecondMap, sample, null, false);
										}
									}
								}
							}*/
							const bufferStartSampleI = processor.eegProcessor.GetSampleIndexForTime(bufferStartTime_estimate, true);
							const endSampleI = processor.eegProcessor.GetSampleIndexForTime(endTime_estimate, true);
							const samplesInSegment = [] as EEGSample[]; // includes buffer samples
							for (let i = bufferStartSampleI; i < endSampleI; i++) {
								const sample: EEGSample = {
									time: processor.eegProcessor.samples_time[i] * 1000,
									left: processor.eegProcessor.samples_left[i],
									right: processor.eegProcessor.samples_right[i],
								};
								samplesInSegment.push(sample);
							}
							if (samplesInSegment.length == 0) return void ShowMessageBox({message: "No EEG samples found in selected range."});
							const bufferStartTime = samplesInSegment[0].time;
							const startTime = samplesInSegment.Last(a=>a.time < startTime_estimate).time;
							const endTime = samplesInSegment.Last().time;

							ShowMessageBox({
								title: "Create test-segment?",
								cancelButton: true,
								message: `
									Create a test-segment containing the ${samplesInSegment.length} EEG samples in the selected range?

									Start time: ${moment(startTime).format("HH:mm:ss.SSS")}
									End time: ${moment(endTime).format("HH:mm:ss.SSS")}
									Duration: ${((endTime - startTime) / 1000).RoundTo(.1)}s (please try to keep segments short, ie. <30s)
								`.AsMultiline(0),
								onOK: ()=>{
									//const startIndex = processor.eegProcessor.GetSampleIndexForTime(startTime, true);
									if (processor.eegProcessor.samples_processingLevel[bufferStartSampleI] < 1) {
										processor.eegProcessor.ProcessSample_L1(bufferStartSampleI);
									}
									const firstBufferSample_cache = processor.eegProcessor.samples_cache[bufferStartSampleI];
									//const firstBufferSample_sumOverNormalizationWindow_excludingSelf

									const newEntry = new TestSegment({
										enabled: true,
										sourceSession: session.id,
										bufferStartTime,
										startTime,
										endTime,
										channelBaselines: {
											/*eeg_left: firstBufferSample_cache.left_sumOverNormalizationWindow / processor.eegProcessor.SamplesPerNormalizationWindow,
											eeg_right: firstBufferSample_cache.right_sumOverNormalizationWindow / processor.eegProcessor.SamplesPerNormalizationWindow,*/
											/*eeg_left: preBufferSample_cache.left_baseline,
											eeg_right: preBufferSample_cache.right_baseline,*/
											eeg_left: firstBufferSample_cache.sumOverNormalizationWindow[0] / processor.eegProcessor.SamplesInNormalizationWindowFor(bufferStartSampleI),
											eeg_right: firstBufferSample_cache.sumOverNormalizationWindow[1] / processor.eegProcessor.SamplesInNormalizationWindowFor(bufferStartSampleI),
											/*eeg_left: (firstBufferSample_cache.left_sumOverNormalizationWindow - processor.eegProcessor.samples_left[bufferStartSampleI]) / (processor.eegProcessor.SamplesPerNormalizationWindow - 1),
											eeg_right: (firstBufferSample_cache.right_sumOverNormalizationWindow - processor.eegProcessor.samples_right[bufferStartSampleI]) / (processor.eegProcessor.SamplesPerNormalizationWindow - 1),*/
										},
										channelSamples: {
											eeg_left: samplesInSegment.map(a=>a.left),
											eeg_right: samplesInSegment.map(a=>a.right),
										},
										eyeMoveDeclarations: [],
									});
									new AddTestSegment({entry: newEntry}).Run();
								},
							});
						}}/>
					</>
				);
				ShowVMenu({
					pos: new Vector2(e.pageX, e.pageY),
				}, menuUI);
			});
		},
	});

	UpdateChart(newXScale?, newZScale?) { //, updateEEGActivityData = false) {
		const chart = this.chart.current;
		if (chart == null) return;
		const xScale_old = {min: chart.scales.x.min, max: chart.scales.x.max};
		//console.log("UpdateChart.updateViewRange:", newXScale, "Old:", xScale_old);

		this.UpdateAnnotations();
		/*if (updateEEGActivityData) {
			this.UpdateEEGActivityData(chart.data as number[][]);
		}*/
		this.UpdateLineVisibilities();

		//chart.setData(data, false);
		//chart.setData(data, true);
		//chart.redraw(true);

		// calling setScale triggers a chart redraw
		chart.batch(()=>{
			Assert([xScale_old.min, xScale_old.max].every(a=>!IsNaN(a)), "Found NaN in new scale values. (EEGChart.tsx)");
			chart.setScale("x", newXScale ?? xScale_old);
			//chart.setScale("y", yScale_old as any); // test

			//if (newZScale) chart.setScale("z", newZScale);
			// below is ugly hack, since the line above doesn't work fsr (even though y-scale equivalent test does)
			if (newZScale) {
				chart["v_newZMax"] = newZScale.max;
			}
		});

		/*if (newZScale) {
			chart.setData(chart.data, true); // needed to refresh scales
			chart.redraw(true); // needed to refresh scales
			console.log("Trying to refresh scales");
		}*/
	}
}