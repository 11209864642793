import {GetValues_ForSchema} from "js-vextensions";
import {AddSchema} from "mobx-firelink";
import {nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";

export enum EffectPointerType {
	soundTag = "soundTag",
	soundFile = "soundFile",
	shakeTag = "shakeTag",
	lightTag = "lightTag",
	scriptTag = "scriptTag",
}
AddSchema("EffectPointerType", {oneOf: GetValues_ForSchema(EffectPointerType)});

export function ResetEffectPointerBasedOnType(effect: EffectPointer) {
	const newData = NewEffectPointerBasedOnType(effect.type);
	// clear existing data
	for (const key in newData) {
		delete effect[key];
	}
	// set new data
	Object.assign(effect, newData);
}
export function NewEffectPointerBasedOnType(type: EffectPointerType) {
	if (type == EffectPointerType.soundTag) return new EffectPointer({type, soundEffectTag: ""});
	if (type == EffectPointerType.soundFile) return new EffectPointer({type, soundFile: {filePath: "", volume: 1, durationLimit: -1, loop: true, runCount: 1, wordsIgnoreGroup: 0}});
	if (type == EffectPointerType.shakeTag) return new EffectPointer({type, shakeEffectTag: ""});
	if (type == EffectPointerType.lightTag) return new EffectPointer({type, lightEffectTag: ""});
	if (type == EffectPointerType.scriptTag)  return new EffectPointer({type, scriptEffectTag: ""});
}

export class EffectPointer {
	static GetEffectTag(effect: EffectPointer) {
		if (effect.soundEffectTag) return effect.soundEffectTag;
		if (effect.shakeEffectTag) return effect.shakeEffectTag;
		if (effect.lightEffectTag) return effect.lightEffectTag;
		if (effect.scriptEffectTag) return effect.scriptEffectTag;
		return null;
	}

	constructor(data?: Partial<EffectPointer>) {
		Object.assign(this, data);
	}

	type = EffectPointerType.soundTag;
	afterEffect: EffectPointer|n;

	// sound
	soundEffectTag: string|n;
	soundFile: {
		filePath: string;
		volume?: number|n;
		durationLimit?: number|n;
		loop?: boolean|n;
		runCount?: number|n;
		wordsIgnoreGroup?: number|n;

		// if set, audio-files containing this subpath will (and will only) be selected in the final-run (see runCount)
		subpathExclusiveToLastRun?: string|n;
		// if set, the audio-file selected for the last-run gets played X times (rather than only once)
		lastRun_playCount?: number|n;
	}|n;

	// shake
	shakeEffectTag: string|n;

	// light
	lightEffectTag: string|n;

	// script
	scriptEffectTag: string|n;
}
AddSchema("EffectPointer", {
	properties: {
		type: {type: "string", enum: Object.values(EffectPointerType)},
		afterEffect: {type: ["null", "object"]},

		soundEffectTag: {type: ["null", "string"]},
		soundFile: {
			type: ["null", "object"],
			properties: {
				filePath: {type: "string"},
				volume: {type: ["null", "number"]},
				durationLimit: {type: ["null", "number"]},
				loop: {type: ["null", "boolean"]},
				runCount: {type: ["null", "number"]},
				wordsIgnoreGroup: {type: ["null", "number"]},
				subpathExclusiveToLastRun: {type: ["null", "string"]},
				lastRun_playCount: {type: ["null", "number"]},
			},
		},
		shakeEffectTag: {type: ["null", "string"]},
		lightEffectTag: {type: ["null", "string"]},
		scriptEffectTag: {type: ["null", "string"]},
	},
});

export function EffectPointer_ToString(effect: EffectPointer) {
	return [
		effect.soundEffectTag && `SoundTag(${effect.soundEffectTag})`,
		effect.shakeEffectTag && `ShakeTag(${effect.shakeEffectTag})`,
		effect.lightEffectTag && `LightTag(${effect.lightEffectTag})`,
		effect.scriptEffectTag && `ScriptTag(${effect.scriptEffectTag})`,
		effect.soundFile && `AudioFile(filePath:${effect.soundFile.filePath
			} volume:${effect.soundFile.volume ?? "n/a"
			} durationLimit:${effect.soundFile.durationLimit ?? "n/a"
			} loop:${effect.soundFile.loop ?? "n/a"
			} runCount:${effect.soundFile.runCount ?? "n/a"
			} wordsIgnoreGroup:${effect.soundFile.wordsIgnoreGroup ?? "n/a"})`,
	].filter(a=>a).join(", ");
}