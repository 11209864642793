import {AddSchema} from "mobx-firelink";
import {TriggerSet} from "../@TriggerSet";
import {SwitchableCompID} from "../../../../Engine/FBASession/Components/ModeSwitcherComp.js";

export class FBAConfig_ModeSwitcher {
	constructor(initialData: Partial<FBAConfig_ModeSwitcher>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	cycleActiveComp_triggerSet: TriggerSet;

	// effects
	onAlarmWaitEnd_setActiveMode: SwitchableCompID|n;

	startStates: StartStateEntry[];
	cycleEntries: CycleEntry[];
}
AddSchema("FBAConfig_ModeSwitcher", {
	properties: {
		enabled: {type: "boolean"},
		cycleActiveComp_triggerSet: {$ref: "TriggerSet"},
		onAlarmWaitEnd_setActiveMode: {"oneOf": [{"type": "null"}, {$ref: "SwitchableCompID"}]},
		startStates: {items: {$ref: "StartStateEntry"}},
		cycleEntries: {items: {$ref: "CycleEntry"}},
	},
});

export class StartStateEntry {
	compID: SwitchableCompID;
	startState_day: CompState;
	startState_night: CompState;
}
AddSchema("StartStateEntry", {
	properties: {
		compID: {$ref: "SwitchableCompID"},
		startState_day: {$ref: "CompState"},
		startState_night: {$ref: "CompState"},
	},
});

export class CompState {
	enabled: boolean;
}
AddSchema("CompState", {
	properties: {
		enabled: {type: "boolean"},
	},
});

export class CycleEntry {
	compID: SwitchableCompID;
	requiredLaunchType?: LaunchType;
}
AddSchema("CycleEntry", {
	properties: {
		compID: {$ref: "SwitchableCompID"},
		requiredLaunchType: {oneOf: [{type: "null"}, {$ref: "LaunchType"}]},
	},
});

export const LaunchType_values = ["night", "day"] as const;
export type LaunchType = typeof LaunchType_values[number];
AddSchema("LaunchType", {
	oneOf: LaunchType_values.map(a=>({const: a})),
});