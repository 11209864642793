import {ProposalsUI as ProposalsUI_Base} from "firebase-feedback";
import {BaseComponent} from "react-vextensions";

export class ProposalsUI extends BaseComponent<{}, {}> {
	render() {
		const ProposalsUI_Base2 = ProposalsUI_Base as any; // temp fix
		return (
			<ProposalsUI_Base2 subNavBarWidth={84}/>
		);
	}
}