import {Entity} from "../../Store/firebase/entities/@Entity.js";
import {JournalEntry, JournalSegment} from "../../Store/firebase/journalEntries/@JournalEntry.js";
import {EntityCategory} from "../../Store/main/tools/journey.js";

export class DNDInfo {
	constructor(data: Partial<DNDInfo>) {
		Object.assign(this, data);
	}
	type:
		// types that can only be droppables
		"EntityPoolHeader" | "EntityPool" | "TermSequence" | "EmptySlotInEntitySequence" | "RemoveEntityFromSequenceButton" |
		// types that can be draggables
		"TermCell";
	parentDroppableInfo?: DNDInfo;
	indexInContainer?: number; // if EmptySlotInEntitySequence or EntityCell

	// if EntityPoolHeader or EntityPool
	category?: EntityCategory|n;

	// if TermSequence
	journalEntry?: JournalEntry;
	segment?: JournalSegment;
	segmentIndex?: number; // segmentIndex needed to ensure derived id is unique
	termSequence?: string[];

	// if EmptySlotInEntitySequence
	// none specific

	// if RemoveEntityFromSequenceButton
	// none specific

	// if TermCell
	term?: string;
}