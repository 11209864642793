import {BaseComponentPlus} from "react-vextensions";
import {Observer, PageContainer} from "web-vcore";
import {SignInPanel} from "../../UI/@Shared/NavBar/UserPanel.js";
import {Text} from "react-vcomponents";
import {SleepAsync} from "js-vextensions";

@Observer
export class SignInHelper extends BaseComponentPlus({} as {}, {}) {
	render() {
		const closeAfterSignIn = startURL.pathNodes[1] == "sign-in-helper";
		return (
			<PageContainer scrollable={true}>
				<Text mb={5}>{`Sign in using one of the buttons below. The auth-data will then be sent to the Lucid Frontier desktop app${closeAfterSignIn ? ", and this page will close" : ""}.`}</Text>
				<SignInPanel onSignIn={async credential=>{
					const idToken = await credential.user.getIdToken();
					// since we're using Google sign-in, the credential object also contains idToken and accessToken fields
					//await TransferTokensToElectronApp(credential.credential["idToken"], credential.credential["accessToken"]);
					await TransferTokensToElectronApp(idToken, credential.user.refreshToken); // todo: Is "refreshToken" the same as "accessToken"? I'd guess not, but dunno how to acquire then.
					if (closeAfterSignIn) {
						window.close();
					}
				}}/>
			</PageContainer>
		);
	}
}

const desktopApp_receiveWebSignInPort = 3202; // 3002 + 200
async function TransferTokensToElectronApp(idToken: string, accessToken: string) {
	const script = document.createElement("script");
	script.src = `http://localhost:${desktopApp_receiveWebSignInPort}/receive-web-sign-in/?idToken=${idToken}&accessToken=${accessToken}`;
	document.head.appendChild(script);
	await SleepAsync(1000);
	script.remove();
}