import {UserEdit} from "../CommandMacros";
import {AssertValidate, GenerateUUID, Command, AssertV} from "mobx-firelink";
import {Scene} from "../../Store/firebase/scenes/@Scene";

@UserEdit
export class AddScene extends Command<{scene: Scene}, string> {
	id: string;
	Validate() {
		const {scene} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		scene.creator = this.userInfo!.id;
		scene.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("Scene", scene, `Scene invalid`);
	}

	GetDBUpdates() {
		const {scene} = this.payload;

		const updates = {};
		updates[`scenes/${this.id}`] = scene;
		return updates;
	}
}