import {Assert, IsString} from "js-vextensions";
import {StoreAccessor, GetDoc, GetDocs, WhereOp} from "mobx-firelink";
import {PermissionGroupSet} from "../../../Store/firebase/users/@User";
import {GetUser} from "../users";

export const GetRVPPromptCount = StoreAccessor(s=>(userID: string|n)=>{
	return GetUser(userID)?.rvpPromptCount ?? 0;
});

export const GetUserProcessMessages = StoreAccessor(s=>(userID: string)=>{
	return GetDocs({
		queryOps: [new WhereOp("user", "==", userID)],
	}, a=>a.userProcessMessages);
});

// permissions
// ==========

/*export const GetUserJoinDate = StoreAccessor(s=>(userID: string): number=>{
	return GetUser(userID)?.joinDate;
});*/

const nullUser_emptyPerms = {basic: false, verified: false, mod: false, admin: false} as PermissionGroupSet; // temp
const nullUser_standardPerms = {basic: true, verified: true, mod: false, admin: false} as PermissionGroupSet; // temp
export function IsNullUserPerms(permissions: PermissionGroupSet|n, countNullPermsAsNullUserPerms = true) {
	if (permissions == null && countNullPermsAsNullUserPerms) return true;
	return permissions == nullUser_emptyPerms || permissions == nullUser_standardPerms;
}

export const GetUserPermissionGroups = StoreAccessor(s=>(userID: string|n, upgradeAnonToStandardUserPerms = false): PermissionGroupSet=>{
	const user = GetUser(userID);
	let result = user?.permissionGroups;
	// if null, user is not logged in; handle based on passed flag
	if (result == null) {
		//result = upgradeAnonToStandardUserPerms ? Clone(standardUserPerms) as PermissionGroupSet : emptyUserPerms;
		result = upgradeAnonToStandardUserPerms ? nullUser_standardPerms : nullUser_emptyPerms;
	}
	return result;
});

/*export function GetUserAccessLevel(userID: string) {
	const groups = GetUserPermissionGroups(userID);
	if (groups == null) return AccessLevel.Basic;

	if (groups.admin) return AccessLevel.Admin;
	if (groups.mod) return AccessLevel.Mod;
	if (groups.verified) return AccessLevel.Verified;
	// if (groups.basic) return AccessLevel.Basic;
	Assert(false);
}*/

export const CanGetBasicPermissions = StoreAccessor(s=>(userIDOrPermissions: string | PermissionGroupSet | n)=>{
	// if (true) return HasModPermissions(userIDOrPermissions); // temp; will be removed once GAD is over

	const permissions = IsString(userIDOrPermissions) ? GetUserPermissionGroups(userIDOrPermissions) : userIDOrPermissions;
	return permissions == null || permissions.basic; // if anon/not-logged-in, assume user can get basic permissions once logged in
});
export const HasBasicPermissions = StoreAccessor(s=>(userIDOrPermissions: string | PermissionGroupSet | n)=>{
	// if (true) return HasModPermissions(userIDOrPermissions); // temp; will be removed once GAD is over

	const permissions = IsString(userIDOrPermissions) ? GetUserPermissionGroups(userIDOrPermissions) : userIDOrPermissions;
	return permissions ? permissions.basic : false;
});
export const HasModPermissions = StoreAccessor(s=>(userIDOrPermissions: string | PermissionGroupSet | n)=>{
	const permissions = IsString(userIDOrPermissions) ? GetUserPermissionGroups(userIDOrPermissions) : userIDOrPermissions;
	return permissions ? permissions.mod : false;
});
export const HasAdminPermissions = StoreAccessor(s=>(userIDOrPermissions: string | PermissionGroupSet | n)=>{
	const permissions = IsString(userIDOrPermissions) ? GetUserPermissionGroups(userIDOrPermissions) : userIDOrPermissions;
	return permissions ? permissions.admin : false;
});
/** If user is the creator, also requires that they (still) have basic permissions. */
//export const IsUserCreatorOrMod = StoreAccessor(s=>(userID: string, entity: Term | Image | Map | MapNode | MapNodePhrasing | Timeline /* | Post | Thread */)=>{
export const IsUserCreatorOrMod = StoreAccessor(s=>(userID: string|n, entity: {creator?: string}|n)=>{
	return (entity && entity.creator === userID && HasBasicPermissions(userID)) || HasModPermissions(userID);
});