import {E, GetEntries} from "js-vextensions";
import {runInAction} from "mobx";
import {Row, Select} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {store} from "../../Store/index.js";
import {GetBundles} from "../../Store/firebase/bundles.js";
import {GetSelectedFBAConfig} from "../../Store/firebase/fbaConfigs.js";
import {GetEntities} from "../../Store/firebase/entities.js";
import {GetLights} from "../../Store/firebase/lights.js";
import {GetShakes} from "../../Store/firebase/shakes.js";
import {GetSounds} from "../../Store/firebase/sounds.js";
import {EngineTab} from "../../Store/main/tools/engine.js";
import {useExpandedNavBar} from "../../UI/@Shared/NavBar.js";
import {ES} from "../../Utils/UI/GlobalStyles.js";
import {Observer, PageContainer, RunInAction} from "web-vcore";
import {GetScripts} from "../../Store/firebase/scripts.js";
import {MeID} from "../../Store/firebase/users.js";
import {HasModPermissions} from "../../Store/firebase/users/$user.js";
import {FBAConfigPanel_Client} from "./Engine/FBAConfigPanel_Client.js";
import {SessionLogPanel} from "./@Shared/BetweenSessionTypes/SessionLogPanel.js";
import {SessionStatsPanel} from "./Engine/SessionStatsPanel.js";
import {SessionTimelinePanel} from "./Engine/SessionTimelinePanel.js";
import {FBAConfigPanel_Local} from "./Engine/FBAConfigPanel_Local.js";

// ui
// ==========

@Observer
export class EngineUI extends BaseComponentPlus({}, {}) {
	render() {
		const tab = store.main.tools.engine.tab;
		// these are just here so we have synchronous access to them in FBASession.ts
		const bundles = GetBundles();
		const entities = GetEntities();
		const sounds = GetSounds();
		const shakes = GetShakes();
		const lights = GetLights();
		const scripts = GetScripts(); //GetScripts(MeID());
		//localConfig: State(a=>a.main.tools.fba.localConfig),
		const config = GetSelectedFBAConfig();

		const largeVersion = useExpandedNavBar();
		let engineTabOptions = GetEntries(EngineTab);
		// these tabs are not useful for other users atm
		if (!HasModPermissions(MeID())) {
			engineTabOptions = engineTabOptions.filter(a=>!a.name.IsOneOf("Timeline", "Stats"));
		}

		return (
			<PageContainer preset="text" style={E(
				{padding: 0},
				tab == EngineTab.Log && {height: "calc(100% - 70px)"},
				!largeVersion && {margin: "20px auto 20px auto"},
			)}>
				<Row>
					<Select displayType="button bar" options={engineTabOptions} value={store.main.tools.engine.tab} onChange={val=>{
						RunInAction("EngineUI.tab.onChange", ()=>store.main.tools.engine.tab = val);
					}}/>
				</Row>
				<ScrollView style={ES({flex: 1, padding: 10})} contentStyle={{display: "flex", flexDirection: "column"}}>
					{tab == EngineTab.Host && <FBAConfigPanel_Local journeyUI={false}/>}
					{tab == EngineTab.Client && <FBAConfigPanel_Client/>}
					{tab == EngineTab.Log && <SessionLogPanel/>}
					{tab == EngineTab.Timeline && <SessionTimelinePanel/>}
					{tab == EngineTab.Stats && <SessionStatsPanel/>}
				</ScrollView>
			</PageContainer>
		);
	}
}