import {Column, Row, RowLR, Spinner, Text, TextInput, Select, CheckBox} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.background, (c, v)=>c.background = v);

const splitAt = 130;
export class Background_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Background" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>
				{/*remote &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Play audio on:</Text>
						<Select options={["Host (other device)", "Remote (this device)"]} enabled={enabled} value={"Host (other device)"}
							onChange={val=>{
								// todo
							}}/>
					</RowLR>*/}

				{config.enabled &&
				<>
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Sound tag: </Text>
						<TextInput enabled={enabled} style={{width: 200}} value={config.tag} onChange={val=>ChangeConfig(c=>c.tag = val)}/>
					</RowLR>}
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Start volume: </Text>
						<Spinner enabled={enabled} value={(config.startVolume * 100).RoundTo(1)} onChange={val=>ChangeConfig(c=>c.startVolume = val / 100)} min={-100} max={100}/>
					</RowLR>}
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Increase volume:</Text>
						<ControlInput enabled={enabled} value={config.increaseVolume_triggerSet} onChange={val=>ChangeConfig(c=>c.increaseVolume_triggerSet = val)}/>
						{!client &&
						<>
							<Text ml={10}>Amount: </Text>
							<Spinner enabled={enabled} value={(config.increaseVolume_amount * 100).RoundTo(1)} onChange={val=>ChangeConfig(c=>c.increaseVolume_amount = val / 100)} min={0} max={100}/>
							<Text> %</Text>
						</>}
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Decrease volume:</Text>
						<ControlInput enabled={enabled} value={config.decreaseVolume_triggerSet} onChange={val=>ChangeConfig(c=>c.decreaseVolume_triggerSet = val)}/>
						{!client &&
						<>
							<Text ml={10}>Amount: </Text>
							<Spinner enabled={enabled} value={(config.decreaseVolume_amount * 100).RoundTo(1)} onChange={val=>ChangeConfig(c=>c.decreaseVolume_amount = val / 100)} min={0} max={100}/>
							<Text> %</Text>
						</>}
					</RowLR>
				</>}
			</Column>
		);
	}
}