import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {FBAConfig_Alarms} from "./@EngineConfig/@EC_Alarms";
import {FBAConfig_Background} from "./@EngineConfig/@EC_Background";
import {FBAConfig_Camera} from "./@EngineConfig/@EC_Camera";
import {FBAConfig_DreamNarration} from "./@EngineConfig/@EC_DreamNarration";
import {FBAConfig_DreamQuiz} from "./@EngineConfig/@EC_DreamQuiz.js";
import {FBAConfig_DreamRecall} from "./@EngineConfig/@EC_DreamRecall.js";
import {FBAConfig_DreamTranscribe} from "./@EngineConfig/@EC_DreamTranscribe.js";
import {FBAConfig_EEG} from "./@EngineConfig/@EC_EEG";
import {FBAConfig_Gyro} from "./@EngineConfig/@EC_Gyro";
import {FBAConfig_JourneyEntry} from "./@EngineConfig/@EC_JourneyEntry.js";
import {FBAConfig_JourneyGrid} from "./@EngineConfig/@EC_JourneyGrid.js";
import {FBAConfig_JourneyTraining} from "./@EngineConfig/@EC_JourneyTraining.js";
import {FBAConfig_JourneyVisualization} from "./@EngineConfig/@EC_JourneyVisualization.js";
import {FBAConfig_Memory} from "./@EngineConfig/@EC_Memory.js";
import {FBAConfig_ModeSwitcher} from "./@EngineConfig/@EC_ModeSwitcher.js";
import {FBAConfig_StoryExplore} from "./@EngineConfig/@EC_StoryExplore.js";
import {FBAConfig_GetDefault, FBAConfig_GetDefault_Remote} from "./@FBAConfig_Presets";
import {GetAllTriggerSetsInFBAConfig} from "./@TriggerSet_FieldFinder.js";
import {FBAConfig_General} from "./@EngineConfig/@EC_General.js";
import {FBAConfig_MicroSnooze} from "./@EngineConfig/@EC_MicroSnooze.js";
import {FBAConfig_ConceptLink} from "./@EngineConfig/@EC_ConceptLink.js";
import {FBAConfig_RealityCheck} from "./@EngineConfig/@EC_RealityCheck.js";

export class FBAConfig {
	static GetAllTriggerSets(config: FBAConfig) {
		return GetAllTriggerSetsInFBAConfig(config);
	}
	static GetAllTriggerSequences(config: FBAConfig) {
		const triggers = FBAConfig.GetAllTriggerSets(config);
		return triggers.SelectMany(a=>(a ? a.sequences : []));
	}
	static GetAllTriggerSequenceItems(config: FBAConfig) {
		const sequences = this.GetAllTriggerSequences(config);
		return sequences.SelectMany(a=>(a ? a.items : []));
	}

	constructor(initialData: Partial<FBAConfig>, remoteConfig = false) {
		this.VSet(remoteConfig ? FBAConfig_GetDefault_Remote() : FBAConfig_GetDefault());
		this.VSet(initialData);
	}
	_key?: string;

	baseConfig?: string;
	creator: string;
	createdAt: number;
	editedAt: number;
	name: string;

	general: FBAConfig_General;
	modeSwitcher: FBAConfig_ModeSwitcher;
	background: FBAConfig_Background;
	camera: FBAConfig_Camera;
	gyro: FBAConfig_Gyro;
	eeg: FBAConfig_EEG;
	alarms: FBAConfig_Alarms;
	microSnooze: FBAConfig_MicroSnooze;
	memory: FBAConfig_Memory;
	dreamTranscribe: FBAConfig_DreamTranscribe;
	dreamNarration: FBAConfig_DreamNarration;
	dreamRecall: FBAConfig_DreamRecall;
	dreamQuiz: FBAConfig_DreamQuiz;
	conceptLink: FBAConfig_ConceptLink;
	realityCheck: FBAConfig_RealityCheck;
	storyExplore: FBAConfig_StoryExplore;
	journeyTraining: FBAConfig_JourneyTraining;
	journeyVisualization: FBAConfig_JourneyVisualization;
	journeyGrid: FBAConfig_JourneyGrid;
	journeyEntry: FBAConfig_JourneyEntry;
}
AddSchema("FBAConfig", {
	properties: {
		baseConfig: {type: "string"},
		creator: {type: "string"},
		createdAt: {type: "number"},
		editedAt: {type: "number"},
		name: {type: "string", pattern: name_general},

		general: {$ref: "FBAConfig_General"},
		modeSwitcher: {$ref: "FBAConfig_ModeSwitcher"},
		background: {$ref: "FBAConfig_Background"},
		camera: {$ref: "FBAConfig_Camera"},
		gyro: {$ref: "FBAConfig_Gyro"},
		eeg: {$ref: "FBAConfig_EEG"},
		alarms: {$ref: "FBAConfig_Alarms"},
		microSnooze: {$ref: "FBAConfig_MicroSnooze"},
		memory: {$ref: "FBAConfig_Memory"},
		dreamTranscribe: {$ref: "FBAConfig_DreamTranscribe"},
		dreamNarration: {$ref: "FBAConfig_DreamNarration"},
		dreamRecall: {$ref: "FBAConfig_DreamRecall"},
		dreamQuiz: {$ref: "FBAConfig_DreamQuiz"},
		conceptLink: {$ref: "FBAConfig_ConceptLink"},
		realityCheck: {$ref: "FBAConfig_RealityCheck"},
		storyExplore: {$ref: "FBAConfig_StoryExplore"},
		journeyTraining: {$ref: "FBAConfig_JourneyTraining"},
		journeyVisualization: {$ref: "FBAConfig_JourneyVisualization"},
		journeyGrid: {$ref: "FBAConfig_JourneyGrid"},
		journeyEntry: {$ref: "FBAConfig_JourneyEntry"},
	},
});

export enum AutoEndType {
	AtTimeOfDay = 10,
	AfterDuration = 20,
}
AddSchema("AutoEndType", {oneOf: GetValues_ForSchema(AutoEndType)});