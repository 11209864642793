import {CachedTransform} from "js-vextensions";
import {StoreAccessor, GetDocs, GetDoc} from "mobx-firelink";
import {Shake} from "./shakes/@Shake";
import {EffectVisibility} from "./sounds/@Sound";
import {IsUserCreatorOrMod} from "./users/$user";
import {MeID, Me} from "./users";

export const GetShakes = StoreAccessor(s=>(filterOutHidden = true): Shake[]=>{
	let result = GetDocs({}, a=>a.shakes).map(a=>a?.Cast(Shake));
	if (filterOutHidden) result = result.filter(a=>a && (a.visibility == EffectVisibility.Visible || IsUserCreatorOrMod(MeID(), a)));
	return result;
});
export const GetShakes_WithUserTag = StoreAccessor(s=>(tagName: string|n, filterOutHidden = true): Shake[]=>{
	const shakes = GetShakes(filterOutHidden);
	const userShakeTags = Me()?.entityTags?.shakes ?? {};
	return shakes.filter(a=>userShakeTags[a._key]?.includes(tagName as any));
});
export const GetShake = StoreAccessor(s=>(id: string): Shake|n=>{
	return GetDoc({}, a=>a.shakes.get(id))?.Cast(Shake);
});