import {name_general} from "../../../Utils/General/SharedPatterns";
import {AddSchema, UUID_regex} from "mobx-firelink";

/*export enum BundleType {
	Target = 10,
	Collection = 20,
}*/

export class Bundle {
	constructor(initialData: Partial<Bundle>) {
		this.VSet(initialData);
	}

	_key: string;
	creator: string;
	createdAt: number;

	name: string;
	//type: SoundType;
	//description: string;
	tags: string[];

	sounds: EntryAttachment[];
	shakes: EntryAttachment[];
	lights: EntryAttachment[];
	//scenes: EntryAttachment[];
	//images: EntryAttachment[];
	// map of the images, sounds, etc. included in arrays above (for usage-searching)
	subs_map: {[key: string]: boolean};
}
AddSchema("Bundle", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},
		name: {type: "string", pattern: name_general},
		//description: {type: "string"},
		//type: {oneOf: GetValues_ForSchema(SoundType)},
		tags: {items: {type: "string"}},

		sounds: {items: {$ref: "EntryAttachment"}},
		shakes: {items: {$ref: "EntryAttachment"}},
		lights: {items: {$ref: "EntryAttachment"}},
		//scenes: {items: {$ref: "EntryAttachment"}},
		//images: {items: {$ref: "EntryAttachment"}},
		subs_map: {$ref: "SubSet"},
	},
});

export type SubSet = { [key: string]: boolean; };
AddSchema("SubSet", {patternProperties: {[UUID_regex]: {type: "boolean"}}});

export class EntryAttachment {
	constructor(initialData?: Partial<EntryAttachment>) {
		this.VSet(initialData);
	}
	id: string;
}
AddSchema("EntryAttachment", {
	properties: {
		id: {type: "string"},
	},
	required: ["id"],
});