import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../Store/index.js";
import {Observer} from "web-vcore";

@Observer
export class ModelsPanel extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.tools.training;

		return (
			<>
			</>
		);
	}
}