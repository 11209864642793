import {runInAction} from "mobx";
import {observer} from "mobx-react";
import {useRef} from "react";
import {Button, Row} from "react-vcomponents";
import {store} from "../../../../../Store/index.js";
import {EngineSessionInfo} from "../../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {SetCurrentTime} from "../../../../../Store/main/timeline.js";

export let VideoRecordingPlayer_instanceInfo: {videoEl: HTMLVideoElement, period: {start: number, end: number}}|n;
export const VideoRecordingPlayer = React.memo(observer((p: {
	session: EngineSessionInfo, period: {start: number, end: number}, path: string,
})=>{
	const uiState = store.main.timeline.sessions;
	const {moveTimeWithVideo, playbackSpeed} = uiState; // access here, so changes cause re-render
	const {currentTime_nonVideoPlayerSets} = uiState; // access this, so video-player reacts to time-changes that were external (and thus necessitate remount)

	//const videoPlaying = false;
	const videoRef = useRef<HTMLVideoElement>();
	return (
		<video controls autoPlay={uiState.video_autoPlay} src={`http://localhost:3102/video/${p.path.replace(/\//g, "$")}`}
			style={{position: "absolute", left: 0, top: 0, width: "100%", height: "100%", outline: "none"}}
			onTimeUpdate={e=>{
				const newTime = p.period.start + (videoRef.current!.currentTime * 1000);
				const timeDiff = newTime.Distance(uiState.currentTime);
				//if (uiState.moveTimeWithVideo && timeDiff >= 500) {
				if (moveTimeWithVideo && timeDiff >= 50) { // update more frequently, since playback likely misaligned with other-comp update-timers
					//console.log("SetToLocalTime:", videoRef.current.currentTime);
					//RunInAction("video.onTimeUpdate", ()=>SetCurrentTime(lastRecordingForTime_period.start + (videoRef.current.currentTime * 1000), session));
					SetCurrentTime(newTime, p.session, {fromVideoPlayer: true});
				}
			}}
			/*onPlay={()=>videoPlaying = true}
			onPause={()=>videoPlaying = false}*/
			ref={c=>{
				videoRef.current = c ?? undefined;
				VideoRecordingPlayer_instanceInfo = c ? {videoEl: c, period: p.period} : null;
				if (!c) return;
				try {
					c.playbackRate = playbackSpeed;
				} catch {
					// defensive; we don't want potentially-differing browser playback-speed limits to break site UI
				}
				//if (!videoPlaying) { // if video is playing, it has priority over store-changes (else the playback interrupts itself!)
				c.currentTime = (uiState.currentTime - p.period.start) / 1000;
			}}/>
	);
}));