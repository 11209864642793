import {StoreAccessor, GetDocs, WhereOp, GetDoc, QueryOp} from "mobx-firelink";
import {TestSegment, SamplesBySecond} from "./testSegments/@TestSegment";

/*function OrderPropsByKey(obj: SamplesBySecond) {
	if (obj == null) return null;
	return obj.Pairs().OrderBy(a=>a.keyNum).ToMapObj(a=>a.key, a=>a.value);
}
function FixSegment(segment: TestSegment) {
	// currently, mutates the segment-data (that's fine)
	segment.channelSamples.eeg_left = OrderPropsByKey(segment.channelSamples.eeg_left);
	segment.channelSamples.eeg_right = OrderPropsByKey(segment.channelSamples.eeg_right);
	return segment;
}*/

export const GetTestSegments = StoreAccessor(s=>(userID: string, sessionID?: string): TestSegment[]=>{
	const result = GetDocs({
		queryOps: [
			userID != null && new WhereOp("creator", "==", userID),
			sessionID && new WhereOp("sourceSession", "==", sessionID),
		].filter(a=>a) as QueryOp[],
	}, a=>a.testSegments);
	//return result.map(segment=>FixSegment(segment));
	return result;
});
export const GetTestSegment = StoreAccessor(s=>(id: string): TestSegment|n=>{
	const result = GetDoc({}, a=>a.testSegments.get(id));
	//return result ? FixSegment(result) : null;
	return result;
});