import chroma from "chroma-js";
import React, {createRef, useEffect} from "react";
import {Annotation, AnnotationsPlugin, GroupedBarsPlugin, GroupedBarsPluginOptions} from "uplot-vplugins";
import useResizeObserver from "use-resize-observer";
import {Chroma, dayInMS, ES, InfoButton, Observer, RunInAction_Set, TextPlus, uplotDefaults, VDateTime} from "web-vcore";
import {Assert, E, GetEntries, GetPercentFromXToY, ModifyString, Range} from "js-vextensions";
import moment from "moment";
import {UPlot} from "react-uplot";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Pre, Row, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import uPlot from "uplot";
import {store} from "../../../../../Store/index.js";
import {TranscribeSettingsState} from "../../../../../Store/main/settings.js";
import {GetYValuesForSamplesGraphYType} from "./SamplesAggregation.js";
import {OnMicSampleSet_extraListeners, SummarySample, summarySamples_bufferOfLast60s, summarySamples_countPerSecond} from "../../../../../Utils/Bridge/Bridge_Native/PhoneSensors.js";
import {Samples_GetChartOptions, SamplesGraph_XValueToLabel} from "./SamplesChartOptions.js";
import {Samples_PopulateAnnotationsForChart} from "./SamplesAnnotationsPopulator.js";

export enum SamplesGraphYType {
	absAvg = "absAvg",
	absAvg_directional = "absAvg_directional",
	absMax_directional = "absMax_directional",
}

// todo: probably change UI to show "tag" instead of "entity" everywhere (more common/understandable term)
export function SamplesGraphYType_Label(yType: SamplesGraphYType) {
	if (yType == "absAvg") return "Sample -> abs -> avgInChunk [non-directional]";
	if (yType == "absAvg_directional") return "Sample -> abs -> avgInChunk [directional]";
	if (yType == "absMax_directional") return "Sample -> abs -> maxInChunk [directional]";
	return ModifyString(yType, m=>[m.startLower_to_upper, m.lowerUpper_to_lowerSpaceLower]);
}

export function SamplesGraph_GetTicks(uiState: Pick<TranscribeSettingsState, "graphTimeToShow" | "smoothing">, includeSmoothing: boolean) {
	const extraLeftRangeForTimeBased = includeSmoothing ? uiState.smoothing : 0;
	
	let ticks: number[];
	if (true) {
		ticks = Range((-uiState.graphTimeToShow - extraLeftRangeForTimeBased) * summarySamples_countPerSecond, 0, 1);
	} else {
		Assert(false, "Invalid x-type.");
	}
	return ticks;
}

@Observer
export class SamplesGraph extends BaseComponent<{}, {}> {
	chart = createRef<uPlot>();
	render() {
		const uiState = store.main.settings.transcribe;
		const {ref: rootRef, width = -1, height = -1} = useResizeObserver();

		// set up listener, so that graph updates whenever a new set of mic-samples are received
		const onMicSampleSet = (summarySamples: SummarySample[])=>{
			this.Update();
		};
		useEffect(()=>{
			OnMicSampleSet_extraListeners.push(onMicSampleSet);
			return ()=>void OnMicSampleSet_extraListeners.Remove(onMicSampleSet);
		}, []);
		/*const summarySamplesUsedForDisplayRange = summarySamples_bufferOfLast60s
			.slice(-summarySamples_countPerSecond * (uiState.graphTimeToShow + uiState.smoothing));*/

		const series: uPlot.Series[] = [
			{
				label: "Sample offset [s]",
				//scale: "x",
				value: (self, xValue)=>SamplesGraph_XValueToLabel(xValue),
			} as any,
		];
		const xTicks = SamplesGraph_GetTicks(uiState, false);
		const xTicks_extendedForSmoothing = SamplesGraph_GetTicks(uiState, uiState.smoothing > 0);

		const uplotData = [xTicks] as uPlot.AlignedData;

		//AddBaseMarker(series, uplotData, xValues, uiState);
		const annotations = [] as Annotation[];

		//const seriesYTypesToShow = GetEntries(SamplesGraphYType).map(a=>a.value);
		const seriesYTypesToShow = [uiState.yType]

		const hueShiftPerSeries = 360 / seriesYTypesToShow.length;
		const yValuesPerSeries = new Map<string, number[]>();
		for (const [index, seriesYType] of seriesYTypesToShow.entries()) {
			const strokeColor = chroma(hueShiftPerSeries * index, 1, .5, "hsl");
			series.push(E(
				{
					label: SamplesGraphYType_Label(seriesYType),
					//scale: groupScales.get(group),
					//scale: seriesYType,
					scale: "y",
					stroke: strokeColor.css(),
					fill: strokeColor.alpha(.1).css(),
					/*fill: uiState.renderType == "bars"
						? strokeColor.css()
						: strokeColor.alpha(.1).css(),*/
					points: {show: false},
					paths: uPlot.paths.linear!(),
					/*paths:
						uiState.renderType == "bars" ? uPlot.paths.bars!() :
						//uiState.renderType == "bars" ? uPlot.paths.bars!({align: -1}) :
						uiState.renderType == "spline" ? uPlot.paths.spline!() :
						uiState.renderType == "linear" ? uPlot.paths.linear!() :
						uiState.renderType == "stepped" ? uPlot.paths.stepped!({align: -1}) :
						Assert(false, `Invalid render-type: ${uiState.renderType}`),*/
				} as uPlot.Series,
				/*groups.length == 1 && {
					stroke: Chroma("hsla(210,30%,90%,1)").alpha(.4).css(),
					fill: Chroma("hsla(210,30%,90%,1)").alpha(.3).css(),
				},*/
			));
			const yTypeHere = seriesYType as SamplesGraphYType;
			const yValues = GetYValuesForSamplesGraphYType(uiState, xTicks, xTicks_extendedForSmoothing, summarySamples_bufferOfLast60s, yTypeHere);
			uplotData.push(new Float64Array(yValues)); // array stores the y-values at each x-pos/session-number, for this scheme's line/series
		}

		const chartOptions = Samples_GetChartOptions({width, height, series, annotations, xValues: xTicks});
		Samples_PopulateAnnotationsForChart({width, height, chartOptions, annotations, xValues: xTicks});
		return (
			<Column style={{position: "relative", flex: 1, minHeight: 0}}>
				<div ref={rootRef as any} className="uplotHolder" style={ES({
					position: "relative", width: "100%",
					//height: "calc(100% - 53px)", // we need to cut off some height, for the legend
					//height: "100%",
					flex: 1, minHeight: 0,
				})}>
					{width != -1 &&
					<>
						<style>{`
						.u-legend { font-size: 12px; }
						.u-legend .hideLegend { display: none; }
						`}</style>
						<UPlot chartRef={this.chart} options={chartOptions} data={uplotData} ignoreDoubleClick={true}/>
					</>}
				</div>
			</Column>
		);
	}
}