import {Column, Row, Select, Text, TextArea} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../Store/index.js";
import {Observer} from "web-vcore";
import {EngineSessionInfo} from "../../../Store/firebase/sessions/@EngineSessionInfo";
import {GetAllFBASessions} from "../../../Engine/FBASession.js";
import {FBASession_Local} from "../../../Engine/FBASession_Local.js";

export type EvaluatorFunc = (sessionInfo: EngineSessionInfo)=>EvaluatorResult;
export type EvaluatorResult = {
	mainValue: number,
	mainValue_showAsPercent?: boolean,
	summary?: string,
	details?: string,
};
export class Evaluator {
	constructor(name: string, func: EvaluatorFunc) {
		this.name = name;
		this.func = func;
	}
	name: string;
	func: EvaluatorFunc;
}

export const evaluators: Evaluator[] = [
	new Evaluator("number remember rate", session=>{
		//const memoryPrompt = session.Comp(MemoryPromptComponent);
		const hits = session.events.filter(a=>a.type == "MemoryPrompt.TargetHit").length;
		const misses = session.events.filter(a=>a.type == "MemoryPrompt.TargetMiss").length;
		const giveUps = session.events.filter(a=>a.type == "MemoryPrompt.TargetGiveUp").length;
		return {
			mainValue: hits / (hits + giveUps).KeepAtLeast(1),
			mainValue_showAsPercent: true,
			summary: `Hits: ${hits} Misses: ${misses} GiveUps: ${giveUps}`,
		};
	}),
];

@Observer
export class SessionTimelinePanel extends BaseComponentPlus({}, {}) {
	render() {
		const {} = this.props;
		const tracker1 = store.main.tools.engine.liveFBASession_setAt; // review: not sure if this is needed...
		const allSessions = GetAllFBASessions().OrderBy(a=>a.coreData.startTime);
		const mostRecentSession = allSessions.LastOrX(a=>a instanceof FBASession_Local) as FBASession_Local;

		const sessionOptions = [mostRecentSession ? "Last session" : "(none)"];
		return (
			<Column>
				<Row mb={5}>
					<Select options={sessionOptions} value={sessionOptions[0]} onChange={()=>{}}/>
				</Row>
				{mostRecentSession && evaluators.map((evaluator, index)=>{
					const result = evaluator.func(mostRecentSession.GetSessionInfo()!);
					return (
						<Column sel key={index}>
							<Row>Evaluator name: {evaluator.name}</Row>
							<Row>Main value: {result.mainValue}{result.mainValue_showAsPercent && ` (${result.mainValue.ToPercentStr(2)})`}</Row>
							{result.summary != null &&
							<Row>Summary: {result.summary}</Row>}
							{result.details != null &&
							<Row>
								<Text>Details:</Text>
								<TextArea autoSize={true} value={result.details}/>
							</Row>}
						</Column>
					);
				})}
			</Column>
		);
	}
}