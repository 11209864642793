import {AddSchema} from "mobx-firelink";

export class FBAConfig_Camera {
	constructor(initialData: Partial<FBAConfig_Camera>) { this.VSet(initialData); }

	enabled: boolean;
	//savePath: string;
	preMotionBuffer: number;
	postMotionBuffer: number;
	//minVideoLength: number;
	maxVideoLength: number;

	camMotion_record: boolean;
	camMotion_pixelTrigger_minValueDelta: number;
	//recordMotion_frameTrigger_minTriggerPixelChainLength: number;
	camMotion_frameTrigger_minTriggerPixelCount: number;
	camMotion_motionTrigger_minTriggerFramePercent: number;
	camMotion_motionTrigger_windowDuration: number;

	eegMotion_record: boolean;
	eegMotion_minActivity: number;
}
AddSchema("FBAConfig_Camera", {
	properties: {
		enabled: {type: "boolean"},
		//savePath: {type: "string"},
		preMotionBuffer: {type: "number"},
		postMotionBuffer: {type: "number"},
		//minVideoLength: {type: "number"},
		maxVideoLength: {type: "number"},

		camMotion_record: {type: "boolean"},
		camMotion_pixelTrigger_minValueDelta: {type: "number"},
		//camMotion_frameTrigger_minTriggerPixelChainLength: {type: "number"},
		camMotion_frameTrigger_minTriggerPixelCount: {type: "number"},
		camMotion_motionTrigger_minTriggerFramePercent: {type: "number"},
		camMotion_motionTrigger_windowDuration: {type: "number"},

		eegMotion_record: {type: "boolean"},
		eegMotion_minActivity: {type: "number"},
	},
});