import {Button, Column, Row, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {GetShakes} from "../../Store/firebase/shakes";
import {MeID} from "../../Store/firebase/users";
import {ES} from "../../Utils/UI/GlobalStyles";
import {Observer, PageContainer} from "web-vcore";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel";
import {TagTargetEntryUI} from "../@Shared/TagTargetEntryUI";
import {ShowAddShakeDialog} from "./Shakes/ShakeDetailsUI";

export const columnWidths = [.25, .1, .1, .3, .25];

@Observer
export class ShakesUI extends BaseComponentPlus({}, {}) {
	render() {
		const shakes = GetShakes().OrderByDescending(a=>(a.creator == MeID() ? 1 : 0));

		return (
			<PageContainer style={{margin: "20px auto 20px auto", padding: 0}}>
				<Column className="clickThrough" style={{height: 80, borderRadius: "10px 10px 0 0"}}>
					<Row center style={{height: 40, padding: 10}}>
						<Button text="Add shake" ml="auto" onClick={()=>{
							if (MeID() == null) return ShowSignInPopup();
							ShowAddShakeDialog();
						}}/>
					</Row>
					<Row style={{height: 40, padding: 10}}>
						<Text style={{flex: columnWidths[0], fontWeight: 500, fontSize: 16}}>Name</Text>
						<Text style={{flex: columnWidths[1], fontWeight: 500, fontSize: 16}}>Type</Text>
						<Text style={{flex: columnWidths[2], fontWeight: 500, fontSize: 16}}>Creator</Text>
						<Text style={{flex: columnWidths[3], fontWeight: 500, fontSize: 16}}><Text>Tags (click tags to </Text><span style={{color: "rgba(100,200,100,.7)"}}>accept</span>)</Text>
						<Text style={{flex: columnWidths[4], fontWeight: 500, fontSize: 16}}></Text>
					</Row>
				</Column>
				<ScrollView style={ES({flex: 1})} contentStyle={ES({flex: 1})}>
					{shakes.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
					{shakes.map((shake, index)=>{
						return <TagTargetEntryUI key={index} group="shakes" entry={shake} index={index} last={index == shakes.length - 1}/>;
					})}
				</ScrollView>
			</PageContainer>
		);
	}
}