import React from "react";
import {Observer} from "web-vcore";
import {BaseComponent} from "react-vextensions";
import {liveSkin} from "../../Utils/Styles/StyleManager.js";

// This class is defined in user-project rather than web-vcore, so that it can use @Observer (ie. evaluate mobx-fields) while determining its final css.
@Observer
export class RootStyles extends BaseComponent<{}, {}> {
	ComponentWillMount() {
		const skin = liveSkin;
		skin.CSSHooks_Freeform();
	}
	loadingUI = ()=>null;
	render() {
		const skin = liveSkin;

		return (
			<style>{`
			${skin.RawCSS_ApplyScalarsAndStyles()}
			${skin.RawCSS_Freeform()}
			`}</style>
		);
	}
}