import {CallExtensionFunction} from "./ExtensionLink";

declare var FB;
export async function GetFBAccessToken() {
	return new Promise((resolve_, reject)=>{
		FB.getLoginStatus(response=>{
			if (response.status === "connected") {
				resolve(response.authResponse.accessToken);
			} else if (response.status === "not_authorized") {
				FB.login(response2=>{
					resolve(response2.authResponse.accessToken);
				//}, {scope: "publish_actions"});
				}, {});
			} else {
				FB.login(response2=>{
					resolve(response2.authResponse.accessToken);
				//}, {scope: "publish_actions"});
				}, {});
			}
		});

		function resolve(token) {
			console.log(`Token:${token}`);
			resolve_(token);
		}
	});
}

/*export async function PostImageToFacebook(token, filename, mimeType, imageDataBlob, message) {
	var fd = new FormData();
	fd.append("access_token", token);
	fd.append("source", imageDataBlob);
	//fd.append("message", "photo message for " + filename);
	fd.append("no_story", "true");
	//fd.append("privacy", "SELF");
	fd.append("published", "false");

	// Upload image to facebook without story(post to feed)
	let uploadPhotoResponse = await fetch("https://graph.facebook.com/me/photos?access_token=" + token, {
		method: "POST",
		body: fd,
		//processData: false,
		//contentType: false,
		cache: "no-store"
	} as any);
	var uploadPhotoResponse_json = FromJSON_Safe(await uploadPhotoResponse.text());
	console.log(`Uploaded photo "${filename}": `, uploadPhotoResponse_json, uploadPhotoResponse);

	let makePostResponse = await fetch("https://graph.facebook.com/v2.11/me/feed", {
		//async: true,
		//crossDomain: true,
		//credentials: "include", // fetch replacement for crossDomain (I think)
		method: "POST",
		cache: "no-store",
		headers: {
			//"cache-control": "no-cache",
			"content-type": "application/x-www-form-urlencoded"
		},
		body: JSON.stringify({
			"message": message,
			"attached_media[0]": `{"media_fbid":${uploadPhotoResponse_json.id}}`,
			//"attached_media[1]": `{"media_fbid":${uploadPhotoResponse2.id}}`,
			"access_token": token
		})
	} as any);
	let makePostResponse_json = FromJSON_Safe(await makePostResponse.text());
	console.log(`Made post: `, makePostResponse_json, makePostResponse);
}

function FromJSON_Safe(str: string) {
	try { return FromJSON(str); }
	catch (ex) { return str; }
}*/

export async function PostImageToFacebook(imageDataURI: string, message) {
	CallExtensionFunction("PostImageAndMessageToFacebook", imageDataURI, message);
}