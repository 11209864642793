import moment from "moment";
import React from "react";
import {Button, CheckBox, DropDown, DropDownContent, DropDownTrigger, Pre, Row, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {InfoButton, Observer, RunInAction_Set, TextPlus, VDateTime} from "web-vcore";
import {store} from "../../../../Store/index.js";
import {GraphRenderType_values, SmoothingType, StatsGrouping, StatsXType} from "../../../../Store/main/tools/journey.js";
import {zIndexes} from "../../../../Utils/UI/ZIndexes.js";
import {ScrollView} from "react-vscrollview";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";

@Observer
export class JourneyStatsSettingsDropdown extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey.stats;

		return (
			<DropDown style={{marginLeft: "auto", marginRight: 5, position: "relative"}}>
				<DropDownTrigger>
					<Button mdIcon="cog" size={32}/>
				</DropDownTrigger>
				<DropDownContent style={{
					zIndex: zIndexes.dropDown, position: "fixed", right: 0,
					top: window.innerWidth < 800 ? 60 : 32, // if screen-width <800px, setting-button probably got pushed to second row
					bottom: 0, // bottom:0 = stop at bottom of screen
					background: "rgba(0,0,0,.9)", borderRadius: 5,
				}}>
					<ScrollView style={{height: "100%"}}>
						<Row>
							<Text>Render type:</Text>
							<Select ml={5} options={GraphRenderType_values} value={uiState.renderType} onChange={val=>RunInAction_Set(this, ()=>uiState.renderType = val)}/>
						</Row>
						{uiState.renderType != "bars" &&
						<Row mt={5} center>
							<Pre>Smoothing:</Pre>
							<Spinner ml={5} min={0} max={100} value={uiState.smoothing} onChange={val=>RunInAction_Set(this, ()=>uiState.smoothing = val)}/>
							<CheckBox ml={5} text="Prev" value={uiState.smoothingType == SmoothingType.previous} onChange={val=>RunInAction_Set(this, ()=>uiState.smoothingType = val ? SmoothingType.previous : SmoothingType.centered)}/>
						</Row>}
						<Row mt={5} center>
							<TextPlus info={`
								What percentage of samples to keep: 0% = keep only median value, 100% = keep all (average)
								Note: This only applies to a *non-percentage* y-types with the "(typical)" marker. (ie. those using averaging)
							`.AsMultiline(0)}>Keep %:</TextPlus>
							<Spinner ml={5} min={0} max={100} step={5} enforceRange={true} instant style={{width: 50}}
								value={uiState.middleKeepPercent.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.middleKeepPercent = val.FromPercent())}/>
							<Text>%</Text>
						</Row>
						{uiState.xType == StatsXType.dayOffset && <>
							<Row mt={5} center>
								<Pre>Days:</Pre>
								<Spinner ml={5} min={1} value={uiState.daysToShow} onChange={val=>RunInAction_Set(this, ()=>uiState.daysToShow = val)}/>
								<Text ml={5}>(start: {moment().add(-uiState.daysToShow, "days").format("MMM D")})</Text>
							</Row>
							<Row mt={5} center>
								<Pre>Days, omit last X:</Pre>
								<Spinner ml={5} min={0} value={uiState.days_omitLastX} onChange={val=>RunInAction_Set(this, ()=>uiState.days_omitLastX = val)}/>
							</Row>
						</>}
						{uiState.xType == StatsXType.cycleInGroup &&
						<Row mt={5} center>
							<Pre>Cycles:</Pre>
							<Spinner ml={5} min={1} value={uiState.cyclesToShow} onChange={val=>RunInAction_Set(this, ()=>uiState.cyclesToShow = val)}/>
						</Row>}
						<CheckBox mt={5} text="Show lucid count" value={uiState.showLucidCount} onChange={val=>RunInAction_Set(this, ()=>uiState.showLucidCount = val)}/>
						<CheckBox mt={5} text="Show lucid rate" value={uiState.showLucidRate} onChange={val=>RunInAction_Set(this, ()=>uiState.showLucidRate = val)}/>
						<Row mt={5}>
							<CheckBox text="Normalize group metrics:" value={uiState.normalizeGroupMetrics} onChange={val=>RunInAction_Set(this, ()=>uiState.normalizeGroupMetrics = val)}/>
							<InfoButton ml={5} text={`
								Problem: When comparing alarm-delay groups, metrics for shorter-delays look worse due to hits being spread among more cycles.
								Solution: This normalizes certain metrics, making their values comparable for evaluating the "total of X" to expect per night.
								Application: These metrics are affected (if grouping is "Alarm delay"): Tags (typical), Words (typical), and lucidity-rate annotation
								Example: If standard alarm-delay is 80mins, a per-cycle lucidity-rate of 5% in a 40mins alarm-delay group gets normalized to 10% for display.
							`.AsMultiline(0)}/>
						</Row>
						{uiState.normalizeGroupMetrics &&
						<Row mt={5} ml={10}>
							<Text>Standard alarm delay:</Text>
							<Spinner ml={5} value={uiState.normalizeGroupMetrics_alarmDelay} onChange={val=>RunInAction_Set(this, ()=>uiState.normalizeGroupMetrics_alarmDelay = val)}/>
						</Row>}
						<Row mt={5}>
							<CheckBox text="Date range:" value={uiState.dateRange_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.dateRange_enabled = val)}/>
							<InfoButton ml={5} text={`
								Note that this also filters the pool used for applying the engine Journey component's "Option catch-up rate" system.
							`.AsMultiline(0)}/>
						</Row>
						{uiState.dateRange_enabled && <>
							<Row mt={5}>
								<Text mr={5} style={{flex: 1}}>From:</Text>
								<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{style: {width: 150}}}
									value={uiState.dateRange_min ? moment(uiState.dateRange_min) : null}
									onChange={val=>RunInAction_Set(this, ()=>uiState.dateRange_min = val?.valueOf() ?? null)}/>
							</Row>
							<Row mt={5}>
								<Text mr={5} style={{flex: 1}}>To:</Text>
								<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{style: {width: 150}}}
									value={uiState.dateRange_max ? moment(uiState.dateRange_max) : null}
									onChange={val=>RunInAction_Set(this, ()=>uiState.dateRange_max = val?.valueOf() ?? null)}/>
							</Row>
						</>}
						<Row>
							<CheckBox text="Date markers:" value={uiState.showDateMarkers} onChange={val=>RunInAction_Set(this, ()=>uiState.showDateMarkers = val)}/>
							<Button ml={5} mdIcon="plus" onClick={()=>RunInAction_Set(this, ()=>uiState.dateMarkers.push(new Date().setHours(0, 0, 0, 0)))}/>
						</Row>
						{uiState.showDateMarkers && uiState.dateMarkers.map((date, index)=>{
							return (
								<Row key={index} mt={5}>
									<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{style: {width: 150}}}
										value={date ? moment(date) : null}
										onChange={val=>RunInAction_Set(this, ()=>uiState.dateMarkers[index] = val?.valueOf() ?? null)}/>
									<Button ml={5} mdIcon="delete" onClick={()=>RunInAction_Set(this, ()=>uiState.dateMarkers.RemoveAt(index))}/>
								</Row>
							);
						})}
						<Row>
							<Text>Group excludes:</Text>
							<Button ml={5} mdIcon="plus" onClick={()=>RunInAction_Set(this, ()=>uiState.groupExcludes.push(""))}/>
						</Row>
						{uiState.groupExcludes.map((group, index)=>{
							return (
								<Row key={index} mt={5}>
									<TextInput style={{flex: 1, width: 100}} value={group} onChange={val=>RunInAction_Set(this, ()=>uiState.groupExcludes[index] = val)}/>
									<Button ml={5} mdIcon="delete" onClick={()=>RunInAction_Set(this, ()=>uiState.groupExcludes.RemoveAt(index))}/>
								</Row>
							);
						})}
						<Row mt={5}>Label overrides:</Row>
						<Row mt={5}>
							<Text>Y-axis:</Text>
							<TextInput ml={5} style={{flex: 1}} value={uiState.yAxis_labelOverride} onChange={val=>RunInAction_Set(this, ()=>uiState.yAxis_labelOverride = val)}/>
						</Row>
						<Row mt={5}>
							<Text>X-axis:</Text>
							<TextInput ml={5} style={{flex: 1}} value={uiState.xAxis_labelOverride} onChange={val=>RunInAction_Set(this, ()=>uiState.xAxis_labelOverride = val)}/>
						</Row>
					</ScrollView>
				</DropDownContent>
			</DropDown>
		);
	}
}