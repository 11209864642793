import {AddSchema, GetDoc, GetDocs, StoreAccessor, WhereOp} from "mobx-firelink";
import {emptyArray} from "js-vextensions";

export const GetStory = StoreAccessor(s=>(id: string|n): Story|n=>{
	return GetDoc({}, a=>a.stories.get(id!));
});
export const GetStories = StoreAccessor(s=>(userID: string|n): Story[]=>{
	if (userID == null) return emptyArray; // if we aren't signed-in, don't even try to make request (the security-rules will make it fail)
	return GetDocs({
		queryOps: [new WhereOp("creator", "==", userID)],
	}, a=>a.stories);
});

export class Story {
	constructor(data?: Partial<Story>) { Object.assign(this, data); }
	_key: string;
	creator: string;
	createdAt: number;
	
	title = "<unnamed>";
	//startMessageIds: string[];
}
AddSchema("Story", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},

		title: {type: "string"},
		//startMessageIds: {items: {type: "string"}},
	},
	//required: [...],
});