import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {FBAConfig_XAlarm, FBAConfig_XAlarm_schema} from "./@EC_Alarms.js";
import {AlarmSequence} from "./Alarms/@AlarmConfig.js";

export enum EEGChannel {
	LeftAndRight_Distance = 10,
	LeftAndRight_MirrorAmount = 20,
	LeftAndRight_Min = 30,
	LeftAndRight_Max = 40,
	Left = 100,
	Right = 110,
}
AddSchema("EEGChannel", {oneOf: GetValues_ForSchema(EEGChannel)});
export enum ActivityToIntensityBasis {
	Linear = 10,
	//Curve = 20,
	//Function = 30,
}
AddSchema("ActivityToIntensityBasis", {oneOf: GetValues_ForSchema(ActivityToIntensityBasis)});

export const AbsOrRel_values = ["absolute", "relative"] as const;
export type AbsOrRel = typeof AbsOrRel_values[number];
AddSchema("AbsOrRel", {oneOf: AbsOrRel_values.map(a=>({const: a}))});

export class FBAConfig_EEG {
	constructor(initialData: Partial<FBAConfig_EEG>) { this.VSet(initialData); }

	enabled: boolean;
	useSampleRounding: boolean;

	recordData_raw: boolean;
	recordData_extended: boolean;
	recordData_activity: boolean;

	detectMotion: boolean;
	//motion_enabled: boolean;

	motion_sampleTrigger_normalizationWindowSize: number;
	motion_sampleTrigger_smoothing: number;
	motion_eegPatterns: EEGPattern[];

	motion_maxActivity: number;
	motion_activityDecayAmount: number;
	motion_activityDecayInterval: number;
	motion_activityDecay_motionTriggersInterrupt: boolean;
	motion_activityReset_to: number;
	motion_activityReset_at: number;

	//motion_prompts_enabled: boolean;
	//motion_prompts_intensityBasis: ActivityToIntensityBasis;
	alarmSequence: AlarmSequence;

	/*motion_resetActivity_triggerSet: TriggerSet;
	motion_snoozePrompts_triggerSet: TriggerSet;
	motion_snoozePrompts_duration: number;*/

	/*increaseMinDeviation_triggerSet: TriggerSet;
	decreaseMinDeviation_triggerSet: TriggerSet;
	changeMinDeviation_amount: number;
	increaseMinPercentOfWindow_triggerSet: TriggerSet;
	decreaseMinPercentOfWindow_triggerSet: TriggerSet;
	changeMinPercentOfWindow_amount: number;*/
}
AddSchema("FBAConfig_EEG", {
	properties: {
		enabled: {type: "boolean"},
		useSampleRounding: {type: "boolean"},

		recordData_raw: {type: "boolean"},
		recordData_extended: {type: "boolean"},
		recordData_activity: {type: "boolean"},

		detectMotion: {type: "boolean"},

		motion_sampleTrigger_normalizationWindowSize: {type: "number"},
		motion_sampleTrigger_smoothing: {type: "number"},
		motion_eegPatterns: {items: {$ref: "EEGPattern"}},

		motion_maxActivity: {type: "number"},
		motion_activityDecayAmount: {type: "number"},
		motion_activityDecayInterval: {type: "number"},
		motion_activityDecay_motionTriggersInterrupt: {type: "boolean"},
		motion_activityReset_at: {type: "number"},
		motion_activityReset_to: {type: "number"},

		//motion_prompts_enabled: {type: "boolean"},
		alarmSequence: {$ref: "AlarmSequence"},

		/*motion_resetActivity_triggerSet: {$ref: "TriggerSet"},
		motion_snoozePrompts_triggerSet: {$ref: "TriggerSet"},
		motion_snoozePrompts_duration: {type: "number"},*/
	},
});

export class EEGPattern {
	constructor(initialData: Partial<EEGPattern>) { this.VSet(initialData); }

	enabled: boolean;

	width_min?: number|n; // in seconds
	width_max?: number|n; // in seconds
	height_min?: number|n; // in eeg-value
	height_max?: number|n; // in eeg-value
	area_min?: number|n;
	area_max?: number|n;

	maxDistFromBaseline?: number|n; // in eeg-value

	activityIncrease: number;
}
AddSchema("EEGPattern", {
	properties: {
		enabled: {type: "boolean"},

		width_min: {type: ["null", "number"]},
		width_max: {type: ["null", "number"]},
		height_min: {type: ["null", "number"]},
		height_max: {type: ["null", "number"]},
		area_min: {type: ["null", "number"]},
		area_max: {type: ["null", "number"]},

		maxDistFromBaseline: {type: ["null", "number"]},

		activityIncrease: {type: "number"},
	},
});