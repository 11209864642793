import React from "react";
import {GetVoices, InfoButton, Observer, RunInAction_Set} from "web-vcore";
import {Button, CheckBox, Column, Row, Select, Spinner, Text, TextArea} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../../Store/index.js";
import {InAndroid, nativeBridge} from "../../../../Utils/Bridge/Bridge_Native.js";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";

@Observer
export class SettingsUI_Info extends BaseComponentPlus({}, {memoryInfo: null as Object|n}) {
	render() {
		const {memoryInfo} = this.state;
		const uiState = store.main.settings;

		return (
			<>
				<Row mt={5}>
					<Button text="Refresh" onClick={async()=>{
						const memoryInfo = (await nativeBridge.Call("GetMemoryInfo")) as Object;
						this.SetState({memoryInfo});
					}}/>
				</Row>
				{memoryInfo != null &&
				<Row>
					<TextArea autoSize={true} editable={false} value={JSON.stringify(memoryInfo, null, "\t")}/>
				</Row>}
			</>
		);
	}
}