import React from "react";
import {Row} from "react-vcomponents";
import {Observer, PageContainer} from "web-vcore";
import {E} from "js-vextensions";
import {BaseComponent} from "react-vextensions";
import {store} from "../../Store/index.js";
import {JourneyTab} from "../../Store/main/tools/journey.js";
import {useExpandedNavBar} from "../@Shared/NavBar";
import {SessionLogPanel} from "./@Shared/BetweenSessionTypes/SessionLogPanel.js";
import {FBAConfigPanel_Local} from "./Engine/FBAConfigPanel_Local.js";
import {JourneyStatsUI} from "./Journey/JourneyStatsUI.js";
import {JSessionUI} from "./Journey/JSessionUI.js";

//export const journeyPanelHeights = [0, 50, 50, 0];

@Observer
export class JourneyUI extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.journey;
		const largeVersion = useExpandedNavBar();

		// engine tab replaces the rest of the UI
		if (uiState.tab == JourneyTab.engine) {
			return <JSessionUI/>;
		}

		return (
			<PageContainer preset="text" style={E(
				{padding: 0, height: "100%"},
				!largeVersion && {margin: "0 auto 0 auto"},
			)}>
				{/*<Row>
					{/*<Button text="History" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.history)}/> *#/}
					<Button text="Config" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.config)}/> 
					<Button ml={5} text="Engine" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.engine)}/> 
					<Button ml={5} text="Log" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.log)}/> 
					<Button ml={5} text="Stats" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.stats)}/> 
				</Row>*/}
				{uiState.tab == JourneyTab.history && <JourneyHistoryUI/>}
				{uiState.tab == JourneyTab.stats && <JourneyStatsUI/>}
				{uiState.tab == JourneyTab.config && <FBAConfigPanel_Local journeyUI={true}/>}
				{uiState.tab == JourneyTab.log && <SessionLogPanel/>}
				{/*uiState.tab == JourneyTab.engine && <JSessionUI/>*/}
			</PageContainer>
		);
	}
}

class JourneyHistoryUI extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		return (
			<Row>
			</Row>
		);
	}
}