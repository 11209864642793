import {ModifyString, SleepAsync, WaitXThenRun} from "js-vextensions";
import {TextSpeaker} from "web-vcore";
import {DreamRecallComp} from "../../Engine/FBASession/Components/DreamRecallComp.js";
import {EngineSessionComp} from "../../Engine/FBASession/Components/EngineSessionComp.js";
import {Sound, SoundType} from "../../Store/firebase/sounds/@Sound.js";
import {GetUserEntityTags, MeID} from "../../Store/firebase/users.js";
import {store} from "../../Store/index.js";
import {InAndroid, nativeBridge} from "../Bridge/Bridge_Native.js";
import {AssertNotify} from "../General/General.js";

export async function NarrateText_ForEngineComp(comp: EngineSessionComp, text: string, volumeMultiplier_preGlobalMultiplier: number, voiceSoundTagFromComp?: string) {
	const dreamRecallComp = comp.s.Comp(DreamRecallComp);
	const findSoundByTag = (tag: string|n)=>{
		if (tag == null || tag.trim().length == 0) return null;
		return dreamRecallComp.sounds_all.filter(a=>GetUserEntityTags(MeID(), "sounds", a._key)?.includes(tag)).Random();
	};
	let sound = findSoundByTag(voiceSoundTagFromComp) ?? findSoundByTag(comp.s.c.general.defaultVoice_soundTag);
	const volumeMultiplier_final = volumeMultiplier_preGlobalMultiplier * comp.s.GetSettingValue(c=>c.general.globalVolumeMultiplier);
	return await NarrateText(text, dreamRecallComp.textSpeaker, volumeMultiplier_final, sound);
}

export async function NarrateText(text: string, textSpeaker: TextSpeaker, volumeMultiplier = 1, sound?: Sound|n, opt?: {
	onUtteranceStart?: ()=>void
	onUtteranceInterrupted_effect?: "resolve" | "reject"
}) {
	// if volume is 0 anyway, don't proceed with the voicing; this way, "silent" voicings don't interrupt audible ones
	if (volumeMultiplier == 0) return;
	
	if (sound == null) {
		sound = new Sound({
			type: SoundType.Speech,
			pitch: 1,
			volume: 1,
			speed: 1,
		});
		// on android, slow down (the fallback) voice speed by a lot (android's TTS engine speaks very fast at speed=1)
		/*if (InAndroid(0)) {
			sound.speed = .6;
		} else {
			// on web-api, slow it down some as well (speed=1 is not as fast as android's TTS engine, but still faster than generally desired)
			sound.speed = .9;
		}*/
	}
	const finalVolume = sound.volume * volumeMultiplier;
	const finalSpeed = sound.speed * store.main.settings.audio.voiceSpeedMultiplier;

	// var named this way, on the assumption the api's "volume" represents energy (eg. loudness^2) rather than perceptual-loudness
	const finalVolume_asEnergy = Math.pow(finalVolume, store.main.settings.audio.loudnessCurve_systemTTS);

	// if web-api for speech-synthesis is supported, use it
	if (g.speechSynthesis != null) {
		try {
			await textSpeaker.Speak({text, voice: sound.voice, volume: finalVolume_asEnergy, rate: finalSpeed, pitch: sound.pitch});
		} catch (ex) {
			// don't show an error-message for these normal/expected cases (at least the "canceled" event is normal/expected)
			if (ex instanceof SpeechSynthesisErrorEvent && (ex.error == "canceled" || ex.error == "interrupted")) return;
			throw ex;
		}
	}
	// else, if on Android, use Android's TTS
	else if (InAndroid(0)) {
		//nativeBridge.Call("SpeakText", text, sound.voice, finalVolume_asEnergy, sound.speed, sound.pitch);
		const utteranceID = NewUtteranceID(text);
		/*await*/ nativeBridge.Call("SpeakText", text, finalVolume_asEnergy, finalSpeed, utteranceID);

		let promiseFuncs: {resolve: ()=>void, reject: ()=>void}|n;
		const meta = new UtteranceMeta({
			id: utteranceID,
			text,
			onStart: opt?.onUtteranceStart,
			// We early-set these handlers, so if they get called synchronously (prior to the promise below being constructed), the promise still ends up able to resolve/reject based on the call.
			// (This can happen, for example, if an OnUtteranceStart event comes in prior to )
			onDone: async()=>{
				while (promiseFuncs == null) { await SleepAsync(10); }
				promiseFuncs.resolve();
			},
			onError: async()=>{
				while (promiseFuncs == null) { await SleepAsync(10); }
				promiseFuncs.reject();
			},
			onInterrupted: async()=>{
				while (promiseFuncs == null) { await SleepAsync(10); }
				// When interrupted, default to resolving the promise. (Some callers may want an error, others a [quick returning] success -- but I think the latter is more common.)
				if (opt?.onUtteranceInterrupted_effect == "reject") {
					promiseFuncs.reject();
				} else {
					promiseFuncs.resolve();
				}
			},
		});
		utteranceMetas.set(utteranceID, meta);

		// defensive; after 60s, if the utterance has not reached an end, assume it's stuck and mark it as interrupted
		WaitXThenRun(60 * 1000, ()=>{
			if (meta.doneTime == null && meta.errorTime == null && meta.interruptedTime == null) {
				meta.MarkInterrupted();
				AssertNotify(false, `TTS utterance seemingly stuck after 30s; marking as interrupted. @id:${utteranceID} @text:${text}`);
			}
		});

		return new Promise<void>((resolve, reject)=>{
			promiseFuncs = {resolve, reject};
		});
	}
}

export function NewUtteranceID(text: string, startTime = Date.now()) {
	const hashCode = s=>s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
	return `${startTime}_${hashCode(text)}`;
}

class UtteranceMeta {
	constructor(data: RequiredBy<Partial<UtteranceMeta>, "id" | "text">) { Object.assign(this, data); }

	id: string;
	text: string;
	onStart?: ()=>void;
	onDone?: ()=>void;
	onError?: ()=>void;
	onInterrupted?: ()=>void; // custom (info-signaling workaround, for Android TTS not calling onDone/onError for earlier utterances when a new one is started/replaces-them)

	creationTime = Date.now();
	startTime?: number;
	doneTime?: number;
	errorTime?: number;
	interruptedTime?: number; // custom

	MarkStart(timestamp = Date.now()) {
		this.startTime = timestamp;
		this.onStart?.();

		// Issue: The Android TTS engine (at least how I currently use it) has new utterances simply replace any existing utterances, but WITHOUT those earlier utterances getting their onDone or onError event called.
		// Fix: When onUtteranceStart occurs for a new utterance, find the metadata we have stored for any earlier utterances, and mark their interruptedTime as now. (clarifying that the lack of a doneTime/errorTime doesn't mean its still running)
		const utteranceList = Array.from(utteranceMetas.values());
		const selfIndex = utteranceList.indexOf(this);
		if (selfIndex != -1) {
			const earlierUtterances = utteranceList.slice(0, selfIndex);
			for (const otherMeta of earlierUtterances) {
				// if utterance "looks like" its still running (but we know it's not, since we're the newest utterance to start), mark it as interrupted
				if (/*otherMeta.startTime != null &&*/ otherMeta.doneTime == null && otherMeta.errorTime == null && otherMeta.interruptedTime == null) {
					otherMeta.MarkInterrupted(timestamp);
				}
			}
		}
	}
	MarkDone(timestamp = Date.now()) {
		this.doneTime = timestamp;
		this.onDone?.();
		DeleteUtteranceMetaOnceSafe(this.id);
	}
	MarkError(timestamp = Date.now()) {
		this.errorTime = timestamp;
		this.onError?.();
		DeleteUtteranceMetaOnceSafe(this.id);
	}
	MarkInterrupted(timestamp = Date.now()) {
		this.interruptedTime = timestamp;
		this.onInterrupted?.();
		DeleteUtteranceMetaOnceSafe(this.id);
	}
}

export const utteranceMetas = new Map<string, UtteranceMeta>();
function DeleteUtteranceMetaOnceSafe(utteranceID: string) {
	// To be safe, wait 30+10 seconds after avoid-transcribe-buffer logic is done with this utterance, then delete the entry for it.
	// * 30s: How long the audio-recorder buffer can get before sending audio for whisper-transcription.
	// * 15s: Extra padding, to account for other possible delays. (eg. time for whisper-api to processing the audio chunk)
	WaitXThenRun((store.main.settings.transcribe.voice_avoidTranscribePadding * 1000) + 45000, ()=>{
		utteranceMetas.delete(utteranceID);
	});
}

nativeBridge.RegisterFunction("OnUtteranceStart", (utteranceID: string)=>{
	utteranceMetas.get(utteranceID)?.MarkStart();
});
nativeBridge.RegisterFunction("OnUtteranceDone", (utteranceID: string)=>{
	utteranceMetas.get(utteranceID)?.MarkDone();
});
nativeBridge.RegisterFunction("OnUtteranceError", (utteranceID: string)=>{
	utteranceMetas.get(utteranceID)?.MarkError();
});

// utterance-meta register/unregister from external/non-tts sources
// ==========

/*export function NotifyNonTTSUtteranceRange_FromSoundFile(filePath: string, wordsIgnoreGroup: number|n, startTime: number, endTime = Date.now()) {
	if (wordsIgnoreGroup == null) return null;
	const utteranceID = NotifyNonTTSUtteranceStart_FromSoundFile(filePath, wordsIgnoreGroup, startTime);
	return NotifyNonTTSUtteranceEnd(utteranceID!, endTime);
}*/
export function NotifyNonTTSUtteranceStart_FromSoundFile(filePath: string, wordsIgnoreGroup: number|n, startTime = Date.now()) {
	if (wordsIgnoreGroup == null) return null;
	const wordsIgnoreTextFromFilePath = filePath.replace(/\.[^._]$/, "").split("_")[wordsIgnoreGroup - 1] as string|n;
	const textToIgnore_normalized = wordsIgnoreTextFromFilePath == null ? "" : ModifyString(wordsIgnoreTextFromFilePath, m=>[m.lowerUpper_to_lowerSpaceLower]);
	return NotifyNonTTSUtteranceStart(textToIgnore_normalized, startTime);
}

export function NotifyNonTTSUtteranceStart(text: string, startTime = Date.now()) {
	const utteranceID = NewUtteranceID(text, startTime);
	const meta = new UtteranceMeta({id: utteranceID, text});
	utteranceMetas.set(utteranceID, meta);
	meta.MarkStart(startTime);
	return utteranceID;
}
export function NotifyNonTTSUtteranceEnd(id: string, endTime = Date.now()) {
	utteranceMetas.get(id)?.MarkDone(endTime);
}