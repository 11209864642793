import {Assert} from "js-vextensions";
import {StoreAccessor} from "mobx-firelink";
import {ignore} from "mobx-sync";
import {JournalState as JournalState} from "../Store/main/journal";
import {ToolsState} from "../Store/main/tools";
import {O} from "web-vcore";
import {pages} from "../Utils/URL/URLs";
import {TimelineState} from "./main/timeline";
import {UsersState} from "./main/users";
import {EffectsState} from "./main/effects";
import {TestingState} from "./main/testing";
import {makeObservable} from "mobx";
import {ContentState} from "./main/content.js";
import {DNDState} from "../UI/@Root/RootDragHandler.js";
import {SettingsState} from "./main/settings.js";
import {CacheState} from "./main/cache.js";

// class is used only for initialization
export class MainState {
	constructor() { makeObservable(this); }
	// [immerable] = true;

	@O page = "home";
	@O urlExtraStr: string|n;

	@O lastDBVersion: number; // tracks the last db-version the client started with, so we can know when we need to upgrade the store-data
	@O envOverride: string|n;
	@O dbOverride: string|n;
	@O dbVersionOverride: string|n;

	@O analyticsEnabled = true;
	// topLeftOpenPanel: string;
	// topRightOpenPanel: string;
	@O lastPageReloadIncompatibleAction: string|n; // gets read then cleared at app launch
	@O dnd = new DNDState();
	@O cache = new CacheState();

	// pages (and nav-bar panels)
	// ==========

	@O testing = new TestingState();

	@O users = new UsersState();
	@O feedback = {} as {subpage: string};
	//@O forum = new ForumState();
	@O forum = {} as {subpage: string};
	//@O social = {} as {subpage: string};
	@O timeline = new TimelineState();
	@O more = {} as {subpage: string};
	@O home = {} as {subpage: string};
	@O journal = new JournalState();
	@O effects = new EffectsState();
	@O content = new ContentState();
	@O tools = new ToolsState();

	@O settings = new SettingsState();
	@O profile = {} as {subpage: string};

	@O @ignore topLeftOpenPanel: string|n;
	@O @ignore topRightOpenPanel: string|n;
}

// export type PageKey = "home" | ""
export const GetPage = StoreAccessor(s=>()=>{
	return s.main.page || "home";
});
export const GetSubpage = StoreAccessor(s=>()=>{
	const page = GetPage();
	Assert(s.main[page] && pages[page], `Cannot find state/info for page: ${page}`);
	return s.main[page].subpage as string || pages[page].DefaultChild;
});