import {ToNumber, Vector2, Timer, VRect, Range} from "js-vextensions";
import moment from "moment";
import {useMemo, useEffect} from "react";
import {UPlot} from "react-uplot";
import {Row, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowVMenu, VMenuItem} from "react-vmenu";
import {ShowMessageBox} from "react-vmessagebox";
import {UpdateTestSegment} from "../../../../Server/Commands/UpdateTestSegment.js";
import {store} from "../../../../Store/index.js";
import {GetSelectedFBAConfig} from "../../../../Store/firebase/fbaConfigs.js";
import {EyeMoveDeclaration, EyeMoveDeclarationType, TestSegment} from "../../../../Store/firebase/testSegments/@TestSegment.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {IsUserCreatorOrMod} from "../../../../Store/firebase/users/$user.js";
import uPlot from "uplot";
import {Annotation, AnnotationsPlugin} from "uplot-vplugins";
import useResizeObserver from "use-resize-observer";
import {styles} from "../../../../Utils/UI/GlobalStyles.js";
import {createRef_withHooks} from "../../../../Utils/UI/ReactHelpers.js";
import {uplotDefaults} from "../../../../Utils/UI/UPlotDefaults.js";
import {HSL, HSLA, Observer} from "web-vcore";
import {FindIndex_BinarySearch} from "../../../../Utils/General/General.js";
import {sessionChartColors} from "../../../../UI/Timeline/Sessions/SessionUI/SessionChartColors.js";
import {EEGProcessor} from "../../Processors/EEGProcessor.js";
import {Detection_ForUI, TestResult} from "../../Processors/TestSegmentRunner.js";
import {DetectionInfoUI} from "./TestSegmentUI.js";

const lineTypes: (uPlot.Series & {key: string})[] = [
	{} as any, // time
	{
		label: "Left",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_left,
		points: {show: false},
	},
	{
		label: "Right",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_right,
		points: {show: false},
	},
];

@Observer
export class SegmentChart extends BaseComponentPlus({} as {segment: TestSegment, processor: EEGProcessor, testResult: TestResult|n}, {hoveredDetection: null as Detection_ForUI|n}) {
	render() {
		const {segment, processor, testResult} = this.props;
		const {hoveredDetection} = this.state;
		const uiState = store.main.testing;
		const config = GetSelectedFBAConfig();
		if (config == null) return;

		let {callbackRef: rootRef, width = 300, height = 100} = useResizeObserver();
		if (width < 300) width = 300; // temp fix for crash issue (see: https://github.com/leeoniya/uPlot/issues/360)
		//const secondTimes = segment.channelSamples.eeg_left.VKeys().map(a=>ToNumber(a));
		//const secondTimes = segment.channelSamples.eeg_left.VKeys().map(a=>ToNumber(a));
		/*const secondTimes = [...new Set(processor.samples_time.map(a=>(a * 1000).FloorTo(1000)))];
		const rangeStart = secondTimes.Min();*/
		const rangeStart = segment.startTime;
		const rangeEnd_standard = rangeStart + (uiState.xRange * 1000); // try to have a consistent time-range (for easy comparison between entries)
		//const rangeEnd_final = Math.max(rangeEnd_default, secondTimes.Max() + 1000);
		const rangeEnd_final = Math.max(rangeEnd_standard, segment.endTime);
		//const secondTimes = Range(rangeStart.FloorTo(1000), rangeEnd_final.CeilingTo(1000), 1000);
		const splitTimes = Range(rangeStart, rangeEnd_final.CeilingTo(1000), 1000, true, null as any); // add a split 1s after range-start, and every 1s after (while within range)

		const annotations = useMemo(()=>{
			return [
				{
					type: "line",
					y: {value: 0},
					color: sessionChartColors.baseline,
					lineWidth: 1,
					drawType: "destination-over",
				},
				...segment.eyeMoveDeclarations.map(declaration=>{
					return {
						type: "line",
						x: {value: declaration.time / 1000},
						color: declaration.type == EyeMoveDeclarationType.Required ? sessionChartColors.eyeMoveDeclaration_required : sessionChartColors.eyeMoveDeclaration_optional,
						lineWidth: 1,
					} as const;
				}),
				...(!uiState.showDetections || testResult == null ? [] : testResult.detections.map(det=>{
					return {
						type: "box",
						xMin: {value: det.startTime / 1000},
						xMax: {value: det.endTime / 1000},
						yMin: {pixel: "0%"},
						yMax: {pixel: "100%"},
						fillStyle: det.matchingDeclarations > 0 ? sessionChartColors.eyeMoveDetection_passed : sessionChartColors.eyeMoveDetection_failed,
						drawType: "destination-over",
					};
				})),
			] as Annotation[];
		}, [segment.eyeMoveDeclarations, testResult, uiState.showDetections]);

		const chartOptions: uPlot.Options = useMemo(()=>{
			return {
				id: `SegmentChart_${segment._key}`,
				class: "eeg-chart",
				width,
				height: 201, // add one pixel, so that y-value of 0 is at exact point! (not X.5)
				plugins: [
					AnnotationsPlugin({
						annotations,
					}),
				],
				cursor: {
					drag: {x: false, setScale: false},
				},
				legend: {show: false},
				axes: [
					{
						...uplotDefaults.axes_props,
						size: 30,
						values: (self, vals)=>vals.map(a=>`${(a - (rangeStart / 1000)).toFixed(0)}`),
						incrs: [1, 2, 5, 10],
						//splits: [1, 2, 5, 10],
						splits: splitTimes.map(a=>a / 1000),
					},
					{
						...uplotDefaults.axes_props,
						size: 35,
					},
				],
				scales: {
					x: {
						//time: false
						auto: false,
						min: rangeStart / 1000,
						max: rangeEnd_final / 1000,
					},
					y: {
						auto: false,
						range: (u, dataMin, dataMax)=>{ // use func, since stable
							//return [-100, 100] as [number, number];
							/*const maxExtent = Math.max(Math.abs(dataMin), Math.abs(dataMax));
							return [-maxExtent, maxExtent] as [number, number];*/
							return [-uiState.yRange, uiState.yRange] as [number, number];
						},
					},
				},
				series: lineTypes,
			};
		}, [segment._key, width, annotations, splitTimes, rangeStart, rangeEnd_final, uiState.yRange]);

		const {timeData, leftData, rightData, uplotData} = useMemo(()=>{
			const timeData = [] as number[];
			const leftData = [] as number[];
			const rightData = [] as number[];
			const uplotData = [
				timeData,
				leftData,
				rightData,
			] as uPlot.AlignedData;
			/*const samplesInNormWindowOtherThanFirstSample = processor.SamplesPerNormalizationWindow - 1; // exclude first-sample itself, since already in realSamples array
			for (let i = samplesInNormWindowOtherThanFirstSample; i < processor.SampleCount; i++) {*/
			//for (let i = 0; i < processor.SampleCount; i++) {
			/*const firstBufferSampleI = processor.SamplesPerNormalizationWindow; // exclude fake-samples; first one after is the first buffer-sample
			const firstRealSampleI = firstBufferSampleI +; // exclude fake-samples; first one after is the first buffer-sample*/

			//const firstRealSampleI = processor.GetSampleIndexForTime(segment.startTime);
			const firstRealAndNonBufferSampleI = FindIndex_BinarySearch(processor.samples_time, time_uplot=>time_uplot * 1000 <= segment.startTime, false)!;

			for (let i = firstRealAndNonBufferSampleI; i < processor.SampleCount; i++) {
				timeData.push(processor.samples_time[i]);
				/*leftData.push(samples[i].left);
				rightData.push(samples[i].right);*/
				leftData.push(processor.samples_left_display[i]);
				rightData.push(processor.samples_right_display[i]);
			}
			return {timeData, leftData, rightData, uplotData};
		}, [processor.SampleCount, processor.samples_left_display, processor.samples_right_display, processor.samples_time]);

		useEffect(()=>{
			if (hoveredDetection == null) return;
			const checkStillHoveredTimer = new Timer(100, ()=>{
				if (this.lastDoubleClickStartDiv == null || hoveredDetection == null) return;
				if (!document.body.contains(this.lastDoubleClickStartDiv)) {
					this.SetState({hoveredDetection: null});
				} else if (!VRect.FromLTWH(this.lastDoubleClickStartDiv.getBoundingClientRect()).Intersects(new VRect(g.mousePos as Vector2, Vector2.one))) {
					this.SetState({hoveredDetection: null});
				}
			}).Start();
			return ()=>checkStillHoveredTimer.Stop();
		}, [hoveredDetection]);

		return (
			<>
				<div ref={rootRef as any} style={{position: "relative", width: "100%"}}>
					<UPlot chartRef={this.chart} options={chartOptions} data={uplotData}/>
				</div>
				{hoveredDetection &&
				<Row style={{height: 18}}>
					<Text>Hovered shape... </Text>
					<DetectionInfoUI detection={hoveredDetection} rangeStart={rangeStart}/>
				</Row>}
			</>
		);
	}
	lastDoubleClickStartDiv: HTMLDivElement;
	chart = createRef_withHooks<uPlot>({
		postSet: chart=>{
			if (chart == null) return;
			const uplotDiv = chart.root as HTMLDivElement;
			const doubleClickStartDiv = uplotDiv.querySelector(".u-over") as HTMLDivElement;
			// prevent uplot's double-click-to-reset-zoom behavior
			uplotDiv.addEventListener("dblclick", e=>{
				e.stopPropagation();
			}, {capture: true});

			this.lastDoubleClickStartDiv = doubleClickStartDiv;
			doubleClickStartDiv.addEventListener("mousemove", e=>{
				const {segment, processor, testResult} = this.props;
				if (testResult == null) return;
				const hoveredTime = chart.posToVal(e.offsetX, "x") * 1000;
				const hoveredDetection = testResult.possibleDetections.find(a=>hoveredTime.IsBetween(a.startTime, a.endTime));
				this.SetState({hoveredDetection});
			});
			doubleClickStartDiv.addEventListener("mouseleave", e=>{
				this.SetState({hoveredDetection: null});
			});
			doubleClickStartDiv.addEventListener("click", e=>{
				const {segment} = this.props;
				if (!IsUserCreatorOrMod(MeID(), segment)) return;
				const clickedTime = chart.posToVal(e.offsetX, "x") * 1000;
				if (e.button == 0) {
					const newDeclaration = new EyeMoveDeclaration({type: EyeMoveDeclarationType.Required, time: clickedTime});
					const newEyeMoveDeclarations = segment.eyeMoveDeclarations.concat(newDeclaration).OrderBy(a=>a.time);
					new UpdateTestSegment({
						id: segment._key,
						updates: {eyeMoveDeclarations: newEyeMoveDeclarations},
					}).Run();
				}
			});
			doubleClickStartDiv.addEventListener("contextmenu", e=>{
				const {segment} = this.props;
				if (!IsUserCreatorOrMod(MeID(), segment)) return;
				const clickedTime = chart.posToVal(e.offsetX, "x") * 1000;

				const closestDeclaration = segment.eyeMoveDeclarations.OrderBy(a=>a.time.Distance(clickedTime))[0];
				if (closestDeclaration == null) return;

				function SetEyeMoveType(type: EyeMoveDeclarationType) {
					const newDeclaration = {...closestDeclaration, type};
					const newEyeMoveDeclarations = segment.eyeMoveDeclarations.Exclude(closestDeclaration).concat(newDeclaration).OrderBy(a=>a.time);
					new UpdateTestSegment({
						id: segment._key,
						updates: {eyeMoveDeclarations: newEyeMoveDeclarations},
					}).Run();
				}
				const menuUI = (
					<>
						<VMenuItem text="Set type: required" enabled={closestDeclaration.type != EyeMoveDeclarationType.Required} style={styles.vMenuItem} onClick={()=>SetEyeMoveType(EyeMoveDeclarationType.Required)}/>
						<VMenuItem text="Set type: optional" enabled={closestDeclaration.type != EyeMoveDeclarationType.Optional} style={styles.vMenuItem} onClick={()=>SetEyeMoveType(EyeMoveDeclarationType.Optional)}/>
						<VMenuItem text="Delete" style={styles.vMenuItem} onClick={()=>{
							ShowMessageBox({
								title: "Remove eye-move entry?",
								message: `
									Remove the closest eye-move entry from segment?
			
									Entry time: ${moment(closestDeclaration.time).format("HH:mm:ss")}
								`.AsMultiline(0),
								cancelButton: true,
								onOK: ()=>{
									const newEyeMoveDeclarations = segment.eyeMoveDeclarations.Exclude(closestDeclaration);
									new UpdateTestSegment({
										id: segment._key,
										updates: {eyeMoveDeclarations: newEyeMoveDeclarations},
									}).Run();
								},
							});
						}}/>
					</>
				);
				ShowVMenu({
					pos: new Vector2(e.pageX, e.pageY),
				}, menuUI);
			});

			//this.UpdateChart();
		},
	});
}