import {AddSchema} from "mobx-firelink";
import {AlarmConfigBase, AlarmConfigBase_SchemaProps} from "./@AlarmConfig.js";

export class FBAConfig_SoundAlarm extends AlarmConfigBase {
	//controlBackgroundAudio?: boolean;
	//loop: boolean;
}
AddSchema("FBAConfig_SoundAlarm", {
	properties: {
		...AlarmConfigBase_SchemaProps as any,
		//separate: {type: "boolean"},
		//loop: {type: "boolean"},
	},
});

export class FBAConfig_ShakeAlarm extends AlarmConfigBase {}
AddSchema("FBAConfig_ShakeAlarm", {
	properties: {
		...AlarmConfigBase_SchemaProps as any,
	},
});

export class FBAConfig_LightAlarm extends AlarmConfigBase {}
AddSchema("FBAConfig_LightAlarm", {
	properties: {
		...AlarmConfigBase_SchemaProps as any,
	},
});

export class FBAConfig_ScriptAlarm extends AlarmConfigBase {}
AddSchema("FBAConfig_ScriptAlarm", {
	properties: {
		...AlarmConfigBase_SchemaProps as any,
	},
});

// todo: finish RVP system, then integrate into FBA engine
/*export class FBAConfig_RVPAlarm {
	@O interval = 300;
	@O voicesEnabled = "{}";
	@O minVolume = 1;
	@O maxVolume = 1;
	@O minPitch = .5;
	@O maxPitch = 1.5;
	@O smoothing = 7;
}*/