import {store} from "../../../../Store/index.js";
import {FBAConfig} from "../../../../Store/firebase/fbaConfigs/@FBAConfig.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {HostClientChannel, HostFindType} from "../../../../Store/main/tools/engine.js";
import {InAndroid, InDesktop} from "../../../../Utils/Bridge/Bridge_Native.js";
import {processID, remoteUserProcessesBridge} from "../../../../Utils/Bridge/Bridge_RemoteUserProcesses.js";
import {GetRemoteInstanceInfos} from "../../../../Utils/Bridge/SiteInstanceBridgeManager.js";
import {InfoButton, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {GetEntries, SleepAsync, WaitXThenRun} from "js-vextensions";
import {Button, CheckBox, Column, Row, Select, Text, TextInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ClearAndStopLiveFBASession, liveFBASession, SetAndStartLiveFBASession as SetAndStartLiveFBASession} from "../../../../Engine/FBASession.js";
import {FBASession_Client} from "../../../../Engine/FBASession_Client.js";
import {ConnectToHost, DisconnectFromHost, hostConnection} from "../../../../Engine/Remoting/HostConnection.js";
import {FBASessionPanels_SharedProps} from "../FBAConfigPanel_Local.js";

@Observer
export class SessionManagementPanel_Client extends BaseComponentPlus({} as FBASessionPanels_SharedProps, {justGotPingResponse: false, lookingForHost: false}) {
	emptyBox: HTMLDivElement;
	ComponentDidMount() {
		document.addEventListener("pointerlockchange", this.onPointerLockChange);
		document.addEventListener("pointerlockerror", this.onPointerLockError);
	}
	ComponentWillUnmount() {
		document.removeEventListener("pointerlockchange", this.onPointerLockChange);
		document.removeEventListener("pointerlockerror", this.onPointerLockError);
	}
	onPointerLockChange = ()=>this.Update();
	onPointerLockError = ()=>this.Update();

	render() {
		const {active, config_full: config} = this.props;
		const uiState = store.main.tools.engine;
		const hostIPAddressAndPort = uiState.remote_hostIPAddressAndPort;
		//snoozeFailTrigger_micLoudness_loudness: State(a=>a.main.tools.fba.remote_snoozeFailTrigger_micLoudness_loudness),
		const {justGotPingResponse, lookingForHost} = this.state;

		const hostConnected = hostConnection != null;
		const hostSessionActive = hostConnection?.activeHostSessionConfig != null;
		const clientSessionActive = liveFBASession instanceof FBASession_Client;

		const largeVersion = window.outerWidth >= 1024;
		const localSessionRunning = liveFBASession && liveFBASession.IsLocal();
		return (
			<Column mb={40} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row>
					<Text style={{fontSize: 18}}>Session management</Text>
					<Text ml={5} sel style={{fontSize: 12, opacity: .5}}>(pid: {processID})</Text>
				</Row>

				{/*<Button text="Start" enabled={!active} onClick={async()=>{
				}}/>
				<Button mt={5} text="Stop" enabled={active} onClick={()=>{
				}}/>*/}
				{// todo: finish implementing, to support Websockets channel
				/*<Button text="Connect" enabled={!remoteConnected} onClick={()=>{
					//RemoteDesktop_InitBridge(remote_hostIPAddressAndPort);
					this.Update();
				}}/>*/}
				<Row center mt={5}>
					<Text>Channel: </Text>
					<Select options={GetEntries(HostClientChannel).filter(a=>(!InAndroid(0) ? a.value != HostClientChannel.Websockets : true))} enabled={!hostConnected}
						value={uiState.remote_channel} onChange={val=>RunInAction_Set(this, ()=>store.main.tools.engine.remote_channel = val)}/>
					<InfoButton ml={5} text={`
						RealtimeDatabase works with any host and client where you're signed in, but is slow.
						Websockets is faster, but currently only works with a desktop app host, and Android app client.
					`.AsMultiline(0)}/>
				</Row>
				{/*uiState.remote_channel == HostClientChannel.Websockets &&*/}
				<Row center mt={5}>
					<Text>Host find: </Text>
					<Select options={GetEntries(HostFindType)} enabled={!hostConnected} value={uiState.remote_hostFindType} onChange={val=>RunInAction_Set(this, ()=>uiState.remote_hostFindType = val)}/>
					<InfoButton ml={5} text={`
						Auto: First remote instance which is signed-in as the same user, and has "Allow client connections" enabled.
						Manual: First remote instance which matches the requirements for "Auto", and whose local-network ip-address matches that within the ip+port combo specified.
					`.AsMultiline(0)}/>
					{uiState.remote_hostFindType == HostFindType.Manual &&
						<>
							<Text ml={5}>IP+port: </Text>
							<TextInput enabled={!hostConnected} value={hostIPAddressAndPort} onChange={val=>{
								RunInAction("SessionManagementPanel.hostIPAddressAndPort.onChange", ()=>uiState.remote_hostIPAddressAndPort = val);
							}}/>
						</>}
				</Row>
				<Row mt={5}>
					<Text>Remote instances check:</Text>
					<Button ml={5} text="Ping (check if online)" onClick={async()=>{
						if (uiState.remote_channel == HostClientChannel.RealtimeDatabase) {
							console.log("Pinging remote-site (any where you're logged in)");
							const response = await remoteUserProcessesBridge.Call("Ping");
							console.log("Remote-site response: ", response);
						} else if (uiState.remote_channel == HostClientChannel.Websockets) {
							// todo: finish implementing, to support Websockets channel
							/*console.log(`Pinging remote-desktop at ${hostIPAddressAndPort}`);
							const response = await remoteDesktopBridge.Call("Ping");
							console.log("Remote-desktop response: ", response);*/
						}
						this.SetState({justGotPingResponse: true});
						WaitXThenRun(3000, ()=>this.SetState({justGotPingResponse: false}));
					}}/>
					{justGotPingResponse &&
						<Text ml={10}>Received response!</Text>}
				</Row>
				<Row center mt={5}>
					<Text>Connected host: {!hostConnected ? "none" : `${hostConnection!.hostInfo.ipAddress} (#${hostConnection!.hostInfo.processID})`}</Text>
					{!hostConnected &&
					<Button ml={5} text="Connect" enabled={!localSessionRunning && !lookingForHost} onClick={async()=>{
						console.log("Looking for host to connect to... (gives up after 10 seconds)");
						this.SetState({lookingForHost: true});

						const connect_promise = (async()=>{
							const instanceInfos = await GetRemoteInstanceInfos(5000);
							const targetHostInstance = instanceInfos.find(instance=>{
								if (instance.processID == processID) return false;
								if (!instance.allowClientConnections) return false;
								if (uiState.remote_hostFindType == HostFindType.Manual) {
									const targetIPAddress = uiState.remote_hostIPAddressAndPort.split(":")[0];
									if (instance.ipAddress != targetIPAddress) return false;
									// filtering by port currently makes no sense, since the "port" of instances is locked to 3502 atm
									/*const targetPort = ToInt(uiState.remote_hostIPAddressAndPort.split(":")[1], 3502);
									if (instance.port != targetPort) return false;*/
								}
								return true;
							});
							if (targetHostInstance == null) {
								this.SetState({lookingForHost: false});
								return;
							}
							await ConnectToHost(targetHostInstance, uiState.remote_channel);
							return true;
						})();
						const result = await Promise.race([SleepAsync(10000), connect_promise]);

						if (result != true) {
							console.log("Failed to find host session.");
						}
						this.SetState({lookingForHost: false}); // also just triggers update, to reflect current-session value
					}}/>}
					{hostConnected &&
					<Button ml={5} text="Disconnect" onClick={async()=>{
						if (clientSessionActive) {
							ClearAndStopLiveFBASession(false, false);
						}
						await DisconnectFromHost();
						this.Update();
					}}/>}
					<CheckBox ml={5} text="Auto-connect" value={uiState.remote_autoConnect} onChange={val=>RunInAction_Set(this, ()=>uiState.remote_autoConnect = val)}/>
					<InfoButton ml={5} text={`
						Whether to auto-connect to a remote host, when one starts a session. (if not already connected to one)

						Note: Remote instance must be signed-in as the same user, and allow client connections. 
					`.AsMultiline(0)}/>
				</Row>
				<Row mt={5}>
					<Text>Host session active: {hostSessionActive ? "yes" : "no"}</Text>
					{!hostSessionActive
						? <Button ml={5} text="Start" enabled={hostConnected} onClick={()=>{
							hostConnection!.bridge.Call("StartHostSession");
						}}/>
						: <>
							{InDesktop() &&
							<>
								<Button ml={5} text="Stop (save local + online)" enabled={MeID() != null} onClick={async()=>{
									// for now at least, it appears fine to leave client-session running despite host-session ending
									//if (clientSessionActive) await fbaCurrentSession.Stop(false);
									hostConnection!.bridge.Call("StopHostSession", true, true);
								}}/>
								<Button ml={5} text="Stop (save local)" onClick={async()=>{
									//if (clientSessionActive) await fbaCurrentSession.Stop(false);
									hostConnection!.bridge.Call("StopHostSession", true, false);
								}}/>
							</>}
							{!InDesktop() &&
							<>
								<Button ml={5} text="Stop (save online)" enabled={MeID() != null} onClick={async()=>{
									// for now at least, it appears fine to leave client-session running despite host-session ending
									//if (clientSessionActive) await fbaCurrentSession.Stop(false);
									hostConnection!.bridge.Call("StopHostSession", false, true);
								}}/>
							</>}
							<Button ml={5} text="Stop (discard)" onClick={async()=>{
								//if (clientSessionActive) await fbaCurrentSession.Stop(false);
								hostConnection!.bridge.Call("StopHostSession", false, false);
							}}/>
						</>}
				</Row>
				<Row mt={5}>
					<Text>Client session active: {clientSessionActive ? "yes" : "no"}</Text>
					{!clientSessionActive
						? <Button ml={5} text="Start" enabled={hostConnection?.activeHostSessionConfig != null && !clientSessionActive} onClick={async()=>{
							//const hostConfig = await hostConnection.bridge.Call("GetHostSessionConfig") as FBAConfig;
							const hostConfig = hostConnection!.activeHostSessionConfig;
							if (hostConfig) {
								hostConfig.Cast(FBAConfig); // set prototype, making it actual instance of FBAConfig
								console.log("Found host session. Connecting... @HostConfig:", hostConfig);
								await SetAndStartLiveFBASession(new FBASession_Client(config, hostConfig));
							} else {
								console.log("Host apparently had no session active, so not starting client session.");
							}
							this.Update();
						}}/>
						: <Button ml={5} text="Stop" onClick={async()=>{
							ClearAndStopLiveFBASession(false, false);
							this.Update();
						}}/>}
				</Row>
			</Column>
		);
	}
}