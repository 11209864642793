import Anthropic, {ClientOptions} from "@anthropic-ai/sdk";
import {MessageParam} from "@anthropic-ai/sdk/resources/messages.js";
import {store} from "../../Store/index.js";
import {Assert, E, IsNumberString} from "js-vextensions";
import {StoryMessageProto} from "../../Store/firebase/storyMessages.js";
import {ShowMessageBox} from "react-vmessagebox";
import moment from "moment";

let anthropic: Anthropic;

export class GenerateNextMessageOptions {
	constructor(data: RequiredBy<Partial<GenerateNextMessageOptions>, "minNumberedListSize" | "dynamicListNumbers" | "llmHistory_keepLastXNumberedLists" | "temperature">) {
		Object.assign(this, data);
	}
	baseURLOverride?: string;
	omitOldNumberedLists = true;
	useSystemPrompt = true;

	// from config
	minNumberedListSize: number;
	dynamicListNumbers: number[];
	llmHistory_keepLastXNumberedLists: number;
	temperature: number;
};
export function CategorizeLines(lines: string[]) {
	return lines.map(line=>CategorizeLine(line));
}
export function CategorizeLine(line: string) {
	const numberedListMatch = line.match(/^\D{0,2}(\d{1,2})\D/);
	const listNumber = numberedListMatch?.[1] != null ? Number(numberedListMatch?.[1]) : null;
	return {
		text: line,
		listNumber,
	};
}
export function GetMessageInfo(message: StoryMessageProto, minNumberedListSize: number) {
	const lines = message.content.split("\n");
	const linesCategorized = CategorizeLines(lines);
	// we never consider messages from the user to be a "numbered list"; if one would seem so, it presumably is just the user trying to modify the engine prompt
	const hasNumberedList = message.role == "assistant" && linesCategorized.filter(a=>a.listNumber != null).length >= minNumberedListSize;
	return {lines, linesCategorized, hasNumberedList};
}

export async function GenerateNextMessage(prevMessages: StoryMessageProto[], options: ConstructorParameters<typeof GenerateNextMessageOptions>[0], onTextSegment?: (nextTextSegment: string)=>void) {
	const opts = new GenerateNextMessageOptions(options);
	if (store.main.settings.anthropicAPIKey == null) throw new Error("Anthropic API key is not set.");
	if (prevMessages.length == 0) throw new Error("Cannot generate next message without any previous messages.");
	
	if (anthropic == null) {
		const config: ClientOptions = {
			//apiKey: GetDotEnvVar("ANTHROPIC_API_KEY"),
			apiKey: store.main.settings.anthropicAPIKey,
			//baseURL: "https://lucidfrontier.com/anthropic", // commented; anthropic api allows direct calls from browsers now!
			dangerouslyAllowBrowser: true,
		};
		if (opts.baseURLOverride) config.baseURL = opts.baseURLOverride;
		anthropic = new Anthropic(config);
	} else {
		Assert(anthropic.apiKey == store.main.settings.anthropicAPIKey, "Anthropic API key changed; please refresh the page to use the Anthropic API further.");
	}

	let prevMessages_final = prevMessages;

	// if "summary" messages are involved, make sure each one has a "dummy" user-input message before it (api requires alternating user/assistant messages)
	for (let i = 0; i < prevMessages_final.length; i++) {
		const prevMessage = prevMessages_final[i - 1];
		const message = prevMessages_final[i];
		if (message.isSummary && prevMessage?.role != "user") {
			prevMessages_final.Insert(i, new StoryMessageProto({role: "user", content: "Please include the summary for the next part of the story."}));
		}
	}

	if (opts.omitOldNumberedLists) {
		const prevMessages_info = prevMessages_final.map(message=>GetMessageInfo(message, opts.minNumberedListSize));

		const indexesOfLastXMessagesWithNumberedList = prevMessages_info.filter(a=>a.hasNumberedList).TakeLast(opts.llmHistory_keepLastXNumberedLists).map(a=>prevMessages_info.indexOf(a));
		
		// for each numbered-list found in the message history, omit it -- *except* for the last such numbered-list (needed ofc, for the user's last choice-input to be recognized)
		prevMessages_final = prevMessages_final.map((message, i)=>{
			const info = prevMessages_info[i];
			if (message.role == "user" || info == null) return message; // never modify user's own messages

			// if this message is not in the last last X messages with a numbered-list, but it has a numbered-list, omit those lines
			if (!indexesOfLastXMessagesWithNumberedList.includes(i) && info.hasNumberedList) {
				return {...message, content: info.linesCategorized.filter(a=>a.listNumber == null).map(a=>a.text).join("\n")};
			}

			// if this is in the last X numbered-lists, AND user made a choice/number-selection from this list, omit all dynamic-list options OTHER THAN the choice selected
			// (thus helping avoid the LLM repeating the same dynamic options; the instructions already tell it to avoid this, but it fairly often repeats them anyway)
			// todo: maybe remove this; it *maybe* is increasing the rate at which the LLM does not produce a numbered list at all (albeit, that case is easy to move past)
			/*const nextMessage = prevMessages_final[i + 1];
			const chosenNumber = nextMessage?.role == "user" && IsNumberString(nextMessage.content) ? Number(nextMessage.content) : null;
			if (indexesOfLastXMessagesWithNumberedList.includes(i) && chosenNumber != null) {
				//const chosenNumber_dynamic = chosenNumber != null && opts.dynamicListNumbers.includes(chosenNumber) ? chosenNumber : null;
				return {
					...message,
					content: info.linesCategorized.map(a=>{
						const isDynamicListItem = a.listNumber != null && opts.dynamicListNumbers.includes(a.listNumber);
						if (a.listNumber != null && isDynamicListItem && a.listNumber != chosenNumber) {
							/*const replacementPhrase = `{LLM MUST: Generate a fresh choice in this slot once player responds}`;
							//const replacementPhrase = ``; // LLM seems to give the best result simply by leaving the slot empty
							return `[${a.listNumber}] ${replacementPhrase}`; // keep line-number, else LLM can get confused and omit numbered-entries in next response*#/

							const extraInstruction = g.test1 ?? "{LLM MUST: Forget this choice, and generate something else in its place.}";
							return `${a.text} ${extraInstruction}`;
						}
						return a.text;
					}).join("\n"),
				};
			}*/

			return message;
		});
	}

	const prevMessages_asMessageParams = prevMessages_final.map(a=>({role: a.role, content: a.content} as MessageParam));
	
	// for now, just consider the last line of story-explore's "startPrompt" as the initial user-prompt; the rest of it is sent as the "system prompt"
	const firstMessage = prevMessages_asMessageParams[0];
	const firstMessageStr = firstMessage.content as string;
	const userPromptSection_match = firstMessageStr.match(/<userPrompt>(.+?)<\/userPrompt>/);
	
	let systemPrompt: string|undefined;
	// if wanting to use system-prompt, try to extract it from the first message
	if (opts.useSystemPrompt) {
		// if first-message had an explicit "user prompt" section marked, use that notation to separate the system-prompt from the user-prompt
		if (userPromptSection_match) {
			systemPrompt = firstMessageStr.replace(userPromptSection_match[0], "").trim();
			firstMessage.content = userPromptSection_match?.[1].trim();
		}
		// else, assume the entire first message is the system prompt, and replace the first message with a generic user-prompt
		else {
			systemPrompt = firstMessageStr;
			firstMessage.content = "Confirm understanding by producing an initial prompt and set of choices.";
		}
	}

	const messageStream = await anthropic.messages.create({
		stream: true,
		max_tokens: 4096,
		system: systemPrompt,
		messages: prevMessages_asMessageParams,
		temperature: opts.temperature,
		// use Claude 3.5 Sonnet; it's good enough, while being about 1/4 the cost of Claude 3.5 Opus (I also haven't tested Opus yet)
		// see list here: https://docs.anthropic.com/en/docs/about-claude/models
		model: store.main.settings.anthropicModel,
	}).catch(async(err)=>{
		console.log("Got headers for anthropic request error:", err.headers);
		if (err instanceof Anthropic.RateLimitError) {
			const resetTime = err.headers ? moment(err.headers["Anthropic-Ratelimit-Tokens-Reset"] + "") : null;
			ShowMessageBox({
				title: "Anthropic API rate limit reached",
				message: `The rate-limit will be reset at: ${resetTime?.format("YYYY-MM-DD HH:mm:ss") ?? "[unknown]"}`,
			});
		} else {
			throw err;
		}
	});
	if (messageStream) {
		for await (const event of messageStream) {
			//console.log("messageStreamEvent:", event);
			switch (event.type) {
				case "message_start":
					break;
				case "message_delta":
					break;
				case "message_stop":
					break;
				case "content_block_start":
					break;
				case "content_block_delta":
					const nextTextSegment = event.delta;
					if (nextTextSegment.type == "text_delta") {
						// sure, there's some overhead to this, but not to a level that actually matters; and this can preserve responses that get off oddly (like a few minutes ago)
						allTextSegmentsStreamedFromAnthropic.push(nextTextSegment.text);

						onTextSegment?.(nextTextSegment.text);
					} else if (nextTextSegment.type == "input_json_delta") {
						onTextSegment?.(JSON.stringify(nextTextSegment.partial_json));
					}
					break;
				case "content_block_stop":
					break;
				default:
					// If any event-type defined by library (in typescript) is not listed in the above, the line below will trigger a compile error.
					const exhaustiveCheck: never = event;
					console.error(`An unexpected MessageStreamEvent type was received from Anthropic api. @type:${event["type"]}`);
			}
		}
	}
}
export const allTextSegmentsStreamedFromAnthropic = [] as string[];

// helper functions for dev-tools experimenting
export let messages = [] as StoryMessageProto[];

export function LLMTest_MessageDump() {
	return messages.map(a=>a.content).join("\n==========\n");
}

export async function LLMTest_Reset(startPrompt: string) {
	messages.Clear();
	await LLMTest_Input(startPrompt);
}
export async function LLMTest_Input(message: string, options?: Partial<ConstructorParameters<typeof GenerateNextMessageOptions>[0]>) {
	const opts = new GenerateNextMessageOptions(E(
		{minNumberedListSize: 2, dynamicListNumbers: [], llmHistory_keepLastXNumberedLists: 3, temperature: 1},
		options,
	));
	
	messages.push(new StoryMessageProto({role: "user", content: message}));
	
	let content = "";
	await GenerateNextMessage(messages, opts, nextTextSegment=>{
		content += nextTextSegment;
	});
	messages.push(new StoryMessageProto({role: "assistant", content}));
	console.log("Response:", content);
}
export function LLMTest_Undo() {
	// remove last two messages (one for response, one for the last input)
	messages.pop();
	messages.pop();
	console.log("Last response (after undo):", messages.Last().content);
}