import {useSortable} from "@dnd-kit/sortable";
import {CSS as CSS_DND} from '@dnd-kit/utilities';
import {E, WaitXThenRun} from "js-vextensions";
import {Row, Text} from "react-vcomponents";
import {BaseComponent, GetDOM} from "react-vextensions";
import {Observer} from "web-vcore";
import React from "react";
import {entityCellAspectRatio} from "../../../Store/main/tools/journey.js";
import {DNDInfo} from "../../@Root/DNDStructures.js";
import {GetTermHitCount} from "./BottomPanel/EntitiesPanel.js";

@Observer
export class TermCell_ForDND extends BaseComponent<{term: string}, {}> {
	render() {
		const {term} = this.props;
		return <TermCell index={0} dndInfo={null} columns={1} term={term}/>;
	}
}

const categories_colors = {
	people: "rgba(0,100,255,.5)",
	objects: "rgba(0,255,0,.5)",
	concepts: "rgba(255,255,0,.5)",
};
@Observer
export class TermCell extends BaseComponent<{index: number, /*droppableInfo?: DNDInfo,*/ dndInfo: DNDInfo|n, columns: number, term: string, forPool?: boolean, /*deleteAction?: ()=>any*/}, {}> {
	render() {
		let {index, dndInfo, columns, term, forPool} = this.props;
		//if (category == null) category = GuessCategoryFromEntity(entity);
		//layoutInfo ??= GetEntityLayoutInfo(entity);
		const hitCount = GetTermHitCount(term);

		// keep in sync with EntitiesSubsequenceUI.render
		//const dndInfo = new DNDInfo({type: "EntityCell", parentDroppableInfo: droppableInfo, entity: entity, indexInContainer: index});
		const draggableID = JSON.stringify(dndInfo);
		//const {attributes, listeners, setNodeRef, transform} = droppableInfo != null ? useDraggable({id: draggableID, data: draggableInfo}) : {} as ReturnType<typeof useDraggable>;
		const {attributes, listeners, setNodeRef, transform, transition} = dndInfo != null ? useSortable({id: draggableID, data: dndInfo}) : {} as ReturnType<typeof useSortable>;

		return (
			<Row
				ref={c=>{
					if (setNodeRef) setNodeRef(GetDOM(c) as any);
				}}
				{...listeners ?? {}} {...attributes ?? {}}
				style={E(
					{
						position: "relative", width: `${100 / columns}%`, aspectRatio: entityCellAspectRatio, border: "1px solid rgba(255,255,255,.3)",
						//backgroundColor: category ? categories_colors[category] : null,
						backgroundColor: categories_colors.objects,
						touchAction: "manipulation",
					},
					// don't apply transform if entity-cell is in entity-pool (we only want to "copy from" the pool, not move its entries)
					//transform && !forPool && {transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`},
					!forPool && transform && {
						transform: CSS_DND.Transform.toString(transform),
    					transition,
					}
				)}
			>
				{/*deleteAction != null &&// temp, till dnd system is improved
				<Button text="X" style={{position: "absolute", top: 0, right: 0}} onClick={()=>deleteAction()}/>*/}
				{/*!entity.url &&*/ <Text style={{
					flex: 1, padding: 0, fontSize: 11,
					justifyContent: "center", textAlign: "center", wordBreak: "break-word",
				}}>{term}</Text>}
				{/*!!entity.url &&
				<PreviewImageSquare entry={entity} allowExtThumbnailing={false} wrapper={false}/>*/}
				<div style={{position: "absolute", right: 0, bottom: -2, fontSize: 11, color: "rgba(255,255,255,.7)"}}>{hitCount}</div>
			</Row>
		);
	}
}

let portal: HTMLElement;
WaitXThenRun(0, ()=>{
	portal = document.createElement("div");
	document.body.appendChild(portal);
});