import {InfoButton, Observer, RunInAction_Set} from "web-vcore";
import {StartDownload} from "js-vextensions";
import moment from "moment";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Row, Spinner, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {EngineSessionInfo} from "../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {store} from "../../../Store/index.js";
import {zIndexes} from "../../../Utils/UI/ZIndexes.js";

@Observer
export class SessionsUISettingsDropdown extends BaseComponent<{sessions: EngineSessionInfo[]}, {}> {
	render() {
		let {sessions} = this.props;
		const uiState = store.main.timeline.sessions;

		return (
			<DropDown style={{marginLeft: 3}}>
				<DropDownTrigger>
					<Button mdIcon="cog" size={32}/>
				</DropDownTrigger>
				<DropDownContent style={{zIndex: zIndexes.dropDown, right: 0, width: 350, background: "rgba(0,0,0,.9)", borderRadius: 5}}><Column>
					<Row>Sessions, total: {sessions.length}</Row>
					<Row>
						<Button text="Export all" onClick={()=>{
							const output = JSON.stringify(sessions, null, "\t");
							StartDownload(output, `SessionsExport_${moment().format("YYYY-MM-DD-HH-mm-ss")}_Count${sessions.length}.json`);
						}}/>
					</Row>
				</Column></DropDownContent>
			</DropDown>
		);
	}
}