import {CloneWithPrototypes, GetErrorMessagesUnderElement} from "js-vextensions";
import {Column, RowLR, Text, TextInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {Script} from "../../../Store/firebase/scripts/@Script";
import {User} from "../../../Store/firebase/users/@User";
import {IDAndCreationInfoUI} from "../../../UI/@Shared/CommonPropUIs/IDAndCreationInfoUI";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {TagsInput} from "../../../Utils/ReactComponents/TagsInput";
import {Observer} from "web-vcore";
import {GetUser} from "../../../Store/firebase/users";
import {EffectPreviewButton} from "../../@Shared/TagTargetEntryUI";

/*export function ShowAddScriptDialog(initialData?: Partial<Script>, postAdd?: (id: string)=>any) {
	let newEntry = new Script(E({
		name: "",
	}, initialData));
	const getCommand = ()=>new AddScript({script: newEntry});

	const boxController: BoxController = ShowMessageBox({
		title: "Add script", cancelButton: true,
		message: observer_simple(()=>{
			const tempCommand = getCommand();
			boxController.UpdateOptions({okButtonProps: {
				enabled: tempCommand.Validate_Safe() == null,
				title: tempCommand.validateError,
			}});

			return (
				<Column style={DialogStyle(600)}>
					<ScriptDetailsUI baseData={newEntry} forNew={true}
						onChange={(val, error)=>{
							newEntry = val;
							boxController.UpdateUI();
						}}/>
				</Column>
			);
		}),
		onOK: async()=>{
			const id = await getCommand().Run();
			if (postAdd) postAdd(id);
		},
	});
}*/

type Props = {baseData: Script, forNew: boolean, enabled?: boolean, style?, onChange?: (newData: Script, ui: ScriptDetailsUI)=>void} & Partial<{creator: User}>;
type Props_Enhanced = Props & {newData: Script, Change};

const splitAt = 120, width = "100%";

@Observer
export class ScriptDetailsUI extends BaseComponentPlus({enabled: true} as Props, {} as {newData: Script}) {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.baseData != this.props.baseData) { // if base-data changed
			const newData = CloneWithPrototypes(props.baseData);
			//Object.setPrototypeOf(newData, Script.prototype); // MS newData's class is "Script", so we can use Script's class methods directly
			//Object.setPrototypeOf(newData, Object.getPrototypeOf(new Script({}))); // MS newData's class is "Script", so we can use Script's class methods directly
			this.SetState({newData});
		}
	}

	render() {
		const {baseData, forNew, enabled, style, onChange} = this.props;
		const {newData} = this.state;
		const Change = (..._)=>{
			if (onChange) onChange(this.GetNewData(), this);
			this.Update();
		};
		const creator = !forNew && GetUser(newData.creator);

		const propsEnhanced = {...this.props, Change, ...this.state, SetState: this.SetState};
		return (
			<Column style={style}>
				{!forNew &&
					<IDAndCreationInfoUI id={baseData._key} creatorID={newData.creator} createdAt={newData.createdAt}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Name: </Text>
					<TextInput pattern={name_general} required
						enabled={enabled} style={{width: "100%"}}
						value={newData.name} onChange={val=>Change(newData.name = val)}/>
				</RowLR>
				{/*<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Type: </Text>
					<Select options={GetEntries(ScriptType)} enabled={enabled} style={{width: "100%"}}
						value={newData.type} onChange={val=>Change(newData.type = val, Script.InitDefaults(newData))}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Row center>
						<Pre>Visibility:</Pre>
						<InfoButton ml={5} text={`
							Visible: Shown publicly in the list of scripts.
							Hidden: Hidden in script list (other than to mods), but still accessible through reading of raw db contents. (so not guarantee of privacy)
						`.AsMultiline(0)}/>
					</Row>
					<Select options={GetEntries(EffectVisibility)} enabled={enabled} value={newData.visibility} onChange={val=>Change(newData.visibility = val)}/>
				</RowLR>*/}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Suggested tags:</Text>
					<TagsInput enabled={enabled} style={{width: "100%"}} value={newData.tags} onChange={val=>Change(newData.tags = val)}/>
				</RowLR>
				<EffectPreviewButton mt={5} group="scripts" entry={newData} startText="Preview script" stopText="Stop preview" width={200}/>
			</Column>
		);
	}
	GetValidationError() {
		return GetErrorMessagesUnderElement(this.DOM)[0];
	}

	GetNewData() {
		const {newData} = this.state;
		return CloneWithPrototypes(newData) as Script;
	}
}