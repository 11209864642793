import {TimelineEvent} from "../../Store/firebase/timelineEvents/@TimelineEvent";
import {AssertValidate, GenerateUUID, Command, AssertV} from "mobx-firelink";
import {EngineSessionInfo} from "../../Store/firebase/sessions/@EngineSessionInfo";

export class AddSession extends Command<{session: EngineSessionInfo}, string> {
	//id: string;
	Validate() {
		const {session} = this.payload;

		session.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		session.creator = this.userInfo!.id;
		//session.createdAt = Date.now();

		this.returnData = session.id;
		AssertValidate(`EngineSessionInfo`, session, `EngineSessionInfo invalid`);
	}

	GetDBUpdates() {
		const {session} = this.payload;

		const updates = {};
		updates[`sessions/${session.id}`] = session;
		return updates;
	}
}