import {Button, CheckBox, Column, Row, Spinner, Text, TextInput} from "react-vcomponents";
import {store} from "../../../Store/index.js";
import {ES} from "../../../Utils/UI/GlobalStyles";
import {Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {BaseComponent} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import React from "react";
import {nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {StoreMagRangeValues, StoreMagValueAverageForDirection} from "./LD1ConfigPanel.js";
import {SendLD1ConfigToDevice} from "../../../Utils/AutoRuns/SendLD1ConfigToDevice.js";

@Observer
export class LD1Panel extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.monitor;
		return (
			//<Column p={10} style={ES({flex: 1})}>
			<LD1ConsolePanel/>
			//</Column>
		);
	}
}

export const onCurrentDirChanged_listeners = [] as Array<(currentDir: number)=>any>;
nativeBridge.RegisterFunction("OnLD1LogReceived", (logMessage: string)=> {
	const uiState = store.main.tools.monitor.ld1;
	let showInLog = true;

	// for certain log messages, we want to parse them and take action accordingly
	// ==========

	const match1 = logMessage.match(/^Notify: @currentDir:(\d+)/);
	if (match1) {
		const currentDir = Number(match1[1]);
		onCurrentDirChanged_listeners.forEach(a=>a(currentDir));
		// we have to do this filtering in the frontend atm, since sending of these "log messages" is required for frontend to know current direction
		if (!uiState.config1.logDirection) {
			showInLog = false;
		}
	}

	const match2 = logMessage.match(/^@Call,StoreMagValueAverageForDirection,(.+),(.+)/);
	if (match2) {
		const [direction, durationStr] = match2.slice(1);
		const duration = Number(durationStr);
		StoreMagValueAverageForDirection(direction, duration);
	}

	const match3 = logMessage.match(/^@Call,StoreMagRangeValues,(.+),(.+),(.+),(.+),(.+),(.+),(.+)/);
	if (match3) {
		const [durationStr, xMin, xMax, yMin, yMax, zMin, zMax] = match3.slice(1);
		//const duration = Number(durationStr);
		StoreMagRangeValues(Number(xMin), Number(xMax), Number(yMin), Number(yMax), Number(zMin), Number(zMax));
	}

	if (showInLog) {
		RunInAction("OnLD1LogReceived", ()=>uiState.consoleLines.push(`[log] ${logMessage}`));
	}
});

@Observer
class LD1ConsolePanel extends BaseComponent<{}, {consoleInputStr: string}> {
	static initialState = {consoleInputStr: ""};
	render() {
		let {} = this.props;
		const {consoleInputStr} = this.state;
		const uiState = store.main.tools.monitor.ld1;
		const addToInputStr = (str: string)=>this.SetState({consoleInputStr: consoleInputStr + str});
		const runOrPrepCommand = (commandStr: string)=> {
			if (uiState.commandsAutoRun) {
				RunLD1ConsoleInput(commandStr);
			} else {
				addToInputStr(commandStr);
			}
		};
		const runOrPrepCommandObj = (command: Object)=> {
			runOrPrepCommand(JSON.stringify(command));
		};

		const updateMotorPower = ()=>runOrPrepCommandObj({setMotorPower: {motor: uiState.motor_pin, power: uiState.motor_power / 255, period: uiState.motor_period}});
		const setMotorPin = (pin: number)=>{
			pin = pin.KeepBetween(2, 21); // technically we can control pins 0 and 1, but they're used for serial comms, so we don't want to
			RunInAction_Set(this, ()=>uiState.motor_pin = pin);
			//updateMotorPower();
		};
		const setMotorPower = (power: number)=>{
			power = power.KeepBetween(0, 255);
			RunInAction_Set(this, ()=>uiState.motor_power = power);
			updateMotorPower();
		};
		const setMotorPeriod = (period: number)=>{
			period = period.KeepAtLeast(2); // 2 microseconds is the minimum for the Nano33BLE, according to: https://forum.arduino.cc/t/how-to-change-arduino-nano-33-ble-sense-pwm-frequency/957895/4
			RunInAction_Set(this, ()=>uiState.motor_period = period);
			updateMotorPower();
		};
		
		return (
			<Column p={5} style={{flex: 1}}>
				<Row>
					<Text>Commands:</Text>
					<CheckBox ml={10} text="Auto-run" value={uiState.commandsAutoRun} onChange={val=>RunInAction_Set(this, ()=>uiState.commandsAutoRun = val)}/>
					<Button ml={5} text="Red" onClick={()=> {
						runOrPrepCommandObj({setLEDColor: {color: "red"}});
					}}/>
					<Button ml={5} text="Green" onClick={()=> {
						runOrPrepCommandObj({setLEDColor: {color: "green"}});
					}}/>
					<Button ml={5} text="Blue" onClick={()=> {
						runOrPrepCommandObj({setLEDColor: {color: "blue"}});
					}}/>
				</Row>
				<Row>
					<Text>Motor pin:</Text>
					<Spinner ml={5} value={uiState.motor_pin} onChange={val=>setMotorPin(val)}/>
					<Text ml={10}>Period:</Text>
					<Spinner ml={5} value={uiState.motor_period} onChange={val=>setMotorPeriod(val)}/>
					<Button ml={3} p="0 5px" text="-10" onClick={()=>setMotorPeriod(uiState.motor_period - 10)}/>
					<Button ml={3} p="0 5px" text="+10" onClick={()=>setMotorPeriod(uiState.motor_period + 10)}/>
					<Button ml={3} p="0 5px" text="-50" onClick={()=>setMotorPeriod(uiState.motor_period - 50)}/>
					<Button ml={3} p="0 5px" text="+50" onClick={()=>setMotorPeriod(uiState.motor_period + 50)}/>
				</Row>
				<Row mt={5}>
					<Text>Power:</Text>
					<Spinner ml={5} value={uiState.motor_power} onChange={val=>setMotorPower(val)}/>
					<Button ml={3} p="0 5px" text="-1" onClick={()=>setMotorPower(uiState.motor_power - 1)}/>
					<Button ml={3} p="0 5px" text="+1" onClick={()=>setMotorPower(uiState.motor_power + 1)}/>
					<Button ml={3} p="0 5px" text="-5" onClick={()=>setMotorPower(uiState.motor_power - 5)}/>
					<Button ml={3} p="0 5px" text="+5" onClick={()=>setMotorPower(uiState.motor_power + 5)}/>
					<Button ml={3} p="0 5px" text="-20" onClick={()=>setMotorPower(uiState.motor_power - 20)}/>
					<Button ml={3} p="0 5px" text="+20" onClick={()=>setMotorPower(uiState.motor_power + 20)}/>
					<Button ml={3} p="0 5px" text="-50" onClick={()=>setMotorPower(uiState.motor_power - 50)}/>
					<Button ml={3} p="0 5px" text="+50" onClick={()=>setMotorPower(uiState.motor_power + 50)}/>
				</Row>
				<Row mt={5}>
					<Button text="Clear console" onClick={()=> {
						RunInAction_Set(this, ()=>uiState.consoleLines = []);
					}}/>
					<Text ml={10}>BLE:</Text>
					<Button ml={5} text="Connect" onClick={async()=> {
						const result = await nativeBridge.Call("BTScan", uiState.deviceAddresses[uiState.deviceAddresses_activeIndex] ?? "[none]");
					}}/>
					<Button ml={5} text="DC" onClick={async()=> {
						const result = await nativeBridge.Call("BTDisconnect");
					}}/>
					{/*<Button ml={5} text="Connect" onClick={async()=> {
						const result = await nativeBridge.Call("BTConnect");
					}}/>*/}
					<Button ml={5} text="Init" onClick={()=>SendLD1ConfigToDevice()}/>
				</Row>
				<ScrollView style={{flex: 1}}>
					{uiState.consoleLines.map((entry, index)=> {
						return (
							<Row key={index} style={ES({flex: 1, padding: 5})}>
								<Text style={ES({flex: 1})}>{entry}</Text>
							</Row>
						);
					})}
				</ScrollView>
				<Row>
					<TextInput style={{flex: 1}} value={consoleInputStr} onChange={val=>this.SetState({consoleInputStr: val})}/>
					<Button text="Run" onClick={()=>{
						RunLD1ConsoleInput(consoleInputStr);
						this.SetState({consoleInputStr: ""});
					}}/>
				</Row>
			</Column>
		);
	}
}

export function RunLD1ConsoleInput(inputStr: string) {
	const uiState = store.main.tools.monitor.ld1;
	RunInAction_Set(this, ()=>uiState.consoleLines.push(`[executed] ${inputStr}`));

	// If input is parseable as json (ie. if try-block proceeds without error), then assume it's a command-json we want to send to the LD1 device.
	try {
		const commandObj = JSON.parse(inputStr);
		/*if (commandObj["setLEDColor"]) {
			const {color} = commandObj["setLEDColor"];
			nativeBridge.Call("setLEDColor", color);
		}*/

		SendCommandToLD1(commandObj);
	} catch (ex) {
		RunInAction_Set(this, ()=>uiState.consoleLines.push(`[error] ${ex.message}`));
	}
}
export function SendCommandToLD1(commandObj: Object) {
	//SendCommandToLD1_AsString(JSON.stringify(commandObj));
	SendCommandsToLD1([commandObj]);
}
export function SendCommandToLD1_AsString(commandStr: string) {
	//nativeBridge.Call("SendCommandToLD1", commandStr);
	SendCommandsToLD1_AsString([commandStr]);
}

// lower-level
export function SendCommandsToLD1(commandObjects: Object[]) {
	SendCommandsToLD1_AsString(commandObjects.map(commandObj=>JSON.stringify(commandObj)));
}
export function SendCommandsToLD1_AsString(commandStrings: string[]) {
	nativeBridge.Call("SendCommandsToLD1", commandStrings);
}