import produce from "immer";
import {AddSchema, WithStore} from "mobx-firelink";
import {ActionFunc, AddErrorMessage, AddWVCSchemas, GetMirrorOfMobXTree, GetMirrorOfMobXTree_Options, manager as manager_framework, RunInAction, ShouldErrorBeIgnored} from "web-vcore";
import {dbVersion, hasHotReloaded} from "../../Main";
import {RootState, store} from "../../Store";
import {GetAuth} from "../../Store/firebase.js";
import {Me, MeID} from "../../Store/firebase/users.js";
import {GetUserPermissionGroups} from "../../Store/firebase/users/$user.js";
import {GetYoutubePlayersToKeepBuffered} from "../../Store/main/effects/sounds.js";
import {useExpandedNavBar} from "../../UI/@Shared/NavBar.js";
import {logTypes, LogTypes_New} from "../../Utils/General/Logging.js";
import {ValidateDBData} from "../../Utils/Store/DBDataValidator.js";
import {colors} from "../../Utils/UI/GlobalStyles.js";
import {zIndexes} from "../../Utils/UI/ZIndexes.js";
import {DoesURLChangeCountAsPageChange, GetLoadActionFuncForURL, GetNewURL, pageTree} from "../../Utils/URL/URLs.js";
import {liveSkin} from "../Styles/StyleManager.js";
import "./WVC/Overrides.js";

const context = (require as any).context("../../../Resources/SVGs/", true, /\.svg$/);
const iconInfo = {};
context.keys().forEach(filename=>{
	iconInfo[filename] = context(filename).default;
});

declare module "web-vcore_UserTypes" {
	interface RootStore extends RootState {}
	//interface DBShape extends GraphDBShape {}
	interface LogTypes extends LogTypes_New {}
}

AddWVCSchemas(AddSchema);
export function InitWVC() {
	manager_framework.Populate({
		// styling
		colors,
		zIndexes,
		iconInfo,
		useExpandedNavBar,

		// core
		db_short: DB,
		devEnv: DEV,
		prodEnv: PROD,
		dbVersion,
		HasHotReloaded: ()=>hasHotReloaded,
		logTypes,
		mobxCompatMode: true,
		ShouldErrorBeIgnored: e=>ShouldErrorBeIgnored(e),
		PostHandleError: (error, errorStr)=>{
			// wait a bit, in case we're in a reducer function (calling dispatch from within a reducer errors)
			setTimeout(()=>{
				RunInAction("WVC.PostHandleError", ()=>AddErrorMessage(error.message, error.stack ?? "No stack trace provided."));
			});
		},

		// urls
		GetSkin: ()=>liveSkin,
		pageTree,
		startURL,
		GetLoadActionFuncForURL,
		GetNewURL,
		DoesURLChangeCountAsPageChange,
		GetNewURLForStoreChanges,

		// store+db
		GetStore: ()=>store,
		GetAuth,
		GetUserID: MeID,
		ValidateDBData,
		//WithStore,
		//firebaseConfig,

		// others
		globalConnectorPropGetters: {
			// also access some other paths here, so that when they change, they trigger ui updates for everything
			_user: ()=>Me(),
			_permissions: ()=>GetUserPermissionGroups(MeID()),
			// _extraInfo: function() { return this.extraInfo; }, // special debug info from within FirebaseConnect function
		},
		GetYoutubePlayerPoolContainer: ()=>document.querySelector("#youtube-players")!,
		GetYoutubePlayersToKeepBuffered,
	});
}

export function GetNewURLForStoreChanges<T = RootState>(actionFunc: ActionFunc<T>, getSubOperatedOnByActionFunc: (root: RootState)=>T = (root=>root as any)) {
	const store_mirror = GetMirrorOfMobXTree(store, new GetMirrorOfMobXTree_Options().VSet({mirrorObservableRefs: true}));
	/*if (store_mirror.feedback != null) {
		const temp = GetMirrorOfMobXTree(store_mirror.feedback); // todo: attempted fix for bug in GetMirrorOfMobXTree
		if (temp) {
			console.log("BeforeAndAfter:", store_mirror.feedback, temp);
			store_mirror.feedback = temp;
		}
	}*/
	// workaround for first-call-not-populated issue
	if (store_mirror.main == null) return null;

	//const newState = produce(store, (draft: RootState)=>{
	const newState = produce(store_mirror, (draft: RootState)=>{
		actionFunc(getSubOperatedOnByActionFunc(draft));
	});
	// have new-state used for our store-accessors (ie. GetNewURL)
	const newURL = WithStore({}, newState, ()=>{
		// and have new-state used for firebase-feedback's store-accessors (ie. GetSelectedProposalID, as called by our GetNewURL)
		// [this part's probably not actually needed, since project-level Link.actionFunc's are unlikely to modify firebase-feedback's internal state; we do this for completeness, though]
		//return WithStore({fire: Feedback_store.firelink}, newState.feedback, ()=>{
		return GetNewURL();
		//});
	});
	return newURL.toString();
}