import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export class FBAConfig_JourneyTraining {
	constructor(initialData: Partial<FBAConfig_JourneyTraining>) { this.VSet(initialData); }

	enabled: boolean;

	entityTags: string[];
	entityMinCount: number;
	seeds_basePerEntity: number;
	seeds_addFromDreams: boolean;

	voiceSoundTag: string;
	volumeMultiplier: number;
	prefixText: string;
	postfixText: string;
}
AddSchema("FBAConfig_JourneyTraining", {
	properties: {
		enabled: {type: "boolean"},

		entityTags: {items: {type: "string"}},
		entityMinCount: {type: "number"},
		seeds_basePerEntity: {type: "number"},
		seeds_addFromDreams: {type: "boolean"},

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},
		prefixText: {type: "string"},
		postfixText: {type: "string"},
	},
});