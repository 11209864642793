import {InfoButton, Observer, TextPlus} from "web-vcore";
import React from "react";
import {CheckBox, Column, Row, RowLR, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {GetEntries, ToNumber} from "js-vextensions";
import {DreamQuiz_QuestionType} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.dreamQuiz, (c, v)=>c.dreamQuiz = v);

const splitAt = 150;

@Observer
export class DreamQuiz_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Dream Quiz" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Submit pick:</Text>
						<ControlInput enabled={enabled} value={config.submitPick_triggerSet} onChange={val=>ChangeConfig(c=>c.submitPick_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Next option:</Text>
						<ControlInput enabled={enabled} value={config.nextOption_triggerSet} onChange={val=>ChangeConfig(c=>c.nextOption_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Previous option:</Text>
						<ControlInput enabled={enabled} value={config.prevOption_triggerSet} onChange={val=>ChangeConfig(c=>c.prevOption_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Skip pick:</Text>
							<InfoButton ml={5} text="Give up on trying to find the target option."/>
						</Row>
						<ControlInput enabled={enabled} value={config.skipPick_triggerSet} onChange={val=>ChangeConfig(c=>c.skipPick_triggerSet = val)}/>
					</RowLR>
					{/*<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Stop voice:</Text>
						<ControlInput enabled={enabled} value={config.stopVoice_triggerSet} onChange={val=>ChangeConfig(c=>c.stopVoice_triggerSet = val)}/>
					</RowLR>*/}
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Voice, tag:</Text>
						<TextInput enabled={enabled} style={{width: 80}} value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>
						<Text ml={5} mr={5}>Vol. mult.:</Text>
						<Spinner min={0} step={0.01} enabled={enabled} value={config.volumeMultiplier} onChange={val=>ChangeConfig(c=>c.volumeMultiplier = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							If set, then on success, "Good, X left" will be spoken (rather than just "Good"), where X is the number of targets left to find this sleep-cycle.
							Note1: So a value of "20, 10, 1" means sleep-cycle #1 wants 20 successes, #2 wants 10, #3 wants 1, and all others want 0 (so always "Good, 0 left").
							Note2: A sleep-cycle is not a journey-cycle: sleep-cycles end ~when alarm-wait periods end; journey cycles start/end based on configured interactions.
						`.AsMultiline(0)}>Target count per cycle:</TextPlus>
						<TextInput enabled={enabled} style={{width: "100%"}} value={(config.targetCountPerCycle ?? []).join(", ")} onChange={val=>{
							ChangeConfig(c=>c.targetCountPerCycle = val.split(",").map(a=>ToNumber(a.trim(), undefined)).filter(a=>a));
						}}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							Note: This option does nothing for some quiz types. (eg. "comesBefore" which is locked to 2 options: no, yes)
						`.AsMultiline(0)}>Option count:</TextPlus>
						<Spinner min={2} enabled={enabled} value={config.optionCount} onChange={val=>ChangeConfig(c=>c.optionCount = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info="This is more convenient for some hotkey schemes.">Start at last:</TextPlus>
						<CheckBox enabled={enabled} value={config.startAtLast} onChange={val=>ChangeConfig(c=>c.startAtLast = val)}/>
					</RowLR>

					<Row mt={10} style={{fontWeight: "bold"}}>Dream quiz</Row>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							This is useful as a way of filtering to dream-segments with more interesting/lucidity-promoting content.
						`.AsMultiline(0)}>Lucid segments only:</TextPlus>
						<CheckBox enabled={enabled} value={config.lucidSegmentsOnly} onChange={val=>ChangeConfig(c=>c.lucidSegmentsOnly = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							This is useful as a way of filtering to dream-segments with enough content for quiz system to work with it.
						`.AsMultiline(0)}>Segment min events:</TextPlus>
						<Spinner min={0} enabled={enabled} value={config.segmentMinEvents} onChange={val=>ChangeConfig(c=>c.segmentMinEvents = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							This is useful as a way of filtering out "events" that are actually just use of semicolon as a regular punctuation mark.
						`.AsMultiline(0)}>Event max words:</TextPlus>
						<Spinner min={0} enabled={enabled} value={config.eventMaxWords} onChange={val=>ChangeConfig(c=>c.eventMaxWords = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Hint size:</Text>
						<Spinner min={1} enabled={enabled} value={config.hintSize} onChange={val=>ChangeConfig(c=>c.hintSize = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Target size:</Text>
						<Spinner min={1} enabled={enabled} value={config.targetSize} onChange={val=>ChangeConfig(c=>c.targetSize = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Min dist to target:</Text>
						<Spinner min={0} enabled={enabled} value={config.minDistToTarget} onChange={val=>ChangeConfig(c=>c.minDistToTarget = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Question type:</Text>
						<Select options={GetEntries(DreamQuiz_QuestionType)} enabled={enabled} style={{width: 100}} value={config.questionType} onChange={val=>ChangeConfig(c=>c.questionType = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Invert question type:</Text>
						<CheckBox enabled={enabled} value={config.questionType_invert} onChange={val=>ChangeConfig(c=>c.questionType_invert = val)}/>
					</RowLR>

					<Row mt={10} style={{fontWeight: "bold"}}>Sound quiz</Row>
					<Row>
						<CheckBox text="Enabled" enabled={enabled} value={config.soundQuiz_enabled} onChange={val=>ChangeConfig(c=>c.soundQuiz_enabled = val)}/>
					</Row>
					{config.soundQuiz_enabled && <>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus mr={5}>Quiz ratio:</TextPlus>
							<Text>Dream:</Text>
							<Spinner ml={5} min={0} step={1} enabled={enabled} value={config.quizRatio_dream} onChange={val=>ChangeConfig(c=>c.quizRatio_dream = val)}/>
							<Text ml={5}>Sound:</Text>
							<Spinner ml={5} min={0} step={1} enabled={enabled} value={config.quizRatio_sound} onChange={val=>ChangeConfig(c=>c.quizRatio_sound = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus mr={5} info={`
								Subpath to folder where target sound files are stored. The quiz will randomly select one of these files to play.
								Example: "Alarms/Creatures" (generally maps to MainStorage/Alarms/Creatures)
							`.AsMultiline(0)}>Sound folder:</TextPlus>
							<TextInput enabled={enabled} value={config.soundFolder} onChange={val=>ChangeConfig(c=>c.soundFolder = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus mr={5}>Sound volume:</TextPlus>
							<Spinner enabled={enabled} value={(config.soundVolume * 100).RoundTo(.01)} onChange={val=>ChangeConfig(c=>c.soundVolume = val / 100)} min={0} max={100}/>
							<Text>%</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus mr={5} info={`
								This can help avoid the need for editing sound-files. (to make them short enough to work with the quiz system)
								Note: This setting also affects the playback of sound-files when using the "[sound quiz]" tag in the "Alarms" comp.
							`.AsMultiline(0)}>Duration limit:</TextPlus>
							<Spinner min={0} step={.1} enabled={enabled} value={config.soundDurationLimit} onChange={val=>ChangeConfig(c=>c.soundDurationLimit = val)}/>
							<Text>s</Text>
						</RowLR>
					</>}
				</>}
			</Column>
		);
	}
}