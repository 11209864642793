import {GetDoc, StoreAccessor} from "mobx-firelink";
import {UserHidden} from "./users_private/@UserHidden";
import {MeID} from "./users.js";

export const GetUserHidden = StoreAccessor(s=>(userID: string): UserHidden|n=>{
	return GetDoc({}, a=>a.users_private.get(userID));
});

export const GetMyGridEntityNames = StoreAccessor(s=>()=>{
	const meID = MeID();
	if (meID == null) return [];
	const userHidden = GetUserHidden(meID);
	return userHidden?.gridEntityNames ?? [];
});