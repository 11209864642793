import {Assert, Clone, GetEntries} from "js-vextensions";
import React, {useState} from "react";
import {Button, Column, Row, Select, Text, TextArea} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {Observer, RunInAction_Set, Slider} from "web-vcore";
import {UpdateJournalEntry} from "../../../../Server/Commands/UpdateJournalEntry.js";
import {GetJournalEntries} from "../../../../Store/firebase/journalEntries.js";
import {GetTermsInDreamSegmentText, JournalEntry, JournalSegment} from "../../../../Store/firebase/journalEntries/@JournalEntry.js";
import {StoryMessageProto} from "../../../../Store/firebase/storyMessages.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {store} from "../../../../Store/index.js";
import {CleanupTab, llmTemplateDefault_cleanup} from "../../../../Store/main/journal.js";
import {GenerateNextMessage, GenerateNextMessageOptions} from "../../../../Utils/Services/Anthropic.js";
import {TermCell} from "../../../Tools/Journey/TermCell.js";

@Observer
export class SegmentCleanupUI extends BaseComponent<{entry: JournalEntry, segment: JournalSegment, segmentIndex: number}, {}> {
	render() {
		const {entry, segment, segmentIndex} = this.props;
		const uiState = store.main.journal;
		Assert(segment == entry.segments[segmentIndex]);
		const segments = entry.segments;
		const journalEntries_asc = GetJournalEntries(MeID(), "asc");
		const journalEntryIndex = journalEntries_asc.findIndex(a=>a._key == entry._key);
		
		let [targetLength, setTargetLength] = useState(24);
		const [llmOutput, setLLMOutput] = useState("");

		let outputText: string|n;
		let simple_outputTerms: string[]|n;
		if (uiState.cleanup_tab == CleanupTab.llmGenerate) {
			outputText = llmOutput;
		}

		return (
			<Column>
				<Row>
					<Select options={GetEntries(CleanupTab, "ui")} displayType="button bar"
						value={uiState.cleanup_tab} onChange={val=>RunInAction_Set(this, ()=>uiState.cleanup_tab = val)}/>
					{uiState.cleanup_tab == CleanupTab.llmTemplate &&
					<Button ml={5} mdIcon="file-restore" onClick={async()=>{
						ShowMessageBox({
							title: "Reset template?", cancelButton: true,
							message: "Reset the template to the default?",
							onOK: ()=>{
								RunInAction_Set(this, ()=>uiState.cleanup_llmTemplate = llmTemplateDefault_cleanup);
							},
						});
					}}/>}
					{uiState.cleanup_tab == CleanupTab.llmGenerate &&
					<Button ml={5} mdIcon="refresh" onClick={async()=>{
						const startMessage = new StoryMessageProto({
							role: "user",
							content: uiState.cleanup_llmTemplate.replace(/\$dream/g, segment.longText.trim()),
						});

						const options = new GenerateNextMessageOptions({
							minNumberedListSize: 999, dynamicListNumbers: [], llmHistory_keepLastXNumberedLists: 999, temperature: 1,
							useSystemPrompt: false,
						});
						let newLLMResponseText = "";
						await GenerateNextMessage([startMessage], options, nextTextSegment=>{
							newLLMResponseText += nextTextSegment;
						});

						console.log("Cleaning-up for long-text:", startMessage, "@CleanedUp:", newLLMResponseText);

						setLLMOutput(newLLMResponseText);
					}}/>}
					<Button ml="auto" mdIcon="transfer-down" enabled={(outputText ?? "").trim().length > 0} onClick={()=>{
						const proceed = ()=>{
							const segments_new = Clone(entry.segments);
							g.JournalCleanUp_oldLongTexts = (g.JournalCleanUp_oldLongTexts ?? []).concat(segment.longText); // safety hatch
							segments_new[segmentIndex] = new JournalSegment({...segment, longText: outputText!});
							new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
						};
						if (segment.longText.length > 0) {
							ShowMessageBox({
								title: "Confirm replace long-text?", cancelButton: true,
								message: `
									The long-text is currently ${segment.longText.length} characters long.
									Replace it with ${(outputText ?? "").length} characters?
								`.AsMultiline(0),
								onOK: proceed,
							});
						} else {
							proceed();
						}
					}}/>
				</Row>
				{uiState.cleanup_tab == CleanupTab.llmTemplate && <>
					<TextArea style={{border: "1px solid black"}} autoSize={true}
						value={uiState.cleanup_llmTemplate} onChange={val=>RunInAction_Set(this, ()=>uiState.cleanup_llmTemplate = val)}/>
				</>}
				{uiState.cleanup_tab == CleanupTab.llmGenerate &&
				<TextArea style={{border: "1px solid black"}} autoSize={true} value={llmOutput} onChange={val=>setLLMOutput(val)}/>}
			</Column>
		);
	}
}