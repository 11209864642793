import {E, ObjectCE, ToInt} from "js-vextensions";
import {useMemo} from "react";
import {UPlot} from "react-uplot";
import {Row} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import uPlot from "uplot";
import {Annotation, AnnotationsPlugin} from "uplot-vplugins";
import useResizeObserver from "use-resize-observer";
import {HSLA, Observer} from "web-vcore";
import {EngineSessionInfo} from "../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {store} from "../../../../Store/index.js";
import {GetSelectedSessionData_AllowingResim, SetCurrentTime} from "../../../../Store/main/timeline.js";
import {moment_local, Moment_ToDate_WithTimeZonePreApplied} from "../../../../Utils/General/General.js";
import {ES} from "../../../../Utils/UI/GlobalStyles.js";
import {createRef_withHooks} from "../../../../Utils/UI/ReactHelpers.js";
import {uplotDefaults} from "../../../../Utils/UI/UPlotDefaults.js";
import {paths_stepped} from "../../../../Utils/UI/UPlotPlugins.js";
import {GetPromptThresholdColor, sessionChartColors} from "./SessionChartColors.js";

@Observer
export class SeekBar extends BaseComponent<{session: EngineSessionInfo}, {}> {
	render() {
		const {session} = this.props;
		const uiState = store.main.timeline.sessions;
		return (
			<Row mt={5} style={E(
				{height: 200},
				/*//uiState.showDetailPanel && {height: 150},
				uiState.showDetailPanel && {height: 200},
				!uiState.showDetailPanel && ES({flex: 1}),
				//!uiState.showDetailPanel && {height: "calc(100% - 5px)"},*/
			)}>
				<SeekChart /*key={`heightMods:${uiState.showDetailPanel}`}*/ session={session}/>
			</Row>
		);
	}
}

export const SeekChart_lineTypes: (uPlot.Series & {key: string})[] = [
	{
		value: (self, rawValue)=>{
			const timeInMS = rawValue * 1000;
			const time = moment_local(timeInMS, self.session?.localOffsetFromUTC); // session set in EEGChart.chart hook's postSet handler
			return `${time.format("YYYY-MM-DD HH:mm:ss")}.${(timeInMS % 1000).FloorTo(1).toString().padStart(3, "0")}`;
		},
	} as any,
	{
		label: "EEG activity",
		value: (self, rawValue)=>`${rawValue != null ? rawValue.toFixed(2) : "n/a"}`,
		stroke: sessionChartColors.eegActivity,
		points: {show: false},
		// stepped-mode
		//paths: paths_stepped,
	},
	{
		label: "Sleep-stage",
		/*value: (self, rawValue)=>{
			/*if (rawValue == null) {
				// todo: interpolate
				return null;
			}
			return rawValue;*#/
			if (rawValue == 0) return "A"; // awake
			if (rawValue == 1) return "R"; // rem
			if (rawValue == 2) return "L"; // light
			if (rawValue == 3) return "D"; // deep
			return "U"; // unknown
		},*/
		stroke: sessionChartColors.sleepStage,
		width: 1.5,

		//spanGaps: true,

		scale: "z", // makes align to sleep-stage scale (right side)
		auto: false,
		paths: paths_stepped,
	},
];

/*export const promptColors = [
	HSL(0, 1, .5),
	HSL(120, 1, .5),
	HSL(240, 1, .5),
];*/

export let currentSeekChart: SeekChart|n;

@Observer
class SeekChart extends BaseComponentPlus({} as {session: EngineSessionInfo}, {}) {
	ComponentDidMount() { currentSeekChart = this; }
	ComponentWillUnmount() { currentSeekChart = null; }
	render() {
		const {session} = this.props;
		const uiState = store.main.timeline.sessions;
		const sessionData = GetSelectedSessionData_AllowingResim()!;

		//const annotations = [] as Annotation[];
		const annotations = useMemo(()=>[] as Annotation[], []);
		annotations.Clear();

		if (uiState.currentTime != 0) {
			annotations.push({
				type: "line",
				x: {value: uiState.currentTime / 1000},
				//x: {type: "valueOnAxis", value: uiState.currentTime / 1000, finalize: "floor"},
				//color: "white",
				color: sessionChartColors.currentTimeLine,
				lineWidth: 1,
				drawType: "destination-over",
			});
		}
		const enabledPrompts = sessionData.config.eeg.alarmSequence.alarms.filter(a=>a.enabled);
		annotations.AddRange(enabledPrompts.map((prompt, index)=>{
			return {
				type: "line",
				y: {value: prompt.startOffset},
				color: GetPromptThresholdColor(index, enabledPrompts.length),
				lineWidth: 1,
				drawType: "destination-over",
			} as const;
		}));
		const camRecordingColor = HSLA(60, 1, .5, .1);
		const camRecordingPeriods_final = sessionData.CamRecordingPeriods_Adjusted(uiState.videoLatency);
		annotations.AddRange(sessionData.generalHistory.camFilenames.map((name, index)=>{
			const period = camRecordingPeriods_final[index];
			return {
				type: "box",
				xMin: {value: period.start / 1000},
				xMax: {value: period.end / 1000},
				yMax: {value: 6},
				yMin: {value: 0},
				fillStyle: sessionChartColors.camRecordingRegion,
				drawType: "destination-over",
			} as const;
		}));

		// todo: remove this ref-keeping, and have react-uplot take care of it
		const {timeData, eegActivityData, sleepStageData, uplotData} = useMemo(()=>{
			const timeData = [] as number[];
			const eegActivityData = [] as number[];
			const sleepStageData = [] as number[];
			const uplotData = [
				timeData,
				eegActivityData,
				sleepStageData,
			] as uPlot.AlignedData;
			return {timeData, eegActivityData, sleepStageData, uplotData};
		}, []);
		timeData.Clear();
		eegActivityData.Clear();
		sleepStageData.Clear();

		const chartStartTime = moment_local(session.startTime, session.localOffsetFromUTC).startOf("minute");
		const chartEndTime = moment_local(session.endTime, session.localOffsetFromUTC).endOf("minute");
		const eegActivityChanges = ObjectCE(sessionData.generalHistory.eegActivity.activityByTime).Pairs();
		// add time and eeg-activity entries
		for (let timeVal = chartStartTime.valueOf(); timeVal < chartEndTime.valueOf(); timeVal += 60000) {
			const time = moment_local(timeVal, session.localOffsetFromUTC);
			//ticks.push({time: timeVal.valueOf(), label: time.format("HH:mm")});
			timeData.push(timeVal.valueOf() / 1000);

			// if at hour mark, add hour annotation
			// commented; add similar thing back at some point, but optional, with shown-offsets relative to cursor-pos
			/*if (time.minute() == 0) {
				annotations.push({
					type: "line",
					x: timeVal / 1000,
					color: HSLA(0, 0, 1, .5),
					lineWidth: 1,
				});
			}*/

			let activityValuesInMinute = eegActivityChanges.filter(a=>a.keyNum! >= timeVal && a.keyNum! < timeVal + 60000).map(a=>a.value);
			if (activityValuesInMinute.length == 0) {
				const activityValuesBeforeMinute = eegActivityChanges.filter(a=>a.keyNum! < timeVal + 60000).map(a=>a.value);
				activityValuesInMinute = [activityValuesBeforeMinute.LastOrX() ?? 0];
			}
			const maxEEGActivityInMinute = activityValuesInMinute.Max();
			//data.push({x: tick.label, y: maxEEGActivityInMinute} as any);
			eegActivityData.push(maxEEGActivityInMinute);

			const minuteI = (timeVal - chartStartTime.valueOf()) / 60000;
			// todo: fix that this only shows sleep-stage data per minute, not per 30s
			const sleepStageI = minuteI * 2;
			const highestSleepStageForMinute = Math.max(sessionData.eegSampleFiles.sleepStages[sleepStageI] ?? 0, sessionData.eegSampleFiles.sleepStages[sleepStageI + 1] ?? 0);
			sleepStageData.push(highestSleepStageForMinute);
		}

		// add eeg-activity data
		/*const eegActivityChanges = ObjectCE(sessionData.generalHistory.eegActivity.activityByTime).Pairs();
		for (const pair of eegActivityChanges) {
			const lastPair = eegActivityChanges[pair.index - 1];
			// if it's been more than a minute since the last eeg-activity change, don't draw line between it and us; use stepped-mode before this point
			if (lastPair && pair.keyNum - lastPair.keyNum > 60000) {
				//timeData.push(pair.keyNum / 1000);
				timeData.push((pair.keyNum - 60000) / 1000);
				eegActivityData.push(lastPair.value);
			}
			timeData.push(pair.keyNum / 1000);
			eegActivityData.push(pair.value);
		}*/

		//AddLine("Gyro motions", gyroMotionColor); // to show color
		for (const timeStr of sessionData.generalHistory.gyroMotion.motionsByTime.VKeys()) {
			const time = ToInt(timeStr);
			annotations.push({
				type: "box",
				xMin: {value: time / 1000},
				//xMax: {value: time / 1000},
				xSize: {pixel: 1},
				yMax: {value: 3},
				yMin: {value: 0},
				fillStyle: sessionChartColors.gyroMotionColor,
				drawType: "destination-over",
			});
		}
		//AddLine("Cam recording", camRecordingColor); // to show color

		//AddLine("View-range", viewRangeColor); // to show color
		annotations.push({
			type: "box",
			xMin: {value: uiState.viewRange_startTime / 1000},
			xMax: {value: uiState.viewRange_endTime / 1000},
			yMin: {pixel: "0%"},
			yMax: {pixel: "100%"},
			fillStyle: sessionChartColors.viewRangeRegion,
		});

		const {ref: rootRef, width = 300, height = 100} = useResizeObserver();
		//const {ref: rootRef, width = 300, height = 100} = {ref: null, width: 300, height: 300}; // test
		const chartOptions: uPlot.Options = useMemo(()=>({
			id: `chart_seekBar`,
			class: "seek-bar",
			width,
			height,
			plugins: [
				AnnotationsPlugin({
					annotations,
				}),
			],
			cursor: {
				drag: {x: false, setScale: false},
			},
			tzDate: rawValue=>{
				const timeInMS = rawValue * 1000;
				const time_creatorLocal = moment_local(timeInMS, session?.localOffsetFromUTC); // session set in EEGChart.chart hook's postSet handler
				return Moment_ToDate_WithTimeZonePreApplied(time_creatorLocal);
			},
			legend: {show: false},
			axes: [
				{
					...uplotDefaults.axes_props,
					size: 50,
				},
				{
					...uplotDefaults.axes_props,
					size: 50,
				},
				{
					...uplotDefaults.axes_props,
					size: 50,
					grid: {show: false},
					// special for z-scale
					scale: "z",
					side: 1,
					//space: 1,
					//range: (u, min, max)=>[Math.ceil(min), Math.max(1, Math.ceil(max))], // keep max at least 1

					// needed to avoid crash in setScale("...")!
					//incrs: [1, 5, 10, 50, 100, 500, 1000],
					//splits: [1],

					values: (self, vals)=>vals.map(val=>{
						if (val == 3) return "A"; // awake
						if (val == 2) return "R"; // rem
						if (val == 1) return "L"; // light
						if (val == 0) return "D"; // deep
						return "U"; // unknown
					}),
				},
			],
			scales: {
				x: {
					//time: false
					auto: false,
					min: session.startTime / 1000,
					max: session.endTime / 1000,
				},
				y: {
					//auto: false,
					auto: true,
					//min: -100, max: 100,
					/*range: (u, dataMin, dataMax)=>{ // use func, since stable
						return [-100, 100] as [number, number];
					},*/
				},
				z: {
					auto: false,
					range: (u, min, max)=>{
						return [0, 3];
					},
				},
			},
			series: SeekChart_lineTypes,
		}), [width, height, session]);

		return (
			<div ref={rootRef as any} style={ES({position: "relative", width: "100%", height: "100%"})}>
				<UPlot chartRef={this.chart} options={chartOptions} data={uplotData}/>
			</div>
		);
	}
	chart = createRef_withHooks<uPlot>({
		postSet: chart=>{
			if (chart == null) return;
			chart["session"] = this.props.session;
			const uplotDiv = chart.root as HTMLDivElement;
			const doubleClickStartDiv = uplotDiv.querySelector(".u-over") as HTMLDivElement;
			// prevent uplot's double-click-to-reset-zoom behavior
			uplotDiv.addEventListener("dblclick", e=>{
				e.stopPropagation();
			}, {capture: true});

			doubleClickStartDiv.addEventListener("mousedown", e=>(e.target ?? {})["mouseDown"] = true);
			doubleClickStartDiv.addEventListener("mouseup", e=>(e.target ?? {})["mouseDown"] = false);
			doubleClickStartDiv.addEventListener("mousemove", e=>{
				if (!(e.target ?? {})["mouseDown"]) return;
				const {session} = this.props;
				const clickedTime = chart.posToVal(e.offsetX, "x") * 1000;
				if (clickedTime.IsBetween(session.startTime, session.endTime)) {
					SetCurrentTime(clickedTime, this.props.session);
				}
			});
			doubleClickStartDiv.addEventListener("click", e=>{
				const {session} = this.props;
				const clickedTime = chart.posToVal(e.offsetX, "x") * 1000;
				if (clickedTime.IsBetween(session.startTime, session.endTime)) {
					SetCurrentTime(clickedTime, this.props.session);
				}
			});
		},
	});

	ComponentDidUpdate() {
		const chart = this.chart.current;
		if (chart == null) return;
		const xScale_old = {min: chart.scales.x.min!, max: chart.scales.x.max!};
		//chart.setData(data, false);
		//chart.setData(data, true);
		//chart.redraw(true);

		// calling setScale triggers a chart redraw
		chart.batch(()=>{
			//Assert([xScale_old.min, xScale_old.max].every(a=>!IsNaN(a)), "Found NaN in new scale values. (EEGChart.tsx)");
			chart.setScale("x", xScale_old);
		});
	}

	/*engineConfig: FBAConfig;
	StartEngineConfigMirrorer() {
		autorun(()=>{
			this.engineConfig = GetSelectedFBAConfig();
		});
	}*/
}