import {SleepAsync, Timer, TimerContext} from "js-vextensions";
import {Button, CheckBox, Row, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {Sound, SoundType} from "../../Store/firebase/sounds/@Sound";
import {SoundPlayer} from "../../Utils/EffectPlayers/SoundPlayer";
import {DisableSleepBlockers, EnableSleepBlockers_Repeating, SleepBlockerOptions} from "../../Utils/General/SleepBlockers";
import {Observer, PageContainer, secondInMS} from "web-vcore";

/*
Old issue: On Android, EnableSleepBlockers() doesn't completely work. The Java side stays awake, but the JS side stops running timers, fetches, etc. after a while (~5mins)

Old notes (before update):
* Shows up after: ~5mins (History: 5:10, 5:00, 5:00, 5:06)
* Appears to be caused by Doze and/or App Standby: https://developer.android.com/training/monitoring-device-state/doze-standby.html
* Issue does NOT show up if the soundPlayer.Play() line is left uncommented below. (so playing youtube-video every 5secs prevents the issue)
* Issue does NOT show up if the EnableFullLockJS() line (inside testTimer tick-func) is left uncommented below. (similar to above)
* [not yet confirmed:] Issue does NOT show up when device is connected over USB. (so when testing, disconnect usb link and use over-wifi debugging, ie. adb over wifi, and chrome:inspect)

New notes (after update):
* [not yet confirmed:] Calling new EnableSleepBlockers() [updated to call EnableFullLockJS() on a timer], appears to prevent issue-part-1 (Doze sleeping device)
	[well, for ~6 hours: 352m:55s, for timer ticking; hopefully it only stopped because of power-saving-mode, due to battery being close to dead]
	[Someone else seemed to have a similar outcome, of doze eventually activating after ~7h, even with request-ignore-battery-optimizations: https://github.com/siacs/Conversations/issues/1624#issuecomment-168345517]
* [not yet confirmed:] Running the foreground service [so starting FBA remote], appears to prevent issue-part-2 (App Standby sleeping app)
* Something to try (haven't yet): Disable battery-saver and such for apps: Browser, Chrome (If works, add note to info-button in Settings panel.)
	(Reason: One might be the app that actually "owns" the js code.: https://capacitor.ionicframework.com/docs/getting-started/dependencies/#android-development)
* Another to try (both main approach, the foreground service, as well as the functions it exposes, like disableBatteryOptimizations()): https://github.com/katzer/cordova-plugin-background-mode
* Test this: According to [https://stackoverflow.com/a/32636301/2441655], RequestIgnoreBatteryOptimizations should give your app normal access to the web. (even if, perhaps, other things are restricted, like timers)
*/

let tickCount = 0;
let firstTickTime: number;
let lastTickTime: number;
const sound = new Sound({type: SoundType.YoutubeClip, videoID: "SD4XIYlFD9c"});
const soundPlayer = new SoundPlayer(sound);
//soundPlayer.youtubePlayer.loop = true;
const testTimer = new Timer(5000, async()=>{
	const tickID = ++tickCount;
	const timeSinceFirstTick = Date.now() - (firstTickTime || Date.now());
	const secondsSinceFirstTick = (timeSinceFirstTick / 1000).RoundTo(1);
	const timeSinceLastTick = Date.now() - (lastTickTime || Date.now());
	const secondsSinceLastTick = (timeSinceLastTick / 1000).RoundTo(1);
	firstTickTime = firstTickTime || Date.now();
	lastTickTime = Date.now();

	const timeStr = `${Math.floor(secondsSinceFirstTick / 60)}m:${(secondsSinceFirstTick % 60).RoundTo(1)}s`;
	console.log(`${tickID} (part 1, ${timeStr}, delay: ${secondsSinceLastTick.toString().padStart(3, " ")}): Playing audio + Calling fetch... @PageOnline(${navigator.onLine})`);
	//soundPlayer.Play(1, false);
	//EnableSleepBlockers(true, false, false, false); // only do "full lock", ie. js-sleep-blockers (creating and playing a short, in-page, data-url-based video)
	//EnableFullLockJS();

	const waitTimeInSeconds = 4;
	let timeoutOccurred = false;

	let fetchResult = `n/a (didn't respond within ${waitTimeInSeconds}s)`;
	const fetchPromise = fetch("/Images/Basic/Black_1x1.png", {cache: "no-cache"})
		.then(response=>{
			if (!response.ok) fetchResult = `fail (status: ${response.status}, ${response.statusText})`;
			//else fetchResult =  response.text();
			else fetchResult = "success";
			if (timeoutOccurred) LogHeavy_Part2();
		})
		.catch(error=>{
			fetchResult = `fail (error: ${error})`;
			if (timeoutOccurred) LogHeavy_Part2();
		});

	// todo: if possible, also add way to see if firebase specifically (the db, etc.) is connected
	await Promise.race([SleepAsync(waitTimeInSeconds * secondInMS), fetchPromise]);
	const LogHeavy_Part2 = ()=>{
		console.log(`${tickID} (part 2, ${timeStr}, delay: ${secondsSinceLastTick.toString().padStart(3, " ")}): @ImageFetch(${fetchResult}) @FinishedBefore${waitTimeInSeconds}sTimeout(${!timeoutOccurred})`);
	};
	LogHeavy_Part2();
	timeoutOccurred = true; // if accessed by async handlers above, a timeout must have occurred before they ran
}).SetContext(TimerContext.default); // make sure in default TimerContext, so we can test the ManuallyTriggerOverdueTimers() function
g.testTimer = testTimer;

let sleepBlockerRepeaterTimer: Timer|n;

@Observer
export class TestsUI extends BaseComponentPlus({} as {}, {blocker_fullLock_js: true, blocker_partialLock: true, blocker_wifiLock: true, blocker_antiBatteryOptimizations: true, blocker_antiWebViewOptimizations: true}) {
	render() {
		const {blocker_fullLock_js, blocker_partialLock, blocker_wifiLock, blocker_antiBatteryOptimizations, blocker_antiWebViewOptimizations} = this.state;
		return (
			<PageContainer scrollable={true}>
				<Row>
					<Text>Sleep blockers:</Text>
					<CheckBox ml={5} text="FullLock_JS" value={blocker_fullLock_js} onChange={val=>this.SetState({blocker_fullLock_js: val})}/>
					<CheckBox ml={5} text="PartialLock" value={blocker_partialLock} onChange={val=>this.SetState({blocker_partialLock: val})}/>
					<CheckBox ml={5} text="WifiLock" value={blocker_wifiLock} onChange={val=>this.SetState({blocker_wifiLock: val})}/>
					<CheckBox ml={5} text="AntiBatteryOptimizations" value={blocker_antiBatteryOptimizations} onChange={val=>this.SetState({blocker_antiBatteryOptimizations: val})}/>
					<CheckBox ml={5} text="AntiWebViewOptimizations" value={blocker_antiWebViewOptimizations} onChange={val=>this.SetState({blocker_antiWebViewOptimizations: val})}/>
				</Row>
				<Row mt={5}>
					<Button text={`${testTimer.Enabled ? "Stop" : "Start"} check-awake timer`} onClick={()=>{
						const blockerOptions = {
							fullLock_js: blocker_fullLock_js, partialLock: blocker_partialLock, wifiLock: blocker_wifiLock,
							antiBatteryOptimizations: blocker_antiBatteryOptimizations, antiWebViewOptimizations: blocker_antiWebViewOptimizations,
						} as SleepBlockerOptions;
						if (sleepBlockerRepeaterTimer == null) {
							sleepBlockerRepeaterTimer = EnableSleepBlockers_Repeating(blockerOptions, blockerOptions, 30000);
							testTimer.Start();
						} else {
							sleepBlockerRepeaterTimer.Stop();
							sleepBlockerRepeaterTimer = null;
							DisableSleepBlockers(blockerOptions);
							testTimer.Stop();
						}
						this.Update();
					}}/>
				</Row>
			</PageContainer>
		);
	}
}