import {Switch} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../Store/index.js";
import {Me, MeID} from "../Store/firebase/users";
import {Observer, PageContainer, SubNavBar_Auto} from "web-vcore";
import {HasModPermissions} from "../Store/firebase/users/$user";
import {ActivitiesUI} from "./Timeline/Activities";
import {TimelineUI} from "./Timeline/TimelineUI";
import {SessionsUI} from "./Timeline/Sessions";

export const columnWidths = [.64, .06, .12, .18];

@Observer
export class TimelinePageUI extends BaseComponentPlus({}, {}) {
	render() {
		//const user = Me();
		const currentSubpage = store.main.timeline.subpage;
		//if (user == null) return null;
		const page = "timeline";

		return (
			<>
				<SubNavBar_Auto page={page} filter={a=>a.key == "sessions" || HasModPermissions(MeID())}/>
				<Switch>
					<SessionsUI/>
					{currentSubpage == "timeline" && <TimelineSubpageUI/>}
					{currentSubpage == "activities" && <ActivitiesUI/>}
				</Switch>
			</>
		);
	}
}

class TimelineSubpageUI extends BaseComponentPlus({}, {}) {
	render() {
		let {} = this.props;
		return (
			<PageContainer preset="full" style={{margin: 0, padding: 0}}>
				<TimelineUI user={Me()!} days={7}/>
			</PageContainer>
		);
	}
}