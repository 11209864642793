import {EffectPointerPlayer} from "../../../Store/firebase/@Shared/EffectPointerPlayer.js";
import {FBAConfig_RealityCheck} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_RealityCheck.js";
import {GetYValuesForSamplesGraphYType} from "../../../UI/@Shared/NavBar/SettingsPanel/Transcribe/SamplesAggregation.js";
import {SamplesGraph_GetTicks, SamplesGraphYType} from "../../../UI/@Shared/NavBar/SettingsPanel/Transcribe/SamplesGraph.js";
import {summarySamples_bufferOfLast60s} from "../../../Utils/Bridge/Bridge_Native/PhoneSensors.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {GetRandomNumber, Timer, Range} from "js-vextensions";

export class RealityCheckComp extends EngineSessionComp<FBAConfig_RealityCheck> {
	constructor(session: FBASession, config: FBAConfig_RealityCheck) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("RealityCheck_SignalRC", this.c.signalRC_triggerSet, this, {}, async triggerInfo=>{
				// we could avoid "adding two hit events in a row", but there's some use-cases for them (eg. for marking spontaneous RCs)
				this.s.AsLocal!.AddEvent({type: "RealityCheck.ReminderHit"});
				this.PlaySignalRCEffect();
			}),
		];
	}

	reminderDelayTimer = new Timer(1_000_000_000, ()=>{
		this.TryPlayReminderEffect();
		this.StartNextReminderDelay();
	}, 1).SetContext(this.s.timerContext);
	GetNextReminderDelayInMS() {
		const reminderDelayInS = GetRandomNumber({min: this.c.reminder_minDelay, max: this.c.reminder_maxDelay});
		return reminderDelayInS * 1_000;
	}
	StartNextReminderDelay() {
		this.reminderDelayTimer.Start(this.GetNextReminderDelayInMS());
	}

	OnStart() {
		this.signalRC_effectPlayer.effectPointer = this.c.signalRC_effect;
		this.signalRC_effectPlayer.OnStart();
		this.effectPlayer.effectPointer = this.c.reminder_effect;
		this.effectPlayer.OnStart();

		if (!this.IsSuspended()) {
			this.StartNextReminderDelay();
		}
	}
	OnStop() {
		this.signalRC_effectPlayer.Stop();
		this.effectPlayer.Stop();
	}

	Unsuspend(): void {
		super.Unsuspend();
		this.StartNextReminderDelay();
	}
	Suspend(): void {
		super.Suspend();
		this.reminderDelayTimer.Stop();
		this.signalRC_effectPlayer.Stop();
		this.effectPlayer.Stop();
	}

	// players
	signalRC_effectPlayer = new EffectPointerPlayer();
	effectPlayer = new EffectPointerPlayer();

	async PlaySignalRCEffect() {
		await this.signalRC_effectPlayer.Play();
	}

	async TryPlayReminderEffect() {
		const fakeUIState = {yType: SamplesGraphYType.absAvg, graphTimeToShow: 10, smoothing: 10};
		const ambientNoise = GetYValuesForSamplesGraphYType(
			fakeUIState,
			SamplesGraph_GetTicks(fakeUIState, false), SamplesGraph_GetTicks(fakeUIState, true), 
			summarySamples_bufferOfLast60s, SamplesGraphYType.absAvg,
		).LastOrX() ?? 0;

		if (ambientNoise <= this.c.reminder_maxAmbientNoise) {
			await this.PlayReminderEffect(ambientNoise);
		} else {
			this.s.AsLocal!.AddEvent({type: "RealityCheck.ReminderSkip", ambientNoise});
		}
	}
	async PlayReminderEffect(ambientNoise: number) {
		this.s.AsLocal!.AddEvent({type: "RealityCheck.ReminderPlay", ambientNoise});
		await this.effectPlayer.Play();
	}
}