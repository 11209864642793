import {GetValues_ForSchema} from "js-vextensions";
import {YoutubeSpeed, YoutubeQuality, GetVoices} from "web-vcore";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {AddSchema} from "mobx-firelink";
import {store} from "../../index.js";

export enum SoundType {
	Beep = 5,
	YoutubeClip = 10,
	Speech = 20,
}

export enum EffectVisibility {
	Visible = 10,
	Hidden = 20,
}

export enum WaveType {
	sine = "sine",
	square = "square",
	triangle = "triangle",
	sawtooth = "sawtooth",
}

export class Sound {
	constructor(initialData: Partial<Sound>) {
		this.VSet(initialData);
		Sound.InitDefaults(this);
		this.VSet(initialData);
	}
	static InitDefaults(s: Sound) {
		s.VSet({
			type: s.type ?? SoundType.YoutubeClip,
			visibility: s.visibility ?? EffectVisibility.Visible,
		});

		if (s.type == SoundType.Beep) {
			s.VSet({
				volume: s.volume || 1,
				waveFrequency: 200,
				waveType: WaveType.sine,
				waveDuration: 100,
			});
		} if (s.type == SoundType.YoutubeClip) {
			s.VSet({
				startTime: s.startTime || 0, endTime: s.endTime || 0, volume: s.volume || 1,
				speed: s.speed || YoutubeSpeed["100%"], quality: s.quality || YoutubeQuality.default,
				loop: false, loopInterval: 0,
			});
		} else if (s.type == SoundType.Speech) {
			const voice = s.voice || GetVoices()?.find(a=>a.name == store.main.settings.audio.mainVoiceName) || GetVoices()[0]?.name;
			s.VSet({
				volume: s.volume || 1, speed: s.speed || 1, voice, pitch: s.pitch || 1,
				loop: false, loopInterval: 0,
			});
		}
	}

	_key: string;
	creator: string;
	createdAt: number;

	name: string;
	type: SoundType;
	visibility: EffectVisibility;
	tags: string[];

	// shared
	volume: number; // for: all
	speed: number; // for: yt, speech
	loop: boolean; // for: yt, speech?
	loopInterval: number; // in seconds (0 for segment-length) // for yt, speech?

	// beep
	waveFrequency: number;
	waveType: WaveType;
	waveDuration: number;

	// youtube clip
	videoID: string;
	startTime: number;
	endTime: number;
	quality: YoutubeQuality;

	// speech
	text: string;
	voice?: string;
	pitch: number;
}
AddSchema("Sound", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},

		name: {type: "string", pattern: name_general},
		type: {oneOf: GetValues_ForSchema(SoundType)},
		visibility: {oneOf: GetValues_ForSchema(EffectVisibility)},
		tags: {items: {type: "string"}},

		volume: {type: "number"},
		//speed: {oneOf: GetValues_ForSchema(YoutubeSpeed)},
		speed: {type: "number"},
		loop: {type: "boolean"},
		loopInterval: {type: "number"},

		waveFrequency: {type: "number"},
		waveType: {oneOf: GetValues_ForSchema(WaveType)},
		waveDuration: {type: "number"},

		videoID: {type: "string"},
		startTime: {type: "number"},
		endTime: {type: "number"},
		quality: {oneOf: GetValues_ForSchema(YoutubeQuality)},

		text: {type: "string"},
		voice: {type: ["null", "string"]},
		pitch: {type: "number"},
	},
});