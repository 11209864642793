import {Bridge, ToJSON, Assert} from "js-vextensions";
import {ShakeLevel} from "../../Store/firebase/shakes/@Shake";
import {HostClientChannel, HostClientChannel_shortNames, HostFindType} from "../../Store/main/tools/engine";
import {nativeBridge} from "../../Utils/Bridge/Bridge_Native";
import {remoteUserProcessesBridge, processID} from "../../Utils/Bridge/Bridge_RemoteUserProcesses";
import {PlayShakeLevel, StopShake} from "../../Utils/Bridge/Bridge_Native/SonicBomb";
import {remoteDesktopBridge, RemoteDesktop_DestroyBridgeAndConnection, RemoteDesktop_InitBridge} from "../../Utils/Bridge/Bridge_RemoteDesktop";
import {AppInstanceInfo, GetOwnInstanceInfo} from "../../Utils/Bridge/SiteInstanceBridgeManager";
import {FBAConfig} from "../../Store/firebase/fbaConfigs/@FBAConfig";
import {O, RunInAction_Set} from "web-vcore";
import {liveFBASession} from "../FBASession";
import {makeObservable} from "mobx";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";

export class HostConnection {
	constructor(initialData?: Partial<HostConnection>) {
		makeObservable(this);
		this.VSet(initialData);
	}

	channel: HostClientChannel;
	get ChannelStr() { return ` (through ${HostClientChannel_shortNames[this.channel]} channel)`; }
	hostInfo: AppInstanceInfo;
	//hostProcessID: number;

	bridge: Bridge;
	CreateBridge() {
		// hostConnection.bridge is a level-2 bridge built on top of the level-1 remoteUserProcessesBridge/remoteDesktopBridge
		this.bridge = new Bridge({
			receiveChannelMessageFunc_adder: receiveChannelMessageFunc=>{
				const ReceiveChannelMessageFromHost = (clientProcessID: number, channelMessage)=>{
					if (clientProcessID != processID) return;
					SessionLog(`Receiving message from host${this.ChannelStr}: ${ToJSON(channelMessage)}`);
					receiveChannelMessageFunc(channelMessage);
				};
				if (this.channel == HostClientChannel.RealtimeDatabase) {
					remoteUserProcessesBridge.RegisterFunction("ClientX_ReceiveChannelMessageFromHost", ReceiveChannelMessageFromHost);
				} else {
					remoteDesktopBridge.RegisterFunction("ClientX_ReceiveChannelMessageFromHost", ReceiveChannelMessageFromHost);
				}
			},
			sendChannelMessageFunc: channelMessage=>{
				SessionLog(`Sending message to host${this.ChannelStr}: ${ToJSON(channelMessage)}`);
				// the implementation of HostX_ReceiveChannelMessageFromClient is in ClientConnection.CreateBridge
				if (this.channel == HostClientChannel.RealtimeDatabase) {
					remoteUserProcessesBridge.Call("HostX_ReceiveChannelMessageFromClient", this.hostInfo.processID, channelMessage);
				} else {
					remoteDesktopBridge.Call("HostX_ReceiveChannelMessageFromClient", this.hostInfo.processID, channelMessage);
				}
			},
			channel_wrapBridgeMessage: false,
			channel_stringifyChannelMessageObj: false,
		});

		this.bridge.RegisterFunction("OnActiveHostSessionConfigSet", (config: FBAConfig)=>{
			RunInAction_Set(this, ()=>this.activeHostSessionConfig = config);
		});

		// todo: make sure this is safe from arbitrary code execution
		this.bridge.RegisterFunction("TryCallOnComp", (compTypeName: string, methodName: string, ...methodArgs: any[])=>{
			liveFBASession!.TryCallOnComp(compTypeName, methodName, ...methodArgs);
		});

		this.bridge.RegisterFunction("SonicBomb_PlayShakeLevel_OnRemote", (shakeLevel: ShakeLevel, strengthMultiplier: number)=>{
			PlayShakeLevel(shakeLevel, strengthMultiplier);
		});
		this.bridge.RegisterFunction("SonicBomb_StopShake_OnRemote", ()=>{
			StopShake();
		});
	}
	DestroyBridge() {
		if (hostConnection!.channel == HostClientChannel.RealtimeDatabase) {
			remoteUserProcessesBridge.UnregisterFunction("ClientX_ReceiveChannelMessageFromHost");
		} else {
			remoteDesktopBridge.UnregisterFunction("ClientX_ReceiveChannelMessageFromHost");
		}
	}

	@O activeHostSessionConfig: FBAConfig;
}
export let hostConnection: HostConnection|n;

/*remoteUserProcessesBridge.RegisterFunction("ConnectToHost", (hostProcessID: number, clientProcessID: number, channel: HostClientChannel)=> {
	if (hostProcessID != processID) return;
	ConnectToHost(clientProcessID, channel);
});*/
export async function ConnectToHost(hostInfo: AppInstanceInfo, channel: HostClientChannel) {
	Assert(hostConnection == null, "Cannot connect to new host when a host is already connected.");
	SessionLog(`Connecting to host: ${ToJSON(hostInfo)}`);

	if (channel == HostClientChannel.Websockets) {
		await RemoteDesktop_InitBridge(hostInfo);
	}

	hostConnection = new HostConnection({hostInfo, channel});
	hostConnection.CreateBridge();

	// now wait for host to connect to us as well
	await remoteUserProcessesBridge.Call("ConnectToClient", hostInfo.processID, GetOwnInstanceInfo(), channel);
}
export async function DisconnectFromHost(tellHostToDisconnect = true) {
	Assert(hostConnection != null, "Cannot disconnect from host when none is connected!");
	SessionLog(`Disconnecting from host: ${ToJSON(hostConnection?.hostInfo)}`);

	const hostConnection_copy = hostConnection;
	hostConnection.DestroyBridge();
	hostConnection = null;

	if (hostConnection_copy.channel == HostClientChannel.Websockets) {
		RemoteDesktop_DestroyBridgeAndConnection();
	}

	// now wait for host to disconnect from us as well
	if (tellHostToDisconnect) {
		await remoteUserProcessesBridge.Call("DisconnectFromClient", hostConnection_copy.hostInfo.processID);
	}
}