import {CachedTransform, IsNumber} from "js-vextensions";
import {StoreAccessor, GetDocs, WhereOp, GetDoc} from "mobx-firelink";
import {TimelineEvent} from "./timelineEvents/@TimelineEvent";

/** Returns the timeline-events for the given timeline. */
export const GetTimelineEvents = StoreAccessor(s=>(timelineID: string|n): TimelineEvent[]=>{
	return GetDocs({
		queryOps: [new WhereOp("timeline", "==", timelineID)],
	}, a=>a.timelineEvents);
});
/** Returns the timeline-events for the given timeline, which intersect with the time period specified. */
export const GetTimelineEventsIntersectingRange = StoreAccessor(s=>(timelineID: string, rangeStart: number, rangeEnd?: number): TimelineEvent[]=>{
	const closedEventsInRange = GetDocs({
		queryOps: [
			//new WhereOp("timeline", "==", timelineID), // commented, since would require composite-index
			new WhereOp("endTime", ">=", rangeStart), // make sure event ended after range's start
			//rangeEnd != null && new WhereOp("startTime", "<", rangeEnd), // make sure event started prior to range's end // commented, since can only use range-filter on one field
		].filter(a=>a),
	}, a=>a.timelineEvents).filter(event=>{
		return rangeEnd == null || event.startTime < rangeEnd; // make sure event started prior to range's end
	});

	const ongoingEvents = GetDocs({
		queryOps: [new WhereOp("endTime", "==", null)],
	}, a=>a.timelineEvents);
	const ongoingEventsInRange = ongoingEvents.filter(event=>(rangeEnd == null || event.startTime < rangeEnd));

	const allEventsInRange = closedEventsInRange.concat(ongoingEventsInRange);
	return allEventsInRange.filter(a=>a.timeline == timelineID).OrderBy(a=>a.startTime);
});

export const GetTimelineEvent = StoreAccessor(s=>(id: string)=>{
	return GetDoc({}, a=>a.timelineEvents.get(id));
});

/*export const GetInProgressTimelineEvents = StoreAccessor(s=>(timelineID: string)=>{
	return GetDocs({
		queryOps: [
			new WhereOp("timeline", "==", timelineID),
			new WhereOp("endTime", "<", ""), // `if value < ""` means "if value is null"
		],
	}, a=>a.timelineEvents).OrderBy(a=>a.startTime);
});*/