import {E, ModifyString} from "js-vextensions";
import {Button, Column, Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Observer, RunInAction_Set} from "web-vcore";
import React from "react";
import {store} from "../../../Store/index.js";
import {BottomPanelSubpanel} from "../../../Store/main/tools/journey.js";
import {EntitiesPanel, EntitiesPanel_Header} from "./BottomPanel/EntitiesPanel.js";
import {GetTranscribePanelHeightOverride, TranscribePanel, TranscribePanel_Header} from "./BottomPanel/TranscribePanel.js";
import {LLMPanel, LLMPanel_Header} from "./BottomPanel/LLMPanel.js";

function GetBottomPanelContentHeightOverride() {
	const uiState = store.main.tools.journey;
	if (uiState.bottomPanelSubpanel == BottomPanelSubpanel.none) return 0;
	if (uiState.bottomPanelSubpanel == "transcribe") return GetTranscribePanelHeightOverride();
	return null;
}

@Observer
export class JBottomPanel extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey;
		const bottomPanelContentHeightOverride = GetBottomPanelContentHeightOverride();

		return (
			<Column style={E(
				{flex: 40},
				bottomPanelContentHeightOverride != null && {flex: null, height: 40 + bottomPanelContentHeightOverride},
				uiState.bottomPanelSubpanel == BottomPanelSubpanel.llm && uiState.llm.panelHeight != 40 && {minHeight: `calc((100% - 32px) * ${uiState.llm.panelHeight.FromPercent()})`},
			)}>
				<Row>
					<SubpanelButton subpanel={BottomPanelSubpanel.none}/>
					<SubpanelButton subpanel={BottomPanelSubpanel.entities}/>
					<SubpanelButton subpanel={BottomPanelSubpanel.transcribe}/>
					<SubpanelButton subpanel={BottomPanelSubpanel.llm}/>
					<Row ml="auto">
						{uiState.bottomPanelSubpanel == BottomPanelSubpanel.entities && <EntitiesPanel_Header/>}
						{uiState.bottomPanelSubpanel == BottomPanelSubpanel.transcribe && <TranscribePanel_Header/>}
						{uiState.bottomPanelSubpanel == BottomPanelSubpanel.llm && <LLMPanel_Header/>}
					</Row>
				</Row>
				{uiState.bottomPanelSubpanel == BottomPanelSubpanel.entities && <EntitiesPanel/>}
				{uiState.bottomPanelSubpanel == BottomPanelSubpanel.transcribe && <TranscribePanel/>}
				{uiState.bottomPanelSubpanel == BottomPanelSubpanel.llm && <LLMPanel/>}
			</Column>
		);
	}
}

@Observer
class SubpanelButton extends BaseComponent<{subpanel: BottomPanelSubpanel}, {}> {
	render() {
		let {subpanel} = this.props;
		const uiState = store.main.tools.journey;
		return (
			<Button p="3px 0"
				mdIcon={
					subpanel == BottomPanelSubpanel.none ? "moon-new" :
					subpanel == BottomPanelSubpanel.entities ? "cube-outline" :
					subpanel == BottomPanelSubpanel.transcribe ? "transcribe" :
					"robot-outline"
				}
				title={ModifyString(subpanel, m=>[m.startLower_to_upper])}
				style={E(
					{flex: null, width: 40, height: 40},
					{":hover": ""}, // clear the regular styling for this button on-hover, so custom background-color always shows
					uiState.bottomPanelSubpanel == subpanel && {backgroundColor: "green"},
					subpanel == BottomPanelSubpanel.transcribe && uiState.transcribe.transcribeText?.trim().length > 0 && {color: "rgb(255,255,100)"},
				)}
				onClick={()=>{
					RunInAction_Set(uiState, ()=>uiState.bottomPanelSubpanel = subpanel);
				}}/>
		);
	}
}