import {Clone, GetEntries, GetErrorMessagesUnderElement, E, CloneWithPrototypes} from "js-vextensions";
import Moment from "moment";
import {Column, Pre, RowLR, Select, TextArea, TextInput, Text, Spinner, Row, Button, CheckBox, TimeSpanInput} from "react-vcomponents";
import {BaseComponent, BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {Shake, ShakeType, patternPresets, ShakeLevel, ShakeSubtype} from "../../../Store/firebase/shakes/@Shake";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {IDAndCreationInfoUI} from "../../../UI/@Shared/CommonPropUIs/IDAndCreationInfoUI";
import {InfoButton, HSLA, observer_simple} from "web-vcore";
import {PlayShakeConfig, PlayShakeLevel} from "../../../Utils/Bridge/Bridge_Native/SonicBomb";
import {AddShake} from "../../../Server/Commands/AddShake";
import {BoxController, ShowMessageBox} from "react-vmessagebox";
import {GetUser} from "../../../Store/firebase/users";
import {SoundDetailsUI} from "../Sounds/SoundDetailsUI";
import {EffectPreviewButton} from "../../@Shared/TagTargetEntryUI";
import {EffectVisibility} from "../../../Store/firebase/sounds/@Sound";
import {DialogStyle} from "../../../Utils/UI/GlobalStyles.js";

export function ShowAddShakeDialog(initialData?: Partial<Shake>, postAdd?: (id: string)=>any) {
	let newEntry = new Shake(E({
		name: "",
		type: ShakeType.SonicBomb,
	}, initialData));
	const getCommand = ()=>new AddShake({shake: newEntry});

	const boxController: BoxController = ShowMessageBox({
		title: "Add shake", cancelButton: true,
		message: observer_simple(()=>{
			const tempCommand = getCommand();
			boxController.UpdateOptions({okButtonProps: {
				enabled: tempCommand.Validate_Safe() == null,
				title: tempCommand.validateError,
			}});

			return (
				<Column style={DialogStyle({width: 600})}>
					<ShakeDetailsUI baseData={newEntry} forNew={true}
						onChange={(val, error)=>{
							newEntry = val;
							boxController.UpdateUI();
						}}/>
				</Column>
			);
		}),
		onOK: async()=>{
			const id = await getCommand().Run();
			if (postAdd) postAdd(id);
		},
	});
}

type Props = {baseData: Shake, forNew: boolean, enabled?: boolean, style?, onChange?: (newData: Shake, ui: ShakeDetailsUI)=>void};
type Props_Enhanced = Props & State & {Change};
type State = {newData: Shake};

const splitAt = 120;
const width = "100%";

export class ShakeDetailsUI extends BaseComponentPlus({enabled: true} as Props, {} as State) {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.baseData != this.props.baseData) { // if base-data changed
			const newData = CloneWithPrototypes(props.baseData);
			//Object.setPrototypeOf(newData, Shake.prototype); // MS newData's class is "Shake", so we can use Shake's class methods directly
			//Object.setPrototypeOf(newData, Object.getPrototypeOf(new Shake({}))); // MS newData's class is "Shake", so we can use Shake's class methods directly
			this.SetState({newData});
		}
	}

	render() {
		const {baseData, forNew, enabled, style, onChange} = this.props;
		const {newData} = this.state;
		const Change = (..._)=>{
			if (onChange) onChange(this.GetNewData(), this);
			this.Update();
		};

		const propsEnhanced = {...this.props, Change, ...this.state, SetState: this.SetState};

		return (
			<Column style={style}>
				{!forNew &&
					<IDAndCreationInfoUI id={baseData._key} creatorID={newData.creator} createdAt={newData.createdAt}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Name: </Text>
					<TextInput pattern={name_general} required
						enabled={enabled} style={{width: "100%"}}
						value={newData.name} onChange={val=>Change(newData.name = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Type: </Text>
					<Select options={GetEntries(ShakeType)} enabled={enabled} style={{width: "100%"}}
						value={newData.type} onChange={val=>Change(newData.type = val, Shake.InitDefaults(newData))}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row>
						<CheckBox text="Loop" enabled={enabled} value={newData.loop} onChange={val=>Change(newData.loop = val)}/>
						<Text>, interval:</Text>
					</Row>
					<TimeSpanInput enabled={enabled && newData.loop} style={{width: "100%"}}
						value={newData.loopInterval} onChange={val=>Change(newData.loopInterval = val)}/>
				</RowLR>
				{newData.type == ShakeType.SonicBomb &&
					<SonicBombUI {...propsEnhanced}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Row center>
						<Pre>Visibility:</Pre>
						<InfoButton ml={5} text={`
							Visible: Shown publicly in the list of shake-configs.
							Hidden: Hidden in shake-config list (other than to mods), but still accessible through reading of raw db contents. (so not guarantee of privacy)
						`.AsMultiline(0)}/>
					</Row>
					<Select options={GetEntries(EffectVisibility)} enabled={enabled} value={newData.visibility} onChange={val=>Change(newData.visibility = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Suggested tags:</Text>
					<TextInput enabled={enabled} style={{width: "100%"}}
						value={(newData.tags || []).join(", ")} onChange={val=>Change(newData.tags = val.split(",").map(a=>a.trim()).filter(a=>a))}/>
				</RowLR>
				<EffectPreviewButton mt={5} group="shakes" entry={newData} startText="Preview shake" stopText="Stop preview" width={200}/>
			</Column>
		);
	}
	GetValidationError() {
		return GetErrorMessagesUnderElement(this.DOM)[0];
	}

	GetNewData() {
		const {newData} = this.state;
		return CloneWithPrototypes(newData) as Shake;
	}
}

class SonicBombUI extends BaseComponent<Props_Enhanced, {}> {
	render() {
		const {newData, enabled, Change} = this.props;
		return (
			<>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center mr={10}>
						<Text>Subtype:</Text>
						<InfoButton ml={5} text={`
							Simple: Only one pattern is used. The effect-strength is multiplied by the current in-engine strength.
							Composite: Multiple patterns; engine activates the last level that's still under the current in-engine strength. Effect-strength isn't modified.
						`.AsMultiline(0)}/>
					</Row>
					<Select enabled={enabled} style={{width: "100%"}} options={GetEntries(ShakeSubtype)}
						value={newData.subtype} onChange={val=>{
							newData.subtype = val;
							if (newData.subtype == ShakeSubtype.Simple && newData.levels?.length < 1) {
								newData.levels = [new ShakeLevel()];
							}
							Change();
						}}/>
				</RowLR>
				{newData.subtype == ShakeSubtype.Simple &&
					<ShakeLevelUI level={newData.levels[0]} index={0} {...this.props}/>}
				{newData.subtype == ShakeSubtype.Composite &&
				<>
					<Row center mt={5}>
						<Text style={{fontWeight: "bold"}}>Shake levels:</Text>
						{/*<InfoButton ml={5} text={`
							TODO
						`.AsMultiline(0)}/>*/}
						<Button ml={5} p="3px 7px" text="+" enabled={enabled} onClick={()=>{
							if (newData.levels == null) newData.levels = [];
							newData.levels.push(new ShakeLevel());
							Change();
						}}/>
					</Row>
					{(newData.levels || []).map((level, index)=>{
						return <ShakeLevelUI key={index} level={level} index={index} {...this.props}/>;
					})}
				</>}
			</>
		);
	}
}

class ShakeLevelUI extends BaseComponent<{level: ShakeLevel, index: number} & Props_Enhanced, {}> {
	render() {
		const {level, index, newData, enabled, Change} = this.props;
		const splitAt_final = newData.subtype == ShakeSubtype.Simple ? splitAt : 170;
		return (
			<Column mt={newData.subtype == ShakeSubtype.Composite ? 5 : 0} style={E(
				newData.subtype == ShakeSubtype.Composite && {background: HSLA(0, 0, 1, .3), borderRadius: 5, padding: 5},
			)}>
				{newData.subtype == ShakeSubtype.Composite &&
				<RowLR mt={5} splitAt={splitAt_final}>
					<Row center mr={10}>
						<Text>From strength min:</Text>
						<InfoButton ml={5} text={`
							The strength listed in the engine config, above which this shake-level is activated. (this doesn't change the *effect* strength)
						`.AsMultiline(0)}/>
					</Row>
					<Spinner enabled={enabled} style={{width: "100%"}} // delay, so doesn't re-order while typing
						value={(level.fromStrengthMin * 100).RoundTo(1)}
						onChange={val=>{
							level.fromStrengthMin = val / 100;
							// anytime a level's from-strength-min changes, make sure their order is correct
							newData.levels = newData.levels.OrderBy(a=>a.fromStrengthMin);
							Change();
						}} min={0} max={100}/>
				</RowLR>}
				<RowLR mt={5} splitAt={splitAt_final}>
					<Text mr={10}>Pattern: </Text>
					<TextInput enabled={enabled} style={{width: "100%"}} value={level.pattern} onChange={val=>Change(level.pattern = val)}/>
					<Select enabled={enabled} style={{width: "100%"}} options={[{name: "", value: null as string|n}].concat(patternPresets)}
						value={level.pattern} onChange={val=>Change(level.pattern = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt_final}>
					<Text mr={10}>Effect strength: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}}
						value={(level.effectStrength * 100).RoundTo(1)}
						onChange={val=>Change(level.effectStrength = val / 100)} min={0} max={100}/>
				</RowLR>
				{newData.subtype == ShakeSubtype.Composite &&
				<Row mt={5}>
					<Button text="Preview shake level" onClick={()=>{
						PlayShakeLevel(level, 1);
					}}/>
					<Button ml="auto" text="Delete" enabled={enabled} onClick={()=>{
						newData.levels.Remove(level);
						Change();
					}}/>
				</Row>}
			</Column>
		);
	}
}