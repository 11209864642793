import * as SentryCapacitor from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import {version} from "../../Main";
import {InAndroid} from "../Bridge/Bridge_Native.js";

// temp-fix (until switched to rspack, such that deploys do not falsely set PROD to false)
//const PROD_fixed = PROD || InAndroid(0);

export function InitSentry() {
	if (PROD && window.location.hostname != "localhost") { // if localhost, never enable Raven (even if env-override is set to production)
		SentryCapacitor.init({
			dsn: "https://5bfbd2cfa0da422f8af4a2de916ada1c@o72324.ingest.us.sentry.io/257916",
			release: version,
			environment: ENV,
		}, SentryReact.init);
	}
}