import {BaseComponent} from "react-vextensions";
import {Row, Button, RowProps} from "react-vcomponents";
import {Icon} from "web-vcore";
import {Range, E} from "js-vextensions";

export class StarsRating extends BaseComponent<{value: number, onChange: (value: number)=>any, allowRightClickFor0: boolean, titleFunc?: (starValue: number)=>string} & RowProps, {}> {
	static defaultProps = {titleFunc: starValue=>`Set to ${starValue} stars (right-click to clear)`};
	render() {
		const {value, onChange, allowRightClickFor0, titleFunc, ...rest} = this.props;
		return (
			<Row {...rest} style={{alignItems: "center"}}>
				{Range(1, 5).map(starValue=>{
					return (
						/*<Button key={starValue} text={<Icon icon="Star" size={15} style={E(value < starValue && {filter: "grayscale"})}/>} title={`Set rating to ${starValue} stars`} onClick={()=>{
							onChange(starValue);
						}}/>*/
						<Icon key={starValue} divContainer={true} icon="Star" size={15} style={E({cursor: "pointer"}, value < starValue && {filter: "grayscale(1)"})}
							title={titleFunc!(starValue)}
							onClick={()=>{
								onChange(starValue);
							}}
							onContextMenu={e=>{
								if (!allowRightClickFor0) return;
								e.preventDefault();
								onChange(0);
							}}/>
					);
				})}
			</Row>
		);
	}
}