import {BaseComponent} from "react-vextensions";

// todo: probably move to react-vcomponents
export class MDIcon extends BaseComponent<{icon: string, size: number, width?: number, height?: number, iconSize?: number, style?} & JSX.IntrinsicElements["span"], {}> {
	render() {
		let {icon, size, iconSize, width, height, style, ...rest} = this.props;
		width = width ?? size;
		height = height ?? size;
		iconSize = iconSize ?? size;
		return (
			<span {...rest} className={`mdi mdi-${icon}`} style={{display: "inline-flex", width, height, fontSize: iconSize, ...style}}/>
		);
	}
}

/*import Icon from '@mdi/react';
import { mdiHeadDotsHorizontal } from '@mdi/js';
<Icon path={mdiHeadDotsHorizontal} size={1} />*/