import {Clone} from "js-vextensions";
import {Column, Row, Select, Text, Button} from "react-vcomponents";
import {ScrollView} from "react-vscrollview";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {TimeToString} from "web-vcore";
import {SessionEvent, SessionEventType_values} from "../../../../Engine/FBASession/SessionEvent.js";
import {GetSessionPeriodStr} from "../../Sessions.js";
import {SessionUI_SharedProps} from "../SessionUI.js";

export class SessionUI_EventsUI extends BaseComponent<{} & SessionUI_SharedProps, {}> {
	render() {
		let {session, ChangeSession} = this.props;
		return (
			<Column style={{flex: 1}}>
				<Row style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: 18}}>{GetSessionPeriodStr(session)}</Row>
				<ScrollView>
					{session.events.map((entry, index)=>{
						const ChangeEvent = (changerFunc: (oldEvent: SessionEvent)=>any)=>{
							const newEvent = Clone(entry);
							changerFunc(newEvent);
							ChangeSession(newSession=>newSession.events[index] = newEvent);
						}
						return (
							<Row key={index} style={{padding: "5px 0"}}>
								<Text>Time:</Text>
								<Text ml={5} style={{width: 60}}>{TimeToString(entry.date, {date: 0})}</Text>
								<Text ml={5}>Type:</Text>
								<Select ml={5} options={SessionEventType_values} value={entry.type} onChange={val=>ChangeEvent(a=>a.type = val)}/>
								<Button ml={5} mdIcon="content-copy" title="Clone event. (placed at next index, with same time and type)" onClick={()=>{
									ChangeSession(newSession=>newSession.events.Insert(index + 1, Clone(entry)));
								}}/>
								<Button ml={5} mdIcon="delete" title="Delete event." onClick={()=>{
									ShowMessageBox({
										title: "Delete event?", cancelButton: true,
										message: `Permanently delete this event?`,
										onOK: ()=>ChangeSession(newSession=>newSession.events.RemoveAt(index)),
									});
								}}/>
							</Row>
						);
					})}
				</ScrollView>
			</Column>
		);
	}
}