import {keyNames} from "web-vcore";

// we customize/substitute some commonly-used key-names, so they look nicer in the UI
export const keyNames_rawToNormalMap = {
	AudioVolumeUp: "VolumeUp",
	AudioVolumeDown: "VolumeDown",
};
export const keyNames_replacements = keyNames_rawToNormalMap.Pairs().map(a=>({rawName: a.key, normalName: a.value}));
export const keyNames_normalToRawMap = keyNames_replacements.ToMapObj(a=>a.normalName, a=>a.rawName);

export const keyNames_normal = keyNames.slice();
keyNames_replacements.forEach(replacement=>{
	const oldIndex = keyNames_normal.indexOf(replacement.rawName);
	keyNames_normal.Remove(replacement.rawName);
	keyNames_normal.Insert(oldIndex, replacement.normalName);
});

export function GetNormalKeyName(keyName_rawOrNormal: string) {
	return keyNames_rawToNormalMap[keyName_rawOrNormal] || keyName_rawOrNormal;
}
export function GetRawKeyName(keyName_rawOrNormal: string|n) {
	return keyNames_normalToRawMap[keyName_rawOrNormal as any] || keyName_rawOrNormal;
}

// extra key-names (the ones accessible through KeyboardEvent.keyCode)
export const keyNames_withSides = ["ControlLeft", "ShiftLeft", "AltLeft", "ControlRight", "ShiftRight", "AltRight"];