import {Observer} from "web-vcore";
import React from "react";
import {CheckBox, Column, Row, RowLR, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.dreamRecall, (c, v)=>c.dreamRecall = v);

const splitAt = 150;

@Observer
export class DreamRecall_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Dream Recall" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Previous dream:</Text>
						<ControlInput enabled={enabled} value={config.prevDream_triggerSet} onChange={val=>ChangeConfig(c=>c.prevDream_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Next dream:</Text>
						<ControlInput enabled={enabled} value={config.nextDream_triggerSet} onChange={val=>ChangeConfig(c=>c.nextDream_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Random dream:</Text>
						<ControlInput enabled={enabled} value={config.randomDream_triggerSet} onChange={val=>ChangeConfig(c=>c.randomDream_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Last dream:</Text>
						<ControlInput enabled={enabled} value={config.lastDream_triggerSet} onChange={val=>ChangeConfig(c=>c.lastDream_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Stop voice:</Text>
						<ControlInput enabled={enabled} value={config.stopVoice_triggerSet} onChange={val=>ChangeConfig(c=>c.stopVoice_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Voice, tag:</Text>
						<TextInput enabled={enabled} style={{width: 80}} value={config.dreamNarration_voiceSoundTag} onChange={val=>ChangeConfig(c=>c.dreamNarration_voiceSoundTag = val)}/>
						<Text ml={5} mr={5}>Vol. mult.:</Text>
						<Spinner min={0} step={0.01} enabled={enabled} value={config.dreamNarration_volumeMultiplier} onChange={val=>ChangeConfig(c=>c.dreamNarration_volumeMultiplier = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Segment min-entities:</Text>
						<Spinner min={0} enabled={enabled} value={config.dreamNarration_segmentMinEntities} onChange={val=>ChangeConfig(c=>c.dreamNarration_segmentMinEntities = val)}/>
					</RowLR>
				</>}
			</Column>
		);
	}
}