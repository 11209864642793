import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";

export enum GyroChannel {
	All_Sum = 10,
	All_Max = 20,
	X = 100,
	Y = 110,
	Z = 120,
}
AddSchema("GyroChannel", {oneOf: GetValues_ForSchema(GyroChannel)});

export class FBAConfig_Gyro {
	constructor(initialData: Partial<FBAConfig_Gyro>) { this.VSet(initialData); }

	enabled: boolean;
	useSampleRounding: boolean;

	recordData_raw: boolean;
	recordData_motions: boolean;

	detectMotion: boolean;
	motion_channel: GyroChannel;

	motion_sampleTrigger_normalizationWindowSize: number;
	motion_sampleTrigger_smoothing: number;
	motion_sampleTrigger_minDeviation: number;
	motion_motionTrigger_minTriggerSamplePercent: number;
	motion_motionTrigger_windowSize: number;
	motion_motionTrigger_maxTriggerRate: number;

	motion_disableEEGMotion_enabled: boolean;
	motion_disableEEGMotion_duration: number;

	motion_resetEEGActivity_enabled: boolean;
	motion_resetEEGActivity_maxValue: number;
}
AddSchema("FBAConfig_Gyro", {
	properties: {
		enabled: {type: "boolean"},
		useSampleRounding: {type: "boolean"},

		recordData_raw: {type: "boolean"},
		recordData_motions: {type: "boolean"},

		detectMotion: {type: "boolean"},
		motion_channel: {$ref: "GyroChannel"},

		motion_sampleTrigger_normalizationWindowSize: {type: "number"},
		motion_sampleTrigger_smoothing: {type: "number"},
		motion_sampleTrigger_minDeviation: {type: "number"},
		motion_motionTrigger_minTriggerSamplePercent: {type: "number"},
		motion_motionTrigger_windowSize: {type: "number"},
		motion_motionTrigger_maxTriggerRate: {type: "number"},

		motion_disableEEGMotion_enabled: {type: "boolean"},
		motion_disableEEGMotion_duration: {type: "number"},

		motion_resetEEGActivity_enabled: {type: "boolean"},
		motion_resetEEGActivity_maxValue: {type: "number"},
	},
});