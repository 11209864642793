import {HSLA, Observer, RunInAction_Set} from "web-vcore";
import moment from "moment";
import React, {useState} from "react";
import {Button, CheckBox, Row, Select, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {DeleteStoryMessage} from "../../../Server/Commands/DeleteStoryMessage.js";
import {UpdateStoryMessage} from "../../../Server/Commands/UpdateStoryMessage.js";
import {FindMessagesInChain, StoryMessage} from "../../../Store/firebase/storyMessages.js";
import {Story} from "../../../Store/firebase/stories.js";
import {GetLiveFBASession_Reactive} from "../../../Engine/FBASession.js";
import {StoryExploreComp} from "../../../Engine/FBASession/Components/StoryExploreComp.js";
import {AddStoryMessage} from "../../../Server/Commands/AddStoryMessage.js";
import {store} from "../../../Store/index.js";
import {ScrollView} from "react-vscrollview";

class MessageStub extends BaseComponent<{story: Story, messages: StoryMessage[], id: string, idTrimLength?: number}, {}> {
	render() {
		let {story, messages, id, idTrimLength} = this.props;
		const message = messages.find(a=>a._key == id);
		if (message == null) return <Text>{id} (missing)</Text>;

		return (
			<Button p="0 5px" text={message._key.slice(0, idTrimLength ?? 5)} onClick={()=>{
				ShowMessageBox({
					title: "Message details",
					message: ()=>{
						return <ScrollView style={{maxHeight: window.innerHeight - 100}}>
							<StoryMessageUI story={story} message={message} messages={messages}/>
						</ScrollView>;
					},
				});
			}}/>
		);
	}
}

@Observer
export class StoryMessageUI extends BaseComponent<{story: Story, message: StoryMessage, messages: StoryMessage[], messagesShown?: StoryMessage[]}, {}> {
	render() {
		const {story, message, messages, messagesShown} = this.props;
		const index = messages.indexOf(message);
		const liveSession = GetLiveFBASession_Reactive();
		const storyExploreComp = liveSession?.Comp(StoryExploreComp);
		let msgStubCallCount = 0;
		const msgStub = (id: string|n, idTrimLength?: number)=>{
			if (id == null) return <Text key={`msgStub_none_${msgStubCallCount++}`}>none</Text>;
			return <MessageStub key={id} story={story} messages={messages} id={id} idTrimLength={idTrimLength}/>;
		};

		const childMessages = messages.filter(a=>a.parent == message._key);

		/*const UpdateMessage = (changerFunc: (data: JournalSegment)=>any)=>{
			const segments_new = Clone(entry.segments);
			const segment_newData = segments_new[index];
			changerFunc(segment_newData);
			new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
		};*/

		const uiState = store.main.content;
		const [editMode, setEditMode] = useState(false);
		const [showChildren, setShowChildren] = useState(false);

		//const hiddenAncestorChainLength = hiddenAncestorChainLengths?.get(message) ?? 0;
		let hiddenAncestorChain = [] as StoryMessage[];
		//let hiddenAncestorChainLength_noSummaries = 0; // could add this as extra info; not yet since requires care to not cause slowdown
		if (messagesShown) {
			const hiddenAncestorChain_raw = FindMessagesInChain(messages, message, true, msg=>msg != message && messagesShown.includes(msg), false);
			// we have to filter out the start-message and terminate-at message (which may well be visible)
			hiddenAncestorChain = hiddenAncestorChain_raw.filter(a=>!messagesShown.includes(a));
		}
		const hiddenAncestorsUI = hiddenAncestorChain.length > 0 &&
			<Row center mt={index == 0 ? 0 : 3} pl={5} style={{
				background: `rgba(255,255,0,.4)`, borderRadius: 5, flexWrap: "wrap", gap: 3,
			}}>
				<Text>{`${hiddenAncestorChain.length} hidden ancestors (some may themselves be summaries). List:`}</Text>
				{hiddenAncestorChain.length > 0 && hiddenAncestorChain.map(a=>msgStub(a._key, 2))}
			</Row>;

		return (
			<>
				{hiddenAncestorsUI}
				<Row center mt={index == 0 && hiddenAncestorsUI == null ? 0 : 3} pl={5} style={{
					background: HSLA(0, 0, 1, .3), borderRadius: "5px 5px 0 0", flexWrap: "wrap", gap: 5
				}}>
					<Row sel style={{flexWrap: "wrap", gap: 5}}>
						<Text>ID: {message._key}</Text>
						{!editMode && <Text>Parent: {msgStub(message.parent)}</Text>}
						{editMode && <>
							<Text>Parent:</Text>
							<TextInput style={{width: 200}} value={message.parent} onChange={val=>{
								if (val.trim().length > 0 && messages.find(a=>a._key == val) == null) {
									return void ShowMessageBox({title: "Invalid parent id", message: "No message with that id exists."});
								}
								new UpdateStoryMessage({id: message._key, updates: {parent: val}}).Run();
							}}/>
						</>}
						{!editMode && <Text>Role: {message.role}</Text>}
						{editMode && <>
							<Text>Role:</Text>
							<Select options={["user", "assistant"]} value={message.role} onChange={val=>{
								new UpdateStoryMessage({id: message._key, updates: {role: val}}).Run();
							}}/>
						</>}
						{childMessages.length > 0 &&
						<Row>
							<Text>Children: {childMessages.length}</Text>
							<Button p="0 5px" text={showChildren ? "hide" : "show"} onClick={()=>setShowChildren(!showChildren)}/>
							{showChildren && childMessages.map(a=>msgStub(a._key))}
						</Row>}
						<Text>Created at: {moment(message.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Text>
						{(message.summary_chainEnd || editMode) && <>
							{!editMode && <Text>Summary chain end: {msgStub(message.summary_chainEnd)}</Text>}
							{editMode && <>
								<Text>Summary chain end:</Text>
								<TextInput style={{width: 200}} value={message.summary_chainEnd} onChange={val=>{
									if (val.trim().length > 0) {
										if (messages.find(a=>a._key == val) == null) {
											// we merely warn here, because it may be that we have a summarizing-message, but have already deleted the messages it summarizes (which we want to support)
											ShowMessageBox({title: "Warning: Invalid summary chain end id", message: "No message with that id exists."});
										}
										new UpdateStoryMessage({id: message._key, updates: {summary_chainEnd: val}}).Run();
									} else {
										new UpdateStoryMessage({id: message._key, updates: {summary_chainEnd: null}}).Run();
									}
								}}/>
							</>}
							<CheckBox text="Summary enabled" value={message.summary_enabled ?? false} onChange={val=>{
								new UpdateStoryMessage({id: message._key, updates: {summary_enabled: val}}).Run();
							}}/>
						</>}
					</Row>
					<Row ml="auto">
						<CheckBox text="Edit" value={editMode} onChange={val=>setEditMode(val)}/>
						<Text ml={5}>Mark (1-2):</Text>
						<CheckBox text="" value={uiState.mark_1 == message._key} onChange={val=>RunInAction_Set(this, ()=>uiState.mark_1 = val ? message._key : null)}/>
						<CheckBox text="" value={uiState.mark_2 == message._key} onChange={val=>RunInAction_Set(this, ()=>uiState.mark_2 = val ? message._key : null)}/>
						<Button ml={5} text="Resume" enabled={storyExploreComp?.behaviorEnabled} style={{padding: "5px 12px", fontSize: 12}} onClick={()=>{
							ShowMessageBox({
								title: "Resume story?", cancelButton: true,
								message: "This will replace the current story in the live session. Continue?",
								onOK: ()=>{
									storyExploreComp?.LoadStoryForChain(story, messages, message);
								},
							});
						}}/>
						<Button ml={5} size={32} mdIcon="content-copy" onClick={()=>{
							ShowMessageBox({
								title: "Clone message?", cancelButton: true,
								message: "Clone this message, as a new peer? (ie. with same parent)",
								onOK: ()=>{
									const newMessage = new StoryMessage({
										role: message.role,
										content: message.content,
										story: message.story,
										parent: message.parent,
									});
									new AddStoryMessage({message: newMessage}).Run();
								},
							});
						}}/>
						<Button ml={5} size={32} mdIcon="delete" enabled={childMessages.length == 0} onClick={()=>{
							ShowMessageBox({
								title: "Delete message?", cancelButton: true,
								message: "Permanently delete this message of the story?",
								onOK: ()=>{
									new DeleteStoryMessage({id: message._key}).Run();
								},
							});
						}}/>
					</Row>
				</Row>
				<Row>
					<TextArea style={{flex: 70, border: "1px solid black"}} autoSize={true} enabled={editMode} value={message.content} onChange={val=>{
						new UpdateStoryMessage({id: message._key, updates: {content: val}}).Run();
					}}/>
				</Row>
			</>
		);
	}
}