import {E, ModifyString} from "js-vextensions";
import {runInAction} from "mobx";
import {useCallback, useEffect, useState} from "react";
import {Div, Row, Select} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus, BaseComponentWithConnector} from "react-vextensions";
import {RootState, store} from "../../Store/index.js";
import {colors} from "../../Utils/UI/GlobalStyles";
import {pageTree, pages} from "../../Utils/URL/URLs";
import {Link, Observer, RunInAction_Set, ErrorBoundary, NavBarPageButton, NavBarPanelButton, NotificationsUI} from "web-vcore";
import {GetAuth} from "../../Store/firebase";
import {IsAuthValid} from "mobx-firelink";
import {zIndexes} from "../../Utils/UI/ZIndexes";
import {GuidePanel} from "./NavBar/GuidePanel";
import {ReputationPanel} from "./NavBar/ReputationPanel";
import {SettingsPanel} from "./NavBar/SettingsPanel";
import {StreamPanel} from "./NavBar/StreamPanel";
import {UserPanel} from "./NavBar/UserPanel";
import {TestingPanel} from "./NavBar/TestingPanel";

// main
// ==========

const originSettings = {horizontal: "right", vertical: "top"};
const buttonStyle = {color: "white", textDecoration: "none"};
const avatarSize = 50;

const avatarStyles = {
	icon: {width: avatarSize, height: avatarSize},
	button: {marginRight: "1.5rem", width: avatarSize, height: avatarSize},
	wrapper: {marginTop: 45 - avatarSize},
};

type SharedProps = {largeVersion: boolean, navBarHeight: number};

function NavBar_UseExpanded() {
	return window.outerWidth >= 1024;
}
export function useExpandedNavBar() {
	const [largeVersion, setLargeVersion] = useState(NavBar_UseExpanded());
	useEffect(()=>{
		const onWindowResize = ()=>{
			//this.Update();
			setLargeVersion(NavBar_UseExpanded());
		};
		window.addEventListener("resize", onWindowResize);
		return ()=>window.removeEventListener("resize", onWindowResize);
	});
	return largeVersion;
}

@Observer
export class NavBar extends BaseComponentPlus({}, {}) {
	// we need this for switching between small and large version
	/*ComponentDidMount() { window.addEventListener("resize", this.onWindowResize); }
	ComponentWillUnmount() { window.removeEventListener("resize", this.onWindowResize); }
	onWindowResize = ()=>this.Update();*/

	render() {
		const uiState = store.main;
		const auth = GetAuth();

		const largeVersion = useExpandedNavBar();
		const navBarHeight = largeVersion ? 45 : 25;
		const sharedProps = {largeVersion, navBarHeight};

		const testingButton = <NavBarPanelButton text={
			store.main.testing.runTests && store.main.testing.averageTestScore != null
				? `Testing (${store.main.testing.averageTestScore.ToPercentStr(0)})`
				: "Testing"
		} panel="testing" corner="top-left"/>; // always marked "top-left", since the panel itself always shows there

		return (
			<nav style={{
				position: "relative", zIndex: zIndexes.navBar,
				padding: "0 10px", boxShadow: colors.navBarBoxShadow,
				//background: "#000 url('/Images/Tiling/TopMenu.png') repeat-x scroll",
				background: "rgba(0,0,0,1)",
			}}>
				<Row center style={{height: navBarHeight}}>
					<span style={{position: "absolute", left: 0}}>
						{/*<NavBarPanelButton text="Stream" panel="stream" corner="top-left"/>
						<NavBarPanelButton text="Chat" panel="chat" corner="top-left"/>
						<NavBarPanelButton text={
							<Div className="cursorSet" style={{position: "relative", height: 45}}>
								<Div style={{color: "rgba(255,255,255,1)", justifyContent: "center"}}>Rep: n/a</Div>
								{/*<Div style={{color: "rgba(255,255,255,1)", justifyContent: "center"}}>Rep: 100</Div>
								<Div style={{position: "absolute", bottom: 3, width: "100%", textAlign: "center",
									fontSize: 11, lineHeight: "11px", color: "rgba(0,255,0,.7)"}}>+100</Div>*#/}
							</Div> as any
						} panel="reputation" corner="top-left"/>*/}
						{largeVersion && testingButton}
					</span>
					<div style={{
						position: "absolute", zIndex: 11, left: 0, top: navBarHeight,
						boxShadow: colors.navBarBoxShadow,
						//clipPath: "inset(0 -150px -150px 0)", display: "table", // commented; seemingly not needed anymore
					}}>
						<ErrorBoundary>
							{uiState.topLeftOpenPanel == "stream" && <StreamPanel/>}
							{uiState.topLeftOpenPanel == "reputation" && <ReputationPanel/>}
							{(uiState.topLeftOpenPanel == "testing" || store.main.testing.runTests) && <TestingPanel/>}
						</ErrorBoundary>
					</div>
					<NotificationsUI placement="topLeft" navBarHeight={navBarHeight}/>

					{!largeVersion && <MiddleSection_Small/>}
					{largeVersion && <MiddleSection_Large {...sharedProps}/>}

					<span style={{position: "absolute", right: 0, display: "flex"}}>
						{/*<NavBarPanelButton text="Search" panel="search" corner="top-right"/>
						<NavBarPanelButton text="Guide" panel="guide" corner="top-right"/>*/}
						{!largeVersion && testingButton}
						<NavBarPanelButton {...sharedProps} text="Settings" panel="settings" corner="top-right"/>
						<NavBarPanelButton {...sharedProps} text={IsAuthValid(auth) ? auth!.displayName.match(/(.+?)( |$)/)?.[1] ?? "n/a" : "Sign in"} panel="profile" corner="top-right"/>
					</span>
					<div style={{
						position: "absolute", zIndex: 11, right: 0, top: navBarHeight,
						boxShadow: colors.navBarBoxShadow,
						//clipPath: "inset(0 0 -150px -150px)", display: "table", // commented; seemingly not needed anymore
					}}>
						<ErrorBoundary>
							{uiState.topRightOpenPanel == "guide" && <GuidePanel/>}
							{uiState.topRightOpenPanel == "settings" && <SettingsPanel/>}
							{uiState.topRightOpenPanel == "profile" && <UserPanel/>}
						</ErrorBoundary>
					</div>
				</Row>
			</nav>
		);
	}
}

@Observer
class MiddleSection_Small extends BaseComponentPlus({}, {}) {
	render() {
		const currentPage = store.main.page;
		const pageOptions = Object.keys(pages).map(page=>{
			const pageInfo = pageTree.children[page];
			return {name: pageInfo.title, value: pageInfo.key};
		});

		const pageInfo = pageTree.children[currentPage];
		const subpageOptions = pageInfo?.simpleSubpages && pageInfo.children?.VValues().map(subpageInfo=>{
			return {name: subpageInfo.title, value: subpageInfo.key};
		});

		return (
			<Row>
				<Select options={pageOptions} value={currentPage} onChange={val=>{
					RunInAction_Set(this, ()=>store.main.page = val);
				}}/>
				{subpageOptions &&
				<Select options={subpageOptions} value={store.main[currentPage].subpage} onChange={val=>{
					RunInAction_Set(this, ()=>store.main[currentPage].subpage = val);
				}}/>}
			</Row>
		);
	}
}

class MiddleSection_Large extends BaseComponent<{} & SharedProps, {}> {
	render() {
		let {} = this.props;
		const sharedProps = this.props;
		return (
			<Row style={{margin: "0 auto", paddingRight: 29}}>
				<NavBarPageButton {...sharedProps} page="users" text="Members"/>
				<NavBarPageButton {...sharedProps} page="feedback" text="Feedback"/>
				{/*<NavBarPageButton {...sharedProps} page="forum" text="Forum"/>*/}
				<NavBarPageButton {...sharedProps} page="timeline" text="Timeline"/>
				<NavBarPageButton {...sharedProps} page="more" text="More"/>
				<NavBarPageButton {...sharedProps} page="home" text="Lucid Frontier" style={{margin: "0 auto", textAlign: "center", fontSize: 23}}/>
				<NavBarPageButton {...sharedProps} page="journal" text="Journal"/>
				<NavBarPageButton {...sharedProps} page="effects" text="Effects"/>
				<NavBarPageButton {...sharedProps} page="content" text="Content"/>
				<NavBarPageButton {...sharedProps} page="tools" text="Tools"/>
				{/*<NavBarButton page="activities" text="Competitions"/>
				<NavBarButton page="journal" text="Journal"/>
				<NavBarButton page="history" text="History"/>
				<NavBarButton page="competition" text="Competition"/>
				<NavBarButton page="stats" text="Stats"/>*/}
			</Row>
		);
	}
}