import {E} from "js-vextensions";
import {Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {DefaultSkin, minuteInMS, Observer, secondInMS} from "web-vcore";
import Moment from "moment";
import React from "react";
import {eventTypes_dreamQuiz, eventTypes_dreamQuiz_promptEnder, SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import {EventGroupWasRehearsalCycle, GetMaxPossibleCycleSuccesses} from "../../../Store/firebase/@Shared/SessionPeriodUtils.js";
import {JournalEntry} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../../Store/index.js";
import {ScrollView} from "react-vscrollview";
import {GetSleepCycleInfoFromEvents} from "../../../Engine/FBASession_Local.js";
import {MDIcon} from "../../../Utils/UI/MDIcon.js";
import {EventGroup, SessionPeriod} from "../../../Store/firebase/@Shared/SessionPeriod.js";

@Observer
export class SessionEventsUI extends BaseComponent<{entry: JournalEntry, nextEntry: JournalEntry|n, period: SessionPeriod}, {}> {
	render() {
		let {entry, nextEntry, period} = this.props;
		const {sessions, eventGroups} = period;
		//const tracker1 = store.main.tools.engine.liveFBASession_loggedAt; // refresh when logs are added
		
		const cycleGroups_theoreticalMax = GetMaxPossibleCycleSuccesses(sessions, eventGroups);
		const cycleGroups_nonRehearsal = eventGroups.filter((a, i)=>a.type == "cycle" && !EventGroupWasRehearsalCycle(a, eventGroups));
		const cycleGroups_nonRehearsal_success = cycleGroups_nonRehearsal.filter(a=>a.events.Any(a=>a.type == "Journey.CycleSuccess"));

		if (eventGroups.length == 0) return null;
		return (
			<Row style={{
				position: "relative", flexWrap: "wrap",
				fontFamily: DefaultSkin.main.MainFont(), // include Symbola font, to avoid the system-font's overly-distracting colored emojis
			}}>
				<div style={{
					marginLeft: 3, padding: "0 3px", borderRadius: 5,
					background: "rgba(0,0,0,.5)",
					border: "1px solid rgba(255,255,255,.3)",
					opacity: cycleGroups_nonRehearsal.length >= 3 ? 1 : .5,
					height: "fit-content", // fixes height issue (where height increases to match height of left-anchored action-button)
				}}>
					{[
						`${cycleGroups_nonRehearsal_success.length}`,
						`(${(cycleGroups_nonRehearsal_success.length / cycleGroups_theoreticalMax).ToPercentStr(0)} of ${cycleGroups_theoreticalMax} t-max)`,
						`(${(cycleGroups_nonRehearsal_success.length / cycleGroups_nonRehearsal.length).ToPercentStr(0)} of ${cycleGroups_nonRehearsal.length} a-max)`,
					].join(" ")}
				</div>
				{eventGroups.map((group, index)=>{
					return <EventGroupUI key={index} group={group} groups={eventGroups}/>;
				})}
			</Row>
		);
	}
}

export const EventGroupUI = (props: {group: EventGroup, groups: EventGroup[]})=>{
	const {group, groups} = props;
	const index = groups.indexOf(group);
	const prevGroups = groups.slice(0, index);
	const eventsInPrevGroups = prevGroups.SelectMany(a=>a.events);

	const baseStyle = {
		marginLeft: 3, padding: "0 3px", borderRadius: 5,
		background: "rgba(0,0,0,.5)", border: "1px solid rgba(255,255,255,.3)",
		gap: 3,
		height: "fit-content", // fixes height issue (where height increases to match height of left-anchored action-button)
	};
	const timeRangeStr = `Time range: ${Moment(group.events.First().date).format("HH:mm:ss")} - ${Moment(group.events.Last().date).format("HH:mm:ss")}`;
	const messageLines = [
		timeRangeStr,
		"",
		...group.events.map(entry=>{
			const timeStr = Moment(entry.date).format("HH:mm:ss (.SSS)");
			const typeStr = entry.type;
			const extraStr = entry.alarmSequence ? ` @seq(${entry.alarmSequence})` : "";
			return `${timeStr}: ${typeStr}${extraStr}`;
		}),
	];

	// the criteria here is kinda arbitrary, but it's what I prefer (eg. if wait-time >10m, it's likely a preset alarm-delay, making decimal precision just clutter)
	/*const durationToStr = (duration: number)=>{
		return (
			duration < 1 * minuteInMS ? `${(duration / secondInMS).RoundTo_Str(.1, undefined, false)}s` :
			duration < 10 * minuteInMS ? `${(duration / minuteInMS).RoundTo_Str(.1, undefined, false)}m` :
			`${(duration / 60000).RoundTo_Str(1, undefined, false)}m`
		);
	};*/
	// if default-args: within the last minute, round to .1 seconds; else, to .1 minutes (in both cases, remove the decimal portion if the value gets rounded to an integer)
	const durationToStr = (duration: number, forceSeconds = false, useFractionsFor10Plus = false)=>{
		const asSeconds = duration / secondInMS;
		const asMinutes = duration / minuteInMS;
		return (duration < 1 * minuteInMS || forceSeconds)
			? `${asSeconds.RoundTo_Str(asSeconds >= 10 && !useFractionsFor10Plus ? 1 : .1)}` //s`
			: `${asMinutes.RoundTo_Str(asMinutes >= 10 && !useFractionsFor10Plus ? 1 : .1)}m`;
	};

	let dreamQuizText: string|n;
	const quizPromptEnderEvents = group.events.filter(a=>eventTypes_dreamQuiz_promptEnder.includes(a.type));
	if (quizPromptEnderEvents.length) {
		dreamQuizText = `@dq:${quizPromptEnderEvents.length}`;
	}

	let conceptLinkText: string|n;
	const conceptLinkVisualizeEvents = group.events.filter(a=>a.type == "ConceptLink.TargetVisualized");
	if (conceptLinkVisualizeEvents.length) {
		conceptLinkText = `@cl:${conceptLinkVisualizeEvents.length}`;
	}

	const showEventsDialog = ()=>{
		ShowMessageBox({
			message: ()=>{
				return <ScrollView style={{maxHeight: window.innerHeight - 100}}>
					<div style={{whiteSpace: "pre-wrap"}}>
						{messageLines.join("\n")}
					</div>
				</ScrollView>;
			}
		});
	};

	if (group.type == "special") {
		return <Row
			style={E(
				baseStyle,
				{cursor: "pointer"},
			)}
			title={timeRangeStr}
			onClick={()=>showEventsDialog()}
		>
			{group.events.Any(a=>a.type == "General.SessionRecovered") && "🔄"}
		</Row>;
	} else if (group.type == "wait-period") {
		//const skippedWaitPeriod = group.events.Any(a=>a.type == "Journey.ResleepSkip");
		return <Row
			style={E(
				baseStyle,
				//skippedWaitPeriod && {background: "rgba(255,255,0,.3)"}
				{cursor: "pointer"},
			)}
			title={timeRangeStr}
			onClick={()=>showEventsDialog()}
		>
			{group.events.Any(a=>a.type == "General.SessionStart") && "Init:"}
			{durationToStr(group.duration)}
			{group.events.Any(a=>a.type == "Journey.LightsBright") && <div>💡</div>}
			{dreamQuizText && <div>{dreamQuizText}</div>}
			{conceptLinkText && <div>{conceptLinkText}</div>}
		</Row>;
	} else if (group.type == "cycle") {
		// if cycle-group had no resolution/"ender", don't show it in UI (user can infer a halted cycle from the event-group before it, so a separate box to show that would just be clutter)
		//if (!group.events.map(a=>a.type).ContainsAny("Journey.SnoozeEnd", "Journey.CycleFail", "Journey.CycleSuccess")) return null;

		const isRehearsal = EventGroupWasRehearsalCycle(group, groups);
		const isFail = group.events.Any(a=>a.type == "Journey.CycleFail");
		const isSuccess = group.events.Any(a=>a.type == "Journey.CycleSuccess");
		const targetCircleChar = '\uD83D\uDF8B'; // raw unicode char: "🞋" (circular target, like a dartboard)
		return <Row
			style={E(
				baseStyle,
				isRehearsal && {background: "rgba(255,255,0,.3)"},
				!isRehearsal && isFail && {background: "rgba(255,0,0,.3)"},
				!isRehearsal && isSuccess && {background: "rgba(0,255,0,.3)"},
				{cursor: "pointer"},
			)}
			title={timeRangeStr}
			onClick={()=>showEventsDialog()}
		>
			{/*!group.events.map(a=>a.type).ContainsAny("Journey.SnoozeEnd", "Journey.CycleFail", "Journey.CycleSuccess") &&
			<div>{`<halted cycle>`}</div>*/}
			{group.events.map((ev, index)=>{
				const spanMarker = (spanStartEvent_matcher: (event: SessionEvent)=>boolean)=>{
					const targetDelay = ev.date - group.events.slice(0, index).filter(spanStartEvent_matcher).LastOrX()?.date!;
					if (targetDelay == null) return null;
					return <div>{durationToStr(targetDelay, true)}</div>;
				};

				const sessionEventsUpToHere = [...eventsInPrevGroups, ...group.events.slice(0, index + 1)];
				const {cycleNumber} = GetSleepCycleInfoFromEvents(sessionEventsUpToHere);
				return <React.Fragment key={index}>
					{ev.type == "General.SleepCycleEnd" && <div>#{cycleNumber}</div>}
					{/*ev.type == "Journey.SnoozeEnd" && <div>💡</div>*/}
					{/*ev.type == "Journey.LightsBright" && <div>💡</div>*/}
					{ev.type == "Journey.LightsBright" && <MDIcon icon="lightbulb-variant-outline" size={18} width={15}/>}
					
					{ev.type == "Journey.CycleStart" && <>{spanMarker(a=>{
						// a sleep-cycle-end event just before cycle-start is viewable as "one unit" (they occur in the same call-chain), so don't consider for finding start of span's time-range
						return a.type != "General.SleepCycleEnd";
					})}<MDIcon icon="eye-outline" size={18}/></>}

					{ev.type == "Journey.CycleReverse" && <>{spanMarker(a=>a.type == "Journey.CycleStart")}<div>⮌</div></>}
					{ev.type == "Journey.ListenStart" && <>{spanMarker(a=>a.type == "Journey.CycleStart")}<div>🎧</div></>}
					{ev.type == "Journey.TargetReached" && <>{spanMarker(a=>a.type == "Journey.ListenStart")}<div>{targetCircleChar}</div></>}
					{ev.type.IsOneOf("Journey.CycleFail", "Journey.CycleSuccess") && (()=>{
						const targetReachedEvent = group.events.slice(0, index).filter(a=>a.type.IsOneOf("Journey.CycleReverse", "Journey.TargetReached")).LastOrX();
						const delaySinceTarget = targetReachedEvent ? ev.date - targetReachedEvent.date! : null;
						return <>
							{delaySinceTarget != null &&
							<div>{durationToStr(delaySinceTarget)}</div>}
							{/*<div>{ev.type == "Journey.CycleFail" ? "❌" : "✔️"}</div>*/}
						</>;
					})()}
				</React.Fragment>;
			})}
			{dreamQuizText && <div style={{whiteSpace: "pre-wrap"}}>{dreamQuizText}</div>}
			{conceptLinkText && <div style={{whiteSpace: "pre-wrap"}}>{conceptLinkText}</div>}
		</Row>;
	}
	return null;
};