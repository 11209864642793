import {AddSchema} from "mobx-firelink";

export const SessionEventType_values = [
	// synthetic (not recorded in database, but frontend may add these to its client-side event-arrays on-the-fly)
	"General.SessionStart",
	"General.SessionRecovered",
	"General.SessionEnd",
	
	// normal
	"General.InitialDelayEnd",
	"General.SleepCycleEnd",
	"MemoryPrompt.TargetHit",
	"MemoryPrompt.TargetMiss",
	"MemoryPrompt.TargetGiveUp",

	"Journey.LightsBright", // todo: rename
	//"Journey.LightsDim", // todo: rename
	"Journey.ResleepStart", // todo: rename
	"Journey.ResleepSkip", // todo: rename
	"Journey.ResleepEnd", // todo: rename
	"Journey.SnoozeEnd", // todo: rename
	"Journey.CycleStart", // todo: rename "Alarms.SolveStart"
	"Journey.CycleFail", // todo: rename "Alarms.SolveFail"
	"Journey.CycleSuccess", // todo: rename "Alarms.SolveSuccess"
	// visualization cycle
	"Journey.CycleReverse", // todo: rename

	// grid cycle
	"Journey.ListenStart",
	"Journey.TargetReached",

	// dream-quiz
	"DreamQuiz.TargetHit",
	"DreamQuiz.TargetMiss",
	"DreamQuiz.TargetGiveUp",

	// sound-quiz
	"SoundQuiz.TargetHit",
	"SoundQuiz.TargetMiss",
	"SoundQuiz.TargetGiveUp",

	// concept-link
	"ConceptLink.TargetVisualized",

	// reality-check
	"RealityCheck.ReminderPlay",
	"RealityCheck.ReminderSkip", // recorded if time of reminder comes, but a condition (like max-ambient-noise) is not met
	"RealityCheck.ReminderHit",
] as const;
export type SessionEventType = typeof SessionEventType_values[number];
AddSchema("SessionEventType", {oneOf: SessionEventType_values.map(a=>({const: a}))});

// subgroups
export const eventTypes_dreamQuiz: SessionEventType[] = ["DreamQuiz.TargetHit", "DreamQuiz.TargetMiss", "DreamQuiz.TargetGiveUp"];
export const eventTypes_dreamQuiz_promptEnder: SessionEventType[] = ["DreamQuiz.TargetHit", "DreamQuiz.TargetGiveUp"];
export const eventTypes_soundQuiz: SessionEventType[] = ["SoundQuiz.TargetHit", "SoundQuiz.TargetMiss", "SoundQuiz.TargetGiveUp"];
export const eventTypes_soundQuiz_promptEnder: SessionEventType[] = ["SoundQuiz.TargetHit", "SoundQuiz.TargetGiveUp"];
export const eventTypes_dreamOrSoundQuiz_promptEnder: SessionEventType[] = [...eventTypes_dreamQuiz_promptEnder, ...eventTypes_soundQuiz_promptEnder];

export class SessionEvent {
	constructor(initialData: Partial<SessionEvent>) {
		Object.assign(this, {date: Date.now()}, initialData);
	}
	date: number;
	type: SessionEventType;

	// if Journey.LightsBright
	alarmSequence?: string;

	// if RealityCheck.ReminderPlay or RealityCheck.ReminderSkip
	ambientNoise?: number; // useful for if user wants to view stats using a different threshold in the future
	
	// these are not stored/found on db entries
	_isSynthetic?: boolean;
}
AddSchema("SessionEvent", {
	properties: {
		date: {type: "number"},
		type: {$ref: "SessionEventType"},
		alarmSequence: {type: ["null", "string"]},
		ambientNoise: {type: ["null", "number"]},
	},
});