import {O} from "web-vcore";
import {makeObservable} from "mobx";
import {EngineState} from "./tools/engine";
import {JourneyState} from "./tools/journey.js";
import {MonitorState} from "./tools/monitor";
import {PestState} from "./tools/pest.js";
import {TrainingState} from "./tools/training";

export class ToolsState {
	constructor() { makeObservable(this); }
	@O subpage = "engine";
	@O engine = new EngineState();
	@O monitor = new MonitorState();
	@O training = new TrainingState();
	@O pest = new PestState();
	@O journey = new JourneyState();
}