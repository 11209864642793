import {ES, minuteInMS, Observer, secondInMS, TextPlus} from "web-vcore";
import React, {useState} from "react";
import {CheckBox, Column, Row, RowLR, Spinner, Text, TextArea, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {GetLights_WithUserTag} from "../../../../Store/firebase/lights.js";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {EffectPreviewButton} from "../../../@Shared/TagTargetEntryUI.js";
import {E, ToNumber} from "js-vextensions";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.storyExplore, (c, v)=>c.storyExplore = v);

const splitAt = 150;

@Observer
export class StoryExplore_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		const [showStartPrompt, setShowStartPrompt] = useState(false);

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Story Explore" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Previous text:</Text>
						<ControlInput enabled={enabled} value={config.prevText_triggerSet} onChange={val=>ChangeConfig(c=>c.prevText_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Next text:</Text>
						<ControlInput enabled={enabled} value={config.nextText_triggerSet} onChange={val=>ChangeConfig(c=>c.nextText_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Select text:</Text>
						<ControlInput enabled={enabled} value={config.selectText_triggerSet} onChange={val=>ChangeConfig(c=>c.selectText_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Stop voice:</Text>
						<ControlInput enabled={enabled} value={config.stopVoice_triggerSet} onChange={val=>ChangeConfig(c=>c.stopVoice_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>New story:</Text>
						<ControlInput enabled={enabled} value={config.newStory_triggerSet} onChange={val=>ChangeConfig(c=>c.newStory_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Voice, tag:</Text>
						<TextInput enabled={enabled} style={{width: 80}} value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>
						<Text ml={5} mr={5}>Vol. mult.:</Text>
						<Spinner min={0} step={0.01} enabled={enabled} value={config.volumeMultiplier} onChange={val=>ChangeConfig(c=>c.volumeMultiplier = val)}/>
					</RowLR>
					<RowLR mt={5}>
						<TextPlus info={`If LLM produces a response with fewer than this many numbered items, selecting a non-numbered line will tell the LLM to generate a full list.`.AsMultiline(0)}>Min numbered-list size:</TextPlus>
						<Spinner min={0} enabled={enabled} value={config.minNumberedListSize} onChange={val=>ChangeConfig(c=>c.minNumberedListSize = val)}/>
					</RowLR>

					<Row mt={10} style={{fontWeight: "bold"}}>LLM history</Row>
					<RowLR mt={5}>
						<TextPlus info={`When sending the message history to the LLM, trim all numbered-lists from prior outputs, except the last X such lists.`.AsMultiline(0)}>Keep last X numbered lists:</TextPlus>
						<Spinner min={1} enabled={enabled} value={config.llmHistory_keepLastXNumberedLists} onChange={val=>ChangeConfig(c=>c.llmHistory_keepLastXNumberedLists = val)}/>
					</RowLR>
					<RowLR mt={5}>
						<TextPlus info={`
							Effects when set:
							* Filters previous messages (when sending to LLM), so that a given message has the last numbered-list, AND user just selected an entry from its "dynamic" list-items, omit all the other dynamic-list options. (thus helping avoid the LLM repeating the same dynamic options; the instructions already tell it to avoid this, but it fairly often repeats them anyway)
						`.AsMultiline(0)}>Dynamic list-numbers:</TextPlus>
						<TextInput style={{flex: 1}} value={(config.dynamicListNumbers || []).join(", ")} onChange={val=>{
							ChangeConfig(c=>c.dynamicListNumbers = val.split(",").map(a=>ToNumber(a.trim(), undefined)).filter(a=>a));
						}}/>
					</RowLR>
					<RowLR mt={5}>
						<TextPlus info={`The higher it is, the more creative/chaotic it is. Range: 0-1`}>LLM temperature:</TextPlus>
						<Spinner min={0} max={1} style={{flex: 1}} value={config.temperature} onChange={val=>ChangeConfig(c=>c.temperature = val)}/>
					</RowLR>
					<Column mt={5}>
						<Row>
							<TextPlus style={{width: splitAt}} info={`
								* This text (other than the user-prompt subsection) is sent as the LLM's "system prompt".
								* You can customize the initial user-prompt by wrapping it like so: <userPrompt>This is my user prompt!</userPrompt>
								* System vs user prompt? Apparently, LLM's are more likely to follow system-prompt instructions. (at least as the message-history gets longer)
							`.AsMultiline(0)}>Start prompt:</TextPlus>
							<CheckBox text="Show" value={showStartPrompt} onChange={val=>setShowStartPrompt(val)}/>
						</Row>
						<TextArea enabled={enabled} autoSize={true} style={ES(!showStartPrompt && {display: "none"})} value={config.startPrompt} onChange={val=>ChangeConfig(c=>c.startPrompt = val)}/>
					</Column>
				</>}
			</Column>
		);
	}
}