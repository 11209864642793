import {autorun} from "mobx";
import {store} from "../../Store/index.js";
import {GetFBAConfig} from "../../Store/firebase/fbaConfigs.js";
import {GetAlarmDelayCandidates, GetNextAlarmDelayTickets} from "../../Engine/FBASession/Components/AlarmsComp.js";

autorun(()=>{
	const configID = store.main.tools.engine.selectedConfig;
	const config = GetFBAConfig(configID);
	if (config) {
		const alarmDelayCandidates = GetAlarmDelayCandidates(config.alarms);
		const tracker1 = GetNextAlarmDelayTickets(alarmDelayCandidates, config.alarms.alarmDelay_poolRates, config.alarms.alarmDelay_optionCatchUpRate ?? 1);
	}
}, {name: "SendLD1ConfigToDevice"});