import {Link, Observer, PageContainer, RunInAction} from "web-vcore";
import moment from "moment";
import {Button, Column, Div, Grid, Row, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {GetStories, Story} from "../../Store/firebase/stories.js";
import {User} from "../../Store/firebase/users/@User.js";
import {store} from "../../Store/index.js";
import {GetSelectedStory} from "../../Store/main/content.js";
import {ES} from "../../Utils/UI/GlobalStyles.js";
import {StoryUI} from "./Stories/StoryUI.js";
import {Me} from "../../Store/firebase/users.js";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel.js";
import {CE, StartUpload} from "js-vextensions";
import {AddStory} from "../../Server/Commands/AddStory.js";
import {StoryMessage} from "../../Store/firebase/storyMessages.js";
import {AddStoryMessage} from "../../Server/Commands/AddStoryMessage.js";

@Observer
export class StoriesUI extends BaseComponent<{user: User}, {}> {
	render() {
		const {user} = this.props;
		const entries = GetStories(user._key).OrderByDescending(a=>a.createdAt);
		const selectedEntry = GetSelectedStory();

		const largeVersion = window.outerWidth >= 1024;
		if (!largeVersion) {
			return (
				<PageContainer preset="full" style={{margin: 0, padding: 0, background: "none"}}>
					{selectedEntry == null && <EntryList {...{entries, selectedEntry}}/>}
					{selectedEntry != null && <StoryUI entry={selectedEntry}/>}
				</PageContainer>
			);
		}

		return (
			<PageContainer style={{flex: 1, flexDirection: "row", margin: 20, padding: 0, background: "none"}}>
				<EntryList {...{entries, selectedEntry}}/>
				<Column ml={10} style={ES({flex: 70})}>
					{selectedEntry && <StoryUI entry={selectedEntry}/>}
				</Column>
			</PageContainer>
		);
	}
}

export function EntryList(props: {entries: Story[], selectedEntry: Story|n}) {
	const {entries, selectedEntry} = props;
	return (
		<Column style={ES({flex: 30})}>
			<Column className="clickThrough" style={{height: 70, background: "rgba(0,0,0,.7)", borderRadius: "10px 10px 0 0"}}>
				<Grid single centerY style={{height: 40, padding: 10}}>
					<Text style={{justifySelf: "center", fontSize: 18}}>Your stories</Text>
					<Button text="Import" style={{justifySelf: "right"}} onClick={async()=>{
						if (Me() == null) return ShowSignInPopup();
						const file = (await StartUpload(false))[0];
						const data = JSON.parse(await file.text());
						const story = new Story({title: "ImportedStory_" + data.story.title});
						console.log("Adding story:", story);
						const newStoryID = await new AddStory({story}).Run();

						const messageMappings = {};
						// add messages
						for (const message of data.messages) {
							const newMessage = new StoryMessage({...CE(message).ExcludeKeys("_id", "story")});
							newMessage.story = newStoryID;
							if (newMessage.parent) newMessage.parent = messageMappings[newMessage.parent];
							console.log("Adding message:", newMessage);
							const newID = await new AddStoryMessage({message: newMessage}).Run();
							messageMappings[message._id] = newID;
						}

						RunInAction("EntryList.addEntry.onClick", ()=>store.main.content.selectedStory = newStoryID);
					}}/>
				</Grid>
				<Row style={{height: 30, padding: 10}}>
					<span style={{flex: columnWidths[0], fontWeight: 500, fontSize: 15}}>Title</span>
					<span style={{flex: columnWidths[1], fontWeight: 500, fontSize: 15}}>Created at</span>
				</Row>
			</Column>
			<ScrollView style={{flex: 1}} contentStyle={{backgroundColor: "rgba(30,30,30,.7)", borderRadius: "0 0 10px 10px"}} onClick={e=>{
				if (e.target != e.currentTarget) return;
				RunInAction("EntryList.background.onClick", ()=>store.main.content.selectedStory = null);
			}}>
				{entries.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
				{entries.map((entry, index)=>{
					return <StoryEntryRow key={index} entry={entry} entries={entries} selected={entry == selectedEntry}/>;
				})}
			</ScrollView>
		</Column>
	);
}

export const columnWidths = [.7, .3];

export function StoryEntryRow(props: {entry: Story, entries: Story[], selected: boolean}) {
	const {entry, entries, selected} = props;
	//const index = entries.indexOf(entry);
	return (
		<Link style={{fontSize: 13, whiteSpace: "pre"}} actionFunc={s=>s.main.content.selectedStory = entry._key} onContextMenu={e=>e.nativeEvent["passThrough"] = true}>
			<Div style={{position: "relative", flex: columnWidths[0]}}>
				<Text style={{fontSize: 17}}>{entry.title.trim().length ? entry.title : "(unnamed)"}</Text>
			</Div>
			<span style={{flex: columnWidths[1]}}>{moment(entry.createdAt).format("YYYY-MM-DD HH:mm:ss")}</span>
		</Link>
	);
}