import {InfoButton, Observer, TextPlus} from "web-vcore";
import React from "react";
import {CheckBox, Button, Column, Row, RowLR, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {GetEntries, ToNumber} from "js-vextensions";
import {DreamQuiz_QuestionType} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";
import {EffectPointerInput} from "../../../@Shared/EffectPointerInput.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.conceptLink, (c, v)=>c.conceptLink = v);

const splitAt = 150;

@Observer
export class ConceptLink_ConfigUI extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Concept Link" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Next sequence:</Text>
						<ControlInput enabled={enabled} value={config.nextSequence_triggerSet} onChange={val=>ChangeConfig(c=>c.nextSequence_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Prev sequence:</Text>
						<ControlInput enabled={enabled} value={config.prevSequence_triggerSet} onChange={val=>ChangeConfig(c=>c.prevSequence_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Play audio:</Text>
						<ControlInput enabled={enabled} value={config.playAudio_triggerSet} onChange={val=>ChangeConfig(c=>c.playAudio_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Randomize audio:</Text>
						<ControlInput enabled={enabled} value={config.randomizeAudio_triggerSet} onChange={val=>ChangeConfig(c=>c.randomizeAudio_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Voice, tag:</Text>
						<TextInput enabled={enabled} style={{width: 80}} value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>
						<Text ml={5} mr={5}>Vol. mult.:</Text>
						<Spinner min={0} step={0.01} enabled={enabled} value={config.volumeMultiplier} onChange={val=>ChangeConfig(c=>c.volumeMultiplier = val)}/>
					</RowLR>

					<Row mt={10} style={{fontWeight: "bold"}}>Dream event-sequences</Row>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							This is useful as a way of filtering to dream-segments with more interesting/lucidity-promoting content.
						`.AsMultiline(0)}>Lucid segments only:</TextPlus>
						<CheckBox enabled={enabled} value={config.lucidSegmentsOnly} onChange={val=>ChangeConfig(c=>c.lucidSegmentsOnly = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							This is useful as a way of filtering to dream-segments with enough content for concept-link system to work with it.
						`.AsMultiline(0)}>Segment min events:</TextPlus>
						<Spinner min={0} enabled={enabled} value={config.segmentMinEvents} onChange={val=>ChangeConfig(c=>c.segmentMinEvents = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							This is useful as a way of filtering out "events" that are actually just use of semicolon as a regular punctuation mark.
						`.AsMultiline(0)}>Event max words:</TextPlus>
						<Spinner min={0} enabled={enabled} value={config.eventMaxWords} onChange={val=>ChangeConfig(c=>c.eventMaxWords = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Sequence size:</Text>
						<Spinner min={1} enabled={enabled} value={config.sequenceSize} onChange={val=>ChangeConfig(c=>c.sequenceSize = val)}/>
					</RowLR>
					<Row mt={5}>
						<TextPlus info={`
							Dreams within the last X days, get a boost of Y times, in their chance of being selected.
							Note: If a dream is in multiple ranges, the highest boost is used.
						`.AsMultiline(0)}>Recency boosts:</TextPlus>
						<Button ml={5} mdIcon="plus" enabled={enabled} onClick={()=>{
							ChangeConfig(c=>{
								for (let i = 1; i < 100; i++) {
									if (c.recencyBoosts[i] == null) {
										c.recencyBoosts[i] = 1;
										break;
									}
								}
							});
						}}/>
					</Row>
					{Object.entries(config.recencyBoosts).map(([recencyStr, boostAmount], index)=>{
						const recency = parseInt(recencyStr);
						return (
							<Row key={index} ml={10} mt={5}>
								<Text>Recency (days):</Text>
								<Spinner ml={5} enabled={enabled} value={recency} onChange={newRecencyForEntry=>ChangeConfig(c=>{
									// firestore loses the ordering of map-objects anyway, so just use basic delete->set
									delete c.recencyBoosts[recency];
									c.recencyBoosts[newRecencyForEntry] = boostAmount;
								})}/>
								<Text ml={5}>Boost:</Text>
								<Spinner ml={5} enabled={enabled} value={boostAmount} onChange={val=>ChangeConfig(c=>c.recencyBoosts[recency] = val)}/>
								<Button ml={5} mdIcon="delete" enabled={enabled} onClick={()=>{
									ChangeConfig(c=>delete c.recencyBoosts[recency]);
								}}/>
							</Row>
						);
					})}

					<Row mt={10} style={{fontWeight: "bold"}}>Audio files</Row>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							Subpath to folder where target sound files are stored. The concept-link system will randomly select one of these files to play.
							Example: "Alarms/Visualizers" (generally maps to MainStorage/Alarms/Visualizers)
						`.AsMultiline(0)}>Sound folder:</TextPlus>
						<TextInput enabled={enabled} value={config.soundFolder} onChange={val=>ChangeConfig(c=>c.soundFolder = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5}>Sound volume:</TextPlus>
						<Spinner enabled={enabled} min={0} max={100} value={(config.soundVolume * 100).RoundTo(.01)} onChange={val=>ChangeConfig(c=>c.soundVolume = val / 100)}/>
						<Text>%</Text>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							This can help avoid the need for editing sound-files. (to make them short enough to work with the concept-link system)
						`.AsMultiline(0)}>Duration limit:</TextPlus>
						<Spinner min={0} step={.1} enabled={enabled} value={config.soundDurationLimit} onChange={val=>ChangeConfig(c=>c.soundDurationLimit = val)}/>
						<Text>s</Text>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Effect 2:</Text>
						<EffectPointerInput enabled={enabled} value={config.effect2} onChange={val=>ChangeConfig(c=>c.effect2 = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Effect 2, delay:</Text>
						<Spinner enabled={enabled} value={config.effect2_delay} onChange={val=>ChangeConfig(c=>c.effect2_delay = val)}/>
						<Text>s</Text>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Effect 2, chance:</Text>
						<Spinner enabled={enabled} min={0} max={100} value={(config.effect2_chance * 100).RoundTo(.01)} onChange={val=>ChangeConfig(c=>c.effect2_chance = val / 100)}/>
						<Text>%</Text>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Effect 2, chance as buildup:</Text>
						<CheckBox enabled={enabled} value={config.effect2_chanceAsBuildup} onChange={val=>ChangeConfig(c=>c.effect2_chanceAsBuildup = val)}/>
					</RowLR>

					<Row mt={5}>
						<CheckBox enabled={enabled} text="Use as solver" value={config.solver_enabled} onChange={val=>ChangeConfig(c=>c.solver_enabled = val)}/>
					</Row>
					{config.solver_enabled &&
					<Column mt={5} ml={10}>
						<RowLR splitAt={splitAt - 10}>
							<Text>Min sequences:</Text>
							<Spinner enabled={enabled} min={0} value={config.solver_minSequences} onChange={val=>ChangeConfig(c=>c.solver_minSequences = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<TextPlus info={`
								When enabled, the solver is able to mark progress even before the completion criteria is met.
								Example: If 3 sequences are visualized (with 5 required), solver still marks progress. (thus, eg. interrupting the alarms)
							`.AsMultiline(0)}>Mark progress before completion:</TextPlus>
							<CheckBox enabled={enabled} value={config.solver_markProgressBeforeCompletion} onChange={val=>ChangeConfig(c=>c.solver_markProgressBeforeCompletion = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<Text mr={5}>Max time for progress:</Text>
							<Spinner enabled={enabled} value={config.solver_maxTimeForProgress} onChange={val=>ChangeConfig(c=>c.solver_maxTimeForProgress = val)}/>
							<Text>s</Text>
						</RowLR>
					</Column>}
				</>}
			</Column>
		);
	}
}