import {Command, AssertV} from "mobx-firelink";
import {GetTestSegment} from "../../Store/firebase/testSegments";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";
import {GetEntity} from "../../Store/firebase/entities";
import {GetScene} from "../../Store/firebase/scenes.js";

export class DeleteScene extends Command<{id: string}> {
	Validate() {
		const {id} = this.payload;
		const oldData = GetScene(id);
		AssertV(IsUserCreatorOrMod(this.userInfo?.id, oldData), "Insufficient permissions.");
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`scenes/${id}`] = null;
		return updates;
	}
}