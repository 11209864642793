import {InfoButton, Observer} from "web-vcore";
import React from "react";
import {CheckBox, Column, Row, RowLR, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.journeyVisualization, (c, v)=>c.journeyVisualization = v);

const splitAt = 150;

@Observer
export class JourneyVisualization_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Journey -> Visualization Mode" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>User nudge:</Text>
						<ControlInput enabled={enabled} value={config.userNudge_triggerSet} onChange={val=>ChangeConfig(c=>c.userNudge_triggerSet = val)}/>
						<Row center>
							<Text ml={10}>Sound tag:</Text>
							<InfoButton ml={5} text="When enabled, and the user triggers the nudge event, a random sound with the given tag is played."/>
							<TextInput ml={5} style={{width: 100}} enabled={enabled} value={config.userNudge_soundTag} onChange={val=>ChangeConfig(c=>c.userNudge_soundTag = val)}/>
						</Row>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Voice, tag:</Text>
						<TextInput enabled={enabled} style={{width: 80}} value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>
						<Text ml={5} mr={5}>Vol. mult.:</Text>
						<Spinner min={0} step={0.01} enabled={enabled} value={config.volumeMultiplier} onChange={val=>ChangeConfig(c=>c.volumeMultiplier = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Entity tags:</Text>
						<TextInput enabled={enabled} style={{width: "100%"}}
							value={(config.entityTags || []).join(", ")} onChange={val=>ChangeConfig(c=>c.entityTags = val.split(",").map(a=>a.trim()).filter(a=>a))}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Seeds, order key:</Text>
						<TextInput enabled={enabled} value={config.seeds_orderKey} onChange={val=>ChangeConfig(c=>c.seeds_orderKey = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Voice entity # in cycle:</Text>
						<CheckBox enabled={enabled} value={config.voiceEntityNumberInCycle} onChange={val=>ChangeConfig(c=>c.voiceEntityNumberInCycle = val)}/>
					</RowLR>
					<Row>Note: The targets are only actually spoken when the general journey state is "entryWait_bright" or "entryWait_dim".</Row>

					<RowLR mt={10} splitAt={splitAt}>
						<Text>Speak interval:</Text>
						<Spinner min={0} enabled={enabled} value={config.speakInterval} onChange={val=>ChangeConfig(c=>c.speakInterval = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Cycle reverse, min time:</Text>
						<Spinner min={0} enabled={enabled} value={config.cycleReverse_minTime} onChange={val=>ChangeConfig(c=>c.cycleReverse_minTime = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Cycle reverse, max time:</Text>
						<Spinner min={0} enabled={enabled} value={config.cycleReverse_maxTime} onChange={val=>ChangeConfig(c=>c.cycleReverse_maxTime = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>User reversal detection, max delay:</Text>
						<Spinner min={0} enabled={enabled} value={config.userReversalDetection_maxDelay} onChange={val=>ChangeConfig(c=>c.userReversalDetection_maxDelay = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>User reversal detection fail, wait period:</Text>
						<Spinner min={0} enabled={enabled} value={config.userReversalDetectionFail_waitPeriod} onChange={val=>ChangeConfig(c=>c.userReversalDetectionFail_waitPeriod = val)}/>
					</RowLR>
				</>}
			</Column>
		);
	}
}