import chroma_js from "chroma-js";
import {Bridge, E, IsNaN, IsObject, SleepAsync, ToNumber} from "js-vextensions";
import {HandleError} from "web-vcore";
import {liveFBASession} from "../../Engine/FBASession";
import {store} from "../../Store/index.js";
import {HSBK2RGB} from "../../Utils/General/Colors";
import {PluginGeneral} from "../Capacitor/GeneralPlugin.js";
import {NodeBridge_AddChannelMessageReceiver, NodeBridge_SendChannelMessage} from "./Bridge_Preload";

export function InDesktop() {
	return inElectron;
	//return g.Capacitor.platform == "electron";
}
//let inAndroid: boolean;
export function InAndroid(allowURLFlagToModify: 0 | 1) {
	/*inAndroid = false;
	(async()=>{
		try {
			await Plugins["General"].Test1();
			// if not in android, we won't reach this, since the call above will immediately error ("no web implementation")
			inAndroid = true;
		} catch (error) {
			console.log("Apparently not in Android. Got error when trying to call Test1: ", error);
		}
	})();*/
	//inAndroid = g.Capacitor != null;
	//inAndroid = Plugins.Device.getInfo().[...];
	return g.Capacitor.platform == "android" || (allowURLFlagToModify && startURL.GetQueryVar("extra") == "android"); // flag is for debugging
}
export function InNativeApp(allowURLFlagToModify: 0 | 1) {
	return InDesktop() || InAndroid(allowURLFlagToModify);
}

export const nativeBridge = new Bridge({
	receiveChannelMessageFunc_adder: receiveChannelMessageFunc=>{
		if (!InNativeApp(0)) return;

		if (InDesktop()) {
			// function populated by Start_WebviewPreload.ts of lucid-frontier-desktop project
			NodeBridge_AddChannelMessageReceiver(channelMessage=>{
				receiveChannelMessageFunc(channelMessage);
			});
		} else {
			// function declared in General.java under "android/..."
			/*General["AddNativeMessageListener"](channelMessage=>{
				receiveChannelMessageFunc(channelMessage);
			});*/
			// this gets called by bridgeJS.sendChannelMessageFunc in General.kt
			window.addEventListener("BridgeNative_SendChannelMessage", channelMessage=>{
				receiveChannelMessageFunc(channelMessage);
			});
		}
	},
	sendChannelMessageFunc: channelMessage=>{
		if (!InNativeApp(0)) return;

		if (InDesktop()) {
			// function populated by Start_WebviewPreload.ts of lucid-frontier-desktop project
			NodeBridge_SendChannelMessage(channelMessage);
		} else {
			// function declared in General.java under "android/..."
			PluginGeneral.SendToNative(channelMessage);
		}
	},
	channel_stringifyChannelMessageObj: false,
	requireMainFuncForCalls: false, // for, eg. NotifyVolumeKeyDown listener in RecordingPanel.tsx
});
nativeBridge.RegisterFunction("FromNode_HandleGlobalError", errorStr=>{
	HandleError(errorStr);
});

type Command = Partial<{
	// custom metadata
	url: string;
	token: string;

	// shared
	color: string;

	// for "set state" command
	brightness: number;
	power: "on" | "off";
	duration: number;
	infrared: number;
	fast: boolean;

	// for effects
	from_color: string;
	period: number;
	cycles: number;
	persist: boolean;
	power_on: boolean;
	peak: number;
	palette: string[];

	// custom (not part of web-api); needed for Pulse effect apparently (node-lifx-lan says so)
	skew_ratio: number;
}>;
// only includes action-endpoints
const lifxEndpoints = ["state", "states", "state/delta", "toggle", "effects/breathe", "effects/move", "effects/morph", "effects/flame", "effects/pulse", "effects/off", "cycle", "activate"];
const lifxWaveforms = ["move", "breathe", "morph", "flame", "pulse"]; // web-api names for these waveforms; they correspond to... 0: SAW, 1: SINE, 2: HALF_SINE, 3: TRIANGLE, 4: PULSE

let currentLanLIFXCommandPromise: Promise<any>|n;
/**
Input command object/json based on lifx http api: https://api.developer.lifx.com
Output calls based on node-lifx-lan api: https://github.com/futomi/node-lifx-lan
*/
export async function ApplyLIFXCommandOverLAN(command: Command) {
	if (currentLanLIFXCommandPromise) await Promise.race([SleepAsync(5000), currentLanLIFXCommandPromise]);

	currentLanLIFXCommandPromise = go();
	await currentLanLIFXCommandPromise;
	currentLanLIFXCommandPromise = null;

	async function go() {
		const endPoint = lifxEndpoints.find(a=>command.url!.endsWith(`/${a}`));

		if (endPoint == "state") {
			if (command.power == "on") {
				await RunLIFXCommand_LAN("turnOn");
			} else if (command.power == "off") {
				await RunLIFXCommand_LAN("turnOff");
			}

			if (command.color || command.brightness) {
				await LIFXSetColor_FromWebAPI(command.color!, command.brightness, command.duration);
			}
		} else if (endPoint == "states") {
			// todo
		} else if (endPoint == "state/delta") {
			// todo
		} else if (endPoint == "toggle") {
			// todo
		} else if (endPoint!.startsWith("effects/") && endPoint != "effects/off") {
			if (command.power_on) {
				await RunLIFXCommand_LAN("turnOn");
			}
			if (command.from_color) {
				await LIFXSetColor_FromWebAPI(command.from_color);
			}

			const effectName = endPoint!.split("/")[1];
			const [hue, saturation, brightness, kelvin] = LIFXColorStringToHSBK(command.color!);
			// all four fields must have a value; just use 3500
			//if (kelvin == null) kelvin = 3500;

			await RunLIFXCommand_LAN("lightSetWaveform", {
				transient: command.persist ? 0 : 1,
				color: {hue, saturation, brightness, kelvin},
				period: command.period != null ? command.period * 1000 : 1000,
				cycles: command.cycles != null ? command.cycles : 1,
				waveform: lifxWaveforms.indexOf(effectName),
				skew_ratio: command.skew_ratio != null ? command.skew_ratio : .5, // only used if Pulse effect; tells what percentage of the time the "from_color" is shown
			});
		} else if (endPoint == "effects/off") {
			// todo
		} else if (endPoint == "cycle") {
			// todo
		} else if (endPoint == "activate") {
			// todo
		}
	}
}
export function GetLIFXDevices() {
	return nativeBridge.Call("GetLIFXDevices") as Promise<{ip: string, mac: string, deviceInfo: any}[]>;
}
export function RunLIFXCommand_LAN(funcName: string, argsObj?: Object): Promise<any> {
	console.log("Running LIFX LAN command (site): ", funcName, argsObj);
	const uiState = store.main.effects.lights;
	/*if (InDesktop()) {
		return RunLIFXCommand_LAN_Desktop(funcName, argsObj, {ip: uiState.lifx_ipAddress, mac: uiState.lifx_macAddress});
	}*/
	return nativeBridge.Call("RunLIFXCommand_LAN", funcName, argsObj, {ip: uiState.lifx_ipAddress, mac: uiState.lifx_macAddress}) as Promise<any>;
}

// wrapper around lifx-lan set-color command, meant to be convenient to call using a web-api command's json
async function LIFXSetColor_FromWebAPI(lifxColorStr: string, brightnessOverride?: number, duration?: number) {
	let [hue, saturation, brightness, kelvin] = [null, null, null, null] as (number|n)[];
	if (lifxColorStr) {
		[hue, saturation, brightness, kelvin] = LIFXColorStringToHSBK(lifxColorStr);
	}
	if (brightnessOverride != null) {
		brightness = brightnessOverride;
	}

	// device.setColor gets the old color, then applies any changes provided (and accepts multiple color formats/overloads -- we always use the css overload)
	// device.setLightColor just sets the color to what's provided
	await RunLIFXCommand_LAN("setColor", E(
		{
			/*color: E(
				colorCSS && {css: colorCSS},
				// if brightness isn't specified, brightness is inferred (when "css" overload is used, and maybe rest too) -- it seems setColor has some inference code, as well as the LIFX bulb itself (kinda spaghetti code tbh)
				brightness && {brightness},
			),*/
			color: E(
				hue != null && {hue},
				saturation != null && {saturation},
				brightness != null && {brightness},
				kelvin != null && {kelvin},
			),
		},
		duration != null && {duration},
		//command.fast && {fast: command.fast},
	));

	//if (command.infrared) {}
}

/*function LIFXColorStringToCSSColorStringAndBrightness(lifxColorStr: string) {
	let color = lifxColorStr;
	let brightness: number = null;

	const brightnessStrMatch = color.match(/ brightness:(\d+)/);
	if (brightnessStrMatch) {
		color = color.replace(brightnessStrMatch[0], "");
		brightness = ToNumber(brightnessStrMatch[1]);
	}
	// maybe todo: support parsing of " saturation:X"
	/*let saturationStrMatch = color.match(/ saturation:(\d+)/);
	if (saturationStrMatch) {
		color = color.replace(saturationStrMatch[0], "");
		brightness = ToNumber(saturationStrMatch[1]);
	}*#/
	// maybe todo: support parsing of " kelvin:X"

	return {color, brightness};
}*/
export function LIFXColorStringToHSBK(lifxColorStr: string, defaultKelvinToNeutral = true) {
	// maybe temp; also allow a command-json to specify a color like so: {h:0, s:0, l:0}
	if (IsObject(lifxColorStr)) {
		var lifxColorObj = lifxColorStr as any;
		lifxColorStr = "[obj]";
	}

	// kelvin is like a minor [hue--and-brightness only used for whitish-only bulbs; defaults to 3500, can be overriden with " kelvin:X" substring
	let [hue, saturation, brightness, kelvin] = [null, null, null, null] as (number|n)[];

	const baseColorStr = lifxColorStr.match(/^([^: ]+)( hue| saturation| brightness| kelvin|$)/)?.[1];
	if (baseColorStr || lifxColorObj) {
		const baseColor = chroma_js(lifxColorObj || baseColorStr);
		let hueInDegrees;
		[hueInDegrees, saturation, brightness] = baseColor.hsv(); // hsv is the same as hsb
		hue = hueInDegrees / 360;
		if (IsNaN(hue)) hue = 0; // if NaN, it means pure black or white; just pick an arbitrary hue (NaN is invalid to send)
	}

	const hueStr = lifxColorStr.match(/hue:([\d.]+)/)?.[1];
	if (hueStr) {
		//[hue, saturation, brightness] = baseColor.set("hsv.h", ToNumber(hueStrMatch[1])).hsv();
		hue = ToNumber(hueStr);
	}
	const saturationStr = lifxColorStr.match(/saturation:([\d.]+)/)?.[1];
	if (saturationStr) {
		saturation = ToNumber(saturationStr);
	}
	const brightnessStr = lifxColorStr.match(/brightness:([\d.]+)/)?.[1];
	if (brightnessStr) {
		brightness = ToNumber(brightnessStr);
	}
	const kelvinStr = lifxColorStr.match(/kelvin:([\d.]+)/)?.[1];
	const kelvin_magicStringForKeepUnset = "000";
	if (kelvinStr && kelvinStr != kelvin_magicStringForKeepUnset) {
		kelvin = ToNumber(kelvinStr);
	}

	// if kelvin is unspecified (and not set to keep-unset-marker of "000"), and defaulting kelvin to neutral is allowed, do so
	// todo: have this "fix" apply for http-api calls as well (atm, color:white apparently defaults to 3500 kelvin through it)
	if (kelvin == null && kelvinStr != kelvin_magicStringForKeepUnset && defaultKelvinToNeutral) {
		// 6600 is apparently the exact kelvin value which causes HSB/HSV color to "pass through" without WB shifting (confirmed for basic colors in console, using Colors.ts->HSBK2RGB)
		// inferred from: https://github.com/tort32/LightServer/blob/db57f45a934a15d6d133696ec3aa95d0b3842a36/src/main/java/com/github/tort32/api/nodemcu/protocol/RawColor.java#L108
		kelvin = 6600;
	}

	return [hue, saturation, brightness, kelvin];
}
HSBK2RGB(0, 0, 0, 0); // uncomment to test HSBK2RGB() and such in console

export function RunKasaCommand_LAN(funcName: string, commandObj?: Object): Promise<any> {
	console.log("Running Kasa LAN command: ", commandObj);
	/*const uiState = store.main.effects.lights;
	if (InDesktop()) {
		return RunKasaCommand_LAN_Desktop(funcName, argsObj, {ip: uiState.lifx_ipAddress, mac: uiState.lifx_macAddress});
	}*/
	return nativeBridge.Call("RunKasaCommand_LAN", commandObj) as Promise<any>;
}

// general Android stuff
// ==========

// if disabled, probably due to wanting to avoid memory-leaks, for if something keeps creating more timers (or if number just gets high enough, since timers aren't auto-removed from this list)
//	(another possible reason is: to make it more obvious when the keep-awake systems aren't working, since even if we make timers work, other stuff might not be working, like mic recording)
//TimerContext.default_autoAddAll = true;

// for this one function, we'll just use the capacitor bridge, since the js-vextensions Bridge has more overhead (eg. callback)
/*nativeBridge.RegisterFunction("WakeUp", pack=>{
	const {sendTime, lastNonZeroMicLoudness} = pack as any;
	//SessionLog(`Got WakeUp event! @SendDelay(${Date.now() - sendTime}) @LastNZMicLoudness(${lastNonZeroMicLoudness})`);
	TimerContext.default.ManuallyTriggerOverdueTimers();
});*/
window.addEventListener("WakeUp", event=>{
	const {sendTime, lastNonZeroMicLoudness} = event as any;
	//SessionLog(`Got WakeUp event! @SendDelay(${Date.now() - sendTime}) @LastNZMicLoudness(${lastNonZeroMicLoudness})`);
	//TimerContext.default.ManuallyTriggerOverdueTimers();
	//if (fbaCurrentSession && InAndroid(0) && !screenOn) {
	if (liveFBASession && InAndroid(0)) {
		liveFBASession.timerContext.ManuallyTriggerOverdueTimers();
	}
});