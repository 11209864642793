import {GetSounds, GetSounds_WithUserTag} from "../../../Store/firebase/sounds";
import {SoundPlayer} from "../../../Utils/EffectPlayers/SoundPlayer";
import {FBAConfig_Background} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Background";
import {TriggerPackage, FBASession} from "../../../Engine/FBASession";
import {EngineSessionComp} from "./EngineSessionComp";
import {SoundAlarmComp} from "./AlarmComps/SoundAlarmComp";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";

export class BackgroundComp extends EngineSessionComp<FBAConfig_Background> {
	constructor(session: FBASession, config: FBAConfig_Background) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("Background_IncreaseVolume", this.c.increaseVolume_triggerSet, this, {}, triggerInfo=>{
				this.volume = (this.volume + this.c.increaseVolume_amount).KeepBetween(0, 1);
				// if prompt and background are same, have prompt-volume reset to just-modified background volume (to prevent jarring increases)
				/*for (const comp of this.s.Comps(SoundAlarmComp)) {
					if (!comp.c.separate) comp.intensity = this.volume;
				}*/
				this.backgroundSoundPlayer.SetLiveVolume(this.volume);
			}),
			new TriggerPackage("Background_DecreaseVolume", this.c.decreaseVolume_triggerSet, this, {}, triggerInfo=>{
				this.volume = (this.volume - this.c.decreaseVolume_amount).KeepBetween(0, 1);
				// if prompt and background are same, have prompt-volume reset to just-modified background volume (to prevent jarring increases)
				/*for (const comp of this.s.Comps(SoundAlarmComp)) {
					if (!comp.c.separate) comp.intensity = this.volume;
				}*/
				this.backgroundSoundPlayer.SetLiveVolume(this.volume);
			}),
		];
	}

	OnInitialDelayCompleted() {
		this.StartBackground(); // we have to await StartBackground, so that if background and prompt sound-player is the same, we don't double-start it
	}

	OnStop() {
		this.backgroundSoundPlayer.Stop();
	}

	backgroundSoundPlayer = new SoundPlayer();

	volume = 0;

	async StartBackground() {
		this.volume = this.c.startVolume;
		this.backgroundSoundPlayer.sound = GetSounds_WithUserTag(this.c.tag).Random();
		if (this.backgroundSoundPlayer.sound == null) return;

		//await this.backgroundSoundPlayer.WaitTillPlayStarted();
		await this.backgroundSoundPlayer.PrepareToPlay();
		//Assert(this.backgroundSoundPlayer.prepared);

		// if loopable, set up looping
		if (this.backgroundSoundPlayer.youtubePlayer) {
			this.Log("Setting up background-sound looping.", LogType.Event_Large);
			/*this.backgroundSoundPlayer.youtubePlayer.AddStateListener(state=> {
				if (state == YoutubePlayerState.ENDED) {
					this.backgroundSoundPlayer.youtubePlayer.Play();
				}
			});*/
			this.backgroundSoundPlayer.youtubePlayer.loop = true;
		}

		this.backgroundSoundPlayer.Play(this.volume);
	}
}