import {TelemetryData} from "muse-js";
import {Column, RowLR, Text, Row, TimeSpanInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../../Store/index.js";
import {Observer, TextPlus, InfoButton, RunInAction_Set} from "web-vcore";
import {currentMuse} from "../../@Shared/MuseInterface";

/*@Observer
export class OthersPanel_ControlUI extends BaseComponentPlus({}, {}) {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.monitor;
		return (
			<Row ml="auto">
			</Row>
		);
	}
}*/

@Observer
export class OthersPanel extends BaseComponentPlus({}, {data: null as TelemetryData|n}) {
	ComponentDidMount() {
		this.SetState({data: currentMuse.lastProcessedTelemetrySample});
		currentMuse.telemetryListeners.push(this.telemetryListener);
	}
	ComponentWillUnmount() {
		currentMuse.telemetryListeners.Remove(this.telemetryListener);
	}
	telemetryListener = data=>{
		this.SetState({data});
	};

	render() {
		const {data} = this.state;
		const uiState = store.main.tools.monitor;
		const splitAt = 170;
		return (
			<Column mt={5}>
				<Row style={{fontWeight: "bold"}}>Status</Row>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Battery:</Text>
					<Text>{((data?.batteryLevel ?? 0) / 100).ToPercentStr(1) ?? "n/a"}</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Voltage:</Text>
					<Text>{data?.fuelGaugeVoltage.toFixed(2) ?? "n/a"}</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Sequence ID:</Text>
					<Text>{data?.sequenceId ?? "n/a"}</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<TextPlus info="Haven't checked yet whether it's in Celsius or Farenheit.">Temperature:</TextPlus>
					<Text>{data?.temperature ?? "n/a"}</Text>
				</RowLR>

				<Row mt={15} style={{fontWeight: "bold"}}>Settings</Row>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Freeze-detect time:</Text>
						<InfoButton ml={5} text={`
							How long to wait (with the Muse headband listed as connected, but not returning new eeg-samples), before marking the connection "layer-2 frozen" and attempting full reconnection.
						`.AsMultiline(0)}/>
					</Row>
					<TimeSpanInput value={uiState.muse_freezeDetectTime} onChange={val=>RunInAction_Set(this, ()=>uiState.muse_freezeDetectTime = val)}/>
				</RowLR>
			</Column>
		);
	}
}