import {EEGPattern} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_EEG";
import {FBAConfig} from "../../../Store/firebase/fbaConfigs/@FBAConfig";
import {EyeMoveDeclarationType, TestSegment} from "../../../Store/firebase/testSegments/@TestSegment";
import {EEGActivityProcessor, EEGShape} from "./EEGActivityProcessor";
import {EEGProcessor} from "./EEGProcessor";

export class Detection_ForUI {
	shape: EEGShape; //Pick<EEGShape, "width" | "height" | "area" | "distFromBaseline">;
	startTime: number;
	endTime: number;
	matchedPatternIndex: number;
	//matchedDeclarations_required: number;
	//matchedDeclarations_optional: number;
	matchingDeclarations: number;
}
function ShapeToDetection(shape: EEGShape, processor: EEGProcessor, config: FBAConfig, matchedPattern: EEGPattern, matchingDeclarations: number) {
	const shapeStartTime = processor.samples_time[shape.startIndex] * 1000;
	const shapeEndTime = processor.samples_time[shape.endIndex] * 1000;
	return {
		/*shape: {
			...shape.IncludeKeys("width", "height", "area", "distFromBaseline"),
			startTime: shapeStartTime,
			endTime: shapeEndTime,
		},*/
		shape,
		startTime: shapeStartTime,
		endTime: shapeEndTime,
		matchedPatternIndex: config.eeg.motion_eegPatterns.indexOf(matchedPattern),
		matchingDeclarations,
	};
}

export type TestResult = ReturnType<typeof RunTestSegment>;
export function RunTestSegment(segment: TestSegment, config: FBAConfig, processor: EEGProcessor, falseDetectionPenalty: number) {
	const possibleDetections = [] as Detection_ForUI[];
	const detections = [] as Detection_ForUI[];
	let hits_forRequireds = 0;
	let hits_forOptionals = 0;
	let falseDetections = 0;

	const activityProcessor = new EEGActivityProcessor({
		eegProcessor: processor,
		postShapeEnd: (sampleTime, shape, matchedPattern)=>{
			const shapeStartTime = processor.samples_time[shape.startIndex] * 1000;
			const shapeEndTime = processor.samples_time[shape.endIndex] * 1000;
			// don't count shapes before segment start-time (those samples are just part of the buffer, or the baseline-establishing fake-sample section)
			if (shapeStartTime < segment.startTime) return;

			let matchingDeclarations = 0;
			for (const dec of segment.eyeMoveDeclarations) {
				if (dec.time.IsBetween(shapeStartTime, shapeEndTime)) {
					if (matchedPattern) {
						if (dec.type == EyeMoveDeclarationType.Required) hits_forRequireds++;
						else if (dec.type == EyeMoveDeclarationType.Optional) hits_forOptionals++;
					}
					matchingDeclarations++;
				}
			}

			const detection = ShapeToDetection(shape, processor, config, matchedPattern, matchingDeclarations);
			possibleDetections.push(detection);
			if (matchedPattern) {
				if (matchingDeclarations == 0) {
					falseDetections++;
				}
				detections.push(detection);
			}
		},
		/*postMotionTrigger: (sampleTime, shape, matchedPattern)=>{
			const shapeStartTime = processor.samples_time[shape.startIndex] * 1000;
			const shapeEndTime = processor.samples_time[shape.endIndex] * 1000;
			let matchingDeclarations = 0;
			for (const dec of segment.eyeMoveDeclarations) {
				if (dec.time.IsBetween(shapeStartTime, shapeEndTime)) {
					if (dec.type == EyeMoveDeclarationType.Required) hits_forRequireds++;
					else if (dec.type == EyeMoveDeclarationType.Optional) hits_forOptionals++;
					matchingDeclarations++;
				}
			}
			if (matchingDeclarations == 0) {
				falseDetections++;
			}

			const detection = ShapeToDetection(shape, processor, config, matchedPattern, matchingDeclarations);
			detections.push(detection);
		},*/
		//postEEGActivityChange: (eegActivity, sampleTime)=>{},
	});
	for (let i = 0; i < processor.SampleCount; i++) {
		activityProcessor.RespondToSample(i);
	}

	const requiredHitsTotal = segment.eyeMoveDeclarations.filter(a=>a.type == EyeMoveDeclarationType.Required).length;
	const requiredHitsNotMet = requiredHitsTotal - hits_forRequireds;
	return {
		possibleDetections,
		detections,
		required_hits: hits_forRequireds,
		optional_hits: hits_forOptionals,
		falseDetections,
		score: 1 - (requiredHitsNotMet / requiredHitsTotal.KeepAtLeast(1)) - (falseDetections * falseDetectionPenalty),
	};
}