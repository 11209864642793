import NoSleep from "nosleep.js";
import {InAndroid} from "../../Utils/Bridge/Bridge_Native";
import {Timer, WaitXThenRun, E} from "js-vextensions";
import {PluginGeneral} from "../Capacitor/GeneralPlugin.js";

export const sleepBlocker = new NoSleep();

// needed since "sleepBlocker" (ie. "nosleep.js") doesn't seem to work consistently
let stopSleepV2_video: HTMLVideoElement|n;
export function NoSleepV2_Enable() {
	//if (stopSleepV2_video == null) {
	if (stopSleepV2_video != null) {
		NoSleepV2_Disable();
	}

	function AsBase64DataStr(mimeType: string, base64: string) {
		return `data:${mimeType};base64,${base64}`;
	}

	stopSleepV2_video = document.createElement("video");
	stopSleepV2_video.setAttribute("loop", "");

	function addSourceToVideo(element, type, dataURI) {
		var source = document.createElement("source");
		source.src = dataURI;
		source.type = `video/${type}`;
		element.appendChild(source);
	}

	// browser uses the first one it understands
	// (disabling the webm version for now, since causes really high cpu usage in latest electron [6.0.2] -- 10% with engine off, +5% [10->15] with engine on)
	//addSourceToVideo(stopSleepV2_video, "webm", AsBase64DataStr("video/webm", "GkXfo0AgQoaBAUL3gQFC8oEEQvOBCEKCQAR3ZWJtQoeBAkKFgQIYU4BnQI0VSalmQCgq17FAAw9CQE2AQAZ3aGFtbXlXQUAGd2hhbW15RIlACECPQAAAAAAAFlSua0AxrkAu14EBY8WBAZyBACK1nEADdW5khkAFVl9WUDglhohAA1ZQOIOBAeBABrCBCLqBCB9DtnVAIueBAKNAHIEAAIAwAQCdASoIAAgAAUAmJaQAA3AA/vz0AAA="));
	addSourceToVideo(stopSleepV2_video, "mp4", AsBase64DataStr("video/mp4", "AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAG21kYXQAAAGzABAHAAABthADAowdbb9/AAAC6W1vb3YAAABsbXZoZAAAAAB8JbCAfCWwgAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAIVdHJhawAAAFx0a2hkAAAAD3wlsIB8JbCAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAIAAAACAAAAAABsW1kaWEAAAAgbWRoZAAAAAB8JbCAfCWwgAAAA+gAAAAAVcQAAAAAAC1oZGxyAAAAAAAAAAB2aWRlAAAAAAAAAAAAAAAAVmlkZW9IYW5kbGVyAAAAAVxtaW5mAAAAFHZtaGQAAAABAAAAAAAAAAAAAAAkZGluZgAAABxkcmVmAAAAAAAAAAEAAAAMdXJsIAAAAAEAAAEcc3RibAAAALhzdHNkAAAAAAAAAAEAAACobXA0dgAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAIAAgASAAAAEgAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABj//wAAAFJlc2RzAAAAAANEAAEABDwgEQAAAAADDUAAAAAABS0AAAGwAQAAAbWJEwAAAQAAAAEgAMSNiB9FAEQBFGMAAAGyTGF2YzUyLjg3LjQGAQIAAAAYc3R0cwAAAAAAAAABAAAAAQAAAAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAEAAAABAAAAFHN0c3oAAAAAAAAAEwAAAAEAAAAUc3RjbwAAAAAAAAABAAAALAAAAGB1ZHRhAAAAWG1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAAK2lsc3QAAAAjqXRvbwAAABtkYXRhAAAAAQAAAABMYXZmNTIuNzguMw=="));

	//stopSleepV2_video.play();

	/*stopSleepV2_video.load();
	WaitXThenRun(0, ()=>stopSleepV2_video.play());*/

	stopSleepV2_video.pause();
	stopSleepV2_video.addEventListener("canplay", function onCanPlay() {
		stopSleepV2_video!.removeEventListener("canplay", onCanPlay);
		stopSleepV2_video!.play();
		console.log("stopSleepV2_video play started");
	});
	stopSleepV2_video.load();
}
export function NoSleepV2_Disable() {
	if (!stopSleepV2_video) return;
	//stopSleepV2_video.pause();
	stopSleepV2_video.remove();
	stopSleepV2_video = null;
}

export function EnableFullLockJS() {
	// keep device from sleeping
	sleepBlocker.enable();
	NoSleepV2_Enable();
}
export function DisableFullLockJS() {
	// disable the sleep-blocker
	sleepBlocker.disable();
	NoSleepV2_Disable();
}

export class SleepBlockerOptions {
	constructor(defaultPropValue = true, propOverrides?: Partial<SleepBlockerOptions>) {
		this.Extend({
			fullLock_js: defaultPropValue,
			partialLock: defaultPropValue,
			wifiLock: defaultPropValue,
			antiBatteryOptimizations: defaultPropValue,
			antiWebViewOptimizations: defaultPropValue,
		});
		this.Extend(propOverrides);
	}
	fullLock_js: boolean;
	partialLock: boolean;
	wifiLock: boolean;
	antiBatteryOptimizations: boolean;
	antiWebViewOptimizations: boolean;
}

/** In some cases, the sleep-blockers are only able to work as "pretend user interacted with device to keep it awake" events, so we need to repeatedly call them. */
export function EnableSleepBlockers_Repeating(initialBlockers: Partial<SleepBlockerOptions>, repeatBlockers: Partial<Omit<SleepBlockerOptions, "repeat_blockerOptions" | "repeat_interval">>, repeatInterval: number, initialDelay = 0) {
	const result = new Timer(repeatInterval, ()=>{
		EnableSleepBlockers(result.callCount_total == 1 ? initialBlockers : repeatBlockers);
	});
	result.Start(initialDelay);
	return result;
}

// todo: add a "foreground service" option, if there's a way to do so cleanly
export function EnableSleepBlockers(options?: Partial<SleepBlockerOptions>) {
	options = E(new SleepBlockerOptions(), options);

	if (options.fullLock_js) {
		EnableFullLockJS();
	}
	if (options.partialLock && InAndroid(0)) {
		PluginGeneral.StartPartialWakeLock();
	}
	if (options.wifiLock && InAndroid(0)) {
		// From: https://developer.zebra.com/community/home/blog/2018/10/26/keeping-your-application-running-when-the-device-wants-to-sleep
		// Note: "WiFi locks are only applicable to Android Nougat devices and below (Marshmallow, Lollipop etc).  From Android Oreo onward the WiFi will always be on when the device is sleeping."
		PluginGeneral.StartWifiLock();
	}
	if (options.antiBatteryOptimizations && InAndroid(0)) {
		PluginGeneral.DisableBatteryOptimizations();
	}
	if (options.antiWebViewOptimizations && InAndroid(0)) {
		PluginGeneral.DisableWebViewOptimizations();
	}
	console.log("Sleep blockers enabled");
}
export function DisableSleepBlockers(options?: Partial<Omit<SleepBlockerOptions, "antiBatteryOptimizations" | "antiWebViewOptimizations" | "repeat_blockerOptions" | "repeat_interval">>) {
	options = E(new SleepBlockerOptions(), options);

	if (options.fullLock_js) {
		DisableFullLockJS();
	}
	if (options.partialLock && InAndroid(0)) {
		PluginGeneral.StopPartialWakeLock();
	}
	if (options.wifiLock && InAndroid(0)) {
		PluginGeneral.StopWifiLock();
	}
	/*if (options.antiBatteryOptimizations && InAndroid(0)) {
		PluginGeneral.EnableBatteryOptimizations();
	}*/
	/*if (options.antiWebViewOptimizations && InAndroid(0)) {
		PluginGeneral.EnableWebViewOptimizations();
	}*/
	console.log("Sleep blockers disabled");
}