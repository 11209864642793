import {FBAConfig} from "../../Store/firebase/fbaConfigs/@FBAConfig";
import {AssertValidate, GenerateUUID, Command, AssertV} from "mobx-firelink";

export class AddFBAConfig extends Command<{entry: FBAConfig}, string> {
	id: string;
	Validate() {
		const {entry} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		entry.creator = this.userInfo!.id;
		entry.createdAt = Date.now();
		entry.editedAt = Date.now();

		this.returnData = this.id;
		AssertValidate("FBAConfig", entry, "FBA-config invalid");
	}

	GetDBUpdates() {
		const {entry} = this.payload;
		const updates = {};
		updates[`fbaConfigs/${this.id}`] = entry;
		return updates;
	}
}