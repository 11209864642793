import {store} from "../../../Store/index.js";
import {GetSessions} from "../../../Store/firebase/sessions.js";
import {MeID} from "../../../Store/firebase/users.js";
import {Observer, RunInAction} from "web-vcore";
import Moment from "moment";
import {Column, Row, Select, Text, TextArea} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {evaluators} from "./SessionTimelinePanel.js";

@Observer
export class SessionStatsPanel extends BaseComponentPlus({}, {}) {
	render() {
		//const events = GetTimelineEvents(MeID());
		const sessions = GetSessions(MeID());
		const evaluatorName = store.main.tools.engine.evaluatorName;
		//evaluatorName = evaluatorName || evaluators[0].name;

		const evaluator = evaluators.find(a=>a.name == evaluatorName);
		const sessionsWithMemPromptData = sessions.filter(a=>a.events.Any(event=>event.type.startsWith("MemoryPrompt.Target")));
		return (
			<Column>
				<Row>
					<Text>Evaluator:</Text>
					<Select ml={5} options={[{name: "(none)", value: null as string|n}].concat(evaluators.map(a=>({name: a.name, value: a.name})))} value={evaluatorName}
						onChange={val=>RunInAction("SessionStatsPanel.evaluator.onChange", ()=>store.main.tools.engine.evaluatorName = val)}/>
				</Row>
				<Row>Sessions: (newest first)</Row>
				{evaluator && sessionsWithMemPromptData.OrderByDescending(a=>a.startTime).map((session, index)=>{
					const result = evaluator.func(session);
					return (
						<Row sel key={index}>
							<Text>{Moment(session.startTime).format("YYYY-MM-DD HH:mm:ss")})</Text>
							<Row ml={5}>Main value: {result.mainValue}{result.mainValue_showAsPercent && ` (${result.mainValue.ToPercentStr(2)})`}</Row>
							{result.summary != null &&
							<Row ml={5}>Summary: {result.summary}</Row>}
							{result.details != null &&
							<Row ml={5}>
								<Text>Details:</Text>
								<TextArea autoSize={true} value={result.details}/>
							</Row>}
						</Row>
					);
				})}
			</Column>
		);
	}
}