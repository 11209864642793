import {AddSchema} from "mobx-firelink";

export class Activity {
	constructor(initialData: Partial<Activity>) {
		this.Extend(initialData);
	}
	_key?: string;
	creator: string;
	//createdAt: number;

	name: string;
	color: string;
}
AddSchema("Activity", {
	properties: {
		creator: {type: "string"},
		//createdAt: {type: "number"},

		name: {type: "string"},
		color: {type: "string"},
	},
});