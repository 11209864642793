import {GetDoc, GetDocs, StoreAccessor} from "mobx-firelink";
import {Light} from "./lights/@Light";
import {EffectVisibility} from "./sounds/@Sound";
import {MeID, Me} from "./users";
import {IsUserCreatorOrMod} from "./users/$user";

export const GetLights = StoreAccessor(s=>(filterOutHidden = true): Light[]=>{
	let result = GetDocs({}, a=>a.lights).map(a=>a?.Cast(Light));
	if (filterOutHidden) result = result.filter(a=>a && (a.visibility == EffectVisibility.Visible || IsUserCreatorOrMod(MeID(), a)));
	return result;
});
export const GetLights_WithUserTag = StoreAccessor(s=>(tagName: string|n, filterOutHidden = true): Light[]=>{
	const lights = GetLights(filterOutHidden);
	const userLightTags = Me()?.entityTags?.lights ?? {};
	return lights.filter(a=>userLightTags[a._key]?.includes(tagName as any));
});
export const GetLight = StoreAccessor(s=>(id: string|n): Light|n=>{
	return GetDoc({}, a=>a.lights.get(id!))?.Cast(Light);
});

const msInHour = 1000 * 60 * 60;
export function GetLightSessions(id: string): number {
	/*let events = GetEvents();
	let activities = GetActivities(MeID());

	let count = 0;
	for (let event of events) {
		let activity = activities.find(a=>a._id == event.activity);
		if (activity && activity.name == "FBA session" && event.fbaConfig && event.fbaConfig.lightID == id) {
			if (event.endTime == null) continue;
			let hours = (event.endTime - event.startTime) / msInHour;
			if (hours < 1) continue; // ignore sessions less than 1 hour long
			count++;
		}
	}
	return count;*/
	return 0;
}
export function GetLightHours(id: string): number {
	/*let events = GetEvents();
	let activities = GetActivities(MeID());

	let totalTime = 0;
	for (let event of events) {
		let activity = activities.find(a=>a._id == event.activity);
		if (activity && activity.name == "FBA session" && event.fbaConfig && event.fbaConfig.lightID == id) {
			if (event.endTime == null) continue;
			totalTime += event.endTime - event.startTime;
		}
	}
	return totalTime / msInHour;*/
	return 0;
}
export function GetLightInductions(id: string): number {
	/*let events = GetEvents();
	let activities = GetActivities(MeID());

	let count = 0;
	for (let event of events) {
		let activity = activities.find(a=>a._id == event.activity);
		if (activity && activity.name.startsWith("LD") && event.fbaConfig && event.fbaConfig.lightID == id) {
			count++;
		}
	}
	return count;*/
	return 0;
}
export function GetLightInductionsPerSession(id: string): number {
	return GetLightInductions(id) / GetLightSessions(id);
}
export function GetLightInductionsPerHour(id: string): number {
	return GetLightInductions(id) / GetLightHours(id);
}