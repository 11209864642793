import {Button, CheckBox, Row, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../Store/index.js";
import {Observer, RunInAction_Set} from "web-vcore";
import {UpdateDownloadedSessionList} from "./SessionsPanel";

@Observer
export class TrainingPanel extends BaseComponentPlus({}, {}) {
	ComponentWillMount() {
		UpdateDownloadedSessionList();
	}

	render() {
		const uiState = store.main.tools.training;
		const localSessions = uiState.localSessionInfos;

		return (
			<>
				<Row>Options:</Row>
				<Row mt={5}>
					<CheckBox ml={5} text="Average left+right as single channel" title="Else, feeds each channel as separate matching-sequence. (for best results, set value to that used by live-session classifier)"
						value={uiState.averageLeftAndRightAsSingleChannel} onChange={val=>RunInAction_Set(this, ()=>uiState.averageLeftAndRightAsSingleChannel = val)}/>
				</Row>
				<Row mt={5}>
					<Button text="Start training" enabled={false} onClick={()=>{
						// todo
					}}/>
				</Row>
				<Row mt={5}>
					<Text>Live training: todo</Text>
				</Row>
				<Row mt={5} style={{fontWeight: "bold"}}>Draft models:</Row>
				{/* todo */}
			</>
		);
	}
}