import {ErrorBoundary, Observer, RunInAction} from "web-vcore";
import {Clone} from "js-vextensions";
import {Column, Row, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {liveFBASession} from "../../../Engine/FBASession.js";
import {store} from "../../../Store/index.js";
import {SessionManagementPanel_Client} from "./ClientPanel/SessionManagementPanel.js";
import {Alarms_ConfigUI} from "./SessionTabPanels/Alarms_ConfigUI.js";
import {Background_ConfigUI} from "./SessionTabPanels/Background_ConfigUI.js";
import {DreamNarration_ConfigUI} from "./SessionTabPanels/DreamNarration_ConfigUI.js";
import {EffectCountsPanel} from "./SessionTabPanels/EffectCountsPanel.js";
import {General_ConfigUI} from "./SessionTabPanels/General_ConfigUI.js";
import {FBAConfigChangerFunc, FixFBAConfigButton, JSONFBAConfigButton, ResetFBAConfigButton} from "./FBAConfigPanel_Local.js";

@Observer
export class FBAConfigPanel_Client extends BaseComponentPlus({}, {}) {
	render() {
		//engineContext: State(a=>a.main.tools.fba.engineContext),
		const channel = store.main.tools.engine.remote_channel;
		const hostIPAddressAndPort = store.main.tools.engine.remote_hostIPAddressAndPort;
		const config = store.main.tools.engine.remoteConfig;
		/*snoozeFailTrigger_volumeKeys: State(a=>a.main.tools.fba.remote_snoozeFailTrigger_volumeKeys),
		snoozeFailTrigger_micLoudness: State(a=>a.main.tools.fba.remote_snoozeFailTrigger_micLoudness),
		snoozeFailTrigger_micLoudness_loudness: State(a=>a.main.tools.fba.remote_snoozeFailTrigger_micLoudness_loudness),*/
		const tracker1 = store.main.tools.engine.liveFBASession_setAt;
		//const active = fbaCurrentSession != null;
		const active = liveFBASession != null && liveFBASession.IsClient();
		const enabled = !active;

		const ChangeConfig = (configChangerFunc: FBAConfigChangerFunc)=>{
			const newConfig = Clone(config);
			// maybe temp; for now just strip away any properties that aren't recognized (fixes error after code upgrades that change property names)
			/*g.oldConfigs = (g.oldConfigs || []).concat(localConfig);
			newConfig = newConfig.IncludeKeys(...GetSchemaJSON("FBAConfig").properties.VKeys());*/

			configChangerFunc(newConfig);
			RunInAction("RemotePanel_ChangeConfig", ()=>store.main.tools.engine.remoteConfig = newConfig);
			//new UpdateFBAConfig({id: config._key, updates: GetUpdates(config, newConfig)}).Run();
		};

		//const largeVersion = window.outerWidth >= 1024;
		const sharedProps = {client: true, enabled, active, config_full: config, ChangeConfig_Full: ChangeConfig};
		return (
			<Row>
				<Column style={{flex: 1}}>
					<SessionManagementPanel_Client {...sharedProps}/>
					<Row>
					<Text>Config:</Text>
					<JSONFBAConfigButton {...sharedProps}/>
					<FixFBAConfigButton {...sharedProps}/>
					<ResetFBAConfigButton {...sharedProps}/>
				</Row>
					<ErrorBoundary /*errorUI={(defaultUI, boundary)=>{
						return (
							<Column>
								<Row>
									<Button mb={5} text="Reset config" onClick={()=>{
										const keysToRemove = config.VKeys(true).filter(key=>GetSchemaJSON("FBAConfig").properties[key] == null);
										ShowMessageBox({
											title: "Reset FBA-remote config?", cancelButton: true,
											message: `
												Clear all invalid options, and reset all valid options to their defaults?

												Keys to remove:${keysToRemove.length == 0 ? " (none)" : keysToRemove.map(key=>`\n* ${key}: ${config[key]}`).join("")}
												Keys to reset:${FBAConfig_default.VKeys(true).map(key=>`\n* ${key}: ${FBAConfig_default[key]}`).join("")}
											`.AsMultiline(0),
											onOK: ()=>{
												/*g.oldConfigs = (g.oldConfigs || []).concat(config);
												ChangeConfig(c=>c.VSet(FBAConfig_default));*#/
												ChangeConfig(c=>{
													// remove invalid
													for (const key of keysToRemove) {
														delete c[key];
													}
													// reset valid
													c.VSet(new FBAConfig({}));
													// try to restore normal ui
													boundary.ClearError();
												});
											},
										});
									}}/>
								</Row>
								{defaultUI}
							</Column>
						);
					}}*/>
						<EffectCountsPanel {...sharedProps}/>
						<General_ConfigUI {...sharedProps}/>
						<Background_ConfigUI {...sharedProps}/>
						{/*<CameraPanel {...sharedProps}/>
						<GyroPanel {...sharedProps}/>
						<EEGPanel {...sharedProps}/>*/}
						<Alarms_ConfigUI {...sharedProps}/>
						<DreamNarration_ConfigUI {...sharedProps}/>
					</ErrorBoundary>
				</Column>
			</Row>
		);
	}
}