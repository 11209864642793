import {AddSchema, UUID_regex} from "mobx-firelink";

export const User_id = "^[a-zA-Z0-9]+$";
export class User {
	_key?: string;
	displayName: string;
	photoURL: string;

	// custom
	// ==========

	joinDate: number;
	permissionGroups: PermissionGroupSet;

	edits: number;
	lastEditAt: number;

	// temp
	rvpPromptCount: number;

	entityTags: EntityTagSetWrapper;
}
AddSchema("User", {
	properties: {
		displayName: {type: "string"},
		photoURL: {type: "string"},

		joinDate: {type: "number"},
		permissionGroups: {$ref: "PermissionGroupSet"},
		edits: {type: "number"},
		lastEditAt: {type: "number"},

		rvpPromptCount: {type: "number"},

		entityTags: {$ref: "EntityTagSetWrapper"},
	},
});

export class PermissionGroupSet {
	basic: boolean;
	verified: boolean; // todo: probably remove
	mod: boolean;
	admin: boolean;
}
AddSchema("PermissionGroupSet", {
	properties: {
		basic: {type: "boolean"},
		verified: {type: "boolean"},
		mod: {type: "boolean"},
		admin: {type: "boolean"},
	},
});

export type EntityTagSetWrapper = {
	sounds: EntityTagSet;
	shakes: EntityTagSet;
	lights: EntityTagSet;
	scripts: EntityTagSet;
	scenes: EntityTagSet;
	entities: EntityTagSet;
};
AddSchema("EntityTagSetWrapper", {
	properties: {
		sounds: {$ref: "EntityTagSet"},
		shakes: {$ref: "EntityTagSet"},
		lights: {$ref: "EntityTagSet"},
		scripts: {$ref: "EntityTagSet"},
		scenes: {$ref: "EntityTagSet"},
		entities: {$ref: "EntityTagSet"},
	},
});

export type EntityTagSet = {
	// entity id -> tag list
	[key: string]: string[]
};
AddSchema("EntityTagSet", {patternProperties: {
	[UUID_regex]: {items: {type: "string"}},
}});