import {store} from "../../../../Store";
import {GetSelectedFBAConfig} from "../../../../Store/firebase/fbaConfigs.js";
import {EEGProcessor} from "../../../../UI/@Shared/Processors/EEGProcessor.js";
import {EEGChart_graphedSamplesPerSecond} from "../../../Timeline/Sessions/SessionUI/DetailsUI/DetailChart.js";
import {currentMuse} from "../../../../UI/Tools/@Shared/MuseInterface.js";
import {EEGSample, EEGSample_interval, Muse_eegSamplesPerSecond_raw} from "../../../../UI/Tools/@Shared/MuseInterface/EEGStructs.js";
import {InAndroid, nativeBridge} from "../../../../Utils/Bridge/Bridge_Native.js";
import {OnStoreLoaded} from "../../../../Utils/General/GlobalHooks.js";
import {InfoButton, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {CreateRandFunc_Mulberry32, E, GetRandomNumber, Timer, WaitXThenRun} from "js-vextensions";
import {autorun, computed} from "mobx";
import {Button, CheckBox, Row, Text} from "react-vcomponents";
import {BaseComponentPlus, ShallowChanged} from "react-vextensions";
import {EEGChart_Live} from "./EEGPanel/EEGChart_Live.js";

export const EEGPanel_chartWidthInSeconds = 10;
export const EEGPanel_graphedSamplesPerChartWidth = EEGChart_graphedSamplesPerSecond * EEGPanel_chartWidthInSeconds;

let fakeEEGGenerator_rand: ()=>number;
OnStoreLoaded(()=>autorun(()=>{
	fakeEEGGenerator_rand = CreateRandFunc_Mulberry32(store.main.settings.fakeSampleGeneratorSeed);
}));
const maxForRandom = 100; // while eeg-values can range from -1000 to 1000, they trend toward low numbers, so do the same for generator
let lastFakeEEGSample: EEGSample;
const fakeEEGGenerator = new Timer(EEGSample_interval, ()=>{
	//console.log("TickCount:", fakeEEGGenerator.callCount_total);
	const sample = {
		time: lastFakeEEGSample ? lastFakeEEGSample.time + EEGSample_interval : Date.now().FloorTo(EEGSample_interval),
		left: GetRandomNumber({min: 0, max: maxForRandom, mustBeInteger: false, randFunc: fakeEEGGenerator_rand}),
		right: GetRandomNumber({min: 0, max: maxForRandom, mustBeInteger: false, randFunc: fakeEEGGenerator_rand}),
	} as EEGSample;
	for (const listener of currentMuse.eegListeners) {
		listener(sample);
	}
	lastFakeEEGSample = sample;
});

@Observer
export class EEGPanel_ControlUI extends BaseComponentPlus({}, {}) {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.monitor;
		return (
			<Row ml="auto">
				{store.main.settings.fakeSampleGeneratorSeed != -1 &&
					<CheckBox ml={10} text="Test" value={uiState.eeg_generateFake} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.eeg_generateFake = val);
						if (val) {
							fakeEEGGenerator.Start();
						} else {
							fakeEEGGenerator.Stop();
						}
					}}/>}
				{InAndroid(0) &&
					<Button ml={5} text="ModelTest" onClick={()=>{
						nativeBridge.Call("Muse_ModelTest");
					}}/>}
				<Text ml={10}>EEG:</Text>
				<CheckBox ml={5} text="Normalize" value={uiState.eeg_normalize} onChange={val=>RunInAction_Set(this, ()=>uiState.eeg_normalize = val)}/>
				<InfoButton ml={5} text="Normalization settings are borrowed from the engine config's eeg section."/>
				<CheckBox ml={5} text="Smooth" value={uiState.eeg_smooth} onChange={val=>RunInAction_Set(this, ()=>uiState.eeg_smooth = val)}/>
				<InfoButton ml={5} text="Smoothing settings are borrowed from the engine config's eeg section."/>
				{/*<Text ml={10}>Show:</Text>
				<CheckBox ml={5} text="Combined deviation" value={uiState.eeg_show_combinedDeviation} onChange={val=>RunInAction_Set(this, ()=>uiState.eeg_show_combinedDeviation = val)}/>
				<CheckBox ml={5} text="Trigger-sample %" value={uiState.eeg_show_triggerSamplePercent} onChange={val=>RunInAction_Set(this, ()=>uiState.eeg_show_triggerSamplePercent = val)}/>*/}
				{/*<Text ml={10}>Range:</Text>
				<Spinner ml={5} min={1} max={1000} value={uiState.eeg_range} onChange={val=>RunInAction_Set(this, ()=>uiState.eeg_range = val)}/>*/}
			</Row>
		);
	}
}

@Observer
export class EEGPanel extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.tools.monitor;
		const tracker1 = uiState.eegProcessor_changedAt;
		return (
			<EEGChart_Live processor={monitorEEGProcessor} viewRange={EEGPanel_chartWidthInSeconds}/>
		);
	}
}

export let monitorEEGProcessor = new EEGProcessor({}).Init_LiveSession();
// use a "reflector" class like this, when the @computed tag is only wanted for a specific variable/instance
class MonitorEEGProcessor_Reflector {
	@computed get options() {
		const uiState = store.main.tools.monitor;
		const engineConfig = GetSelectedFBAConfig();
		if (engineConfig == null) return null;
		return E(engineConfig.eeg, {
			samplesProcessedPerSecond: Muse_eegSamplesPerSecond_raw,
			/*calc_normalize: uiState.eeg_normalize,
			calc_smooth: uiState.eeg_smooth,*/
			calc_normalize: true, // always calc, so derivatives below match engine-runtime
			calc_smooth: true, // always calc, so derivatives below match engine-runtime
			calc_combinedDeviation: true,
			calc_triggerSamplePercent: true,

			uplotData_normalize: uiState.eeg_normalize,
			uplotData_smooth: uiState.eeg_smooth,

			clearOutOfRangeSamples: true,
		});
	}
}
export const monitorEEGProcessor_reflector = new MonitorEEGProcessor_Reflector();
OnStoreLoaded(()=>{
	autorun(()=>{
		const oldOptions = monitorEEGProcessor.options;
		const newOptions = monitorEEGProcessor_reflector.options;
		if (!ShallowChanged(oldOptions, newOptions)) return;

		//monitorEEGProcessor.options = newOptions;
		monitorEEGProcessor = new EEGProcessor({options: newOptions ?? undefined}).Init_LiveSession();
		monitorEEGProcessor.maxSampleDependencyDist = (monitorEEGProcessor.maxSampleDependencyDist ?? 0).KeepAtLeast(EEGPanel_graphedSamplesPerChartWidth);
		WaitXThenRun(0, ()=>RunInAction("recreate_monitorEEGProcessor", ()=>store.main.tools.monitor.eegProcessor_changedAt = Date.now()));
	});
});