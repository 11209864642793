import {AssertV, AssertValidate, Command, GenerateUUID} from "mobx-firelink";
import {Story} from "../../Store/firebase/stories.js";
import {UserEdit} from "../CommandMacros";

@UserEdit
export class AddStory extends Command<{story: Story}, string> {
	id: string;
	Validate() {
		const {story} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		story.creator = this.userInfo!.id;
		story.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("Story", story, `Story invalid`);
	}

	GetDBUpdates() {
		const {story} = this.payload;

		const updates = {};
		updates[`stories/${this.id}`] = story;
		return updates;
	}
}