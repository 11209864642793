import {TimelineEvent} from "../../Store/firebase/timelineEvents/@TimelineEvent";
import {AssertValidate, GenerateUUID, Command} from "mobx-firelink";

//@UserEdit
export class AddTimelineEvent extends Command<{event: TimelineEvent}, string> {
	eventID: string;
	Validate() {
		const {event} = this.payload;

		this.eventID = GenerateUUID();
		event.createdAt = Date.now();

		this.returnData = this.eventID;
		AssertValidate(`TimelineEvent`, event, `Event invalid`);
	}

	GetDBUpdates() {
		const {event} = this.payload;

		const updates = {};
		updates[`timelineEvents/${this.eventID}`] = event;
		//updates[`timelines/${this.userInfo.id}/events/${this.eventID}`] = true;
		return updates;
	}
}