// from: https://github.com/tort32/LightServer/blob/db57f45a934a15d6d133696ec3aa95d0b3842a36/src/main/java/com/github/tort32/api/nodemcu/protocol/RawColor.java
// ==========

/*export function RGB2HSB(r: number, g: number, b: number) {
	let c = new HSB();
	let cMax = Math.max(Math.max(r, g), b);
	if (cMax == 0) {
		c.s = 0;
		c.h = 0;
		return c;
	}
	let cMin = Math.min(Math.min(r, g), b);
	let delta = cMax - cMin;
	if (r == cMax) {
		c.h = toInt((g - b) * 60.0 / delta);
	} else if (g == cMax) {
		c.h = toInt((b - r) * 60.0 / delta) + 120;
	} else {
		c.h = toInt((r - g) * 60.0 / delta) + 240;
	}
	if (c.h < 0) {
		c.h += 360;
	}
	c.s = toInt(delta * 100.0 / cMax);
	c.b = toInt(cMax * 100.0 / RGB_MAX);
	return c;
}*/

class RGB {
	r: number;
	g: number;
	b: number;
}

//const toInt = Math.floor;
const toInt = (a: number)=>a;
const pow = (x: number, y: number)=> {
	if (x > 0) {
		 return Math.pow(x, y);
	}
	return -1 * Math.pow(-x, y);
}

const RGB_MAX = 255;
export function HSB2RGB(hue: number, sat: number, br: number) {
	let r, g, b;
	let t2 = (100.0 - sat) * br / 100.0;
	let t3 = (sat * br / 100.0) * ((hue % 60) / 60.0);
	let i = hue / 60;
	switch (i % 6) {
		default:
		case 0: r = br; b = t2; g = t2 + t3; break;
		case 1: g = br; b = t2; r = br - t3; break;
		case 2: g = br; r = t2; b = t2 + t3; break;
		case 3: b = br; r = t2; g = br - t3; break;
		case 4: b = br; g = t2; r = t2 + t3; break;
		case 5: r = br; g = t2; b = br - t3; break;
	}
	let c = new RGB();
	c.r = toInt(r * RGB_MAX / 100.0);
	c.g = toInt(g * RGB_MAX / 100.0);
	c.b = toInt(b * RGB_MAX / 100.0);
	 return c;
}

export function K2RGB(kelvin: number) {
	let c = new RGB();
	//if (kelvin < 6600) {
	// from console testing, "<=" appears better than "<" (ie. results in 6600 being truly neutral)
	if (kelvin <= 6600) {
		c.r = RGB_MAX;
		c.g = toInt(100.0 * Math.log(kelvin) - 620.0);
		c.b = toInt(200.0 * Math.log(kelvin) - 1500.0);
	} else {
		c.r = toInt(480.0 * pow(kelvin - 6000.0, -0.1));
		c.g = toInt(400.0 * pow(kelvin - 6000.0, -0.07));
		c.b = RGB_MAX;
	}
	c.r = Math.min(Math.max(c.r, 0), RGB_MAX);
	c.g = Math.min(Math.max(c.g, 0), RGB_MAX);
	c.b = Math.min(Math.max(c.b, 0), RGB_MAX);
	return c;
}

export function HSBK2RGB(hue: number, sat: number, br: number, k: number) {
	let c = HSB2RGB(hue, sat, br);
	let w = K2RGB(k);
	let a = sat / 100.0;
	let b = (1.0 - a) / RGB_MAX;
	// Color components weighted by saturation
	// Saturation = 100 is not impacted by color temperature
	c.r = toInt(c.r * (a + w.r * b));
	c.g = toInt(c.g * (a + w.g * b));
	c.b = toInt(c.b * (a + w.b * b));
	return c;
}