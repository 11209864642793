import {Button, Column, Row, Spinner, Text} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {store} from "../../Store/index.js";
import {GetSounds} from "../../Store/firebase/sounds";
import {EffectVisibility} from "../../Store/firebase/sounds/@Sound";
import {MeID} from "../../Store/firebase/users";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";
import {GetYoutubePlayersToKeepBuffered} from "../../Store/main/effects/sounds";
import {ES} from "../../Utils/UI/GlobalStyles";
import {Observer, PageContainer, RunInAction_Set} from "web-vcore";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel";
import {columnWidths, TagTargetEntryUI} from "../@Shared/TagTargetEntryUI";
import {ShowAddSoundDialog} from "./Sounds/SoundDetailsUI";

@Observer
export class SoundsUI extends BaseComponent<{}, {}> {
	render() {
		const sounds = GetSounds().OrderByDescending(a=>(a.creator == MeID() ? 1 : 0));
		const youtubePlayersToKeepBuffered = GetYoutubePlayersToKeepBuffered();

		return (
			<PageContainer style={{margin: "20px auto 20px auto", padding: 0}}>
				<Column className="clickThrough" style={{height: 80, borderRadius: "10px 10px 0 0"}}>
					<Row center style={{height: 40, padding: 10}}>
						<Text>Youtube players to keep buffered: </Text>
						<Spinner value={youtubePlayersToKeepBuffered} onChange={val=>RunInAction_Set(this, ()=>store.main.effects.sounds.youtubePlayersToKeepBuffered = val)} />
						<Button text="Add sound" ml="auto" onClick={()=>{
							if (MeID() == null) return ShowSignInPopup();
							ShowAddSoundDialog();
						}}/>
					</Row>
					<Row style={{height: 40, padding: 10}}>
						<Text style={{flex: columnWidths[0], fontWeight: 500, fontSize: 16}}>Name</Text>
						<Text style={{flex: columnWidths[1], fontWeight: 500, fontSize: 16}}>Type</Text>
						<Text style={{flex: columnWidths[2], fontWeight: 500, fontSize: 16}}>Creator</Text>
						<Text style={{flex: columnWidths[3], fontWeight: 500, fontSize: 16}}><Text>Tags (click tags to </Text><span style={{color: "rgba(100,200,100,.7)"}}>accept</span>)</Text>
						<Text style={{flex: columnWidths[4], fontWeight: 500, fontSize: 16}}></Text>
					</Row>
				</Column>
				<ScrollView style={ES({flex: 1})} contentStyle={ES({flex: 1})}>
					{sounds.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
					{sounds.map((sound, index)=>{
						return <TagTargetEntryUI key={index} group="sounds" entry={sound} index={index} last={index == sounds.length - 1}/>;
					})}
				</ScrollView>
			</PageContainer>
		);
	}
}