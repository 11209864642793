import {Assert} from "js-vextensions";
import {EEGPattern, FBAConfig_EEG} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_EEG";
import {EngineSessionInfo} from "../../../Store/firebase/sessions/@EngineSessionInfo";
import {EEGProcessingLevel_max, EEGProcessor} from "../../../UI/@Shared/Processors/EEGProcessor";
import {secondInMS} from "web-vcore";
import {EEGSample_interval, EEGSample} from "../../../UI/Tools/@Shared/MuseInterface/EEGStructs";
import {FinalizeSamplesForSecond, UpdateSamplesBySecondMap} from "../../../UI/Tools/@Shared/MuseInterface/SampleHelpers";
import {SessionDataProcessor} from "./SessionDataProcessor";
import {GyroMotionProcessor} from "./GyroMotionProcessor";

export function FillNullsWithX(array: number[], firstIndex: number, stepSize: number, value: number) {
	const stepSize_abs = Math.abs(stepSize);
	const stepSize_1 = stepSize > 1 ? 1 : -1;
	for (let i = firstIndex; i >= 0 && i < array.length; i += stepSize_1) {
		// reached end of null-region; return
		if (array[i] != null) return;

		const distFromFirstIndex = i.Distance(firstIndex);
		if (distFromFirstIndex % stepSize_abs == 0) {
			array[i] = value;
		}
	}
}

// this processing is extracted out, so it can be used both by EEGComp (for live data), and by SessionDataProcessor (for saved data)
// maybe todo: rename to EEGMotionProcessor
export class EEGActivityProcessor {
	constructor(opt: Partial<EEGActivityProcessor>) {
		this.VSet(opt);
	}
	parent: SessionDataProcessor;
	eegProcessor: EEGProcessor;
	gyroMotionProcessor: GyroMotionProcessor;
	postRespondToNewSample: (sample: EEGSample, index: number)=>void;
	postSamplesBySecondEntryCompleted: (secondStartTime: number, secondSamples: EEGSample[])=>void;
	postShapeEnd: (sampleTime: number, shape: EEGShape, matchedPattern: EEGPattern)=>void;
	postMotionTrigger: (sampleTime: number, shape: EEGShape, matchedPattern: EEGPattern)=>void;
	postEEGActivityChange: (eegActivity: number, sampleTime: number)=>void;

	get c(): FBAConfig_EEG {
		return this.eegProcessor.options;
	}

	eegActivityByTime = {} as {[key: number]: number}; // for chart (only used for saved sessions)
	alignedSamples_eegActivity = [] as number[]; // for chart (only used for saved sessions)

	Init_SavedSession(session: EngineSessionInfo, eegProcessor: EEGProcessor) {
		this.alignedSamples_eegActivity.length = eegProcessor.samples_left.length;
	}

	private _eegActivity = 0;
	get EEGActivity() { return this._eegActivity; }
	private SetEEGActivity(val: number, sampleTime: number) {
		const oldVal = this._eegActivity;
		if (val == oldVal) return;
		this._eegActivity = val;
		this.eegActivityByTime[sampleTime] = val;

		const alignedSampleIndex = this.eegProcessor.StaticArrays
			? this.eegProcessor.GetSampleIndexForTime(sampleTime, true)
			: this.eegProcessor.SampleCount - 1;

		const valLessThanExisting = val < this.alignedSamples_eegActivity[alignedSampleIndex];
		// if slot's existing val is higher, place new val into next slot instead (same as in SessionDataProcessor, for loaded sim)
		if (valLessThanExisting) {
			this.alignedSamples_eegActivity[alignedSampleIndex + 1] = val;
		} else {
			this.alignedSamples_eegActivity[alignedSampleIndex] = val;
		}

		// use data-driven stepped-mode (this causes spanGaps to span correctly, and stepped-mode paths-func is used to prevent glitch below when zoomed-out)
		if (this.alignedSamples_eegActivity[alignedSampleIndex - 1] == null) {
			//this.alignedSamples_eegActivity[alignedSampleIndex - 1] = oldVal;
			// apply to previous slot, for stepped-mode (and at every Xth slot prior over null region, to avoid uplot zoom-out optimizations from breaking stepped-mode)
			FillNullsWithX(this.alignedSamples_eegActivity, alignedSampleIndex - 1, -10, oldVal);
		}

		this.postEEGActivityChange?.(val, sampleTime);

		// if val just went over the "at" level, for reset system, perform reset
		if (val >= this.c.motion_activityReset_at && oldVal < this.c.motion_activityReset_at) {
			const sampleTimeForReset = sampleTime + 1; // MS reset-op doesn't take slot of source eeg-activity increase in EEGActivity.json (not the most elegant, but ok for now)
			//const sampleTimeForReset = sampleTime + .00001; // MS reset-op doesn't take slot of source eeg-activity increase in EEGActivity.json (not the most elegant, but ok for now)
			this.SetEEGActivity(this.c.motion_activityReset_to, sampleTimeForReset);
		}
	}

	//activityDecayTimer: Timer;
	activityDecayTimer_tick = (sampleTime: number)=>{
		// "max activity" not true/raw max; rather, decay reduces from it, as if max
		const eegActivity_capped = this.EEGActivity.KeepAtMost(this.c.motion_maxActivity);
		this.SetEEGActivity((eegActivity_capped - this.c.motion_activityDecayAmount).KeepAtLeast(0), sampleTime);
	};
	activityDecayTimer_lastTickTime: number;

	RespondToSample(index: number) {
		//const sample = this.eegProcessor.samples[index];
		//const sampleTime = sample.time_uplot * 1000;
		const sampleTime = this.eegProcessor.samples_time[index] * 1000;
		// temporarily recreate sample object, for storing in samplesBySecond, and for passing to postRespondToNewSample
		const sample = {
			time: this.eegProcessor.samples_time[index] * 1000,
			left: this.eegProcessor.samples_left[index],
			right: this.eegProcessor.samples_right[index],
			left_ear: this.eegProcessor.samples_left_ear?.[index],
			right_ear: this.eegProcessor.samples_right_ear?.[index],
		} as EEGSample;
		if (DEV) Assert(sample.time == sample.time.RoundTo(EEGSample_interval), "Sample does not align to the global interval!");

		//const motionTriggering = false;
		if (this.c.detectMotion) {
			// first apply any pending activity-decay (shown in UI under detection-motion group, so place under if statement as well)
			const activityDecayTimer_interval = this.c.motion_activityDecayInterval * secondInMS;
			while ((this.activityDecayTimer_lastTickTime ?? 0) + activityDecayTimer_interval <= sampleTime) {
				const tickSampleTime = this.activityDecayTimer_lastTickTime ? this.activityDecayTimer_lastTickTime + activityDecayTimer_interval : sampleTime;
				//this.activityDecayTimer_tick(tickSampleTime);
				this.activityDecayTimer_tick(sampleTime); // use sampleTime, not tickSampleTime (to make consistent with live-session, where gap in eeg-samples causes delay in activity-decay as well)
				this.activityDecayTimer_lastTickTime = tickSampleTime;
			}

			this.CheckForEyeMove(index);
		}

		// collect gyro-samples for each second, and record each second's samples to disk once it's over
		UpdateSamplesBySecondMap(this.samplesBySecond, sample, (secondStartTime, secondSamples)=>{
			secondSamples = FinalizeSamplesForSecond(secondStartTime, secondSamples, EEGSample_interval);
			this.postSamplesBySecondEntryCompleted?.(secondStartTime, secondSamples);
		});

		// also apply eeg-activity-capping, from the current gyro motion-trigger (if one exists, and setting is enabled)
		if (this.gyroMotionProcessor?.c.motion_resetEEGActivity_enabled && this.gyroMotionProcessor.HasMotionTriggerWithinPeriod(sampleTime - 1000, sampleTime)) {
			this.SetEEGActivity(this._eegActivity.KeepAtMost(this.gyroMotionProcessor.c.motion_resetEEGActivity_maxValue), sampleTime);
		}

		this.postRespondToNewSample?.(sample, index);
	}
	samplesBySecond = new Map<number, EEGSample[]>();
	currentShape: EEGShape|n;
	CheckForEyeMove(index: number) {
		/*const triggerSamplePercent_fraction = this.eegProcessor.samples_triggerSamplePercent[index] / 100; // sample's prop is as percentage, for display purposes
		motionTriggering = triggerSamplePercent_fraction >= this.c.motion_motionTrigger_minTriggerSamplePercent_absolute_value;
		if (motionTriggering) {
			const timeSinceLastMotionTrigger = sampleTime - this.lastMotionTriggerTime;
			// if required wait between motion-triggers has been fulfilled, activate motion-trigger
			if (timeSinceLastMotionTrigger >= this.c.motion_motionTrigger_maxTriggerRate * 1000) {
				this.NotifyMotionTrigger(sampleTime);
			}
		}*/

		const sample_time = this.eegProcessor.samples_time[index] * 1000;
		const sample_timeSinceLast = index == 0 ? 0 : sample_time - (this.eegProcessor.samples_time[index - 1] * 1000);
		// the sample can be unprocessed, if current eeg-shape was too long, extending past generous cache-maintain range (with cacheKeepSizeForEEGShapes of at least 10s)
		if (this.eegProcessor.samples_processingLevel[index] < EEGProcessingLevel_max) {
			this.eegProcessor.ProcessEEGSample(index);
		}
		const sample_cache = this.eegProcessor.samples_cache[index];
		const sample_inShape = sample_cache.smoothed[0] != sample_cache.smoothed[1];
		const sample_leftHigher = sample_cache.smoothed[0] > sample_cache.smoothed[1];
		// calculate dist-from-baseline
		const sample_closestEdgeDistToBaseline = Math.min(Math.abs(sample_cache.smoothed[0]), Math.abs(sample_cache.smoothed[1]));
		// baseline is within shape, if one edge is above the baseline, but the other is not (and both edges are non-zero/not-on-baseline)
		const sample_baselineIsWithinShape = ((sample_cache.smoothed[0] > 0) != (sample_cache.smoothed[1] > 0)) && sample_closestEdgeDistToBaseline != 0;
		// if baseline is within shape, set "dist from baseline" to a negative, marking how "deep" the baseline lies within the shape; else, set to distance between baseline and closest shape-edge
		const sample_distFromBaseline = sample_baselineIsWithinShape ? -sample_closestEdgeDistToBaseline : sample_closestEdgeDistToBaseline;

		if (this.currentShape != null) {
			const shape = this.currentShape;
			const shapeStillActive = sample_inShape && sample_leftHigher == this.currentShape.leftStartedHigher;
			if (shapeStillActive) {
				const heightAtSample = sample_cache.smoothed[0].Distance(sample_cache.smoothed[1]);
				shape.height = Math.max(shape.height, heightAtSample);
				shape.area += heightAtSample * (sample_timeSinceLast / 1000);
				shape.distFromBaseline = Math.min(shape.distFromBaseline, sample_distFromBaseline);
				//shape.farEdgeDist_max = Math.max(shape.farEdgeDist_max ?? 0, Math.max(Math.abs(sample_cache.smoothed[0]), Math.abs(sample_cache.smoothed[1])));
			} else {
				shape.endIndex = index;
				shape.width = (sample_time / 1000) - this.eegProcessor.samples_time[shape.startIndex]; // in seconds

				let matchingPattern: EEGPattern;
				for (const pattern of this.c.motion_eegPatterns) {
					if (pattern.width_min != null && shape.width < pattern.width_min) continue;
					if (pattern.width_max != null && shape.width > pattern.width_max) continue;
					if (pattern.height_min != null && shape.height < pattern.height_min) continue;
					if (pattern.height_max != null && shape.height > pattern.height_max) continue;
					if (pattern.area_min != null && shape.area < pattern.area_min) continue;
					if (pattern.area_max != null && shape.area > pattern.area_max) continue;
					if (pattern.maxDistFromBaseline != null && shape.distFromBaseline > pattern.maxDistFromBaseline) continue;
					//if (shape.farEdgeDist_max >= 30) continue;
					matchingPattern = pattern;
					break;
				}

				this.postShapeEnd?.(sample_time, shape, matchingPattern!);
				if (matchingPattern!) {
					this.NotifyMotionTrigger(sample_time, shape, matchingPattern);
				}
				this.currentShape = null;
			}
		}

		if (this.currentShape == null && sample_inShape) {
			this.currentShape = new EEGShape({
				startIndex: index,
				leftStartedHigher: sample_leftHigher,
				height: sample_cache.smoothed[0].Distance(sample_cache.smoothed[1]),
				area: sample_cache.smoothed[0].Distance(sample_cache.smoothed[1]),
				distFromBaseline: sample_distFromBaseline,
			});
		}
	}

	lastMotionTriggerTime = 0;
	NotifyMotionTrigger(sampleTime: number, shape: EEGShape, matchedPattern: EEGPattern) {
		//if (this.gyroMotionProcessor.IsEEGMotionTriggerDisabledFor(sampleTime)) return;
		if (this.gyroMotionProcessor) {
			const checkPeriod_start = sampleTime - (this.gyroMotionProcessor.c.motion_disableEEGMotion_duration * 1000);
			if (this.gyroMotionProcessor.c.motion_disableEEGMotion_enabled && this.gyroMotionProcessor.HasMotionTriggerWithinPeriod(checkPeriod_start, sampleTime)) return;
		}

		// don't cap value here, since "max activity" is not true/raw max; rather, decay reduces from it, as if max
		//this.eegActivity = (this.eegActivity + this.c.motion_motionTrigger_activityIncrease).KeepAtMost(this.c.motion_maxActivity);
		this.SetEEGActivity(this.EEGActivity + matchedPattern.activityIncrease, sampleTime);

		if (this.c.motion_activityDecay_motionTriggersInterrupt) {
			this.activityDecayTimer_lastTickTime = sampleTime; // equivalent to interrupting/resetting the decay timer
		}

		this.lastMotionTriggerTime = sampleTime;
		this.postMotionTrigger?.(sampleTime, shape, matchedPattern);
	}
}

export class EEGShape {
	constructor(data?: Partial<EEGShape>) {
		this.VSet(data);
	}
	startIndex: number;
	leftStartedHigher: boolean;
	endIndex: number;

	width: number;
	height: number;
	area: number;
	distFromBaseline: number;
}