import {JournalEntry} from "../../Store/firebase/journalEntries/@JournalEntry";
import {Command, AssertValidate, GenerateUUID, AssertV} from "mobx-firelink";

export class AddJournalEntry extends Command<{entry: JournalEntry}, string> {
	id: string;
	Validate() {
		const {entry} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		entry.creator = this.userInfo!.id;
		entry.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("JournalEntry", entry, "Journal-entry invalid");
	}

	GetDBUpdates() {
		const {entry} = this.payload;
		const updates = {};
		updates[`journalEntries/${this.id}`] = entry;
		return updates;
	}
}