import Moment from "moment";
import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";

export enum LogType {
	Action = 10,
	Event_Large = 20,
	Event_Small = 30,
	// these are only from console.log/warn and console.error
	App_Log = 40,
	App_Error = 50,
}
AddSchema("LogType", {oneOf: GetValues_ForSchema(LogType)});
export function GetLogTypeShorthand(logType: LogType) {
	const map = {[LogType.Action]: "A", [LogType.Event_Large]: "EL", [LogType.Event_Small]: "ES", [LogType.App_Log]: "AL", [LogType.App_Error]: "AE"};
	return map[logType];
}

export class LogEntry {
	static nonEnumerableFields = ["blockAutoCommit", "onCommit"] as const;
	constructor(initialData?: Partial<LogEntry>) {
		if (initialData) {
			this.VSet((initialData as any).ExcludeKeys(...LogEntry.nonEnumerableFields));
			for (const field of LogEntry.nonEnumerableFields) {
				if (initialData[field] != null) {
					this.VSet(field, initialData[field]); // store non-enumerably
				}
			}
		}
		this.createdAt = this.createdAt ?? Date.now();
	}
	type: LogType;
	createdAt: number;
	message: string;

	// special fields -- stored non-enumerably, so not serialized (and not causing schema reject)
	blockAutoCommit: boolean;
	onCommit: ()=>void;
	Commit() {
		if (this.onCommit) {
			this.onCommit();
			this.VSet("onCommit", null);
		}
	}
	//eegActivity: number;
	//triggerSamplePercent_peak: number;

	toString(previousLogEntry: LogEntry|n = null) {
		/*if (this.type == LogType.App_Log && this.message.startsWith("[SessionLog; no session] ")) {
			return this.message;
		}*/

		let dateStr = Moment(this.createdAt).format("YYYY-MM-DD");
		const timeStr = Moment(this.createdAt).format("HH:mm:ss");
		if (previousLogEntry && Moment(previousLogEntry.createdAt).format("YYYY-MM-DD") == dateStr) {
			dateStr = "";
		}
		const headerStr = `[${dateStr ? `${dateStr} ` : ""}${timeStr}, ${GetLogTypeShorthand(this.type)}] `;
		return `${headerStr}${this.message}`;
	}
}
AddSchema("LogEntry", {
	properties: {
		type: {$ref: "LogType"},
		createdAt: {type: "number"},
		message: {type: "string"},
	},
});

/*export function ConvertSessionLogEntriesIntoString(sessions: FBASession[], logTypesToShow: LogType[], allowTimestampTrim = true) {
	let result = "";
	for (const session of sessions) {
		if (result.length) result += "\n\n\n\n";
		const typeStr = {FBASession_Local: "fba local", FBASession_Remote: "fba remote"}.Pairs().find(a=>session.constructor.name == a.key).value;
		//result += `========== Start of session (${Moment(session.startTime).format("YYYY-MM-DD HH:mm:ss")}) ==========\n`;
		result += `========== Start of session (${typeStr}) ==========\n`;
		const matchingLogEntries = session.logEntries.filter(a=>logTypesToShow.Contains(a.type));
		result += matchingLogEntries.map((entry, index)=>entry.toString(matchingLogEntries[index - 1])).join("\n");
	}
	return result;
}*/