import {CachedTransform} from "js-vextensions";
import {Activity} from "../../Store/firebase/activities/@Activity";
import {GetDoc, StoreAccessor, GetDocs, WhereOp} from "mobx-firelink";

export function GetActivity(id: string|n): Activity|n {
	return GetDoc({}, a=>a.activities.get(id!));
}
export const GetActivities = StoreAccessor(s=>(userID: string|n): Activity[]=>{
	return GetDocs({
		queryOps: [new WhereOp("creator", "==", userID)],
	}, a=>a.activities);
});