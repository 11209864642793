import {GenerateUUID} from "mobx-firelink";
import {Script} from "../../Store/firebase/scripts/@Script.js";
import {liveFBASession} from "../../Engine/FBASession.js";
import {PlayScriptConfig_Backend, StopScriptConfig_Backend} from "../../Utils/Bridge/Bridge_Preload.js";
import {LogWarning} from "web-vcore";
import {PlayState, PlayStateListener} from "./SoundPlayer.js";
import {EffectPlayExtras, EffectPlayer} from "./EffectPlayer.js";

// needed for Refresh button on Scripts page
export const activeScriptPlayers = new Set<ScriptPlayer>();

//export let lastActiveScriptPlayer: ScriptPlayer;
export class ScriptPlayer extends EffectPlayer {
	constructor(script: Script|n = null) {
		super();
		this.script = script;
		this.stateListeners.push(state=>{
			if (state == "playing") activeScriptPlayers.add(this);
			else activeScriptPlayers.delete(this);
		});
	}

	private script_: Script|n;
	get script() { return this.script_; }
	set script(val: Script|n) {
		if (val == this.script_) return;
		this.Stop();
		this.script_ = val;
	}

	_playState: PlayState = "stopped"; // flag is not necessarily real-world accurate (effect may be overridden by other code)
	get playState() { return this._playState; }
	set playState(val: PlayState) { this._playState = val; this.stateListeners.forEach(a=>a(val, ()=>this.stateListeners.Remove(a))); }
	stateListeners = [] as PlayStateListener[];
	//WaitTillState(waitForStates: PlayState[], allowExistingTrigger = false) {
	WaitTillState(...waitForStates: PlayState[]) {
		return new Promise<void>((resolve, reject)=>{
			//if (allowExistingTrigger && waitForStates.includes(this.playState)) return resolve();
			this.stateListeners.push((state, removeListener)=>{
				if (waitForStates.includes(state)) {
					resolve();
					removeListener();
				}
			});
		});
	}

	lastPlayIndex = -1;
	async Play(intensity = 1, extras?: Partial<EffectPlayExtras>) {
		const {} = new EffectPlayExtras(extras);
		if (this.script == null) return; //void LogWarning("Failed to play script, since it is null.");
		if (this.playState == "playing") this.Stop();
		const playIndex = ++this.lastPlayIndex;
		this.playState = "playing";
		//lastActiveScriptPlayer = this;

		PlayScriptConfig(this.script, intensity).then(()=>{
			// if still same play-index (ie. another play call didn't occur while waiting), mark playing as false
			if (this.lastPlayIndex == playIndex) {
				this.playState = "stopped";
			}
		});

		await this.WaitTillState("stopped");
	}
	Stop() {
		if (this.playState == "stopped") return;
		this.playState = "stopped";
		StopScriptConfig(this.script!);
	}
}

export const fakeSessionID_forNoActiveSession = GenerateUUID();
export function GetCurrentSessionID_ForSessionScriptContext() {
	return liveFBASession?.id ?? fakeSessionID_forNoActiveSession;
}
export async function PlayScriptConfig(script: Script, intensity: number) {
	await PlayScriptConfig_Backend({
		sessionID: GetCurrentSessionID_ForSessionScriptContext(),
		scriptID: script._key,
		intensity,
	});
}
export function StopScriptConfig(script: Script) {
	StopScriptConfig_Backend({
		sessionID: GetCurrentSessionID_ForSessionScriptContext(),
		scriptID: script._key,
	});
}