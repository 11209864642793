import {Assert, AssertWarn} from "js-vextensions";
import {SamplesBySecond} from "../../../../Store/firebase/testSegments/@TestSegment";
import {EEGProcessor} from "../../../../UI/@Shared/Processors/EEGProcessor";
import {EEGSample, EEGSample_interval, Muse_eegSamplesPerSecond_raw} from "./EEGStructs";
import {GyroSample, GyroSample_interval, Muse_gyroSamplesPerSecond_raw} from "./GyroStructs";

/*
Warning
==========
The functions below rely on timestamp+interval addition.
The sample-intervals for eeg (3.90625ms) and gyro (19.53125ms) are small enough, however, that the addition results in rounding.

Right now, this is okay, since we obtain sample-times through the same mechanism for all of the codepaths dealing with them:
1) Live-sim: MuseInterface.clientXXXSubscription (FloorTo, followed by additions)
2) Re-sim: SampleHelpers.RecreateXXXSamplesInSecond (CeilingTo, followed by additions)
3) Record/Assert: SampleHelpers.FinalizeSamplesForSecond (CeilingTo, followed by additions)
4) Re-sim for tests: TestSegmentUI.useProcessor (additions from bufferStartTime field only; okay, since doesn't need 100% accuracy to orig)

However, it is prone to future mistakes; so be very careful making changes!
*/

export function UpdateSamplesBySecondMap<T extends EEGSample | GyroSample>(
	samplesBySecondMap: Map<number, T[]>,
	newSample: T,
	postSamplesBySecondEntryCompleted: (secondStartTime: number, secondSamples: T[])=>void,
	deleteSecondEntryOnceCompleted = true,
) {
	// collect eeg-samples for each second, and record each second's samples to disk once it's over
	const currentSecond = newSample.time.FloorTo(1000);
	const startOfNewSecond = !samplesBySecondMap.has(currentSecond);
	if (startOfNewSecond) {
		for (const previousSecondTime of samplesBySecondMap.keys()) {
			const samplesForPreviousSecond = samplesBySecondMap.get(previousSecondTime)!;
			postSamplesBySecondEntryCompleted?.(previousSecondTime, samplesForPreviousSecond);
			if (deleteSecondEntryOnceCompleted) {
				samplesBySecondMap.delete(previousSecondTime); // previous second completed, so clear to save memory
			}
		}
		samplesBySecondMap.set(currentSecond, []);
	}
	const previousSample = samplesBySecondMap.get(currentSecond)!.LastOrX();
	Assert(previousSample == null || newSample.time > previousSample.time, ()=>`New sample's time (${newSample.time}) must be higher than previous sample's (${previousSample?.time ?? "n/a"})!`);
	samplesBySecondMap.get(currentSecond)!.push(newSample);
}
export function FinalizeSamplesForSecond<T extends GyroSample | EEGSample>(secondTime: number, secondArray: T[], interval: number) {
	Assert(secondArray.length <= 256, ()=>`There were more samples in second (${secondArray.length}) than should be possible (max 256)!`);
	const entriesLeft = secondArray.slice();
	const result = [] as T[];
	for (let time = secondTime.CeilingTo(interval); time < secondTime + 1000; time += interval) {
		const matchingEntryIndex = entriesLeft.findIndex(a=>a.time == time);
		result.push(entriesLeft[matchingEntryIndex]);
		if (matchingEntryIndex != -1) {
			entriesLeft.RemoveAt(matchingEntryIndex);
		}
	}
	Assert(entriesLeft.length == 0, ()=>{
		const sampleTimesAndOffsets = entriesLeft.map(sample=>{
			const closestSlot = sample.time.RoundTo(interval);
			return `${sample.time} (closest: ${closestSlot}, diff: ${sample.time - closestSlot})`;
		});
		return `There samples in second that did not align to the global interval! @SecondTime:${secondTime} @Interval:${interval} @SampleTimesAndOffsets:${sampleTimesAndOffsets.join(",")}`;
	});
	return result;
}

export function RecreateEEGSamplesInSecond(leftSamplesBySecond: SamplesBySecond, rightSamplesBySecond: SamplesBySecond, secondTime: number) {
	const result = [] as EEGSample[];

	// sample-times aligned to "EEGSample_interval" step-size (see FinalizeSamplesForSecond); thus, we can deterministically infer sample-time just from sample's index in its samplesBySecond group
	let nextSampleTime = secondTime.CeilingTo(EEGSample_interval);

	let sampleCountInSecond = leftSamplesBySecond[secondTime].length;

	// fix for when loading old sessions, with varying samples in each second
	// todo: someday remove this graceful handling (once not helpful anymore), and switch back to a hard assert, to catch future errors more easily
	if (sampleCountInSecond > Muse_eegSamplesPerSecond_raw) {
		/*if (DEV) {
			Assert(false, ()=>`Samples in second (${sampleCountInSecond}) exceeded the max (${Muse_eegSamplesPerSecond_raw}).`);
		} else {*/
		//AssertNotify(false, ()=>`Samples in second (${sampleCountInSecond}) exceeded the max (${Muse_eegSamplesPerSecond_raw}). Ignoring additional samples...`);
		AssertWarn(false, ()=>`Samples in second (${sampleCountInSecond}) exceeded the max (${Muse_eegSamplesPerSecond_raw}). Ignoring additional samples...`);
		sampleCountInSecond = Muse_eegSamplesPerSecond_raw;
	}

	for (let i = 0; i < sampleCountInSecond; i++) {
		const time = nextSampleTime;
		const sample: EEGSample = {
			time,
			left: leftSamplesBySecond[secondTime][i],
			right: rightSamplesBySecond[secondTime][i],
		};
		// if sample-data is null, it means a sample didn't actually occur here, so don't pass-on (null-entry just signifies time passing)
		//if (sample.left != null) {
		if (sample.left != Number.MIN_SAFE_INTEGER) {
			result.push(sample);
		}

		nextSampleTime += EEGSample_interval;
	}

	return result;
}

export function RecreateGyroSamplesInSecond(xSamplesBySecond: SamplesBySecond, ySamplesBySecond: SamplesBySecond, zSamplesBySecond: SamplesBySecond, secondTime: number, eegProcessor: EEGProcessor) {
	const result = [] as GyroSample[];

	// sample-times aligned to "EEGSample_interval" step-size (see FinalizeSamplesForSecond); thus, we can deterministically infer sample-time just from sample's index in its samplesBySecond group
	let nextSampleTime = secondTime.CeilingTo(GyroSample_interval);

	const typicalEEGSamplesPerGyroSample = Math.floor(Muse_eegSamplesPerSecond_raw / Muse_gyroSamplesPerSecond_raw);
	let lastSample_alignedSampleIndex = -1;

	const sampleCountInSecond = xSamplesBySecond[secondTime].length;
	for (let i = 0; i < sampleCountInSecond; i++) {
		const time = nextSampleTime;

		const time_uplot = time / 1000;
		//const time_uplot_aligned = eegProcessor.FindSampleIndexForGyroSampleTime(time_uplot_raw);
		//const alignedSampleIndex = eegProcessor.GetSampleIndexForTime(time_uplot_raw * 1000, true);
		let alignedSampleIndex: number;
		if (lastSample_alignedSampleIndex == -1) {
			alignedSampleIndex = eegProcessor.GetSampleIndexForTime(time, true);
			Assert(alignedSampleIndex != null, `Could not find aligned-sample-index for gyro sample at time ${time}.`);
		} else {
			alignedSampleIndex = lastSample_alignedSampleIndex + typicalEEGSamplesPerGyroSample;
			while (eegProcessor.samples_time[alignedSampleIndex] < time_uplot) alignedSampleIndex++; // increase aligned-sample till its time exceeds/equals ours
			while (eegProcessor.samples_time[alignedSampleIndex] > time_uplot) alignedSampleIndex--; // decrease aligned-sample till its time precedes/equals ours
			//alignedSampleIndex = alignedSampleIndex.KeepBetween(0, eegProcessor.SampleCount - 1);
			if (!alignedSampleIndex.IsBetween(0, eegProcessor.SampleCount - 1)) alignedSampleIndex = -1;
		}
		// if no aligned-sample-index found, this can just mean we have a few gyro samples before the first eeg-sample; just ignore these (later might want to include)
		//if (alignedSampleIndex == -1) continue;

		//const time_uplot_aligned = eegProcessor.samples_time[alignedSampleIndex];
		//Assert(time_uplot_aligned != null, `Could not find time_uplot_aligned for Gyro sample at time:${time}`);

		const sample = {
			time,
			alignedIndex: alignedSampleIndex,
			//time_uplot_aligned,
			x: xSamplesBySecond[secondTime][i],
			y: ySamplesBySecond[secondTime][i],
			z: zSamplesBySecond[secondTime][i],
			processingLevel: 0,
		} as GyroSample;
		// if sample-data is null, it means a sample didn't actually occur here, so don't pass-on (null-entry just signifies time passing)
		//if (sample.x != null) {
		if (sample.x != Number.MIN_SAFE_INTEGER) {
			result.push(sample);
		}

		lastSample_alignedSampleIndex = alignedSampleIndex;
		nextSampleTime += GyroSample_interval;
	}

	return result;
}