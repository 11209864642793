import {Assert, IsNaN} from "js-vextensions";
import moment from "moment";
import {useMemo, useEffect, useState} from "react";
import {UPlot} from "react-uplot";
import {BaseComponentPlus, SimpleShouldUpdate} from "react-vextensions";
import {EEGProcessor} from "../../../../../UI/@Shared/Processors/EEGProcessor";
import {currentMuse} from "../../../../../UI/Tools/@Shared/MuseInterface";
import uPlot from "uplot";
import useResizeObserver from "use-resize-observer";
import {InAndroid} from "../../../../../Utils/Bridge/Bridge_Native";
import {createRef_withHooks} from "../../../../../Utils/UI/ReactHelpers";
import {HSL, Observer} from "web-vcore";
import {uplotDefaults} from "../../../../../Utils/UI/UPlotDefaults";
import {monitorEEGProcessor_reflector} from "../EEGPanel";
import {Muse_eegSamplesPerSecond_raw} from "../../../../../UI/Tools/@Shared/MuseInterface/EEGStructs";
import {sessionChartColors} from "../../../../../UI/Timeline/Sessions/SessionUI/SessionChartColors";

export const EEGChart_live_percentOfSamplesGraphed = InAndroid(0) ? .1 : 1;
export const EEGChart_live_graphedSamplesPerSecond = Muse_eegSamplesPerSecond_raw * EEGChart_live_percentOfSamplesGraphed;

const lineTypes: (uPlot.Series & {key: string})[] = [
	{
		value: (self, rawValue)=>{
			/*const timeInMS = rawValue * 1000;
			return `${moment(timeInMS).format("YYYY-MM-DD HH:mm:ss")}.${(timeInMS % 1000).FloorTo(1).toString().padStart(3, "0")}`;*/
			return `${(rawValue / EEGChart_live_graphedSamplesPerSecond).toFixed(1)}s`;
		},
	} as any,
	{
		label: "Left",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_left,
		points: {show: false},
	},
	{
		label: "Right",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_right,
		points: {show: false},
	},
	{
		label: "Left_Ear",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_left_ear,
		points: {show: false},
	},
	{
		label: "Right_Ear",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.eeg_right_ear,
		points: {show: false},
	},
];

//@SimpleShouldUpdate
@Observer
export class EEGChart_Live extends BaseComponentPlus({} as {
	processor: EEGProcessor,
	viewRange: number,
}, {}) {
	render() {
		const {processor, viewRange} = this.props;
		//const {ref: rootRef, width = 100, height = 100} = useResizeObserver();
		const {callbackRef: rootRef, width = 300, height = 100} = useResizeObserver();

		const legendHeight = 33; // from dev-tools
		const chartOptions: uPlot.Options = useMemo(()=>{
			return {
				id: `chart_live`,
				class: "eeg-chart",
				width,
				height: height - legendHeight,
				cursor: {
					drag: {x: false, setScale: false},
				},
				axes: [
					{
						label: "Time",
						...uplotDefaults.axes_props,
						size: 50,
						values: (self, vals)=>vals.map(a=>`${(a / EEGChart_live_graphedSamplesPerSecond).toFixed(1)}s`),
					},
					{
						label: "Value",
						...uplotDefaults.axes_props,
						size: 50,
					},
				],
				scales: {
					x: {
						time: false,
						range(u, dataMin, dataMax): [number, number] {
							return [-10 * EEGChart_live_graphedSamplesPerSecond, 0];
						},
					},
					y: {
						auto: false,
						//min: -100, max: 100,
						range: (u, dataMin, dataMax)=>{ // use func, since stable
							return [-100, 100] as [number, number];
						},
					},
				},
				series: lineTypes,
			};
		}, [width, height, viewRange]);
		//if (monitorEEGProcessor.options == null) return null; // wait
		//if (monitorEEGProcessor_reflector?.options == null) return null; // wait

		/*if (processor.samples_uplotData == null) {
			processor.ResetData();
		}*/
		const data = null; // start data as null; populate using UpdateChart()

		return (
			<div ref={rootRef as any} style={{position: "relative", width: "100%", height: "calc(100% - 18px)"}}>
				<UPlot chartRef={this.chart} options={chartOptions} data={data as any}/>
			</div>
		);
	}
	chart = createRef_withHooks<uPlot>({
		postSet: chart=>{
			if (chart == null) return;
			const uplotDiv = chart.root as HTMLDivElement;
			//const doubleClickStartDiv = uplotDiv.querySelector(".u-over") as HTMLDivElement;
			// prevent uplot's double-click-to-reset-zoom behavior
			uplotDiv.addEventListener("dblclick", e=>{
				e.stopPropagation();
			}, {capture: true});

			this.UpdateChart();
		},
	})

	ComponentDidMount() {
		currentMuse.eegListeners.push(this.eegListener);
		//this.StartEngineConfigMirrorer();
		//this.UpdateChart(); // show existing data stored in processor (if any) // commented; moved to chart-ref.postSet above (this handler's too early)
	}
	ComponentDidUpdate() {
		this.UpdateChart();
	}
	ComponentWillUnmount() {
		currentMuse.eegListeners.Remove(this.eegListener);
	}
	sampleCountAtLastChartUpdate = 0;
	eegListener = sample=>{
		const {processor, viewRange} = this.props;
		//if (monitorEEGProcessor_reflector?.options == null) return; // wait
		if (processor.options == null) return; // wait

		processor.AddAndProcessEEGSample(sample);

		// every .05 seconds, update chart
		if (processor.SampleCount - this.sampleCountAtLastChartUpdate >= EEGChart_live_graphedSamplesPerSecond * .05) {
			this.UpdateChart(); //newXScale);
			this.sampleCountAtLastChartUpdate = processor.SampleCount;
		}
	};

	UpdateChart(newXScale?) {
		const chart = this.chart.current;
		if (chart == null) return;
		const {processor} = this.props;
		//console.log("UpdateChart.chart:", xScale_old, yScale_old);

		//const data = this.props.processor.samples_uplotData;
		const recentDataLength = processor.samples_time.length.KeepAtMost(10 * EEGChart_live_graphedSamplesPerSecond);
		const recentData = [
			processor.samples_time.slice(-recentDataLength).map((a, i)=>-i.Distance(recentDataLength - 1)),
			processor.samples_left_display.slice(-recentDataLength),
			processor.samples_right_display.slice(-recentDataLength),
			processor.samples_left_ear_display.slice(-recentDataLength),
			processor.samples_right_ear_display.slice(-recentDataLength),
		];

		const xScale_old = {min: chart.scales.x.min, max: chart.scales.x.max};
		//const yScale_old = {min: chart.scales.y.min, max: chart.scales.y.max};
		//chart.setData(data, false);
		chart.setData(recentData as any, false);
		//chart.setData(data, true);
		//chart.redraw(true);

		// calling setScale triggers a chart redraw
		chart.batch(()=>{
			Assert([xScale_old.min, xScale_old.max].every(a=>!IsNaN(a)), "Found NaN in new scale values. (EEGChart.tsx)");
			chart.setScale("x", newXScale ?? xScale_old);
			//chart.setScale("y", yScale_old);
		});
	}
}