import {E} from "js-vextensions";
import {Column, DropDown, DropDownContent, DropDownTrigger, Pre, Row, Button, ButtonProps, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {ES} from "../../../Utils/UI/GlobalStyles";
import {Observer} from "web-vcore";
import {GetFBAConfigs} from "../../../Store/firebase/fbaConfigs";
import moment from "moment";
import {zIndexes} from "../../../Utils/UI/ZIndexes";
import {GetUsers} from "../../../Store/firebase/users";

@Observer
export class EngineConfigPicker extends BaseComponentPlus({} as {value: string, onChange: (value: string)=>any, containerStyle: any} & ButtonProps, {}) {
	dropDown: DropDown|n;
	render() {
		const {value, onChange, containerStyle, style, ...rest} = this.props;
		const configs = GetFBAConfigs().OrderByDescending(a=>a.editedAt);
		const users = GetUsers();
		const selectedConfig = configs.find(a=>a._key == value);
		return (
			<DropDown ref={c=>this.dropDown = c} style={E({position: "relative", flex: 1}, containerStyle)}>
				<DropDownTrigger>
					<Button {...rest} style={E({display: null}, style)} text={selectedConfig?.name ?? "(click to select an engine config)"}/>
				</DropDownTrigger>
				<DropDownContent style={E(
					{padding: null, background: null, borderRadius: null, zIndex: zIndexes.dropDown},
					selectedConfig && {left: 0},
					!selectedConfig && {position: "fixed"}, // Why this? If no config selected, scroll-container's very short, so we need to break out of it.
				)}>
					<Row style={{alignItems: "flex-start"}}>
						<Column style={{width: 880}}>
							<ScrollView style={ES({flex: 1})} contentStyle={{position: "relative", maxHeight: 500}}>
								{configs.map((config, index)=>{
									const creator = users.find(a=>a._key == config.creator);
									return (
										<Row center key={index} p="5px 10px"
												style={E(
													{
														cursor: "pointer",
														background: index % 2 == 0 ? "rgba(30,30,30,1)" : "rgba(0,0,0,1)",
													},
													index == configs.length - 1 && {borderRadius: "0 0 10px 10px"},
												)}
												onClick={()=>{
													onChange(config._key!);
													this.dropDown!.Hide();
												}}>
											<Text style={{flex: .4}}>{config.name}</Text>
											<Column style={{flex: .6}}>
												<span style={{marginLeft: 5, fontSize: 11}}>Edited: {moment(config.editedAt).format("YYYY-MM-DD HH:mm:ss")}, id: {config._key}</span>
												<span style={{marginLeft: 5, fontSize: 11}}>Creator: {creator?.displayName ?? "n/a"} ({config.creator})</span>
											</Column>
										</Row>
									);
								})}
							</ScrollView>
						</Column>
					</Row>
				</DropDownContent>
			</DropDown>
		);
	}
}