import {EffectPointerPlayer} from "../../../../Store/firebase/@Shared/EffectPointerPlayer.js";
import {AlarmSequence} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {FBAConfig_SoundAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@EC_BasicAlarms.js";
import {nativeBridge} from "../../../../Utils/Bridge/Bridge_Native.js";
import {FBASession} from "../../../FBASession";
import {AlarmComp} from "./@AlarmComp";

export class SoundAlarmComp extends AlarmComp<FBAConfig_SoundAlarm> {
	constructor(session: FBASession, sequence: AlarmSequence, config: FBAConfig_SoundAlarm) {
		super(session, sequence, config);
	}

	promptPlayer = new EffectPointerPlayer();

	override OnStart() {
		this.promptPlayer.effectPointer = this.c.effectPointer;
		this.promptPlayer.OnStart();
	}
	override OnStop() {
		this.promptPlayer.Stop();
		return super.OnStop();
	}

	override async PlayAlarmEffect() {
		await this.promptPlayer.Play(this.intensity);
	}
	override UpdatePrompt_ForReducedIntensity() {
		this.promptPlayer.NotifyReducedIntensity(this.intensity);
	}
	override StopAlarm(...args: Parameters<AlarmComp<any>["StopAlarm"]>) {
		/*if (this.c.controlBackgroundAudio) {
			this.s.Comp(BackgroundComp).backgroundSoundPlayer.SetLiveVolume(this.s.Comp(BackgroundComp).volume); // background and prompt players are the same, in this case, so just reset volume
			return;
		}*/
		this.promptPlayer.Stop();
		return super.StopAlarm(...args);
	}
}