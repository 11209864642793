import {InfoButton, Observer, TextPlus} from "web-vcore";
import React from "react";
import {CheckBox, Column, Row, RowLR, Spinner, Text, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.journeyGrid, (c, v)=>c.journeyGrid = v);

const splitAt = 150;

@Observer
export class JourneyGrid_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Journey -> GRID Mode" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
					<InfoButton ml={5} text={`GRID stands for the "Guided Reminder of Imminent Dreaming" technique.`}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Listen start:</Text>
						<ControlInput enabled={enabled} value={config.listenStart_triggerSet} onChange={val=>ChangeConfig(c=>c.listenStart_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Listen end:</Text>
						<ControlInput enabled={enabled} value={config.listenEnd_triggerSet} onChange={val=>ChangeConfig(c=>c.listenEnd_triggerSet = val)}/>
					</RowLR>

					<RowLR mt={5} splitAt={splitAt}>
						<Text>Entity tags (regular):</Text>
						<TextInput enabled={enabled} style={{width: "100%"}}
							value={(config.entityTags_regular || []).join(", ")} onChange={val=>ChangeConfig(c=>c.entityTags_regular = val.split(",").map(a=>a.trim()).filter(a=>a))}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Entity tags (target):</Text>
						<TextInput enabled={enabled} style={{width: "100%"}}
							value={(config.entityTags_target || []).join(", ")} onChange={val=>ChangeConfig(c=>c.entityTags_target = val.split(",").map(a=>a.trim()).filter(a=>a))}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Entity tags (success):</Text>
						<TextInput enabled={enabled} style={{width: "100%"}}
							value={(config.entityTags_success || []).join(", ")} onChange={val=>ChangeConfig(c=>c.entityTags_success = val.split(",").map(a=>a.trim()).filter(a=>a))}/>
					</RowLR>

					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Voice, tag:</Text>
						<TextInput enabled={enabled} style={{width: 80}} value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>
						<Text ml={5} mr={5}>Vol. mult.:</Text>
						<Spinner min={0} step={0.01} enabled={enabled} value={config.volumeMultiplier} onChange={val=>ChangeConfig(c=>c.volumeMultiplier = val)}/>
					</RowLR>
					<RowLR mt={10} splitAt={splitAt}>
						<Text>Speak interval:</Text>
						<Spinner min={0} enabled={enabled} value={config.speakInterval} onChange={val=>ChangeConfig(c=>c.speakInterval = val)}/>
					</RowLR>

					<RowLR mt={5} splitAt={splitAt}>
						<Text>Target delay, min time:</Text>
						<Spinner min={0} enabled={enabled} value={config.targetDelay_minTime} onChange={val=>ChangeConfig(c=>c.targetDelay_minTime = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Target delay, max time:</Text>
						<Spinner min={0} enabled={enabled} value={config.targetDelay_maxTime} onChange={val=>ChangeConfig(c=>c.targetDelay_maxTime = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>User target detection, max delay:</Text>
						<Spinner min={0} enabled={enabled} value={config.userTargetDetection_maxDelay} onChange={val=>ChangeConfig(c=>c.userTargetDetection_maxDelay = val)}/>
					</RowLR>
				</>}
			</Column>
		);
	}
}