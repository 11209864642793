import React from "react";
import {CheckBox, Column, Row, RowLR, Spinner, Text, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Observer, TextPlus} from "web-vcore";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {EffectPointerInput} from "../../../@Shared/EffectPointerInput.js";
import {ExtendFBASessionPanelSharedProps, FBASessionPanels_SharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.realityCheck, (c, v)=>c.realityCheck = v);

const splitAt = 150;

@Observer
export class RealityCheck_ConfigUI extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Reality Check" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Signal RC:</Text>
						<ControlInput enabled={enabled} value={config.signalRC_triggerSet} onChange={val=>ChangeConfig(c=>c.signalRC_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Signal RC, effect:</Text>
						<EffectPointerInput enabled={enabled} value={config.signalRC_effect} onChange={val=>ChangeConfig(c=>c.signalRC_effect = val)}/>
					</RowLR>

					<RowLR mt={5} splitAt={splitAt}>
						<Text>Effect, delay:</Text>
						<Text>Min:</Text>
						<TimeSpanInput ml={5} enabled={enabled} value={config.reminder_minDelay} onChange={val=>ChangeConfig(c=>c.reminder_minDelay = val)}/>
						<Text ml={5}>Max:</Text>
						<TimeSpanInput ml={5} enabled={enabled} value={config.reminder_maxDelay} onChange={val=>ChangeConfig(c=>c.reminder_maxDelay = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							Ambient noise is calculated based on the average amplitude of the audio signal over the last 10 seconds.
							Note: Use settings->transcribe->test panel (range:10s, smoothing:10s) to observe ambient noise level.
						`.AsMultiline(0)}>Effect, max ambient noise:</TextPlus>
						<Spinner enabled={enabled} step="any"
							value={(config.reminder_maxAmbientNoise * 100).RoundTo(.01)}
							onChange={val=>ChangeConfig(c=>c.reminder_maxAmbientNoise = val / 100)} min={0}/>
						<Text>%</Text>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Effect:</Text>
						<EffectPointerInput enabled={enabled} value={config.reminder_effect} onChange={val=>ChangeConfig(c=>c.reminder_effect = val)}/>
					</RowLR>
				</>}
			</Column>
		);
	}
}