import {E, emptyArray} from "js-vextensions";
import moment from "moment";
import {Button, Column, Div, Grid, Row, Text} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {GetJournalEntries} from "../../Store/firebase/journalEntries.js";
import {JournalEntry, JournalSegment} from "../../Store/firebase/journalEntries/@JournalEntry.js";
import {GetUser, Me, MeID} from "../../Store/firebase/users.js";
import {GetSelectedJournalEntry, JournalDisplayMode} from "../../Store/main/journal.js";
import {Link, PageContainer, Observer, RunInAction} from "web-vcore";
import {ES} from "../../Utils/UI/GlobalStyles.js";
import {User} from "../../Store/firebase/users/@User.js";
import {GetUserPermissionGroups} from "../../Store/firebase/users/$user.js";
import {runInAction} from "mobx";
import {store} from "../../Store/index.js";
import {AddJournalEntry} from "../../Server/Commands/AddJournalEntry.js";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel.js";
import {JournalEntryUI} from "./JournalEntryUI.js";
import {useExpandedNavBar} from "../@Shared/NavBar.js";
import {EntryGrid} from "./EntryGrid.js";
import {EntryList} from "./EntryList.js";

//type JournalTab = "list" | "entry";

@Observer
export class JournalUI extends BaseComponentPlus({} as {user: User}, {}) {
	render() {
		const {user} = this.props;
		const uiState = store.main.journal;
		//if (user == null) return <PageContainer>You must be signed in to access this page.</PageContainer>;
		const permissions = GetUserPermissionGroups(MeID());
		const entries: JournalEntry[] = user ? GetJournalEntries(user._key, "desc") : emptyArray;
		const selectedEntry = GetSelectedJournalEntry();

		if (selectedEntry) {
			return <PageContainer preset="full" style={{margin: 0, padding: 0, height: "100%", background: "none"}}>
				<JournalEntryUI entry={selectedEntry}/>
			</PageContainer>;
		}

		/*const largeVersion = useExpandedNavBar();
		if (!largeVersion) {*/
		return (
			<PageContainer preset="full" style={{margin: 0, padding: 0, height: "100%", background: "none"}}>
				{uiState.displayMode == JournalDisplayMode.list && <EntryList entries={entries}/>}
				{uiState.displayMode == JournalDisplayMode.grid && <EntryGrid entries={entries}/>}
			</PageContainer>
		);

		/*return (
			<PageContainer style={{flex: 1, flexDirection: "row", margin: 20, padding: 0, height: "100%", background: "none"}}>
				<EntriesListComp {...{entries, selectedEntry}}/>
				<Column ml={10} style={ES({flex: 70})}>
					{selectedEntry && <JournalEntryUI entry={selectedEntry}/>}
				</Column>
			</PageContainer>
		);*/
	}
}