import {Clone, GetEntries, VRect} from "js-vextensions";
import {Button, Column, Row, RowLR, Select, Spinner, Text, TextInput, TimeSpanInput, CheckBox} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {CameraConfig, CameraType, StreamFindType, ChannelsType_options, TransportProtocol_values, FFMpegLogLevel_values} from "../../../../Store/main/tools/@CameraConfig";
import {InfoButton, RunInAction_Set, Observer, TextPlus} from "web-vcore";
import {store} from "../../../../Store/index.js";

@Observer
export class CameraConfigUI extends BaseComponentPlus({} as {
	enabled: boolean,
	config: CameraConfig,
	onChange: (val: CameraConfig)=>void,
}, {}) {
	render() {
		const {enabled, config: config_base, onChange} = this.props;
		const uiState = store.main.tools.monitor;
		const config = Clone(config_base) as CameraConfig;
		const Change = (..._)=>{
			onChange(config);
			this.Update();
		};

		const splitAt = 160;
		return (
			<Column>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Type:</Text>
						<InfoButton ml={5} text={`
							Webcam: Camera plugged into your device's usb port.
							IPCamera: Camera that runs independently, with a video stream accessible through your home network.${""/*

							Note that streaming from webcams (vs ip-cams) currently uses browser-based image-decoding, which has a memory leak and thus may cause crashes.*/}
						`.AsMultiline(0)}/>
					</Row>
					<Select options={GetEntries(CameraType, "ui")} enabled={enabled} value={config.type} onChange={val=>Change(config.type = val)}/>
				</RowLR>
				{config.type == CameraType.IPCamera &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Stream-find type:</Text>
						<Select options={GetEntries(StreamFindType)} enabled={enabled} value={config.streamFindType} onChange={val=>Change(config.streamFindType = val)}/>
					</RowLR>
					{config.streamFindType == StreamFindType.SetDevice_FindStream &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Device URI:</Text>
						<TextInput enabled={enabled} style={{width: 500}} value={config.deviceURI} onChange={val=>Change(config.deviceURI = val)}/>
					</RowLR>}
					{config.streamFindType == StreamFindType.SetStream &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Stream URI:</Text>
						<TextInput enabled={enabled} style={{width: 500}} value={config.streamURI} onChange={val=>Change(config.streamURI = val)}/>
					</RowLR>}
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Username:</Text>
						<TextInput enabled={enabled} value={config.username} onChange={val=>Change(config.username = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Password:</Text>
						{/*<TextInput enabled={enabled} value={config.password} onChange={val=>Change(config.password = val)}/>*/}
						<input type="password" disabled={!enabled} value={config.password} onChange={e=>Change(config.password = e.target.value)}/>
					</RowLR>
				</>}
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Resolution:</Text>
						<InfoButton ml={5} text={`
							The resolution of the buffer into which the camera frames will be rendered, with scaling-down-to-fit (while keeping aspect ratio).

							Matching the camera's native resolution will give the most detail, but it's not required. (you can downsize to improve performance)
						`.AsMultiline(0)}/>
					</Row>
					<Spinner enabled={enabled} min={1} value={config.resolution_width} onChange={val=>Change(config.resolution_width = val)}/>
					<Text ml={5}>by</Text>
					<Spinner ml={5} enabled={enabled} min={1} value={config.resolution_height} onChange={val=>Change(config.resolution_height = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Framerate:</Text>
						<InfoButton ml={5} text="Matching the camera's native framerate will give the most detail, but it's not required. (you can reduce it to improve performance)"/>
					</Row>
					<Spinner enabled={enabled} value={config.framerate} onChange={val=>Change(config.framerate = val)}/>
				</RowLR>
				<Row mt={5}>
					<CheckBox text="Advanced settings" value={uiState.cam_showAdvanced} onChange={val=>RunInAction_Set(this, ()=>uiState.cam_showAdvanced = val)}/>
				</Row>
				{uiState.cam_showAdvanced &&
				<Column ml={15}>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Channels:</Text>
							<InfoButton ml={5} text={`
								Which of the Red, Green, and Blue channels to process for motion-detection, etc.

								Set to a single channel (any) if using a night-vision/grayscale camera, or if you need the slight performance boost it gives.
							`.AsMultiline(0)}/>
						</Row>
						<Select enabled={enabled} options={ChannelsType_options} value={config.channels} onChange={val=>Change(config.channels = val)}/>
					</RowLR>
					{/*<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Keep-alive:</Text>
							<InfoButton ml={5} text={`
								How long to keep the camera's video stream open after usage ceases.

								(Some cameras require a "cool off" period between streams; this setting enables continuation/reuse of the previous stream, avoiding downtime.)
							`.AsMultiline(0)}/>
						</Row>
						<TimeSpanInput enabled={enabled} value={config.keepAlive} onChange={val=>Change(config.keepAlive = val)}/>
					</RowLR>*/}
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Freeze-detect time:</Text>
							<InfoButton ml={5} text={`
								How long to wait (with the ip-camera stream not returning new frames), before considering the stream frozen/broken and preparing reconnection.

								(Note that reconnection attempts are only made during dream-engine sessions -- not while just previewing on this Monitor page.)
							`.AsMultiline(0)}/>
						</Row>
						<TimeSpanInput enabled={enabled} value={config.freezeDetectTime} onChange={val=>Change(config.freezeDetectTime = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Reconnect delay:</Text>
							<InfoButton ml={5} text={`When preparing reconnection, how long to shut down the camera-stream for, prior to starting a new one up. (some ip-cams reject quick reconnects)`}/>
						</Row>
						<TimeSpanInput enabled={enabled} value={config.reconnectDelay} onChange={val=>Change(config.reconnectDelay = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Transport protocol:</Text>
						<Select options={TransportProtocol_values} enabled={enabled} value={config.transportProtocol} onChange={val=>Change(config.transportProtocol = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Log level:</Text>
							<InfoButton ml={5} text="See here for log-level meanings: https://ffmpeg.org/ffmpeg.html"/>
						</Row>
						<Select options={FFMpegLogLevel_values} enabled={enabled} value={config.logLevel} onChange={val=>Change(config.logLevel = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<CheckBox text="FFMpeg override" enabled={enabled} value={config.ffmpegOverride_enabled} onChange={val=>Change(config.ffmpegOverride_enabled = val)}/>
						<TextInput enabled={enabled} style={{flex: 1}} value={config.ffmpegOverride_command} onChange={val=>Change(config.ffmpegOverride_command = val)}/>
						{/*<Button text="Reset" onClick={()=>{ // todo: add this
							Change(config.ffmpegOverride_command =...)
						}}/>*/}
					</RowLR>
				</Column>}

				<Row center mt={5}>
					<Text style={{fontWeight: "bold"}}>Ignore rects:</Text>
					<InfoButton ml={5} text={`
						Ignore-rects specify areas of the camera frames for which all changes should be ignored. (eg. timestamps)
					
						Note: All four components are based on progression-percent from left->right or top->bottom.
					`.AsMultiline(0)}/>
					<Button ml={5} p="3px 7px" text="+" enabled={enabled} onClick={()=>{
						if (config.ignoreRects == null) config.ignoreRects = [];
						config.ignoreRects.push(new VRect(0, 0, 0, 0));
						Change();
					}}/>
				</Row>
				{config.ignoreRects.map((rect_raw, index)=>{
					//const rect = new VRect(rect_raw.x, rect_raw.y, rect_raw.width, rect_raw.height);
					const rect = rect_raw.Cast(VRect);
					const ChangeRect = (rectChanger: (r: VRect)=>void)=>{
						//const newRect = new VRect(rect.x, rect.y, rect.width, rect.height);
						const newRect = rect.Clone();
						rectChanger(newRect);
						Change(config.ignoreRects[index] = newRect);
					};
					return (
						<Row key={index}>
							<Text>{index + 1}) Left:</Text>
							<Spinner ml={5} /*enabled={enabled}*/ min={0} max={100} enforceRange={true} instant style={{width: 50}}
								value={rect.Left.ToPercent(.1)} onChange={val=>ChangeRect(r=>r.Left = val.FromPercent())}/>
							<Text ml={3}>% Right:</Text>
							<Spinner ml={5} /*enabled={enabled}*/ min={0} max={100} enforceRange={true} instant style={{width: 50}}
								validator={val=>val.FromPercent() >= rect.Left}
								value={rect.Right.ToPercent(.1)} onChange={val=>ChangeRect(r=>r.Right = val.FromPercent())}/>
							<Text ml={3}>% Top:</Text>
							<Spinner ml={5} /*enabled={enabled}*/ min={0} max={100} enforceRange={true} instant style={{width: 50}}
								value={rect.Top.ToPercent(.1)} onChange={val=>ChangeRect(r=>r.Top = val.FromPercent())}/>
							<Text ml={3}>% Bottom:</Text>
							<Spinner ml={5} /*enabled={enabled}*/ min={0} max={100} enforceRange={true} instant style={{width: 50}}
								validator={val=>val.FromPercent() >= rect.Top}
								value={rect.Bottom.ToPercent(.1)} onChange={val=>ChangeRect(r=>r.Bottom = val.FromPercent())}/>
							<Text ml={3}>%</Text>
							<Button ml={5} text="X" enabled={enabled} style={{padding: "3px 5px"}} onClick={()=>{
								ShowMessageBox({
									title: `Delete ignore-rect #${index + 1}?`,
									message: "Delete this ignore-rect?", cancelButton: true,
									onOK: ()=>{
										Change(config.ignoreRects.Remove(rect));
									},
								});
							}}/>
						</Row>
					);
				})}
			</Column>
		);
	}
}