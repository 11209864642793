import {makeObservable} from "mobx";
import {AddSchema} from "mobx-firelink";
import {Assert} from "js-vextensions";
import {O} from "web-vcore";
import {SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import {LogEntry} from "../../../UI/Tools/@Shared/LogEntry.js";
import {LaunchType} from "../fbaConfigs/@EngineConfig/@EC_ModeSwitcher.js";
import {FBAConfig} from "../fbaConfigs/@FBAConfig.js";
import {Setting, SettingValue} from "../fbaConfigs/@EngineConfig/@Shared/Setting.js";
import _ from "lodash";

/** Core session-data, segmented into a separate struct; if session crashes, this data gets restored during the session restart. */
export class SessionCoreData {
	constructor() { makeObservable(this); }
	@O startTime: number;
	@O launchType: LaunchType;
	@O events = [] as SessionEvent[]; // todo: make this the only place events are stored (right now it's just a copy of FBASession.events)
	//compStates: any[];
	@O journey_alarmDelay: number; // in ms
	@O settingValuesSelected: {[key: string]: SettingValue<any>} = {};
}

// todo: probably just place the SessionCoreData struct above *within* the EngineSessionInfo below, in the future (delayed, since requires db mutations)

// session data that is stored permanently
export class EngineSessionInfo {
	constructor(initialData?: Partial<EngineSessionInfo>) { this.VSet(initialData); }

	//_key: string;
	/** Whether session has locally-saved entry. */
	_local?: boolean;
	/** If session has locally-saved entry, this is the folder name the data is stored under. */
	_folderName?: string; // note: in-progress sessions have the equivalent "FBASession.folderName"
	/** Whether session has web-saved entry. */
	_online?: boolean;

	id: string; // todo: remove this, and switch to _key (with local sessions just storing "_key" prop in file)
	creator: string;
	configID: string;
	config: FBAConfig;

	// from SessionCoreData
	startTime: number;
	launchType: LaunchType;
	events: SessionEvent[];
	/** Only set if the alarm-delay is locked to a single value in the config. */
	alarmDelay?: number; // in ms
	settingValuesSelected: {[key: string]: any};

	/** How many minutes you'd need to subtract from the local-time (of the session-creator), for it to match the utc-time. (when session started) */
	localOffsetFromUTC: number;
	endTime: number;
	//week: string; // eg. "2020-08-02" (sunday where week started)
	week: number; // time at start of utc-week (in which session started)

	logEntries: LogEntry[];
}
AddSchema("EngineSessionInfo", {
	properties: {
		id: {type: "string"},
		creator: {type: "string"},
		configID: {type: "string"},
		config: {$ref: "FBAConfig"},

		// from SessionCoreData
		startTime: {type: "number"},
		launchType: {$ref: "LaunchType"},
		alarmDelay: {type: ["null", "number"]},
		settingValuesSelected: {patternProperties: {"^[a-zA-Z0-9_.]+$": {}}},

		localOffsetFromUTC: {type: "number"},
		endTime: {type: "number"},
		week: {type: "number"},

		events: {items: {$ref: "SessionEvent"}},
		logEntries: {items: {$ref: "LogEntry"}},
	},
});

export function GetSettingValueFromEngineSessionInfo<T>(session: EngineSessionInfo, settingGetter: (config: FBAConfig)=>Setting<T>, defaultValue: T): T {
	const settingName = GetSettingNameFromGetter(settingGetter);
	return session.settingValuesSelected && settingName in session.settingValuesSelected
		? session.settingValuesSelected[settingName]
		: defaultValue;
}
export function GetSettingNameFromGetter<T>(settingGetter: (config: FBAConfig)=>Setting<T>) {
	const settingName = settingGetter.toString().match(/\.([a-zA-Z0-9_.]+)/)?.[1];
	Assert(settingName, `Failed to extract setting-name from settingGetter.toString(): ${settingGetter.toString()}`);
	return settingName;
}