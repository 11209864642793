import {Button, CheckBox, Column, Row, Spinner, Text, TextInput} from "react-vcomponents";
import {store} from "../../../Store/index.js";
import {ES} from "../../../Utils/UI/GlobalStyles";
import {Observer, RunInAction_Set} from "web-vcore";
import {BaseComponent} from "react-vextensions";
import React from "react";
import {ScrollView} from "react-vscrollview";

@Observer
export class LD1SettingsPanel extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.monitor.ld1;
		
		return (
			<Column p={5}>
				<Row>
					<Text>Device addresses:</Text>
					<Button ml={5} text="Add" onClick={()=> {
						RunInAction_Set(this, ()=>uiState.deviceAddresses.push("00:00:00:00:00:00"));
					}}/>
				</Row>
				<ScrollView>
					{uiState.deviceAddresses.map((address, index)=> {
						return (
							<Row key={index} style={ES({flex: 1, padding: 5})}>
								<TextInput style={ES({flex: 1})} value={address} onChange={val=>{
									RunInAction_Set(this, ()=>uiState.deviceAddresses[index] = val);
								}}/>
								<CheckBox ml={5} text="Active" value={uiState.deviceAddresses_activeIndex == index} onChange={val=>{
									RunInAction_Set(this, ()=>uiState.deviceAddresses_activeIndex = val ? index : -1);
								}}/>
								<Button ml={5} text="X" onClick={()=> {
									RunInAction_Set(this, ()=>uiState.deviceAddresses.RemoveAt(index));
								}}/>
							</Row>
						);
					})}
				</ScrollView>
			</Column>
		);
	}
}