import {AddSchema, NewSchema, GetSchemaJSON, AssertValidate, Command} from "mobx-firelink";
import {GetTimelineEvent} from "../../Store/firebase/timelineEvents";
import {TimelineEvent} from "../../Store/firebase/timelineEvents/@TimelineEvent";
import {UserEdit} from "../CommandMacros";

AddSchema("UpdateTimelineEvent_payload", ["TimelineEvent"], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON("TimelineEvent").properties!.IncludeKeys("startTime", "endTime", "activity", "sessionInfo"),
		}),
	},
	required: ["id", "updates"],
}));

@UserEdit
export class UpdateTimelineEvent extends Command<{id: string, updates: Partial<TimelineEvent>}> {
	oldData: TimelineEvent;
	newData: TimelineEvent;
	Validate() {
		AssertValidate("UpdateTimelineEvent_payload", this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = GetTimelineEvent.NN(id);
		this.newData = {...this.oldData, ...updates};
		AssertValidate("TimelineEvent", this.newData, `New event-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`timelineEvents/${id}`] = this.newData;
		return updates;
	}
}