import {O} from "web-vcore";
import {makeObservable} from "mobx";
import {LightsState} from "./effects/lights";
import {SoundsState} from "./effects/sounds";

export class EffectsState {
	constructor() { makeObservable(this); }
	@O subpage = "sounds";
	@O sounds = new SoundsState();
	@O lights = new LightsState();
}