import {E, GetEntries} from "js-vextensions";
import {Row, Select} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {store} from "../../Store/index.js";
import {MonitorTab} from "../../Store/main/tools/monitor";
import {useExpandedNavBar} from "../../UI/@Shared/NavBar";
import {ES} from "../../Utils/UI/GlobalStyles";
import {Observer, PageContainer, RunInAction_Set} from "web-vcore";
import {MusePanel} from "./Monitor/MusePanel";
import {CameraPanel_Monitor} from "./Monitor/CameraPanel";
import {GyroPanel} from "./Monitor/MusePanel/GyroPanel";
import {LD1Panel} from "./Monitor/LD1Panel";
import React from "react";
import {LD1SettingsPanel} from "./Monitor/LD1SettingsPanel.js";
import {LD1ConfigPanel} from "./Monitor/LD1ConfigPanel.js";

// ui
// ==========

@Observer
export class MonitorUI extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.monitor;
		const largeVersion = useExpandedNavBar();

		return (
			<PageContainer preset="text" style={E(
				{padding: 0},
				uiState.tab == MonitorTab.Muse && {height: "100%"},
				uiState.tab == MonitorTab.LD1 && {height: "100%"},
				uiState.tab == MonitorTab.LD1Config && {height: "100%"},
				!largeVersion && {margin: "0 auto 0 auto"},
			)}>
				<Row>
					<Select displayType="button bar" options={GetEntries(MonitorTab)} value={uiState.tab} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.tab = val);
					}}/>
				</Row>
				{uiState.tab == MonitorTab.Camera && <CameraPanel_Monitor/>}
				{uiState.tab == MonitorTab.Muse && <MusePanel/>}
				{uiState.tab == MonitorTab.LD1 && <LD1Panel/>}
				{uiState.tab == MonitorTab.LD1Config && <LD1ConfigPanel/>}
				{uiState.tab == MonitorTab.LD1Settings && <LD1SettingsPanel/>}
			</PageContainer>
		);
	}
}