import {Switch} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {store} from "../Store/index.js";
import {Observer, SubNavBar_Auto} from "web-vcore";
import {AboutUI} from "./Home/About";
import {HomeUI2} from "./Home/Home";

@Observer
export class HomeUI extends BaseComponent<{}, {}> {
	render() {
		const currentSubpage = store.main.home.subpage;
		const page = "home";
		return (
			<>
				<SubNavBar_Auto page={page}/>
				<Switch>
					<HomeUI2/>
					{currentSubpage == "about" && <AboutUI/>}
				</Switch>
			</>
		);
	}
}