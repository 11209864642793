import {AddSchema, NewSchema, GetSchemaJSON, AssertValidate, Command} from "mobx-firelink";
import {GetEntity} from "../../Store/firebase/entities";
import {Entity} from "../../Store/firebase/entities/@Entity";

type MainType = Entity;
const MTName = "Entity";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.IncludeKeys(
				"name", "visibleToGroups", "tags",
				"url", "image_embedded", "square_center", "square_size",
			),
		}),
	},
	required: ["id", "updates"],
}));

export class UpdateEntity extends Command<{id: string, updates: Partial<MainType>}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = GetEntity.NN(id);
		this.newData = {...this.oldData, ...updates} as MainType;
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`entities/${id}`] = this.newData;
		return updates;
	}
}