import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export class FBAConfig_StoryExplore {
	constructor(initialData: Partial<FBAConfig_StoryExplore>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	prevText_triggerSet: TriggerSet;
	nextText_triggerSet: TriggerSet;
	selectText_triggerSet: TriggerSet;
	stopVoice_triggerSet: TriggerSet;
	newStory_triggerSet: TriggerSet;

	voiceSoundTag: string;
	volumeMultiplier: number;
	
	minNumberedListSize: number;
	dynamicListNumbers: number[];
	llmHistory_keepLastXNumberedLists: number;
	temperature: number;
	startPrompt: string;
}
AddSchema("FBAConfig_StoryExplore", {
	properties: {
		enabled: {type: "boolean"},

		prevText_triggerSet: {$ref: "TriggerSet"},
		nextText_triggerSet: {$ref: "TriggerSet"},
		selectText_triggerSet: {$ref: "TriggerSet"},
		stopVoice_triggerSet: {$ref: "TriggerSet"},
		newStory_triggerSet: {$ref: "TriggerSet"},

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},

		minNumberedListSize: {type: "number"},
		dynamicListNumbers: {items: {type: "number"}},
		llmHistory_keepLastXNumberedLists: {type: "number"},
		temperature: {type: "number"},
		startPrompt: {type: "string"},
	},
});