import {Column, Row, RowLR, Spinner, Text, TextInput, Select, CheckBox, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput";
import {InfoButton} from "web-vcore";
import {FBAConfig_GetDefault} from "../../../../Store/firebase/fbaConfigs/@FBAConfig_Presets";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.camera, (c, v)=>c.camera = v);

const splitAt = 250;
export class CameraPanel extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;
		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Camera" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					{/*<Row mt={5}>
						<CheckBox text="Use as trigger" enabled={enabled} value={config.webcam.enabled} onChange={val=>ChangeConfig(c=>c.webcam.enabled = val)}/>
					</Row>
					<Row mt={5}>
						<CheckBox text="Use for recording" enabled={enabled} value={config.webcam.enabled} onChange={val=>ChangeConfig(c=>c.webcam.enabled = val)}/>
					</Row>*/}
					{/*<Row mt={5} style={{fontWeight: "bold"}}>Recording:</Row>*/}
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Device:</Text>
						<Text style={{opacity: .7}}>(For now, uses the settings on Monitor page)</Text>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Pre-motion buffer:</Text>
							<InfoButton ml={5} text="How long to record, prior to a motion starting. (buffer is extended to make this possible)"/>
						</Row>
						<TimeSpanInput enabled={enabled} largeUnit="minute" smallUnit="second"
							value={config.preMotionBuffer} onChange={val=>ChangeConfig(c=>c.preMotionBuffer = val)}/>
						<Text ml={5}>(actual range: {config.preMotionBuffer}s-{config.preMotionBuffer * 2}s)</Text>
						<InfoButton ml={5} text="For technical reasons, the pre-motion buffer-length varies between 1x and 2x of the buffer-length specified."/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Post-motion buffer:</Text>
							<InfoButton ml={5} text="How long to record, after motion stops (ie. drops below target sensitivity)."/>
						</Row>
						<TimeSpanInput enabled={enabled} largeUnit="minute" smallUnit="second"
							value={config.postMotionBuffer} onChange={val=>ChangeConfig(c=>c.postMotionBuffer = val)}/>
					</RowLR>
					{/*<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Min video length:</Text>
							<InfoButton ml={5} text={`
								Minimum length of a recorded video-segment. (used to bridge gaps in cam-motion, avoiding large sequences of tiny recordings)
							`.AsMultiline(0)}/>
						</Row>
						<TimeSpanInput enabled={enabled} largeUnit="minute" smallUnit="second"
							value={config.minVideoLength} onChange={val=>ChangeConfig(c=>c.minVideoLength = val)}/>
					</RowLR>*/}
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Max video length:</Text>
							<InfoButton ml={5} text={`
								Maximum length of a recorded video-segment. If motion is still triggering when a segment ends, a new (slightly overlapping) segment will continue its role.
								
								(This just breaks the capture into pieces, to keep one video from growing too long, eg. consuming too much memory, risking more data-loss if power fails, etc.)
							`.AsMultiline(0)}/>
						</Row>
						<TimeSpanInput enabled={enabled} largeUnit="minute" smallUnit="second"
							value={config.maxVideoLength} onChange={val=>ChangeConfig(c=>c.maxVideoLength = val)}/>
					</RowLR>

					<Row mt={5}>
						<CheckBox text="Record periods of camera motion" enabled={enabled} value={config.camMotion_record} onChange={val=>ChangeConfig(c=>c.camMotion_record = val)}/>
					</Row>
					{config.camMotion_record &&
					<Column ml={15}>
						<Row mt={5}>
							<Text style={{fontWeight: "bold"}}>Pixel trigger (level 1):</Text>
						</Row>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Min value delta:</Text>
								<InfoButton ml={5} text={`How much the average of the R,G,B color-values of a pixel must differ from the previous frame, for a pixel to "trigger".`}/>
							</Row>
							<Spinner enabled={enabled} value={config.camMotion_pixelTrigger_minValueDelta} onChange={val=>ChangeConfig(c=>c.camMotion_pixelTrigger_minValueDelta = val)}/>
							<Text ml={5}>(range: 0-255)</Text>
						</RowLR>

						<Row mt={5}>
							<Text style={{fontWeight: "bold"}}>Frame trigger (level 2):</Text>
						</Row>
						{/*<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Min trigger-pixel chain-length:</Text>
								<InfoButton ml={5} text={`
									How long of a chain of triggered-pixels must be found, for a frame to "trigger". (one of the two conditions)
									${""
									/*Chain-length is defined as the diameter of the smallest circle which encompasses the given set of interconnected triggered-pixels.*#/}
									Chain-length is defined as the length of the smallest rectangle which encompasses the given set of interconnected triggered-pixels.
								`.AsMultiline(0)}/>
							</Row>
							<Spinner enabled={enabled} value={config.recordMotion_frameTrigger_minTriggerPixelChainLength} onChange={val=>ChangeConfig(c=>c.recordMotion_frameTrigger_minTriggerPixelChainLength = val)}/>
						</RowLR>*/}
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Min trigger-pixel count:</Text>
								<InfoButton ml={5} text={`How many triggered-pixels must be found (anywhere in the frame, other than the ignore-rects), for a frame to "trigger".`}/>{/*(one of the two conditions)`}/>*/}
							</Row>
							<Spinner enabled={enabled} style={{width: 100}}
								value={config.camMotion_frameTrigger_minTriggerPixelCount} onChange={val=>ChangeConfig(c=>c.camMotion_frameTrigger_minTriggerPixelCount = val)}/>
						</RowLR>

						<Row mt={5}>
							<Text style={{fontWeight: "bold"}}>Motion trigger (level 3):</Text>
						</Row>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Min trigger-frame percent:</Text>
								<InfoButton ml={5} text={`The percentage of frames within the time-window which must be triggered-frames, for motion to be detected/"triggered".`}/>
							</Row>
							<Spinner enabled={enabled}
								value={config.camMotion_motionTrigger_minTriggerFramePercent.ToPercent(.01)} onChange={val=>ChangeConfig(c=>c.camMotion_motionTrigger_minTriggerFramePercent = val.FromPercent())}/>
							<Text> %</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text ml={15}>Window duration:</Text>
								<InfoButton ml={5} text={`
									The size of the time-window, in which to search for triggered-frames. (see setting directly above)

									Set to 0 if you want a single frame (matching the conditions) to count as a motion trigger/detection.
								`.AsMultiline(0)}/>
							</Row>
							<TimeSpanInput enabled={enabled}
								value={config.camMotion_motionTrigger_windowDuration} onChange={val=>ChangeConfig(c=>c.camMotion_motionTrigger_windowDuration = val)}/>
						</RowLR>
						</Column>}

					<Row mt={5}>
						<CheckBox text="Record periods of eeg motion" enabled={enabled} value={config.eegMotion_record} onChange={val=>ChangeConfig(c=>c.eegMotion_record = val)}/>
					</Row>
					{config.camMotion_record &&
					<Column ml={15}>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text>Min eeg-activity:</Text>
								<InfoButton ml={5} text={`Min eeg-activity for recording to be activated/sustained.`}/>
							</Row>
							<Spinner enabled={enabled} value={config.eegMotion_minActivity} onChange={val=>ChangeConfig(c=>c.eegMotion_minActivity = val)}/>
						</RowLR>
					</Column>}
				</>}
			</Column>
		);
	}
}