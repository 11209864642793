import {DefaultSkin} from "web-vcore";

//export const liveSkin = new DefaultSkin();

export class LFSkin extends DefaultSkin {
	/*override MainFont() {
		// add Symbola to font-family list (see Main.scss for font-face declaration), to ensure that some handy unicode characters (eg. play/pause) are available regardless of device's available fonts
		return super.MainFont() + ", Symbola";
	};*/
	override MainFont() {
		// todo: read this list from DefaultSkin.MainFont_List, once published
		const fonts = [
			"Quicksand",
			"Symbola",
			"Roboto", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif",
			"Segoe UI Emoji", "Noto Color Emoji", "Android Emoji", "EmojiSymbols", "EmojiOne Mozilla", "TweMoji Mozilla", "Segoe UI Symbol",
		];
		// atm, exclude Symbola; we want the emojis to fallback to the device's colorful emojis, rather than use Symbola's colorless ones
		return fonts.Exclude("Symbola").join(", ");
	}
}
export const liveSkin = new LFSkin();