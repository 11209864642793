import {SleepAsync} from "js-vextensions";
import {store} from "../../Store";
import {remoteUserProcessesBridge, processID} from "./Bridge_RemoteUserProcesses";
import {InDesktop} from "./Bridge_Native";
import {GetLocalIPAddresses} from "./Bridge_Preload";

export function GetOwnInstanceInfo() {
	return {
		processID,
		ipAddress: InDesktop() ? GetLocalIPAddresses()[0] : null, // in Start_WebviewPreload.ts of lf-desktop
		port: 3502, // always on 3502, for the moment
		allowClientConnections: store.main.tools.engine.allowClientConnections,
	} as AppInstanceInfo;
}
remoteUserProcessesBridge.RegisterFunction("BroadcastInstanceInformation", ()=>{
	//if (!store.main.tools.engine.allowConnectionOfRemotes) return new Promise(()=>{}); // return a promise that never resolves (response would not be useful)
	//if (!store.main.tools.engine.allowClientConnections) return;

	const instanceInfo = GetOwnInstanceInfo();
	remoteUserProcessesBridge.Call("CollectInstanceInformation", instanceInfo);
});

export class AppInstanceInfo {
	processID: number;
	ipAddress: string|n; // don't worry; only shared to instances where user is signed in
	port: number;
	allowClientConnections: boolean;
}
export class AppInstanceInfoHolder {
	instanceInfos: AppInstanceInfo[] = [];
}

var currentInstanceInfoHolder: AppInstanceInfoHolder|n = new AppInstanceInfoHolder();
remoteUserProcessesBridge.RegisterFunction("CollectInstanceInformation", instanceInfo=>{
	if (currentInstanceInfoHolder && currentInstanceInfoHolder.instanceInfos.find(a=>a.processID == instanceInfo.processID) == null) {
		currentInstanceInfoHolder.instanceInfos.push(instanceInfo);
	}
});

export async function GetRemoteInstanceInfos(timeout: number) {
	const infoHolder = new AppInstanceInfoHolder();
	currentInstanceInfoHolder = infoHolder;
	remoteUserProcessesBridge.Call("BroadcastInstanceInformation");
	await SleepAsync(timeout);
	currentInstanceInfoHolder = null;

	return infoHolder.instanceInfos;
}