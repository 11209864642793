import {ignore} from "mobx-sync";
import {store} from "../../../Store/index.js";
import {O} from "web-vcore";
import {CameraConfig} from "./@CameraConfig";
import {makeObservable} from "mobx";

export enum MonitorTab {
	//Dashboard = 10,
	//General = 20,
	Camera = 30,
	Muse = 40,
	LD1 = 50,
	LD1Config = 60,
	LD1Settings = 70,
}
export enum MuseSubtab {
	EEG = 10,
	Gyro = 20,
	Others = 30,
}

export class MonitorState {
	constructor() { makeObservable(this); }
	
	@O tab = MonitorTab.Camera;

	// camera
	// ==========

	@O cameraConfig = new CameraConfig();
	@O @ignore preview = false;
	@O overlay_ignoreRects = true;
	//@O overlay_pixelPercentToTrigger = true;
	@O overlay_pixelTriggers = false; // disabled by default, since heavy on cpu
	@O graph_triggerPixelCount = true;
	@O cam_showAdvanced = false;

	@O @ignore record = false;
	@ignore recorder: any;
	@ignore recordedChunks: BlobPart[] = [];

	// muse
	// ==========

	@O muse_subtab = MuseSubtab.EEG;

	// eeg
	@O @ignore eeg_monitor = false;
	@O @ignore eeg_generateFake = false;
	//@O @ignore museStatus = "disconnected" as MuseConnectStatus; // (moved to MuseInterface.ts)
	//@O eeg_showRaw = false;
	//@O eeg_showNormalized = true;
	@O eeg_normalize = true;
	@O eeg_smooth = true;
	/*@O eeg_show_combinedDeviation = false;
	@O eeg_show_triggerSamplePercent = false;*/
	//@O eeg_range = 100;
	@O @ignore eegProcessor_changedAt = 0;

	// gyro
	@O @ignore gyro_monitor = false;
	@O @ignore gyro_generateFake = false;
	@O gyro_normalize = true;
	@O gyro_smooth = true;
	/*@O gyro_show_combinedDeviation = false;
	@O gyro_show_triggerSamplePercent = false;*/
	@O @ignore gyroProcessor_changedAt = 0;

	// others
	@O muse_freezeDetectTime = 10;

	// ld1
	// ==========

	@O ld1 = new LD1State();
}

export class LD1State {
	constructor() { makeObservable(this); }
	
	@O commandsAutoRun = true;
	@O @ignore consoleLines = [] as string[];

	@O motor_pin = 2;
	@O @ignore motor_power = 0; // 0-255
	@O motor_period = 65;

	// from config panel
	@O magRangeCollecting_expandOnly = false;
	@O config1 = new LD1Config();

	// from settings panel
	@O deviceAddresses = [
		//"00:11:22:33:44:55",
		"E9:60:3B:58:24:03", // Nano 33 BLE, with headers (#1)
		"E0:56:D8:DB:C7:F1", // Nano 33 BLE, without headers
		"49:92:DB:6F:E0:40", // Nano 33 BLE, with headers (#2)
	];
	@O deviceAddresses_activeIndex = 1;
}

export function GetMonitorCameraConfig() {
	return store.main.tools.monitor.cameraConfig;
}

// NOTE: All fields must start with a default value, so that `Object.keys(new LD1Config())` can enumerate the field-names. (eg. for SendLD1ConfigToDevice)
export class LD1Config {
	constructor() { makeObservable(this); }

	// todo: populate these with workable defaults
	@O magRanges_xMin = 0;
	@O magRanges_xMax = 0;
	@O magRanges_yMin = 0;
	@O magRanges_yMax = 0;
	@O magRanges_zMin = 0;
	@O magRanges_zMax = 0;

	// Note: These values are for the "raw magnetometer values", ie. prior to usage/calculation of true/geographic north (ie. using declination), and prior to custom offset.
	// todo: maybe rename this term from "magnetometer value" to "magnetometer angle" (to distinguish it more clearly from the raw readings)
	@O magValForNorth = 0;
	@O magValForEast = 90;
	@O magValForSouth = 180;
	@O magValForWest = 270;
	@O additionalMagTargetOffset = 0;

	@O motorPin_front = 8;
	@O motorPin_right = 2;
	@O motorPin_back = 4;
	@O motorPin_left = 6;
	
	@O vibrateMultiplier_front = 1;
	@O vibrateMultiplier_right = 1;
	@O vibrateMultiplier_back = 1;
	@O vibrateMultiplier_left = 1;
	
	@O logSensorReadings = false;
	@O logDirection = true;
	@O vibrateStrength = .5; // 0-1
	@O minTurnAmountForVibrate = 70;
	@O maxAngleFromVerticalForVibrate = 20;
	@O minTimeSinceBadAngleForVibrate = 1000;
	@O averageHeadingOverXMilliseconds = 0;
	@O useBoardLights = true;
}