import {BaseComponentPlus} from "react-vextensions";
import {Row, Text, Button} from "react-vcomponents";
import {Range, IsNaN, Assert, E} from "js-vextensions";
import {HSL, PageContainer, RunInAction_Set} from "web-vcore";
//import {Line} from "react-chartjs-2";
import {RecordingSession, GetSessionSampleCount} from "../../../Store/firebase/recordingSessions/@RecordingSession";
import {useExpandedNavBar} from "../../../UI/@Shared/NavBar";
import moment from "moment";
import {store} from "../../../Store/index.js";
import {Muse_eegSamplesPerSecond_raw} from "../@Shared/MuseInterface/EEGStructs";

export class RecordingSessionGraphPanel extends BaseComponentPlus({} as {session: RecordingSession}, {offset: 0}) {
	render() {
		const {session} = this.props;
		const {offset} = this.state;

		const uiState = store.main.tools.training;
		const largeVersion = useExpandedNavBar();

		const sampleCount = GetSessionSampleCount(session);
		const graph_pageCount = (sampleCount / RecordingSessionGraph_graphedSamplesPerChartWidth).CeilingTo(1);

		return (
			<PageContainer preset="text" style={E(
				{padding: 0, height: "100%"},
				!largeVersion && {margin: "0 auto 0 auto"},
			)}>
				<Row>
					<Button text="Back" onClick={()=>{
						RunInAction_Set(this, ()=>uiState.showGraphForSession = null);
					}}/>
					<Text ml={5}>Date: {moment(uiState.showGraphForSession!.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Text>
					<Button ml="auto" text="<" enabled={offset > 0} onClick={()=>this.SetState({offset: offset - 1})}/>
					<Button ml={5} text=">" enabled={offset < graph_pageCount - 1} onClick={()=>this.SetState({offset: offset + 1})}/>
				</Row>
				<RecordingSessionGraph session={uiState.showGraphForSession!} sampleOffset={offset * RecordingSessionGraph_graphedSamplesPerChartWidth}/>
			</PageContainer>
		);
	}
}

const lineTypes = [
	{name: "Left", color: HSL(0, 0, 1)},
	{name: "Right", color: HSL(0, 0, .5)},
];

//export const RecordingSessionGraph_chartWidthInSeconds = 10;
export const RecordingSessionGraph_chartWidthInSeconds = 5; // data for recorded-session graph is more detailed than for live-graph, so reduce chart-width to 5-seconds-worth
export const RecordingSessionGraph_graphedSamplesPerChartWidth = Muse_eegSamplesPerSecond_raw * RecordingSessionGraph_chartWidthInSeconds;

export class RecordingSessionGraph extends BaseComponentPlus({} as {session: RecordingSession, sampleOffset: number}, {}) {
	render() {
		const {session, sampleOffset} = this.props;

		const ticks = Range(0, RecordingSessionGraph_graphedSamplesPerChartWidth);
		const chartData = {
			labels: ticks.map(tickIndex=>{
				const time = tickIndex / Muse_eegSamplesPerSecond_raw;
				return time.toFixed(2);
			}),
			datasets: [] as any[],
		};
		const chartOptions = {
			tooltips: {mode: "index"},
			maintainAspectRatio: false,
			animation: false,
			elements: {point: {radius: 7}},
			scales: {
				yAxes: [{
					display: true,
					ticks: {min: 0, suggestedMin: 0, max: 2000, suggestedMax: 2000},
				}],
			},
		};
		function AddLine(label: string, color: string) {
			const dataset = {
				label, backgroundColor: color, borderColor: color, borderWidth: 1, lineTension: 0, fill: false,
				pointBackgroundColor: "transparent", pointHoverRadius: 7, pointHoverBackgroundColor: color, pointBorderWidth: 0, pointBorderColor: "transparent",
				data: [] as number[],
			};
			dataset.data = (session.eegChannelSamples[label == "Left" ? "af7" : "af8"] ?? []).slice(sampleOffset, sampleOffset + RecordingSessionGraph_graphedSamplesPerChartWidth);
			chartData.datasets.push(dataset);
			return dataset.data;
		}
		for (const type of lineTypes) {
			AddLine(type.name, type.color);
		}

		return (
			<div style={{width: "100%", height: "100%"}}>
				{/*<Line ref={c=>this.line = c} data={chartData} options={chartOptions as any}/>*/}
				<div>TODO (replace chart lib)</div>
			</div>
		);
	}
	//line: Line|n;
}