import uPlot from "uplot";

const axes_gridStyle = {
	stroke: "rgba(255,255,255,.12)",
	width: 1,
};
const axes_tickStyle = {
	stroke: "#eee",
	width: 2,
	size: 10,
};
export const uplotDefaults = {
	axes_props: {
		gap: 5,
		//size: 30,
		stroke: "white",
		grid: axes_gridStyle,
		ticks: axes_tickStyle,
		// label styling (requires label prop to be used)
		labelSize: 30,
		labelFont: "bold 12px Arial",
		font: "12px Arial",
	} as uPlot.Axis,
	axes_gridStyle,
	axes_tickStyle,
};