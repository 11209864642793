let extensionFound = false;
export function ExtensionPresent() {
	if (!extensionFound) {
		extensionFound = document.getElementById("lucid-frontier-extension-marker") != null;
	}
	return extensionFound;
}

export function CallExtensionFunction(func, ...args) {
	window.postMessage({type: "LucidFrontier_CallExtensionFunction", func, args}, "*");
}