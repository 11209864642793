import {Clone, E, GetEntries, ToJSON} from "js-vextensions";
import {Button, Column, DropDown, DropDownContent, DropDownTrigger, Row, RowLR, Select, Spinner, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {store} from "../../Store/index.js";
import {GetLights} from "../../Store/firebase/lights";
import {EffectVisibility} from "../../Store/firebase/sounds/@Sound";
import {GetUser, MeID} from "../../Store/firebase/users";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";
import {KasaDevice, LIFXConnectionType} from "../../Store/main/effects/lights";
import {GetLIFXDevices, InAndroid, InDesktop, nativeBridge} from "../../Utils/Bridge/Bridge_Native";
import {DialogStyle, ES} from "../../Utils/UI/GlobalStyles";
import {zIndexes} from "../../Utils/UI/ZIndexes";
import {InfoButton, Observer, PageContainer, RunInAction, RunInAction_Set, TextPlus} from "web-vcore";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel";
import {TagTargetEntryUI} from "../@Shared/TagTargetEntryUI";
import {ShowAddLightDialog} from "./Lights/LightDetailsUI";
import React from "react";
import {LightType} from "../../Store/firebase/lights/@Light.js";

export const columnWidths = [.25, .1, .1, .3, .25];

@Observer
export class LightsUI extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.effects.lights;
		const {filter} = uiState;
		const lights_raw = GetLights().OrderByDescending(a=>(a.creator == MeID() ? 1 : 0));

		const lights = filter.length == 0 ? lights_raw : lights_raw.filter(a=>{
			if (a.name.toLowerCase().includes(filter.toLowerCase())) return true;
			if (LightType[a.type].toLowerCase().includes(filter.toLowerCase())) return true;
			if (a.creator && GetUser(a.creator)?.displayName.toLowerCase().includes(filter.toLowerCase())) return true;
			if ((a.tags ?? []).filter(a=>a.toLowerCase().includes(filter.toLowerCase())).length) return true;
			return false;
		});

		return (
			<PageContainer style={{margin: "20px auto 20px auto", padding: 0}}>
				<Column className="clickThrough" style={{height: 80, borderRadius: "10px 10px 0 0"}}>
					<Row center style={{height: 40, padding: 10}}>
						<Row center>
							<DropDown>
								<DropDownTrigger>
									<Button text="LIFX"/>
								</DropDownTrigger>
								<DropDownContent style={{zIndex: zIndexes.dropDown, background: "rgba(0,0,0,.9)", borderRadius: 5}}>
									<LIFXDropDown/>
								</DropDownContent>
							</DropDown>
							<DropDown>
								<DropDownTrigger>
									<Button ml={5} text="Kasa"/>
								</DropDownTrigger>
								<DropDownContent style={E(
									{zIndex: zIndexes.dropDown, background: "rgba(0,0,0,.9)", borderRadius: 5},
									// if on small screen, float drop-down to the very left of entire screen (else would likely not fit on-screen)
									window.outerWidth < (130 + KasaDropDown_width) && {position: "fixed", left: 0},
								)}>
									<KasaDropDown/>
								</DropDownContent>
							</DropDown>
							<Text ml={5}>Filter:</Text>
							<TextInput ml={5} style={{width: 150}} instant value={filter} onChange={val=>RunInAction_Set(this, ()=>uiState.filter = val)}/>
						</Row>
						<Button text="Add light" ml="auto" onClick={()=>{
							if (MeID() == null) return ShowSignInPopup();
							ShowAddLightDialog();
						}}/>
					</Row>
					<Row style={{height: 40, padding: 10}}>
						<Text style={{flex: columnWidths[0], fontWeight: 500, fontSize: 16}}>Name</Text>
						<Text style={{flex: columnWidths[1], fontWeight: 500, fontSize: 16}}>Type</Text>
						<Text style={{flex: columnWidths[2], fontWeight: 500, fontSize: 16}}>Creator</Text>
						<Text style={{flex: columnWidths[3], fontWeight: 500, fontSize: 16}}><Text>Tags (click tags to </Text><span style={{color: "rgba(100,200,100,.7)"}}>accept</span>)</Text>
						<Text style={{flex: columnWidths[4], fontWeight: 500, fontSize: 16}}></Text>
					</Row>
				</Column>
				<ScrollView style={ES({flex: 1})} contentStyle={ES({flex: 1})}>
					{lights.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
					{lights.map((light, index)=>{
						return <TagTargetEntryUI key={index} group="lights" entry={light} index={index} last={index == lights.length - 1}/>;
					})}
				</ScrollView>
			</PageContainer>
		);
	}
}

@Observer
class LIFXDropDown extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.effects.lights;

		const splitAt = 150;
		return (
			<Column style={{width: 600}}>
				<RowLR splitAt={splitAt}>
					<Text>LIFX connection:</Text>
					<Select options={GetEntries(LIFXConnectionType)} value={uiState.lifxConnection} onChange={val=>RunInAction_Set(this, ()=>uiState.lifxConnection = val)}/>
					<InfoButton ml={5} text={`
						Auto: If in desktop/android app: LAN, if in browser with extension: WebDirect, else: fallback to WebProxy
						Web API (proxy): Slowest option, but works everywhere. (doesn't require the desktop app, android app, or Chrome extension)
						Web API (direct): Faster than the proxy, but requires the desktop app, android app, or Chrome extension.
						LAN API: Fastest option, but requires the Lucid Frontier desktop app, or android app.
						`.AsMultiline(0)}/>
				</RowLR>
				<Row mt={5}>Web settings:</Row>
				<RowLR mt={5} splitAt={splitAt}>
					<TextPlus ml={15} sel info={"To generate an API token for your account, see: https://api.developer.lifx.com/docs/authentication"}>API token:</TextPlus>
					<TextInput style={{flex: 1}} value={uiState.lifx_webToken} onChange={val=>RunInAction_Set(this, ()=>uiState.lifx_webToken = val)}/>
				</RowLR>
				<Row mt={5} center>
					<Text>LAN settings:</Text>
					<InfoButton ml={5} text={`
						The bulb-search behavior depends on which bulb values are specified (ie. not left empty)...
						None: Searches network for first LIFX bulb, with no requirements.
						IP or mac: Searches network for first LIFX bulb that matches the specified ip/mac.
						IP+Mac: No bulb-search is performed; device connection is made directly based on specified ip+mac.

						Note: After connecting to a LIFX device, the app must be restarted for changes to the search criteria to be reflected.
						Note: The search criteria are currently ignored in the Android app. (it just sends the commands to the last-found LIFX device)
					`.AsMultiline(0)}/>
					<Button ml={10} text="Search" enabled={InDesktop()} onClick={async()=>{
						const entries = await GetLIFXDevices();
						ShowMessageBox({
							title: "LIFX devices found on network",
							message: ()=>{
								return (
									<Column style={DialogStyle({width: 800, height: 500})}>
										{/*<Text></Text>*/}
										<TextArea mt={5} style={{flex: 1}} value={entries.map(device=>{
											//return ToJSON(device.IncludeKeys("ip", "mac"));
											return ToJSON(device, undefined, 2);
										}).join("\n")}/>
									</Column>
								);
							},
						});
					}}/>
				</Row>
				<RowLR mt={5} splitAt={splitAt}>
					<Text ml={15}>IP address:</Text>
					<TextInput value={uiState.lifx_ipAddress} onChange={val=>RunInAction_Set(this, ()=>uiState.lifx_ipAddress = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text ml={15}>MAC address:</Text>
					<TextInput value={uiState.lifx_macAddress} onChange={val=>RunInAction_Set(this, ()=>uiState.lifx_macAddress = val)}/>
				</RowLR>
			</Column>
		);
	}
}

// temp fix for "DEV" being true in production (due to justDeploy:prod reusing the webpack-dev-server bundles atm, as shortcut)
const DEV_real = DEV && globalThis?.location?.hostname == "localhost";

const KasaDropDown_width = 370;
@Observer
class KasaDropDown extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.effects.lights;

		return (
			<Column style={{width: KasaDropDown_width}}>
				<Row mt={5} mb={5} center>
					<Text>LAN settings:</Text>
					<Button ml={10} text="Search" enabled={InAndroid(0) || DEV_real} onClick={async()=>{
						let newLights = InAndroid(0)
							? (await nativeBridge.Call("FindKasaLights")) as KasaDevice[]
							: [
								new KasaDevice({host: "Example device-host #1 (for ui-dev testing)"}),
								new KasaDevice({host: "Example device-host #2 (for ui-dev testing)"})
							];
						//alert("newLights_json:" + JSON.stringify(newLights, null, 2));

						const boxController = ShowMessageBox({
							title: "Kasa lights found on network",
							message: ()=>{
								return (
									<Column style={{width: KasaDropDown_width, height: 500}}>
										<Row>
											<Button text="Replace light-list with these" onClick={()=>{
												RunInAction_Set(this, ()=>uiState.kasa_lights = newLights);
												//boxController.Close();
											}}/>
										</Row>
										{/*<TextArea mt={5} style={{flex: 1}} value={lights.map(device=>{
											//return ToJSON(device.IncludeKeys("ip", "mac"));
											return ToJSON(device, null, 2);
										}).join("\n")}/>*/}
										<ScrollView>
											{newLights.map((light, index)=>{
												return <KasaLightUI key={index} index={index} enabled={false} value={light} onChange={val=>{}}/>;
											})}
										</ScrollView>
									</Column>
								);
							},
						});
					}}/>
					<Button ml={5} text="Add" onClick={()=>{
						uiState.kasa_lights.push(new KasaDevice());
					}}/>
				</Row>
				<Row>Note: Only the "host" (ie. ip-address) is actually needed atm.</Row>
				{uiState.kasa_lights.map((light, index)=>{
					return <KasaLightUI key={index} index={index} enabled={true} value={light} onChange={val=>{
						RunInAction("KasaLightManualEdit", ()=>{
							if (val) uiState.kasa_lights[index] = val;
							else uiState.kasa_lights.RemoveAt(index);
						});
					}}/>;
				})}
			</Column>
		);
	}
}

const KasaLightUI = (props: {index: number, enabled: boolean, value: KasaDevice, onChange: (val: KasaDevice|n)=>any})=>{
	const {index, enabled, value, onChange} = props;
	
	const Change = (changeFunc: (a: KasaDevice)=>void)=>{
		const newValue = Clone(value);
		changeFunc(newValue);
		onChange(newValue);
	};
	const splitAt = 110;
	return (
		<Column mt={index == 0 ? 0 : 5} style={{position: "relative", border: "1px solid rgba(255,255,255,.3)", padding: 5}}>
			<Button text="Delete" enabled={enabled} style={{position: "absolute", right: 0, top: 0}} onClick={()=>onChange(null)}/>
			<RowLR mt={5} splitAt={splitAt}>
				<Text>Name (parsed):</Text>
				<TextInput enabled={enabled} value={value.name} onChange={val=>Change(a=>a.name = val)}/>
			</RowLR>
			<RowLR mt={5} splitAt={splitAt}>
				<Text>Device ID:</Text>
				<TextInput enabled={enabled} value={value.deviceId} onChange={val=>Change(a=>a.deviceId = val)}/>
			</RowLR>
			<RowLR splitAt={splitAt}>
				<Text>IP address:</Text>
				<TextInput enabled={enabled} value={value.host} onChange={val=>Change(a=>a.host = val)}/>
			</RowLR>
			<RowLR splitAt={splitAt}>
				<Text>Port:</Text>
				<Spinner enabled={enabled} value={value.port} onChange={val=>Change(a=>a.port = val)}/>
			</RowLR>
		</Column>
	);
};