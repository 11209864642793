import {E, ObjectCE, ShallowChanged, Timer, Assert} from "js-vextensions";
import {makeObservable, observable, runInAction} from "mobx";
import {store} from "../../../Store/index.js";
import {FBAConfig} from "../../../Store/firebase/fbaConfigs/@FBAConfig.js";
import {EngineSessionInfo} from "../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {EEGSampleFile, GyroSampleFile, SessionDerivativeData, GeneralHistory} from "../../../Store/main/timeline.js";
import uPlot from "uplot";
import {FindIndex_BinarySearch} from "../../../Utils/General/General.js";
import {O, RunInAction} from "web-vcore";
import {Muse_eegSamplesPerSecond_raw} from "../../../UI/Tools/@Shared/MuseInterface/EEGStructs.js";
import {Muse_gyroSamplesPerSecond_raw} from "../../../UI/Tools/@Shared/MuseInterface/GyroStructs.js";
import {EEGActivityProcessor, FillNullsWithX} from "./EEGActivityProcessor.js";
import {EEGProcessor, EEGProcessingLevel, EEGProcessingLevel_max} from "./EEGProcessor.js";
import {GyroMotionProcessor} from "./GyroMotionProcessor.js";
import {GyroProcessor, GyroProcessingLevel_max} from "./GyroProcessor.js";

export type SessionDataProcessor_ExtraConfig = {
	performResim: boolean;
	eegAndGyro_normalize: boolean;
	eegAndGyro_smooth: boolean;
	clearOutOfRangeCache: boolean;
	//clearOutOfRangeSamples: boolean;
}

/** This class collects data from gyro, eeg, and eeg-activity processors, into one package for sending to the DetailPanel uplot chart. */
export class SessionDataProcessor {
	constructor() {
		makeObservable(this);
	}
	
	// config
	config: FBAConfig;
	extraConfig: SessionDataProcessor_ExtraConfig;

	GetEEGProcessorOptions(config = this.config, extraConfig = this.extraConfig) {
		return E(config.eeg, {
			samplesProcessedPerSecond: Muse_eegSamplesPerSecond_raw,
			calc_normalize: true,
			calc_smooth: true,
			calc_combinedDeviation: true,
			calc_triggerSamplePercent: true,

			uplotData_normalize: extraConfig.eegAndGyro_normalize,
			uplotData_smooth: extraConfig.eegAndGyro_smooth,

			clearOutOfRangeSamples: false,
		});
	}
	GetGyroProcessorOptions(config = this.config, extraConfig = this.extraConfig) {
		return E(config.gyro, {
			samplesProcessedPerSecond: Muse_gyroSamplesPerSecond_raw,
			calc_normalize: true,
			calc_smooth: true,
			calc_combinedDeviation: true,
			calc_triggerSamplePercent: true,

			uplotData_normalize: extraConfig.eegAndGyro_normalize,
			uplotData_smooth: extraConfig.eegAndGyro_smooth,

			clearOutOfRangeSamples: false,
		});
	}
	UpdateConfig(config: FBAConfig, extraConfig: SessionDataProcessor_ExtraConfig) {
		const invalidatesExistingData =
			ShallowChanged(this.GetEEGProcessorOptions(config, extraConfig), this.eegProcessor?.options)
			|| ShallowChanged(this.GetGyroProcessorOptions(config, extraConfig), this.gyroProcessor?.options)
			|| ShallowChanged(extraConfig, this.extraConfig);
		this.config = config;
		this.extraConfig = extraConfig;
		if (invalidatesExistingData && this.loadedSessionData) {
			//this.ResetData();
			this.Init_SavedSession(this.loadedSessionData);
		}
	}

	// for "raw" data
	// ==========

	@observable.ref eegProcessor: EEGProcessor;
	@observable.ref gyroProcessor: GyroProcessor;

	// for "simulation" data
	// ==========

	// if loading
	loadedSimData_eeg_alignedSamples_eegActivity = [] as number[]; // derivative of "activityByTime" map in EEGActivity.json

	// if "resimulating"
	@observable.ref gyroMotionProcessor: GyroMotionProcessor|n;
	@observable.ref eegActivityProcessor: EEGActivityProcessor|n;
	// used by GetSelectedSessionData_AllowingResim()
	GetSessionDataOverrides(realData: SessionDerivativeData) {
		// must be calling prior to this session-processor responding to new settings (and creating the needed sub-processors)
		if (this.eegActivityProcessor == null || this.gyroMotionProcessor == null) return;

		return {
			config: this.config,
			generalHistory: E(realData.generalHistory, {
				eegActivity: {
					activityByTime: this.eegActivityProcessor.eegActivityByTime,
				},
				gyroMotion: {
					motionsByTime: this.gyroMotionProcessor.motionTriggerTimes.ToMapObj(time=>time.toString(), ()=>true),
				},
			} as GeneralHistory),
		};
	}

	// output data
	// ==========

	detailChart_uplotData: uPlot.AlignedData;
	//seekBarChart_uplotData: uPlot.AlignedData; // seek-bar data small enough, we can recalc/repackage each render

	// for loading a saved session
	// ==========

	@observable.ref loadedSessionData: {
		session: EngineSessionInfo|n,
		//sessionID: string,
		// raw data
		eegSampleFiles: {left: EEGSampleFile, right: EEGSampleFile},
		gyroSampleFiles: {x: GyroSampleFile, y: GyroSampleFile, z: GyroSampleFile},
		// simulation data
		eeg_activityByTime: {[key: number]: number};
		gyro_motionsByTime: {[key: number]: boolean}; // this is sufficient; only seek-bar uses, and it doesn't need the data eeg-sample-aligned
	}|n;
	Init_Empty() {
		this.config = {} as any; // needed to avoid error in GetEEGProcessorOptions and such
		this.extraConfig = {} as any;
		this.Init_SavedSession({
			session: null,
			eegSampleFiles: {left: {samplesBySecond: {}}, right: {samplesBySecond: {}}},
			gyroSampleFiles: {x: {samplesBySecond: {}}, y: {samplesBySecond: {}}, z: {samplesBySecond: {}}},
			eeg_activityByTime: {},
			gyro_motionsByTime: {},
		});
	}
	Init_SavedSession(data: Exclude<SessionDataProcessor["loadedSessionData"], n>) {
		//this.ResetData();
		RunInAction("SessionDataProcessor.Init_SavedSession", ()=>{
			this.eegProcessor = new EEGProcessor({parent: this, options: this.GetEEGProcessorOptions()});
			this.gyroProcessor = new GyroProcessor({parent: this, options: this.GetGyroProcessorOptions()}); // gyro ordered second for processing, since depends on eeg sample-times\
			this.gyroMotionProcessor = !this.extraConfig.performResim ? null : new GyroMotionProcessor({parent: this, gyroProcessor: this.gyroProcessor});
			this.eegActivityProcessor = !this.extraConfig.performResim ? null : new EEGActivityProcessor({parent: this, eegProcessor: this.eegProcessor, gyroMotionProcessor: this.gyroMotionProcessor ?? undefined});
			//this.loadedSimData_eeg_alignedSamples_eegActivity.Clear(); // commented; cleared and length-set in DeriveAligned...
			//this.gyroProcessor.alignedSamples_combinedDeviation.Clear(); // commented; length-set in GyroProcessor.Populate...

			// important; needed to prevent memory leak, in react/react-uplot (see: https://github.com/facebook/react/issues/18790#issuecomment-726394247)
			// (rule of thumb: any refs accessed by hooks [useEffect at least], may stay active even after comp is unmounted/remounted...)
			(this.detailChart_uplotData as any[])?.Clear();

			if (data.session) {
				this.eegProcessor.Init_SavedSession(data.session, data.eegSampleFiles);
				this.gyroProcessor.Init_SavedSession(data.session, data.gyroSampleFiles, this.eegProcessor);
				if (this.extraConfig.performResim) {
					//this.gyroMotionProcessor?.Init_SavedSession(data.session, data.gyroSampleFiles, this.eegProcessor);
					this.eegActivityProcessor?.Init_SavedSession(data.session, this.eegProcessor);
				} else {
					this.DeriveAlignedEEGActivityDataFromSessionData(data);
				}

				this.detailChart_uplotData = [
					// time
					this.eegProcessor.samples_time as number[],

					// eeg
					this.eegProcessor.samples_left_display as number[],
					this.eegProcessor.samples_right_display as number[],

					// eeg-activity
					this.extraConfig.performResim ? this.eegActivityProcessor!.alignedSamples_eegActivity : this.loadedSimData_eeg_alignedSamples_eegActivity,

					// gyro
					this.gyroProcessor.alignedSamples_combinedDeviation,
				];
			} else {
				this.detailChart_uplotData = Array(7).fill(0).map(()=>new Float64Array()) as uPlot.AlignedData;
			}

			//this.viewCenterSampleIndex = undefined; // commented; keeping view-center is fine/correct
			this.rawProcessing_nextOffsetFromCenter = 0;
			this.simulation_nextOffsetFromStart = 0;
			this.processingComplete = false;

			//this.loadedSessionData = data;
			// only store session-data, if we loaded an actual session (if just empty arrays from Init_Empty, treat as though no loading occurred)
			this.loadedSessionData = data.session ? data : null;
		});
	}
	DeriveAlignedEEGActivityDataFromSessionData(sessionData: Exclude<SessionDataProcessor["loadedSessionData"], n>) {
		const timeSamples = this.eegProcessor.samples_time;
		//const eegSamples = this.eegProcessor.samples;
		const alignedEEGActivityData = this.loadedSimData_eeg_alignedSamples_eegActivity;
		alignedEEGActivityData.Clear();
		alignedEEGActivityData.length = this.eegProcessor.SampleCount;

		const eegActivityChanges = ObjectCE(sessionData.eeg_activityByTime).Pairs();
		for (const pair of eegActivityChanges) {
			const lastPair = eegActivityChanges[pair.index - 1];
			//const lastPair_closestSlotIndex = lastPair ? (FindIndex_BinarySearch(timeSamples, a=>a * 1000 > lastPair.keyNum, true) - 1).KeepAtLeast(0) : null;
			//const closestSlotIndex = (FindIndex_BinarySearch(timeSamples, a=>a * 1000 > pair.keyNum, true) - 1).KeepAtLeast(0); // find index of first slot whose time is prior to (or at) eeg-activity change's time
			const closestSlotIndex = FindIndex_BinarySearch(timeSamples, a=>a * 1000 >= pair.keyNum!, true)!;
			//Assert(closestSlotIndex != -1, `EEG sample not found matching eeg-activity entry time! Time:${pair.keyNum}`);
			// if no slot found >= entry's time, the entry must be after last EEG sample; fill nulls in final-segment with previous value
			if (closestSlotIndex == -1) {
				FillNullsWithX(alignedEEGActivityData, this.eegProcessor.SampleCount - 1, -10, lastPair.value);
				break;
			}

			// use data-driven stepped-mode (this causes spanGaps to span correctly, and stepped-mode paths-func is used to prevent glitch below when zoomed-out)
			if (lastPair == null) {
				FillNullsWithX(alignedEEGActivityData, closestSlotIndex - 1, -10, 0);
			} else if (lastPair && alignedEEGActivityData[closestSlotIndex - 1] == null) {
				//alignedEEGActivityData[closestSlotIndex - 1] = lastPair.value;
				// apply to previous slot, for stepped-mode (and at every Xth slot prior over null region, to avoid uplot zoom-out optimizations from breaking stepped-mode)
				FillNullsWithX(alignedEEGActivityData, closestSlotIndex - 1, -10, lastPair.value);
			}

			// must fill all slots, else zoomed-out state only processes some (and thus spanGaps can span to the wrong next-point)
			/*if (lastPair) {
				//for (let i = lastPair.keyNum + 1; i < pair.keyNum; i++) {
				for (let i = lastPair_closestSlotIndex + 1; i < closestSlotIndex; i++) {
					if (alignedEEGActivityData[i] > lastPair.value) continue; // if two values for slot, keep higher
					alignedEEGActivityData[i] = lastPair.value;
				}
			}*/

			const valLessThanExisting = pair.value < alignedEEGActivityData[closestSlotIndex];
			// if slot's existing val is higher, place new val into next slot instead (same as in SessionDataProcessor, for loaded sim)
			if (valLessThanExisting) {
				alignedEEGActivityData[closestSlotIndex + 1] = pair.value;
			} else {
				alignedEEGActivityData[closestSlotIndex] = pair.value;
			}
		}
	}
	/*ResetProcessing() {
		//this.PopulateWithSessionData(this.populationInfo.session, this.populationInfo.eegSampleFiles, this.populationInfo.gyroSampleFiles);
		this.ResetData();
	}*/

	// the rest
	// ==========

	// auto-process system
	SetViewCenter(viewCenterSampleIndex: number) {
		if (viewCenterSampleIndex == this.viewCenterSampleIndex) return;
		this.viewCenterSampleIndex = viewCenterSampleIndex;
		this.rawProcessing_nextOffsetFromCenter = 0;
	}
	viewCenterSampleIndex: number;
	/** Next extent/offset (from viewCenterSampleIndex), for which to perform sample raw-processing. */
	rawProcessing_nextOffsetFromCenter = 0;
	/** Next extent/offset (from start), for which to perform simulation/sim-processing. */
	simulation_nextOffsetFromStart = 0;
	@O processingComplete = false;

	// for calculating processing percent-done
	get RawProcessing_MaxExtentFromCenter() {
		return Math.max(0..Distance(this.viewCenterSampleIndex), (this.eegProcessor.SampleCount - 1).Distance(this.viewCenterSampleIndex));
	}
	get Simulation_MaxExtentFromStart() {
		return (this.eegProcessor.SampleCount - 1).Distance(0);
	}
	get RawProcessing_Progress() {
		let totalSamplesProcessed = 0;

		// add from-start processing (if applicable)
		if (this.extraConfig.performResim) {
			const fromStartPortion_samplesProcessed = this.simulation_nextOffsetFromStart;
			totalSamplesProcessed += fromStartPortion_samplesProcessed;
		}

		// add view-center processing (if not already surpassed by from-start processing)
		const viewCenterPortion_exclusive_startI = (this.viewCenterSampleIndex - (this.rawProcessing_nextOffsetFromCenter - 1)).KeepAtLeast(this.simulation_nextOffsetFromStart);
		const viewCenterPortion_exclusive_endI = (this.viewCenterSampleIndex + this.rawProcessing_nextOffsetFromCenter).KeepAtLeast(viewCenterPortion_exclusive_startI).KeepAtMost(this.eegProcessor.SampleCount);
		const viewCenterPortion_exclusive_samplesProcessed = viewCenterPortion_exclusive_endI - viewCenterPortion_exclusive_startI;
		// if view-center portion (after excluding start-portion) is at least one entry long, add its length to the total-samples-processed count
		if (viewCenterPortion_exclusive_samplesProcessed >= 1) {
			totalSamplesProcessed += viewCenterPortion_exclusive_samplesProcessed;
		}

		return totalSamplesProcessed / this.eegProcessor.SampleCount;
	}
	get Simulation_Progress() {
		if (!this.extraConfig.performResim) return 1;
		const samplesSimulated = this.simulation_nextOffsetFromStart;
		return samplesSimulated / this.eegProcessor.SampleCount;
	}

	scannerPositions = new Map<string, number>();
	ProcessSample_Raw(index: number, scannerName_withDir?: string, allowDeleteJustOutOfRangeCacheEntries = true) {
		if (index < 0 || index >= this.eegProcessor.SampleCount) return; // exclude out-of-range (for unobstructed break-points)
		const processingLevel = this.eegProcessor.samples_processingLevel[index] as EEGProcessingLevel;
		if (processingLevel != null && processingLevel < EEGProcessingLevel_max && processingLevel != -1) { // (if -1, means processing completed but cache deleted, which is fine)
			// send to eegProcessor before gyroProcessor (no strong reason; but keeps consistent with field order)
			this.eegProcessor.ProcessEEGSample(index);
		}

		if (scannerName_withDir != null) {
			this.scannerPositions.set(scannerName_withDir, index);
			if (this.extraConfig.clearOutOfRangeCache && allowDeleteJustOutOfRangeCacheEntries) {
				this.eegProcessor.ClearJustOutOfRangeData(this.scannerPositions, "cache");
			}
		}

		//const gyroSampleIndex = this.gyroProcessor.GetGyroSampleIndexForEEGSampleIndex(index);
		const gyroSampleIndex = this.gyroProcessor.sampleIndexesForAlignedSampleIndexes.get(index);
		if (gyroSampleIndex != null && this.gyroProcessor.samples[gyroSampleIndex] && this.gyroProcessor.samples[gyroSampleIndex].processingLevel < GyroProcessingLevel_max) {
			this.gyroProcessor.ProcessGyroSample(gyroSampleIndex);
		}
	}
	// send to gyroMotionProcess then eegActivityProcessor (no strong reason; but keeps consistent with field order)
	ProcessSample_Simulation(index: number) {
		Assert(this.gyroMotionProcessor && this.eegActivityProcessor);
		if (this.extraConfig.performResim) {
			const gyroSampleIndex = this.gyroProcessor.sampleIndexesForAlignedSampleIndexes.get(index);
			if (gyroSampleIndex != null) {
				this.gyroMotionProcessor.RespondToSample(gyroSampleIndex);
			}
			this.eegActivityProcessor.RespondToSample(index);

			// probably todo: switch to something like the below (I think it's technically more correct, though current code seems to yield correct results as well, perhaps due to JS float imprecision)
			/*const gyroSampleIndex = this.gyroProcessor.sampleIndexesForAlignedSampleIndexes.get(index);
			const gyroSample = this.gyroProcessor.samples[gyroSampleIndex];
			const gyroSample_isBefore = gyroSample && gyroSample?.time <= this.eegProcessor.samples_time[index] * 1000;
			if (gyroSample && gyroSample_isBefore) this.gyroMotionProcessor.RespondToSample(gyroSampleIndex);
			this.eegActivityProcessor.RespondToSample(index);
			if (gyroSample && !gyroSample_isBefore) this.gyroMotionProcessor.RespondToSample(gyroSampleIndex);*/
		}
	}

	processingTimer = new Timer(processing_baseTickInterval, ()=>{
		// keep timer-interval up-to-date
		const uiState = store.main.timeline.sessions;
		const combinedCPULimit = uiState.processing_cpuLimit + uiState.simulation_cpuLimit;
		const newTimerInterval = processing_baseTickInterval / combinedCPULimit;
		if (newTimerInterval != this.processingTimer.intervalInMS) {
			this.processingTimer.intervalInMS = newTimerInterval;
			this.processingTimer.Start(); // restart with new timer-interval
			return;
		}
		if (this.loadedSessionData == null) return; // if no session loaded, do nothing (this timer should be disabled from @ProcessorInstances.ts soon)
		if (this.eegProcessor.options == null) return; // wait till options loaded (from autorun in DetailPanel.tsx)

		// if no simulation, use its cpu-time for raw-processing (since simulation normally also involves raw-processing)
		const processing_cpuLimit = uiState.processing_cpuLimit + (this.extraConfig.performResim ? 0 : uiState.simulation_cpuLimit);

		// do raw processing batch
		const raw_startTime = Date.now();
		const raw_endTime = raw_startTime + (processing_baseTickInterval * (processing_cpuLimit / combinedCPULimit));
		const maxExtent = this.RawProcessing_MaxExtentFromCenter;
		for (let extent = this.rawProcessing_nextOffsetFromCenter; extent <= maxExtent; extent++) {
			this.ProcessSample_Raw(this.viewCenterSampleIndex - extent, "l_process", false);
			this.ProcessSample_Raw(this.viewCenterSampleIndex + extent, "r_process");
			this.rawProcessing_nextOffsetFromCenter = extent + 1;
			if (Date.now() >= raw_endTime) break;
		}

		// do resim processing batch
		const sim_startTime = Date.now();
		const sim_endTime = sim_startTime + (processing_baseTickInterval * (uiState.simulation_cpuLimit / combinedCPULimit));
		if (this.extraConfig.performResim) {
			const maxExtent = this.Simulation_MaxExtentFromStart;
			for (let i = this.simulation_nextOffsetFromStart; i <= maxExtent; i++) {
				/*const eegSampleForIndex = this.eegProcessor.samples[i];
				const gyroSampleForIndex = this.gyroProcessor.samples[i];
				// we cannot do resim processing for an index, until raw eeg and gyro processing are complete up to that point
				if (eegSampleForIndex.processingLevel < 4 || gyroSampleForIndex.processingLevel < 4) break;*/
				this.ProcessSample_Raw(i, "r_simulate"); // ensure sample is raw-processed up to this point

				this.ProcessSample_Simulation(i);
				this.simulation_nextOffsetFromStart = i + 1;
				if (Date.now() >= sim_endTime) break;
			}
		}

		/*const scanPoints_leftMoving = [
			this.viewCenterSampleIndex - (this.rawProcessing_nextOffsetFromCenter - 1),
		];
		const scanPoints_rightMoving = [
			this.simulation_nextOffsetFromStart - 1,
			this.viewCenterSampleIndex + (this.rawProcessing_nextOffsetFromCenter - 1),
		];
		this.eegProcessor.DeleteOutOfRangeCacheEntries(scanPoints_leftMoving, scanPoints_rightMoving);*/

		this.CheckIfProcessingAndSimulationComplete();
	});
	CheckIfProcessingAndSimulationComplete() {
		// processing can never change from completed to incompleted, without call to ResetData() [which resets processingComplete to false]
		if (this.processingComplete) return;

		/*const rawProcessingComplete = this.rawProcessing_nextOffsetFromCenter >= this.RawProcessing_MaxExtentFromCenter;
		const simProcessingComplete = !this.extraConfig.performResim || this.simulation_nextOffsetFromStart >= this.Simulation_MaxExtentFromStart;*/
		const rawProcessingComplete = this.RawProcessing_Progress >= 1; // use helper, since it includes processing done by start-to-end simulation-processor
		const simProcessingComplete = this.Simulation_Progress >= 1;
		const processingComplete = rawProcessingComplete && simProcessingComplete;
		if (processingComplete != this.processingComplete) {
			RunInAction("onProcessingCompleted", ()=>this.processingComplete = processingComplete);
			this.processingTimer.Stop();
		}
	}
}
const processing_baseTickInterval = 16; // 1000/60 = 16.66 = ~16; just low enough that maintaining 60fps is possible