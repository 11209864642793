import {UserEdit} from "../CommandMacros";
import {AssertValidate, GenerateUUID, Command, AssertV} from "mobx-firelink";
import {Entity} from "../../Store/firebase/entities/@Entity";

@UserEdit
export class AddEntity extends Command<{entity: Entity}, string> {
	id: string;
	Validate() {
		const {entity} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		entity.creator = this.userInfo!.id;
		entity.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("Entity", entity, `Entity invalid`);
	}

	GetDBUpdates() {
		const {entity} = this.payload;

		const updates = {};
		updates[`entities/${this.id}`] = entity;
		return updates;
	}
}