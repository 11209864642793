import {emptyArray} from "js-vextensions";
import {Button, ColorPickerBox, Column, Row, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {UpdateActivity} from "../../Server/Commands/UpdateActivity.js";
import {GetActivities} from "../../Store/firebase/activities.js";
import {Activity} from "../../Store/firebase/activities/@Activity.js";
import {GetUser, MeID} from "../../Store/firebase/users.js";
import {PageContainer, Observer, Chroma_Safe, Chroma} from "web-vcore";
import {ES} from "../../Utils/UI/GlobalStyles.js";
import {GetUserPermissionGroups} from "../../Store/firebase/users/$user.js";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel.js";
import {ShowAddActivityDialog} from "../Activities/AddActivityDialog.js";

@Observer
export class ActivitiesUI extends BaseComponent<{}, {}> {
	render() {
		const permissions = GetUserPermissionGroups(MeID());
		const activities = MeID() ? GetActivities(MeID()) : emptyArray;

		return (
			<PageContainer style={{padding: 0}}>
				<Column className="clickThrough" style={{height: 70, background: "rgba(0,0,0,.7)", borderRadius: "10px 10px 0 0"}}>
					<Row style={{height: 40, padding: 10}}>
						<span style={{position: "absolute", left: "50%", transform: "translateX(-50%)", fontSize: 18}}>Activities</span>
						<Button text="Add activity" ml="auto" onClick={()=>{
							if (MeID() == null) return ShowSignInPopup();
							ShowAddActivityDialog();
						}}/>
					</Row>
					<Row style={{height: 30, padding: 10}}>
						<span style={{flex: columnWidths[0], fontWeight: 500, fontSize: 15}}>Name</span>
						<span style={{flex: columnWidths[1], fontWeight: 500, fontSize: 15}}>Color</span>
					</Row>
				</Column>
				<Column>
					{activities.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
					{activities.map((activity, index)=>{
						return <ActivityRow key={index} activity={activity} index={index} last={index == activities.length - 1}/>;
					})}
				</Column>
			</PageContainer>
		);
	}
}

export const columnWidths = [.7, .3];

export class ActivityRow extends BaseComponent<{activity: Activity, index: number, last: boolean}, {}> {
	render() {
		const {activity, index, last} = this.props;
		return (
			<Row p="7px 10px" style={ES(
				{background: index % 2 == 0 ? "rgba(30,30,30,.7)" : "rgba(0,0,0,.7)"},
				last && {borderRadius: "0 0 10px 10px"},
			)}>
				<span style={{flex: columnWidths[0]}}>
					<TextInput value={activity.name} onChange={val=>{
						new UpdateActivity({id: activity._key!, updates: {name: val}}).Run();
					}}/>
				</span>
				<span style={{flex: columnWidths[1]}}>
					<ColorPickerBox color={Chroma_Safe(activity.color).rgba()} onChange={color=>{
						const updates = {color: Chroma(color).css()};
						new UpdateActivity({id: activity._key!, updates}).Run();
					}}/>
				</span>
			</Row>
		);
	}
}