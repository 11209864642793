import {useDroppable} from "@dnd-kit/core";
import {Clone, E} from "js-vextensions";
import {Button, Column, Row, Text} from "react-vcomponents";
import {BaseComponent, GetDOM} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ES, Observer} from "web-vcore";
import React from "react";
import {AlarmsComp, AlarmsPhase} from "../../../../Engine/FBASession/Components/AlarmsComp.js";
import {DeleteJournalEntry} from "../../../../Server/Commands/DeleteJournalEntry.js";
import {UpdateJournalEntry} from "../../../../Server/Commands/UpdateJournalEntry.js";
import {GetEntity} from "../../../../Store/firebase/entities.js";
import {Entity} from "../../../../Store/firebase/entities/@Entity.js";
import {GetJournalEntries} from "../../../../Store/firebase/journalEntries.js";
import {GetTermsInDreamSegment, GetTermsInDreamSegmentText, JournalEntry, JournalSegment} from "../../../../Store/firebase/journalEntries/@JournalEntry.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {store} from "../../../../Store/index.js";
import {DNDInfo} from "../../../@Root/DNDStructures.js";
import {JournalSegmentUI} from "../JournalSegmentUI.js";
import {GetLiveFBASession_Reactive, GetLiveLocalSession_Reactive} from "../../../../Engine/FBASession.js";

export function GetTermCountForEntityAnchor(entity: Entity) {
	const dreams = GetJournalEntries(MeID());
	const segments = dreams.SelectMany(a=>a.segments);
	return segments.filter(a=>a.anchorEntity == entity._key).map(a=>GetTermsInDreamSegment(a, "longText", false).length).concat(0).Sum();
}

export function GetSegmentActionButtonWidthInColumns(segment: JournalSegment) {
	const anchorEntity = segment.anchorEntity ? GetEntity(segment.anchorEntity) : null;
	const showAnchorEntity = anchorEntity?.image_embedded && store.main.tools.journey.showAnchorEntities;
	return showAnchorEntity ? 2 : 1;
}

@Observer
export class SegmentActionButton extends BaseComponent<{parentDroppableInfo: DNDInfo|n, journalEntry: JournalEntry, segment: JournalSegment, onClick: ()=>any}, {}> {
	render() {
		let {parentDroppableInfo, journalEntry, segment, onClick} = this.props;
		const segmentIndex = journalEntry.segments.indexOf(segment);

		const terms_short = GetTermsInDreamSegmentText(segment.shortText ?? "");
		const terms_long = GetTermsInDreamSegmentText(segment.longText ?? "");
		const termCount = Math.max(terms_short.length, terms_long.length);
		const colorForWordCount = E(
			terms_short.length == 0 && terms_long.length == 0 && {color: "rgba(255,0,0,1)"},
			terms_short.length > 0 && terms_long.length == 0 && {color: "rgba(255,128,0,1)"},
			terms_short.length == 0 && terms_long.length > 0 && {color: "rgba(255,255,0,1)"},
			terms_short.length > 0 && terms_long.length > 0 && {color: "rgba(0,255,0,1)"},
			segment.wakeTime != null && {color: "white"}, // wake segments always use white text
		)["color"] ?? "white";

		const anchorEntity = segment.anchorEntity ? GetEntity(segment.anchorEntity) : null;
		const showAnchorEntity = anchorEntity?.image_embedded && store.main.tools.journey.showAnchorEntities;
		/*const buttonSizeInColumns = GetSegmentActionButtonWidthInColumns(segment);
		const columnSizeAsFraction = 1 / columns;
		const buttonWidth = (buttonSizeInColumns * columnSizeAsFraction).ToPercentStr();*/
		const buttonWidth = showAnchorEntity ? 80 : 40;

		const droppableInfo = new DNDInfo({
			type: "RemoveEntityFromSequenceButton",
			parentDroppableInfo: parentDroppableInfo ?? undefined,
		});
		const droppableID = JSON.stringify(droppableInfo);
		const {isOver, setNodeRef} = parentDroppableInfo ? useDroppable({id: droppableID, data: droppableInfo}) : {} as ReturnType<typeof useDroppable>;

		let borderStyle: any;
		if (segment.lucid) borderStyle = {border: "2px solid rgba(255,255,0,1)"};
		else if (segment.semiLucid) borderStyle = {border: "2px solid rgba(255,128,0,1)"};
		else if (segment.ordered) borderStyle = {border: "2px solid rgba(0,255,0,1)"};

		if (showAnchorEntity) {
			const hitCount = GetTermCountForEntityAnchor(anchorEntity);
			return (
				<Row onClick={onClick} style={ES(
					{position: "relative", width: buttonWidth, flexShrink: 0, cursor: "pointer"},
					borderStyle,
				)}>
					<img ref={c=>setNodeRef && setNodeRef(c as any)} src={anchorEntity.image_embedded!} style={{width: `100%`, height: "auto", border: "1px solid rgba(255,255,255,.3)"}}/>
					<div style={{
						position: "absolute", left: 0, right: 0, bottom: 0, background: "rgba(0,0,0,.7)",
						height: 18, lineHeight: "18px", fontSize: 16, fontWeight: "bold",
						color: "rgba(255,255,255,1)", textShadow: "rgb(10, 10, 10) -1px 0px, rgb(10, 10, 10) 0px 1px, rgb(10, 10, 10) 1px 0px, rgb(10, 10, 10) 0px -1px",
					}}>
						<div style={{position: "absolute", left: 3, right: 3, bottom: 0, textAlign: "left"}}>
							{hitCount}
						</div>
						<div style={{position: "absolute", left: 3, right: 3, bottom: 0, textAlign: "right", color: colorForWordCount}}>
							{termCount}
						</div>
					</div>
				</Row>
			);
		}
		
		return (
			<Row ref={c=>setNodeRef && setNodeRef(GetDOM(c) as any)}
				style={ES(
					{
						width: buttonWidth, flexShrink: 0,
						//width: `${(100 / columns) * 2}%`,
						aspectRatio: segment.wakeTime != null || segment.longText.trim().length == 0 ? "1/.5" : "1/1",
						border: "1px solid rgba(255,255,255,.3)"
					},
					borderStyle,
				)}>
				<Button text={termCount + ""}
					style={{
						padding: 0,
						//flex: 1, aspectRatio: "1/1",
						width: "100%", height: "100%",
						color: colorForWordCount,
					}}
					onClick={onClick}/>
			</Row>
		);
	}
}

@Observer
export class SegmentActionRowAndDetailsUI extends BaseComponent<{journalEntry: JournalEntry, segment: JournalSegment}, {}> {
	render() {
		let {journalEntry, segment} = this.props;
		const segmentIndex = journalEntry.segments.indexOf(segment);
		const liveJourneySession = GetLiveLocalSession_Reactive();

		return (
			<>
				<JournalSegmentUI entry={journalEntry} segment={segment} segments={journalEntry.segments} showEntitiesSequence={false}/>
				<Row>
					<Text>Actions:</Text>
					<Column>
						<Row>
							<Button text="Phase sleep"
								enabled={liveJourneySession != null && liveJourneySession.Comp(AlarmsComp).PhaseIs(AlarmsPhase.Alarm, AlarmsPhase.Solving, AlarmsPhase.Sleep)}
								onClick={()=>{
									if (liveJourneySession != null && liveJourneySession.Comp(AlarmsComp).PhaseIs(AlarmsPhase.Alarm, AlarmsPhase.Solving, AlarmsPhase.Sleep)) {
										liveJourneySession.Comp(AlarmsComp).SetPhase(AlarmsPhase.Sleep);
									}
								}}/>
							<Button ml={5} text="🗑️ (segment)" onClick={()=>{
								ShowMessageBox({
									title: `Delete this segment`, cancelButton: true,
									message: `Delete this segment?`,
									onOK: ()=>{
										const segments_new = Clone(journalEntry.segments) as JournalSegment[];
										segments_new.RemoveAt(segmentIndex);
										new UpdateJournalEntry({id: journalEntry._key, updates: {segments: segments_new}}).Run();
									},
								});
							}}/>
							<Button ml={5} text="🗑️ (dream)" onClick={()=>{
								ShowMessageBox({
									title: `Delete this journal entry`, cancelButton: true,
									message: `Journal entry contains ${journalEntry.segments.SelectMany(a=>GetTermsInDreamSegment(a, "both", true) ?? []).length ?? 0} terms. Still delete it?`,
									onOK: ()=>{
										new DeleteJournalEntry({id: journalEntry._key}).Run();
									},
								});
							}}/>
						</Row>
						<Row>
							<Button text="Add above" onClick={()=>{
								const segments_new = Clone(journalEntry.segments) as JournalSegment[];
								segments_new.Insert(segmentIndex, new JournalSegment());
								new UpdateJournalEntry({id: journalEntry._key, updates: {segments: segments_new}}).Run();
							}}/>
							<Button text="Add below" onClick={()=>{
								const segments_new = Clone(journalEntry.segments) as JournalSegment[];
								segments_new.Insert(segmentIndex + 1, new JournalSegment());
								new UpdateJournalEntry({id: journalEntry._key, updates: {segments: segments_new}}).Run();
							}}/>
							<Button text="Move up" enabled={segmentIndex > 0} onClick={()=>{
								const segments_new = Clone(journalEntry.segments) as JournalSegment[];
								segments_new[segmentIndex - 1] = Clone(segment);
								segments_new[segmentIndex] = Clone(journalEntry.segments[segmentIndex - 1]);
								new UpdateJournalEntry({id: journalEntry._key, updates: {segments: segments_new}}).Run();
							}}/>
							<Button text="Move down" enabled={segmentIndex < journalEntry.segments.length - 1} onClick={()=>{
								const segments_new = Clone(journalEntry.segments) as JournalSegment[];
								segments_new[segmentIndex + 1] = Clone(segment);
								segments_new[segmentIndex] = Clone(journalEntry.segments[segmentIndex + 1]);
								new UpdateJournalEntry({id: journalEntry._key, updates: {segments: segments_new}}).Run();
							}}/>
						</Row>
					</Column>
				</Row>
			</>
		);
	}
}