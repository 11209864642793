// Note: This is currently not used, and probably doesn't even work atm.

//export async function ResetCurrentDBRoot(database: firebase.Database) {
export async function ResetCurrentDBRoot() {
	/*let firebase = store.firebase.helpers;

	/*await firebase.DBRef("nodes").remove();
	let rootNode: MapNode = {
		type: MapNodeType.Category,
		title: "Root",
		agrees: 0, degree: 0, disagrees: 0, weight: 0, // averages, generated by server
		creator: null,
		approved: true,
		accessLevel: 0, voteLevel: 0,
		supportChildren: {},
		opposeChildren: {},
		talkChildren: {},
	};
	await firebase.DBRef(`nodes/${1}`).set(rootNode);
	ShowMessageBox({message: "Done!"});*#/

	let data = {} as FirebaseData;
	let _ = true; // placeholder, for prop-less objects, which we want to still be saved

	// general
	// ==========

	/*data.general = {
		lastMapID: 99,
		lastTermID: 0,
		lastTermComponentID: 0,
		lastImageID: 0,
		lastNodeID: 99,
	};*#/

	// users
	// ==========

	//let user1Keys = {dev: "Ecq3r7NvgahaMwQQ3PsdgqAFirD2", prod: "EElUKWc2RFNacoSrdgqKbrksQnA3"};
	let user1Key = DEV ? "Ecq3r7NvgahaMwQQ3PsdgqAFirD2" : "EElUKWc2RFNacoSrdgqKbrksQnA3";
	data.users = {
		[user1Key]: {
			avatarUrl: "https://lh6.googleusercontent.com/-CeOB1puP1U8/AAAAAAAAAAI/AAAAAAAAAZA/nk51qe4EF8w/photo.jpg",
			displayName: "Stephen Wicklund",
			email: "venryx@gmail.com",
			providerData: [
				{
					displayName: "Stephen Wicklund",
					email: "venryx@gmail.com",
					photoURL: "https://lh6.googleusercontent.com/-CeOB1puP1U8/AAAAAAAAAAI/AAAAAAAAAZA/nk51q4EF8w/photo.jpg",
					providerId: "google.com",
					uid: "108415649882206100036"
				}
			]
		},
	}
	data.userExtras = {
		[user1Key]: new UserExtraInfo({
			joinDate: Date.now(),
			permissionGroups: {basic: true, verified: true, mod: true, admin: true},
		}),
	}

	await firebase.update(data);
	ShowMessageBox({message: "Done!"});*/
}