import {GetValues_ForSchema} from "js-vextensions";
import {AddSchema} from "mobx-firelink";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {EffectVisibility} from "../sounds/@Sound";

export enum ShakeType {
	SonicBomb = 10,
}

export enum ShakeSubtype {
	Simple = 10,
	Composite = 20,
}

export const patternPresets = [
	// stock
	{name: "Default", value: "!WLALALALALALALALA;"},
	{name: "Pulse", value: "!WTRG0TRG0TRG0TRG;"},
	{name: "SOS", value: "!WPJ0FPV7S1J600;"},
	// custom
];

export class Shake {
	constructor(initialData?: Partial<Shake>) {
		this.VSet(initialData);
		Shake.InitDefaults(this);
		this.VSet(initialData);
	}
	static InitDefaults(s: Shake) {
		s.VSet({
			type: s.type ?? ShakeType.SonicBomb,
			visibility: s.visibility ?? EffectVisibility.Visible,
		});

		if (s.type == ShakeType.SonicBomb) {
			s.VSet({
				loop: false,
				loopInterval: 0,
				subtype: ShakeSubtype.Simple,
				levels: [
					new ShakeLevel(),
				],
			});
		}
	}

	_key: string;
	creator: string;
	createdAt: number;

	name: string;
	type: ShakeType;
	visibility: EffectVisibility;
	tags: string[];
	
	loop: boolean;
	loopInterval: number; // in seconds
	subtype: ShakeSubtype;
	levels: ShakeLevel[];
}
AddSchema("Shake", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},

		name: {type: "string", pattern: name_general},
		type: {oneOf: GetValues_ForSchema(ShakeType)},
		visibility: {oneOf: GetValues_ForSchema(EffectVisibility)},
		tags: {items: {type: "string"}},

		subtype: {oneOf: GetValues_ForSchema(ShakeSubtype)},
		levels: {items: {$ref: "ShakeLevel"}},
		loop: {type: "boolean"},
		loopInterval: {type: "number"},
	},
});

export class ShakeLevel {
	constructor(initialData?: Partial<ShakeLevel>) {
		this.VSet(initialData);
	}

	fromStrengthMin = 0;
	pattern = "";
	effectStrength = 1;
}
AddSchema("ShakeLevel", {
	properties: {
		fromStrengthMin: {type: "number"},
		pattern: {type: "string"},
		effectStrength: {type: "number"},
	},
});