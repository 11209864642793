import {BaseComponent} from "react-vextensions";
import {Row, Button, Text} from "react-vcomponents";
import Moment from "moment";
import {ES} from "../../Utils/UI/GlobalStyles";

export class AudioChunkPlayer extends BaseComponent<{audioChunks: any[]} & React.HTMLProps<Row>, {}> {
	ComponentWillMountOrReceiveProps(props) {
		const {audioChunks} = props;
		this.blob = new Blob(audioChunks, {type: "audio/x-mpeg-3"});
		this.blobURL = URL.createObjectURL(this.blob);
	}

	blob: Blob;
	blobURL: string;
	audioElement: HTMLAudioElement;
	audioElementRef: (c)=>any;
	render() {
		const {audioChunks, style, ...rest} = this.props;
		return (
			<Row {...rest as any} center style={ES({backgroundColor: "hsla(0,0%,100%,.2)", borderRadius: 5, padding: 5}, style)}>
				<audio src={this.blobURL} style={{marginLeft: 5}} ref={this.audioElementRef = this.audioElementRef || (c=>{
					this.audioElement = c;
					if (this.audioElement) {
						this.audioElement.ontimeupdate = ()=>this.Update();
					}
					this.Update();
				})}/>
				{this.audioElement &&
					<>
						<Button size={28} iconSize={14} iconPath={`/Images/Buttons/${this.audioElement.paused ? "Right" : "Pause"}.png`} onClick={()=>{
							if (this.audioElement.paused) {
								this.audioElement.play();
							} else {
								this.audioElement.pause();
							}
							this.Update();
						}}/>
						<Button ml={5} size={28} iconSize={14} iconPath={"/Images/Buttons/Square.png"} enabled={this.audioElement.currentTime != 0} onClick={()=>{
							this.audioElement.currentTime = 0;
							this.Update();
						}}/>
						<Text ml={5}>{this.audioElement.currentTime.toFixed(2)}s</Text>
						<a href={this.blobURL} download={`LFNotes_${Moment().format("YYYY-MM-DD_HH-mm-ss")}.mp3`} style={{marginLeft: 5}}><Button text="Download"/></a>
					</>}
			</Row>
		);
	}
}