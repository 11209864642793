import {UserEdit} from "../../Server/CommandMacros";
import {AssertValidate, GenerateUUID, Command, AssertV} from "mobx-firelink";
import {Light} from "../../Store/firebase/lights/@Light";

@UserEdit
export class AddLight extends Command<{light: Light}, string> {
	id: string;
	Validate() {
		const {light} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		light.creator = this.userInfo!.id;
		light.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("Light", light, `Light invalid`);
	}

	GetDBUpdates() {
		const {light} = this.payload;

		const updates = {};
		updates[`lights/${this.id}`] = light;
		return updates;
	}
}