import {ProcessMessage} from "../../Store/firebase/users/@ProcessMessage";
import {userProcessMessage_expireTime} from "../../Utils/Bridge/Bridge_RemoteUserProcesses";
import {AssertV, Command} from "mobx-firelink";
import {GetUserProcessMessages} from "../../Store/firebase/users/$user";

export class ClearOldUserProcessMessages extends Command<{}> {
	oldUserProcessMessageIDs: string[];
	Validate() {
		AssertV(this.userInfo != null, "User must be signed in.");
		const userProcessMessages = GetUserProcessMessages(this.userInfo!.id);
		const now = Date.now();
		this.oldUserProcessMessageIDs = userProcessMessages.filter(a=>now - a.sendTime >= userProcessMessage_expireTime).map(a=>a._key) as string[];
	}

	GetDBUpdates() {
		const updates = {};
		for (const messageID of this.oldUserProcessMessageIDs) {
			updates[`userProcessMessages/${messageID}`] = null;
		}
		return updates;
	}
}