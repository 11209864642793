import {UserEdit} from "../../Server/CommandMacros";
import {Command, AssertV} from "mobx-firelink";
import {GetTimelineEvent} from "../../Store/firebase/timelineEvents";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";
import {TimelineEvent} from "../../Store/firebase/timelineEvents/@TimelineEvent";

@UserEdit
export class DeleteTimelineEvent extends Command<{id: string}> {
	oldData: TimelineEvent|n;
	Validate() {
		const {id} = this.payload;
		//this.oldData = await GetAsync(()=>GetPost(postID));
		this.oldData = GetTimelineEvent(id);
		//AssertV(IsUserCreatorOrMod(this.userInfo?.id, this.oldData), "Insufficient permissions.");
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		//updates[`timelines/${this.oldData.timeline}/events/${id}`] = null;
		updates[`timelineEvents/${id}`] = null;
		return updates;
	}
}