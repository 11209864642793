import {Timer} from "js-vextensions";
import {ObservableMap, runInAction} from "mobx";
import {StoreAccessor} from "mobx-firelink";
import {GetLocalScripts} from "../../Utils/Bridge/Bridge_Preload.js";
import {OnStoreLoaded, OnStoreLoaded_called} from "../../Utils/General/GlobalHooks.js";
import {store} from "../../Store";
import {ScriptPlayer} from "../../Utils/EffectPlayers/ScriptPlayer.js";
import {RunInAction} from "web-vcore";
import {Me, MeID} from "./users.js";
import {Script, ScriptType} from "./scripts/@Script.js";

export const appStartupScriptPlayer = new ScriptPlayer();
/*export const appStartupScripts_checkForLaunchTimer = new Timer(1000, ()=>{
	if (!OnStoreLoaded_called) return;
	appStartupScripts_checkForLaunchTimer.Stop();
*/
export const appStartupScripts_checkForLaunchTimer = new Timer(1000, ()=>{
	if (OnStoreLoaded_called == false) return;
	if (MeID() == null) return;
	if (Me()?.entityTags?.scripts == null) return; // if user script-tags haven't loaded yet (or were never set), keep waiting
	appStartupScripts_checkForLaunchTimer.Stop();

	// load list of user-scripts
	UpdateLocalScriptsMap(MeID());

	const scripts = GetScripts_WithUserTag(store.main.settings.appStartupScriptTag);
	console.log(`Running random app-startup script. (one of the ${scripts.length} tagged scripts)`);

	// todo: make this run all matching scripts
	//for (const script of scripts) {
	const script = scripts.Random();
	if (script != null) {
		appStartupScriptPlayer.script = script;
		appStartupScriptPlayer.Play(1);
	}
}).Start();

export const GetScript = StoreAccessor(s=>(id: string): Script|n=>{
	const localEntryMatch = Array.from(localScriptMap.values()).find(a=>a._key == id);
	if (localEntryMatch) {
		return GetScripts().find(a=>a._key == id);
	}

	//return GetDoc({}, a=>a.scripts.get(id));
	return null;
});

/** Returns the scripts for the given user. */
export const GetScripts = StoreAccessor(s=>(): Script[]=>{
	const webSessions = []; //GetScripts_Web(userID);
	const localSessions = GetScripts_Local();
	const result = localSessions.slice();
	/*for (const session of webSessions) {
	}*/
	return result;
});
export const localScriptMap = new ObservableMap<string, Script>();
//export const UpdateLocalScriptsMap_callOnceTimer = new Timer(0, ()=>UpdateLocalScriptsMap(), 1);
export function UpdateLocalScriptsMap(userID: string|n) {
	RunInAction("UpdateLocalScriptsMap", ()=>{
		const scripts = GetLocalScripts(userID) as Script[];
		for (const script of scripts) {
			//script.VSet("_key", script.id);
			script.VSet("_type", ScriptType.Privileged);
			localScriptMap.set(script._key, script);
		}

		/*if (appStartupScripts_checkForLaunchTimer.callCount_total == 0) {
			appStartupScripts_checkForLaunchTimer.Start();
		}*/
	});
}
export const GetScripts_Local = StoreAccessor(s=>(): Script[]=>{
	return Array.from(localScriptMap.values());
});
/*export const GetScripts_Web = StoreAccessor(s=>(userID: string): Script[]=>{
	return GetDocs({
		queryOps: [
			new WhereOp("creator", "==", userID),
		],
	}, a=>a.scripts);
});*/

export const GetScripts_WithUserTag = StoreAccessor(s=>(tagName: string|n): Script[]=>{
	const scripts = GetScripts();
	const userScriptTags = Me()?.entityTags?.scripts ?? {};
	return scripts.filter(a=>userScriptTags[a._key]?.includes(tagName as any));
});