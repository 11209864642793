import {E} from "js-vextensions";
import React from "react";
import {BaseComponent, GetDOM} from "react-vextensions";
import useResizeObserver from "use-resize-observer";
import {Observer, PageContainer, RunInAction_Set} from "web-vcore";
import {GetEntities} from "../../../Store/firebase/entities.js";
import {GetSelectedFBAConfig} from "../../../Store/firebase/fbaConfigs.js";
import {GetLights} from "../../../Store/firebase/lights.js";
import {store} from "../../../Store/index.js";
import {BottomPanelSubpanel, entityCellWidthTarget} from "../../../Store/main/tools/journey.js";
import {nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {useExpandedNavBar} from "../../@Shared/NavBar";
import {DreamPeriodsUI} from "../Journey/DreamPeriodsUI.js";
import {CanvasOverlay} from "./CanvasOverlay.js";
import {JBottomPanel} from "./JBottomPanel.js";
import {LockOverlay} from "./LockOverlay.js";
import {JBanner} from "./JBanner.js";

//export const journeyPanelHeights = [0, 50, 50, 0];

export var sessionUIActive = false;
export var lockOverlayActive = false;
export function SetSessionUIActive(active: boolean) {
	sessionUIActive = active;
	UpdateSystemUIVisibility();
}
export function SetLockOverlayActive(active: boolean) {
	lockOverlayActive = active;
	UpdateSystemUIVisibility();
}
export function UpdateSystemUIVisibility() {
	const fbaConfig = GetSelectedFBAConfig();
	const config = fbaConfig?.general;
	if (config == null) return;
	if (lockOverlayActive) {
		nativeBridge.Call("SetSystemUIVisibility", !config.lockOverlay_hideStatusBar, !config.lockOverlay_hideNavBar);
	} else if (sessionUIActive) {
		nativeBridge.Call("SetSystemUIVisibility", !config.sessionUI_hideStatusBar, !config.sessionUI_hideNavBar);
	} else {
		nativeBridge.Call("SetSystemUIVisibility", true, true);
	}
}

@Observer
export class JSessionUI extends BaseComponent<{}, {}> {
	ComponentWillMount = ()=>SetSessionUIActive(true);
	ComponentWillUnmount = ()=>SetSessionUIActive(false);
	render() {
		const uiState = store.main.tools.journey;
		const largeVersion = useExpandedNavBar();

		const {ref: rootRef, width = -1, height = -1} = useResizeObserver({onResize: e=>{
			// find the highest number of columns we can fit, while keeping the column-width >=40px, and keeping the column-count >=9, and a multiple of 3 (since select-entities panel splits it into 3 categories)
			// (add one to the result, to fit an empty column at right of select-entities panel, for touch-based scrolling)
			const newWidth = e.width!.FloorTo(1);
			const newColumnCount = (e.width! / entityCellWidthTarget).FloorTo(3).KeepAtLeast(9) + 1;
			if (newWidth != e.width || newColumnCount != uiState.entities.entityCellColumns) {
				RunInAction_Set(uiState, ()=>{
					uiState.entities.entityCellContainerWidth = newWidth;
					uiState.entities.entityCellColumns = newColumnCount;
				});
			}
		}});
		
		// these are just here so we have synchronous access to them in JourneySession.ts
		const entities = GetEntities();
		const lights = GetLights();

		return (
			<>
				{/* put lock-overlay outside page-container, since page-container's filter-css otherwise keeps overlay from covering the whole viewport */}
				{uiState.locked && <LockOverlay/>}
				{uiState.canvasLocked && <CanvasOverlay/>}
				<PageContainer preset={JSessionUI_Full() ? "full" : "text"}
					ref={c=>{
						const el = GetDOM(c) as HTMLElement|n;
						rootRef["current" as any] = el; // force-set
					}}
					style={E(
						{position: "relative", padding: 0, width: "100%", height: "100%"},
						JSessionUI_Full() && {
							height: "calc(100% - 30px)",
							//background: `rgba(0,0,0,.6)`,
							background: `rgba(0,0,0,.8)`,
						},
						!largeVersion && {margin: "0 auto 0 auto"},
					)}
				>
					<JBanner/>
					<DreamPeriodsUI/>
					{uiState.showBottomPanel && <JBottomPanel/>}
				</PageContainer>
			</>
		);
	}
}

export function JSessionUI_Full() {
	const uiState = store.main.tools.journey;
	if (uiState.bottomPanelSubpanel == BottomPanelSubpanel.llm && uiState.llm.showLLMOutputOnSide) return true;
	return false;
}