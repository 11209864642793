import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {E} from "js-vextensions";
import {Entity} from "../../Store/firebase/entities/@Entity";
import {Scene} from "../../Store/firebase/scenes/@Scene.js";

export class PreviewImageSquare extends BaseComponent<{entry: Scene|Entity, allowExtThumbnailing: boolean, applyPosAndZoom: boolean, style?: any, wrapper?: boolean}, {}> {
	static defaultProps = {allowExtThumbnailing: true, applyPosAndZoom: false, wrapper: true};
	render() {
		const {entry, allowExtThumbnailing, applyPosAndZoom, style, wrapper} = this.props;
		const square_center = (applyPosAndZoom ? entry.square_center : null) || {x: .5, y: .5};
		const square_size = (applyPosAndZoom ? entry.square_size : null) || 1;
		const square_zoom = 1 / (square_size || 1);
		
		let imageURL_final = entry.url ?? "";
		// if allowed by caller, and image is from service that supports getting a "thumbnail" version, use that (to reduce bandwidth costs just for the preview)
		if (allowExtThumbnailing) {
			if (imageURL_final.includes("//i.imgur.com/")) {
				const extDotIndex = imageURL_final.lastIndexOf(".");
				// s = Small Square (90×90), b = Big Square (160×160), t = Small Thumbnail (160×160), m = Medium Thumbnail (320×320), l = Large Thumbnail (640×640), h = Huge Thumbnail (1024×1024)
				imageURL_final = imageURL_final.slice(0, extDotIndex) + "s" + imageURL_final.slice(extDotIndex);
			}
		}

		// have embedded image override the above, if present
		if ((entry as Entity).image_embedded) {
			imageURL_final = (entry as Entity).image_embedded!;
		}

		const img = <img src={imageURL_final} style={{
			position: "absolute", objectFit: "contain",
			//left: `${(.5 - image.square_offset.x) * 100}%`, top: `${(.5 - image.square_offset.y) * 100}%`,
			left: `${(.5 - (square_center.x * square_zoom)) * 100}%`, bottom: `${(.5 - (square_center.y * square_zoom)) * 100}%`,
			width: `${square_zoom * 100}%`, height: `${square_zoom * 100}%`,
		}}/>;
		if (!wrapper) return img;

		return (
			<div style={E({position: "relative", width: 100, height: 100, backgroundColor: "white", overflow: "hidden"}, style)}>
				{img}
			</div>
		);
	}
}