import {EEGReading} from "muse-js";

export const Muse_eegSamplesPerSecond_raw = 256; // consistently observed (and from: https://mind-monitor.com/FAQ.php)
export const EEGSet_sampleCount = 12; // consistently observed (and from muse-js ts-interface comment)

/** Warning: Interval is small enough (3.90625ms), that timestamp+interval addition results in rounding. See SampleHelpers.ts for more info. */
export const EEGSample_interval = 1000 / Muse_eegSamplesPerSecond_raw; // 3.90625ms

export type EEGSet = {
	index: number; // from "index" prop
	//resolveTime: number;
	sourceTime: number;
	processTime: number;
	left: EEGReading;
	right: EEGReading;
	left_ear: EEGReading;
	right_ear: EEGReading;
}
export type EEGSample = {
	//time_uplot?: number; // if not specified when adding to EEGProcessor, current time will be used
	time: number;
	left: number;
	right: number;
	// these fields only exist for live session; for saved sessions, ear eeg values are only read by backend (for sleep-stage calculation)
	left_ear?: number;
	right_ear?: number;
};
export type EEGListener = (sample: EEGSample)=>void;