import {AddSchema} from "mobx-firelink";


export class Setting<T> {
	constructor(initialValues: T[], otherData?: Partial<Setting<T>>) {
		this.values = initialValues.map(value=>({value}));
		Object.assign(this, otherData);
	}
	values: SettingValue<T>[];
}
AddSchema("Setting", {
	properties: {
		values: {items: {$ref: "SettingValue"}},
	},
});

export class SettingValue<T> {
	value: T;
	disabled?: boolean|n; // null = false
	weight?: number|n; // null = 1
}
AddSchema("SettingValue", {
	properties: {
		value: {},
		disabled: {type: ["null", "boolean"]},
		weight: {type: ["null", "number"]},
	},
});