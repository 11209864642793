import {YoutubePlayerState} from "web-vcore";
import {liveFBASession} from "../../../Engine/FBASession.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {PlaySound_ByContentUri_ForLiveSession, PlaySound_StopAll, PlaySound_StopAll_extraListeners} from "../../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {EffectPlayer, EffectPlayExtras} from "../../../Utils/EffectPlayers/EffectPlayer.js";
import {LightPlayer} from "../../../Utils/EffectPlayers/LightPlayer.js";
import {ScriptPlayer} from "../../../Utils/EffectPlayers/ScriptPlayer.js";
import {ShakePlayer} from "../../../Utils/EffectPlayers/ShakePlayer.js";
import {SoundPlayer} from "../../../Utils/EffectPlayers/SoundPlayer.js";
import {NotifyNonTTSUtteranceEnd, NotifyNonTTSUtteranceStart_FromSoundFile} from "../../../Utils/Services/TTS.js";
import {GetCache} from "../../main/cache.js";
import {GetLights_WithUserTag} from "../lights.js";
import {LightType} from "../lights/@Light.js";
import {GetScripts_WithUserTag} from "../scripts.js";
import {GetShakes_WithUserTag} from "../shakes.js";
import {GetSounds_WithUserTag} from "../sounds.js";
import {EffectPointer} from "./EffectPointer.js";
import {GeneralComp} from "../../../Engine/FBASession/Components/GeneralComp.js";

export enum VLogLevel {
	VERBOSE = 2,
	DEBUG = 3,
	INFO = 4,
	WARN = 5,
	ERROR = 6,
	ASSERT = 7,
}
/** Useful for info that is not important enough to add session-events for, but which the user might still want to keep accessible for a while. (android log lines get saves to a rotating log-file) */
export function Android_VLog(level: VLogLevel, message: string, tag = "Android_VLog") {
	nativeBridge.Call("VLog_println", level, tag, message);
}

export class EffectPointerPlayer extends EffectPlayer {
	soundPlayer = new SoundPlayer();
	shakePlayer = new ShakePlayer();
	lightPlayer = new LightPlayer();
	scriptPlayer = new ScriptPlayer();

	effectPointer: EffectPointer|n;

	OnStart() {
		if (this.effectPointer?.soundEffectTag) {
			this.soundPlayer.sound = GetSounds_WithUserTag(this.effectPointer.soundEffectTag).Random();
		} else if (this.effectPointer?.shakeEffectTag) {
			this.shakePlayer.shake = GetShakes_WithUserTag(this.effectPointer.shakeEffectTag).Random();
		} else if (this.effectPointer?.lightEffectTag) {
			this.lightPlayer.light = GetLights_WithUserTag(this.effectPointer.lightEffectTag).Random();
		} else if (this.effectPointer?.scriptEffectTag) {
			this.scriptPlayer.script = GetScripts_WithUserTag(this.effectPointer.scriptEffectTag).Random();
		}
	}

	async Play(intensity?: number, extras?: Partial<EffectPlayExtras>): Promise<void> {
		if (this.effectPointer == null) return;
		return await this.Play_Advanced(this.effectPointer, intensity, extras);
	}
	private async Play_Advanced(effect: EffectPointer, intensity?: number, extras?: Partial<EffectPlayExtras>): Promise<void> {
		// effects in database
		if (effect.soundEffectTag) {
			if (this.soundPlayer.sound == null) return;
			if (!this.soundPlayer.prepared) {
				await this.soundPlayer.PrepareToPlay();
				/*if (this.promptSoundPlayer.youtubePlayer && this.c.loop) {
					this.promptSoundPlayer.youtubePlayer.loop = true;
				}*/
			}
		
			// if youtube-player sound, and it's already started (and looping), then just adjust volume
			if (this.soundPlayer.youtubePlayer && [YoutubePlayerState.PLAYING, YoutubePlayerState.BUFFERING].Contains(this.soundPlayer.youtubePlayer.state) && this.soundPlayer.youtubePlayer.loop) {
				this.soundPlayer.SetLiveVolume(intensity ?? 1);
				//this.UpdatePrompt_ForReducedIntensity();
			} else {
				await this.soundPlayer.Play(intensity, extras);
			}
		} else if (effect.shakeEffectTag) {
			await this.shakePlayer.Play(intensity, extras);
		} else if (effect.lightEffectTag) {
			await this.lightPlayer.Play(intensity, extras);
		} else if (effect.scriptEffectTag) {
			await this.scriptPlayer.Play(intensity, extras);
		}

		// inline effects
		if (effect.soundFile) {
			const entry = effect.soundFile;
			const runCount = entry.runCount ?? 1;
			const finalVolume = (entry.volume ?? 1) * (intensity ?? 1);

			let canceled = false;
			const listener = ()=>canceled = true;
			PlaySound_StopAll_extraListeners.push(listener);

			for (let i = 0; i < runCount; i++) {
				const isLastRun = i == runCount - 1;
				const audioFileToPlay = GetCache().audioFiles.filter(file=>{
					if (!file.filePath.includes(entry.filePath)) return false;
					if (entry.subpathExclusiveToLastRun && !isLastRun && file.filePath.toLowerCase().includes(entry.subpathExclusiveToLastRun.toLowerCase())) return false;
					if (entry.subpathExclusiveToLastRun && isLastRun && !file.filePath.toLowerCase().includes(entry.subpathExclusiveToLastRun.toLowerCase())) return false;
					return true;
				}).Random();
				
				if (audioFileToPlay) {
					const playCount = (isLastRun ? entry.lastRun_playCount : null) ?? 1;
					for (let i = 0; i < playCount; i++) {
						const utteranceID = NotifyNonTTSUtteranceStart_FromSoundFile(audioFileToPlay.filePath, entry.wordsIgnoreGroup, Date.now());
						await PlaySound_ByContentUri_ForLiveSession(audioFileToPlay.contentUri, finalVolume, entry.durationLimit ?? -1, entry.loop ?? true);
						if (utteranceID) NotifyNonTTSUtteranceEnd(utteranceID);
					}
				}
				SessionLog(`Played audio file: ${audioFileToPlay?.filePath} @runIndex:${i}`);
				Android_VLog(VLogLevel.INFO, `Played audio file: ${audioFileToPlay?.filePath} @runIndex:${i}`);

				if (canceled) break;
			}

			if (effect.afterEffect) {
				await this.Play_Advanced(effect.afterEffect, intensity, extras);
			}
			
			PlaySound_StopAll_extraListeners.Remove(listener);
		}
	}
	async Stop() {
		// effects in database
		if (this.effectPointer?.soundEffectTag) {
			// we need to add an explicit call to Stop() here, with disownInternalPlayer=true, since the call in StopAlarm() does not do that disowning
			await this.soundPlayer.Stop(true);
		} else if (this.effectPointer?.shakeEffectTag) {
			await this.shakePlayer.Stop();
		} else if (this.effectPointer?.lightEffectTag) {
			await this.lightPlayer.Stop();
			if (this.lightPlayer.light?.type == LightType.Screen && liveFBASession?.resetScreenLightPlayer.light) {
				liveFBASession.resetScreenLightPlayer.Play();
			}
		} else if (this.effectPointer?.scriptEffectTag) {
			await this.scriptPlayer.Stop();
		}

		// inline effects
		// commented, till more precise stopping method is implemented
		/*if (this.effectPointer.soundFile) {
			PlaySound_StopAll();
		}*/
	}

	NotifyReducedIntensity(intensity: number) {
		if (this.effectPointer?.soundEffectTag) {
			this.soundPlayer.SetLiveVolume(intensity);
		} else if (this.effectPointer?.shakeEffectTag) {
			// do nothing (we can't adjust an existing shake-prompt, and duration too short for weaker-restart to be worthwhile)
		} else if (this.effectPointer?.lightEffectTag) {
			if (this.lightPlayer.light?.temp) {
				// if existing prompt is still playing, then re-play with the new (lower) intensity
				//	(else, do nothing, since we don't want intensity-decay to ever increase effect noticability)
				if (this.lightPlayer.playState != "stopped") {
					this.Play(intensity);
				}
			} else {
				// just play light-config again; will just update the intensity
				this.Play(intensity);
			}
		} else if (this.effectPointer?.scriptEffectTag) {
			// if existing prompt is still playing, then re-play with the new (lower) intensity
			//	(else, do nothing, since we don't want intensity-decay to ever increase effect noticability)
			if (this.scriptPlayer.playState != "stopped") {
				this.Play(intensity);
			}
		}
	}
}