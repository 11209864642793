import {O} from "web-vcore";
import {VRect} from "js-vextensions";
import {makeObservable} from "mobx";

export enum CameraType {
	Webcam = 10,
	IPCamera = 20,
}
export enum StreamFindType {
	FindDevice_FindStream = 10,
	SetDevice_FindStream = 20,
	SetStream = 30,
}
export type ChannelsType = "rgb" | "r" | "g" | "b";
export const ChannelsType_options = [
	{name: "All (RGB)", value: "rgb"},
	{name: "Red (R)", value: "r"},
	{name: "Green (G)", value: "g"},
	{name: "Blue (B)", value: "b"},
];

export const TransportProtocol_values = ["tcp", "udp", "udp_multicast", "http"] as const;
export type TransportProtocol = typeof TransportProtocol_values[number];

export const FFMpegLogLevel_values = [
	"quiet",
	"panic",
	"fatal",
	"error", // our default
	"warning",
	"info", // ffmpeg's default
	"verbose",
	"debug",
	"trace",
] as const;
export type FFMpegLogLevel = typeof FFMpegLogLevel_values[number];

export class CameraConfig {
	constructor() { makeObservable(this); }
	
	@O type = CameraType.Webcam;

	// if ip-camera
	@O streamFindType = StreamFindType.FindDevice_FindStream;
	@O deviceURI = "192.168.0.X:XXXX"; // if SetDevice_FindStream
	@O streamURI = "rtsp://192.168.0.X:XXXX/XXX"; // if SetStream
	@O username = "";
	@O password = "";

	@O resolution_width = 1280;
	@O resolution_height = 720;
	//@O resolution_height_auto = true;
	@O framerate = 10;
	@O channels = "rgb" as ChannelsType;
	//@O keepAlive = 60;

	// advanced options
	@O freezeDetectTime = 10;
	@O reconnectDelay = 65;
	@O transportProtocol = "tcp" as TransportProtocol;
	@O logLevel = "error" as FFMpegLogLevel;
	@O ffmpegOverride_enabled = false;
	@O ffmpegOverride_command?: string;

	@O ignoreRects: VRect[] = [];
}