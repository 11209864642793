import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export class FBAConfig_DreamRecall {
	constructor(initialData: Partial<FBAConfig_DreamRecall>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	prevDream_triggerSet: TriggerSet;
	nextDream_triggerSet: TriggerSet;
	randomDream_triggerSet: TriggerSet;
	lastDream_triggerSet: TriggerSet;
	stopVoice_triggerSet: TriggerSet;
	
	dreamNarration_enabled: boolean;
	//dreamNarration_globalMusicVolume: string;
	dreamNarration_voiceSoundTag: string;
	dreamNarration_volumeMultiplier: number;
	dreamNarration_segmentMinEntities: number;
}
AddSchema("FBAConfig_DreamRecall", {
	properties: {
		enabled: {type: "boolean"},

		prevDream_triggerSet: {$ref: "TriggerSet"},
		nextDream_triggerSet: {$ref: "TriggerSet"},
		randomDream_triggerSet: {$ref: "TriggerSet"},
		lastDream_triggerSet: {$ref: "TriggerSet"},
		stopVoice_triggerSet: {$ref: "TriggerSet"},

		dreamNarration_enabled: {type: "boolean"},
		//dreamNarration_globalMusicVolume: {type: "string"},
		dreamNarration_voiceSoundTag: {type: "string"},
		dreamNarration_volumeMultiplier: {type: "number"},
		dreamNarration_segmentMinEntities: {type: "number"},
	},
});