import {store} from "../../Store/index.js";
import {LD1Config} from "../../Store/main/tools/monitor.js";
import {SendCommandsToLD1, SendCommandToLD1} from "../../UI/Tools/Monitor/LD1Panel.js";
import {autorun} from "mobx";

// must declare these constants before autorun, since it's used by the autorun (which runs as soon as its lines are executed)
const lengthOfExtraJSONRequiredToSendEachConfigPart = JSON.stringify({setConfig: {config: {}}}).length;

autorun(()=>{
	const config = store.main.tools.monitor.ld1.config1;
	// stringify the config (just to make sure we access everything, to have that data subscribed-to)
	const configJSON = JSON.stringify(config);
	SendLD1ConfigToDevice();
}, {name: "SendLD1ConfigToDevice"});

export function SendLD1ConfigToDevice() {
	const config = store.main.tools.monitor.ld1.config1;
	const config_cleaned = config.IncludeKeys(...Object.keys(new LD1Config()) as any); // exclude any "old fields" on config from being sending
	const config_parts = SplitObjIntoPartsWhoseJSONAreXLengthOrUnder(config_cleaned, 512 - lengthOfExtraJSONRequiredToSendEachConfigPart, "isLastPart");
	/*for (const config_part of config_parts) {
		SendCommandToLD1({setConfig: {config: config_part}});
	}*/
	// Send commands all in one call, so that their relative ordering is reliably preserved through js-frontend -> java-backend bridge.
	SendCommandsToLD1(config_parts.map(config_part=>({setConfig: {config: config_part}})));
}

export function SplitObjIntoPartsWhoseJSONAreXLengthOrUnder(obj: Object, maxLength: number, keyForIsLastPart: string) {
	const result = [] as any[];
	let curPart = {[keyForIsLastPart]: false};
	for (const [key, value] of Object.entries(obj)) {
		// if adding this field fits within the current-part's max-length, add it; then continue to next field
		if (JSON.stringify({...curPart, [key]: value}).length <= maxLength) {
			curPart[key] = value;
		}
		// else, push the current-part, then start a new one, with this field as its first field
		else {
			result.push(curPart);
			curPart = {[keyForIsLastPart]: false, [key]: value};
		}
	}
	curPart[keyForIsLastPart] = true; // modify last-part to indicate that it's the last part
	result.push(curPart);
	return result;
}