import {ToJSON, Assert, IsString} from "js-vextensions";
import {KJUR} from "jsrsasign";
import {RobustFetch} from "./RobustFirebaseSet";

export class ClaimSet {
	constructor(scopes: string[], issuerEmail: string) {
		this.scopes = scopes;
		this.issuerEmail = issuerEmail;
		//this.audienceURL = "https://accounts.google.com/o/oauth2/token";
		this.audienceURL = "https://www.googleapis.com/oauth2/v4/token";
		this.duration = 60 * 60 * 1000;
	}

	scopes: string[];
	issuerEmail: string;
	delegationEmail?: string;
	audienceURL: string;
	issuedAtTime?: number; // milliseconds since unix-epoch
	duration: number; // milliseconds (one hour max)

	ToSpecClaimSet() {
		const issuedAtTime = this.issuedAtTime || Date.now();
		const expireAtTime = issuedAtTime + this.duration;
		return {
			scope: this.scopes.join(" "),
			iss: this.issuerEmail,
			sub: this.delegationEmail,
			aud: this.audienceURL,
			iat: Math.floor(issuedAtTime / 1000),
			exp: Math.floor(expireAtTime / 1000),
		};
	}
}

function GetAssertion(claimSet: ClaimSet, privateKey: string) {
	var header = {alg: "RS256", typ: "JWT"};

	//let jws = new KJUR.jws.JWS();
	/*const prv = KEYUTIL.getKey(privateKey);
	return KJUR.jws.JWS.sign(header.alg, header, claimSet, prv);*/
	return KJUR.jws.JWS.sign(header.alg, header, claimSet.ToSpecClaimSet(), privateKey);
}

export const claimSetPlusPK_cachedData = {} as {
	[key: string]: {accessToken: string, cacheTime: number};
};
export async function GetAccessToken(claimSet: ClaimSet, privateKey: string) {
	const claimSetPlusPK_json = JSON.stringify({claimSet, privateKey});

	// if access-token is not cached, or cached token is expired (expires after 60m, but we get new one at 59m to be safe)
	const cachedData = claimSetPlusPK_cachedData[claimSetPlusPK_json];
	if (cachedData == null || Date.now() - cachedData.cacheTime > 59 * 60 * 1000) {
		var assertion = GetAssertion(claimSet, privateKey);
		const response = await RobustFetch("https://www.googleapis.com/oauth2/v4/token", {
			method: "POST",
			headers: {
				//"content-type": "application/x-www-form-urlencoded",
				"content-type": "application/json",
			},
			//body: `grant_type=urn:ietf:params:oauth:grant-type:jwt-bearer&assertion=${assertion}`,
			body: ToJSON({
				grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
				assertion,
			}),
		});
		const responseJSON = await response.json();
		Assert(IsString(responseJSON.access_token) && responseJSON.access_token.length > 10, `Failed to get access token. Instead got: ${responseJSON}`);
		console.log(`Got access token: ${responseJSON.access_token}`);
		claimSetPlusPK_cachedData[claimSetPlusPK_json] = {accessToken: responseJSON.access_token, cacheTime: Date.now()};
	}

	return claimSetPlusPK_cachedData[claimSetPlusPK_json].accessToken;
}