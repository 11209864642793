import {AddSchema} from "mobx-firelink";

export class TimelineEvent {
	constructor(initialData: Partial<TimelineEvent>) {
		this.VSet({endTime: null}); // start these props as null (not undefined/absent), so entry's indexed on it (enabling find-by-query)
		this.VSet(initialData);
	}

	_key: string;
	//creator: string; // timeline prop serves same role
	createdAt: number;

	timeline: string;
	activity: string;
	startTime: number;
	endTime: number;
	// probably todo: add "week" field, enabling finding events in a given week, using db-based filtering (queries can only use one range operator)

	sessionID: string;
}
AddSchema("TimelineEvent", {
	properties: {
		createdAt: {type: "number"},

		timeline: {type: "string"},
		activity: {type: "string"},
		startTime: {type: "number"},
		endTime: {type: ["null", "number"]},

		// if "Session" activity
		sessionID: {type: "string"},
	},
	required: ["timeline", "activity", "startTime", "createdAt"],
});