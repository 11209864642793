import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";
import {EffectPointer} from "../../@Shared/EffectPointer.js";

export class FBAConfig_ConceptLink {
	constructor(initialData: Partial<FBAConfig_ConceptLink>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	nextSequence_triggerSet: TriggerSet;
	prevSequence_triggerSet: TriggerSet;
	playAudio_triggerSet: TriggerSet;
	randomizeAudio_triggerSet: TriggerSet;

	voiceSoundTag: string;
	volumeMultiplier: number;

	// dream event-sequence selection
	lucidSegmentsOnly: boolean;
	segmentMinEvents: number;
	eventMaxWords: number;
	sequenceSize: number;
	recencyBoosts: {[key: string]: number};

	// audio files
	soundFolder: string;
	soundVolume: number;
	soundDurationLimit: number;
	effect2: EffectPointer;
	effect2_delay: number;
	effect2_chance: number;
	effect2_chanceAsBuildup: boolean;

	// solver
	solver_enabled: boolean;
	solver_minSequences: number;
	solver_markProgressBeforeCompletion: boolean;
	solver_maxTimeForProgress: number; // in seconds
}
AddSchema("FBAConfig_ConceptLink", {
	properties: {
		enabled: {type: "boolean"},

		nextSequence_triggerSet: {$ref: "TriggerSet"},
		prevSequence_triggerSet: {$ref: "TriggerSet"},
		playAudio_triggerSet: {$ref: "TriggerSet"},
		randomizeAudio_triggerSet: {$ref: "TriggerSet"},
		signalRC_triggerSet: {$ref: "TriggerSet"},

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},

		lucidSegmentsOnly: {type: "boolean"},
		segmentMinEvents: {type: "number"},
		eventMaxWords: {type: "number"},
		sequenceSize: {type: "number"},
		recencyBoosts: {patternProperties: {"[0-9]+": {type: "number"}}},

		soundFolder: {type: "string"},
		soundVolume: {type: "number"},
		soundDurationLimit: {type: "number"},
		effect2: {type: "object"},
		effect2_delay: {type: "number"},
		effect2_chance: {type: "number"},
		effect2_chanceAsBuildup: {type: "boolean"},

		solver_enabled: {type: "boolean"},
		solver_minSequences: {type: "number"},
		solver_markProgressBeforeCompletion: {type: "boolean"},
		solver_maxTimeForProgress: {type: "number"},
	},
});