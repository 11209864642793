import {Command, AssertV, AssertValidate, GetSchemaJSON, NewSchema} from "mobx-firelink";
import {GetUser} from "../../Store/firebase/users";
import {UserHidden} from "../../Store/firebase/users_private/@UserHidden";
import {GetUserHidden} from "../../Store/firebase/users_private";

type MainType = UserHidden;
const MTName = "UserHidden";

export class UpdateUserHidden extends Command<{id: string, updates: Partial<MainType>}, {}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate({
			properties: {
				id: {type: "string"},
				updates: NewSchema({
					properties: GetSchemaJSON(MTName)["properties"]!.IncludeKeys(
						"email", "providerData",
						//"backgroundID", "backgroundCustom_enabled", "backgroundCustom_color", "backgroundCustom_url", "backgroundCustom_position",
						"customGridExtractor_enabled", "customGridExtractor_regex", "gridEntityNames",
					),
				}),
			},
			required: ["id", "updates"],
		}, this.payload, "Payload invalid");

		const {id, updates} = this.payload;
		this.oldData = GetUserHidden.NN(id);
		this.newData = {...this.oldData, ...updates};
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`users_private/${id}`] = this.newData;
		return updates;
	}
}