import {Assert, E, IsNaN, ModifyString, Range, Timer, WaitXThenRun, CreateRandFunc_Mulberry32, GetRandomNumber} from "js-vextensions";
import {autorun, runInAction} from "mobx";
import {CheckBox, Row, Text, Column} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../../Store/index.js";
import {GetSelectedFBAConfig} from "../../../../Store/firebase/fbaConfigs";
import {FBAConfig} from "../../../../Store/firebase/fbaConfigs/@FBAConfig";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native";
import {OnStoreLoaded} from "../../../../Utils/General/GlobalHooks";
import {HSL, InfoButton, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {ES} from "../../../../Utils/UI/GlobalStyles";
import {Muse_gyroSamplesPerSecond_raw, GyroSample, GyroSample_interval} from "../../../../UI/Tools/@Shared/MuseInterface/GyroStructs";
import {currentMuse} from "../../@Shared/MuseInterface";
import {GyroChart_Live} from "./GyroPanel/GyroChart_Live";
import {GyroProcessor} from "../../../@Shared/Processors/GyroProcessor";

export const GyroPanel_percentOfSamplesGraphed = InAndroid(0) ? .1 : 1;
export const GyroPanel_graphedSamplesPerSecond = Muse_gyroSamplesPerSecond_raw * GyroPanel_percentOfSamplesGraphed;

export const GyroPanel_chartWidthInSeconds = 10;
export const GyroPanel_graphedSamplesPerChartWidth = GyroPanel_graphedSamplesPerSecond * GyroPanel_chartWidthInSeconds;

let fakeGyroGenerator_rand: ()=>number;
OnStoreLoaded(()=>autorun(()=>{
	fakeGyroGenerator_rand = CreateRandFunc_Mulberry32(store.main.settings.fakeSampleGeneratorSeed);
}));
//const maxForRandom = 100; // while gyro-values can go to 360 (presumably), they trend toward low numbers, so do the same for generator
const maxForRandom = 20; // temporarily low, to try to reproduce gyro-motion-not-being-detected issue 
let lastFakeGyroSample: GyroSample;
const fakeGyroGenerator = new Timer(GyroSample_interval, ()=>{
	const sample = {
		time: lastFakeGyroSample ? lastFakeGyroSample.time + GyroSample_interval : Date.now().FloorTo(GyroSample_interval),
		x: GetRandomNumber({min: 0, max: maxForRandom, mustBeInteger: false, randFunc: fakeGyroGenerator_rand}),
		y: GetRandomNumber({min: 0, max: maxForRandom, mustBeInteger: false, randFunc: fakeGyroGenerator_rand}),
		z: GetRandomNumber({min: 0, max: maxForRandom, mustBeInteger: false, randFunc: fakeGyroGenerator_rand}),
	} as GyroSample;
	for (const listener of currentMuse.gyroListeners) {
		listener(sample);
	}
	lastFakeGyroSample = sample;
});

@Observer
export class GyroPanel_ControlUI extends BaseComponentPlus({}, {}) {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.monitor;
		return (
			<Row ml="auto">
				{store.main.settings.fakeSampleGeneratorSeed != -1 &&
					<CheckBox ml={10} text="Test" value={uiState.gyro_generateFake} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.gyro_generateFake = val);
						if (val) {
							fakeGyroGenerator.Start();
						} else {
							fakeGyroGenerator.Stop();
						}
					}}/>}
				<Text ml={10}>Gyro:</Text>
				<CheckBox ml={5} text="Normalize" value={uiState.gyro_normalize} onChange={val=>RunInAction_Set(this, ()=>uiState.gyro_normalize = val)}/>
				<InfoButton ml={5} text="Normalization settings are borrowed from the engine config's gyro section."/>
				<CheckBox ml={5} text="Smooth" value={uiState.gyro_smooth} onChange={val=>RunInAction_Set(this, ()=>uiState.gyro_smooth = val)}/>
				<InfoButton ml={5} text="Smoothing settings are borrowed from the engine config's gyro section."/>
				{/*<Text ml={10}>Show:</Text>
				<CheckBox ml={5} text="Combined deviation" value={uiState.gyro_show_combinedDeviation} onChange={val=>RunInAction_Set(this, ()=>uiState.gyro_show_combinedDeviation = val)}/>
				<CheckBox ml={5} text="Trigger-sample %" value={uiState.gyro_show_triggerSamplePercent} onChange={val=>RunInAction_Set(this, ()=>uiState.gyro_show_triggerSamplePercent = val)}/>*/}
			</Row>
		);
	}
}

@Observer
export class GyroPanel extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.tools.monitor;
		const tracker1 = uiState.gyroProcessor_changedAt;
		return (
			<GyroChart_Live processor={monitorGyroProcessor} viewRange={GyroPanel_chartWidthInSeconds}/>
		);
	}
}

export let monitorGyroProcessor = new GyroProcessor({});
OnStoreLoaded(()=>{
	autorun(()=>{
		const uiState = store.main.tools.monitor;
		const engineConfig = GetSelectedFBAConfig();
		if (engineConfig == null) return;
		const newOptions = E(engineConfig.gyro, {
			samplesProcessedPerSecond: Muse_gyroSamplesPerSecond_raw,
			calc_normalize: true, // always calc, so derivatives below match engine-runtime
			calc_smooth: true, // always calc, so derivatives below match engine-runtime
			calc_combinedDeviation: true,
			calc_triggerSamplePercent: true,

			uplotData_normalize: uiState.gyro_normalize,
			uplotData_smooth: uiState.gyro_smooth,

			clearOutOfRangeSamples: true,
		});

		//monitorGyroProcessor.options = newOptions;
		monitorGyroProcessor = new GyroProcessor({options: newOptions});
		monitorGyroProcessor.maxSampleDependencyDist = monitorGyroProcessor.maxSampleDependencyDist.KeepAtLeast(GyroPanel_graphedSamplesPerChartWidth);
		WaitXThenRun(0, ()=>RunInAction("recreate_monitorGyroProcessor", ()=>store.main.tools.monitor.gyroProcessor_changedAt = Date.now()));
	});
});