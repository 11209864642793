import {shuffle} from "lodash";
import {DreamQuiz_QuestionType, FBAConfig_DreamQuiz} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {NarrateText_ForEngineComp} from "../../../../Utils/Services/TTS.js";
import {FBASession} from "../../../FBASession.js";
import {DreamQuizComp} from "../DreamQuizComp.js";
import {Assert, GetRandomNumber, Range, SleepAsync} from "js-vextensions";
import {GetDreamEvents, GetValidQuizSegments} from "../../../../Store/firebase/journalEntries.js";
import {JournalSegment} from "../../../../Store/firebase/journalEntries/@JournalEntry.js";
import {AddNotificationMessage} from "web-vcore";
import {autorun} from "mobx";

export class DreamQuizSubcomp {
	constructor(dreamQuizComp: DreamQuizComp) {
		this.s = dreamQuizComp.s;
		this.c = dreamQuizComp.c;
		this.p = dreamQuizComp;
		
		this.dbAutorun_disposer = autorun(()=>{
			this.validQuizSegments = GetValidQuizSegments(this.c.lucidSegmentsOnly, this.c.segmentMinEvents, this.c.eventMaxWords, this.c.questionType);
			this.validQuizSegments_allEvents = this.validQuizSegments.SelectMany(a=>GetDreamEvents(a, this.c.eventMaxWords));
		});
	}
	s: FBASession;
	c: FBAConfig_DreamQuiz;
	p: DreamQuizComp;

	async SubmitPick() {
		if (this.options_targetIndex == -1) return;
		if (this.options_currentIndex == this.options_targetIndex) {
			this.s.AsLocal!.AddEvent({type: "DreamQuiz.TargetHit"});
			const sleepCycleInfo = this.s.AsLocal!.GetSleepCycleInfo();
			if (this.c.targetCountPerCycle?.length > 0) {
				const targetCountThisCycle = this.c.targetCountPerCycle[sleepCycleInfo.cycleNumber - 1] ?? 0;
				const targetsLeftThisCycle = targetCountThisCycle - sleepCycleInfo.eventsThisCycle.filter(a=>a.type == "DreamQuiz.TargetHit").length;
				this.p.NarrateText(`Good, ${targetsLeftThisCycle.KeepAtLeast(0)} left.`);
			} else {
				this.p.NarrateText("Good.");
			}

			await SleepAsync(1000);
			this.p.GoNextPrompt(true);
		} else {
			this.p.NarrateText("Wrong.");
			// only record a "miss" event if option actually exists at current index ("submit" of an empty option would just be a mistaken press)
			if (this.options[this.options_currentIndex] != null) {
				this.s.AsLocal!.AddEvent({type: "DreamQuiz.TargetMiss"});
			}
		}
	}
	NextOption() {
		if (this.options_targetIndex == -1) return;
		this.options_currentIndex = (this.options_currentIndex + 1).KeepBetween(-1, this.options.length);
		const isEdgeHelper = !this.options_currentIndex.IsBetween(0, this.options.length - 1);
		if (isEdgeHelper) this.NarrateCurrentHint();
		else this.NarrateCurrentOption();
	}
	PrevOption() {
		if (this.options_targetIndex == -1) return;
		this.options_currentIndex = (this.options_currentIndex - 1).KeepBetween(-1, this.options.length);
		const isEdgeHelper = !this.options_currentIndex.IsBetween(0, this.options.length - 1);
		if (isEdgeHelper) this.NarrateCurrentHint();
		else this.NarrateCurrentOption();
	}
	SkipPick() {
		this.s.AsLocal!.AddEvent({type: "DreamQuiz.TargetGiveUp"});
		this.p.GoNextPrompt(true);
	}

	CurrentSegmentAndDerivatives() {
		const segment = this.validQuizSegments[this.currentSegment_index];
		const segmentEvents = GetDreamEvents(segment, this.c.eventMaxWords);
		const hintEvents = this.GetEventsForEventRange(segmentEvents, this.currentSegment_hintEventRange);
		const targetEvents = this.GetEventsForEventRange(segmentEvents, this.currentSegment_targetEventRange);
		const currentOption = this.options[this.options_currentIndex];
		if (hintEvents.Any(a=>a == null)) AddNotificationMessage("Hint event missing/incomplete.");
		if (targetEvents.Any(a=>a == null)) AddNotificationMessage("Target event missing/incomplete.");
		//if (currentOption == null) AddNotificationMessage("Current option missing/incomplete.");
		return {segment, segmentEvents, hintEvents, targetEvents, currentOption};
	}
	GetEventsForEventRange(segmentEvents: string[], eventRange: EventRange|n) {
		if (eventRange == null) return [];
		return Range(eventRange.first, eventRange.after - 1).map(index=>segmentEvents[index]);
	}
	GetTextForEventRange(eventsPool: string[], eventRange: EventRange|n) {
		return this.GetTextForEvents(this.GetEventsForEventRange(eventsPool, eventRange));
	}
	GetTextForEvents(events: string[]) {
		return events.join("; THEN: ");
	}
	async NarrateCurrentHint() {
		const {segment, hintEvents, targetEvents} = this.CurrentSegmentAndDerivatives();
		if (segment == null || hintEvents.length == 0) return;
		//const text = `HINT: ${this.GetTextForEvents(hintEvents)}`;
		let text: string;
		if (this.c.questionType == DreamQuiz_QuestionType.comesAfter) {
			text = [
				this.GetTextForEvents(hintEvents),
				!this.c.questionType_invert ? "; PRECEDES: " : "; FOLLOWS: ",
				this.GetTextForEvents(targetEvents),
			].join("");
		} else {
			text = [
				this.GetTextForEvents(hintEvents),
				!this.c.questionType_invert ? "; IS NEAR..." : "; ISN'T NEAR...",
			].join("");
		}
		await this.p.NarrateText(text);
	}
	async NarrateCurrentOption() {
		const {segment, currentOption} = this.CurrentSegmentAndDerivatives();
		if (segment == null || currentOption == null) return;
		const needsNumberVoiced = this.c.questionType != DreamQuiz_QuestionType.comesAfter;
		const text = [
			needsNumberVoiced && `${this.options_currentIndex + 1}: `,
			currentOption.text,
		].filter(a=>a).join("");
		this.p.NarrateText(text);
	}

	dbAutorun_disposer: ()=>void;
	validQuizSegments: JournalSegment[];
	// todo: *maybe* change this to be calc'ed in SelectNew... func, and be limited to only within validQuizSegments_final (debateable)
	validQuizSegments_allEvents: string[];
	currentSegment_index = -1;
	currentSegment_hintEventRange: EventRange|n;
	currentSegment_targetEventRange: EventRange|n;
	options = [] as QuizOption[];
	options_targetIndex = -1;
	options_currentIndex = -1;
	SelectNewCurrentSegmentAndDerivatives() {
		const onFailed = ()=>{
			throw new Error(`
				Failed to find valid segment+derivatives.
				Do you have enough journal entries, and a high enough min-segment-count to find enough valid options?
			`.AsMultiline(0));
		};
		if (this.validQuizSegments.length == 0) onFailed();

		let loopSucceeded = false;
		tryLoop: for (let tryIndex = 0; tryIndex < 10; tryIndex++) {
			const newSegment = this.validQuizSegments.Random();
			const newSegmentEvents = GetDreamEvents(newSegment, this.c.eventMaxWords);
			this.currentSegment_index = this.validQuizSegments.indexOf(newSegment);
			this.currentSegment_hintEventRange = FindFreshRange(newSegmentEvents, this.c.hintSize, []);
			if (this.currentSegment_hintEventRange == null) continue tryLoop;

			const possiblyInvert = (val: boolean)=>this.c.questionType_invert ? !val : val;

			let options = [] as QuizOption[];
			if (this.c.questionType == "comesAfter") {
				this.currentSegment_targetEventRange = FindFreshRange(newSegmentEvents, this.c.targetSize, [this.currentSegment_hintEventRange]);
				if (this.currentSegment_targetEventRange == null) continue tryLoop;

				const targetComesAfter = this.currentSegment_targetEventRange.first >= this.currentSegment_hintEventRange.after;
				options.push(new QuizOption({text: "No", isTarget: possiblyInvert(!targetComesAfter)}));
				options.push(new QuizOption({text: "Yes", isTarget: possiblyInvert(targetComesAfter)}));
			} else if (this.c.questionType == "isPresent") {
				const goalOptionCountInSegment = !this.c.questionType_invert ? 1 : this.c.optionCount - 1;
				const goalOptionCountOutsideOfSegment = this.c.optionCount - goalOptionCountInSegment;

				const optionsInSegment = [] as QuizOption[];
				for (let i = 0; i < goalOptionCountInSegment; i++) {
					const priorRangesInSegment = [
						this.currentSegment_hintEventRange,
						...optionsInSegment.map(a=>a.eventRange).filter(a=>a) as EventRange[],
					];
					const optionRange = FindFreshRange(newSegmentEvents, this.c.targetSize, priorRangesInSegment);
					if (optionRange == null) continue tryLoop;
					optionsInSegment.push(new QuizOption({
						eventRange: optionRange,
						text: this.GetTextForEventRange(newSegmentEvents, optionRange),
						isTarget: possiblyInvert(true),
					}));
				}

				const allEventsOutOfSegment = this.validQuizSegments_allEvents.filter(a=>!newSegmentEvents.includes(a));
				const optionsOutOfSegment = [] as QuizOption[];
				for (let i = 0; i < goalOptionCountOutsideOfSegment; i++) {
					const priorRangesOutOfSegment = optionsOutOfSegment.map(a=>a.eventRange).filter(a=>a) as EventRange[];
					const optionRange = FindFreshRange(allEventsOutOfSegment, this.c.targetSize, priorRangesOutOfSegment)!;
					if (optionRange == null) continue tryLoop;
					optionsOutOfSegment.push(new QuizOption({
						eventRange: optionRange,
						text: this.GetTextForEventRange(allEventsOutOfSegment, optionRange),
						isTarget: possiblyInvert(false),
					}));
				}

				options.push(...optionsInSegment, ...optionsOutOfSegment);
				options = shuffle(options);

				this.currentSegment_targetEventRange = options.find(a=>a.isTarget)!.eventRange;
			}
			this.options = options;
			this.options_targetIndex = this.options.findIndex(a=>a.isTarget);
			// start at first-1/last+1, so that first press to next/prev will go to first/last option, and NOT voice the hint
			this.options_currentIndex = this.c.startAtLast ? this.options.length : -1;

			// temp; for debugging
			/*console.log("Dream quiz debug:", [
				this.validQuizSegments,
				this.validQuizSegments_allEvents,
				this.currentSegment_index,
				this.currentSegment_hintEventRange,
				this.currentSegment_targetEventRange,
				this.options,
				this.options_targetIndex,
				this.options_currentIndex,
				this.CurrentSegmentAndDerivatives(),
			]);*/

			loopSucceeded = true;
			break;
		}
		if (!loopSucceeded) onFailed();
	}
}

class EventRange {
	constructor(startIndex: number, size: number) {
		this.first = startIndex;
		this.after = startIndex + size;
	}
	first: number;
	after: number;
	Intersects(other: EventRange) {
		const selfIsFullyBeforeOther = this.after <= other.first;
		const selfIsFullyAfterOther = this.first >= other.after;
		return !selfIsFullyBeforeOther && !selfIsFullyAfterOther;
	}
	IntersectsAny(others: EventRange[]) {
		return others.Any(other=>this.Intersects(other));
	}
}
export function FindFreshRange(pool: any[], size: number, priorRanges: EventRange[], gapToEnsure = 0) {
	const validNewRangeStartIndexes = Range(0, pool.length - size).filter(index=>{
		let newRange = new EventRange(index, size);
		const newRange_extendedForGap = gapToEnsure == 0 ? newRange :
			new EventRange(newRange.first - gapToEnsure, newRange.after + gapToEnsure);
		return !newRange_extendedForGap.IntersectsAny(priorRanges);
	});
	if (validNewRangeStartIndexes.length == 0) return null;
	return new EventRange(validNewRangeStartIndexes.Random(), size);
}

class QuizOption {
	constructor(data?: Partial<QuizOption>) {
		Object.assign(this, data);
	}
	eventRange?: EventRange;
	text: string;
	isTarget = false;
}