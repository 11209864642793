import {E} from "js-vextensions";
import {runInAction} from "mobx";
import {useEffect} from "react";
import {CheckBox, Column, Row, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {store} from "../../../Store";
import {CameraType} from "../../../Store/main/tools/@CameraConfig.js";
import {GetMonitorCameraConfig} from "../../../Store/main/tools/monitor.js";
import {InDesktop} from "../../../Utils/Bridge/Bridge_Native.js";
import {InfoButton, Observer, RunInAction_Set, Size, UseSize, BlobToArrayBuffer, RunInAction, GetSize_Method} from "web-vcore";
import {SaveStandaloneRecording} from "../../../Utils/Bridge/Bridge_Preload.js";
import {ScrollView} from "react-vscrollview";
import {ES} from "../../../Utils/UI/GlobalStyles.js";
import {currentCamera} from "../@Shared/CameraInterface.js";
import {CameraConfigUI} from "./CameraPanel/CameraConfigUI.js";
import {CameraMonitorChart} from "./CameraPanel/CameraMonitorChart.js";

@Observer
export class CameraPanel_Monitor extends BaseComponentPlus({}, {}) {
	ComponentWillUnmount() {
		const uiState = store.main.tools.monitor;
		// stop recording on page leave, since canvas, chart, etc. no longer present
		// maybe todo: make it possible to record while away from page, by keeping comp rendered but 0 opacity
		if (uiState.record) {
			this.StopRecording();
		}
	}

	StartRecording() {
		const uiState = store.main.tools.monitor;
		console.log("Starting recording.");

		//var canvasStream = this.canvasEl["captureStream"](uiState.cameraConfig.framerate);
		var canvasStream = this.canvasEl?.["captureStream"](); // leave out framerate, to capture every frame (ie. each time the canvas changes)
		uiState.recorder = new g.MediaRecorder(canvasStream, {mimeType: "video/webm;codecs=vp9"});

		uiState.recorder.ondataavailable = event=>{
			if (event.data.size > 0) {
				uiState.recordedChunks.push(event.data);
			}
		};
		uiState.recorder.start();

		RunInAction("CameraPanelMonitor_StartRecording", ()=>uiState.record = true);
	}
	StopRecording() {
		const uiState = store.main.tools.monitor;
		console.log("Stopping recording.");

		/*uiState.recorder.stop();
		function Download() {
			var blob = new Blob(uiState.recordedChunks, {
				type: "video/webm",
			});
			var url = URL.createObjectURL(blob);
			var a = document.createElement("a");
			document.body.appendChild(a);
			a.style.display = "none";
			a.href = url;
			a.download = "Test1.webm";
			a.click();
			window.URL.revokeObjectURL(url);
		}
		Download();*/
		uiState.recorder.addEventListener("stop", async()=>{
			const combinedBlob = new Blob(uiState.recordedChunks, {type: "video/webm"});
			const combinedArrayBuffer = await BlobToArrayBuffer(combinedBlob);
			//SaveStandaloneRecording(combinedArrayBuffer, `${mainDataPathMarker}/Standalone Recordings/${moment().format("YYYY-MM-DD-HH-mm-ss")}.webm`);
			SaveStandaloneRecording(combinedArrayBuffer);
		});
		uiState.recorder.stop();

		RunInAction("CameraPanelMonitor_StopRecording", ()=>uiState.record = false);
		uiState.recorder = null;
		uiState.recordedChunks = [];
	}

	//videoEl: HTMLVideoElement;
	canvasEl: HTMLCanvasElement|n;
	canvasContext: CanvasRenderingContext2D|n;
	imgEl: HTMLImageElement;
	chart: CameraMonitorChart|n;
	previewContainerSize: Size;

	render() {
		const uiState = store.main.tools.monitor;
		//const engineConfig = GetSelectedFBAConfig();

		useEffect(()=>{
			currentCamera.ConnectMonitorCameraPanel(this);
			return ()=>currentCamera.DisconnectMonitorCameraPanel();
		});

		//const [previewContainerRef, previewContainerSize] = UseSize({method: UseSize_Method.BoundingClientRect});
		const [previewContainerRef, previewContainerSize_raw] = UseSize({method: GetSize_Method.ScrollSize});
		const previewContainerSize =  {width: previewContainerSize_raw?.width ?? 0, height: previewContainerSize_raw?.height ?? 0};
		this.previewContainerSize = previewContainerSize;

		const targetWidth = GetMonitorCameraConfig().resolution_width;
		const targetHeight = GetMonitorCameraConfig().resolution_height;
		return (
			<ScrollView style={ES({flex: 1, padding: 10})} contentStyle={{display: "flex", flexDirection: "column"}}>
				<CameraConfigUI enabled={!uiState.preview} config={uiState.cameraConfig} onChange={val=>RunInAction_Set(this, ()=>uiState.cameraConfig = val)}/>
				<Row mt={20}>
					<Text style={{fontWeight: "bold"}}>Preview options:</Text>
					<Text ml={5} style={{opacity: .5}}>(based on selected engine-config)</Text>
				</Row>
				<Column ml={15}>
					<Row mt={5}>
						<Text>Overlay:</Text>
						<CheckBox ml={10} text="Ignore rects" value={uiState.overlay_ignoreRects} onChange={val=>RunInAction_Set(this, ()=>uiState.overlay_ignoreRects = val)}/>
						{/*<CheckBox ml={10} text="% to trigger" value={uiState.pixelOverlay_percentToTrigger} onChange={val=>RunInAction_Set(this, ()=>uiState.pixelOverlay_percentToTrigger = val)}/>*/}
						<CheckBox ml={10} text="Pixel triggers" value={uiState.overlay_pixelTriggers} onChange={val=>RunInAction_Set(this, ()=>uiState.overlay_pixelTriggers = val)}/>
					</Row>
					<Row mt={5} center>
						<Text>Graph:</Text>
						<CheckBox ml={10} text="Trigger-pixel count" value={uiState.graph_triggerPixelCount} onChange={val=>RunInAction_Set(this, ()=>uiState.graph_triggerPixelCount = val)}/>
						<InfoButton ml={5} text={`
							Graphs the total number of pixels that were triggered, for each frame.
						`/*
							Color of line changes based on number of frame-trigger conditions that are met:
							* If 0 conditions are met: white
							* If 1 conditions are met: light green
							* If 2 conditions are met: dark green
						`*/.AsMultiline(0)}/>
					</Row>
				</Column>
				<Row mt={5} center>
					<CheckBox text="Preview" enabled={uiState.cameraConfig.type == CameraType.Webcam || InDesktop()} value={uiState.preview} onChange={val=>{
						RunInAction_Set(this, ()=>{
							uiState.preview = val;
							if (!val && uiState.record) {
								this.StopRecording();
							}
						});
					}}/>
					<CheckBox ml={10} text="Record" enabled={uiState.preview} value={uiState.record} onChange={val=>{
						if (val) this.StartRecording();
						else this.StopRecording();
					}}/>
					<InfoButton ml={5} text={`Stored to "$LFData/Standalone Recordings/$RecordTime.webm"`}/>
				</Row>
				{/*<div ref={previewContainerRef} style={{opacity: 0}}/> {/* dummy div for obtaining width */}
				<div ref={previewContainerRef as any} style={E({position: "relative"}, !uiState.preview && {opacity: 0}) as any}>
					{/*uiState.cameraConfig.type == CameraType.Webcam &&
						<video ref={c=>this.videoEl = c} style={{height: "100%"}}/>*/}
					{/*uiState.cameraType == CameraType.IPCamera &&
						<img ref={c=>this.imgEl = c} width={videoWidth} height={videoHeight} style={{width: previewContainerSize.width, height: previewContainerSize.width * (videoHeight / videoWidth)}}/>*/}
					<canvas ref={c=>{
						this.canvasEl = c;
						this.canvasContext = c?.getContext("2d");
					}}
						width={targetWidth} height={targetHeight}
						style={{
							width: previewContainerSize.width, height: previewContainerSize.width * (targetHeight / targetWidth),
							display: "block", // fixes that whitespace would otherwise be added to container (due to default "display:inline")
						}}
						//style={{width: videoWidth, height: videoHeight}}
					/>
					{uiState.graph_triggerPixelCount &&
					<div style={ES(
						uiState.record && {
							// match video width and height, so we can draw onto main canvas at high-res and without rescaling (if used, use dummy-div, and remove ref and "position:relative" from container)
							//position: "absolute", width: videoWidth, height: videoHeight,
							opacity: 0, // when recording, make chart canvas invisible; it's just a buffer, to be overlaid onto main canvas by useEffect(...) in CameraPanel.tsx
						},
					)}>
						<CameraMonitorChart ref={c=>this.chart = c} framesPerSecond={uiState.cameraConfig.framerate}/>
					</div>}
				</div>
			</ScrollView>
		);
	}
}