import {BaseComponentPlus} from "react-vextensions";
import {TextInput, TextInputProps} from "react-vcomponents";

export class TagsInput extends BaseComponentPlus({} as {value: string[], onChange: (val: string[])=>any} & Omit<TextInputProps, "value" | "onChange">, {}) {
	render() {
		const {enabled, value, onChange, ...rest} = this.props;
		return (
			<TextInput {...rest} enabled={enabled} style={{width: "100%"}}
				value={(value || []).join(", ")} onChange={val=>onChange(val.split(",").map(a=>a.trim()).filter(a=>a))}/>
		);
	}
}