import {FBAConfig} from "../../Store/firebase/fbaConfigs/@FBAConfig";
import {AddSchema, AssertValidate, GetSchemaJSON, NewSchema, Command, AssertV} from "mobx-firelink";
import {GetFBAConfig} from "../../Store/firebase/fbaConfigs";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";

type MainType = FBAConfig;
const MTName = "FBAConfig";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.ExcludeKeys("creator", "createdAt"),
		}),
		// alternative, used for edit-json dialog (lets you delete properties, including old ones no longer in schema)
		newData: {type: "object"},
	},
	//required: ["id", "updates"],
	required: ["id"],
}));

export class UpdateFBAConfig extends Command<{id: string, updates?: Partial<MainType>, newData?: MainType}> {
	oldData: MainType;
	newData: MainType;
	skipValidateKeysBeingUpdated = false;
	Validate() {
		if (!this.skipValidateKeysBeingUpdated) AssertValidate(`Update${MTName}_payload`, this.payload, "Payload invalid");

		const {id, updates, newData} = this.payload;
		this.oldData = GetFBAConfig.NN(id);
		AssertV(IsUserCreatorOrMod(this.userInfo?.id, this.oldData), "Insufficient permissions.");

		if (newData != null) {
			this.newData = newData;
		} else {
			this.newData = {...this.oldData, ...updates};
			this.newData.editedAt = Date.now();
		}
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`fbaConfigs/${id}`] = this.newData;
		return updates;
	}
}