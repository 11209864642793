import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export class FBAConfig_JourneyVisualization {
	constructor(initialData: Partial<FBAConfig_JourneyVisualization>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	userNudge_triggerSet: TriggerSet;
	userNudge_soundTag: string;

	entityTags: string[];
	seeds_orderKey: string;
	voiceEntityNumberInCycle: boolean;
	speakInterval: number;
	cycleReverse_minTime: number;
	cycleReverse_maxTime: number;
	userReversalDetection_maxDelay: number;
	userReversalDetectionFail_waitPeriod: number;

	voiceSoundTag: string;
	volumeMultiplier: number;
}
AddSchema("FBAConfig_JourneyVisualization", {
	properties: {
		enabled: {type: "boolean"},

		userNudge_triggerSet: {$ref: "TriggerSet"},
		userNudge_soundTag: {type: "string"},

		entityTags: {items: {type: "string"}},
		seeds_orderKey: {type: "string"},
		voiceEntityNumberInCycle: {type: "boolean"},
		speakInterval: {type: "number"},
		cycleReverse_minTime: {type: "number"},
		cycleReverse_maxTime: {type: "number"},
		userReversalDetection_maxDelay: {type: "number"},
		userReversalDetectionFail_waitPeriod: {type: "number"},

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},
	},
});