import {SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import {JournalEntry, JournalSegment} from "../journalEntries/@JournalEntry.js";
import {EngineSessionInfo} from "../sessions/@EngineSessionInfo.js";

/**
 * A "session period" is a synthetic structure, meant to unify processing of day-time sessions and night-time sessions/journal-entries.
 * 
 * Main structure (for processing): SessionPeriod -> SleepCycle [cycleNumber:0 for daytime periods] -> EventGroups and JournalSegments
 * */
export class SessionPeriod {
	//static NewEmpty() { return new SessionPeriod({sessions: [], eventGroups: []}); }
	
	constructor(data: Partial<SessionPeriod>) { Object.assign(this, data); }
	
	startTime: number;
	endTime: number|n;

	sessions: EngineSessionInfo[];
	// concatened from sessions
	eventGroups: EventGroup[];

	journalEntries: JournalEntry[];
	// concatenated from journal entries
	journalSegments: JournalSegment[];

	// extra synthesized info
	sleepCycles: SleepCycle[];
}

export class SleepCycle {
	constructor(data: SleepCycle) { Object.assign(this, data); }

	/**
	 * Starts at 1, for valid sleep cycles. 
	 * (Can be 0, which means this "sleep cycle" is just a container for a daytime/no-cycle session.)
	 */
	cycleNumber: number;
	startTime: number;
	endTime: number|n;

	// basic logic for associating dream-segments and sleep-cycles: a given dream-segment is associated with the latest sleep-cycle whose end-time is before the dream-segment's end-time
	// (reason: regardless of whether the user or the engine adds the dream-segment, the sleep-period should have ended first to trigger the user/engine to add the dream-segment)
	// NOTE: This array does NOT include wake-segments. (those are excluded within DiscernSleepCyclesFromEventGroups())
	associatedDreamSegments: JournalSegment[];

	associatedSessions: EngineSessionInfo[];

	// synthesized from associated-sessions
	// ==========

	associatedEventGroups: EventGroup[];

	alarmDelay: number; // in ms?
	alarmWaitEndTime: number; // in unix-timestamp (ms)?
	responseTime: number; // in ms?
}

export type EventGroup = {
	sessionInfo: EngineSessionInfo,
	type: "special" | "wait-period" | "cycle",
	events: SessionEvent[],
	//enderReached: boolean|n,
	duration: number, // in ms
}