import {GetRandomNumber, Timer} from "js-vextensions";
import {TextSpeaker} from "web-vcore";
import {Text} from "react-vcomponents";
import {FBASession, TriggerPackage} from "../../../Engine/FBASession.js";
import {IsMetaEntity} from "../../../Store/firebase/entities.js";
import {FBAConfig_JourneyVisualization} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyVisualization.js";
import {GetUserEntityTags, MeID} from "../../../Store/firebase/users.js";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {AlarmsComp, AlarmsPhase} from "./AlarmsComp.js";
import {DreamRecallComp} from "./DreamRecallComp.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";

export class JourneyVisualizationComp extends EngineSessionComp<FBAConfig_JourneyVisualization> {
	constructor(session: FBASession, config: FBAConfig_JourneyVisualization) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}
	
	GetTriggerPackages() {
		return [
			new TriggerPackage("JourneyVisualization_UserNudge", this.c.userNudge_triggerSet, this, {}, async triggerInfo=>{
				const alarmsComp = this.s.Comp(AlarmsComp);
				const phase_active = alarmsComp.PhaseIs(AlarmsPhase.Alarm, AlarmsPhase.Solving);
				if (!phase_active) return;
				this.UserNudge();
			}),
		];
	}
	GetStatusUI() {
		return <Text style={{whiteSpace: "pre"}}>{`TODO`.AsMultiline(1)}</Text>;
	}

	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}

	// players
	//soundPlayer = new SoundPlayer(); // we use SoundPlayer (not TextSpeaker), since it's project-specific so understands Sound-objects directly
	textSpeaker = new TextSpeaker();

	// in-session timers
	checkPhaseTimer: Timer;
	speakTimer: Timer;
	checkForTimeToCycleReverseTimer: Timer;

	lastSpokenIndex = -1;
	cycleDirection_clockwise = true;
	cycleReversal_lastTime = 0;
	currentCycle_entitySpeakCount = 0;

	SpeakNextTarget() {
		const dreamRecallComp = this.s.Comp(DreamRecallComp);
		const seeds_orderKey = (this.c.seeds_orderKey ?? "").trim().length ? this.c.seeds_orderKey.trim() : null;
		const validEntityIDs = dreamRecallComp.entities_all.filter(entity=>{
			// never random-seek to a meta-entity (even if it's a valid target moving forward/backward)
			if (IsMetaEntity(entity)) return false;
			// exclude entities that don't have all the required tags
			const userTags = GetUserEntityTags(MeID(), "entities", entity._key);
			for (const tag of this.c.entityTags) {
				if (!userTags?.includes(tag)) return false;
			}
			// exclude entities that don't have an "order key" specified in their name
			const orderKeyVal_match = entity.name.match(new RegExp(`\\[${seeds_orderKey}:(.+?)\\]`));
			if (orderKeyVal_match == null) return false;
			return true;
		})
			.OrderBy(entity=>{
				const [matchStr, orderValStr] = entity.name.match(new RegExp(`\\[${seeds_orderKey}:(.+?)\\]`))!;
				return Number(orderValStr);
			})
			.map(a=>a._key);
		if (validEntityIDs.length == 0) {
			this.NarrateText("No valid targets.");
			return;
		}

		let currentEntityIndex: number;
		if (this.cycleDirection_clockwise) {
			currentEntityIndex = (this.lastSpokenIndex + 1) % validEntityIDs.length;
		} else {
			currentEntityIndex = ((this.lastSpokenIndex - 1) + validEntityIDs.length) % validEntityIDs.length;
		}
		this.lastSpokenIndex = currentEntityIndex;

		this.currentCycle_entitySpeakCount++;
		
		const currentEntityID = validEntityIDs[currentEntityIndex];
		const currentEntity = dreamRecallComp.entities_all.find(a=>a._key == currentEntityID)!;
		this.NarrateText([
			currentEntity.name.split("[")[0].trim(),
			this.c.voiceEntityNumberInCycle ? `${this.currentCycle_entitySpeakCount}` : null,
		].filter(a=>a).join(" "))
	}
	nudgeSoundEffectPlaying = false;
	UserNudge() {
		// if user-nudge sound-effect is not already playing, play the sound
		(async()=>{
			if (this.nudgeSoundEffectPlaying) return;
			this.nudgeSoundEffectPlaying = true;
			try {
				await this.s.PlaySoundEffect(this.c.userNudge_soundTag);
			} catch (ex) {
				console.error(ex);
				//HandleError(ex); // uncomment? or too unimportant?
			}
			this.nudgeSoundEffectPlaying = false;
		})();

		// if user caught the reversal...
		if (!this.cycleDirection_clockwise) {
			const cycleJustReversed = Date.now() - this.cycleReversal_lastTime <= this.c.userReversalDetection_maxDelay;
			// ...and did so in time, reward them with an alarm-wait
			if (cycleJustReversed) {
				this.Log("User caught cycle-reversal; starting alarm-wait.", LogType.Event_Large);
				this.s.AsLocal!.AddEvent({type: "Journey.CycleSuccess"});
				this.NarrateText("Goodnight");
				const alarmsComp = this.s.Comp(AlarmsComp);
				alarmsComp.SetPhase(AlarmsPhase.Sleep);
			}
			// ...but did so too late, don't start alarm-wait; instead, restart cycle (going clockwise), and tell them they were too late
			else {
				this.Log("User did nudge too late.", LogType.Event_Large);
				this.s.AsLocal!.AddEvent({type: "Journey.CycleFail"});
				this.NarrateText("Too late");
				this.StartCycle();
			}
		}
		// user did a nudge, despite no reversal having just happened; to disincentivize "cheating" (ie. just doing nudges constantly), require a wait of at least X seconds before the next actual cycle-reversal
		else {
			//this.Log("User did nudge too early.", LogType.Event_Small);
			//this.NarrateText("Too early");
			this.targetCycleReverseTime = this.targetCycleReverseTime.KeepAtLeast(Date.now() + this.c.userReversalDetectionFail_waitPeriod);
		}
	}

	//lastProcessedPhase = AlarmsPhase.NotStarted;
	targetCycleReverseTime = 0;
	override OnStartPhase(oldPhase: AlarmsPhase, newPhase: AlarmsPhase) {
		// as per note in UI: if not in entryWait_dim or entryWait_bright phases, don't do the speaking
		const newPhase_active = newPhase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving);
		const oldPhase_active = oldPhase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving);
		if (newPhase_active && !oldPhase_active) {
			this.StartCycle();
		} else if (!newPhase_active && oldPhase_active) {
			this.StopCycle();
		}
	}

	StartCycle() {
		this.Log("Cycle started.", LogType.Event_Large);
		this.s.AsLocal!.AddEvent({type: "Journey.CycleStart"});
		this.cycleDirection_clockwise = true;
		this.currentCycle_entitySpeakCount = 0;
		this.targetCycleReverseTime = Date.now() + GetRandomNumber({min: this.c.cycleReverse_minTime, max: this.c.cycleReverse_maxTime});
		this.speakTimer.Start();
		this.checkForTimeToCycleReverseTimer.Start();
	}
	StopCycle() {
		this.Log("Cycle stopped.", LogType.Event_Large);
		this.speakTimer.Stop();
		this.checkForTimeToCycleReverseTimer.Stop();
	}

	override OnStart() {
		this.speakTimer = new Timer(this.c.speakInterval, ()=>{
			this.SpeakNextTarget();
		}).SetContext(this.s.timerContext);
		this.checkForTimeToCycleReverseTimer = new Timer(1000, ()=>{
			if (Date.now() > this.targetCycleReverseTime) {
				this.cycleDirection_clockwise = false;
				this.cycleReversal_lastTime = Date.now();
				this.checkForTimeToCycleReverseTimer.Stop();
				this.Log("Cycle reversed.", LogType.Event_Large);
				this.s.AsLocal!.AddEvent({type: "Journey.CycleReverse"});
			}
		});
	}
	override OnStop() {
		this.StopTextSpeaker();
	}

	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}
}