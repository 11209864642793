import {FBAConfig_DreamQuiz} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {DreamQuizSubcomp} from "./DreamQuiz/DreamQuizSubcomp.js";
import {SoundQuizSubcomp} from "./DreamQuiz/SoundQuizSubcomp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";

export class DreamQuizComp extends EngineSessionComp<FBAConfig_DreamQuiz> {
	constructor(session: FBASession, config: FBAConfig_DreamQuiz) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("DreamQuiz_SubmitPick", this.c.submitPick_triggerSet, this, {}, async triggerInfo=>{
				if (this.quizTypeInfo.type == "dream") {
					await this.dreamQuiz.SubmitPick();
				} else {
					await this.soundQuiz.SubmitPick();
				}
			}),
			new TriggerPackage("DreamQuiz_NextOption", this.c.nextOption_triggerSet, this, {}, async triggerInfo=>{
				if (this.quizTypeInfo.type == "dream") {
					this.dreamQuiz.NextOption();
				} else {
					this.soundQuiz.NextOption();
				}
			}),
			new TriggerPackage("DreamQuiz_PrevOption", this.c.prevOption_triggerSet, this, {}, async triggerInfo=>{
				if (this.quizTypeInfo.type == "dream") {
					this.dreamQuiz.PrevOption();
				} else {
					this.soundQuiz.PrevOption();
				}
			}),
			new TriggerPackage("DreamQuiz_SkipPick", this.c.skipPick_triggerSet, this, {}, triggerInfo=>{
				if (this.quizTypeInfo.type == "dream") {
					this.dreamQuiz.SkipPick();
				} else {
					this.soundQuiz.SkipPick();
				}
			}),
		];
	}

	OnStart() {
		this.InitQuizTypeInfo("sound"); // give a preference for "dream" quiz at start (it's the more commonly used quiz-type, and is more comfortable as starting point)
		this.dreamQuiz.SelectNewCurrentSegmentAndDerivatives();
		//this.soundQuiz.SelectNewPromptInfo(); // moved to async-func within constructor of SoundQuizSubcomp
	}
	OnStop() {
		this.dreamQuiz.dbAutorun_disposer();
	}

	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}

	// dream<>sound switching
	// ==========
	
	quizTypeInfo: {type: "dream" | "sound", promptsLeft: number};
	InitQuizTypeInfo(quizTypeToTryToAvoid?: "dream" | "sound") {
		const validQuizTypes = [this.c.quizRatio_dream > 0 && "dream", this.c.quizRatio_sound > 0 && "sound"].filter(a=>a) as ("dream" | "sound")[];
		// if user creates an invalid state (where dream-quiz comp is enabled, but both quiz-types have their "ratio" at 0!), avoid errors by just forcing the quiz-type to "dream"
		if (validQuizTypes.length == 0) {
			SessionLog("Warning: DreamQuizComp has no valid quiz-types to choose from (each quiz-type's ratio is 0); defaulting to 'dream' quiz-type.", LogType.Event_Large);
			this.quizTypeInfo = {type: "dream", promptsLeft: 1};
			return;
		}

		const validQuizTypes_withAvoidance = validQuizTypes.Exclude(quizTypeToTryToAvoid) as ("dream" | "sound")[];
		const newQuizType = validQuizTypes_withAvoidance.length ? validQuizTypes_withAvoidance.Random() : validQuizTypes.Random();
		this.quizTypeInfo = {type: newQuizType, promptsLeft: newQuizType == "dream" ? this.c.quizRatio_dream : this.c.quizRatio_sound};
	}
	IncrementQuizTypeInfo() {
		let newPromptsLeft_thisType = this.quizTypeInfo.promptsLeft - 1;
		if (newPromptsLeft_thisType > 0) {
			this.quizTypeInfo = {...this.quizTypeInfo, promptsLeft: newPromptsLeft_thisType};
		} else {
			this.InitQuizTypeInfo(this.quizTypeInfo.type);
		}
	}
	GoNextPrompt(playOrNarrateNextHint: boolean) {
		if (this.quizTypeInfo.type == "dream") {
			this.dreamQuiz.SelectNewCurrentSegmentAndDerivatives();
		} else {
			this.soundQuiz.SelectNewPromptInfo();
		}
		this.IncrementQuizTypeInfo();

		if (playOrNarrateNextHint) {
			if (this.quizTypeInfo.type == "dream") {
				this.dreamQuiz.NarrateCurrentHint();
			} else {
				this.soundQuiz.PlayCurrentHint();
			}
		}
	}

	dreamQuiz = new DreamQuizSubcomp(this);
	soundQuiz = new SoundQuizSubcomp(this);
}