import {InfoButton, Observer, RunInAction, SpeechRecognizer, VDateTime, VMediaRecorder} from "web-vcore";
import {Clone, GetEntries} from "js-vextensions";
import Moment from "moment";
import React from "react";
import {Button, Row, Select, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {DeleteJournalEntry} from "../../Server/Commands/DeleteJournalEntry.js";
import {UpdateJournalEntry} from "../../Server/Commands/UpdateJournalEntry.js";
import {JournalEntry, JournalSegment, SleepPosition} from "../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../Store/index.js";
import {useAutoUnregisterWhisperTranscriptionWatcher, WhisperTranscriptionWatcher} from "../../Utils/Bridge/Whisper.js";
import {AudioChunkPlayer} from "../../Utils/ReactComponents/AudioChunkPlayer.js";
import {ES} from "../../Utils/UI/GlobalStyles.js";
import {JournalSegmentUI} from "./JournalEntry/JournalSegmentUI.js";

const browserSupportsSpeechRecognition = (g.SpeechRecognition ?? g.webkitSpeechRecognition) != null;

@Observer
export class JournalEntryUI extends BaseComponentPlus({} as {entry: JournalEntry}, {recognizing: false}) {
	notesRecorder = new VMediaRecorder();
	notesRecognizer = browserSupportsSpeechRecognition ? new SpeechRecognizer() : null;
	NotesRecognizer_OnTranscriptChange = ()=>this.Update();
	
	whisperTranscriptionWatcher = new WhisperTranscriptionWatcher({
		processingPriority: 10,
		absorbsResult: true,
		keepsRecordingAlive: true,
		onChunkTranscribed: ({text})=>{
			if (text.trim().length == 0) return null;

			const {entry} = this.props;
			const newNotes = entry.notes + text + "\n";
			new UpdateJournalEntry({id: entry._key, updates: {notes: newNotes}}).Run();
		},
	});

	ComponentDidMount() {
		//this.notesRecorder = new SoundRecorder();
		if (this.notesRecognizer) {
			this.notesRecognizer.transcriptChangeListeners.push(this.NotesRecognizer_OnTranscriptChange);
		}
	}
	ComponentWillUnmount() {
		this.notesRecorder.StopRecording();
		if (this.notesRecognizer) {
			this.notesRecognizer.StopRecognizing();
			this.notesRecognizer.transcriptChangeListeners.Remove(this.NotesRecognizer_OnTranscriptChange);
		}
	}

	render() {
		const {entry} = this.props;
		const mainMicrophoneID = store.main.settings.audio.mainMicrophoneID;
		const {recognizing} = this.state;

		useAutoUnregisterWhisperTranscriptionWatcher(this.whisperTranscriptionWatcher);

		//const sleepPositionOptions = [{name: "", value: null}].concat(GetEntries(SleepPosition));
		return (
			<ScrollView style={ES({flex: 1})} contentStyle={{display: "flex", flexDirection: "column", backgroundColor: "hsla(0,0%,0%,.7)", borderRadius: 10, padding: 10}}>
				<Row>
					<Button text="Back" onClick={()=>RunInAction("JournalEntryUI.Close.onClick", ()=>store.main.journal.selectedJournalEntry = null)}/>
					<Button ml={5} text="Delete" onClick={()=>{
						ShowMessageBox({
							title: "Delete journal entry?", cancelButton: true,
							message: "Permanently delete this journal entry?",
							onOK: ()=>{
								new DeleteJournalEntry({id: entry._key}).Run();
							},
						});
					}}/>
				</Row>
				<Row mt={5}>
					<Text>Title: </Text>
					<TextInput style={{flex: 1}} value={entry.title} onChange={val=>{
						new UpdateJournalEntry({id: entry._key, updates: {title: val}}).Run();
					}}/>
				</Row>
				<Row mt={5}>
					<Text>Tags:</Text>
					<TextInput ml={5} style={{flex: 1}}
						value={(entry.tags || []).join(", ")} onChange={val=>{
							const tags = val.split(",").map(a=>a.trim()).filter(a=>a.length);
							new UpdateJournalEntry({id: entry._key, updates: {tags}}).Run();
						}}/>
				</Row>
				<Row center mt={5}>
					<Text mr={5}>Sleep start:</Text>
					<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" value={entry.sleepTime ? Moment(entry.sleepTime) : null} onChange={val=>{
						//if (val == null) return;
						new UpdateJournalEntry({id: entry._key, updates: {sleepTime: val?.valueOf() ?? null}}).Run();
					}}/>
				</Row>
				<Row center mt={5}>
					<Text mr={5}>Sleep end:</Text>
					<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" value={entry.wakeTime ? Moment(entry.wakeTime) : null} onChange={val=>{
						//if (val == null) return;
						new UpdateJournalEntry({id: entry._key, updates: {wakeTime: val?.valueOf() ?? null}}).Run();
					}}/>
					<Text ml={5}>Wake position:</Text>
					<Select ml={5} options={GetEntries(SleepPosition)} value={entry.wakePosition} onChange={val=>{
						new UpdateJournalEntry({id: entry._key, updates: {wakePosition: val}}).Run();
					}}/>
				</Row>
				<Text>Notes:</Text>
				<Row center>
					<Button text={recognizing ? "Stop recording" : "Start recording"} onClick={async()=>{
						if (this.notesRecognizer == null) return;
						const newRecognizing = !recognizing;
						this.SetState({recognizing: newRecognizing}); // change the toggle first, so that if person double-clicks, it doesn't run the start/stop process twice
						if (newRecognizing) {
							await this.notesRecorder.StartRecording_Audio(mainMicrophoneID);
							this.notesRecognizer.StartRecognizing();
						} else {
							await this.notesRecorder.StopRecording();
							await this.notesRecognizer.StopRecognizing();

							const newNotes = entry.notes + this.notesRecognizer.GetTranscript(true, false);
							this.notesRecognizer.ClearTranscript();
							new UpdateJournalEntry({id: entry._key, updates: {notes: newNotes}}).Run();
						}
						this.Update();
					}}/>
					<InfoButton ml={5} text={
					// I think this issue is caused by the constant starting-and-stopping of the Google Voice app, as the source of the speech-recognition.
					// Each time it starts/stops, it pauses and resumes our app, and triggers "D/AudioManager: dispatching onAudioFocusChange([-2/1]) to android.media.AudioManager@8ae88e4org.chromium.content.browser.AudioFocusDelegate@45df14d"
					// I think that dispatching of onAudioFocusChange triggers code within the web-view that lessens the sensitivity of all of its MediaRecorders. (which we use for the mic-loudness trigger)
					`
						Important warning: If the Lucid Frontier Android app is open, starting recording there (or in Chrome) greatly decreases the microphone sensitivity for the mic-loudness trigger!
						
						Not sure how to fix (seems like a WebView bug). If you do use this while a session is running, you can restore mic sensitivity by closing the app and restarting it.
					`.AsMultiline(0)}/>
					<Text ml={5}>Recordings:</Text>
					{this.notesRecorder.dataChunks.map((chunk, index)=>{
						/*const blob = new Blob([chunk], {type: "audio/x-mpeg-3"});
						const blobURL = URL.createObjectURL(blob);
						let audioChunkElement: HTMLAudioElement;
						return (
							//<audio key={index} controls={true} src={blobURL} style={{marginLeft: 5}}></audio>
							<Fragment key={index}>
								{/*<audio ref={c=>this.audioChunkElements[index] = c} src={blobURL} style={{marginLeft: 5}}></audio>*#/}
								<audio controls={true} src={blobURL} style={{marginLeft: 5}}></audio>
							</Fragment>
						);*/
						return (
							<AudioChunkPlayer key={index} audioChunks={[chunk]} style={{marginLeft: 5}}/>
						);
					})}
				</Row>
				<Text>Notes (whisper input):</Text>
				<Row center>
					<Button text={this.whisperTranscriptionWatcher.IsRegistered() ? "Stop recording" : "Start recording"} onClick={async()=>{
						const newRecognizing = !this.whisperTranscriptionWatcher.IsRegistered();
						if (newRecognizing) {
							this.whisperTranscriptionWatcher.Register();
						} else {
							this.whisperTranscriptionWatcher.UnregisterAfterTranscribedToNow();
						}
					}}/>
					<Text ml={5}>Recordings: (n/a atm)</Text>
				</Row>
				<TextArea enabled={!recognizing} autoSize={true} style={{marginTop: 5, flexShrink: 0}}
					//value={`${entry.notes};${navigator.userAgent};${navigator.userAgent.includes("; Android ") && navigator.userAgent.includes(" Chrome/") ? ToJSON(this.notesRecognizer.transcriptSessions) : this.notesRecognizer.GetTranscript()}`}
					value={entry.notes + (this.notesRecognizer?.GetTranscript() ?? "")}
					onChange={val=>{
						new UpdateJournalEntry({id: entry._key, updates: {notes: val}}).Run();
					}}/>
				<Text mt={5} mb={5}>Segments:</Text>
				{entry.segments && entry.segments.map((segment, index)=>{
					return <JournalSegmentUI key={index} entry={entry} segment={segment} segments={entry.segments} showEntitiesSequence={true}/>;
				})}
				<Row mt={5}>
					<Button text="Add segment" onClick={()=>{
						const segments_new = Clone(entry.segments);
						segments_new.push(new JournalSegment());
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
				</Row>
			</ScrollView>
		);
	}
}