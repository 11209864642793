import {Clone, DeepGet, GetTreeNodesInObjTree} from "js-vextensions";
import {GetSchemaJSON} from "mobx-firelink";
import {FBAConfig} from "./@FBAConfig.js";
import {TriggerSet} from "./@TriggerSet.js";

export function GetAllTriggerSetsInFBAConfig(config: FBAConfig) {
	const result = [] as TriggerSet[];
	JSON.stringify(config, function(key, value) {
		//if (value?.minTriggerInterval != null && value?.sequences != null) {
		if (key.endsWith("_triggerSet")) {
			result.push(value);
		}
		return value;
	});
	return result;
}

/*export function GetAllTriggerSetsInFBAConfig(config: FBAConfig) {
	const paths = GetTriggerSetPaths_InFBAConfig();
	const triggerSets = paths.map(path=>DeepGet(config, path));
	return triggerSets;
}
export function GetTriggerSetPaths_InFBAConfig() {
	const fbaConfigSchema_withRefsResolved = CopySchemaWithAllRefsResolved(GetSchemaJSON("FBAConfig"));
	const triggerSetPaths = GetTreeNodesInObjTree(fbaConfigSchema_withRefsResolved).filter(a=>a.Value?.$ref == "TriggerSet").map(a=>a.Path);
	// hard(er) part: convert prop-path "in json" into a prop-path "in object"
	return triggerSetPaths_inObj = TODO;
	return triggerSetPaths_inObj;
}
export function CopySchemaWithAllRefsResolved(schemaObj: Object, recursive = true) {
	const newSchemaObj = Clone(schemaObj);
	while (true) {
		const refTreeNodes = GetTreeNodesInObjTree(newSchemaObj).filter(a=>a.Value?.$ref != null);
		if (refTreeNodes.length == 0) break;

		for (const refTreeNode of refTreeNodes) {
			const refSchemaName = refTreeNode.Value.$ref;
			const refSchema = GetSchemaJSON(refSchemaName);
			if (refSchema == null) throw new Error(`Could not find ref-schema with name "${refSchemaName}".`);
			refTreeNode.Value = refSchema;
		}
		if (!recursive) break;
	}
	return newSchemaObj;
}*/