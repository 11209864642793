import {GetDoc, GetDocs, StoreAccessor, WhereOp} from "mobx-firelink";
import {store} from "../../Store";
import {sessionFolderName_formatStr as sessionFolderName_startTimeFormatStr} from "../../Engine/FBASession.js";
import {GetNewLocalSessions} from "../../Utils/Bridge/Bridge_Preload.js";
import {RunInAction} from "web-vcore";
import {emptyArray, Timer} from "js-vextensions";
import {observable} from "mobx";
import moment from "moment";
import {EngineSessionInfo} from "./sessions/@EngineSessionInfo.js";
import {MeID} from "./users.js";

export const GetSession = StoreAccessor(s=>(id: string|n): EngineSessionInfo|n=>{
	//if (id == null) return null; // defensive

	const localEntryMatch = Array.from(localSessionMap.values()).find(a=>a.id == id);
	if (localEntryMatch) {
		return GetSessions(localEntryMatch.creator ?? MeID()).find(a=>a.id == id);
	}

	//return GetDoc({}, a=>a.sessions.get(id))?.Cast(EngineSessionInfo);
	return GetDoc({}, a=>a.sessions.get(id!));
});

/** Returns the timeline-events for the given user. */
export const GetSessions = StoreAccessor(s=>(userID: string|n): EngineSessionInfo[]=>{
	/*return GetDocs({
		queryOps: [new WhereOp("creator", "==", userID)],
	}, a=>a.sessions);*/
	return GetSessionsIntersectingRange(userID, 0);
});
export const GetOpenSession = StoreAccessor(s=>()=>{
	const sessions = MeID() ? GetSessions(MeID()) : emptyArray;
	return sessions.find(a=>a.id == store.main.timeline.sessions.selectedSession);
});

export const GetSessionsIntersectingRange = StoreAccessor(s=>(userID: string|n, rangeStart: number, rangeEnd?: number): EngineSessionInfo[]=>{
	const webSessions = GetSessionsIntersectingRange_Web(userID, rangeStart, rangeEnd);
	const localSessions = GetSessionsIntersectingRange_Local(userID, rangeStart, rangeEnd);
	const result = localSessions.slice();
	for (const session of webSessions) {
		const localEntry = result.find(a=>a.startTime == session.startTime);
		if (localEntry) {
			// if local-entry exists, use it instead of the web-entry (we just gotta pick one)

			/*const localEntry_new = Clone(localEntry);
			localEntry_new.VSet("_online", true);
			result[result.indexOf(localEntry)] = localEntry_new;*/

			// just mutate the object; we're not ruining anything (and this way we prevent detail-panel-eeg-processor from having to restart the processing)
			//session.VSet("_online", true, {prop: {enumerable: false}});
			localEntry._online = true; // mark local-entry as also having online data
			//result.push(localEntry); // already added at array declaration
		} else {
			/*const session_new = Clone(session);
			session_new.VSet("_online", true);
			result.push(session_new);*/

			// just mutate the object; we're not ruining anything (and this way we perhaps prevent some UIs refreshing without need)
			//session.VSet("_online", true, {prop: {enumerable: false}});
			session._online = true;
			result.push(session);
		}
	}
	return result.OrderBy(a=>a.startTime);
});
//export const localSessionMap = new ObservableMap<number, EngineSessionInfo>();
export const localSessionMap = observable.map<number, EngineSessionInfo>({}, {deep: false}); // have shallow, so that entries in map are not proxies (ensuring no reference-mismatches)
export const UpdateLocalSessionsMap_callOnceTimer = new Timer(0, ()=>UpdateLocalSessionsMap(), 1);
// todo: probably make-so only the needed range is read
export function UpdateLocalSessionsMap(refreshAll = false, sessionsToRefresh_folderNames = [] as string[]) {
	RunInAction("UpdateLocalSessionMap", ()=>{
		const knownFolders = Array.from(localSessionMap.values()).map(a=>moment(a.startTime).format(sessionFolderName_startTimeFormatStr));
		const foldersToNotRefresh = refreshAll ? [] : knownFolders.Exclude(...sessionsToRefresh_folderNames);
		const newSessionInfos = GetNewLocalSessions(foldersToNotRefresh) as EngineSessionInfo[];
		for (const session of newSessionInfos) {
			//session.VSet("_local", true, {prop: {enumerable: false}});
			session._local = true;

			//localSessionMap.set(session.id, session);
			localSessionMap.set(session.startTime, session); // store by start-time (not id), because id can change after uploading
		}
	});
}
export const GetSessionsIntersectingRange_Local = StoreAccessor(s=>(userID: string|n, rangeStart: number, rangeEnd?: number): EngineSessionInfo[]=>{
	if (localSessionMap.size == 0 && UpdateLocalSessionsMap_callOnceTimer.startTime == null) {
		UpdateLocalSessionsMap_callOnceTimer.Start();
	}

	return Array.from(localSessionMap.values()).filter(session=>{
		//if (session.creator != userID) return false; // uncomment later, once multiple people using
		const inRange = session.endTime >= rangeStart && (rangeEnd == null || session.startTime < rangeEnd);
		return inRange;
	}).OrderBy(a=>a.startTime);
});
export const GetSessionsIntersectingRange_Web = StoreAccessor(s=>(userID: string|n, rangeStart: number, rangeEnd?: number): EngineSessionInfo[]=>{
	const closedSessionsInRange = GetDocs({
		queryOps: [
			new WhereOp("creator", "==", userID),
			new WhereOp("endTime", ">=", rangeStart), // make sure session ended after range's start
			//rangeEnd != null && new WhereOp("startTime", "<", rangeEnd), // make sure session started prior to range's end // commented, since can only use range-filter on one field
		].filter(a=>a),
	}, a=>a.sessions).filter(session=>{
		return rangeEnd == null || session.startTime < rangeEnd; // make sure session started prior to range's end
	});

	/*const ongoingSessions = GetDocs({
		queryOps: [new WhereOp("creator", "==", userID), new WhereOp("endTime", "==", null)],
	}, a=>a.sessions);
	const ongoingSessionsInRange = ongoingSessions.filter(session=>(rangeEnd == null || session.startTime < rangeEnd));*/

	const allSessionsInRange = closedSessionsInRange; //.concat(ongoingEventsInRange);
	return allSessionsInRange.OrderBy(a=>a.startTime);
});
/*#* Returns the sessions for the given user, which intersect with the time period specified. */
/*export const GetSessionsIntersectingRange_ByWeek = StoreAccessor(s=>(userID: string, rangeStart: number, rangeEnd?: number): EngineSessionInfo[]=>{
	const weekIDs = [TODO];
	Assert(weekIDs.length > 10, "Cannot get sessions by-week, for a period larger than 10 weeks.");

	const sessionsRoughlyInRange = GetDocs({
		queryOps: [new WhereOp("creator", "==", userID), new WhereOp("week", "in", weekIDs)],
	}, a=>a.sessions);
	const sessionsInRange = sessionsRoughlyInRange.filter(session=>{
		return session.endTime >= rangeStart // make sure event ended after range's start
			&& (rangeEnd == null || session.startTime < rangeEnd); // make sure event started prior to range's end
	});

	return sessionsInRange.OrderBy(a=>a.startTime);
});*/

export const GetTimelineEvent = StoreAccessor(s=>(id: string)=>{
	return GetDoc({}, a=>a.timelineEvents.get(id));
});

/*export const GetInProgressTimelineEvents = StoreAccessor(s=>(timelineID: string)=>{
	return GetDocs({
		queryOps: [
			new WhereOp("timeline", "==", timelineID),
			new WhereOp("endTime", "<", ""), // `if value < ""` means "if value is null"
		],
	}, a=>a.timelineEvents).OrderBy(a=>a.startTime);
});*/