import {Light, LightType} from "../../Store/firebase/lights/@Light";
import {ToJSON, E, Assert, GetStackTraceStr} from "js-vextensions";
import {LIFXConnectionType} from "../../Store/main/effects/lights";
import {ApplyLIFXCommandOverLAN, InNativeApp, InDesktop, InAndroid, nativeBridge} from "../Bridge/Bridge_Native";
import {GetLight, GetLights, GetLights_WithUserTag} from "../../Store/firebase/lights";
import {store} from "../../Store/index.js";
import {AssertWarn} from "mobx-firelink";
import {RunUserScript, RunUserScript_CallArgsObj} from "../UserScripts/ScriptRunner";
import {MeID} from "../../Store/firebase/users";
import {CallExtensionFunction, ExtensionPresent} from "./ExtensionLink";
import {RobustFetch} from "./RobustFirebaseSet";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {AddErrorMessage} from "web-vcore";

export function SetLightBrightness(brightness: number, options = null) {
	SessionLog(`Setting light brightness: ${brightness}`);
	const command = E({
		url: "https://api.lifx.com/v1/lights/all/state",
		power: brightness >= .01 ? "on" : "off",
		brightness,
	}, options);

	RunLightCommand_LIFX(command, store.main.effects.lights.lifxConnection);
}
export const lifx_lightReset_defaultConfig = new Light({
	type: LightType.LIFXBulb,
	effectJSON_functionStr: `
		return {
			url: "https://api.lifx.com/v1/lights/all/state",

			//power: on ? "on" : "off",
			power: "off",
			color: "white",
			//brightness: on ? 1 : 0,
			brightness: 0,
		};
	`.AsMultiline(0),
});
export function ResetLight_LIFX(allowUserOverride = true) {
	const uiState = store.main.effects.lights;
	const lightTagForReset = store.main.settings.lightTagForReset;
	if (allowUserOverride && lightTagForReset && lightTagForReset.trim().length) {
		const lightForReset = GetLights_WithUserTag(lightTagForReset).filter(a=>a.type == LightType.LIFXBulb).Random();
		if (lightForReset) {
			//PlayLightConfig(light, -1);
			PlayLightConfig_LIFX(lightForReset, .99999);
		}
	} else {
		PlayLightConfig_LIFX(lifx_lightReset_defaultConfig, store.main.effects.lights.lifxConnection);
	}
}

export async function PlayLightConfig_LIFX(light: Light, brightness: number) {
	//SessionLog(`Playing light config:"${light.name}" Brightness:${brightness}`);

	// wrap with try, so that buggy user-script doesn't break engine execution
	let command;
	try {
		/*command = await RunUserScript(`function(brightness) {
			"use strict";
			${light.effectJSON_functionStr}
		}`, brightness);*/
		command = await RunUserScript_CallArgsObj(light.effectJSON_functionStr, {brightness});
		Assert(command != null && typeof command == "object", "Script did not return an object.");
	} catch (ex) {
		const message = "Failed to generate light command.";
		console.warn(message, ex);
		// only show notification if user is the config's creator (else, user-script could throw error that gives malicious instructions)
		//if (light.creator == MeID() || light.creator == null) AddNotificationMessage(`${message} ${ex}`);
		//if (light.creator == MeID()) AddNotificationMessage(`${message} ${ex}`);
		AddErrorMessage(`${message} ${ex}`, ex?.stack ?? GetStackTraceStr());
		return;
	}

	RunLightCommand_LIFX(command, store.main.effects.lights.lifxConnection);
}

let lastLightCommandJSON: string;
let lastLightCommandRunTime = 0;
export function RunLightCommand_LIFX(command: any, connectionType: LIFXConnectionType) {
	// json contains url and token, but "options" (sent to lifx end-point) should not, so strip
	const options = E(command);
	delete options.url;
	delete options.token; // maybe remove this line later, once all Light entries in db have the token field removed

	const uiState = store.main.effects.lights;
	//const lifxWebToken = options.token ?? uiState.lifx_webToken;
	const lifxWebToken = uiState.lifx_webToken;

	const fetchOptions: RequestInit = {
		method: command.method || "PUT",
		mode: "cors", // no-cors, cors, *same-origin
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		credentials: "same-origin", // include, *same-origin, omit
		headers: {
			Accept: "*#/*",
			Authorization: `Bearer ${lifxWebToken}`,
			"accept-encoding": "gzip, deflate",
			"Content-Type": "application/json",
			//"Content-Type": "application/x-www-form-urlencoded",
		},
		redirect: "follow", // manual, *follow, error
		referrer: "no-referrer", // no-referrer, *client
		body: JSON.stringify(options),
	};

	// resolve "auto" connection type, to a specific connection-type
	if (connectionType == LIFXConnectionType.Auto) {
		if (InNativeApp(0)) connectionType = LIFXConnectionType.LAN;
		else if (ExtensionPresent()) connectionType = LIFXConnectionType.WebDirect;
		else connectionType = LIFXConnectionType.WebProxy;
	}

	// if using web calls, prevent the same command from being run in quick sequence (if over lan, commands can be spammed without issue)
	if (connectionType.IsOneOf(LIFXConnectionType.WebProxy, LIFXConnectionType.WebDirect)) {
		const commandJSON = ToJSON(command);
		// todo: make-so this only blocks commands that are within a couple seconds of each other, or that are automated duplicates
		if (commandJSON == lastLightCommandJSON && Date.now() - lastLightCommandRunTime < 1000 * 60 * 5) {
			SessionLog("Skipped running light-command over web-api, because identical to last (and recently run) command.");
			return;
		}
		// if we're going through with the web-api calls, mark our having done so
		lastLightCommandJSON = commandJSON;
		lastLightCommandRunTime = Date.now();
	}

	if (connectionType == LIFXConnectionType.WebProxy) {
		//RobustFetch(`https://vproxy1.herokuapp.com/${command.url}`, fetchOptions); // RobustFetch doesn't yet support all the fetchOptions specified
		fetch(`https://vproxy1.herokuapp.com/${command.url}`, fetchOptions); //, {mode: "no-cors"}));
	} else if (connectionType == LIFXConnectionType.WebDirect) {
		if (InDesktop()) {
			nativeBridge.Call("RunLIFXCommand_WebDirect", command.url, fetchOptions);
		} else if (InAndroid(0)) {
			RobustFetch(command.url, fetchOptions);
		} else {
			CallExtensionFunction("Fetch", command.url, fetchOptions);
			/*const payload = new Uint16Array([0]);
			CallExtensionFunction("SendUDPPacket", lightInfo.ip, lightInfo.port, payload);*/
		}
	} else if (connectionType == LIFXConnectionType.LAN) {
		ApplyLIFXCommandOverLAN(command);
	}
}