import React from "react";
import {InfoButton, minuteInMS, Observer, RunInAction_Set, TextPlus, VDateTime} from "web-vcore";
import {GetEntries} from "js-vextensions";
import Moment from "moment";
import {CheckBox, Column, Row, RowLR, Select, Spinner, Text, TextArea, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {AutoEndType} from "../../../../Store/firebase/fbaConfigs/@FBAConfig.js";
import {GetLights_WithUserTag} from "../../../../Store/firebase/lights.js";
import {LightType} from "../../../../Store/firebase/lights/@Light.js";
import {GetScripts_WithUserTag} from "../../../../Store/firebase/scripts.js";
import {store} from "../../../../Store/index.js";
import {EffectPreviewButton} from "../../../@Shared/TagTargetEntryUI.js";
import {colors} from "../../../../Utils/UI/GlobalStyles.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";
import {SettingUI} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@Shared/SettingUI.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.general, (c, v)=>c.general = v);

const splitAt = 200;

@Observer
export class General_ConfigUI extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		const uiState = store.main.tools.engine;
		const startupScript_preview = config.startupScriptTag ? GetScripts_WithUserTag(config.startupScriptTag).Random() : null;
		const resetScreenLight_preview = config.resetScreenLightTag ? GetLights_WithUserTag(config.resetScreenLightTag).filter(a=>a.type == LightType.Screen).Random() : null;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>General</Row>

				{!client &&
				<RowLR mt={5} splitAt={splitAt} style={{color: colors.nightTint}}>
					<TextPlus info="After the given delay, the prompt-effect(s) with the smallest snooze-delay will immediately play, with the others playing later. (their relative delay-offsets preserved)">Initial delay:</TextPlus>
					<TimeSpanInput enabled={enabled} largeUnit="hour" smallUnit="minute" value={config.initialDelay / minuteInMS} onChange={val=>ChangeConfig(c=>c.initialDelay = val * minuteInMS)}/>
				</RowLR>}
				{!client &&
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<CheckBox text="Auto-end:" enabled={enabled} value={config.autoEnd_enabled} onChange={val=>ChangeConfig(c=>c.autoEnd_enabled = val)}/>
						<InfoButton ml={5} text="At the given time / after the given duration (depending on type), the session will be automatically stopped/ended."/>
					</Row>
					<Select options={GetEntries(AutoEndType)} enabled={enabled && config.autoEnd_enabled} value={config.autoEnd_type} onChange={val=>ChangeConfig(c=>c.autoEnd_type = val)}/>
					{config.autoEnd_type == AutoEndType.AtTimeOfDay &&
						<VDateTime dateFormat={false} timeFormat="HH:mm:ss" enabled={enabled && config.autoEnd_enabled} value={Moment(config.autoEnd_time)} onChange={(val: /*Moment.Moment*/ any)=>{
							if (val == null) return;
							ChangeConfig(c=>c.autoEnd_time = val.valueOf());
						}}/>}
					{config.autoEnd_type == AutoEndType.AfterDuration &&
						<TimeSpanInput enabled={enabled && config.autoEnd_enabled} largeUnit="hour" smallUnit="minute" value={config.autoEnd_delay / minuteInMS} onChange={val=>ChangeConfig(c=>c.autoEnd_delay = val * minuteInMS)}/>}
				</RowLR>}
				<Row mt={5} center>
					<CheckBox text="Enable sleep-blockers" enabled={enabled} value={config.sleepBlockers_enabled} onChange={val=>ChangeConfig(c=>c.sleepBlockers_enabled = val)}/>
					<InfoButton ml={5} text="If enabled, engine will try to keep device awake, wifi enabled, etc. (costs performance on some devices)"/>
				</Row>
				{!client &&
				<RowLR mt={5} splitAt={splitAt}>
					<TextPlus info="A random script with the given tag will be run (and have its effect played, with 100% intensity) when the session is started.">Startup script tag:</TextPlus>
					<TextInput enabled={enabled} value={config.startupScriptTag} onChange={val=>ChangeConfig(c=>c.startupScriptTag = val)}/>
					<EffectPreviewButton group="scripts" entry={startupScript_preview} enabled={startupScript_preview != null} style={{marginLeft: 5}}/>
				</RowLR>}
				{!client &&
				<RowLR mt={5} splitAt={splitAt}>
					<TextPlus info={`
						A random screen-light with the given tag will be run (and have its effect played, with 100% intensity) when an active/prompt screen-light effect is ended.

						(If field left empty, the screen-light overlay just gets hidden, showing the Lucid Frontier UI.)
					`.AsMultiline(0)}>Reset screen light tag:</TextPlus>
					<TextInput enabled={enabled} value={config.resetScreenLightTag} onChange={val=>ChangeConfig(c=>c.resetScreenLightTag = val)}/>
					<EffectPreviewButton group="lights" entry={resetScreenLight_preview} enabled={resetScreenLight_preview != null} style={{marginLeft: 5}}/>
				</RowLR>}
				{!client &&
				<RowLR mt={5} splitAt={splitAt}>
					<Text mr={5}>Default voice, tag:</Text>
					<TextInput enabled={enabled} style={{width: 80}} value={config.defaultVoice_soundTag} onChange={val=>ChangeConfig(c=>c.defaultVoice_soundTag = val)}/>
					<Text ml={5} mr={5}>Vol. mult.:</Text>
					<Spinner min={0} step={0.01} enabled={enabled} value={config.defaultVoice_volumeMultiplier} onChange={val=>ChangeConfig(c=>c.defaultVoice_volumeMultiplier = val)}/>
				</RowLR>}
				{/*!client &&
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Global volume mult.:</Text>
					<Column>
						{config.globalVolumeMultiplier.values.map((value, index)=>{
							return (
								<Row key={index}>
									<CheckBox enabled={enabled} value={!value.disabled} onChange={val=>{
										ChangeConfig(c=>{
											if (val) {
												delete c.globalVolumeMultiplier.values[index].disabled;
											} else {
												c.globalVolumeMultiplier.values[index].disabled = true;
											}
										});
									}}/>
									<Spinner ml={5} min={0} step={0.01} enabled={enabled}
										value={value.value}
										onChange={val=>ChangeConfig(c=>c.globalVolumeMultiplier.values[index].value = val)}/>;
								</Row>
							);
						})}
					</Column>
				</RowLR>*/}
				{!client &&
				<SettingUI mt={5} splitAt={splitAt} enabled={enabled} displayType="number_percent" text="G: Volume mod:"
					info={`
						Modifies the volume of all TTS and audio-files played by the engine. (100% means no modification)
						Note: Values >100% might have no effect. (depending on other volume multipliers being applied)
					`.AsMultiline(0)}
					data={config.globalVolumeMultiplier} dataSetter={a=>ChangeConfig(c=>c.globalVolumeMultiplier = a)}/>}
				{!client &&
				<SettingUI mt={5} splitAt={splitAt} enabled={enabled} displayType="number_percent" text="G: Alarm-restart interval mod:"
					info={`
						Modifies the auto-restart intervals of all alarms played by the engine. (100% means no modification)
					`.AsMultiline(0)}
					data={config.globalAlarmRestartIntervalMultiplier} dataSetter={a=>ChangeConfig(c=>c.globalAlarmRestartIntervalMultiplier = a)}/>}
				{!client &&
				<RowLR mt={5} splitAt={splitAt}>
					<TextPlus info={`For each mouse-move event, if distance from current-pos to pos-at-start-of-window is less than this min-dist, the event is ignored.`}>Mouse-move min-dist:</TextPlus>
					<Spinner enabled={enabled} step={.1} value={config.mouseMove_minDistToTrigger} onChange={val=>ChangeConfig(c=>c.mouseMove_minDistToTrigger = val)}/>
					<Text ml={5}>Compare window:</Text>
					<Spinner ml={5} enabled={enabled} step={1} value={config.mouseMove_compareWindow} onChange={val=>ChangeConfig(c=>c.mouseMove_compareWindow = val)}/>
					<Text ml={5}>ms</Text>
				</RowLR>}
				{!client &&
				<RowLR mt={5} splitAt={splitAt}>
					<TextPlus info={`
						If enabled, mouse/touch locations (while input held down) will be tracked, and repeat moves to those locations will be ignored.
						
						Purpose: To mitigate occurence, during sleep, where finger is held on screen and breathing motions cause "false inputs" by causing "repeated movements" over an area of the screen.
					`.AsMultiline(0)}>Mouse-move repeat-reject:</TextPlus>
					<CheckBox enabled={enabled} value={config.mouseMoveRepeatReject_enabled} onChange={val=>ChangeConfig(c=>c.mouseMoveRepeatReject_enabled = val)}/>
					<Text ml={5}>Round positions to:</Text>
					<Spinner ml={5} enabled={enabled && config.mouseMoveRepeatReject_enabled} step={.1} value={config.mouseMoveRepeatReject_roundPositionsTo} onChange={val=>ChangeConfig(c=>c.mouseMoveRepeatReject_roundPositionsTo = val)}/>
				</RowLR>}
				<RowLR mt={5} splitAt={splitAt}>
					<Text mr={5}>In session-ui, hide:</Text>
					<CheckBox text="Status bar" enabled={enabled} value={config.sessionUI_hideStatusBar} onChange={val=>ChangeConfig(c=>c.sessionUI_hideStatusBar = val)}/>
					<CheckBox ml={5} text="Nav bar" enabled={enabled} value={config.sessionUI_hideNavBar} onChange={val=>ChangeConfig(c=>c.sessionUI_hideNavBar = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text mr={5}>In lock-overlay, hide:</Text>
					<CheckBox text="Status bar" enabled={enabled} value={config.lockOverlay_hideStatusBar} onChange={val=>ChangeConfig(c=>c.lockOverlay_hideStatusBar = val)}/>
					<CheckBox ml={5} text="Nav bar" enabled={enabled} value={config.lockOverlay_hideNavBar} onChange={val=>ChangeConfig(c=>c.lockOverlay_hideNavBar = val)}/>
				</RowLR>

				{/*<Row center>
					<Text>Engine context: </Text>
					<Select options={GetEntries(EngineContext)} value={engineContext} onChange={val=>{
						store.dispatch(new ACTSet(a=>a.main.tools.fba.engineContext, val));
					}}/>
					<InfoButton ml={5} text={`
						WebOnly: For components with web and native implementations, uses web; disables the rest. (however, most components have a web implementation)
						WebAndNative: For components with web and native implementations, uses native; for the rest, uses web.
						NativeOnly: For components with web and native implementations, uses native; disables the rest. (useful as it stops the app being forced-to-front, for preventing web-code sleep when screen is off)
					`.AsMultiline(0)}/>
				</Row>*/}
				{/*<Row>
					<Text>Prefer native over web: </Text>
					<CheckBox checked={screenshotMode} onChange={val=>this.SetState({screenshotMode: val})}/>
					<InfoButton ml={5} text="When in a native app, prefer native implementations of a function over its web equivalent."/>
				</Row>
				<Row>
					<Text>Use web functions that require focus: </Text>
					<CheckBox checked={screenshotMode} onChange={val=>this.SetState({screenshotMode: val})}/>
					<InfoButton ml={5} text="When in a native app, prefer native implementations of a function over its web equivalent."/>
				</Row>*/}
				<Column mt={5}>
					<CheckBox text="Notes" value={uiState.showNotes} onChange={val=>RunInAction_Set(this, ()=>uiState.showNotes = val)}/>
					{uiState.showNotes &&
					<TextArea mt={5} style={{marginTop: 5}} autoSize={true} /*enabled={enabled}*/ value={config.notes} onChange={val=>ChangeConfig(c=>c.notes = val)}/>}
				</Column>
			</Column>
		);
	}
}