import {FBAConfig_JourneyEntry} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyEntry.js";
import {EngineSessionComp} from "./EngineSessionComp.js";

export class JourneyEntryComp extends EngineSessionComp<FBAConfig_JourneyEntry> {}

/*import {GetAsync} from "mobx-firelink";
import {RunInAction, TextSpeaker} from "web-vcore";
import {AssertWarn, Clone, GetRandomNumber, Timer} from "js-vextensions";
import {Text} from "react-vcomponents";
import {AddEntity} from "../../../Server/Commands/AddEntity.js";
import {SetUserEntityTags} from "../../../Server/Commands/SetUserEntityTags.js";
import {UpdateJournalEntry} from "../../../Server/Commands/UpdateJournalEntry.js";
import {GetEntity, IsMetaEntity} from "../../../Store/firebase/entities.js";
import {Entity, SimpleVisibility_AsVisibleToGroup, EntryVisibility} from "../../../Store/firebase/entities/@Entity.js";
import {FBAConfig_Journey} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Journey.js";
import {FBAConfig_JourneyEntry} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyEntry.js";
import {Sequence, SequenceItem, TriggerSet} from "../../../Store/firebase/fbaConfigs/@TriggerSet.js";
import {JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {GetLights_WithUserTag} from "../../../Store/firebase/lights.js";
import {GetUser, GetUserEntityTags, MeID} from "../../../Store/firebase/users.js";
import {store} from "../../../Store/index.js";
import {EntityCategory} from "../../../Store/main/tools/journey.js";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {LightPlayer} from "../../../Utils/EffectPlayers/LightPlayer.js";
import {ResetLight_Kasa} from "../../../Utils/Services/Kasa.js";
import {FBASession, GetLiveJourneySession, TriggerPackage} from "../../../Engine/FBASession.js";
import {DreamRecallComp, NarrateText, TimeAgoToNarrateText} from "./DreamRecallComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {JourneyComp, JourneyInputMode, AlarmsPhase} from "./JourneyComp.js";
import {GetEntitiesMatchingSearchText, entityCategories_tags_values, GetEntityLayoutInfo, entityCategories_tags, ParseSearchTextRaw} from "../../../UI/Tools/Journey/BottomPanel/EntitiesPanel.js";

export class JourneyEntryComp extends EngineSessionComp<FBAConfig_JourneyEntry> {
	constructor(session: FBASession, config: FBAConfig_JourneyEntry) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	searchingEntity = false;

	// if entry.normal submode
	targetJournalEntryIndex: number;
	targetSegmentIndex: number;
	targetEntityIndex: number; // index of -1 signifies the segment's anchor/reference entity
	InitializeTargetsIfNotYet() {
		if (this.targetJournalEntryIndex != null) return;
		this.SeekToEnd();
	}
	SeekToEnd(speakEntity = true) {
		const dreamRecallComp = this.s.Comp(DreamRecallComp);
		this.targetJournalEntryIndex = dreamRecallComp.journalEntries_sorted.length - 1;
		this.targetSegmentIndex = 1000;
		this.targetEntityIndex = 1000;
		this.Normal_GoPrevEntity(speakEntity);
	}
	AreTargetsValid() {
		const {segment, entity} = ResolveTargets(this);
		// if target entity is resolved, OR target segment is a valid wake-segment, and target-entity-index is referencing its anchor-entity (even if null, that's a valid target, since it represents the segment itself)
		return entity != null || (segment?.wakeTime != null && this.targetEntityIndex == -1);
	}
	Normal_GoPrevEntity(speakEntity = true) {
		GoPrevEntity(this, speakEntity);
	}
	Normal_GoNextEntity() {
		GoNextEntity(this);
	}
	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}
	SpeakCurrentEntity(prefixTextToSpeak = "", justInsertedEntities = [] as Entity[]) {
		const {journalEntry, segment, entityID, entity, entityIsRef} = ResolveTargets(this, justInsertedEntities);
		if (segment.wakeTime != null && this.targetEntityIndex == -1) {
			const timeAgo = Date.now() - segment.wakeTime;
			this.NarrateText(`${prefixTextToSpeak} Awoke ${TimeAgoToNarrateText(timeAgo)}`);
		} else if (entity != null) {
			this.NarrateText(`${prefixTextToSpeak} ${entityIsRef ? "Reference" : ""} ${entity.name}`);
		} else {
			this.NarrateText(`${prefixTextToSpeak} Target entity missing.`);
		}
	}

 	// if entry.search submode
	search_entityIndex = 0;
	DeleteLastSearchCharOrLeaveSearch() {
		const uiState = store.main.tools.journey.entities;
		if (uiState.searchText.length > 0 && uiState.searchText != "^") { // don't delete the ^ char (it's just a marker telling to require search-from-start)
			RunInAction("DeleteLastSearchCharOrLeaveSearch", ()=>uiState.searchText = uiState.searchText.slice(0, -1));
			//const prefixText = `Backspace, to: ${uiState.searchText.split("").join(", ")} Target now: `;
			//this.NarrateText(prefixText);
			this.Search_SpeakCurrentEntity();
		} else {
			this.searchingEntity = false;
			RunInAction("DeleteLastSearchCharOrLeaveSearch", ()=>uiState.searchText = ""); // fully clear search-text, so category-grouping in ui is enabled again
			this.NarrateText("Canceled search");
		}
	}
	GetEntitiesMatchingSearch() {
		//const dreamRecallComp = this.s.Comp(DreamRecallComp);
		const uiState = store.main.tools.journey.entities;
		const userTags = GetUser(MeID())?.entityTags?.entities ?? {};
		let entities = GetEntitiesMatchingSearchText(uiState.searchText)
			.filter(entity=>{
				//return uiState.selectedCategory == null || userTags[a._key]?.includes(uiState.selectedCategory);
				return entityCategories_tags_values.some(tag=>userTags[entity._key]?.includes(tag));
			});
		const entities_layoutInfos = entities.map(entity=>GetEntityLayoutInfo(entity))
			.OrderByDescending(a=>a.hitCount);
			// sort by category, so that order matches what's seen in the select-entities panel
			/*.OrderBy(a=>{
				const firstCategoryTagEntityHas = entityCategoryTags.find(tag=>userTags[a.entity._key]?.includes(tag));
				return entityCategoryTags.indexOf(firstCategoryTagEntityHas);
			});*#/
		entities = entities_layoutInfos.map(a=>a.entity); // re-order entities by hit-count
		return entities;
	}
	Search_GoPrevEntity() {
		this.search_entityIndex = (this.search_entityIndex - 1).KeepAtLeast(0);
		this.Search_SpeakCurrentEntity();
	}
	Search_GoNextEntity() {
		const maxIndexForExtantEntity = this.GetEntitiesMatchingSearch().length - 1;
		const uiState = store.main.tools.journey;
		this.search_entityIndex = (this.search_entityIndex + 1).KeepAtMost(maxIndexForExtantEntity + (uiState.allowScreenlessEntityAdding ? 3 : 0)); // add three slots, to allow for new-entry creation (one for each category)
		this.Search_SpeakCurrentEntity();
	}
	Search_SpeakCurrentEntity(prefixTextToSpeak = "") {
		const candidateEntities = this.GetEntitiesMatchingSearch();
		const targetEntity = candidateEntities[this.search_entityIndex];
		const targetEntity_text = targetEntity ? targetEntity.name : "null, as " + entityCategories_tags[this.Search_GetCategoryForNewEntity()!];

		let candidateCountText = `, with ${candidateEntities.length} total`;
		//if (targetEntity == null) candidateCountText = ""; // don't add (arguably) useless text about having 0-candidates, if we're already saying that the target-entity is null
		
		const uiState = store.main.tools.journey.entities;
		const {searchText} = ParseSearchTextRaw(uiState.searchText);

		this.NarrateText(`${prefixTextToSpeak}${targetEntity_text}${candidateCountText}, for search: ${searchText.split("").join(", ")}`);
	}
	Search_GetCategoryForNewEntity() {
		const candidateEntities = this.GetEntitiesMatchingSearch();
		if (this.search_entityIndex < candidateEntities.length) return null;
		const categoryForNew: EntityCategory = (["people", "objects", "concepts"] as const)[(this.search_entityIndex - candidateEntities.length).KeepAtMost(2)];
		return categoryForNew;
	}
	async Search_InsertTargetEntity() {
		const uiState = store.main.tools.journey.entities;
		const {searchText} = ParseSearchTextRaw(uiState.searchText);
		if (searchText.length == 0) {
			this.NarrateText("No search-text entered.");
			return;
		}

		const candidateEntities = this.GetEntitiesMatchingSearch();
		let targetEntity = candidateEntities[this.search_entityIndex];
		let completionMessage: string;
		if (targetEntity == null) {
			if (!store.main.tools.journey.allowScreenlessEntityAdding) {
				this.NarrateText("Canceling; screenless entity-adding is disabled.");
				return;
			}
			const category = this.Search_GetCategoryForNewEntity()!;
			const newEntry = new Entity({
				name: searchText,
				tags: [entityCategories_tags[category]],
				//visibleToGroups: SimpleVisibility_AsVisibleToGroup(store.main.content.lastEntityVisibility, MeID()),
				// just always enter new entities as private, for this interface; safer, since user can't confirm visibility like they can with ui
				// (and would be a pain to have to think about that question during the night anyway)
				visibleToGroups: SimpleVisibility_AsVisibleToGroup(EntryVisibility.private, MeID()!),
			})
			const id = await new AddEntity({entity: newEntry}).Run();
			await new SetUserEntityTags({entityGroup: "entities", entityID: id, entityTags: newEntry.tags}).Run();
			targetEntity = await GetAsync(()=>GetEntity(id)!);
			completionMessage = "Inserted new entity: "; //+ newEntry.name;
		} else {
			completionMessage = "Inserted: "; //+ targetEntity.name;
		}

		//const {journalEntry, segment, entityID, entity, entityIsRef} = this.ResolveTargets();
		const {journalEntry, segment, entity} = ResolveTargets(this);
		if (journalEntry == null) { this.NarrateText("Canceling; no target journal-entry."); return; }
		if (segment == null) { this.NarrateText("Canceling; no target segment."); return; }
		if (segment.wakeTime != null) { this.NarrateText("Canceling; cannot insert into wake segment."); return; }
		if (entity == null && this.targetEntityIndex != -1) { this.NarrateText("Canceling; no target entity to insert after."); return; }
		
		const segments_new = Clone(journalEntry.segments) as JournalSegment[];
		const segment_new = segments_new[this.targetSegmentIndex];

		// todo
		this.NarrateText("TO-DO: This feature needs updating...");
		if (1) return;

		/*segment_new.entitiesSequence!.Insert(this.targetEntityIndex + 1, targetEntity._key);
		await new UpdateJournalEntry({
			id: journalEntry._key,
			updates: {segments: segments_new},
		}).Run();
		
		RunInAction("JourneyEntry_Search_InsertTargetEntity", ()=>{
			// reset search state
			//uiState.searchText = "^";
			uiState.searchText = ""; // fully clear search-text, so category-grouping in ui is enabled again
			this.searchingEntity = false;
			this.search_entityIndex = 0;
			// increment target-entity-index, to equal the index of the just-inserted entity in sequence
			this.targetEntityIndex++;
		});

		// since entity was inserted, enter alarm-wait phase (same as if entity was dragged onto segment)
		if (GetLiveJourneySession() != null && GetLiveJourneySession()!.Comp(AlarmsComp).phase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving, AlarmsPhase.AlarmWait)) {
			GetLiveJourneySession()!.Comp(AlarmsComp).StartPhase_AlarmWait();
		}

		this.SpeakCurrentEntity(completionMessage, [targetEntity]);*#/
	}

	// players
	//soundPlayer = new SoundPlayer(); // we use SoundPlayer (not TextSpeaker), since it's project-specific so understands Sound-objects directly
	textSpeaker = new TextSpeaker();

	GetTriggerPackages() {
		return [
			new TriggerPackage("JourneyEntry_PrevEntity", this.c.prevEntity_triggerSet, this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Entry) return;
				if (this.searchingEntity) {
					this.Search_GoPrevEntity();
				} else {
					this.Normal_GoPrevEntity();
				}
			}),
			new TriggerPackage("JourneyEntry_NextEntity", this.c.nextEntity_triggerSet, this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Entry) return;
				if (this.searchingEntity) {
					this.Search_GoNextEntity();
				} else {
					this.Normal_GoNextEntity();
				}
			}),
			// NOTE: Atm, these triggers have their inputs hard-coded.
			new TriggerPackage("JourneyEntry_HoldUp", new TriggerSet({sequences: [new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {})]}), this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Entry) return;
				if (this.searchingEntity) {
					this.DeleteLastSearchCharOrLeaveSearch();
				} else {
					const dreamRecallComp = this.s.Comp(DreamRecallComp);
					const validEntityIDs = new Set(dreamRecallComp.entities_all.filter(a=>!IsMetaEntity(a)).map(a=>a._key)); // never random-seek to a meta-entity (even if it's a valid target moving forward/backward)
					const validTargetInfos = [] as {journalEntryIndex: number, segmentIndex: number, entityIndex: number}[];
					for (const [i_dream, dream] of dreamRecallComp.journalEntries_sorted.entries()) {
						for (const [i_segment, segment] of dream.segments.entries()) {
							if (segment.wakeTime != null) continue;
							for (const [i_entity, entityID] of segment.entitiesSequence!.entries()) {
								if (validEntityIDs.has(entityID)) {
									validTargetInfos.push({journalEntryIndex: i_dream, segmentIndex: i_segment, entityIndex: i_entity});
								}
							}
						}
					}
					const chosenTargetInfo = validTargetInfos.Random();
					this.targetJournalEntryIndex = chosenTargetInfo.journalEntryIndex;
					this.targetSegmentIndex = chosenTargetInfo.segmentIndex;
					this.targetEntityIndex = chosenTargetInfo.entityIndex;
					this.SpeakCurrentEntity();

					// todo: maybe change/expand to (also?) open "more-options menu"
				}
			}),
			new TriggerPackage("JourneyEntry_HoldDown", new TriggerSet({sequences: [new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {})]}), this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Entry) return;
				if (this.searchingEntity) {
					this.Search_InsertTargetEntity();
				} else {
					this.SeekToEnd(false);
				}
			}),
		];
	}
	GetStatusUI() {
		return <Text style={{whiteSpace: "pre"}}>{`TODO`.AsMultiline(1)}</Text>;
	}

	OnStop() {
		this.StopTextSpeaker();
	}

	// entry-mode
	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}
}

export type JourneyEntryComp_SharedProps = Pick<JourneyEntryComp,
	"s"
	| "InitializeTargetsIfNotYet" | "AreTargetsValid" | "SpeakCurrentEntity"
	| "targetEntityIndex" | "targetSegmentIndex" | "targetJournalEntryIndex"
>;
export function ResolveTargets(self: JourneyEntryComp_SharedProps, justInsertedEntities = [] as Entity[]) {
	const dreamRecallComp = self.s.Comp(DreamRecallComp);
	//const journalEntry = dreamRecallComp.journalEntries_sorted.find(a=>a._key == self.targetJournalEntryID);
	const journalEntry = dreamRecallComp.journalEntries_sorted[self.targetJournalEntryIndex];
	const segment = journalEntry?.segments[self.targetSegmentIndex];
	const entityIsRef = self.targetEntityIndex < 0;
	const entityID = entityIsRef ? segment?.anchorEntity : segment?.entitiesSequence![self.targetEntityIndex];
	let entity = entityID ? dreamRecallComp.entities_all.find(a=>a._key == entityID) : null;
	// workaround for entity not being found in entities_all, if it was just inserted (since autorun doesn't run right away, and we don't want an arguably fragile/delay-causing waiting system)
	if (entity == null) entity = justInsertedEntities.find(a=>a._key == entityID);
	return {journalEntry, segment, entityID, entity, entityIsRef};
}
export function GoPrevEntity(self: JourneyEntryComp_SharedProps, speakEntity = true) {
	self.InitializeTargetsIfNotYet();
	const dreamRecallComp = self.s.Comp(DreamRecallComp);
	do {
		const {journalEntry: oldJournalEntry, segment: oldSegment, entity: oldEntity} = ResolveTargets(self);
		if (self.targetEntityIndex > -1) { // -1 for anchor/ref entity
			self.targetEntityIndex--;
			//self.targetEntityIndex = (self.targetEntityIndex - 1).KeepAtMost(oldSegment.entitiesSequence.length - 1); 
		} else if (self.targetSegmentIndex > 0 && oldJournalEntry) {
			self.targetSegmentIndex--;
			const newSegment = oldJournalEntry.segments[self.targetSegmentIndex];
			if (newSegment) self.targetEntityIndex = newSegment.entitiesSequence!.length - 1;
		} else if (self.targetJournalEntryIndex > 0) {
			self.targetJournalEntryIndex--;
			const newEntry = dreamRecallComp.journalEntries_sorted[self.targetJournalEntryIndex];
			if (newEntry) {
				self.targetSegmentIndex = newEntry.segments.length - 1;
				const newSegment = newEntry.segments[self.targetSegmentIndex];
				if (newSegment) self.targetEntityIndex = newSegment.entitiesSequence!.length - 1;
			}
		} else {
			break;
		}
	}
	// if, after decrementing, target entity not found (ie. landed in empty journal-entry or segment), do another decrementing
	while (!self.AreTargetsValid());
	if (speakEntity) {
		self.SpeakCurrentEntity();
	}
}
export function GoNextEntity(self: JourneyEntryComp_SharedProps) {
	self.InitializeTargetsIfNotYet();
	const dreamRecallComp = self.s.Comp(DreamRecallComp);
	const {journalEntry: oldJournalEntry, segment: oldSegment} = ResolveTargets(self);
	do {
		if (oldSegment?.entitiesSequence && self.targetEntityIndex < oldSegment.entitiesSequence.length - 1) {
			self.targetEntityIndex++;
		} else if (oldJournalEntry && self.targetSegmentIndex < oldJournalEntry.segments.length - 1) {
			self.targetSegmentIndex++;
			self.targetEntityIndex = -1; // -1 for anchor/ref entity
		} else if (self.targetJournalEntryIndex < dreamRecallComp.journalEntries_sorted.length - 1) {
			self.targetJournalEntryIndex++;
			self.targetSegmentIndex = 0;
			self.targetEntityIndex = -1; // -1 for anchor/ref entity
		} else {
			break;
		}
	}
	// if, after incrementing, target entity not found (ie. landed in empty journal-entry or segment), do another incrementing
	while (!self.AreTargetsValid());
	self.SpeakCurrentEntity();
}*/