import {Assert, E, IsString, ToJSON} from "js-vextensions";
import {DBPath, GenerateUUID} from "mobx-firelink";
import {NotifyFBASessionLogChanged} from "../../Engine/FBASession.js";
import {firebaseConfig} from "../../Main";
import {ProcessMessage} from "../../Store/firebase/users/@ProcessMessage";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogType} from "../../UI/Tools/@Shared/LogEntry.js";
import {InAndroid} from "../../Utils/Bridge/Bridge_Native";
import {screenOn} from "../Bridge/Bridge_Native/PhoneSensors.js";
import {PluginGeneral} from "../Capacitor/GeneralPlugin.js";

/*export function SendChannelMessageOverUserProcessesBridge(channelMessage: any) {
	// maybe temp; for the remote-snooze command, use http api, since it works reliably even when screen is off
	/*const bridgeMessage = new BridgeMessage({functionCall_callID: Math.random(), functionCall_name: "Remote_SnoozeFail", functionCall_args: []});
	const channelMessage = bridgeMessage;*#/

	const collectionPath = DBPath(`userExtras/${MeID()}/processMessages`);
	const sendTime = Date.now();
	const documentID = sendTime.toString();
	const processMessage = new ProcessMessage({fromProcessID: processID, sendTime, message: channelMessage});

	return RobustFirebaseSet(collectionPath, documentID, processMessage);
}*/
export function SendUserProcessesBridgeMessage_RobustFirebaseSet(processMessage: any) {
	//const collectionPath = DBPath({}, `userExtras/${MeID()}/processMessages`);
	const collectionPath = DBPath({}, `userProcessMessages`);
	//const documentID = processMessage.sendTime.toString();
	const documentID = GenerateUUID();
	return RobustFirebaseSet(collectionPath, documentID, processMessage);
}

/**
Does the equivalent of the following (except using the http-api):
==========
if (!InAndroid(0)) {
	const response = await remoteUserProcessesBridge.Call("Remote_SnoozeFail");
	if (logEntry) {
		logEntry.message += ` [response: ${response}]`; // maybe todo: reimplement this within the http-api approach below
		NotifyFBASessionSetOrLogChanged();
	}
	return true;
}
*/
export function RobustFirebaseSet(collectionPath: string, documentID: string, value: any): Boolean {
	let bodyObj;
	// todo: make this generic, so that it just converts the "value" argument into the proper structure
	//if (value instanceof ProcessMessage && value.message["functionCall_args"] instanceof Array && value.message["functionCall_args"].length == 0) {
	if (value instanceof ProcessMessage && IsString(value.message)) {
		const processMessage = value as ProcessMessage;
		const channelMessage = processMessage.message;
		bodyObj = {
			fields: {
				user: {stringValue: processMessage.user},
				fromProcessID: {integerValue: processMessage.fromProcessID},
				sendTime: {integerValue: processMessage.sendTime},
				message: {
					/*mapValue: {
						fields: {
							functionCall_callID: {doubleValue: channelMessage["functionCall_callID"]},
							functionCall_name: {stringValue: channelMessage["functionCall_name"]},
							functionCall_args: {arrayValue: {values: []}}, // todo: make this support argument-arrays with values
						},
					},*/
					stringValue: channelMessage,
				},
			},
		};
	} else {
		//Assert(false, "Not yet implemented.");
		return false;
	}

	const fetchOptions: RequestInit = {
		method: "POST",
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		headers: {},
		body: JSON.stringify(bodyObj),
	};

	const sendTime = Date.now();
	const entry = SessionLog("Performing robust Firebase set, using RobustFetch.", LogType.Event_Large); // todo: make this event_small once debugged
	/*fetch(`https://firestore.googleapis.com/v1beta1/projects/lucid-frontier-${DB}/databases/(default)/documents/${collectionPath}?documentId=${documentID}&key=${apiKey}`, fetchOptions).then(async response=>{
		const responseObj = await response.json();
		const delay = Date.now() - sendTime;
		entry.message += ` @Response(${delay}): ${ToJSON(responseObj)}`;
		NotifyFBASessionSetOrLogChanged();
	});*/
	RobustFetch(`https://firestore.googleapis.com/v1beta1/projects/lucid-frontier-${DB}/databases/(default)/documents/${collectionPath}?documentId=${documentID}&key=${firebaseConfig.apiKey}`, fetchOptions).then(async result=>{
		/*const responseStr = result.text.fetchResponseStr;
		const responseObj = FromJSON(responseStr);*/
		const responseObj = await result.json();

		const delay = Date.now() - sendTime;
		//entry.message += ` @Response(${delay}): ${ToJSON(responseObj)}`;
		entry.message += ` @Response(${delay}): [length: ${ToJSON(responseObj).length}]`;
		NotifyFBASessionLogChanged();
	});
	entry.message += " @Sent";
	NotifyFBASessionLogChanged();
	return true;
}

export async function RobustFetch(url: string, options: RequestInit): Promise<Response> {
	if (InAndroid(0) && !screenOn) {
		const supportedKeys = ["method", "cache", "headers", "body"];
		Assert(options.VKeys().All(key=>supportedKeys.includes(key)));
		if (options.cache) Assert(options.cache == "no-cache", `Only supports "no-cache" for cache property.`);

		const result = await PluginGeneral.RobustFetch(E({url}, options));
		const fetchResponseStr = result.fetchResponseStr as string;
		//var fetchResponseStr_blob = new Blob([ToJSON(fetchResponseStr)], {type: "application/json"});
		var fetchResponseStr_blob = new Blob([fetchResponseStr], {type: "text/plain"});
		const reconstructedResponse = new Response(fetchResponseStr_blob, {status: 200, statusText: "SuperSmashingGreat!"});
		//return new Promise(resolve=>resolve(reconstructedResponse));
		return reconstructedResponse;
	}
	return fetch(url, options);
}