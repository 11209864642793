import {HSL} from "web-vcore";
import {E} from "js-vextensions";
import {Column, Row, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {FBAConfig_XAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Alarms.js";
import {AlarmType} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig";
import {GetLights_WithUserTag} from "../../../../Store/firebase/lights";
import {GetScripts_WithUserTag} from "../../../../Store/firebase/scripts";
import {GetShakes_WithUserTag} from "../../../../Store/firebase/shakes";
import {GetSounds_WithUserTag} from "../../../../Store/firebase/sounds";
import {FBASessionPanels_SharedProps} from "../FBAConfigPanel_Local.js";
import {EffectPointer} from "../../../../Store/firebase/@Shared/EffectPointer.js";

export class EffectCountsPanel extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled, config_full: config, ChangeConfig_Full: ChangeConfig} = this.props;

		const allAlarms = ([] as FBAConfig_XAlarm[])
			.concat(config.eeg.enabled ? config.eeg.alarmSequence.alarms.filter(a=>a.enabled) : [])
			.concat(config.alarms.enabled ? config.alarms.phaseAlarm_sequences.filter(a=>a.enabled).SelectMany(a=>a.alarms).filter(a=>a.enabled) : []);

		const usedSoundTags = ([] as (string|n)[])
			.concat([
				config.background.enabled && config.background.tag,
				config.alarms.enabled && config.alarms.phaseSleep_soundTag,
				config.alarms.enabled && config.alarms.phaseSolving_soundTag,
				config.dreamNarration.enabled && config.dreamNarration.voiceSoundTag,
			].filter(a=>a) as string[])
			.concat(allAlarms.filter(a=>a.type == AlarmType.Sound).map(a=>EffectPointer.GetEffectTag(a.effectPointer)))
			.concat(allAlarms.filter(a=>a.type == AlarmType.Countdown).map(a=>EffectPointer.GetEffectTag(a.effectPointer)))
			//.concat(allAlarms.filter(a=>a.type == AlarmType.Memory).map(a=>a.voiceSoundTag))
			.filter(a=>a).map(a=>a!).Distinct();
		const usedShakeTags = ([] as (string|n)[])
			.concat(allAlarms.filter(a=>a.type == AlarmType.Shake).map(a=>EffectPointer.GetEffectTag(a.effectPointer)))
			.filter(a=>a).map(a=>a!).Distinct();
		const usedLightTags = ([] as (string|n)[])
			.concat([
				config.general.resetScreenLightTag,
				config.alarms.enabled && config.alarms.phaseSleep_lightTag,
				config.alarms.enabled && config.alarms.phaseSolving_lightTag,
				config.dreamNarration.enabled && config.dreamNarration.voiceSoundTag,
			].filter(a=>a) as string[])
			.concat(allAlarms.filter(a=>a.type == AlarmType.Light).map(a=>EffectPointer.GetEffectTag(a.effectPointer)))
			.filter(a=>a).map(a=>a!).Distinct();
		const usedScriptTags = ([] as (string|n)[])
			.concat(config.general.startupScriptTag)
			.concat(allAlarms.filter(a=>a.type == AlarmType.Script).map(a=>EffectPointer.GetEffectTag(a.effectPointer)))
			.filter(a=>a).map(a=>a!).Distinct();

		const usedSoundTags_counts = usedSoundTags.map(a=>({tagName: a, count: GetSounds_WithUserTag(a).length})).OrderByDescending(a=>a.count);
		const usedShakeTags_counts = usedShakeTags.map(a=>({tagName: a, count: GetShakes_WithUserTag(a).length})).OrderByDescending(a=>a.count);
		const usedLightTags_counts = usedLightTags.map(a=>({tagName: a, count: GetLights_WithUserTag(a).length})).OrderByDescending(a=>a.count);
		const usedScriptTags_counts = usedScriptTags.map(a=>({tagName: a, count: GetScripts_WithUserTag(a).length})).OrderByDescending(a=>a.count);
		const allTags_counts = usedSoundTags_counts.concat(usedShakeTags_counts).concat(usedLightTags_counts).concat(usedScriptTags_counts);
		const allTagsHaveMatches = allTags_counts.All(a=>a.count > 0);

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row center>
					<Text>Tag matches:</Text>
					<Text ml={5}>Sound*({usedSoundTags_counts.map((tag, i)=>{
						return <Text key={i} ml={i == 0 ? 0 : 5} style={E({display: "inline-block"}, tag.count == 0 && {color: HSL(0, 1, .7)})}>{tag.tagName}<sup>{tag.count}</sup></Text>;
					})})</Text>
					<Text ml={5}>Shake({usedShakeTags_counts.map((tag, i)=>{
						return <Text key={i} ml={i == 0 ? 0 : 5} style={E({display: "inline-block"}, tag.count == 0 && {color: HSL(0, 1, .7)})}>{tag.tagName}<sup>{tag.count}</sup></Text>;
					})})</Text>
					<Text ml={5}>Light({usedLightTags_counts.map((tag, i)=>{
						return <Text key={i} ml={i == 0 ? 0 : 5} style={E({display: "inline-block"}, tag.count == 0 && {color: HSL(0, 1, .7)})}>{tag.tagName}<sup>{tag.count}</sup></Text>;
					})})</Text>
					<Text ml={5}>Script({usedScriptTags_counts.map((tag, i)=>{
						return <Text key={i} ml={i == 0 ? 0 : 5} style={E({display: "inline-block"}, tag.count == 0 && {color: HSL(0, 1, .7)})}>{tag.tagName}<sup>{tag.count}</sup></Text>;
					})})</Text>
				</Row>
				<Row>
					<Text>Status:</Text>
					<Text ml={5} style={E(!allTagsHaveMatches && {color: HSL(0, 1, .7)})}>{allTagsHaveMatches ? "ready" : "not ready"}</Text>
					{!allTagsHaveMatches && <Text ml={5}>(use the Effects page to find and tag the effects you want, for the tag-names shown in red above)</Text>}
				</Row>
				{usedScriptTags_counts.Any(a=>a.count == 0) &&
					<Text mt={10} style={{fontSize: 12}}>{`(Note: Some of the missing effects are scripts, which cannot currently be shared through the database. You can try requesting them from the config's creator directly.)`}</Text>}
			</Column>
		);
	}
}