import {App} from '@capacitor/app';
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';
import {closestCorners, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import chroma from "chroma-js";
import {getApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithCredential} from "firebase/auth";
import React from "react";
import * as ReactColor from "react-color";
import ReactGA from "react-ga";
import {AddressBarWrapper, ErrorBoundary, LoadURL, Observer, RunInAction} from "web-vcore";
import {Clone, Timer, Vector2} from "js-vextensions";
import {AsyncTrunk} from "mobx-sync";
import {makeObservable, observable} from "mobx";
import {ColorPickerBox, Column} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {VMenuLayer} from "react-vmenu";
import {MessageBoxLayer} from "react-vmessagebox";
import "../../Source/Utils/Styles/Main.scss"; // keep absolute-ish, since scss file not copied to Source_JS folder
import {hasHotReloaded} from "../Main.js";
import {Me, MeID} from "../Store/firebase/users.js";
import {store} from "../Store/index.js";
import {JourneyTab} from "../Store/main/tools/journey.js";
import {NavBar} from "../UI/@Shared/NavBar.js";
import {FeedbackUI} from "../UI/Feedback.js";
import {HomeUI} from "../UI/Home.js";
import {MoreUI} from "../UI/More.js";
import {InAndroid} from "../Utils/Bridge/Bridge_Native.js";
import {OnStoreLoaded_Trigger} from "../Utils/General/GlobalHooks.js";
import {OnDragEnd, OnDragStart} from "./@Root/RootDragHandler.js";
import {RootStyles} from "./@Root/RootStyles.js";
import {SettingsPanel, SettingsUI} from "./@Shared/NavBar/SettingsPanel.js";
import {NavBar_Android} from "./@Shared/NavBar_Android.js";
import {ContentUI} from "./Content.js";
import {EffectsUI} from "./Effects.js";
import {ScreenLightOverlay} from "./Effects/Lights/ScreenLightOverlay.js";
import {JournalsUI} from "./Journal.js";
import {TimelinePageUI} from "./Timeline.js";
import {ToolsUI} from "./Tools.js";
import {UsersUI} from "./Users.js";
import {UserProfileUI} from "./Users/UserProfile.js";
import {TermCell_ForDND} from "./Tools/Journey/TermCell.js";

/*if (InAndroid(0)) {
	require("@codetrix-studio/capacitor-google-auth"); // eslint-disable-line
}*/

ColorPickerBox.Init(ReactColor, chroma);

@Observer
export class RootUIWrapper extends BaseComponentPlus({}, {}) {
	constructor(props) {
		super(props);
		makeObservable(this);
	}
	
	async ComponentWillMount() {
		// InitStore();

		const trunk = new AsyncTrunk(store, {storage: localStorage});
		if (startURL.GetQueryVar("clearState") == "true") {
			await trunk.clear();
		}

		await trunk.init();
		console.log("Loaded state:", Clone(store));
		OnStoreLoaded_Trigger();

		if (!hasHotReloaded) {
			LoadURL(startURL);
		}
		// UpdateURL(false);
		if (PROD && store.main.analyticsEnabled) {
			console.log("Initialized Google Analytics.");
			// ReactGA.initialize("UA-21256330-33", {debug: true});
			ReactGA.initialize("UA-21256330-33");

			/* let url = VURL.FromLocationObject(State().router).toString(false);
			ReactGA.set({page: url});
			ReactGA.pageview(url || "/"); */
		}

		// wrap with try, since it synchronously triggers rendering -- which breaks loading process below, when rendering fails
		/* try {
			this.SetState({ storeReady: true });
		} finally { */
		RunInAction("RootUIWrapper.ComponentWillMount.notifyStoreReady", ()=>this.storeReady = true);
	}
	// use observable field for this rather than react state, since setState synchronously triggers rendering -- which breaks loading process above, when rendering fails
	@observable storeReady = false;

	render() {
		// const { storeReady } = this.state;
		const {storeReady} = this;
		// if (!g.storeRehydrated) return <div/>;
		if (!storeReady) return null;
		const dndState = store.main.dnd;

		const mouseSensor = useSensor(MouseSensor);
		const touchSensor = useSensor(TouchSensor);
		const sensors = useSensors(mouseSensor, touchSensor);

		return (
			<DndContext sensors={sensors}
				//collisionDetection={rectIntersection}
				//collisionDetection={closestCenter}
				collisionDetection={closestCorners}
				onDragStart={OnDragStart}
				onDragEnd={OnDragEnd}
			>
				<RootUI/>
				<DragOverlay>
					{dndState.activeDraggableInfo?.term != null && <TermCell_ForDND term={dndState.activeDraggableInfo.term}/>}
				</DragOverlay>
			</DndContext>
		);
	}
}

/*(async()=>{
	const {App} = Plugins;

	App.addListener("appStateChange", (state: AppState)=>{
	// state.isActive contains the active state
		console.log("App state changed. Is active?", state.isActive);
	});

	// Listen for serious plugin errors
	/*App.addListener("pluginError", (info: any)=>{
		console.error("There was a serious error with a plugin", err, info);
	});*#/

	var ret = await App.canOpenUrl({url: "com.getcapacitor.myapp"});
	console.log("Can open url: ", ret.value);

	/*ret = await App.openUrl({ url: 'com.getcapacitor.myapp://page?id=ionicframework' });
	console.log('Open url response: ', ret);*#/

	const ret2 = await App.getLaunchUrl();
	if (ret2 && ret2.url) {
		console.log(`App opened with URL: ${ret2.url}`);
	}
	console.log("Launch url: ", ret2);

	App.addListener("appUrlOpen", (data: any)=>{
		console.log(`App opened with URL: ${data.url}`);
	});

	App.addListener("appRestoredResult", (data: any)=>{
		console.log("Restored state:", data);
	});
})();*/

/*window.addEventListener("NotifyLoudnessHit", loudness=> {
});*/

App.addListener("appUrlOpen", data=>{
//Plugins.App.addListener("appUrlOpen", data=>{
	console.log("Got authData (type 2): ", data);

	/*const account = firebaseHelper.login({
		credential: firebase.auth.GoogleAuthProvider.credential(authData.authentication.idToken),
		//profile: authData.Excluding("authentication"),
		profile: authData,
	} as any);*/
});

export async function LaunchGoogleSignInDialog() {
	//const authData = await Plugins.GoogleAuth.signIn();
	GoogleAuth.initialize();
	const authData = await GoogleAuth.signIn();
	console.log("Got authData: ", authData);

	/*const account = firebaseHelper.login({
		credential: firebase.auth.GoogleAuthProvider.credential(authData.authentication.idToken),
		//profile: authData.Excluding("authentication"),
		profile: authData,
	} as any);
	const account = await fire.LogIn({provider, type: "popup"});*/
	//store.firelink.subs.firestoreDB.
	signInWithCredential(getAuth(getApp()), GoogleAuthProvider.credential(authData.authentication.idToken));
}

@Observer
class RootUI extends BaseComponent<{}, {}> {
	ComponentDidMount() {
		document.addEventListener("mousemove", event=>{
			/*if (event["handledGlobally"]) return;
			event["handledGlobally"] = true;*/

			// useful for various purposes, eg. for unreliable-mouseLeave fix
			g.mousePos = new Vector2(event.pageX, event.pageY);
		});

		if (InAndroid(0)) {
			//require("@codetrix-studio/capacitor-google-auth");
			const gapiWaitTimer = new Timer(1000, ()=>{
				if (g.gapi == null) return;
				gapiWaitTimer.Stop();
				
				if (MeID() != null) return; // if already signed in, no need to sign-in again (and actually, right now it clears the auth-data if you try to sign-in again!)
				LaunchGoogleSignInDialog();
			}).Start();
		}
	}

	render() {
		const uiState = store.main;
		const {page} = uiState;
		return (
			<Column className="background"/*"unselectable"*/ style={{height: "100%"}}>
				<RootStyles/>
				<ErrorBoundary>
					<AddressBarWrapper/>
					<OverlayUI/>
				</ErrorBoundary>
				{(!InAndroid(1) || uiState.settings.android_showNavBar) &&
				<ErrorBoundary>
					<NavBar/>
				</ErrorBoundary>}
				<ErrorBoundary key={page} /* Add key, so that error message goes away when changing page. */>
					{/*<InfoButton_TooltipWrapper/>*/}
					<main style={{position: "relative", flex: 1, overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
						{page == "users" && <UsersUI/>}
						{page == "feedback" && <FeedbackUI/>}
						{/*page == "forum" && <ForumUI/>*/}
						{page == "more" && <MoreUI/>}
						{page == "home" && <HomeUI/>}
						{page == "timeline" && <TimelinePageUI/>}
						{page == "journal" && <JournalsUI/>}
						{page == "effects" && <EffectsUI/>}
						{page == "content" && <ContentUI/>}
						{page == "tools" && <ToolsUI/>}

						{page == "settings" && <SettingsUI asPanel={false}/>}
						{page == "profile" && <UserProfileUI profileUser={Me()!}/>}
					</main>
				</ErrorBoundary>
				{InAndroid(1) && !FullScreenPanelOpen() &&
				<NavBar_Android/>}
			</Column>
		);
	}
}

export function GetNavBarHeights() {
	let result = 0;
	if (!InAndroid(1) || store.main.settings.android_showNavBar) result += 45; // top nav-bar
	if (InAndroid(1) && !FullScreenPanelOpen()) result += 50; // bottom nav-bar
	return result;
}
function FullScreenPanelOpen() {
	// commented; find new way to make full-screen that is less confusing to new users
	//if (store.main.page == "tools" && store.main.tools.subpage == "journey" && store.main.tools.journey.tab == JourneyTab.engine) return true;
	return false;
}

class OverlayUI extends BaseComponent<{}, {}> {
	render() {
		return (
			<div style={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, overflow: "hidden"}}>
				<ScreenLightOverlay/>
				<MessageBoxLayer/>
				<VMenuLayer/>
			</div>
		);
	}
}