import {TextPlus} from "web-vcore";
import {CheckBox, Column, Row, RowLR, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {colors} from "../../../../Utils/UI/GlobalStyles.js";
import {ControlInput} from "../../../@Shared/ControlInput";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.microSnooze, (c, v)=>c.microSnooze = v);

const splitAt = 150;
export const PromptPanel_splitAt = splitAt;

export class MicroSnooze_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Micro-snooze (solver)" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Micro-snooze:</Text>
						<ControlInput enabled={enabled} value={config.microSnooze_triggerSet} onChange={val=>ChangeConfig(c=>c.microSnooze_triggerSet = val)}/>
					</RowLR>

					<RowLR mt={5} splitAt={splitAt}>
						<Text>Micro-snooze tilt:</Text>
						<CheckBox enabled={enabled} value={config.tiltSnooze_enabled} onChange={val=>ChangeConfig(c=>c.tiltSnooze_enabled = val)}/>
					</RowLR>
					{config.tiltSnooze_enabled &&
					<Column mt={5} ml={10}>
						<RowLR splitAt={splitAt - 10}>
							<Text>Min angle from flat:</Text>
							<Spinner enabled={enabled} value={config.tiltSnooze_minAngleFromFlat} onChange={val=>ChangeConfig(c=>c.tiltSnooze_minAngleFromFlat = val)}/>
							<Text>degrees</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<Text>Max tilt duration:</Text>
							<Spinner enabled={enabled} value={config.tiltSnooze_maxTiltDuration} onChange={val=>ChangeConfig(c=>c.tiltSnooze_maxTiltDuration = val)}/>
							<Text>s</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<Text>Sound path:</Text>
							<TextInput enabled={enabled} value={config.tiltSnooze_soundPath} onChange={val=>ChangeConfig(c=>c.tiltSnooze_soundPath = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt - 10}>
							<Text>Sound volume:</Text>
							<Spinner enabled={enabled} value={(config.tiltSnooze_soundVolume * 100).RoundTo(.01)} onChange={val=>ChangeConfig(c=>c.tiltSnooze_soundVolume = val / 100)} min={0} max={100}/>
							<Text>%</Text>
						</RowLR>
					</Column>}

					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							A micro-snooze pretends to "make progress" on a solve, triggering a shift to the "solve" phase for a certain amount of time. (usually pretty short)
							Purpose: Lets the user briefly turn off alarms, to not be stressed while trying to work toward their full/actual solve path.
						`.AsMultiline(0)}>Micro-snooze, duration:</TextPlus>
						<Spinner enabled={enabled} value={config.maxTimeForProgress} onChange={val=>ChangeConfig(c=>c.maxTimeForProgress = val)}/>
						<Text>s</Text>
					</RowLR>
					{/*<Column mt={5} ml={15}>
						<RowLR splitAt={splitAt - 15}>
							<Text>Enter effects:</Text>
							<Text>Sound:</Text>
							<TextInput ml={5} enabled={enabled} style={{width: 100}} value={config.microSnooze_soundTag} onChange={val=>ChangeConfig(c=>c.microSnooze_soundTag = val)}/>
							<Text ml={5}>Light:</Text>
							<TextInput ml={5} enabled={enabled} style={{width: 100}} value={config.microSnooze_lightTag} onChange={val=>ChangeConfig(c=>c.microSnooze_lightTag = val)}/>
						</RowLR>
					</Column>*/}
				</>}
			</Column>
		);
	}
}