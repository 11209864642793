import {E} from "js-vextensions";
import React from "react";
import {Button, CheckBox, ColorPickerBox, Column, Div, Pre, Row, Select, Text, TextInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {BoxController, ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {GetUser, MeID} from "../../Store/firebase/users.js";
import {GetUserPermissionGroups} from "../../Store/firebase/users/$user.js";
import {User} from "../../Store/firebase/users/@User.js";
import {GetUserHidden} from "../../Store/firebase/users_private.js";
import {PropNameToTitle} from "../../Utils/General/General.js";
import {DialogStyle, ES} from "../../Utils/UI/GlobalStyles.js";
import {Observer, PageContainer} from "web-vcore";
import {UpdateUser} from "../../Server/Commands/UpdateUser.js";

@Observer
export class UserProfileUI extends BaseComponentPlus({} as {profileUser: User}, {}) {
	render() {
		const {profileUser} = this.props;
		if (profileUser == null) return <PageContainer>User does not exist.</PageContainer>;
		const profileUser_p = profileUser._key == MeID() ? GetUserHidden(profileUser._key!) : null;
		//if (profileUser_p == null) return <PageContainer>Loading...</PageContainer>;

		const userID = MeID();
		const profileUserPermissionGroups = GetUserPermissionGroups(profileUser ? profileUser._key : null);
		const currentUser = GetUser(userID);

		return (
			<PageContainer>
				<Row>
					<Text>Username: {profileUser.displayName}</Text>
					{profileUser == currentUser &&
						<Button ml={5} text="Change" onClick={()=>{
							ShowChangeDisplayNameDialog(currentUser._key!, currentUser.displayName);
						}}/>}
				</Row>
				<Row mt={3}>
					<Pre>Permissions: </Pre>
					{["basic", "verified", "mod", "admin"].map((group, index)=>{
						const admin = userID != null && GetUserPermissionGroups(MeID()).admin;
						const changingOwnAdminState = currentUser && profileUser._key == currentUser._key && group == "admin";
						return (
							<CheckBox key={index} mr={index < 3 ? 5 : 0} text={PropNameToTitle(group)} value={(profileUserPermissionGroups || {})[group]} enabled={admin && !changingOwnAdminState} onChange={val=>{
								const newPermissionGroups = E(profileUserPermissionGroups, {[group]: val});
								new UpdateUser({id: profileUser._key!, updates: {permissionGroups: newPermissionGroups}}).Run();
							}}/>
						);
					})}
				</Row>

				{profileUser == currentUser &&
					<React.Fragment>
						<Row mt={10} mb={5} style={{fontSize: 15, fontWeight: "bold"}}>Tools</Row>
						<Row>
							<Button text="Clear local data" onClick={()=>{
								ShowMessageBox({
									title: "Clear local data?", cancelButton: true,
									message: `
										Are you sure you want to clear local data?

										Some of the things this will clear: (not exhaustive)
										* Display settings.

										Some of the things this won't clear:
										* Your posts and comments.

										This is usually only done if an error is occuring because of outdated or invalid data.
									`.AsMultiline(0),
									onOK: ()=>{
										// ClearLocalData(persister);
										// todo
										window.location.reload();
									},
								});
							}}/>
						</Row>
					</React.Fragment>}
			</PageContainer>
		);
	}
}

export function ShowChangeDisplayNameDialog(userID: string, oldDisplayName: string) {
	let newDisplayName = oldDisplayName;

	const valid = true;
	const boxController: BoxController = ShowMessageBox({
		title: "Change display name", cancelButton: true,
		message: ()=>{
			boxController.UpdateOptions({okButtonProps: {enabled: valid}});
			return (
				<Column style={DialogStyle({width: 600})}>
					<Row>
						<Pre>Display name: </Pre>
						<TextInput value={newDisplayName} onChange={val=>{
							newDisplayName = val;
							boxController.UpdateUI();
						}}/>
					</Row>
				</Column>
			);
		},
		onOK: ()=>{
			new UpdateUser({id: userID, updates: {displayName: newDisplayName}}).Run();
		},
	});
}