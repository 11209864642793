import {DragEndEvent, DragStartEvent, UniqueIdentifier} from "@dnd-kit/core";
import {ignore} from "mobx-sync";
import {O, RunInAction} from "web-vcore";
import {makeObservable} from "mobx";
import {store} from "../../Store/index.js";
import {DNDInfo} from "./DNDStructures.js";

export class DNDState {
	constructor() { makeObservable(this); }
	
	@O @ignore activeDraggableID: UniqueIdentifier|n;
	@O @ignore activeDraggableInfo: DNDInfo|n;
}

export function OnDragStart(event: DragStartEvent) {
	RunInAction("OnDragStart", ()=>{
		store.main.dnd.activeDraggableID = event.active.id;
		store.main.dnd.activeDraggableInfo = event.active.data.current as DNDInfo;
	});
}
export function OnDragEnd(event: DragEndEvent) {
	if (event.over?.data.current == null) return;
	const dragged = event.active.data.current as DNDInfo;
	const dragFrom = dragged?.parentDroppableInfo;
	const dropOn = event.over.data.current as DNDInfo;
	RunInAction("OnDragEnd", ()=>{
		store.main.dnd.activeDraggableID = null;
		store.main.dnd.activeDraggableInfo = null;
	});

	(async()=>{
		// todo: needs updating to work with entitySequence->shortText change
		/*let addedEntityToSequence = false;
		if (dropOn.type == "EntitySequence" || dropOn.type == "EmptySlotInEntitySequence") {
			const dropOn_sequence = dropOn.type == "EntitySequence" ? dropOn : dropOn.parentDroppableInfo;
			if (dropOn_sequence == null || dropOn_sequence.journalEntry == null || dropOn_sequence.journalEntry.segments == null || dropOn_sequence.sequenceItems == null || dragged.entity == null || dragged.indexInContainer == null) return;
			
			const segments_new = Clone(dropOn_sequence.journalEntry.segments) as JournalSegment[];
			const segment_new = segments_new[dropOn_sequence.journalEntry.segments.indexOf(dropOn_sequence.segment as any)];
			if (segment_new.entitiesSequence == null) segment_new.entitiesSequence = []; // temp
			segment_new.entitiesSequence = segment_new.entitiesSequence.slice();

			const oldIndex = dragged.indexInContainer;
			//const newIndex = segment_new.entitiesSequence.length;
			const newIndex = dropOn_sequence.sequenceItems.length;
			segment_new.entitiesSequence.Insert(newIndex, dragged.entity._key);
			if (JSON.stringify(dropOn_sequence) == JSON.stringify(dragFrom) && oldIndex != -1) {
				let oldEntry_currentIndex = newIndex <= oldIndex ? oldIndex + 1 : oldIndex; // if we just inserted the new version before the old entry, fix the old-entry's index by adding 1
				segment_new.entitiesSequence.RemoveAt(oldEntry_currentIndex);
			} else {
				addedEntityToSequence = true;
			}

			await new UpdateJournalEntry({
				id: dropOn_sequence.journalEntry._key,
				updates: {segments: segments_new},
			}).Run();
		} else if (dropOn.type == "EntityCell") {
			const dropOn_group = dropOn.parentDroppableInfo;
			if (dropOn_group?.type != "EntitySequence") return; // ignore drops onto an EntityCell that is over a non-EntitySubsequence
			if (dropOn_group.journalEntry == null || dropOn_group.journalEntry.segments == null || dropOn_group.segment == null || dragged.indexInContainer == null || dropOn.indexInContainer == null || dragged.entity == null) return;

			const segments_new = Clone(dropOn_group.journalEntry.segments) as JournalSegment[];
			const segment_new = segments_new[dropOn_group.journalEntry.segments.indexOf(dropOn_group.segment)];
			//if (segment_new.entitiesSequence == null) segment_new.entitiesSequence = []; // temp
			//segment_new.entitiesSequence = segment_new.entitiesSequence.slice(); // I think this isn't necessary?? (since we cloned the source array already anyway)

			const oldIndex = dragged.indexInContainer;
			const newIndex = dropOn.indexInContainer;
			if (JSON.stringify(dropOn_group) == JSON.stringify(dragFrom)) {
				segment_new.entitiesSequence!.RemoveAt(oldIndex);
			} else {
				addedEntityToSequence = true;
			}
			segment_new.entitiesSequence!.Insert(newIndex, dragged.entity._key);
			/*if (JSON.stringify(dropOn_group) == JSON.stringify(dragFrom)) {
				if (oldIndex != -1) {
					let oldEntry_currentIndex = newIndex <= oldIndex ? oldIndex + 1 : oldIndex; // if we just inserted the new version before the old entry, fix the old-entry's index by adding 1
					segment_new.entitiesSequence.RemoveAt(oldEntry_currentIndex);
				}
			}*#/

			await new UpdateJournalEntry({
				id: dropOn_group.journalEntry._key,
				updates: {segments: segments_new},
			}).Run();
		} else if (dropOn.type == "RemoveEntityFromSequenceButton") {
			if (dragFrom?.type != "EntitySequence") return;
			if (dragFrom.journalEntry == null || dragFrom.journalEntry.segments == null || dragFrom.segment == null || dragged.indexInContainer == null) return;

			const segments_new = Clone(dragFrom.journalEntry.segments) as JournalSegment[];
			const segment_new = segments_new[dragFrom.journalEntry.segments.indexOf(dragFrom.segment)];
			if (segment_new.entitiesSequence == null) segment_new.entitiesSequence = []; // temp
			segment_new.entitiesSequence = segment_new.entitiesSequence.slice();

			segment_new.entitiesSequence.RemoveAt(dragged.indexInContainer);

			await new UpdateJournalEntry({
				id: dragFrom.journalEntry._key,
				updates: {segments: segments_new},
			}).Run();
		} else if (dropOn.type == "EntityPoolHeader") {
			if (dragFrom?.type != "EntityPool") return;
			if (dragFrom.category == null || dropOn.category == null) return;

			const tagRemoving = entityCategories_tags[dragFrom.category];
			const tagAdding = entityCategories_tags[dropOn.category];
			
			ShowMessageBox({
				title: `Change entity category?`, cancelButton: true,
				message: `Change category of this entity from "${tagRemoving}" to "${tagAdding}"?`,
				onOK: async()=>{
					const suggestedTags_new = Clone(dragged.entity!.tags);
					const ownTags_new = Clone(GetUserEntityTags(MeID(), "entities", dragged.entity!._key));
					suggestedTags_new.Remove(tagRemoving);
					ownTags_new.Remove(tagRemoving);
					suggestedTags_new.push(tagAdding);
					ownTags_new.push(tagAdding);
		
					await new UpdateEntity({id: dragged.entity!._key, updates: {tags: suggestedTags_new}}).Run();
					await new SetUserEntityTags({entityGroup: "entities", entityID: dragged.entity!._key, entityTags: ownTags_new}).Run();
				},
			})
		}

		if (addedEntityToSequence) {
			//if (liveJourneySession != null && liveJourneySession.phase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving)) {
			if (GetLiveJourneySession() != null && GetLiveJourneySession()!.Comp(AlarmsComp).phase.IsOneOf(AlarmsPhase.Alarm, AlarmsPhase.Solving, AlarmsPhase.AlarmWait)) {
				GetLiveJourneySession()!.Comp(AlarmsComp).StartPhase_AlarmWait();
			}
		}*/
	})();
}