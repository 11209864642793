import {Observer, UseWindowEventListener} from "web-vcore";
import {GetEntries} from "js-vextensions";
import {Column, Row, Select} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import React from "react";
import {store} from "../../../Store/index.js";
import {SettingsTab} from "../../../Store/main/settings.js";
import {SettingsUI_Audio} from "./SettingsPanel/Audio.js";
import {SettingsUI_General} from "./SettingsPanel/General.js";
import {GetNavBarHeights} from "../../Root.js";
import {SettingsUI_Info} from "./SettingsPanel/Info.js";
import {SettingsUI_Transcribe} from "./SettingsPanel/Transcribe.js";

@Observer
export class SettingsPanel extends BaseComponentPlus({}, {}) {
	render() {
		return <SettingsUI asPanel={true}/>;
	}
}

@Observer
export class SettingsUI extends BaseComponentPlus({asPanel: false}, {}) {
	render() {
		const {asPanel} = this.props;
		const uiState = store.main.settings;

		UseWindowEventListener("resize", ()=>asPanel && this.forceUpdate());

		const maxHeight = asPanel ? `calc(${window.innerHeight}px - ${GetNavBarHeights()}px)` : "100%";
		return (
			<Column style={{
				width: asPanel ? undefined : "100%",
				maxHeight,
				height: uiState.tab == SettingsTab.transcribe ? maxHeight : undefined,
				padding: 5, background: "rgba(0,0,0,.7)", borderRadius: "0 0 0 5px",
			}}>
				<Row>
					<Select displayType="button bar" options={GetEntries(SettingsTab, "ui")} value={uiState.tab} onChange={val=>uiState.tab = val}/>
				</Row>
				{uiState.tab == SettingsTab.general && <SettingsUI_General/>}
				{uiState.tab == SettingsTab.audio && <SettingsUI_Audio/>}
				{uiState.tab == SettingsTab.transcribe && <SettingsUI_Transcribe/>}
				{uiState.tab == SettingsTab.info && <SettingsUI_Info/>}
			</Column>
		);
	}
}