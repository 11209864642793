import {AddSchema} from "mobx-firelink";

// temp replaced
// import {UserInfo as ProviderData} from "firebase";
type ProviderData = any;

// todo: change the actual table in db to "userHiddens"
export class UserHidden {
	_key?: string;
	//displayName: string;
	//avatarUrl: string;
	email: string;
	providerData: ProviderData[];

	// custom
	// ==========

	backgroundID?: string;
	backgroundCustom_enabled?: boolean;
	backgroundCustom_color?: string;
	backgroundCustom_url?: string;
	backgroundCustom_position?: string;

	customGridExtractor_enabled?: boolean;
	customGridExtractor_regex?: string;
	gridEntityNames?: string[];
}
AddSchema("UserHidden", {
	properties: {
		//displayName: {type: "string"},
		//avatarUrl: {type: "string"},
		email: {type: "string"},
		providerData: {type: "array"},

		backgroundID: {type: "string"},
		backgroundCustom_enabled: {type: "boolean"},
		backgroundCustom_color: {type: ["null", "string"]},
		backgroundCustom_url: {type: "string"},
		backgroundCustom_position: {type: "string"},

		customGridExtractor_enabled: {type: "boolean"},
		customGridExtractor_regex: {type: "string"},
		gridEntityNames: {items: {type: "string"}},
	},
});