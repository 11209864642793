import {EffectPointerPlayer} from "../../../../Store/firebase/@Shared/EffectPointerPlayer.js";
import {AlarmSequence} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {FBAConfig_ShakeAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@EC_BasicAlarms.js";
import {FBASession} from "../../../FBASession";
import {AlarmComp} from "./@AlarmComp";

export class ShakeAlarmComp extends AlarmComp<FBAConfig_ShakeAlarm> {
	constructor(session: FBASession, sequence: AlarmSequence, config: FBAConfig_ShakeAlarm) {
		super(session, sequence, config);
	}

	promptPlayer = new EffectPointerPlayer();

	override OnStart() {
		this.promptPlayer.effectPointer = this.c.effectPointer;
		this.promptPlayer.OnStart();
	}
	
	override async PlayAlarmEffect() {
		await this.promptPlayer.Play(this.intensity);
	}
	override UpdatePrompt_ForReducedIntensity() {
		this.promptPlayer.NotifyReducedIntensity(this.intensity);
	}
	override StopAlarm(...args: Parameters<AlarmComp<any>["StopAlarm"]>) {
		this.promptPlayer.Stop();
		return super.StopAlarm(...args);
	}
}