import {SessionProps} from "../../../Store/main/tools/training";
import {AddSchema} from "mobx-firelink";

export function GetSessionSampleCount(session: RecordingSession) {
	return session.eegChannelSamples.VValues()?.[0].length ?? 0;
}

export class RecordingSession {
	constructor(initialData?: Partial<RecordingSession>) {
		this.VSet(initialData);
	}

	_key: string;
	creator: string;
	createdAt: number;

	sessionProps: SessionProps;
	sessionProps_disabled: string[];
	// channel-name (eg. af7, af8) -> sample-values
	// We use channel names for consistency between (potentially 2+) device-types, and because Firestore prohibits an array directly within another array.
	eegChannelSamples: {[key: string]: number[]};
}
AddSchema("RecordingSession", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},

		sessionProps: {patternProperties: {".+": {type: "string"}}},
		sessionProps_disabled: {items: {type: "string"}},
		eegChannelSamples: {
			patternProperties: {
				".+": {items: {type: "number"}},
			},
		},
	},
});