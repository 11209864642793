import {TimelineEvent} from "../../Store/firebase/timelineEvents/@TimelineEvent";
import {Command, MergeDBUpdates, GetDocs, WhereOp} from "mobx-firelink";
import {GetTimelineEvents} from "../../Store/firebase/timelineEvents";
import {StopTimelineEvent} from "./StopTimelineEvent";

//@UserEdit
export class StopCurrentTimelineEvents extends Command<{timelineID: string}> {
	subs_eventStops: StopTimelineEvent[];
	Validate() {
		const {timelineID} = this.payload;

		/*const events = GetTimelineEvents(timelineID);
		//let events = GetAsync(()=>GetEvents(timelineID));
		const eventToStopIDs = events.filter(a=>a.endTime == null).map(a=>a._key);*/
		const ongoingEvents = GetDocs({
			queryOps: [new WhereOp("endTime", "==", null)],
		}, a=>a.timelineEvents);
		const eventToStopIDs = ongoingEvents.filter(a=>a.timeline == timelineID).map(a=>a._key);

		this.subs_eventStops = eventToStopIDs.map(id=>new StopTimelineEvent({id}).MarkAsSubcommand(this));
		for (const command of this.subs_eventStops) {
			command.Validate();
		}
	}

	GetDBUpdates() {
		const {timelineID} = this.payload;

		let updates = {};
		for (const command of this.subs_eventStops) {
			updates = MergeDBUpdates(updates, command.GetDBUpdates());
		}
		return updates;
	}
}