import {Vector2} from "js-vextensions";
import {AddSchema} from "mobx-firelink";
import {name_general} from "../../../Utils/General/SharedPatterns";

export class Scene {
	constructor(initialData: Partial<Scene>) {
		this.VSet(initialData);
	}

	_key: string;
	creator: string;
	createdAt: number;

	name: string;
	tags: string[];

	url: string;
	/** as {x: percentOfWidth, y: percentOfHeight}, from bottom-left */
	square_center = new Vector2(.5, .5);
	/** 1: target-square maximally-fits into source-image, 0.5: target-square's width/height is set to half of source-image's (whichever dimension is smaller) */
	square_size = 1;

	// scene-specific stuff
	// ==========

	// todo: probably add a way (eventually) to "crop" the image to a "usable subsection", to then treat as though that were the whole image (makes using externally-hosted images more flexible)
	groundEdge_left = new Vector2(0, .5);
	groundEdge_right = new Vector2(1, .5);
	door_basePos = new Vector2(.5, .5);
	door_height = .15;
}
AddSchema("Scene", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},
		name: {type: "string", pattern: name_general},
		tags: {items: {type: "string"}},

		url: {type: "string"},
		square_center: {$ref: "Vector2"},
		square_size: {type: "number"},
		
		groundEdge_left: {$ref: "Vector2"},
		groundEdge_right: {$ref: "Vector2"},
		door_basePos: {$ref: "Vector2"},
		door_height: {type: "number"},
	},
});