import {Assert, Clone, GetEntries} from "js-vextensions";
import React, {useState} from "react";
import {Button, Column, Row, Select, Text, TextArea} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {Observer, RunInAction_Set, Slider} from "web-vcore";
import {UpdateJournalEntry} from "../../../../Server/Commands/UpdateJournalEntry.js";
import {GetJournalEntries} from "../../../../Store/firebase/journalEntries.js";
import {GetTermsInDreamSegmentText, JournalEntry, JournalSegment} from "../../../../Store/firebase/journalEntries/@JournalEntry.js";
import {StoryMessageProto} from "../../../../Store/firebase/storyMessages.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {store} from "../../../../Store/index.js";
import {llmTemplateDefault_summarize, SummarizeTab} from "../../../../Store/main/journal.js";
import {GenerateNextMessage, GenerateNextMessageOptions} from "../../../../Utils/Services/Anthropic.js";
import {TermCell} from "../../../Tools/Journey/TermCell.js";

export function CalculateSegmentTermsFromLongText(longText: string, targetLength: number, segmentsBeforeThisOne: JournalSegment[]) {
	const oldTermCounts = new Map<string, number>();
	for (const segment of segmentsBeforeThisOne) {
		const words = GetTermsInDreamSegmentText(segment.longText, true);
		for (const word of words) {
			oldTermCounts.set(word, (oldTermCounts.get(word) || 0) + 1);
		}
	}

	const oldTermCounts_max = [...oldTermCounts.values()].Max();
	const ourTerms = GetTermsInDreamSegmentText(longText, true);
	
	const resultTerms = [] as string[];
	if (targetLength == 0) return resultTerms;
	
	const termsToKeep = [] as string[];
	for (let i = 0; i <= oldTermCounts_max && termsToKeep.length < targetLength; i++) {
		for (const term of ourTerms) {
			const timesTermSeenInPast = oldTermCounts.get(term) ?? 0;
			if (timesTermSeenInPast == i) {
				termsToKeep.push(term);
				if (termsToKeep.length >= targetLength) {
					break;
				}
			}
		}
	}

	// we do a two-part "find" then "stitch" op, so that we keep the 
	return ourTerms.filter(term=>termsToKeep.includes(term));
}

@Observer
export class SegmentSummarizeUI extends BaseComponent<{entry: JournalEntry, segment: JournalSegment, segmentIndex: number}, {}> {
	render() {
		const {entry, segment, segmentIndex} = this.props;
		const uiState = store.main.journal;
		Assert(segment == entry.segments[segmentIndex]);
		const segments = entry.segments;
		const journalEntries_asc = GetJournalEntries(MeID(), "asc");
		const journalEntryIndex = journalEntries_asc.findIndex(a=>a._key == entry._key);
		
		let [targetLength, setTargetLength] = useState(24);
		const [llmOutput, setLLMOutput] = useState("");

		let outputText: string|n;
		let simple_outputTerms: string[]|n;
		if (uiState.summarize_tab == SummarizeTab.simple) {
			const journalEntriesBeforeThisOne = journalEntryIndex != -1 ? journalEntries_asc.slice(0, journalEntryIndex) : [];
			const segmentsBeforeThisOne = [
				...journalEntriesBeforeThisOne.SelectMany(a=>a.segments),
				...segments.slice(0, segmentIndex),
			];
			simple_outputTerms = CalculateSegmentTermsFromLongText(segment.longText, targetLength, segmentsBeforeThisOne);
			outputText = simple_outputTerms.join(" ");
		} else if (uiState.summarize_tab == SummarizeTab.llmGenerate) {
			outputText = llmOutput;
		}

		return (
			<Column>
				<Row>
					<Select options={GetEntries(SummarizeTab, "ui")} displayType="button bar"
						value={uiState.summarize_tab} onChange={val=>RunInAction_Set(this, ()=>uiState.summarize_tab = val)}/>
					{uiState.summarize_tab == SummarizeTab.llmTemplate &&
					<Button ml={5} mdIcon="file-restore" onClick={async()=>{
						ShowMessageBox({
							title: "Reset template?", cancelButton: true,
							message: "Reset the template to the default?",
							onOK: ()=>{
								RunInAction_Set(this, ()=>uiState.summarize_llmTemplate = llmTemplateDefault_summarize);
							},
						});
					}}/>}
					{uiState.summarize_tab == SummarizeTab.llmGenerate &&
					<Button ml={5} mdIcon="refresh" onClick={async()=>{
						const startMessage = new StoryMessageProto({
							role: "user",
							content: uiState.summarize_llmTemplate.replace(/\$dream/g, segment.longText.trim()),
						});

						const options = new GenerateNextMessageOptions({
							minNumberedListSize: 999, dynamicListNumbers: [], llmHistory_keepLastXNumberedLists: 999, temperature: 1,
							useSystemPrompt: false,
						});
						let newLLMResponseText = "";
						await GenerateNextMessage([startMessage], options, nextTextSegment=>{
							newLLMResponseText += nextTextSegment;
						});

						console.log("Summarizing for long-text:", startMessage, "@Summary:", newLLMResponseText);

						setLLMOutput(newLLMResponseText);
					}}/>}
					<Button ml="auto" mdIcon="transfer-down" enabled={(outputText ?? "").trim().length > 0} onClick={()=>{
						const proceed = ()=>{
							const segments_new = Clone(entry.segments);
							segments_new[segmentIndex] = new JournalSegment({...segment, shortText: outputText!});
							new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
						};
						if (segment.shortText.length > 0) {
							ShowMessageBox({
								title: "Confirm replace short-text?", cancelButton: true,
								message: `
									The short-text is currently ${segment.shortText.length} characters long.
									Replace it with ${(outputText ?? "").length} characters?
								`.AsMultiline(0),
								onOK: proceed,
							});
						} else {
							proceed();
						}
					}}/>
				</Row>
				{uiState.summarize_tab == SummarizeTab.simple &&
				<Row>
					<Text ml={5} style={{width: 125, whiteSpace: "pre"}}>Target length ({targetLength}):</Text>
					<div style={{flex: 1}}>
						<Slider value={targetLength} min={0} max={48} step={1} instant onChange={val=>setTargetLength(val)}/>
					</div>
				</Row>}
				{uiState.summarize_tab == SummarizeTab.simple &&
				<Row style={{flexWrap: "wrap"}}>
					{simple_outputTerms?.map((term, index)=>{
						return <TermCell columns={8} key={index} index={index} term={term} dndInfo={null}/>;
					})}
				</Row>}
				{uiState.summarize_tab == SummarizeTab.llmTemplate && <>
					<TextArea style={{border: "1px solid black"}} autoSize={true}
						value={uiState.summarize_llmTemplate} onChange={val=>RunInAction_Set(this, ()=>uiState.summarize_llmTemplate = val)}/>
				</>}
				{uiState.summarize_tab == SummarizeTab.llmGenerate &&
				<TextArea style={{border: "1px solid black"}} autoSize={true} value={llmOutput} onChange={val=>setLLMOutput(val)}/>}
			</Column>
		);
	}
}