import {makeObservable, runInAction} from "mobx";
import {EEGReading, MuseClient, MUSE_SERVICE, AccelerometerData, XYZ, TelemetryData, GyroscopeData} from "muse-js";
import {Subscription} from "rxjs";
import {InAndroid, nativeBridge, InDesktop} from "../../../Utils/Bridge/Bridge_Native";
import {O, OnPopulated, RunInAction} from "web-vcore";
import {Timer, SleepAsync, ToInt, Assert} from "js-vextensions";
import {GyroProcessingLevel} from "../../../UI/@Shared/Processors/GyroProcessor";
import {store} from "../../../Store/index.js";
import {RequestMuseDevice_Reliable} from "../../../Utils/Bridge/Bridge_Preload";
import {EEGListener, EEGSet, EEGSample_interval, EEGSet_sampleCount, EEGSample} from "./MuseInterface/EEGStructs";
import {GyroListener, GyroSet, GyroSample_interval, GyroSet_sampleCount, GyroSample} from "./MuseInterface/GyroStructs";
import {TelemetryListener} from "./MuseInterface/TelemetryStructs";
import {SessionLog} from "./BetweenSessionTypes/SessionLog.js";
import {LogType} from "./LogEntry.js";

export type MuseConnectStatus = "disconnected" | "connecting" | "connected";
/*type EEGSet = (EEGReading & {receiveTime: number});
type GyroSet = (EEGReading & {receiveTime: number});*/

const rcAttemptSequence_maxStepWait = 10000;
export class MuseInterface {
	/*constructor(config: CameraConfig) {
		this.cameraConfig = config;
		//this.cameraConfig = Clone(config); // use clone, avoiding mobx-getters (improves perf in pixel-processing loop)
		this.StartEngineConfigMirrorer();
		this.StartStreamToggler();
	}*/
	constructor() {
		makeObservable(this);

		if (InAndroid(0)) {
			nativeBridge.RegisterFunction("OnChangeMuseConnectStatus", (status: MuseConnectStatus)=>{
				RunInAction("OnChangeMuseConnectStatus", ()=>this.museStatus = status);
			});
			nativeBridge.RegisterFunction("OnEEGProcessorAdvance_Batch", samples=>{
				for (const sample of samples) {
					this.eegListeners.forEach(a=>a(sample));
				}
			});
		} else {
			this.client = new MuseClient();
			this.client.connectionStatus.subscribe(rawStatus=>{
				const oldStatus = this.museStatus;
				const newStatus = rawStatus ? "connected" : "disconnected";
				if (newStatus == oldStatus) return; // happens when subscription first added

				SessionLog(`Muse connect-status changed: ${newStatus} (was: ${oldStatus})`);
				RunInAction("MuseClient_connectionStatus_change", ()=>this.museStatus = newStatus);
				this.museStatus_changedAt = Date.now();

				// if just connected
				if (oldStatus != "connected" && newStatus == "connected") {
					//this.reconnectTimer.Stop();
					this.attemptingReconnect = false;
				}
				// if just disconnected
				else if (oldStatus == "connected" && newStatus == "disconnected") {
					// and was intended, just reset flag
					if (this.userTriggeredDCIsAwaitingStatusChange) {
						this.userTriggeredDCIsAwaitingStatusChange = false;
					} else {
						// if not already attempting a reconnect (eg. from layer-2 freeze-detect system), start one now
						if (!this.attemptingReconnect) {
							SessionLog(`Muse disconnected without user action. Attempting reconnection...`);
							//this.reconnectTimer.Start();
							this.attemptingReconnect = true;
							this.AttemptReconnectSequence();
						}
					}
				}
			});
		}
	}

	@O museStatus: MuseConnectStatus = "disconnected";
	museStatus_changedAt = 0;
	eegListeners = [] as EEGListener[];
	gyroListeners = [] as GyroListener[];
	telemetryListeners = [] as TelemetryListener[];

	// if using muse-js
	clientDevice: BluetoothDevice|n;
	clientGATT: BluetoothRemoteGATTServer|n;
	client: MuseClient;
	clientEEGSubscription: Subscription|n;
	clientGyroSubscription: Subscription|n;
	clientTelemetrySubscription: Subscription|n;

	/*reconnectTimer = new Timer(5000, async()=>{
		if (!this.inReconnectSequence) {
			this.AttemptReconnectSequence();
		}
	});*/
	attemptingReconnect = false;
	lastReconnectAttemptStartTime = 0;
	async AttemptReconnectSequence(attemptIndex = 0) {
		const CheckSuccessOrCancel = ()=>{
			if (this.client.connectionStatus.value) {
				SessionLog(`Reconnect attempt succeeded.`);
				this.attemptingReconnect = false;
				return true;
			}
			if (!this.attemptingReconnect) {
				SessionLog(`Reconnect attempt canceled. Ending reconnect sequence...`);
				return true;
			}
			return false;
		};
		if (CheckSuccessOrCancel()) return;

		this.lastReconnectAttemptStartTime = Date.now();
		const stepWaitTime = (2000 + (attemptIndex * 2000)).KeepAtMost(rcAttemptSequence_maxStepWait);
		try {
			// only do full disconnect on 2nd+ attempt (it's usually not necessary)
			if (attemptIndex >= 1) {
				/*await Promise.race([
					this.Disconnect(false).then(()=>SleepAsync(500)),
					SleepAsync(stepWaitTime),
				]);*/
				await this.Disconnect(false); // for the disconnect step, we must wait the full duration (for 10s reset period)
				if (CheckSuccessOrCancel()) return;
			}

			// only do device+gatt re-acquire on 3rd+ attempt (it's usually not necessary)
			if (attemptIndex >= 2) {
				//SessionLog("Reacquiring reference to Muse device and gatt-server...");
				this.clientDevice = null;
				this.clientGATT = null;
				await this.EnsureDeviceObtained(stepWaitTime.KeepAtLeast(4000));
				this.EnsureGATTObtained();
				if (CheckSuccessOrCancel()) return;
			}

			// if this.clientDevice null (from failed re-acquire), don't even attempt Connect(), as it relies on this.clientDevice
			if (this.clientDevice != null) {
				await Promise.race([
					this.Connect(false),
					SleepAsync(stepWaitTime.KeepAtLeast(4000)), // wait at least 4s for Connect() to try to complete
				]);
			}
		} catch (ex) {
			SessionLog(`Reconnect attempt hit error:${ex}`);
		}
		if (CheckSuccessOrCancel()) return;

		SessionLog(`Reconnect attempt failed. Trying again in ${stepWaitTime / 1000}s.`);

		// wait a bit, then make another attempt (with increased step wait-time)
		await SleepAsync(stepWaitTime);
		//if (CheckSuccessOrCancel()) return; // commented; check already occurs at start of next attempt
		this.AttemptReconnectSequence(attemptIndex + 1);
	}

	/*Unsubscribe() {
		if (InAndroid(0)) {
			nativeBridge.UnregisterFunction("OnEEGProcessorAdvance_Batch");
		} else {
			this.clientEEGSubscription?.unsubscribe();
			this.clientEEGSubscription = null;
		}
	}*/

	requesters = [] as any[];
	AddConnectRequester(requester: any) {
		const oldRequestersLength = this.requesters.length;
		this.requesters.push(requester);
		if (oldRequestersLength == 0) {
			this.Connect(true);
		}
	}
	RemoveConnectRequester(requester: any) {
		this.requesters.Remove(requester);
		if (this.requesters.length == 0) {
			this.Disconnect(true);
		}
	}

	obtainingDevice = false;
	// support using a "max-wait-time" for this func, because if Muse is off, the native request call will await forever, and (for rc caller) we don't want multiple callers buffered-up/later-overlapping
	async EnsureDeviceObtained(maxWaitTime?: number) {
		if (this.clientDevice != null) return;

		SessionLog("Obtaining reference to Muse device and gatt-server...");
		const startTime = Date.now();

		if (InDesktop()) {
			//this.clientDevice = await RequestMuseDevice_Reliable();
			window["RequestMuseDevice_Reliable_result"] = null;
			await Promise.race([
				RequestMuseDevice_Reliable(),
				maxWaitTime ? SleepAsync(maxWaitTime) : new Promise(()=>{}),
			]);
			this.clientDevice = window["RequestMuseDevice_Reliable_result"]; // set by call above
		} else {
			// when device is not immediately available, requestDevice errors unless part of a user-gesture call-stack (for web/non-priveleged code)
			// so only have the first call (eg. from OnStart_Early) in stack perform the request; later-stacked requests just wait for it
			if (!this.obtainingDevice) {
				this.obtainingDevice = true;
				try {
					this.clientDevice = await Promise.race([
						navigator.bluetooth.requestDevice({
							filters: [{services: [MUSE_SERVICE]}],
						}),
						maxWaitTime ? SleepAsync(maxWaitTime) as any : new Promise(()=>{}),
					]);
				} finally {
					this.obtainingDevice = false;
				}
			} else {
				while (this.obtainingDevice && (maxWaitTime == null || Date.now() < startTime + maxWaitTime)) {
					await SleepAsync(100);
				}
			}
		}

		if (this.clientDevice != null) {
			SessionLog(`Obtained reference to Muse device and gatt-server. (took ${((Date.now() - startTime) / 1000).toFixed(1)}s)`);
		} else {
			throw new Error(`Failed to obtain reference to Muse device and gatt-server. (within wait-time of ${maxWaitTime != null ? maxWaitTime / 1000 : "[n/a]"}s)`);
		}
	}
	/*async EnsureDeviceObtained() {
		if (this.clientDevice == null) {
			this.clientDevice = await navigator.bluetooth.requestDevice({
				filters: [{services: [MUSE_SERVICE]}],
			});
		}
	}*/
	EnsureGATTObtained() {
		if (this.clientGATT == null) {
			this.clientGATT = this.clientDevice?.gatt;
		}
	}

	lastUserTriggeredConnectTime = 0;
	get ConnectReady() { return !this.dcInProgress; }
	private async Connect(userTriggered: boolean) {
		if (!this.ConnectReady) {
			SessionLog("Waiting for Connect() call to be ready...");
			while (!this.ConnectReady) await SleepAsync(100);
		}

		SessionLog(`Connecting to Muse device... (${userTriggered ? "user triggered" : "for reconnect attempt"})`);
		if (InAndroid(0)) {
			nativeBridge.Call("Muse_Connect");
		} else {
			if (userTriggered) {
				this.lastUserTriggeredConnectTime = Date.now();
				this.layer2FreezeChecker.Start();
			}

			this.userTriggeredDCIsAwaitingStatusChange = false; // fixes that rc was being blocked, if Disconnect() previously called when already dc'ed
			//const firstConnect = this.clientDevice == null;
			//if (!this.client.connectionStatus.value) {
			await this.EnsureDeviceObtained(10000);
			this.EnsureGATTObtained();
			await this.clientGATT?.connect();
			if (this.clientGATT) await this.client.connect(this.clientGATT);

			// call resume() instad of start(), to better match with BlueMuse: https://github.com/kowalej/BlueMuse/blob/c8449174cc81c366570e58d4f75b6bf05024092a/BlueMuse.App/MuseManagement/Muse.cs#L365
			// todo: maybe switch this back (after testing to fix Seb connect issue), since resume()-only uses default preset (which seems to start unnecessary PPG stream -- as inferred from PPG light turning on)
			//await this.client.start();
			await this.client.resume();

			// if subscription exists from last run, unsubscribe that instance first
			if (this.clientEEGSubscription) {
				this.clientEEGSubscription.unsubscribe();
				this.clientEEGSubscription = null;
			}
			this.clientEEGSubscription = this.client.eegReadings.subscribe(reading=>{
				//console.log("Got EEG reading:", reading);
				this.eegReadingsToPackage.push(reading);

				this.PackageReadingsIntoSets();
				this.ResolveSetSourceTimes_AndPackageIntoSamples();
				this.ProcessSamplesInFullyResolvedRange();
			});

			// if subscription exists from last run, unsubscribe that instance first
			if (this.clientGyroSubscription) {
				this.clientGyroSubscription.unsubscribe();
				this.clientGyroSubscription = null;
			}
			this.clientGyroSubscription = this.client.gyroscopeData.subscribe(reading=>{
				//console.log("Got gyro reading:", reading);
				this.gyroReadingsToPackage.push(reading);

				this.PackageReadingsIntoSets();
				this.ResolveSetSourceTimes_AndPackageIntoSamples();
				this.ProcessSamplesInFullyResolvedRange();
			});

			// if subscription exists from last run, unsubscribe that instance first
			if (this.clientTelemetrySubscription) {
				this.clientTelemetrySubscription.unsubscribe();
				this.clientTelemetrySubscription = null;
			}
			this.clientTelemetrySubscription = this.client.telemetryData.subscribe(reading=>{
				//console.log("Got telemetry reading:", data);

				// telemetry data is currently not used in sessions/simulations, so no need for buffering
				this.telemetryListeners.forEach(a=>a(reading));
				this.lastProcessedTelemetrySample = reading;
			});
		}
	}

	dcInProgress = false;
	userTriggeredDCIsAwaitingStatusChange = false;
	get DisconnectReady() { return !this.dcInProgress; }
	private async Disconnect(userTriggered: boolean) {
		if (!this.DisconnectReady) {
			SessionLog("Waiting for Disconnect() call to be ready...");
			while (!this.DisconnectReady) await SleepAsync(100);
		}

		SessionLog(`Disconnecting from Muse device... (${userTriggered ? "user triggered" : "for reconnect attempt"})`);
		if (InAndroid(0)) {
			nativeBridge.Call("Muse_Disconnect");
		} else {
			this.dcInProgress = true;
			//this.lastDCStartTime = Date.now();
			if (userTriggered) {
				this.userTriggeredDCIsAwaitingStatusChange = true;
				//this.reconnectTimer.Stop(); // also stop any in-progress rc-attempts
				this.attemptingReconnect = false; // also stop any in-progress rc-attempts
				this.layer2FreezeChecker.Stop();
			}

			try {
				//await this.client.sendCommand("h");
				await this.client.pause(); // to match BlueMuse's calling pause before disconnecting: https://github.com/kowalej/BlueMuse/blob/c8449174cc81c366570e58d4f75b6bf05024092a/BlueMuse.App/MuseManagement/Muse.cs#L365
			} catch {}

			// tell Muse to end the BT connection (not needed for most computers/BT-dongles, but some apparently require this call)
			// (command from: https://github.com/kowalej/BlueMuse/blob/master/BlueMuse.App/Misc/Constants.cs)
			// (use "new TextDecoder().decode(new Int8Array([...]))", with the bytes [excluding first and last] from the C# command, to convert it into a string sendable with "client.sendCommand(str)")
			try {
				await this.client.sendCommand("*1");
				//await SleepAsync(100); // give small delay, just in case the promise from sendCommand/writeValue resolves a bit too early
				await SleepAsync(10000); // wait full 10 seconds, to match wait code in Connect() (which itself is to match BlueMuse wait)
			} catch {} // ignore error, since command's usually not necessary, and error most likely just means it's being called on a prior-ended connection (which is normal for the dc->rc process)

			this.client.disconnect();
			// commented; gatt.disconnect() is already called within client.disconnect()
			/*if (this.clientGATT == null) return; // happens if failed to find/connect-to device
			this.clientGATT.disconnect(); // dc needed prior to next start, so saves time to start now*/
			await SleepAsync(5000); // 3s seems sufficient, but do 5s to be safe

			this.dcInProgress = false;
		}
	}
	//lastDCStartTime = 0;

	layer2FreezeChecker = new Timer(1000, ()=>{
		this.CheckForLayer2Freeze();
	});
	CheckForLayer2Freeze() {
		const uiState = store.main.tools.monitor;

		// if muse not even listed as connected, return (this case is handled by regular AttemptReconnectSequence() system above)
		// todo: probably fully merge the AttemptReconnectSequence() system into this check-interval-based system
		//if (!this.client.connectionStatus.value) return;

		// if a user-triggered Connect() call started recently, don't yet check for frozen stream (we need to give it time)
		const timeSinceUserTriggeredConnectStart = Date.now() - this.lastUserTriggeredConnectTime;
		if (timeSinceUserTriggeredConnectStart < uiState.muse_freezeDetectTime * 1000) return;

		// if muse was listed as "connected" recently, don't yet check for frozen stream (we need to give it time, ie. a chance to finish connecting/data-streaming)
		const timeSinceListedAsConnected = Date.now() - this.museStatus_changedAt;
		if (timeSinceListedAsConnected < uiState.muse_freezeDetectTime * 1000) return;

		// if a rc-attempt started recently, don't yet check for frozen stream (we need to give it time; and if rc-attempt keeps looping, that's fine, since it will reach the attempt-index layer-2 triggers anyway)
		const timeSinceReconnectAttempt = Date.now() - this.lastReconnectAttemptStartTime;
		if (timeSinceReconnectAttempt < (rcAttemptSequence_maxStepWait * 4) + 5000) return;

		// if eeg-sample was received recently, don't start reconnection (no need!)
		const timeSinceEEGSample = Date.now() - this.lastProcessedEEGSample?.time;
		if (timeSinceEEGSample > uiState.muse_freezeDetectTime * 1000) {
			this.NotifyLayer2Freeze(timeSinceEEGSample);
		}
	}
	NotifyLayer2Freeze(freezeDuration: number) {
		SessionLog(`Muse layer-2 freeze/disconnection detected (frozen for ${(freezeDuration / 1000).RoundTo(1)}s). Will disconnect, reacquire device BT-ref, then attempt reconnection.`, LogType.Event_Large);
		this.attemptingReconnect = true;
		this.client.connectionStatus.next(false); // correct "connected" flag to its true state of "disconnected" (at least that's closest, since no data is being received) [needed so reconnect sequence proceeds]
		this.AttemptReconnectSequence(2); // do a full-rc cycle (dc, reaquire device, rc), since a shallow "reconnect" could fail again, preventing attempt-index from increasing (and thus full-rc from ever occurring)
	}

	// packaging into sets
	eegReadingsToPackage: EEGReading[] = [];
	gyroReadingsToPackage: AccelerometerData[] = [];

	// resolving (and repackaging into samples)
	eegSetsToResolve: EEGSet[] = [];
	gyroSetsToResolve: GyroSet[] = [];
	eegSet_nextSourceTimeExpected: number;
	gyroSet_nextSourceTimeExpected: number;
	highestResolvedEEGSampleTime = 0;
	highestResolvedGyroSampleTime = 0;

	// processing
	samplesToProcess: (EEGSample | GyroSample)[] = [];

	// persistence of last entries, after processing
	lastProcessedEEGSample: EEGSample;
	lastProcessedGyroSample: GyroSample;
	lastProcessedTelemetrySample: TelemetryData; // helper (eg. so no waiting needed for Monitor/Muse/Others)

	PackageReadingsIntoSets() {
		const eegReadingIndexes = this.eegReadingsToPackage.map(a=>a.index).Distinct();
		const newlyPackagedEEGSets = eegReadingIndexes.map(index=>{
			// electrodes) 0: left_ear, 1: left_forehead, 2: right_forehead, 3: right_ear
			const leftData = this.eegReadingsToPackage.find(a=>a.index == index && a.electrode == 1);
			const rightData = this.eegReadingsToPackage.find(a=>a.index == index && a.electrode == 2);
			const leftEarData = this.eegReadingsToPackage.find(a=>a.index == index && a.electrode == 0);
			const rightEarData = this.eegReadingsToPackage.find(a=>a.index == index && a.electrode == 3);
			return {
				index,
				left: leftData,
				right: rightData,
				left_ear: leftEarData,
				right_ear: rightEarData,
			} as EEGSet;
		}).filter(a=>a.left && a.right && a.left_ear && a.right_ear).OrderBy(a=>a.index);
		const newlyPackagedGyroSets = this.gyroReadingsToPackage.map(reading=>{
			return {
				index: reading.sequenceId,
				samples: reading.samples,
			} as GyroSet;
		}).OrderBy(a=>a.index);

		this.eegSetsToResolve.AddRange(newlyPackagedEEGSets);
		this.gyroSetsToResolve.AddRange(newlyPackagedGyroSets);

		// trim all buffered readings that are prior to the point we've just packaged
		//this.eegReadingsToPackage.Clear(); // technically this should work, but the filter version is clearer
		if (newlyPackagedEEGSets.length) {
			this.eegReadingsToPackage = this.eegReadingsToPackage.filter(a=>a.index > newlyPackagedEEGSets.Last().index);
		}
		this.gyroReadingsToPackage.Clear();
	}
	ResolveSetSourceTimes_AndPackageIntoSamples() {
		// don't progress resolving until we have an unprocessed set from every channel (needed for consistent drift-checking... I think...)
		if (this.eegSetsToResolve.length == 0 || this.gyroSetsToResolve.length == 0) return;

		const resolveTime = Date.now();
		if (this.eegSet_nextSourceTimeExpected == null) {
			this.eegSet_nextSourceTimeExpected = resolveTime.FloorTo(EEGSample_interval);
			this.gyroSet_nextSourceTimeExpected = resolveTime.FloorTo(GyroSample_interval);
		}
		// if drift-realignment is enabled, check for drift
		else if (store.main.settings.maxSampleTimeDrift > 0) {
			/*const eeg_nextSourceTimeExpected = this.lastProcessedEEGSet ? this.lastProcessedEEGSet.sourceTime + (EEGSample_interval * EEGSet_sampleCount) : processTime.FloorTo(EEGSample_interval);
			const gyro_nextSourceTimeExpected = this.lastProcessedGyroSet ? this.lastProcessedGyroSet.sourceTime + (GyroSample_interval * GyroSet_sampleCount) : processTime.FloorTo(GyroSample_interval);*/
			const eegResolvingDelay = resolveTime - this.eegSet_nextSourceTimeExpected;
			const gyroResolvingDelay = resolveTime - this.gyroSet_nextSourceTimeExpected;
			const maxResolvingDelay = store.main.settings.maxSampleTimeDrift * 1000;
			if (Math.abs(eegResolvingDelay) > maxResolvingDelay || Math.abs(gyroResolvingDelay) > maxResolvingDelay) {
				if (Math.abs(eegResolvingDelay) > maxResolvingDelay) {
					SessionLog(`EEG sample's [resolve-time - source-time] offset (${eegResolvingDelay}) rose above threshold (${store.main.settings.maxSampleTimeDrift}s). Realigning eeg+gyro samples...`, LogType.Event_Large);
				} else {
					SessionLog(`Gyro sample's [resolve-time - source-time] offset (${gyroResolvingDelay}) rose above threshold (${store.main.settings.maxSampleTimeDrift}s). Realigning eeg+gyro samples...`, LogType.Event_Large);
				}
				this.eegSet_nextSourceTimeExpected = resolveTime.FloorTo(EEGSample_interval);
				this.gyroSet_nextSourceTimeExpected = resolveTime.FloorTo(GyroSample_interval);
			}
		}

		// resolve source-times for the sets in buffer
		for (const set of this.eegSetsToResolve) {
			//set.resolveTime = resolveTime;
			set.sourceTime = this.eegSet_nextSourceTimeExpected;
			if (DEV) Assert(set.sourceTime.IsMultipleOf(EEGSample_interval, 0));
			this.eegSet_nextSourceTimeExpected += EEGSample_interval * EEGSet_sampleCount;
		}
		for (const set of this.gyroSetsToResolve) {
			//set.resolveTime = resolveTime;
			set.sourceTime = this.gyroSet_nextSourceTimeExpected;
			if (DEV) Assert(set.sourceTime.IsMultipleOf(GyroSample_interval, 0));
			this.gyroSet_nextSourceTimeExpected += GyroSample_interval * GyroSet_sampleCount;
		}

		// convert sets into standalone samples (note that the samples will not be fully sorted-by-time at this point)
		const newSamplesToProcess: (EEGSample | GyroSample)[] = [];
		for (const set of this.eegSetsToResolve) {
			let nextSampleTime = set.sourceTime;
			for (let i = 0; i < EEGSet_sampleCount; i++) {
				const sample = {time: nextSampleTime, left: set.left.samples[i], right: set.right.samples[i], left_ear: set.left_ear.samples[i], right_ear: set.right_ear.samples[i]} as EEGSample;
				// never process samples older than (or equal to) those previously resolved, else can cause issues downstream (can happen due to source-time realignment code above)
				if (sample.time <= this.highestResolvedEEGSampleTime) continue;

				this.highestResolvedEEGSampleTime = this.highestResolvedEEGSampleTime.KeepAtLeast(sample.time);
				newSamplesToProcess.push(sample);
				nextSampleTime += EEGSample_interval;
			}
		}
		for (const set of this.gyroSetsToResolve) {
			let nextSampleTime = set.sourceTime;
			for (let i = 0; i < GyroSet_sampleCount; i++) {
				const sample = {time: nextSampleTime, x: set.samples[i].x, y: set.samples[i].y, z: set.samples[i].z} as GyroSample;
				// never process samples older than (or equal to) those previously resolved, else can cause issues downstream (can happen due to source-time realignment code above)
				if (sample.time <= this.highestResolvedGyroSampleTime) continue;

				this.highestResolvedGyroSampleTime = this.highestResolvedGyroSampleTime.KeepAtLeast(sample.time);
				newSamplesToProcess.push(sample);
				nextSampleTime += GyroSample_interval;
			}
		}
		this.samplesToProcess.AddRange(newSamplesToProcess);

		this.eegSetsToResolve.Clear();
		this.gyroSetsToResolve.Clear();
	}
	ProcessSamplesInFullyResolvedRange() {
		const pointBeforeWhichSamplesAreFullyResolved = Math.min(this.highestResolvedEEGSampleTime, this.highestResolvedGyroSampleTime);
		const samplesInFullyResolvedRange = this.samplesToProcess.filter(a=>a.time < pointBeforeWhichSamplesAreFullyResolved);

		function IsEEGSample(sample: EEGSample | GyroSample): sample is EEGSample { return sample["left"] != null; }
		// order the samples by time (but if an eeg and gyro sample share a time, order the gyro one first, to match SessionDataProcessor.ProcessSample_Simulation)
		const samplesToProcess_ordered = samplesInFullyResolvedRange.OrderBy(a=>(IsEEGSample(a) ? 1 : 0)).OrderBy(a=>a.time);

		// perform the processing
		for (const sample of samplesToProcess_ordered) {
			//if (g.test1) continue; // temp; for testing layer-2 freeze-detect system

			if (IsEEGSample(sample)) {
				this.eegListeners.forEach(a=>a(sample));
				this.lastProcessedEEGSample = sample;
			} else {
				this.gyroListeners.forEach(a=>a(sample));
				this.lastProcessedGyroSample = sample;
			}
		}

		// remove the samples that we just processed, from the buffer
		this.samplesToProcess = this.samplesToProcess.filter(a=>a.time >= pointBeforeWhichSamplesAreFullyResolved);
	}
}
/*export let currentMuse: MuseInterface;
OnStoreLoaded(()=>{
	autorun(()=>{
		if (currentCamera) {
			currentCamera.cameraConfig = store.main.tools.monitor.cameraConfig;
		} else {
			currentCamera = new CameraInterface(store.main.tools.monitor.cameraConfig);
		}
	});
});*/
//export const currentMuse = new MuseInterface();
export let currentMuse: MuseInterface;
OnPopulated(()=>{
	currentMuse = new MuseInterface();
});