import {Timer} from "js-vextensions";
import React from "react";
import {Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {DefaultSkin, RunInAction_Set, UseWindowEventListener} from "web-vcore";
import {store} from "../../../Store/index.js";
import {SetLockOverlayActive} from "./JSessionUI.js";

export class LockOverlay extends BaseComponent<{}, {drag_downPosX: number|n, drag_lastPosX: number|n}> {
	ComponentWillMount = ()=>SetLockOverlayActive(true);
	ComponentWillUnmount = ()=>SetLockOverlayActive(false);

	endDragTimer = new Timer(2000, ()=>{
		this.SetState({drag_downPosX: null, drag_lastPosX: null});
	}, 1);
	get DragStillActive() { return this.endDragTimer.Enabled; }
	get DragDelta_AsPercent() {
		let {drag_downPosX, drag_lastPosX} = this.state;
		return drag_downPosX != null && drag_lastPosX != null ? ((drag_lastPosX - drag_downPosX) / window.innerWidth) * 100 : null;
	}
	//OnTouchStart = (touchPosX: number, touchPosY: number)=>this.SetState({drag_downPosX: touchPosX, drag_lastPosX: null});
	OnFirstTouchStart = (touchPosX: number, touchPosY: number)=>{
		const inEdgeZone =
			(touchPosX <= window.innerWidth * 0.12 && store.main.settings.lockOverlay_dragDirection.IsOneOf("leftToRight", "either"))
			|| (touchPosX >= (window.innerWidth * 0.88) && store.main.settings.lockOverlay_dragDirection.IsOneOf("rightToLeft", "either"));
		if (!inEdgeZone) return;
		this.SetState({drag_downPosX: touchPosX, drag_lastPosX: null});
		this.endDragTimer.Start(); // require user to complete drag within X seconds; else, drag is force-ended
	};
	OnFirstTouchMove = (touchPosX: number, touchPosY: number)=>{
		if (!this.DragStillActive) return;
		/*if (Math.abs(this.DragDelta_AsPercent) >= 80) {
			RunInAction_Set(()=>store.main.tools.journey.locked = false);
		}*/
		this.SetState({drag_lastPosX: touchPosX});
	};
	//OnTouchEnd = (touchPosX: number, touchPosY: number)=>{
	OnAllTouchesEnded = ()=>{
		if (!this.DragStillActive) return;
		if (this.DragDelta_AsPercent != null && Math.abs(this.DragDelta_AsPercent) >= 80) {
			RunInAction_Set(()=>store.main.tools.journey.locked = false);
		}
		this.SetState({drag_downPosX: null, drag_lastPosX: null});
	};
	
	render() {
		let {} = this.props;

		// when window-size changes, update canvas size to match it (needed, eg. if nav-button toolbar gets hidden just after overlay closes)
		UseWindowEventListener("resize", ()=>this.Update());

		return (
			<div
				style={{
					position: "fixed", left: 0, top: 0, right: 0, bottom: 0, zIndex: 100,
					display: "flex", alignItems: "center", justifyContent: "center",
					background: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(3px)",
					touchAction: "none",
				}}
				// use "touches" rather than "changedTouches"; this way, if multiple fingers are down, we only process the first one (else can accidentally unlock by just having a contact-point on each side)
				onTouchStart={e=>e.touches.length == 1 && this.OnFirstTouchStart(e.touches[0].clientX, e.touches[0].clientY)}
				onMouseDown={e=>this.OnFirstTouchStart(e.clientX, e.clientY)}
				onTouchMove={e=>this.OnFirstTouchMove(e.touches[0].clientX, e.touches[0].clientY)}
				onMouseMove={e=>this.OnFirstTouchMove(e.clientX, e.clientY)}
				//onTouchEnd={e=>this.OnTouchEnd(e.touches[0].clientX, e.touches[0].clientY)}
				//onMouseUp={e=>this.OnTouchEnd(e.clientX, e.clientY)}
				onTouchEnd={e=>e.touches.length == 0 && this.OnAllTouchesEnded()}
				onMouseUp={e=>this.OnAllTouchesEnded()}
			>
				<Row style={{
					width: "100%", height: "100%",
					transform: this.DragDelta_AsPercent != null && `translateX(${this.DragDelta_AsPercent}%)`,
					opacity: .5,
				}}>
					<div style={{
						width: "12%", height: "100%",
						display: "flex", alignItems: "center", justifyContent: "center",
						background: "rgba(0, 0, 0, 0.5)", fontSize: "10vw", color: "rgb(0, 110, 200)",
						opacity: store.main.settings.lockOverlay_dragDirection.IsOneOf("leftToRight", "either") ? 1 : 0,
						fontFamily: DefaultSkin.main.MainFont(), // include Symbola font, for the "target" emoji
					}}>🞋</div>
					<div style={{
						flex: 1, height: "100%",
						display: "flex", alignItems: "center", justifyContent: "center",
						fontSize: "4.3vw", fontWeight: "bold", color: "#FFF",
						//textShadow: "-1px -1px 0 #0F0, 1px -1px 0 #0F0, -1px 1px 0 #0F0, 1px 1px 0 #0F0",
						//textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
						// add blue glow using css filter
						//filter: Array(6).fill("drop-shadow(rgb(0, 130, 255) 0px 0px 15vh)").join(" "),
					}}>Swipe from edge to edge to unlock.</div>
					<div style={{
						width: "12%", height: "100%",
						display: "flex", alignItems: "center", justifyContent: "center",
						background: "rgba(0, 0, 0, 0.5)", fontSize: "10vw", color: "rgb(0, 110, 200)",
						opacity: store.main.settings.lockOverlay_dragDirection.IsOneOf("rightToLeft", "either") ? 1 : 0,
						fontFamily: DefaultSkin.main.MainFont(), // include Symbola font, for the "target" emoji
					}}>🞋</div>
				</Row>
			</div>
		);
	}
}