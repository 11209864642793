import "mobx"; // import mobx before we declare the module below, otherwise vscode auto-importer gets confused at path to mobx
import {Firelink, GetDoc, SetDefaultFireOptions, StoreAccessorOptions} from "mobx-firelink";
import {dbVersion} from "../../Main";
import {FirebaseDBShape} from "../../Store/firebase";
import {store, RootState} from "../../Store/index.js";
import {OnPopulated} from "web-vcore";
import {RootStoreShape} from "mobx-firelink/Dist/UserTypes";

declare module "mobx-firelink/Dist/UserTypes" {
	interface RootStoreShape extends RootState {}
	interface DBShape extends FirebaseDBShape {}
}

export const fire = new Firelink<RootStoreShape, FirebaseDBShape>();
store.firelink = fire;
SetDefaultFireOptions({fire});

const linkRootPath = `versions/v${dbVersion}-${DB}`;
export function InitFirelink() {
	fire.Initialize({rootPathInDB: linkRootPath, rootStore: store});
}

// modify some default options
// StoreAccessorOptions.default.cache_keepAlive = true;

// mobx debug helper
// ==========

/* window['mobxDevtools_processChange'] = (change) => {
	// change.rootStoreData = store;
	/* change.mapViews = store.main.mapViews['raw'];
	change.mapViews._sendFull = true; *#/
	change.mapViews = { _serialize() {
		// return store.main.mapViews['raw'];
		return Clone(store.main.mapViews['raw']);
	} };
}; */