import {E, GetEntries} from "js-vextensions";
import {Row, Select, Button, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {store} from "../../Store/index.js";
import {TrainingTab} from "../../Store/main/tools/training";
import {useExpandedNavBar} from "../../UI/@Shared/NavBar";
import {ES} from "../../Utils/UI/GlobalStyles";
import {Observer, PageContainer, RunInAction_Set} from "web-vcore";
import moment from "moment";
import {RecordingPanel} from "./Training/RecordingPanel";
import {TrainingPanel} from "./Training/TrainingPanel";
import {RecordingSessionGraph, RecordingSessionGraphPanel} from "./Training/RecordingSessionGraph";
import {ModelsPanel} from "./Training/ModelsPanel";
import {SessionsPanel} from "./Training/SessionsPanel";

// ui
// ==========

@Observer
export class TrainingUI extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.tools.training;
		const largeVersion = useExpandedNavBar();

		if (uiState.showGraphForSession) {
			return <RecordingSessionGraphPanel session={uiState.showGraphForSession}/>;
		}

		return (
			<PageContainer preset="text" style={E(
				//{padding: 0, height: "100%"},
				{padding: 0},
				//uiState.showGraphForSession && {height: "100%"},
				!largeVersion && {margin: "0 auto 0 auto"},
			)}>
				<Row>
					<Select displayType="button bar" options={GetEntries(TrainingTab)} value={uiState.tab} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.tab = val);
					}}/>
				</Row>
				<ScrollView style={ES({flex: 1, padding: 10})} contentStyle={{display: "flex", flexDirection: "column"}}>
					{uiState.tab == TrainingTab.Recording && <RecordingPanel/>}
					{uiState.tab == TrainingTab.Sessions && <SessionsPanel/>}
					{uiState.tab == TrainingTab.Training && <TrainingPanel/>}
					{uiState.tab == TrainingTab.Models && <ModelsPanel/>}
				</ScrollView>
			</PageContainer>
		);
	}
}