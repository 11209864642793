import {InfoButton, Observer, P, RunInAction, RunInAction_Set, SpeechRecognizer, VDateTime, VMediaRecorder} from "web-vcore";
import {Clone, GetEntries, StartDownload} from "js-vextensions";
import Moment from "moment";
import React from "react";
import {Button, CheckBox, Column, Row, Select, Spinner, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {DeleteJournalEntry} from "../../../Server/Commands/DeleteJournalEntry.js";
import {UpdateJournalEntry} from "../../../Server/Commands/UpdateJournalEntry.js";
import {JournalEntry, JournalSegment, SleepPosition} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../../Store/index.js";
import {AudioChunkPlayer} from "../../../Utils/ReactComponents/AudioChunkPlayer.js";
import {ES} from "../../../Utils/UI/GlobalStyles.js";
import {Story} from "../../../Store/firebase/stories.js";
import {FindMessagesInChain, GetStoryMessages, StoryMessage, StoryMessageProto} from "../../../Store/firebase/storyMessages.js";
import {DeleteStory} from "../../../Server/Commands/DeleteStory.js";
import {UpdateStory} from "../../../Server/Commands/UpdateStory.js";
import moment from "moment";
import {StoryMessageUI} from "./StoryMessageUI.js";
import {AddStoryMessage} from "../../../Server/Commands/AddStoryMessage.js";
import {GenerateNextMessage, GenerateNextMessageOptions} from "../../../Utils/Services/Anthropic.js";
import {SessionLog} from "../../Tools/@Shared/BetweenSessionTypes/SessionLog.js";

const browserSupportsSpeechRecognition = (g.SpeechRecognition ?? g.webkitSpeechRecognition) != null;

@Observer
export class StoryUI extends BaseComponentPlus({} as {entry: Story}, {}) {
	render() {
		const {entry} = this.props;
		const uiState = store.main.content;

		const messages = GetStoryMessages(entry.creator, entry._key);
		const messages_summaries = messages.filter(a=>a.summary_chainEnd != null);
		let messages_normal = messages.filter(a=>a.summary_chainEnd == null);

		const activeSummaryChains = messages_summaries.filter(a=>a.summary_enabled).map(summaryMessage=>{
			const sourceChain_endMessage = messages.find(a=>a._key == summaryMessage.summary_chainEnd);
			if (sourceChain_endMessage == null) return [];
			const isFirstMessageOfSummaryChain = (msg: StoryMessage)=>msg.parent == summaryMessage.parent;
			return FindMessagesInChain(messages, sourceChain_endMessage, false, isFirstMessageOfSummaryChain);
		});
		const messages_summarizedAway = activeSummaryChains.SelectMany(a=>a).Distinct().filter(a=>a.summary_chainEnd == null);
		if (uiState.hideSummarizedMessages) {
			messages_normal = messages_normal.filter(a=>!messages_summarizedAway.includes(a));
		}

		const mainChain = messages_normal.length ? FindMessagesInChain(messages, messages_normal.Last(), true) : [];
		const mainChain_summaries = mainChain.filter(a=>a.summary_chainEnd != null);
		const mainChain_noSummarizing = messages_normal.length ? FindMessagesInChain(messages, messages_normal.Last(), false) : [];
		const mainChain_summarizedAway = mainChain_noSummarizing.filter(a=>!mainChain.includes(a));
		const messages_sideBranches = messages.filter(a=>!mainChain.includes(a) && !mainChain_noSummarizing.includes(a));
		const messages_sideBranches_onesToKeep = messages_sideBranches.TakeLast(uiState.hideSideBranchMessages_enabled ? uiState.hideSideBranchMessages_keepLastX : 999999);

		const messages_summaries_onesToShow = messages_summaries.TakeLast(uiState.hideSummaryMessages_enabled ? uiState.hideSummaryMessages_keepLastX : 999999)
		const messages_normal_onesToShow = messages_normal.filter(a=>!messages_sideBranches.includes(a) || messages_sideBranches_onesToKeep.includes(a));
		
		// todo: maybe change this someday to use logic of ordering by the following string: (delayed since more complicated to impl.)
		// * "<index of first self or ancestor in main chain, padded>_<created-at time, in standard sv format>"
		const messagesToShow_chronological = [...messages_summaries_onesToShow, ...messages_normal_onesToShow].sort((a, b)=>{
			const a_indexInMainChain = mainChain.indexOf(a);
			const b_indexInMainChain = mainChain.indexOf(b);
			// if both are in main-chain, order by index in main chain
			if (a_indexInMainChain != -1 && b_indexInMainChain != -1) {
				return a_indexInMainChain - b_indexInMainChain;
			}

			// else, sort the two entries based on creation-time
			return a.createdAt - b.createdAt;
		});

		/*const mainChainMessages_hiddenAncestorChainLengths = new Map<StoryMessage, number>();
		let currentHiddenAncestorChainLength = 0;
		for (const message of mainChain_noSummarizing) {
			// if this main-chain message is also shown, store the built-up ancestor chain-length (if present), then reset the chain-length
			if (messagesToShow_chronological.includes(message)) {
				if (currentHiddenAncestorChainLength > 0) {
					mainChainMessages_hiddenAncestorChainLengths.set(message, currentHiddenAncestorChainLength);
				}
				currentHiddenAncestorChainLength = 0;
			}
			// if this main-chain message is hidden, simply increment the hidden-ancestor-chain-length
			else {
				currentHiddenAncestorChainLength++;
			}
		}*/

		return (
			<ScrollView style={ES({flex: 1})} contentStyle={{display: "flex", flexDirection: "column", backgroundColor: "hsla(0,0%,0%,.7)", borderRadius: 10, padding: 10}}>
				<Row style={{flexWrap: "wrap", gap: 5}}>
					<Button text="Back" onClick={()=>RunInAction_Set(this, ()=>store.main.content.selectedStory = null)}/>
					<Button text="Delete" enabled={messages.length == 0} onClick={()=>{
						ShowMessageBox({
							title: "Delete story?", cancelButton: true,
							message: "Permanently delete this story?",
							onOK: ()=>{
								new DeleteStory({id: entry._key}).Run();
							},
						});
					}}/>
					<Button text="Export" onClick={()=>{
						// open dialog where user can input the id of a story-message, in which case it will only export that message and its ancestors
						let targetMessageID = "";
						const controller = ShowMessageBox({
							title: "Export story", cancelButton: true,
							message: ()=>{
								const Change = (..._)=>controller.UpdateUI();
								return (
									<Row style={{flex: 1, padding: 10}}>
										<Text>Target chain, final message ID: </Text>
										<TextInput style={{flex: 1}} value={targetMessageID} onChange={val=>Change(targetMessageID = val)}/>
									</Row>
								);
							},
							onOK: async()=>{
								const targetMessage = messages.find(a=>a._key == targetMessageID);
								if (targetMessageID.length > 0 && targetMessage == null) {
									return void ShowMessageBox({title: "Error", message: "Specified message not found."});
								}

								const messagesToExport = targetMessage ? FindMessagesInChain(messages, targetMessage) : messages;
								const output = JSON.stringify({
									story: entry,
									messages: messagesToExport.map(a=>({...a, _id: a._key})),
								}, null, "\t");
								StartDownload(output, `StoryExport_${moment().format("YYYY-MM-DD-HH-mm-ss")}_${entry.title}.json`);
							},
						});
					}}/>
					<Button text="Summarize" onClick={()=>{
						const uiState = store.main.content;
						let summarize_firstMessage = uiState.mark_1 ?? "";
						let summarize_lastMessage = uiState.mark_2 ?? "";
						let summarize_command = `
							LLM, please compress the events from all the messages above into a timestamped outline titled "Events from Part ${mainChain_summaries.length + 1} of the story".

							Rules:
							* Don't omit any events in your timeline. It's fine for the outline to have dozens of events in it.
							* Each event should be placed on its own line, consisting of three sentences.
							* Start each event's text with the time/date stamp of that event, as seen within the original messages.
						`.AsMultiline(0);
						const controller = ShowMessageBox({
							title: "Summarize range of messages", cancelButton: true,
							okOnEnterKey: false,
							message: ()=>{
								const Change = (..._)=>controller.UpdateUI();
								return (
									<Column>
										<Row style={{flex: 1, padding: 10}}>
											<Text>First ID: </Text>
											<TextInput style={{flex: 1}} value={summarize_firstMessage} onChange={val=>Change(summarize_firstMessage = val)}/>
										</Row>
										<Row style={{flex: 1, padding: 10}}>
											<Text>Last ID: </Text>
											<TextInput style={{flex: 1}} value={summarize_lastMessage} onChange={val=>Change(summarize_lastMessage = val)}/>
										</Row>
										<Row>Summarize command:</Row>
										<TextArea autoSize={true} value={summarize_command} onChange={val=>Change(summarize_command = val)}/>
									</Column>
								);
							},
							onOK: async()=>{
								const firstMessage = messages.find(a=>a._key == summarize_firstMessage);
								if (firstMessage == null) return void ShowMessageBox({title: "Error", message: "First message not found."});
								const lastMessage = messages.find(a=>a._key == summarize_lastMessage);
								if (lastMessage == null) return void ShowMessageBox({title: "Error", message: "Last message not found."});
								const messageChainToSummarize = FindMessagesInChain(messages, lastMessage, true, a=>a._key == summarize_firstMessage);

								const messageChainToSummarize_plusSummarizeCommand = [
									...messageChainToSummarize,
									new StoryMessageProto({role: "user", content: summarize_command}),
								];

								const options = new GenerateNextMessageOptions({
									minNumberedListSize: 999, dynamicListNumbers: [], llmHistory_keepLastXNumberedLists: 999, temperature: 1,
									useSystemPrompt: false,
								});
								const summaryMessage = new StoryMessageProto({role: "assistant", content: "", partial: true, isSummary: true});
								await GenerateNextMessage(messageChainToSummarize_plusSummarizeCommand, options, nextTextSegment=>{
									summaryMessage.content += nextTextSegment;
								});

								console.log("Summarizing for chain:", messageChainToSummarize, "@Summary:", summaryMessage);

								const summaryMessage_final_id = await new AddStoryMessage({message: new StoryMessage({
									// proto data
									role: summaryMessage.role,
									content: summaryMessage.content,
									// extra data
									story: entry._key,
									parent: firstMessage.parent,
									summary_chainEnd: lastMessage._key,
									summary_enabled: true,
								})}).Run();
								SessionLog(`Uploaded new story message (type: summary): ${summaryMessage_final_id}`);
							},
						});
					}}/>
					<Button text="Info" enabled={messages_normal.length > 0} onClick={()=>{
						// show dialog with some info, eg. the number of messages in chain where last-message overall is the chain end-point
						ShowMessageBox({
							title: "Story info",
							message: ()=>{
								return (
									<Column sel>
										<Text>Summary messages: {messages_summaries.length} ({mainChain_summarizedAway.length} summarized away on main chain)</Text>
										<Text>Main-branch messages, before summarization: {mainChain_noSummarizing.length}</Text>
										<Text>Main-branch messages, after summarization: {mainChain.length}</Text>
										<Text>Side-branch messages: {messages_sideBranches.length}</Text>
									</Column>
								);
							},
						});
					}}/>
					<CheckBox text={`Hide summarized (${messages_summarizedAway.length})`} value={uiState.hideSummarizedMessages} onChange={val=>RunInAction_Set(this, ()=>uiState.hideSummarizedMessages = val)}/>
					<CheckBox text={`Hide summaries (${messages_summaries.length})`} value={uiState.hideSummaryMessages_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.hideSummaryMessages_enabled = val)}/>
					{uiState.hideSummaryMessages_enabled &&
					<>
						<Text>Keep last:</Text>
						<Spinner min={0} value={uiState.hideSummaryMessages_keepLastX} onChange={val=>uiState.hideSummaryMessages_keepLastX = val}/>
					</>}
					<CheckBox text={`Hide side-branches (${messages_sideBranches.length})`} value={uiState.hideSideBranchMessages_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.hideSideBranchMessages_enabled = val)}/>
					{uiState.hideSideBranchMessages_enabled &&
					<>
						<Text>Keep last:</Text>
						<Spinner min={0} value={uiState.hideSideBranchMessages_keepLastX} onChange={val=>uiState.hideSideBranchMessages_keepLastX = val}/>
					</>}
				</Row>
				<Row mt={5}>
					<Text>Title: </Text>
					<TextInput style={{flex: 1}} value={entry.title} onChange={val=>{
						new UpdateStory({id: entry._key, updates: {title: val}}).Run();
					}}/>
				</Row>
				<Row center mt={5}>
					<Text mr={5}>Created at: {moment(entry.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Text>
				</Row>
				<Text mt={5} mb={5}>Messages:</Text>

				{/* display all summary messages first, so they're easy to locate*/}
				{/*messages_summaries_onesToShow.map((message, index)=>{
					return <StoryMessageUI key={index} story={entry} message={message} messages={messages}/>;
				})}
				{messages_normal_onesToShow.map((message, index)=>{
					return <StoryMessageUI key={index} story={entry} message={message} messages={messages}/>;
				})*/}

				{messagesToShow_chronological.map((message, index)=>{
					return <StoryMessageUI key={index} story={entry} message={message} messages={messages} messagesShown={messagesToShow_chronological}/>;
				})}

				{/*<Row mt={5}>
					<Button text="Add message" onClick={()=>{
						const segments_new = Clone(entry.segments);
						segments_new.push(new JournalSegment());
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
				</Row>*/}
			</ScrollView>
		);
	}
}

