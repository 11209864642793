import {Assert} from "mobx-firelink";
import {TranscribeSettingsState} from "../../../../../Store/main/settings.js";
import {SamplesGraphYType} from "./SamplesGraph.js";
import {SummarySample, summarySamples_countPerSecond} from "../../../../../Utils/Bridge/Bridge_Native/PhoneSensors.js";
import {SmoothLine} from "../../../../Tools/Journey/JourneyStatsUI/AggregationData.js";
import {SmoothingType} from "../../../../../Store/main/tools/journey.js";

// todo: replace with instance in web-vcore/js-vextensions (once published)
export function AssertUnreachable(x: never, customMessage?: string): never {
	Assert(false, customMessage ?? `Encountered a value outside of the known, valid set. Unexpected value was: ${x}`);
	throw new Error("This should never be reached.");
}

export function GetYValuesForSamplesGraphYType(
	uiState: Pick<TranscribeSettingsState, "yType" | "smoothing">,
	xTicks: number[], xTicks_extendedForSmoothing: number[], recentSamples: SummarySample[], yType: SamplesGraphYType,
) {
	let yValues: number[];

	// NOTE: When multiple x-values/x-ticks exist "on the same pixel", uplot draws a "column" from the min-value to the max-value: https://github.com/leeoniya/uPlot/issues/15

	if (yType == SamplesGraphYType.absAvg) {
		yValues = xTicks_extendedForSmoothing.map(indexOffsetFromLastSample=>{
			const sample = recentSamples[(recentSamples.length - 1) + indexOffsetFromLastSample];
			if (sample == null) return 0;
			const amplitude = sample.absAvg;
			return amplitude;
		});
	} else if (yType == SamplesGraphYType.absAvg_directional) {
		// this is useful for (mostly) aligning with the "standard" segmenting approach (standard is RootMeanSquare, but avg of the absolute-values of the raw samples is quite close/similar)
		yValues = xTicks_extendedForSmoothing.map(indexOffsetFromLastSample=>{
			const sample = recentSamples[(recentSamples.length - 1) + indexOffsetFromLastSample];
			if (sample == null) return 0;
			const amplitude = sample.absAvg;
			return sample.avg >= 0 ? amplitude : -amplitude;
		});
	} else if (yType == SamplesGraphYType.absMax_directional) {
		// this is useful for seeing extremes in each sample
		yValues = xTicks_extendedForSmoothing.map(indexOffsetFromLastSample=>{
			const sample = recentSamples[(recentSamples.length - 1) + indexOffsetFromLastSample];
			if (sample == null) return 0;
			const absExtreme = Math.max(Math.abs(sample.max), Math.abs(sample.min)); // this is useful for seeing extremes in each sample
			return sample.avg >= 0 ? absExtreme : -absExtreme;
		});
	} else {
		AssertUnreachable(yType, "Invalid yType.");
	}

	if (uiState.smoothing > 0) {
		yValues = SmoothLine(yValues, uiState.smoothing * summarySamples_countPerSecond, SmoothingType.previous) as number[];
		// now that smoothing is done, trim the yValues to the correct/non-extended length
		yValues = yValues.slice(-xTicks.length);
	} else {
		Assert(xTicks_extendedForSmoothing.length == xTicks.length, "If not smoothing, xTicks_extendedForSmoothing should be same as xTicks.");
	}

	return yValues;
}