import {StoreAccessor} from "mobx-firelink";
import {RunInAction_Set, TextSpeaker, TimeToString} from "web-vcore";
import {Assert, Clone} from "js-vextensions";
import {Text} from "react-vcomponents";
import {UpdateJournalEntry} from "../../../Server/Commands/UpdateJournalEntry.js";
import {FBAConfig_DreamTranscribe, TranscribeTarget} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamTranscribe.js";
import {Journey_GetJournalEntriesToShow} from "../../../Store/firebase/journalEntries.js";
import {JournalEntry, JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../../Store/index.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {BaseSpanEffects, ExtractBracketedTextFromSpanText, ProcessTranscribedSpans, SpanEffect_NearTTS, SpansToText, StripBracketedTextFromSpanText, TranscribedSpan, WhisperTranscriptionWatcher} from "../../../Utils/Bridge/Whisper.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {AlarmsComp, AlarmsPhase} from "./AlarmsComp.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";

export class DreamTranscribeComp extends EngineSessionComp<FBAConfig_DreamTranscribe> {
	constructor(session: FBASession, config: FBAConfig_DreamTranscribe) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("Journey_TranscribeStart", this.c.transcribeStart_triggerSet, this, {}, triggerInfo=>{
				this.transcriptionWatcher.Register();
			}),
			new TriggerPackage("Journey_TranscribeEnd", this.c.transcribeEnd_triggerSet, this, {}, async triggerInfo=>{
				// for now, we use simple logic of "background mode" just meaning we can't stop transcription
				if (!this.c.background_enabled) {
					this.transcriptionWatcher.UnregisterAfterTranscribedToNow();
				}
			}),
		];
	}
	GetStatusUI() {
		return <Text style={{whiteSpace: "pre"}}>{`TODO`.AsMultiline(1)}</Text>;
	}

	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.s.c.general.defaultVoice_volumeMultiplier * volumeMultiplier);
	}

	// players
	//soundPlayer = new SoundPlayer(); // we use SoundPlayer (not TextSpeaker), since it's project-specific so understands Sound-objects directly
	textSpeaker = new TextSpeaker();

	//cycle_transcriptionChunksForSolver = [] as {text: string, duration: number}[];
	cycle_transcribedSpansForSolver = [] as TranscribedSpan[];
	OnLeavePhase_Sleep(newPhase: AlarmsPhase): void {
		this.cycle_transcribedSpansForSolver.Clear();
	}

	GetCurrentTranscriptionTarget() {
		if (this.behaviorEnabled) {
			const {journalEntry, transcribeTargetSegment} = GetKeySegmentsInCurrentDream();

			let transcribeTarget_fromJourneyComp = this.c.transcribeTarget;
			const transcribeTarget_fromJourneyComp_invalid = transcribeTarget_fromJourneyComp == TranscribeTarget.dreamSegment && (journalEntry == null || transcribeTargetSegment == null);
			if (!transcribeTarget_fromJourneyComp_invalid) {
				return transcribeTarget_fromJourneyComp;
			}
		}
		return TranscribeTarget.panel;
	}
	transcriptionWatcher = new WhisperTranscriptionWatcher({
		keepsRecordingAlive: true,
		onChunkTranscribed: ({spans, chunkStartTime, chunkEndTime})=>{
			const text_withBracketing = SpansToText(ProcessTranscribedSpans(spans, [
				...BaseSpanEffects(),
				new SpanEffect_NearTTS({maxDist: this.c.bracketWordsNearTTS * 1000, bracket_wholeSpan: true}),
			]));
			const text_withBracketing_strippedText = StripBracketedTextFromSpanText(text_withBracketing);
			const text_withBracketing_bracketedText = ExtractBracketedTextFromSpanText(text_withBracketing);
			
			if (text_withBracketing.length > 0) {
				const {journalEntry, transcribeTargetSegment} = GetKeySegmentsInCurrentDream();
			
				let transcribeTarget = this.GetCurrentTranscriptionTarget();
				if (transcribeTarget == TranscribeTarget.panel) {
					const uiState = store.main.tools.journey.transcribe;
					RunInAction_Set(this, ()=>uiState.transcribeText = uiState.transcribeText + text_withBracketing + "\n");
				} else {
					// if bracketed-text cannot go to DJ, strip it out, and add it to the transcribe-panel's text instead (with timestamp)
					let text_withBracketing_forDJ = text_withBracketing;
					if (this.c.bracketWordsNearTTS_noDJ) {
						text_withBracketing_forDJ = text_withBracketing_strippedText;

						if (text_withBracketing_bracketedText.length > 0) {
							const uiState = store.main.tools.journey.transcribe;
							const bracketedText_withTimestamp = `[${TimeToString(chunkEndTime, {date: 0})}] {${text_withBracketing_bracketedText}}`;
							RunInAction_Set(this, ()=>uiState.transcribeText = uiState.transcribeText + bracketedText_withTimestamp + "\n");
						}
					}

					// ensure that after bracketed-text stripping, there's still text to send to DJ
					if (text_withBracketing_forDJ.length > 0) {
						Assert(journalEntry && transcribeTargetSegment, "These shouldn't be null. (if null, we should have redirected to branch above)");
						const segmentIndex = journalEntry.segments.indexOf(transcribeTargetSegment);
						const segments_new = Clone(journalEntry.segments) as JournalSegment[];
						const segment_newData = segments_new[segmentIndex];
						segment_newData.longText = (segment_newData.longText ?? "") + (segment_newData.longText?.length ? "\n" : "") + text_withBracketing_forDJ;
				
						new UpdateJournalEntry({id: journalEntry._key, updates: {segments: segments_new}}).Run();
					}
				}
			}

			let textForSpeakCountAndVibrate = this.c.bracketWordsNearTTS_noSpeakCountOrVibrate ? text_withBracketing_strippedText : text_withBracketing;
			const wordCount = TextToWordsOrTargets(textForSpeakCountAndVibrate, false).length;
			if (wordCount > 0) {
				if (this.c.onTranscribe_speakWordCount) {
					this.NarrateText(wordCount + "");
				}
				if (wordCount > 0 && this.c.onTranscribe_vibrateDuration > 0) {
					nativeBridge.Call("VibratePhone", this.c.onTranscribe_vibrateDuration * 1000, this.c.onTranscribe_vibrateStrength);
				}
			}
			
			if (this.c.solver_enabled && this.s.Comp(AlarmsComp).IsSolvingCurrentlyPossible()) {
				const spans_forSolver = ProcessTranscribedSpans(spans, [
					...BaseSpanEffects(),
					// use whole-span removal; better to err on over-rejection than under-rejection (else user could cheat and use dream-quiz option-switching to fulfill requirements)
					new SpanEffect_NearTTS({maxDist: this.c.solver_ignoreWordsNearTTS * 1000, remove_wholeSpan: true}),
				]);
				this.cycle_transcribedSpansForSolver.push(...spans_forSolver);
				this.TryToSolve(spans_forSolver);
			}
		},
	});

	TryToSolve(newSpans: TranscribedSpan[]) {
		const fullText = SpansToText(this.cycle_transcribedSpansForSolver);
		const wordsTranscribed = TextToWordsOrTargets(fullText, false);

		const alarmsComp = this.s.Comp(AlarmsComp);
		const success = ()=>{
			alarmsComp.NotifySolveCompleted(this.c.solver_maxTimeForProgress);
			this.cycle_transcribedSpansForSolver.Clear();
			return true;
		};
		const fail = ()=>{
			if (this.c.solver_markProgressBeforeCompletion) {
				//this.Log(`User transcribed text, but it didn't match the requirements. @transcribedText:"${fullText}"`, LogType.Event_Large);
				const newSpans_text = SpansToText(newSpans);
				// if new-spans had at least one word, consider it an "attempt at solving" the current alarms
				if (TextToWordsOrTargets(newSpans_text, false).length > 0) {
					alarmsComp.NotifySolveProgress(this.c.solver_maxTimeForProgress);
				}
			}
			return false;
		};

		// check basic transcribed-word-count requirement
		if (wordsTranscribed.length < this.c.solver_minWords) {
			return fail();
		}

		// check if the user-defined required word-sequence is found
		if (this.c.solver_wordSequence.startsWith("/") && this.c.solver_wordSequence.endsWith("/")) {
			const regex = new RegExp(this.c.solver_wordSequence.slice(1, -1), "i");
			if (!regex.test(fullText)) {
				return fail();
			}
		} else {
			const targetsToFind = TextToWordsOrTargets(this.c.solver_wordSequence, true);
			let wordsTranscribed_stillInPool = wordsTranscribed.slice();
			for (const target of targetsToFind) {
				let indexAfterMatchInPool = -1;
				for (let i = 0; i < wordsTranscribed_stillInPool.length; i++) {
					const wordInPool = wordsTranscribed_stillInPool[i];
					if (wordInPool == target) {
						indexAfterMatchInPool = i + 1;
						break;
					}
					// handling for groups (ie. where a transcribed word only has to match one of a set of word-alternatives)
					if (target.includes("|")) {
						const targetOptions = target.split("|").map(a=>a.trim());
						const matchingTargetOpt = targetOptions.find(targetOpt=>{
							// special handling for multi-word target-options
							if (targetOpt.includes(" ")) {
								return wordsTranscribed_stillInPool.slice(i).join(" ").startsWith(targetOpt);
							}
							return wordInPool == targetOpt;
						});
						if (matchingTargetOpt) {
							// if matching target-opt is multi-word, move past all words in that sub-sequence
							indexAfterMatchInPool = i + matchingTargetOpt.split(" ").length;
							break;
						}
					}
				}
				if (indexAfterMatchInPool == -1) {
					return fail();
				}
				wordsTranscribed_stillInPool = wordsTranscribed_stillInPool.slice(indexAfterMatchInPool);
			}
		}

		// check if the user-defined required transcription time is met (even after max-transcription-time-counted-per-word adjustments)
		const transcribeTimeRequired = this.c.solver_minTime * 1000;
		const transcribeTimeCountedPerSpan = this.cycle_transcribedSpansForSolver.map(span=>{
			const wordsInSpan = TextToWordsOrTargets(span.text, false).length;
			const maxTranscribeTimePossibleForWords = wordsInSpan * this.c.solver_maxTimeValuePerWord * 1000;
			return (span.end - span.start).KeepAtMost(maxTranscribeTimePossibleForWords);
		});
		const totalTranscribeTimeCounted = transcribeTimeCountedPerSpan.Sum();
		//console.log([totalTranscribeTimeCounted, transcribeTimeRequired]);
		if (totalTranscribeTimeCounted < transcribeTimeRequired) {
			return fail();
		}

		return success();
	}

	OnStart() {
		if (this.c.background_enabled && !this.IsSuspended()) {
			this.transcriptionWatcher.Register();
		}
	}
	OnStop() {
		this.StopTextSpeaker();
		this.transcriptionWatcher.UnregisterAfterTranscribedToNow();
	}
	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}

	Unsuspend(): void {
		super.Unsuspend();
		if (this.c.background_enabled) {
			this.transcriptionWatcher.Register();
		}
	}
	Suspend(): void {
		super.Suspend();
		this.transcriptionWatcher.UnregisterAfterTranscribedToNow();
	}

	/*OnPhase_Alarm() {
		this.Log("Cycle started.", LogType.Event_Large);
		this.s.AsLocal!.AddEvent({type: "Journey.CycleStart"});
		this.cycleActive = true;
		this.cycle_targetReached = false;
		// if cycle is merely restarting after a target-detection/transcription-length fail, don't clear words-transcribed (let user build up to target-count)
		if (!isRestartAfterFail) {
			this.cycle_transcriptionChunks.Clear();
		}
		this.speakTimer.Stop();

		const alarmsComp = this.s.Comp(AlarmsComp);
		if (journeyComp.phase != AlarmsPhase.Alarm) {
			journeyComp.StartPhase_EntryWait_Bright();
		}
	}*/
}

export const GetKeySegmentsInCurrentDream = StoreAccessor(s=>(): {journalEntry: JournalEntry|n, lastEndedDreamSegment: JournalSegment|n, transcribeTargetSegment: JournalSegment|n}=>{
	const {journalEntryForSession} = Journey_GetJournalEntriesToShow();
	if (journalEntryForSession == null) return {journalEntry: null, lastEndedDreamSegment: null, transcribeTargetSegment: null};

	let lastEndedDreamSegment: JournalSegment|n;
	for (let i = 0; i < journalEntryForSession.segments.length; i++) {
		const segment = journalEntryForSession.segments[i];
		const prevSegment = journalEntryForSession.segments[i - 1] as JournalSegment|n;
		// if we reach an "awake" segment, and previous was a dream-segment, then mark previous as the "last ended dream segment" (so far)
		if (segment.wakeTime != null && prevSegment?.wakeTime == null) {
			lastEndedDreamSegment = prevSegment;
		}
	}
	const transcribeTargetSegment = lastEndedDreamSegment ?? journalEntryForSession.segments.filter(a=>a.wakeTime == null).LastOrX();
	return {journalEntry: journalEntryForSession, lastEndedDreamSegment, transcribeTargetSegment};
});

export function TextToWordsOrTargets(text: string, allowGroups: boolean) {
	let text_simplified = text.toLowerCase();
	// only alphanumerics + apostrophes + group-specifying-chars are kept; the rest all get replaced with spaces
	text_simplified = text_simplified.replace(/[^a-z0-9'()|]/g, " ");
	if (!allowGroups) text_simplified = text_simplified.replace(/[()|]/g, " ");
	text_simplified = text_simplified.replace(/ +/g, " ").trim();

	const resultWords = [] as string[];
	for (let i = 0; i < text_simplified.length; i++) {
		// skip standalone spaces
		if (text_simplified[i] == " ") {
			continue;
		}
		// special group-handling
		else if (text_simplified[i] == "(") {
			const groupEnder_charIndex = text_simplified.indexOf(")", i);
			if (groupEnder_charIndex != -1) {
				const groupText = text_simplified.slice(i + 1, groupEnder_charIndex);
				resultWords.push(groupText);
				// increase "i", so that next char processed is after the group
				i = groupEnder_charIndex;
				continue;
			}
		}
		// regular word-handling of seeking till next space
		else {
			const nextSpace_charIndex = text_simplified.indexOf(" ", i);
			const postWordCharIndex = nextSpace_charIndex == -1 ? text_simplified.length : nextSpace_charIndex;
			const word = text_simplified.slice(i, postWordCharIndex);
			resultWords.push(word);
			// increase "i", so that next char processed is after the word
			i = postWordCharIndex - 1; // (one less, since loop will increase it by 1 -- thus resuming at first post-word char)
		}
	}
	return resultWords;
};