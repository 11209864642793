import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";
import {EffectPointer} from "../../@Shared/EffectPointer.js";

export class FBAConfig_RealityCheck {
	constructor(initialData: Partial<FBAConfig_RealityCheck>) { this.VSet(initialData); }

	enabled: boolean;

	signalRC_triggerSet: TriggerSet;
	signalRC_effect: EffectPointer;

	reminder_minDelay: number; // in seconds
	reminder_maxDelay: number; // in seconds
	reminder_maxAmbientNoise: number;
	reminder_effect: EffectPointer;
}
AddSchema("FBAConfig_RealityCheck", {
	properties: {
		enabled: {type: "boolean"},
		
		signalRC_triggerSet: {$ref: "TriggerSet"},
		signalRC_effect: {$ref: "EffectPointer"},

		reminder_minDelay: {type: "number"},
		reminder_maxDelay: {type: "number"},
		reminder_maxAmbientNoise: {type: "number"},
		reminder_effect: {$ref: "EffectPointer"},
	},
});