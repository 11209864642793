import {IsNaN, Assert, ToJSON, ToNumber, ShallowChanged} from "js-vextensions";
import {observable} from "mobx";
import {EngineSessionInfo} from "../../../Store/firebase/sessions/@EngineSessionInfo";
import {GyroSampleFile} from "../../../Store/main/timeline";
import {FBAConfig_Gyro, GyroChannel} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Gyro";
import {RecreateGyroSamplesInSecond} from "../../../UI/Tools/@Shared/MuseInterface/SampleHelpers";
import {GyroSample} from "../../../UI/Tools/@Shared/MuseInterface/GyroStructs";
import {EEGProcessor} from "./EEGProcessor";
import {SessionDataProcessor} from "./SessionDataProcessor";

function GetMaxGyroSampleDependencyDist(options: GyroProcessorOptions) {
	const normalizationWindowSize = options.motion_sampleTrigger_normalizationWindowSize * options.samplesProcessedPerSecond;
	const smoothingWindowSize = options.motion_sampleTrigger_normalizationWindowSize * options.samplesProcessedPerSecond;
	const motionTriggerWindowSize = options.motion_motionTrigger_windowSize * options.samplesProcessedPerSecond;
	return Math.ceil(normalizationWindowSize + smoothingWindowSize + motionTriggerWindowSize);
}

/**
* Levels: (each level has some dependency on previous level; see info-text in EEGPanel.tsx for more context)
*
* 0/null: x, y, z, time_uplot?  
* 1: + dev.norm, time_uplot  
* 2: + dev.smoothed  
* 3: + dev.combinedDeviation, dev.display  
* 4: + triggerSamplePercent  
*/
export type GyroProcessingLevel = 0 | 1 | 2 | 3 | 4; // see EEGSample.processingLevel for details
export const GyroProcessingLevel_max = 4;

export type GyroProcessorOptions = FBAConfig_Gyro & {
	samplesProcessedPerSecond: number,
	calc_normalize: boolean,
	calc_smooth: boolean,
	calc_combinedDeviation: boolean,
	calc_triggerSamplePercent: boolean,

	uplotData_normalize: boolean;
	uplotData_smooth: boolean;

	clearOutOfRangeSamples: boolean;
};
export class GyroProcessor {
	constructor(opt: Partial<GyroProcessor>) {
		this.VSet(opt);
		if (this.options) {
			this.maxSampleDependencyDist = GetMaxGyroSampleDependencyDist(this.options);
		}
	}
	parent: SessionDataProcessor;
	options: GyroProcessorOptions;
	// options derivatives
	maxSampleDependencyDist: number;

	// todo: probably change this to use all flat arrays, as in EEGProcessor
	samples = [] as GyroSample[];
	// store this data separately, for uplot (and to enable typed-arrays, which use less memory -- though not currently used, since typed-arrays don't support nulls)
	alignedSamples_x_display = [] as number[]; // not used atm
	alignedSamples_y_display = [] as number[]; // not used atm
	alignedSamples_z_display = [] as number[]; // not used atm
	alignedSamples_combinedDeviation = [] as number[];

	// other stuff
	/*minX = 0;
	maxX = 0;*/
	sampleIndexesForSeconds = {} as {[key: number]: number};
	//sampleIndexesForAlignedSampleIndexes = {} as {[key: number]: number};
	sampleIndexesForAlignedSampleIndexes = new Map<number, number>(); // use Map for easier dev-tools inspection
	//processedSamples = 0;

	// eeg-processor passed, so that gyro samples can be aligned to eeg-sample slots/times
	Init_SavedSession(session: EngineSessionInfo, gyroSampleFiles: {x: GyroSampleFile, y: GyroSampleFile, z: GyroSampleFile}, eegProcessor: EEGProcessor) {
		this.alignedSamples_x_display.length = eegProcessor.samples_left.length;
		this.alignedSamples_y_display.length = eegProcessor.samples_left.length;
		this.alignedSamples_z_display.length = eegProcessor.samples_left.length;
		this.alignedSamples_combinedDeviation.length = eegProcessor.samples_left.length;

		const secondKeys = gyroSampleFiles.x.samplesBySecond.VKeys();
		if (secondKeys.length == 0) return;
		/*this.minX = secondKeys[0].ToInt();
		this.maxX = secondKeys.Last().ToInt();*/

		for (const secondKey of Object.keys(gyroSampleFiles.x.samplesBySecond)) {
			const secondTime = ToNumber(secondKey);
			this.sampleIndexesForSeconds[secondKey as any] = this.samples.length;

			const samplesInSecond = RecreateGyroSamplesInSecond(gyroSampleFiles.x.samplesBySecond, gyroSampleFiles.y.samplesBySecond, gyroSampleFiles.z.samplesBySecond, secondTime, eegProcessor);
			for (const sample of samplesInSecond) {
				const sampleI = this.samples.length;
				this.samples.push(sample); // just add directly (we need the performance -- reduces first 11hr-session render from 2.5s to 1s)

				this.sampleIndexesForAlignedSampleIndexes.set(sample.alignedIndex, sampleI);
			}
		}
	}

	AddAndProcessGyroSample(sample: Partial<GyroSample>, slotIndex = this.samples.length) {
		this.AddGyroSample(sample, slotIndex);
		this.ProcessGyroSample(slotIndex);
	}
	AddGyroSample(sample: Partial<GyroSample>, slotIndex = this.samples.length) {
		if (DEV) {
			Assert(sample.time != null, "Sample's time must be specified!");
			Assert(sample.x != null && sample.x != null && sample.z != null, ()=>`Sample cannot be empty! Sample: ${ToJSON(sample)}`);
			Assert(!IsNaN(sample.x) && !IsNaN(sample.y) && !IsNaN(sample.z), ()=>`Sample cannot have NaN values! Sample: ${ToJSON(sample)}`);
		}
		sample.processingLevel = sample.processingLevel ?? 0;
		sample.alignedIndex = sample.alignedIndex ?? slotIndex; // "aligned index" defaults to just the gyro-sample index; only "aligned" when from PopulateSamplesFromSessionData()
		//sample.time = sample.time ?? Date.now();
		this.samples[slotIndex] = sample as GyroSample;
	}

	get SamplesPerNormalizationWindow() {
		return Math.floor(this.options.motion_sampleTrigger_normalizationWindowSize * this.options.samplesProcessedPerSecond).KeepAtLeast(1);
	}
	GetPartialNormalizationSumFromPrecalcedSegment(newSegmentEndI: number, precalcedSegmentEndI: number) {
		const newSegmentStartI = (newSegmentEndI - this.SamplesPerNormalizationWindow).KeepAtLeast(0);
		const precalcedSegmentStartI = (precalcedSegmentEndI - this.SamplesPerNormalizationWindow).KeepAtLeast(0);
		const precalcedSegmentEndSample = this.samples[precalcedSegmentEndI - 1];
		let xSumToAdd = precalcedSegmentEndSample.x_sumOverNormalizationWindow;
		let ySumToAdd = precalcedSegmentEndSample.y_sumOverNormalizationWindow;
		let zSumToAdd = precalcedSegmentEndSample.z_sumOverNormalizationWindow;

		// subtract entries from the precalced-segment, which are out-of-range for the new segment
		const [subtract_startI, subtract_endI] = precalcedSegmentEndI < newSegmentEndI ? [precalcedSegmentStartI, newSegmentStartI] : [newSegmentEndI, precalcedSegmentEndI];
		for (let i = subtract_startI; i < subtract_endI; i++) {
			const sampleToSubtract = this.samples[i];
			xSumToAdd -= sampleToSubtract.x;
			ySumToAdd -= sampleToSubtract.y;
			zSumToAdd -= sampleToSubtract.z;
		}

		return {xSumToAdd, ySumToAdd, zSumToAdd};
	}
	Sample_CalculateNormalizedValues(sample: GyroSample, index: number) {
		Assert(sample.processingLevel == 0);
		let segmentXSum = 0;
		let segmentYSum = 0;
		let segmentZSum = 0;
		const segmentEndI = index + 1; // end-index is bound-exclusive
		const segmentStartI_preFix = segmentEndI - this.SamplesPerNormalizationWindow;
		const segmentStartI = segmentStartI_preFix.KeepAtLeast(0);

		// if sample just after this one has sumOverNormalizationWindow data (eg. eeg-processing saved session-data), use it to shortcut our own calc
		if (this.samples[index + 1]?.x_sumOverNormalizationWindow != null) {
			const {xSumToAdd, ySumToAdd, zSumToAdd} = this.GetPartialNormalizationSumFromPrecalcedSegment(segmentEndI, index + 2);
			segmentXSum += xSumToAdd;
			segmentYSum += ySumToAdd;
			segmentZSum += zSumToAdd;

			// if segment's start entry is outside of precalced-segment, add its value (the only time not outside, is near session-start boundary)
			if (segmentStartI_preFix >= 0) {
				const segmentStartSample = this.samples[segmentStartI];
				segmentXSum += segmentStartSample.x;
				segmentYSum += segmentStartSample.y;
				segmentZSum += segmentStartSample.z;
			}
		} else {
			const midPoint = (segmentStartI + segmentEndI) / 2;
			// iterate backward, so if we find a sumOverNormalizationWindow value, we get maximum benefit from it
			for (let i = segmentEndI - 1; i >= segmentStartI; i--) {
				const otherSample = this.samples[i];

				// if we found a "sumOverNormalizationWindow" value prior to halfway point [else not worth], use it to shortcut our own calc
				if (otherSample.x_sumOverNormalizationWindow != null && i > midPoint) {
					const {xSumToAdd, ySumToAdd, zSumToAdd} = this.GetPartialNormalizationSumFromPrecalcedSegment(segmentEndI, i + 1);
					segmentXSum += xSumToAdd;
					segmentYSum += ySumToAdd;
					segmentZSum += zSumToAdd;
					break;
				}

				segmentXSum += otherSample.x;
				segmentYSum += otherSample.y;
				segmentZSum += otherSample.z;
			}
		}

		const x_baseline = segmentXSum / (segmentEndI - segmentStartI);
		const y_baseline = segmentYSum / (segmentEndI - segmentStartI);
		const z_baseline = segmentZSum / (segmentEndI - segmentStartI);
		Object.assign(sample, {
			x_sumOverNormalizationWindow: segmentXSum,
			y_sumOverNormalizationWindow: segmentYSum,
			z_sumOverNormalizationWindow: segmentZSum,
			x_norm: sample.x - x_baseline,
			y_norm: sample.y - y_baseline,
			z_norm: sample.z - z_baseline,
		});
		// extra catch for first block (for easier debugging)
		if (DEV) Assert(!IsNaN(x_baseline), `x_baseline should not be NaN!`);
	}
	ProcessSample_L1(sample: GyroSample, index: number) {
		Assert(sample.processingLevel < 1, `Cannot re-perform l1-processing. Found: ${sample.processingLevel}`);

		// if time prop is null, this must be a dynamically-added sample (eg. on monitor page); set time now
		/*if (sample.time_uplot_aligned == null) {
			Assert(this.parent?.loadedSessionData?.session == null, "Sample is missing time_uplot_aligned data, despite being based on saved data.");
			sample.time_uplot_aligned = Date.now() / 1000;
		}*/
		/*if (this.samples_time[index] == null) {
			this.samples_time[index] = sample.time_uplot;
		}*/

		if (this.options.calc_normalize) { //&& sample.left_norm == null) { // normalized values maybe already calculated, if was needed by to-the-right sample's "..._smoothed" calculation
			this.Sample_CalculateNormalizedValues(sample, index);
		}
		sample.processingLevel = 1;
	}

	// todo: share code with normalization equivalent
	GetPartialSmoothingSumFromPrecalcedSegment(newSegmentEndI: number, precalcedSegmentEndI: number, ext: {smoothingWindowSize: number, useNormalizedValues: boolean}) {
		const newSegmentStartI = (newSegmentEndI - ext.smoothingWindowSize).KeepAtLeast(0);
		const precalcedSegmentStartI = (precalcedSegmentEndI - ext.smoothingWindowSize).KeepAtLeast(0);
		const precalcedSegmentEndSample = this.samples[precalcedSegmentEndI - 1];
		let sumToAddX = precalcedSegmentEndSample.x_sumForSmoothing;
		let sumToAddY = precalcedSegmentEndSample.y_sumForSmoothing;
		let sumToAddZ = precalcedSegmentEndSample.z_sumForSmoothing;

		// subtract entries from the precalced-segment, which are out-of-range for the new segment
		const [subtract_startI, subtract_endI] = precalcedSegmentEndI < newSegmentEndI ? [precalcedSegmentStartI, newSegmentStartI] : [newSegmentEndI, precalcedSegmentEndI];
		for (let i = subtract_startI; i < subtract_endI; i++) {
			const sampleToSubtract = this.samples[i];
			if (sampleToSubtract.processingLevel < 1) this.ProcessSample_L1(sampleToSubtract, i);
			sumToAddX -= ext.useNormalizedValues ? sampleToSubtract.x_norm : sampleToSubtract.x;
			sumToAddY -= ext.useNormalizedValues ? sampleToSubtract.y_norm : sampleToSubtract.y;
			sumToAddZ -= ext.useNormalizedValues ? sampleToSubtract.z_norm : sampleToSubtract.z;
		}

		return {sumToAddX, sumToAddY, sumToAddZ};
	}
	GetSampleSmoothedValues(sample: GyroSample, index: number, ext: {smoothingWindowSize: number, useNormalizedValues: boolean}) {
		let segmentSumX = 0;
		let segmentSumY = 0;
		let segmentSumZ = 0;
		const segmentEndI = index + 1;
		const segmentStartI_preFix = (segmentEndI - ext.smoothingWindowSize);
		const segmentStartI = segmentStartI_preFix.KeepAtLeast(0);

		// see "opts used for cache" in Sample_CalculateSmoothedValues()
		const matchesOptsUsedForCache = ext.smoothingWindowSize == this.options.motion_sampleTrigger_smoothing && ext.useNormalizedValues == this.options.calc_normalize;

		// if sample just after this one has sumForSmoothing data (eg. eeg-processing saved session-data), use it to shortcut our own calc
		if (matchesOptsUsedForCache && this.samples[index + 1]?.x_sumForSmoothing != null) {
			const {sumToAddX, sumToAddY, sumToAddZ} = this.GetPartialSmoothingSumFromPrecalcedSegment(segmentEndI, index + 2, ext);
			segmentSumX += sumToAddX;
			segmentSumY += sumToAddY;
			segmentSumZ += sumToAddZ;

			// if segment's start entry is outside of precalced-segment, add its value (the only time not outside, is near session-start boundary)
			if (segmentStartI_preFix >= 0) {
				const segmentStartSample = this.samples[segmentStartI];
				if (segmentStartSample.processingLevel < 1) this.ProcessSample_L1(segmentStartSample, segmentStartI);
				segmentSumX += ext.useNormalizedValues ? segmentStartSample.x_norm : segmentStartSample.x;
				segmentSumY += ext.useNormalizedValues ? segmentStartSample.y_norm : segmentStartSample.y;
				segmentSumZ += ext.useNormalizedValues ? segmentStartSample.z_norm : segmentStartSample.z;
			}
		} else {
			const midPoint = (segmentStartI + segmentEndI) / 2;
			// iterate backward, so if we find a sumForSmoothing value, we get maximum benefit from it
			for (let i = segmentEndI - 1; i >= segmentStartI; i--) {
				const otherSample = this.samples[i];

				// if we found a "left_sumForSmoothing" value prior to halfway point [else not worth], use it to shortcut our own calc
				if (matchesOptsUsedForCache && otherSample.x_sumForSmoothing != null && i > midPoint) {
					const {sumToAddX, sumToAddY, sumToAddZ} = this.GetPartialSmoothingSumFromPrecalcedSegment(segmentEndI, i + 1, ext);
					segmentSumX += sumToAddX;
					segmentSumY += sumToAddY;
					segmentSumZ += sumToAddZ;
					break;
				}

				if (otherSample.processingLevel < 1) this.ProcessSample_L1(otherSample, i);
				segmentSumX += ext.useNormalizedValues ? otherSample.x_norm : otherSample.x;
				segmentSumY += ext.useNormalizedValues ? otherSample.y_norm : otherSample.y;
				segmentSumZ += ext.useNormalizedValues ? otherSample.z_norm : otherSample.z;
			}
		}

		//if (segmentLeftSum / (segmentEndI - segmentStartI) > 100) debugger;
		return {
			x_sumForSmoothing: segmentSumX,
			y_sumForSmoothing: segmentSumY,
			z_sumForSmoothing: segmentSumZ,
			x_smoothed: segmentSumX / (segmentEndI - segmentStartI),
			y_smoothed: segmentSumY / (segmentEndI - segmentStartI),
			z_smoothed: segmentSumZ / (segmentEndI - segmentStartI),
		};
	}
	Sample_CalculateSmoothedValues(sample: GyroSample, index: number) {
		Assert(sample.processingLevel == 1);
		const smoothedValues = this.GetSampleSmoothedValues(sample, index, {
			smoothingWindowSize: this.options.motion_sampleTrigger_smoothing,
			useNormalizedValues: this.options.calc_normalize,
		});
		Object.assign(sample, smoothedValues);
	}
	ProcessSample_L2(sample: GyroSample, index: number) {
		Assert(sample.processingLevel < 2, `Cannot re-perform l2-processing. Found: ${sample.processingLevel}`);
		if (sample.processingLevel < 1) this.ProcessSample_L1(sample, index);

		if (this.options.calc_smooth) { //&& sample.left_smoothed == null) {
			this.Sample_CalculateSmoothedValues(sample, index);
		}
		sample.processingLevel = 2;
	}

	ProcessSample_L3(sample: GyroSample, index: number) {
		Assert(sample.processingLevel < 3, `Cannot re-perform l3-processing. Found: ${sample.processingLevel}`);
		if (sample.processingLevel < 2) this.ProcessSample_L2(sample, index);

		const opt = this.options;
		const x_forDerivatives = sample.x_smoothed ?? sample.x_norm ?? sample.x;
		const y_forDerivatives = sample.y_smoothed ?? sample.y_norm ?? sample.y;
		const z_forDerivatives = sample.z_smoothed ?? sample.z_norm ?? sample.z;

		// below inlined, so that afterward, processingLevel flag lets us know the processing was done (since data stored in no-null-values array)
		// if uplot-data settings match calculation settings, just use for-derivatives values directly
		if (opt.uplotData_normalize == opt.calc_normalize && opt.uplotData_smooth == opt.calc_smooth) {
			this.alignedSamples_x_display[sample.alignedIndex] = x_forDerivatives;
			this.alignedSamples_y_display[sample.alignedIndex] = y_forDerivatives;
			this.alignedSamples_z_display[sample.alignedIndex] = z_forDerivatives;
		} else {
			if (opt.uplotData_normalize && opt.uplotData_smooth) {
				const smoothedValues = this.GetSampleSmoothedValues(sample, index, {smoothingWindowSize: this.options.motion_sampleTrigger_smoothing, useNormalizedValues: true});
				this.alignedSamples_x_display[sample.alignedIndex] = smoothedValues.x_smoothed;
				this.alignedSamples_y_display[sample.alignedIndex] = smoothedValues.y_smoothed;
				this.alignedSamples_z_display[sample.alignedIndex] = smoothedValues.z_smoothed;
			} else if (opt.uplotData_normalize) {
				this.alignedSamples_x_display[sample.alignedIndex] = sample.x_norm;
				this.alignedSamples_y_display[sample.alignedIndex] = sample.y_norm;
				this.alignedSamples_z_display[sample.alignedIndex] = sample.z_norm;
			} else if (opt.uplotData_smooth) {
				const smoothedValues = this.GetSampleSmoothedValues(sample, index, {smoothingWindowSize: this.options.motion_sampleTrigger_smoothing, useNormalizedValues: false});
				this.alignedSamples_x_display[sample.alignedIndex] = smoothedValues.x_smoothed;
				this.alignedSamples_y_display[sample.alignedIndex] = smoothedValues.y_smoothed;
				this.alignedSamples_z_display[sample.alignedIndex] = smoothedValues.z_smoothed;
			} else {
				this.alignedSamples_x_display[sample.alignedIndex] = sample.x;
				this.alignedSamples_y_display[sample.alignedIndex] = sample.y;
				this.alignedSamples_z_display[sample.alignedIndex] = sample.z;
			}
		}

		// below inlined, so that afterward, processingLevel flag lets us know the processing was done (since data stored in no-null-values array)
		// (if calc_triggerSamplePercent, then combined deviation needs to be calculated as well)
		if (this.options.calc_combinedDeviation || this.options.calc_triggerSamplePercent) {
			const x_abs = Math.abs(x_forDerivatives);
			const y_abs = Math.abs(y_forDerivatives);
			const z_abs = Math.abs(z_forDerivatives);

			let combinedDeviation: number;
			if (opt.motion_channel == GyroChannel.All_Sum) {
				combinedDeviation = x_abs + y_abs + z_abs;
			} else if (opt.motion_channel == GyroChannel.All_Max) {
				combinedDeviation = Math.max(x_abs, y_abs, z_abs);
			} else if (opt.motion_channel == GyroChannel.X) {
				combinedDeviation = x_abs;
			} else if (opt.motion_channel == GyroChannel.Y) {
				combinedDeviation = y_abs;
			} else if (opt.motion_channel == GyroChannel.Z) {
				combinedDeviation = z_abs;
			}
			this.alignedSamples_combinedDeviation[sample.alignedIndex] = combinedDeviation!;
		}
		sample.processingLevel = 3;
	}

	get SamplesPerMotionTriggerWindow() {
		return Math.floor(this.options.motion_motionTrigger_windowSize * this.options.samplesProcessedPerSecond).KeepAtLeast(1);
	}
	Sample_CalculateTriggerSamplePercent(sample: GyroSample, index: number) {
		Assert(sample.processingLevel == 3);
		const opt = this.options;
		const windowEndI = index + 1;
		const windowStartI = (windowEndI - this.SamplesPerMotionTriggerWindow).KeepAtLeast(0);

		let samplesWhere_combinedDeviationIsAboveMin = 0;
		for (let i = windowStartI; i < windowEndI; i++) {
			const sample2 = this.samples[i];
			Assert(sample2);
			/*if (sample2.processingLevel < 4) this.ProcessSample_L4(sample2, i);
			const flooredDeviation = this.samples_flooredDeviation[i];
			if (flooredDeviation >= opt.motion_sampleTrigger_minDeviation_absolute_value) {
				samplesWhere_combinedDeviationIsAboveMin++;
			}*/
			if (sample2.processingLevel < 3) this.ProcessSample_L3(sample2, i);
			const combinedDeviation = this.alignedSamples_combinedDeviation[sample2.alignedIndex];
			if (combinedDeviation >= opt.motion_sampleTrigger_minDeviation) {
				samplesWhere_combinedDeviationIsAboveMin++;
			}
		}
		const percentOfSamplesWhere_combinedDeviationIsAboveMin = samplesWhere_combinedDeviationIsAboveMin / (windowEndI - windowStartI);

		sample.triggerSamplePercent = percentOfSamplesWhere_combinedDeviationIsAboveMin.NaNTo(0) * 100;
		//this.samples_triggerSamplePercent[index] = percentOfSamplesWhere_combinedDeviationIsAboveMin.NaNTo(0) * 100;
	}
	ProcessSample_L4(sample: GyroSample, index: number) {
		Assert(sample.processingLevel < 4, `Cannot re-perform l4-processing. Found: ${sample.processingLevel}`);
		if (sample.processingLevel < 3) this.ProcessSample_L3(sample, index);

		if (this.options.calc_triggerSamplePercent) {
			this.Sample_CalculateTriggerSamplePercent(sample, index);
		}
		sample.processingLevel = 4;
	}

	scannerPositions = new Map<string, number>();
	ProcessGyroSample(index: number) {
		const sample = this.samples[index];
		// final-level processing will also run any missed earlier levels
		this.ProcessSample_L4(sample, index);

		if (this.options.clearOutOfRangeSamples) {
			this.scannerPositions.set("r_process", index);
			this.ClearJustOutOfRangeData(this.scannerPositions, "all");
		}

		//this.processedSamples++;
		if (DEV && index % 1000 == 0) Assert(sample.VValues().every(a=>!IsNaN(a)), ()=>`No value resulting from processing should be NaN! Data:${ToJSON(sample)}`);
	}

	ClearJustOutOfRangeData(scannerPositions: Map<string, number>, dataGroup: "all") { //| "cache") {
		const scanPoints = Array.from(scannerPositions.values());
		const checkPoints = Array.from(scannerPositions.entries()).map(entry=>{
			//Assert(entry[0][0].IsOneOf("l", "r"), `Invalid scanner-name; must start with "l" or "r".`);
			if (entry[0][0] == "l") return entry[1] + (this.maxSampleDependencyDist + 1); // if scanner moving left
			return entry[1] - (this.maxSampleDependencyDist + 1); // if scanner moving right
		});
		for (const checkPoint of checkPoints) {
			// check for scan-point within max-sample-dependency-dist; if none found, sample's cache is not needed anymore!
			const pointStillInScanRange = scanPoints.Any(a=>a.Distance(checkPoint) <= this.maxSampleDependencyDist);
			if (!pointStillInScanRange) {
				if (dataGroup == "all") { // only enabled for cases with dynamic-arrays, so delete-ops will work
					const sample = this.samples[checkPoint];
					if (sample == null) continue; // can be null if, eg. index is out-of-bounds
					delete this.samples[checkPoint];
					delete this.alignedSamples_x_display[sample.alignedIndex]; // not used atm
					delete this.alignedSamples_y_display[sample.alignedIndex]; // not used atm
					delete this.alignedSamples_z_display[sample.alignedIndex]; // not used atm
					delete this.alignedSamples_combinedDeviation[sample.alignedIndex];
					//console.log("Deleted sample at:", checkPoint);
				} /*else {
					delete this.samples_cache[checkPoint];
					//console.log("Deleted cache at:", checkPoint);
				}*/
			}
		}
	}
}