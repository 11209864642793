import {AddSchema} from "mobx-firelink";
import {TriggerSet} from "../@TriggerSet";

export class FBAConfig_DreamNarration {
	constructor(initialData: Partial<FBAConfig_DreamNarration>) { this.VSet(initialData); }

	enabled: boolean;
	playAudioOnRemote: boolean;
	startVolume: number; // 0-1
	voiceSoundTag: string;
	narrationTemplate: string;

	repeatDream_triggerSet: TriggerSet;
	nextDream_triggerSet: TriggerSet;
	previousDream_triggerSet: TriggerSet;
	stopNarration_triggerSet: TriggerSet;
	increaseVolume_triggerSet: TriggerSet;
	increaseVolume_amount: number;
	decreaseVolume_triggerSet: TriggerSet;
	decreaseVolume_amount: number;
	/*volumeChangeBackgroundSound: string;
	volumeChangeBackgroundSound_loop: boolean;
	volumeChangeBackgroundSound_lingerTime: number;*/
	volumeChangePreviewText: string;
}
AddSchema("FBAConfig_DreamNarration", {
	properties: {
		enabled: {type: "boolean"},
		playAudioOnRemote: {type: "boolean"},
		startVolume: {type: "number"},
		voiceSoundTag: {type: ["null", "string"]},
		narrationTemplate: {type: "string"},

		repeatDream_triggerSet: {$ref: "TriggerSet"},
		nextDream_triggerSet: {$ref: "TriggerSet"},
		previousDream_triggerSet: {$ref: "TriggerSet"},
		stopNarration_triggerSet: {$ref: "TriggerSet"},
		increaseVolume_triggerSet: {$ref: "TriggerSet"},
		increaseVolume_amount: {type: "number"},
		decreaseVolume_triggerSet: {$ref: "TriggerSet"},
		decreaseVolume_amount: {type: "number"},
		/*volumeChangeBackgroundSound: {type: "string"},
		volumeChangeBackgroundSound_loop: {type: "boolean"},
		volumeChangeBackgroundSound_lingerTime: {type: "number"},*/
		volumeChangePreviewText: {type: "string"},
	},
});