import {FBAConfig_General} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_General.js";
import {FBASession} from "../../FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp.js";

export class GeneralComp extends EngineSessionComp<FBAConfig_General> {
	constructor(session: FBASession, config: FBAConfig_General) {
		super(session, config, s=>true, s=>s.IsLocal());
	}

	// todo: gradually move logic here from FBASession/FBASession_Local (if it fits)
}