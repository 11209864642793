import {IsString, Range, ToJSON} from "js-vextensions";
import Moment from "moment";
import {Button, Column, Div, Row, Select, Text, TextArea} from "react-vcomponents";
import {BaseComponent, BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {AddTimelineEvent} from "../../../Server/Commands/AddTimelineEvent";
import {Activity} from "../../../Store/firebase/activities/@Activity";
import {VDateTime, Observer, RunInAction} from "web-vcore";
import {ES} from "../../../Utils/UI/GlobalStyles";
import {GetTimelineEvents, GetTimelineEventsIntersectingRange} from "../../../Store/firebase/timelineEvents";
import {ShowMessageBox} from "react-vmessagebox";
import {runInAction} from "mobx";
import {store} from "../../../Store/index.js";
import {DeleteTimelineEvent} from "../../../Server/Commands/DeleteTimelineEvent";
import {UpdateTimelineEvent} from "../../../Server/Commands/UpdateTimelineEvent";
import {GetActivities} from "../../../Store/firebase/activities";
import {TimelineEvent} from "../../../Store/firebase/timelineEvents/@TimelineEvent";

@Observer
export class TimelineRow extends BaseComponentPlus({} as {timelineID: string, startTime: number, endTime: number, index: number, last: boolean}, {expanded: false}) {
	render() {
		const {startTime, endTime, timelineID, index, last} = this.props;
		const events = GetTimelineEventsIntersectingRange(timelineID, startTime, endTime);
		const activities = GetActivities(timelineID);
		const {expanded} = this.state;
		const expandedOrLast = expanded || last;
		return (
			<Column>
				<Row
						style={{
							position: "relative", height: expandedOrLast ? 71 : 70, borderTop: index != 0 ? "1px solid rgba(255,255,255,.3)" : "1px solid transparent",
							borderBottom: expandedOrLast ? "1px solid rgba(255,255,255,.3)" : "none", cursor: "pointer",
						}}
						onClick={()=>{
							this.SetState({expanded: !expanded});
						}}>
					<Div ml={10} style={{float: "left", zIndex: 1, fontSize: 12, color: "rgba(255,255,255,.5)"}}>
						{Moment(startTime).format("YYYY-MM-DD (ddd)")}
					</Div>
					{events.map(event=>{
						const eventEndTime = event.endTime || Date.now();
						const eventStart_percentFromLeftToRight = Moment(event.startTime).diff(startTime) / Moment(endTime).diff(startTime);
						const eventWidth_percentOfChartWidth = Moment(eventEndTime).diff(event.startTime).KeepAtLeast(0) / Moment(endTime).diff(startTime);
						return <EventUI key={event.startTime} event={event} xPosPercent={eventStart_percentFromLeftToRight}
							widthPercent={eventWidth_percentOfChartWidth} activities={activities}/>;
					})}
					<div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0, pointerEvents: "none"}}>
						{Range(0, 23).map(hour=>{
							return <div key={hour} style={{position: "absolute", left: `${(hour / 24) * 100}%`, width: 1, height: "100%", background: "rgba(128,128,128,.25)"}}/>;
						})}
					</div>
				</Row>
				{expanded &&
					<Column>
						<Row style={{height: 40, padding: 10}}>
							<Row>Events:</Row>
							<Row ml="auto">
								<Button text="Add event" onClick={()=>{
									const newEvent = new TimelineEvent({timeline: timelineID, activity: activities[0]._key,
										startTime, endTime: Moment(startTime).add(1, "hour").valueOf()});
									new AddTimelineEvent({event: newEvent}).Run();
								}}/>
							</Row>
						</Row>
						{events.map((event, eventIndex)=>{
							return <EventEditorUI key={eventIndex} event={event} index={eventIndex} last={eventIndex == events.length - 1} activities={activities} dayStartTime={startTime}/>;
						})}
					</Column>}
			</Column>
		);
	}
}

class EventUI extends BaseComponent<{event: TimelineEvent, xPosPercent: number, widthPercent: number, activities: Activity[]}, {}> {
	render() {
		const {event, xPosPercent, widthPercent, activities} = this.props;
		const activity = activities.filter(a=>a._key == event.activity)[0];
		const timeInMins = Moment(event.endTime || Date.now()).diff(event.startTime, "minutes");
		return (
			<Div style={{
				position: "absolute", left: `${xPosPercent * 100}%`, width: `${widthPercent * 100}%`, height: "100%",
				minWidth: 1, backgroundColor: activity ? `rgba(${activity.color})` : "",
			}}>
				<Column style={{position: "absolute", bottom: 0, width: "100%", height: "15%"}}>
					<div style={{width: "100%", height: "50%", background: "rgba(0,0,0,.5)", clipPath: "ellipse(50% 100% at 50% 100%)"}}/>
					<div style={{width: "100%", height: "50%", background: "rgba(0,0,0,.5)"}}/>
					<div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0, textAlign: "center", fontSize: 10, color: "white"}}>
						{timeInMins}
					</div>
				</Column>
			</Div>
		);
	}
}

class EventEditorUI extends BaseComponentPlus({} as {event: TimelineEvent, index: number, last: boolean, activities: Activity[], dayStartTime: number}, {}) {
	render() {
		const {event, index, last, activities, dayStartTime} = this.props;
		return (
			<Column p="7px 10px" style={ES(
				{background: "rgba(0,0,0,.7)", borderTop: "1px solid rgba(255,255,255,.3)"},
			)}>
				<Row center>
					<Text style={{flexShrink: 0}}>{index + 1}) </Text>
					<Text>Start time: </Text>
					<VDateTime dateFormat={false} value={event.startTime ? Moment(event.startTime) : null} max={event.endTime ? Moment(event.endTime) : null} onChange={val=>{
						/*if (IsString(val)) {
							let newTime_today = Moment(val.toString(), ["h:m a", "H:m"]);
							let newTime_correctDay = Moment(event.startTime).set({hour: newTime_today.get("hour"), minute: newTime_today.get("minute")});
						} else {
							var newTime_correctDay = val;
						}
						let updates = {startTime: newTime_correctDay.valueOf() as number};*/
						if (val == null) return;
						const updates = {startTime: Moment(dayStartTime).set({hour: val.get("hour"), minute: val.get("minute")}).valueOf()};
						new UpdateTimelineEvent({id: event._key, updates}).Run();
					}}/>
					<Text ml={5}>End time: </Text>
					<VDateTime dateFormat={false} value={event.endTime ? Moment(event.endTime) : null} min={event.startTime ? Moment(event.startTime) : null} onChange={val=>{
						const updates = {endTime: val ? Moment(dayStartTime).set({hour: val.get("hour"), minute: val.get("minute")}).valueOf() : undefined};
						new UpdateTimelineEvent({id: event._key, updates}).Run();
					}}/>
					<Text ml={5}>Activity: </Text>
					<Select options={activities.map(a=>({name: a.name, value: a._key}))} value={event.activity}
						onChange={val=>{
							const updates = {activity: val};
							new UpdateTimelineEvent({id: event._key, updates}).Run();
						}}/>
					{/*<Text ml={5}>FBA config: </Text>
					{event.fbaConfig
						? <InfoButton text={ToJSON(event.fbaConfig)}/>
						: <Text>None</Text>}
					<Button ml={5} text="Set to current" onClick={()=>{
						const updates = {fbaConfig: WithoutHelpers(fbaConfig)};
						new UpdateTimelineEvent({id: event._id, updates}).Run();
					}}/>
					<Button ml={5} text="Clear" onClick={()=>{
						const updates = {fbaConfig: null};
						new UpdateTimelineEvent({id: event._id, updates}).Run();
					}}/>*/}
					{event.sessionID &&
						<Button ml={5} text="Show session" onClick={()=>{
							RunInAction("EventEditorUI.ShowSession.onClick", ()=>{
								store.main.page = "timeline";
								store.main.timeline.subpage = "sessions";
								store.main.timeline.sessions.selectedSession = event.sessionID;
							});
						}}/>}
					<Button ml={5} text="X" onClick={()=>{
						ShowMessageBox({
							title: "Delete event?", cancelButton: true,
							message: "Permanently delete this timeline event?",
							onOK: ()=>{
								new DeleteTimelineEvent({id: event._key}).Run();
							},
						});
					}}/>
				</Row>
			</Column>
		);
	}
}