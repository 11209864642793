import {Button, Column, Row, Text, TextArea} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {GetSounds} from "../../Store/firebase/sounds";
import {MeID} from "../../Store/firebase/users";
import {ShowFolderInOSExplorer, ResetSessionScriptContext} from "../../Utils/Bridge/Bridge_Preload";
import {ES} from "../../Utils/UI/GlobalStyles";
import {Observer, PageContainer, InfoButton} from "web-vcore";
import {GetScripts, UpdateLocalScriptsMap, localScriptMap} from "../../Store/firebase/scripts";
import {store} from "../../Store/index.js";
import {liveFBASession, mainDataPathMarker} from "../../Engine/FBASession";
import {GetCurrentSessionID_ForSessionScriptContext, fakeSessionID_forNoActiveSession, activeScriptPlayers} from "../../Utils/EffectPlayers/ScriptPlayer";
import {InDesktop} from "../../Utils/Bridge/Bridge_Native";
import {columnWidths, TagTargetEntryUI} from "../@Shared/TagTargetEntryUI";

@Observer
export class ScriptsUI extends BaseComponentPlus({}, {}) {
	render() {
		const scripts = GetScripts(); //.OrderByDescending(a=>(a.creator == MeID() ? 1 : 0));
		const tracker1 = store.main.tools.engine.liveFBASession_setAt;

		return (
			<PageContainer style={{margin: "20px auto 20px auto", padding: 0}}>
				<Column className="clickThrough" style={{height: 80, borderRadius: "10px 10px 0 0"}}>
					<Row center style={{height: 40, padding: 10}}>
						<Button text="Open scripts folder" enabled={InDesktop()} onClick={()=>{
							ShowFolderInOSExplorer(`${mainDataPathMarker}/Scripts`);
						}}/>
						<Button ml={5} text="Refresh" enabled={InDesktop() && MeID() != null && liveFBASession == null} onClick={()=>{
							UpdateLocalScriptsMap(MeID());
							//ResetSessionScriptContext(GetCurrentSessionID_ForSessionScriptContext());
							for (const player of activeScriptPlayers) {
								player.Stop();
							}
							ResetSessionScriptContext(fakeSessionID_forNoActiveSession);
						}}/>
						<InfoButton ml={5} enabled={liveFBASession == null} text={`
							Pressing Refresh not only refreshes the UI list; it also resets the "script context", and reloads the scripts.
							
							(More precisely, it stops existing script effect instances, then derefs them, so new instance is created next time effect is played.)
						`.AsMultiline(0)}/>

						<Button ml="auto" text="Add script" enabled={InDesktop()} onClick={()=>{
							/*if (MeID() == null) return ShowSignInPopup();
							ShowAddScriptDialog();*/
							ShowMessageBox({
								title: "Must add scripts manually",
								message: ()=>(<Column>
									<Text>{`
										For security purposes, the frontend of this app is restricted from directly editing the code files of user-scripts.
										
										Press OK to open the user-scripts folder, where you can create a new user-script file manually.
										==========
										Instructions:
										1) Create a code file with filename ending in ".js".
										2) Paste the template code shown below (or start from scratch).
										3) Finish entering the code for the effect-script, then save.
										4) Press the "Refresh" button above list, to generate the json metadata file. (and show new entry)

										Template:
									`.AsMultiline(0)}
									</Text>
									<TextArea editable={false} style={{height: 250}} value={`
									const {shell} = require("electron");
									exports.Effect = class {
										async Start(info) {
											const {intensity} = info;
											// Code here runs when effect is "played". (eg. due to rem-detection from eye movement)
											shell.beep();
										}
										Stop() {
											// Code here runs when effect is "stopped". (eg. due to wake-detection from body movement)
											// * Only called if Start()'s execution/promise is still active when stop/play-again request is sent.
											// * Can be left empty or removed, if effect has a short duration anyway.
										}
									}
									`.AsMultiline(0)}/>
								</Column>),
								cancelButton: true,
								onOK: ()=>{
									ShowFolderInOSExplorer(`${mainDataPathMarker}/Scripts`);
								},
							});
						}}/>
					</Row>
					<Row style={{height: 40, padding: 10}}>
						<Text style={{flex: columnWidths[0], fontWeight: 500, fontSize: 16}}>Name</Text>
						<Text style={{flex: columnWidths[1], fontWeight: 500, fontSize: 16}}>Type</Text>
						<Text style={{flex: columnWidths[2], fontWeight: 500, fontSize: 16}}>Creator</Text>
						<Text style={{flex: columnWidths[3], fontWeight: 500, fontSize: 16}}><Text>Tags (click tags to </Text><span style={{color: "rgba(100,200,100,.7)"}}>accept</span>)</Text>
						<Text style={{flex: columnWidths[4], fontWeight: 500, fontSize: 16}}></Text>
					</Row>
				</Column>
				<ScrollView style={ES({flex: 1})} contentStyle={ES({flex: 1})}>
					{scripts.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>{InDesktop() ? "Loading..." : "Scripts are currently only usable in desktop app."}</div>}
					{scripts.map((script, index)=>{
						return <TagTargetEntryUI key={index} group="scripts" entry={script} index={index} last={index == scripts.length - 1}/>;
					})}
				</ScrollView>
			</PageContainer>
		);
	}
}