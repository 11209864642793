import {GetInvalidPropPaths, GetSchemaJSON} from "mobx-firelink";
import {ErrorBoundary, GetUpdates, Observer, RunInAction} from "web-vcore";
import {Clone, DeepGet, DeepSet, FromJSON, ToJSON} from "js-vextensions";
import React from "react";
import {Button, Column, Row, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {GetLiveFBASession_Reactive} from "../../../Engine/FBASession.js";
import {GetFBASessionPanelsToShow} from "../../../Engine/FBASession/ComponentRegistry.js";
import {FBASession_Local} from "../../../Engine/FBASession_Local.js";
import {AddFBAConfig} from "../../../Server/Commands/AddFBAConfig.js";
import {DeleteFBAConfig} from "../../../Server/Commands/DeleteFBAConfig.js";
import {UpdateFBAConfig} from "../../../Server/Commands/UpdateFBAConfig.js";
import {store} from "../../../Store";
import {GetSelectedFBAConfig, GetSelectedFBAConfigID} from "../../../Store/firebase/fbaConfigs.js";
import {FBAConfig} from "../../../Store/firebase/fbaConfigs/@FBAConfig.js";
import {FBAConfig_GetDefault, FBAConfig_GetDefault_Remote as FBAConfig_GetDefault_Client} from "../../../Store/firebase/fbaConfigs/@FBAConfig_Presets.js";
import {MeID} from "../../../Store/firebase/users.js";
import {IsUserCreatorOrMod} from "../../../Store/firebase/users/$user.js";
import {ShowSignInPopup} from "../../../UI/@Shared/NavBar/UserPanel.js";
import {SortKeysInObjectTree} from "../../../Utils/General/General.js";
import {DialogStyle} from "../../../Utils/UI/GlobalStyles.js";
import {EngineConfigPicker} from "../@Shared/EngineConfigPicker.js";
import {SessionManagementPanel_Local} from "./LocalPanel/SessionManagementPanel.js";
import {ScrollView} from "react-vscrollview";

export type FBAConfigChangerFunc = (config: FBAConfig)=>any;
export type FBASessionPanels_SharedProps = {client: boolean, enabled: boolean, active: boolean, config_full: FBAConfig, ChangeConfig_Full: (configChangerFunc: FBAConfigChangerFunc)=>any}; // is also used for Remote panel

export function ExtendFBASessionPanelSharedProps<T, T2>(props: FBASessionPanels_SharedProps, compConfigGetter: (config: FBAConfig)=>T, compConfigSetter: (config: FBAConfig, value: T)=>any, otherProps?: T2) {
	const {config_full, ChangeConfig_Full} = props;
	return {
		...props,
		config: compConfigGetter(config_full),
		ChangeConfig: (compConfigChangerFunc: (config: T)=>any)=>{
			ChangeConfig_Full(c=>{
				const newValue = compConfigGetter(c);
				compConfigChangerFunc(newValue);
				compConfigSetter(c, newValue);
			});
		},
		...otherProps,
	};
}

@Observer
export class FBAConfigPanel_Local extends BaseComponent<{journeyUI: boolean}, {}> {
	render() {
		const {journeyUI} = this.props;
		const config = GetSelectedFBAConfig()!;
		const liveSession = GetLiveFBASession_Reactive();
		const active = liveSession instanceof FBASession_Local;
		const enabled = IsUserCreatorOrMod(MeID(), config) && !active;

		const ChangeConfig = (configChangerFunc: FBAConfigChangerFunc)=>{
			const newConfig = Clone(config);
			// maybe temp; for now just strip away any properties that aren't recognized (fixes error after code upgrades that change property names)
			/*g.oldConfigs = (g.oldConfigs || []).concat(localConfig);
			newConfig = newConfig.IncludeKeys(...GetSchemaJSON("FBAConfig").properties.VKeys());*/

			configChangerFunc(newConfig);
			//store.dispatch(new ACTSet(a=>a.main.tools.fba.localConfig, newConfig));
			new UpdateFBAConfig({id: config._key!, updates: GetUpdates(config, newConfig, true)}).Run();
		};

		const sharedProps = {client: false, enabled, active, config_full: config, ChangeConfig_Full: ChangeConfig};

		const Container = journeyUI ? ScrollView : Column;
		return (
			<Container style={{
				flex: 1, //minWidth: 0,
				//alignItems: "flex-start", // improves layout on small screen, when h-scrolling needed
			}}>
				{!journeyUI && <SessionManagementPanel_Local {...sharedProps}/>}
				<ConfigSelectRow {...sharedProps}/>
				{config &&
				<ErrorBoundary>
					{GetFBASessionPanelsToShow(journeyUI).map((PanelClass, index)=>{
						return <PanelClass key={index} {...sharedProps}/>;
					})}
				</ErrorBoundary>}
				{!config &&
				<Text mt={10}>Select a configuration to see/edit its options.</Text>}
			</Container>
		);
	}
}

export class ConfigSelectRow extends BaseComponent<{style?: any} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {style, ...rest} = this.props;
		const configID = GetSelectedFBAConfigID();
		const config = GetSelectedFBAConfig()!;
		const liveSession = GetLiveFBASession_Reactive();
		const active = liveSession instanceof FBASession_Local;
		const enabled = IsUserCreatorOrMod(MeID(), config) && !active;

		const sharedProps = rest;
		return (
			<Row style={style}>
				<Text>Config:</Text>
				<EngineConfigPicker containerStyle={{marginLeft: 5}} style={{flex: 1}} value={configID} onChange={val=>{
					RunInAction("LocalPanel.selectedConfig.onChange", ()=>store.main.tools.engine.selectedConfig = val);
				}}/>
				<Button ml={5} enabled={!active} text="New" style={{fontSize: 12, padding: "3px 7px"}} onClick={async()=>{
					if (MeID() == null) return ShowSignInPopup();
					const newConfig = new FBAConfig({name: "New config"});
					const newConfigID = await new AddFBAConfig({entry: newConfig}).Run();
					RunInAction("LocalPanel.newConfig.onClick", ()=>store.main.tools.engine.selectedConfig = newConfigID);
				}}/>
				<Button ml={5} text="Clone" enabled={config != null && !active} style={{fontSize: 12, padding: "3px 7px"}} onClick={async()=>{
					if (MeID() == null) return ShowSignInPopup();
					const newConfig = Clone(config) as FBAConfig;
					newConfig.baseConfig = config._key;
					newConfig.name += " (copy)";
					const newConfigID = await new AddFBAConfig({entry: newConfig}).Run();
					RunInAction("LocalPanel.Clone.onClick", ()=>store.main.tools.engine.selectedConfig = newConfigID);
				}}/>
				<Button ml={5} text="Rename" enabled={config != null && enabled} style={{fontSize: 12, padding: "3px 7px"}} onClick={()=>{
					let newName = config.name;

					//let error = null;
					const Change = (..._)=>boxController.UpdateUI();
					const boxController = ShowMessageBox({
						title: "Rename FBA config", cancelButton: true,
						message: ()=>{
							//boxController.UpdateOptions({okButtonProps: {enabled: error == null}});
							return (
								<Column style={DialogStyle({width: 600})}>
									<Row>
										<Text>Name:</Text>
										<TextInput ml={5} style={{flex: 1}} value={newName} onChange={val=>Change(newName = val)}/>
									</Row>
								</Column>
							);
						},
						onOK: ()=>{
							new UpdateFBAConfig({id: config._key!, updates: {name: newName}}).Run();
						},
					});
				}}/>
				<JSONFBAConfigButton {...sharedProps}/>
				<FixFBAConfigButton {...sharedProps}/>
				<ResetFBAConfigButton {...sharedProps}/>
				<Button ml={5} text="Delete" enabled={config != null && enabled} style={{fontSize: 12, padding: "3px 7px"}} onClick={()=>{
					ShowMessageBox({
						title: "Delete FBA config?", cancelButton: true,
						message: "Permanently delete this FBA configuration?",
						onOK: ()=>{
							new DeleteFBAConfig({id: config._key!}).Run();
						},
					});
				}}/>
			</Row>
		);
	}
}

export class JSONFBAConfigButton extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {enabled, active, config_full, ChangeConfig_Full} = this.props;
		return (
			<Button ml={5} text="JSON" enabled={config_full != null && !active} style={{fontSize: 12, padding: "3px 7px"}} onClick={()=>{
				//let newJSON = ToJSON_WithSpaces(config);
				const configCopy = SortKeysInObjectTree(config_full);
				let newJSON = ToJSON(configCopy, undefined, 4);

				const boxController = ShowMessageBox({
					title: `${enabled ? "Edit" : "View"} engine config json`, cancelButton: true,
					message: ()=>(
						//<Column style={DialogStyle({width: 800})}>
						<Column>
							<Row>Config:</Row>
							<TextArea enabled={enabled} style={{width: 800..KeepAtMost(window.innerWidth - 100), height: window.innerHeight - 200}} value={newJSON} onChange={val=>{
								newJSON = val;
								boxController.UpdateUI();
							}}/>
						</Column>
					),
					okButtonProps: {enabled},
					okOnEnterKey: false, // enter key needed for adding new-lines!
					onOK: ()=>{
						g.oldFBAConfigs = (g.oldFBAConfigs || []).concat(config_full);
						const newJSONObj = FromJSON(newJSON);
						/*ChangeConfig(c=>{
							c.VKeys().forEach(key=>Reflect.deleteProperty(c, key));
							//ProcessDBData(newJSONObj, false, true, null); // add back the helpers (eg. entry._key) // actually, not needed since only root config._key is used
							newJSONObj.VKeys().forEach(key=>{
								c[key] = newJSONObj[key];
							});
						});*/
						new UpdateFBAConfig({id: config_full._key!, newData: newJSONObj}).Run();
					},
				});
			}}/>
		);
	}
}
export class FixFBAConfigButton extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {enabled, config_full, ChangeConfig_Full} = this.props;
		return (
			<Button ml={5} text="Fix" enabled={config_full != null && enabled} style={{fontSize: 12, padding: "3px 7px"}} onClick={()=>{
				//const keysToRemove = config.VKeys(true).filter(key=>GetSchemaJSON("FBAConfig").properties[key] == null);
				let keysToRemove = GetInvalidPropPaths(Clone(config_full), GetSchemaJSON("FBAConfig")).map(a=>a.propPath);

				// fix that for "root keys", the function above includes an extra "/" at the start (confusing our DeepSet calls below)
				keysToRemove = keysToRemove.map(key=>key.startsWith("/") ? key.slice(1) : key);
				// todo: move this fix into the GetInvalidPropPaths func

				ShowMessageBox({
					title: "Fix config?", cancelButton: true,
					message: ()=><Text sel style={DialogStyle({whiteSpace: "pre-wrap"})}>{`
						Remove any invalid properties in the configuration?

						Keys to remove:${keysToRemove.length == 0 ? " (none)" : keysToRemove.map(key=>`\n* ${key}: ${DeepGet(config_full, key)}`).join("")}
					`.AsMultiline(0)}</Text>,
					onOK: ()=>{
						g.oldFBAConfigs = (g.oldFBAConfigs || []).concat(config_full);
						const oldConfig = config_full;

						// we can't use ChangeConfig_Full here, because we want to set skipValidateKeysBeingUpdated to true on the command, before calling Run()
						const newConfig = Clone(oldConfig) as typeof oldConfig;
						for (const key of keysToRemove) {
							//delete c[key];
							DeepSet(newConfig, key, undefined, undefined, undefined, true);
						}
						//const updates = GetUpdates(oldConfig, newConfig, true, false);
						const command = new UpdateFBAConfig({id: oldConfig._key!, newData: newConfig});
						command.skipValidateKeysBeingUpdated = true;
						command.Run();
					},
				});
			}}/>
		);
	}
}
export class ResetFBAConfigButton extends BaseComponent<{} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {enabled, config_full, ChangeConfig_Full, client} = this.props;
		return (
			<Button ml={5} text="Reset" enabled={config_full != null && enabled} style={{fontSize: 12, padding: "3px 7px"}} onClick={()=>{
				//const keysToReset = GetInvalidPropPaths(WithoutHelpers(config), GetSchemaJSON("FBAConfig")).map(a=>a.propPath);
				const defaultConfig = client ? FBAConfig_GetDefault_Client() : FBAConfig_GetDefault();
				//const keysToReset = defaultConfig.VKeys().map(key=>`\n* ${key}: ${defaultConfig[key]}`).join("");

				let pathsToReset = [] as string[];
				const controller = ShowMessageBox({
					title: "Reset config?", cancelButton: true, okOnEnterKey: false,
					message: ()=><Column style={DialogStyle({})}>
						<Text>Enter the data-paths within the config, to reset to default values: (one line per path) [to reset all: ALL]</Text>
						<TextArea autoSize={true} value={pathsToReset.join("\n")} onChange={val=>{
							pathsToReset = val.split("\n");
							controller.UpdateUI();
						}}/>
					</Column>,
					onOK: ()=>{
						g.oldFBAConfigs = (g.oldFBAConfigs || []).concat(config_full);
						ChangeConfig_Full(c=>{
							if (pathsToReset.length == 1 && pathsToReset[0] == "ALL") {
								c.VSet(defaultConfig);
							} else {
								for (const path of pathsToReset) {
									const defaultVal = DeepGet(defaultConfig, path);
									DeepSet(c, path, defaultVal);
								}
							}
						});
					},
				});
			}}/>
		);
	}
}