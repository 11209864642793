import {AddSchema, GetDoc, GetDocs, QueryOp, StoreAccessor, WhereOp} from "mobx-firelink";

export const GetStoryMessage = StoreAccessor(s=>(id: string|n): StoryMessage|n=>{
	return GetDoc({}, a=>a.storyMessages.get(id!));
});
export const GetStoryMessages = StoreAccessor(s=>(userId: string, storyId: string, parentId?: string, orderByCreatedAt = true): StoryMessage[]=>{
	let result = GetDocs({
		queryOps: [
			new WhereOp("creator", "==", userId),
			new WhereOp("story", "==", storyId),
			parentId != null && new WhereOp("parent", "==", parentId),
		].filter(a=>a) as QueryOp[],
	}, a=>a.storyMessages);
	if (orderByCreatedAt) result = result.OrderBy(a=>a.createdAt);
	return result;
});

// proto data (ie. data returned by llm + attached-info needed for the processing immediately after [ie. before an entry is pushed to db])
export class StoryMessageProto {
	constructor(initialData: Partial<StoryMessageProto>) { Object.assign(this, initialData); }

	// core data (ie. data that llm produces/expects)
	role: "user" | "assistant";
	content: string;

	partial?: boolean;
	isSummary?: boolean;
	uploadedId?: string; // id of StoryMessage based on this StoryMessageProto, which has been uploaded to db
}

export class StoryMessage {
	constructor(initialData: Partial<StoryMessage>) { Object.assign(this, initialData); }

	// proto data (see StoryMessageProto class)
	role: "user" | "assistant";
	content: string;

	_key: string;
	creator: string;
	createdAt: number;

	story: string;
	parent?: string|n;

	summary_chainEnd?: string|n;
	summary_enabled?: boolean|n;
}
AddSchema("StoryMessage", {
	properties: {
		role: {type: "string"},
		content: {type: "string"},

		creator: {type: "string"},
		createdAt: {type: "number"},

		story: {type: "string"},
		parent: {type: ["null", "string"]},

		summary_chainEnd: {type: ["null", "string"]},
		summary_enabled: {type: ["null", "boolean"]},
	},
	//required: [...],
});

export function FindMessagesInChain(messages: StoryMessage[], finalMessageInChain: StoryMessage, allowSummaries = true, terminateAtMessageX = null as ((msg: StoryMessage)=>boolean)|n, mustFindTerminateMessage = true) {
	const chain = [finalMessageInChain];
	let currentMessage = finalMessageInChain;
	while (currentMessage.parent && !terminateAtMessageX?.(currentMessage)) {
		const parentMessage_summaryIntercept = allowSummaries && currentMessage.parent != null
			// use findLast, so that if two summaries exist for the same chain-end, we prefer the newer one
			? messages.findLast(a=>a.summary_chainEnd == currentMessage.parent && a.summary_enabled)
			: null;
		const parentMessage_normal = messages.find(a=>a._key == currentMessage.parent);
		const parentMessage = parentMessage_summaryIntercept ?? parentMessage_normal;
		if (parentMessage == null) break;

		chain.Insert(0, parentMessage);
		currentMessage = parentMessage;
	}
	if (terminateAtMessageX != null && mustFindTerminateMessage && !terminateAtMessageX(chain[0])) {
		throw new Error("Terminate-at message not found in chain.");
	}
	return chain;

}