import {UserEdit} from "../../Server/CommandMacros";
import {AssertValidate, GenerateUUID, Command, AssertV} from "mobx-firelink";
import {Sound} from "../../Store/firebase/sounds/@Sound";

@UserEdit
export class AddSound extends Command<{sound: Sound}, string> {
	id: string;
	Validate() {
		const {sound} = this.payload;

		this.id = GenerateUUID();
		AssertV(this.userInfo != null, "User not signed in.");
		sound.creator = this.userInfo!.id;
		sound.createdAt = Date.now();

		this.returnData = this.id;
		AssertValidate("Sound", sound, `Sound invalid`);
	}

	GetDBUpdates() {
		const {sound} = this.payload;

		const updates = {};
		updates[`sounds/${this.id}`] = sound;
		return updates;
	}
}