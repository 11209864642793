import {CachedTransform, emptyArray} from "js-vextensions";
import {JournalEntry, JournalSegment} from "../../Store/firebase/journalEntries/@JournalEntry";
import {StoreAccessor, GetDoc, GetDocs, WhereOp} from "mobx-firelink";
import {MeID} from "./users.js";
import {DreamQuiz_QuestionType} from "./fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {GetLiveFBASession_Reactive, GetLiveLocalSession_Reactive} from "../../Engine/FBASession.js";

export const GetJournalEntry = StoreAccessor(s=>(id: string|n): JournalEntry|n=>{
	return GetDoc({}, a=>a.journalEntries.get(id!));
});
export const GetJournalEntries = StoreAccessor(s=>(userID: string|n, orderByStartTime = "asc" as null|"asc"|"desc"): JournalEntry[]=>{
	if (userID == null) return emptyArray; // if we aren't signed-in, don't even try to make request (the security-rules will make it fail)
	let result = GetDocs({
		queryOps: [new WhereOp("creator", "==", userID)],
	}, a=>a.journalEntries);
	if (orderByStartTime == "asc") result = result.OrderBy(a=>EstimateDreamStartTime(a));
	else if (orderByStartTime == "desc") result = result.OrderByDescending(a=>EstimateDreamStartTime(a));
	return result;
});

export function EstimateDreamStartTime(dream: JournalEntry): number {
	if (dream.sleepTime) return dream.sleepTime;

	const secondaryTimings = [
		...dream.segments.map(a=>a.wakeTime),
		dream.wakeTime,
	].filter(a=>a != null);
	if (secondaryTimings.length) {
		return secondaryTimings.OrderBy(a=>a)[0]!;
	}
	
	return dream.createdAt;
}
export function EstimateDreamEndTime(dream: JournalEntry): number|n {
	return dream.wakeTime ?? dream.segments.map(a=>a.wakeTime).OrderBy(a=>a).LastOrX();
}

export function EstimateSegmentStartTime(dream: JournalEntry, segment: JournalSegment): number {
	const segmentsAfter = dream.segments.slice(dream.segments.indexOf(segment) + 1);
	const firstWakeAfter = segmentsAfter.find(a=>a.wakeTime != null);
	if (firstWakeAfter) return firstWakeAfter.wakeTime!;

	const segmentsBefore = dream.segments.slice(0, dream.segments.indexOf(segment));
	const lastWakeBefore = segmentsBefore.LastOrX(a=>a.wakeTime != null);
	if (lastWakeBefore) return lastWakeBefore.wakeTime!;

	return EstimateDreamStartTime(dream);
}
export function EstimateSegmentEndTime(dream: JournalEntry, segment: JournalSegment): number|n {
	const segmentsAfter = dream.segments.slice(dream.segments.indexOf(segment) + 1);
	const firstWakeAfter = segmentsAfter.find(a=>a.wakeTime != null);
	if (firstWakeAfter) return firstWakeAfter.wakeTime!;

	return EstimateDreamEndTime(dream);
}


export const GetDreamEvents = StoreAccessor(s=>(segment: JournalSegment, eventMaxWords = Number.MAX_SAFE_INTEGER)=>{
	const result = [] as string[];
	const lines = segment.shortText.split("\n");
	for (const line of lines) {
		if (!line.includes(";")) continue;
		const eventCandidates = line.split(";").map(a=>a.trim()).filter(a=>a);
		for (const eventCandidate of eventCandidates) {
			if (eventCandidate.split(" ").length > eventMaxWords) continue;
			result.push(eventCandidate);
		}
	}
	return result;
});
export const GetSegmentToDreamMap = StoreAccessor(s=>(userID: string|n)=>{
	const journalEntries = GetJournalEntries(userID);
	const result = new Map<JournalSegment, JournalEntry>();
	for (const journalEntry of journalEntries) {
		for (const segment of journalEntry.segments) {
			result.set(segment, journalEntry);
		}
	}
	return result;
});
export const GetValidQuizSegments = StoreAccessor(s=>(lucidSegmentsOnly: boolean, segmentMinEvents: number, eventMaxWords: number, questionType: DreamQuiz_QuestionType)=>{
	const journalEntries = GetJournalEntries(MeID());
	return journalEntries.SelectMany(a=>a.segments).filter(a=>{
		if (a.excludeFromIntegration) return false;
		if (lucidSegmentsOnly && !a.lucid) return false;
		const events = GetDreamEvents(a, eventMaxWords);
		if (events.length < segmentMinEvents) return false;
		if (questionType == "comesAfter" && !a.ordered) return false;
		return true;
	});
});

export function SortJournalEntries(entries: JournalEntry[], filterOutTimeless: boolean) {
	let result = entries;
	if (filterOutTimeless) {
		// only include entries that have some type of timestamp (else UI can get confusing, eg. a year-old entry with no timestamp showing)
		result = result.filter(a=>a.sleepTime != null || a.wakeTime != null /*|| a.createdAt != null*/);
	}
	return result.OrderBy(a=>a.sleepTime ?? a.wakeTime /*?? a.createdAt*/ ?? Number.MAX_VALUE);
}

// we have caller pass in the "now" moment, so that it can maintain some stability (ie. the "now" as of last comp render)
export const Journey_GetJournalEntriesToShow = StoreAccessor(s=>()=>{
	const uiState = s.main.tools.journey;
	const liveJourneySession = GetLiveLocalSession_Reactive();
	
	const journalEntries_raw = GetJournalEntries(MeID());
	const journalEntries_sorted = SortJournalEntries(journalEntries_raw, true);
	const lastJournalEntry = journalEntries_sorted.LastOrX();

	let journalEntryForSession: JournalEntry|n;
	/*journalEntryForSession = liveJourneySession == null ? null : journalEntries_sorted.findLast(a=>{
		if (a == null) return false;
		const sleepTimeInRange = a.sleepTime != null && a.sleepTime >= liveJourneySession.startTime && (a.sleepTime <= liveJourneySession.endTime || liveJourneySession.endTime == null);
		const wakeTimeInRange = a.wakeTime != null && a.wakeTime >= liveJourneySession.startTime && (a.wakeTime <= liveJourneySession.endTime || liveJourneySession.endTime == null);
		return sleepTimeInRange || wakeTimeInRange;
	});*/
	if (liveJourneySession && lastJournalEntry?.wakeTime == null) {
		journalEntryForSession = lastJournalEntry;
	}

	const journalEntriesToShow = journalEntries_sorted.TakeLast(uiState.pastJournalEntriesToShow + (journalEntryForSession ? 1 : 0));

	return {lastJournalEntry, journalEntryForSession, journalEntriesToShow};
});