import {Switch} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {store} from "../Store/index.js";
import {Me, MeID} from "../Store/firebase/users";
import {Observer, SubNavBar_Auto} from "web-vcore";
import {HasModPermissions} from "../Store/firebase/users/$user";
import {EngineUI} from "./Tools/Engine";
import {MonitorUI} from "./Tools/Monitor";
import {TrainingUI} from "./Tools/Training";
import React from "react";
import {JourneyUI} from "./Tools/Journey.js";
import {PestUI} from "./Tools/Pest.js";

export const columnWidths = [.64, .06, .12, .18];

@Observer
export class ToolsUI extends BaseComponent<{}, {}> {
	render() {
		const currentSubpage = store.main.tools.subpage;
		const page = "tools";
		//const subpages = pageTree.children[page].children.VValues();

		return (
			//<Column style={ES({flex: 1, background: "rgba(0,0,0,.8)"})}>
			<>
				<SubNavBar_Auto page={page} filter={a=>HasModPermissions(MeID()) || a.key != "training"}/>
				<Switch>
					{/*subpages.map((subpage, index)=>{
						if (index != 0 && currentSubpage != subpage.key) return null;
						return subpage.component;
					})*/}
					<EngineUI/>
					{currentSubpage == "monitor" && <MonitorUI/>}
					{currentSubpage == "training" && <TrainingUI/>}
					{currentSubpage == "pest" && <PestUI/>}
					{currentSubpage == "journey" && <JourneyUI/>}
				</Switch>
			</>
		);
	}
}