import {BinarySearch} from "../../../Utils/General/General";
import {FBAConfig_Gyro} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Gyro";
import {Assert} from "js-vextensions";
import {GyroSample, GyroSample_interval} from "../../../UI/Tools/@Shared/MuseInterface/GyroStructs";
import {FinalizeSamplesForSecond, UpdateSamplesBySecondMap} from "../../../UI/Tools/@Shared/MuseInterface/SampleHelpers";
import moment from "moment";
import {GyroProcessor} from "./GyroProcessor";
import {SessionDataProcessor} from "./SessionDataProcessor";

// this processing is extracted out, so it can be used both by GyroComp (for live data), and by SessionDataProcessor (for saved data)
export class GyroMotionProcessor {
	constructor(opt: Partial<GyroMotionProcessor>) {
		this.VSet(opt);
	}
	parent: SessionDataProcessor;
	gyroProcessor: GyroProcessor;

	postRespondToNewSample: (sample: GyroSample, index: number, motionTriggering: boolean)=>void;
	postSamplesBySecondEntryCompleted: (secondStartTime: number, secondSamples: GyroSample[])=>void;
	postMotionTrigger: (sampleTime: number)=>void;

	get c(): FBAConfig_Gyro {
		return this.gyroProcessor.options;
	}

	motionTriggerTimes = [] as number[];
	HasMotionTriggerWithinPeriod(/** inclusive */ periodStart: number, /** inclusive */ periodEnd: number) { // called by EEGActivityProcessor
		const {indexOfMatch} = BinarySearch(this.motionTriggerTimes, triggerTime=>{
			if (triggerTime > periodEnd) return -1; // this trigger is too late; check to the left
			if (triggerTime < periodStart) return 1; // this trigger is too early; check to the right
			return 0; // we found a matching trigger
		}, {firstCheckIndex: this.motionTriggerTimes.length - 1});
		return indexOfMatch != null;
	}

	RespondToSample(index: number) {
		const sample = this.gyroProcessor.samples[index];
		if (DEV) Assert(sample.time == sample.time.RoundTo(GyroSample_interval), "Sample does not align to the global interval!");

		let motionTriggering = false;
		if (this.c.detectMotion) {
			const triggerSamplePercent_fraction = this.gyroProcessor.samples[index].triggerSamplePercent / 100; // sample's prop is as percentage, for display purposes
			motionTriggering = triggerSamplePercent_fraction >= this.c.motion_motionTrigger_minTriggerSamplePercent;
			if (motionTriggering) {
				//console.log(`MotionTriggering. @time:${moment().format("HH:mm:ss")}`);
				const timeSinceLastMotionTrigger = sample.time - this.motionTriggerTimes.LastOrX(undefined, 0)!;
				// if required wait between motion-triggers has been fulfilled, activate motion-trigger
				if (timeSinceLastMotionTrigger >= this.c.motion_motionTrigger_maxTriggerRate * 1000) {
					this.NotifyMotionTrigger(sample.time);
				}
			}
		}

		// collect gyro-samples for each second, and record each second's samples to disk once it's over
		UpdateSamplesBySecondMap(this.samplesBySecond, sample, (secondStartTime, secondSamples)=>{
			secondSamples = FinalizeSamplesForSecond(secondStartTime, secondSamples, GyroSample_interval);
			this.postSamplesBySecondEntryCompleted?.(secondStartTime, secondSamples);
		});

		this.postRespondToNewSample?.(sample, index, motionTriggering);
	}
	samplesBySecond = new Map<number, GyroSample[]>();

	NotifyMotionTrigger(sampleTime: number) {
		this.motionTriggerTimes.push(sampleTime);
		this.postMotionTrigger?.(sampleTime);
	}
}