import {Assert, Clone, CloneWithPrototypes, E} from "js-vextensions";
import {Button, ButtonProps, Column, Row, Text, TextInput} from "react-vcomponents";
import {ApplyBasicStyles, BaseComponent, BaseComponentPlus, BasicStyles} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {AddLight} from "../../Server/Commands/AddLight";
import {AddShake} from "../../Server/Commands/AddShake";
import {AddSound} from "../../Server/Commands/AddSound";
import {DeleteLight} from "../../Server/Commands/DeleteLight";
import {DeleteShake} from "../../Server/Commands/DeleteShake";
import {DeleteSound} from "../../Server/Commands/DeleteSound";
import {EffectGroup, EffectGroup_values, SetUserEntityTags, TagTargetGroup} from "../../Server/Commands/SetUserEntityTags";
import {UpdateLight} from "../../Server/Commands/UpdateLight";
import {UpdateShake} from "../../Server/Commands/UpdateShake";
import {UpdateSound} from "../../Server/Commands/UpdateSound";
import {Light, LightType} from "../../Store/firebase/lights/@Light";
import {Shake, ShakeType} from "../../Store/firebase/shakes/@Shake";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";
import {LightPlayer} from "../../Utils/EffectPlayers/LightPlayer";
import {ShakePlayer} from "../../Utils/EffectPlayers/ShakePlayer";
import {SoundPlayer} from "../../Utils/EffectPlayers/SoundPlayer";
import {ES} from "../../Utils/UI/GlobalStyles";
import {GetUpdates, HSLA, Observer} from "web-vcore";
import {ScriptPlayer} from "../../Utils/EffectPlayers/ScriptPlayer";
import {Script, ScriptType} from "../../Store/firebase/scripts/@Script";
import {ShowSignInPopup} from "./NavBar/UserPanel";
import {Sound, SoundType} from "../../Store/firebase/sounds/@Sound";
import {GetUser, GetUserEntityTagCounts, MeID, GetUserEntityTags} from "../../Store/firebase/users";
import {LightDetailsUI} from "../Effects/Lights/LightDetailsUI";
import {ShakeDetailsUI} from "../Effects/Shakes/ShakeDetailsUI";
import {SoundDetailsUI} from "../Effects/Sounds/SoundDetailsUI";
import {ScriptDetailsUI} from "../Effects/Scripts/ScriptDetailsUI";
import {Scene} from "../../Store/firebase/scenes/@Scene.js";
import {Entity} from "../../Store/firebase/entities/@Entity.js";
import React from "react";
import {SceneDetailsUI} from "../Content/Scenes/SceneDetailsUI.js";
import {AddScene} from "../../Server/Commands/AddScene.js";
import {AddEntity} from "../../Server/Commands/AddEntity.js";
import {DeleteScene} from "../../Server/Commands/DeleteScene.js";
import {DeleteEntity} from "../../Server/Commands/DeleteEntity.js";
import {EntityDetailsUI} from "../Content/Entities/EntityDetailsUI.js";
import {UpdateEntity} from "../../Server/Commands/UpdateEntity.js";
import {UpdateScene} from "../../Server/Commands/UpdateScene.js";
import {PreviewImageSquare} from "./PreviewImageSquare.js";
import {GetSound, GetSounds_WithUserTag} from "../../Store/firebase/sounds.js";
import {GetShakes_WithUserTag} from "../../Store/firebase/shakes.js";
import {GetLights_WithUserTag} from "../../Store/firebase/lights.js";
import {GetScripts_WithUserTag} from "../../Store/firebase/scripts.js";

export const columnWidths = [.25, .1, .1, .3, .25];
export type EffectEntry = Sound|Shake|Light|Script;
export type ContentEntry = Scene|Entity;
export type TagTargetEntry = EffectEntry|ContentEntry;

@Observer
export class TagTargetEntryUI extends BaseComponent<{group: TagTargetGroup, entry: TagTargetEntry, index: number, last: boolean}, {editMode: boolean, newData: TagTargetEntry|n, error: string|n}> {
	static initialState = {editMode: false};
	render() {
		const {index, last, entry, group} = this.props;
		const creator = entry && GetUser(entry.creator);
		const userTagCounts = GetUserEntityTagCounts(group, entry._key);
		const userTagCounts_final = Clone(userTagCounts);
		for (const suggestedTag of entry.tags ?? []) {
			if (userTagCounts_final[suggestedTag] == null) {
				userTagCounts_final[suggestedTag] = 0;
			}
		}
		const ownTags = GetUserEntityTags(MeID(), group, entry._key);
		const {editMode, newData, error} = this.state;
		const canEdit = IsUserCreatorOrMod(MeID(), entry);

		const isEffect = EffectGroup_values.includes(group as any);
		const EffectTypeEnum = isEffect ? {sounds: SoundType, shakes: ShakeType, lights: LightType, scripts: ScriptType}[group] : null;
		return (
			<Column p="5px 10px" style={ES(
				{background: index % 2 == 0 ? "rgba(30,30,30,.7)" : "rgba(0,0,0,.7)"},
				last && {borderRadius: "0 0 10px 10px"},
			)}>
				{!editMode &&
					<Row center>
						<span style={{flex: columnWidths[0], wordBreak: "break-word"}}>{entry.name}</span>
						{isEffect && <span style={{flex: columnWidths[1], wordBreak: "break-word"}}>{EffectTypeEnum[entry["_type"] ?? entry["type"]]}</span>}
						{!isEffect &&
						<Row style={{flex: columnWidths[1]}}>
							{/*<img src={entry.url} style={{maxWidth: 100 /*height: 100*#/}}/>*/}
							<PreviewImageSquare entry={entry as Scene|Entity} allowExtThumbnailing={group != "entities"}/>
						</Row>}
						<span style={{flex: columnWidths[2], wordBreak: "break-word"}}>{creator ? creator.displayName : "..."}</span>
						<Row center style={{flex: columnWidths[3] + columnWidths[4], wordBreak: "break-word"}}>
							<span style={{flex: 1, marginRight: 5}}>
								{userTagCounts_final.Pairs().OrderByDescending(a=>a.value).map((tag, tagIndex)=>{
									return (
										<Text key={tagIndex} ml={tagIndex == 0 ? 0 : 5} p="0 5px 3px"
												style={E(
													{display: "inline-block", background: HSLA(0, 0, 1, .3), borderRadius: 5, cursor: "pointer"},
													ownTags.includes(tag.key) && {background: "rgba(100,200,100,.5)"},
												)}
												onClick={()=>{
													if (MeID() == null) return ShowSignInPopup();
													const newOwnTags = ownTags.includes(tag.key) ? ownTags.Exclude(tag.key) : ownTags.concat(tag.key);
													new SetUserEntityTags({entityGroup: group, entityID: entry._key, entityTags: newOwnTags}).Run();
												}}>
											{tag.key}<sup>{tag.value}</sup>
										</Text>
									);
								})}
								<Button ml={5} text="+" p="5px 10px" onClick={()=>{
									if (MeID() == null) return ShowSignInPopup();
									let newTagName = "";
									const boxController = ShowMessageBox({
										title: "Enter new tag name",
										cancelButton: true,
										message: ()=>{
											return (
												<Row style={{display: "inline-flex"}}>
													<Text>Tag name:</Text>
													<TextInput ml={5} value={newTagName} onChange={val=>{ newTagName = val; boxController.UpdateUI(); }}/>
												</Row>
											);
										},
										onOK: ()=>{
											if (ownTags.includes(newTagName)) return;
											const newOwnTags = ownTags.concat(newTagName);
											new SetUserEntityTags({entityGroup: group, entityID: entry._key, entityTags: newOwnTags}).Run();
										},
									});
								}}/>
							</span>
							{EffectGroup_values.includes(group as any) && <EffectPreviewButton ml="auto" group={group as EffectGroup} entry={entry as EffectEntry}/>}
							<Button ml={5} faIcon={canEdit ? "edit" : "eye"} title={canEdit ? "Edit" : "View"} enabled={group != "scripts"} onClick={()=>{
								this.SetState({editMode: true, newData: CloneWithPrototypes(entry)});
							}}/>
							<Button ml={5} faIcon="clone" title="Clone" enabled={group != "scripts"} onClick={()=>{
								if (MeID() == null) return ShowSignInPopup();
								ShowMessageBox({
									title: "Clone entry?",
									cancelButton: true,
									message: `
										Entry to clone: ${entry.name}
										
										After cloning, scroll to the top of the list to see the new entry.
									`.AsMultiline(0),
									onOK: ()=>{
										const newEntry = Clone(entry);
										newEntry.name += " (clone)";
										if (group == "sounds") new AddSound({sound: newEntry}).Run();
										else if (group == "shakes") new AddShake({shake: newEntry}).Run();
										else if (group == "lights") new AddLight({light: newEntry}).Run();
										//else if (group == "scripts") new AddScript({script: newEntry}).Run();
										else if (group == "scenes") new AddScene({scene: newEntry}).Run();
										else if (group == "entities") new AddEntity({entity: newEntry}).Run();
									},
								});
							}}/>
							<Button ml={5} faIcon="trash" title="Delete" enabled={canEdit && group != "scripts"} onClick={()=>{
								ShowMessageBox({
									title: "Delete entry?", cancelButton: true,
									message: "Permanently delete this entry?",
									onOK: async()=>{
										if (group == "sounds") new DeleteSound({id: entry._key}).Run();
										else if (group == "shakes") new DeleteShake({id: entry._key}).Run();
										else if (group == "lights") new DeleteLight({id: entry._key}).Run();
										//else if (group == "scripts") new DeleteScript({id: entry._key}).Run();
										else if (group == "scenes") new DeleteScene({id: entry._key}).Run();
										else if (group == "entities") new DeleteEntity({id: entry._key}).Run();
									},
								});
							}}/>
						</Row>
					</Row>}
				{editMode &&
					<Column style={{padding: "10px 0", width: "100%"}}>
						{group == "sounds" && <SoundDetailsUI baseData={newData as Sound} forNew={false} enabled={canEdit} onChange={(val, ui)=>this.SetState({newData: val, error: ui.GetValidationError()})}/>}
						{group == "shakes" && <ShakeDetailsUI baseData={newData as Shake} forNew={false} enabled={canEdit} onChange={(val, ui)=>this.SetState({newData: val, error: ui.GetValidationError()})}/>}
						{group == "lights" && <LightDetailsUI baseData={newData as Light} forNew={false} enabled={canEdit} onChange={(val, ui)=>this.SetState({newData: val, error: ui.GetValidationError()})}/>}
						{group == "scripts" && <ScriptDetailsUI baseData={newData as Script} forNew={false} enabled={canEdit} onChange={(val, ui)=>this.SetState({newData: val, error: ui.GetValidationError()})}/>}
						{group == "scenes" && <SceneDetailsUI baseData={newData as Scene} forNew={false} enabled={canEdit} onChange={(val, ui)=>this.SetState({newData: val, error: ui.GetValidationError()})}/>}
						{group == "entities" && <EntityDetailsUI baseData={newData as Entity} forNew={false} enabled={canEdit} onChange={(val, ui)=>this.SetState({newData: val, error: ui.GetValidationError()})}/>}
						{error && error != "Please fill out this field." && <Row mt={5} style={{color: "rgba(200,70,70,1)"}}>{error}</Row>}
						<Row mt={5}>
							{canEdit &&
							<Button text="OK" enabled={GetUpdates(entry, newData).VKeys().length > 0} onClick={async()=>{
								const updates = GetUpdates(entry, newData);
								if (group == "sounds") await new UpdateSound({id: entry._key, updates}).Run();
								else if (group == "shakes") await new UpdateShake({id: entry._key, updates}).Run();
								else if (group == "lights") await new UpdateLight({id: entry._key, updates}).Run();
								//else if (group == "scripts") await new UpdateScript({id: entry._key, updates}).Run();
								else if (group == "scenes") await new UpdateScene({id: entry._key, updates}).Run();
								else if (group == "entities") await new UpdateEntity({id: entry._key, updates}).Run();
								this.SetState({editMode: false, newData: null, error: null});
							}}/>}
							<Button ml={canEdit ? 5 : 0} text={canEdit ? "Cancel" : "Close"} onClick={()=>{
								this.SetState({editMode: false, newData: null, error: null});
							}}/>
						</Row>
					</Column>}
			</Column>
		);
	}
}

export function GetEffectsByTag(group: EffectGroup, tag: string|n): EffectEntry[] {
	if (group == "sounds") return GetSounds_WithUserTag(tag);
	if (group == "shakes") return GetShakes_WithUserTag(tag);
	if (group == "lights") return GetLights_WithUserTag(tag);
	if (group == "scripts") return GetScripts_WithUserTag(tag);
	Assert(false, `Invalid effects group "${group}".`);
}
export class EffectPreviewButton_AutoGetEntry extends BaseComponent<{group: EffectGroup, entryTag: string|n, startText?: string, stopText?: string} & Partial<ButtonProps>, {playing: boolean}> {
	render() {
		const {group, entryTag, ...rest} = this.props;
		const entry = GetEffectsByTag(group, entryTag).Random();
		return (
			<EffectPreviewButton {...rest} group={group} entry={entry}/>
		);
	}
}

export class EffectPreviewButton extends BaseComponent<{group: EffectGroup, entry: EffectEntry|n, startText?: string, stopText?: string} & Partial<ButtonProps>, {playing: boolean}> {
	player: SoundPlayer|ShakePlayer|LightPlayer|ScriptPlayer;
	render() {
		const {group, entry, startText, stopText, style, ...rest} = this.props;
		const {playing} = this.state;

		const styleProps = startText || stopText
			? {text: playing ? stopText : startText}
			: {size: 28, iconSize: 14, iconPath: `/Images/Buttons/${playing ? "Square" : "Right"}.png`};

		return (
			<Button {...styleProps} {...rest as any}
				style={E(
					styleProps.size != null && {
						// fsr, the width/height css props are ignored in some cases; so ensure button always has its target size
						minWidth: styleProps.size, minHeight: styleProps.size,
					},
					style,
				)}
				onClick={async()=>{
					if (this.player == null) {
						if (group == "sounds") this.player = new SoundPlayer();
						else if (group == "shakes") this.player = new ShakePlayer();
						else if (group == "lights") this.player = new LightPlayer();
						else if (group == "scripts") this.player = new ScriptPlayer();
					}
					if (playing) {
						//if (this.player.Stop()) {
						this.player.Stop();
						this.SetState({playing: false});
					} else {
						this.SetState({playing: true});
						this.player[group.slice(0, -1)] = entry;

						await this.player.Play(1, {allowReapply: false});
						/*const promise = this.player.Play(1);
						const previewPersistenceDesired = this.player instanceof LightPlayer && !this.player.light.temp;
						if (!previewPersistenceDesired) await promise;*/

						if (this.player instanceof SoundPlayer) {
							this.player.DisownInternalPlayer(); // disown internal yt-player right after sound completes, so we don't keep enlarging the yt-player pool
						}
						this.SetState({playing: false});
					}
				}}/>
		);
	}

	ComponentWillUnmount() {
		if (this.player == null) return;
		//if (this.player && this.player.playing) this.player.Stop();
		// "preview" persistence is likely desired for non-temp lights, since LF app is the only good on-pc light-controller for many users (eg. me)
		const previewPersistenceLikelyDesired = this.player instanceof LightPlayer && !this.player.light!.temp;
		if (!previewPersistenceLikelyDesired) {
			this.player.Stop();
		}
	}
}