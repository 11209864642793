import {Assert, Lerp, Timer} from "js-vextensions";
import {secondInMS} from "web-vcore";
import {AlarmConfigBase, AlarmSequence, AlarmsGroup} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {LogType} from "../../../../UI/Tools/@Shared/LogEntry.js";
import {FBASession} from "../../../FBASession";
import {AlarmsComp, AlarmsPhase} from "../AlarmsComp.js";
import {EngineSessionComp} from "../EngineSessionComp";
import {SessionLog} from "../../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {FBAConfig_XAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Alarms.js";
import {GetSettingValueFromEngineSessionInfo} from "../../../../Store/firebase/sessions/@EngineSessionInfo.js";

/** This is returned by some base-class methods, as a typescript trick to ensure derived-classes call those base methods. */
const AlarmComp_ProofBaseMethodCalled = Symbol("AlarmComp_ReceiptOfBaseMethodBeingCalled");

/** Use this key to store the interval-calc function of alarm auto-restart timers, on itself.
 * When interval-mod is changed by JBannerComp, it iterates timerContext.timers and updates relevant intervals using it. */
export const KEY_alarmRestartTimer_getInterval = "alarmRestartTimer_getInterval";

export abstract class AlarmComp<T extends AlarmConfigBase> extends EngineSessionComp<T> {
	constructor(session: FBASession, sequence: AlarmSequence, config: T) {
		const getGroup = (session: FBASession)=>session.c.eeg.alarmSequence.alarms.includes(config) ? "eeg" : "alarms";
		super(session, config, s=>s.c[getGroup(s)].enabled && config.enabled, s=>s.IsLocal());
		this.group = getGroup(session);
		this.sequence = sequence;
		this.indexInSequence = sequence.alarms.indexOf(config);
		Assert(this.indexInSequence != -1, `AlarmComp created with config not found in sequence.`); // defensive

		this.offsetWaitTimer = new Timer(this.c.startOffset * secondInMS, ()=>{
			SessionLog(`Offset wait @${this.constructor.name}`);
			this.StartAlarm();
		}, 1).SetContext(this.s.timerContext);
		this.autoEndTimer = new Timer(this.c.autoEndAfter > 0 ? this.c.autoEndAfter * secondInMS : 1_000_000_000, ()=>{
			SessionLog(`Auto-end @${this.constructor.name}`);
			this.StopAlarm("autoEnd");
		}, 1).SetContext(this.s.timerContext);
		const autoRestart_getInterval = ()=>this.c.autoRestartAfter > 0
			? this.c.autoRestartAfter * secondInMS * this.s.GetSettingValue(a=>a.general.globalAlarmRestartIntervalMultiplier)
			: 1_000_000_000;
		this.autoRestartTimer = new Timer(autoRestart_getInterval(), ()=>{
			SessionLog(`Auto-restart @${this.constructor.name}`);
			this.StopAlarm("other");
			this.StartAlarm();
		}, 1).SetContext(this.s.timerContext);
		this.autoRestartTimer[KEY_alarmRestartTimer_getInterval] = autoRestart_getInterval; // see doc of KEY_... constant, for purpose
		this.effectApplyTimer = new Timer(this.c.effectInterval > 0 ? this.c.effectInterval * 1000 : 1_000_000_000, async()=>{
			this.effectApplyAttempts_sinceAlarmStart++;
			this.UpdateIntensity();
			this.UpdateEffectPlayChance();

			// for debugging only
			/*const timeSinceLastApplyAttempt = Date.now() - this.lastEffectApplyAttemptTime;
			this.lastEffectApplyAttemptTime = Date.now();
			if (this.c.effectInterval <= 0 && timeSinceLastApplyAttempt < 100) {
				console.warn("Alarm effect-interval is disabled, yet effect-apply for this comp was tried twice in quick-succession. Pausing debugger.");
				debugger;
			}*/
			
			if (Math.random() < this.effectPlayChance) {
				await this.PlayAlarmEffect();
			}

			// if next (enabled) alarm has its start-time as relative (to this alarm's end), start that relative-offset countdown now
			const nextAlarm = this.sequence.alarms.slice(this.indexInSequence + 1).find(a=>a.enabled) as FBAConfig_XAlarm|n;
			if (nextAlarm) {
				// if next (enabled) alarm has a relative start-offset, start it now
				if (nextAlarm.startOffset_relative) {
					const nextAlarmComp = this.s.components.find(a=>a instanceof AlarmComp && a.c == nextAlarm) as AlarmComp<any>;
					nextAlarmComp.offsetWaitTimer.Start();
				}
			}
		}).SetContext(this.s.timerContext);
	}
	group: AlarmsGroup;
	sequence: AlarmSequence;
	indexInSequence: number;

	offsetWaitTimer: Timer;
	autoEndTimer: Timer;
	autoRestartTimer: Timer;
	effectApplyTimer: Timer;

	//timeOfAlarmPhaseStarting = 0;
	currentAlarmPhase_firstAlarmStartTime: number|n;
	OnStartPhase_Alarm(oldPhase: AlarmsPhase): void {
		// on alarm-phase starting, reset this field; it'll get set the first time this alarm starts again (ie. within this sequence-run)
		this.currentAlarmPhase_firstAlarmStartTime = null;
	}
	/** Note: If config has "chance" at <100%, this field can be higher than actual effect apply/play count. (it just counts timer-ticks/attempts) */
	effectApplyAttempts_sinceAlarmStart = 0; // this is preferred over a "lastAlarmStartTime" field, since it also works for eg. reliable countdown messages
	/*EffectApplyCount() {
		return this.effectApplyTimer.callCount_thisRun;
	}*/
	//lastEffectApplyAttemptTime = 0; // atm, only used for debugging

	intensity = 0;
	UpdateIntensity() {
		if (this.c.fadeIn_enabled) {
			let timeSinceFadeInStartPoint: number;
			if (this.c.fadeIn_anchoredToSequence) {
				timeSinceFadeInStartPoint = this.currentAlarmPhase_firstAlarmStartTime
					? (Date.now() - this.currentAlarmPhase_firstAlarmStartTime) / 1000
					: 0; // defensive (this case currently shouldn't happen)
			} else {
				//const ticksPastFirst = this.effectApplyTimer.callCount_thisRun - 1;
				const ticksPastFirst = this.effectApplyAttempts_sinceAlarmStart - 1;
				timeSinceFadeInStartPoint = ticksPastFirst * this.c.effectInterval;
			}

			const percentThroughFadeIn = (timeSinceFadeInStartPoint / this.c.fadeIn_duration).KeepBetween(0, 1);
			let currentIntensity = Lerp_WithCurve(
				this.c.intensityStart, this.c.fadeIn_intensityEnd, percentThroughFadeIn, false, // don't clip [correct?]
				this.c.fadeIn_curve != 0 ? this.c.fadeIn_curve : null,
			);

			this.intensity = currentIntensity;
		} else {
			this.intensity = this.c.intensityStart;
		}
	}
	effectPlayChance = 0;
	UpdateEffectPlayChance() {
		if (this.c.chance_fadeIn_enabled) {
			let timeSinceFadeInStartPoint: number;
			if (this.c.chance_fadeIn_anchoredToSequence) {
				timeSinceFadeInStartPoint = this.currentAlarmPhase_firstAlarmStartTime
					? (Date.now() - this.currentAlarmPhase_firstAlarmStartTime) / 1000
					: 0; // defensive (this case currently shouldn't happen)
			} else {
				//const ticksPastFirst = this.effectApplyTimer.callCount_thisRun - 1;
				const ticksPastFirst = this.effectApplyAttempts_sinceAlarmStart - 1;
				timeSinceFadeInStartPoint = ticksPastFirst * this.c.effectInterval;
			}

			const percentThroughFadeIn = (timeSinceFadeInStartPoint / this.c.chance_fadeIn_duration).KeepBetween(0, 1);
			let currentChance = Lerp_WithCurve(
				this.c.chance_startValue, this.c.chance_fadeIn_endValue, percentThroughFadeIn, false, // don't clip [correct?]
				this.c.chance_fadeIn_curve != 0 ? this.c.chance_fadeIn_curve : null,
			);

			this.effectPlayChance = currentChance;
		} else {
			this.effectPlayChance = this.c.chance_startValue;
		}
	}

	//intensityAtLastPrompt = 0;
	//eegActivityAtLastNotify = 0;
	eegActivityAtLastPromptPlayOrUpdate = 0;
	GetIntensityForEEGActivity(eegActivity: number) {
		// there's got to be a better way to calculate this...
		/*const intensityIncreaseRange = this.c.intensityEnd - this.c.intensityStart;
		const intensityIncreasePerActivityUnit = this.c.intensityIncreasePerStep / this.c.effectInterval;
		const activityIncreaseToReachFullIntensity = intensityIncreaseRange / intensityIncreasePerActivityUnit;
		const percentThroughActivityRange = GetPercentFromXToY(this.c.startOffset, this.c.startOffset + activityIncreaseToReachFullIntensity, eegActivity);
		const newIntensity = Lerp(this.c.intensityStart, this.c.intensityEnd, percentThroughActivityRange);
		return newIntensity;*/
		// needs rework; for now hard-code to full-intensity
		return this.c.fadeIn_intensityEnd;
	}
	NotifyEEGActivity(eegActivity: number) {
		const activityChangeSinceLastPromptPlayOrUpdate = eegActivity - this.eegActivityAtLastPromptPlayOrUpdate;
		if (eegActivity >= this.c.startOffset) {
			const oldIntensity = this.intensity;
			this.intensity = this.GetIntensityForEEGActivity(eegActivity);
			//const intensityChangeSinceLastPrompt = comp.intensity - comp.intensityAtLastPrompt;

			// if activity changed (in either direction) by step-size
			if (Math.abs(activityChangeSinceLastPromptPlayOrUpdate) >= this.c.effectInterval) {
				const activitySteppedUp = activityChangeSinceLastPromptPlayOrUpdate > 0;
				if (activitySteppedUp) {
					//const tempIntensity = comp.intensityAtLastPrompt
					this.PlayAlarmEffect();
				} else {
					// only update prompt for activity decrease, if it led to an actual intensity decrease
					if (this.intensity < oldIntensity) {
						this.UpdatePrompt_ForReducedIntensity();
					}
				}
				// always update this; even if intensity doesn't change, we need to be ready to trigger new prompt, on new increase (not all prompts are persistent)
				this.eegActivityAtLastPromptPlayOrUpdate = eegActivity;
			}
		} else {
			// if prompt may have been active before this notify, make sure it's stopped
			if (this.eegActivityAtLastPromptPlayOrUpdate >= this.c.startOffset) {
				this.StopAlarm("other");
			}
			this.eegActivityAtLastPromptPlayOrUpdate = 0; // reset, so prompt will play as soon as above min-activity again
		}
	}
	
	override OnStop() {
		this.StopBaseTimers();
		this.StopAlarm("other");
		return AlarmComp_ProofBaseMethodCalled;
	}

	//override OnStartPhase_Alarm() {
	override OnLeavePhase_Alarm(newPhase: AlarmsPhase) {
		this.StopBaseTimers();
		this.StopAlarm("other");
		return AlarmComp_ProofBaseMethodCalled;
	}
	StopBaseTimers() {
		this.offsetWaitTimer.Stop();
		this.autoEndTimer.Stop();
		this.autoRestartTimer.Stop();
		this.effectApplyTimer.Stop();
	}

	OnSequenceStarted() {
		// if start-offset is relative, then offset-wait-timer will be started by previous alarm in sequence
		if (!this.c.startOffset_relative) {
			this.offsetWaitTimer.Start();
		}
		return AlarmComp_ProofBaseMethodCalled;
	}
	/*OnSequenceEnabled() {
		if (this.s.Comp(AlarmsComp).PhaseIs(AlarmsPhase.Alarm)) {
			this.OnStartPhase_Alarm();
		}
		return AlarmComp_ProofBaseMethodCalled;
	}*/
	OnSequenceDisabled() {
		this.StopBaseTimers();
		this.StopAlarm("other");
		return AlarmComp_ProofBaseMethodCalled;
	}

	/*TryPlayAlarmEffect() {
		const alarmsComp = this.s.Comp(AlarmsComp);
		if (alarmsComp.microSnoozeActive) return;
		//const effectApplyCount = this.effectApplyTimer.callCount_thisRun;
		this.PlayAlarmEffect();
	}*/

	/** Called in alarm phase, when the start-offset point has been reached. */
	StartAlarm() {
		this.Log(`Starting alarm (${this.constructor.name})`, LogType.Event_Large);
		this.effectApplyAttempts_sinceAlarmStart = 0;
		this.currentAlarmPhase_firstAlarmStartTime ??= Date.now();
		this.autoEndTimer.Start();
		this.autoRestartTimer.Start();
		this.effectApplyTimer.Start(0); // have first tick happen immediately
		return AlarmComp_ProofBaseMethodCalled;
	}
	/** Called when effect-interval passes (in alarms comp) or eeg-activity increases by interval (in eeg comp), from last prompt play/apply. */
	abstract PlayAlarmEffect(): Promise<void>;
	/** Called when eeg-activity drops by step-size, from last trigger. (example: if intensity should increase by 10% per 5 eeg-activity increase, and eeg-activity drops from 10 to 5, this is called) */
	abstract UpdatePrompt_ForReducedIntensity();
	/** Called when snooze occurs (in snooze group; add handling to each comp), or when eeg-activity drops below prompting min (in eeg group). */
	StopAlarm(cause: "autoEnd" | "other") {
		SessionLog(`StopAlarm @${this.constructor.name} @cause:${cause}`);
		this.autoEndTimer.Stop();
		if (cause != "autoEnd") this.autoRestartTimer.Stop();
		this.effectApplyTimer.Stop();
		return AlarmComp_ProofBaseMethodCalled;
	}
}

function Lerp_WithCurve(from: number, to: number, percentFromXToY: number, keepResultInRange = true, curve: number|n) {
	const fadeInRange = to - from;
	let result: number;
	if (curve != null) {
		const multiplierAtCurrentPointOnCurve = Math.pow(percentFromXToY, curve) / Math.pow(1, curve);
		result = from + (fadeInRange * multiplierAtCurrentPointOnCurve);
	} else {
		result = from + (fadeInRange * percentFromXToY);
	}
	if (keepResultInRange) result = result.KeepBetween(from, to);
	return result;
}