import {Annotation} from "uplot-vplugins";
import {store} from "../../../../../Store/index.js";

export function Samples_PopulateAnnotationsForChart(args: {
	width: number, height: number, chartOptions: uPlot.Options, annotations: Annotation[],
	xValues: number[],
}) {
	const {width, height, chartOptions, annotations, xValues} = args;
	const uiState = store.main.settings.transcribe;

	if (uiState.silence_maxPeakVolume != 0) {
		const base: Annotation = {
			type: "box",
			xMin: {pixel: "0%"},
			xMax: {pixel: "100%"},
			//yMin: {value: uiState.silence_maxPeakVolume},
			ySize: {
				pixel: 1 * window.devicePixelRatio,
				finalize: ()=>1 * window.devicePixelRatio, // fsr this is needed
			},
			fillStyle: "rgba(255,255,0,1)",
			drawType: "destination-over",
		};
		annotations.push({...base, yMin: {value: uiState.silence_maxPeakVolume}});
		annotations.push({...base, yMin: {value: -uiState.silence_maxPeakVolume}});
	}
	
	if (uiState.chunk_minPeakVolume != 0) {
		const base: Annotation = {
			type: "box",
			xMin: {pixel: "0%"},
			xMax: {pixel: "100%"},
			//yMin: {value: uiState.chunk_minPeakVolume},
			ySize: {
				pixel: 1 * window.devicePixelRatio,
				finalize: ()=>1 * window.devicePixelRatio, // fsr this is needed
			},
			fillStyle: "rgba(0,255,0,1)",
			drawType: "destination-over",
		};
		annotations.push({...base, yMin: {value: uiState.chunk_minPeakVolume}});
		annotations.push({...base, yMin: {value: -uiState.chunk_minPeakVolume}});
	}
}