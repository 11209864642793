import {autorun, makeObservable} from "mobx";
import {ignore} from "mobx-sync";
import {O, RunInAction} from "web-vcore";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {AudioFileEntry, GetAudioFileEntries_LowLevel} from "../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {store} from "../index.js";
import {AutoRun} from "../../Utils/General/General.js";

export function GetCache() {
	return store.main.cache;
}

export class CacheState {
	constructor() { makeObservable(this); }

	@O @ignore audioFiles_updatedRequestedAt = 0;
	@O @ignore audioFiles: AudioFileEntry[] = [];
}

// todo: ensure that the case-sensitivity here matches with the query-based filtering done on android side, in its "GetAudioFileEntries" bridge-function
export function GetAudioFilesWithSubpath(subpath: string) {
	return GetCache().audioFiles.filter(a=>a.filePath.includes(subpath));
}

AutoRun({wait: 0}, ()=>{
	const cache = GetCache();
	const updateRequestedAt = cache.audioFiles_updatedRequestedAt; // read this, so that updating the field causes this autorun to re-execute
	const updateCallSuperseded = ()=>cache.audioFiles_updatedRequestedAt != updateRequestedAt;

	(async()=>{
		if (updateCallSuperseded()) return;
		const audioFiles = await GetAudioFileEntries_LowLevel("/");
		if (updateCallSuperseded()) return;

		SessionLog(`Refreshed audio-files cache, from android media store: ${audioFiles.length}`);
		RunInAction("CacheState_audioFiles_autorun", ()=>{
			cache.audioFiles = audioFiles;
		});
	})();
}, {name: "CacheState_audioFiles_autorun"});