import {AddSchema} from "mobx-firelink";
import {GetValues_ForSchema} from "js-vextensions";
import {TriggerSet} from "../@TriggerSet.js";

export enum DreamQuiz_QuestionType {
	isPresent = "isPresent",
	comesAfter = "comesAfter",
}

export class FBAConfig_DreamQuiz {
	constructor(initialData: Partial<FBAConfig_DreamQuiz>) { this.VSet(initialData); }

	enabled: boolean;

	// triggers
	submitPick_triggerSet: TriggerSet;
	nextOption_triggerSet: TriggerSet;
	prevOption_triggerSet: TriggerSet;
	skipPick_triggerSet: TriggerSet;
	//stopVoice_triggerSet: TriggerSet;

	voiceSoundTag: string;
	volumeMultiplier: number;
	targetCountPerCycle: number[];
	optionCount: number;
	startAtLast: boolean;

	// dream quiz
	lucidSegmentsOnly: boolean;
	segmentMinEvents: number;
	eventMaxWords: number;
	hintSize: number;
	targetSize: number;
	minDistToTarget: number;
	questionType: DreamQuiz_QuestionType;
	questionType_invert: boolean;

	// sound quiz
	soundQuiz_enabled: boolean;
	quizRatio_dream: number;
	quizRatio_sound: number;
	soundFolder: string;
	soundVolume: number;
	soundDurationLimit: number;
}
AddSchema("FBAConfig_DreamQuiz", {
	properties: {
		enabled: {type: "boolean"},

		submitPick_triggerSet: {$ref: "TriggerSet"},
		nextOption_triggerSet: {$ref: "TriggerSet"},
		prevOption_triggerSet: {$ref: "TriggerSet"},
		skipPick_triggerSet: {$ref: "TriggerSet"},
		//stopVoice_triggerSet: {$ref: "TriggerSet"},

		voiceSoundTag: {type: "string"},
		volumeMultiplier: {type: "number"},
		targetCountPerCycle: {items: {type: "number"}},
		optionCount: {type: "number"},
		startAtLast: {type: "boolean"},

		lucidSegmentsOnly: {type: "boolean"},
		segmentMinEvents: {type: "number"},
		eventMaxWords: {type: "number"},
		hintSize: {type: "number"},
		targetSize: {type: "number"},
		minDistToTarget: {type: "number"},
		questionType: {oneOf: GetValues_ForSchema(DreamQuiz_QuestionType)},
		questionType_invert: {type: "boolean"},

		soundQuiz_enabled: {type: "boolean"},
		quizRatio_dream: {type: "number"},
		quizRatio_sound: {type: "number"},
		soundFolder: {type: "string"},
		soundVolume: {type: "number"},
		soundDurationLimit: {type: "number"},
	},
});