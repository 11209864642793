import {GyroProcessor} from "../../../../../UI/@Shared/Processors/GyroProcessor.js";
import {sessionChartColors} from "../../../../../UI/Timeline/Sessions/SessionUI/SessionChartColors.js";
import {currentMuse} from "../../../../../UI/Tools/@Shared/MuseInterface.js";
import {Muse_gyroSamplesPerSecond_raw} from "../../../../../UI/Tools/@Shared/MuseInterface/GyroStructs.js";
import useResizeObserver from "use-resize-observer";
import {InAndroid} from "../../../../../Utils/Bridge/Bridge_Native.js";
import {createRef_withHooks} from "../../../../../Utils/UI/ReactHelpers.js";
import {uplotDefaults} from "../../../../../Utils/UI/UPlotDefaults.js";
import {Observer} from "web-vcore";
import {Assert, IsNaN} from "js-vextensions";
import {UPlot} from "react-uplot";
import {BaseComponentPlus} from "react-vextensions";
import {useMemo} from "react";
import uPlot from "uplot";

export const GyroChart_live_percentOfSamplesGraphed = InAndroid(0) ? .1 : 1;
export const GyroChart_live_graphedSamplesPerSecond = Muse_gyroSamplesPerSecond_raw * GyroChart_live_percentOfSamplesGraphed;

const lineTypes: (uPlot.Series & {key: string})[] = [
	{
		value: (self, rawValue)=>{
			return `${(rawValue / GyroChart_live_graphedSamplesPerSecond).toFixed(1)}s`;
		},
	} as any,
	{
		label: "X",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.gyro_x,
		points: {show: false},
	},
	{
		label: "Y",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.gyro_y,
		points: {show: false},
	},
	{
		label: "Z",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}`,
		stroke: sessionChartColors.gyro_z,
		points: {show: false},
	},
	{
		label: "Combined-deviation",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}%`,
		stroke: sessionChartColors.gyro_combinedDeviation,
		points: {show: false},
	},
	{
		label: "Trigger-sample %",
		value: (self, rawValue)=>`${rawValue.toFixed(2)}%`,
		stroke: sessionChartColors.gyro_triggerSamplePercent,
		points: {show: false},
	},
	//{name: "Gyro deviation", color: HSL(240, 1, .5)},
];

//@SimpleShouldUpdate
@Observer
export class GyroChart_Live extends BaseComponentPlus({} as {
	processor: GyroProcessor,
	viewRange: number,
}, {}) {
	render() {
		const {processor, viewRange} = this.props;
		//const {ref: rootRef, width = 100, height = 100} = useResizeObserver();
		const {callbackRef: rootRef, width = 100, height = 100} = useResizeObserver();

		const legendHeight = 33; // from dev-tools
		const chartOptions: uPlot.Options = useMemo(()=>{
			return {
				id: `chart_live`,
				class: "gyro-chart",
				width,
				height: height - legendHeight,
				cursor: {
					drag: {x: false, setScale: false},
				},
				axes: [
					{
						label: "Time",
						...uplotDefaults.axes_props,
						size: 50,
						values: (self, vals)=>vals.map(a=>`${(a / GyroChart_live_graphedSamplesPerSecond).toFixed(1)}s`),
					},
					{
						label: "Value",
						...uplotDefaults.axes_props,
						size: 50,
					},
				],
				scales: {
					x: {
						time: false,
						range(u, dataMin, dataMax): [number, number] {
							return [-10 * GyroChart_live_graphedSamplesPerSecond, 0];
						},
					},
					y: {
						auto: false,
						//min: -100, max: 100,
						range: (u, dataMin, dataMax)=>{ // use func, since stable
							return [-100, 100] as [number, number];
						},
					},
				},
				series: lineTypes,
			};
		}, [width, height, viewRange]);
		//if (monitorGyroProcessor.options == null) return null; // wait
		//if (monitorGyroProcessor_reflector?.options == null) return null; // wait

		/*if (processor.samples_uplotData == null) {
			processor.ResetData();
		}*/
		const data = null; // start data as null; populate using UpdateChart()

		return (
			<div ref={rootRef as any} style={{position: "relative", width: "100%", height: "calc(100% - 18px)"}}>
				<UPlot chartRef={this.chart} options={chartOptions} data={data as any}/>
			</div>
		);
	}
	chart = createRef_withHooks<uPlot>({
		postSet: chart=>{
			if (chart == null) return;
			const uplotDiv = chart.root as HTMLDivElement;
			//const doubleClickStartDiv = uplotDiv.querySelector(".u-over") as HTMLDivElement;
			// prevent uplot's double-click-to-reset-zoom behavior
			uplotDiv.addEventListener("dblclick", e=>{
				e.stopPropagation();
			}, {capture: true});

			this.UpdateChart();
		},
	})

	ComponentDidMount() {
		currentMuse.gyroListeners.push(this.gyroListener);
		//this.StartEngineConfigMirrorer();
		//this.UpdateChart(); // show existing data stored in processor (if any) // commented; moved to chart-ref.postSet above (this handler's too early)
	}
	ComponentDidUpdate() {
		this.UpdateChart();
	}
	ComponentWillUnmount() {
		currentMuse.gyroListeners.Remove(this.gyroListener);
	}
	sampleCountAtLastChartUpdate = 0;
	gyroListener = sample=>{
		const {processor, viewRange} = this.props;
		//if (monitorEEGProcessor_reflector?.options == null) return; // wait
		if (processor.options == null) return; // wait

		processor.AddAndProcessGyroSample(sample);

		// every .05 seconds, update chart
		if (processor.samples.length - this.sampleCountAtLastChartUpdate >= GyroChart_live_graphedSamplesPerSecond * .05) {
			this.UpdateChart(); //newXScale);
			this.sampleCountAtLastChartUpdate = processor.samples.length;
		}
	};

	UpdateChart(newXScale?) {
		const chart = this.chart.current;
		if (chart == null) return;
		const {processor} = this.props;
		//console.log("UpdateChart.chart:", xScale_old, yScale_old);

		/*const data = this.props.processor.samples_uplotData;
		const recentDataLength = data[0].length.KeepAtMost(10 * GyroChart_live_graphedSamplesPerSecond);*/
		const recentDataLength = processor.samples.length.KeepAtMost(10 * GyroChart_live_graphedSamplesPerSecond);
		const recentSamples = processor.samples.slice(-recentDataLength);
		const recentData = [
			recentSamples.map((a, i)=>-i.Distance(recentDataLength - 1)),
			/*recentSamples.map(a=>a.x_final).slice(-recentDataLength),
			recentSamples.map(a=>a.y_final).slice(-recentDataLength),
			recentSamples.map(a=>a.z_final).slice(-recentDataLength),
			recentSamples.map(a=>a.combinedDeviation).slice(-recentDataLength),*/
			processor.alignedSamples_x_display.slice(-recentDataLength),
			processor.alignedSamples_y_display.slice(-recentDataLength),
			processor.alignedSamples_z_display.slice(-recentDataLength),
			processor.alignedSamples_combinedDeviation.slice(-recentDataLength),
			recentSamples.map(a=>a.triggerSamplePercent).slice(-recentDataLength),
		] as uPlot.AlignedData;

		const xScale_old = {min: chart.scales.x.min, max: chart.scales.x.max};
		chart.setData(recentData, false);

		// calling setScale triggers a chart redraw
		chart.batch(()=>{
			Assert([xScale_old.min, xScale_old.max].every(a=>!IsNaN(a)), "Found NaN in new scale values. (GyroChart.tsx)");
			chart.setScale("x", newXScale ?? xScale_old);
			//chart.setScale("y", yScale_old);
		});
	}
}