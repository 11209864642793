import {AddSchema} from "mobx-firelink";

export class ProcessMessage {
	constructor(initialData: Partial<ProcessMessage>) {
		this.Extend(initialData);
	}
	_key?: string; // same as sendTime.toString(), as below
	user: string;
	fromProcessID: number; // id is just the time-in-ms when that process started
	sendTime: number;
	message: Object;
}
AddSchema("ProcessMessage", {
	properties: {
		user: {type: "string"},
		fromProcessID: {type: "number"},
		sendTime: {type: "number"},
		message: {type: "object"},
	},
});