import {InfoButton, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {Clone} from "js-vextensions";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Row, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {AddJournalEntry} from "../../../Server/Commands/AddJournalEntry.js";
import {UpdateUserHidden} from "../../../Server/Commands/UpdateUserHidden.js";
import {JournalEntry, JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {Me, MeID} from "../../../Store/firebase/users.js";
import {GetMyGridEntityNames, GetUserHidden} from "../../../Store/firebase/users_private.js";
import {store} from "../../../Store/index.js";
import {zIndexes} from "../../../Utils/UI/ZIndexes.js";
import {ShowSignInPopup} from "../../@Shared/NavBar/UserPanel.js";

@Observer
export class EntryGridSettingsDropdown extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.journal;
		const userHidden = GetUserHidden(MeID()!);
		const gridEntityNames = GetMyGridEntityNames();

		const ChangeMyGridEntityNames = async(changerFunc: (names: string[])=>any)=>{
			const newNames = Clone(gridEntityNames);
			changerFunc(newNames);
			await new UpdateUserHidden({id: MeID()!, updates: {gridEntityNames: newNames}}).Run();
		};

		return (
			<DropDown style={{marginLeft: "auto"}}>
				<DropDownTrigger>
					<Button mdIcon="cog" size={32}/>
				</DropDownTrigger>
				<DropDownContent style={{zIndex: zIndexes.dropDown, right: 0, width: 300, background: "rgba(0,0,0,.9)", borderRadius: 5}}><Column>
					<Button text="Add entry" style={{alignSelf: "flex-end"}} onClick={async()=>{
						if (Me() == null) return ShowSignInPopup();
						const entry = new JournalEntry({
							title: "New entry",
							wakeTime: Date.now(),
							segments: [
								new JournalSegment({
									wakeTime: Date.now(),
								}),
							],
						});
						const entryID = await new AddJournalEntry({entry}).Run();
						RunInAction("JournalEntryList.addEntry.onClick", ()=>uiState.selectedJournalEntry = entryID);
					}}/>
					<Row>
						<Text>Entries per cell:</Text>
						<Spinner ml={5} style={{width: 30}} min={1} value={uiState.entriesPerGridCell} onChange={val=>RunInAction_Set(()=>uiState.entriesPerGridCell = val)}/>
					</Row>
					<Row>
						<CheckBox text="Custom grid text-extractor" value={userHidden?.customGridExtractor_enabled ?? false} onChange={val=>{
							new UpdateUserHidden({id: MeID()!, updates: {customGridExtractor_enabled: val}}).Run();
						}}/>
						<InfoButton ml={5} text={`
							When enabled, uses a custom regex to extract text from journal entries, to determine the content in the journal-grid cells.
							Note: The extractor is a regex (with flags "s" and "i"), matched against journal entries' lowercased all-text.
							Note: Use $entity for entity-name; the last group becomes the grid cell's content.
							Example: @$entity"(.+?)"
						`.AsMultiline(0)}/>
					</Row>
					{userHidden?.customGridExtractor_enabled &&
					<TextInput style={{flex: 1}} value={userHidden.customGridExtractor_regex} onChange={val=>{
						new UpdateUserHidden({id: MeID()!, updates: {customGridExtractor_regex: val}}).Run();
					}}/>}
					<Row>
						<Text>Grid entity names:</Text>
						<InfoButton ml={5} text={`
							For each entry, adds grid cell, and finds matching journal entries, using each entry as regex.
							Note: The entry's text is matched against lowercased all-text, and wrapped in a regex of contents /[^a-z](...)[^a-z]/.
						`.AsMultiline(0)}/>
						<Button ml={5} mdIcon="plus" onClick={()=>{
							ChangeMyGridEntityNames(names=>names.push("New entity"));
						}}/>
					</Row>
					{gridEntityNames.map((name, index)=>{
						return (
							<Row key={index} center>
								<Text style={{width: 11}}>{index + 1}:</Text>
								<TextInput style={{flex: 1}} value={name} onChange={val=>{
									ChangeMyGridEntityNames(names=>names[index] = val);
								}}/>
								<Button ml={3} mdIcon="arrow-up-bold" size={20} enabled={index > 0} onClick={()=>{
									ChangeMyGridEntityNames(names=>names.Move(name, index - 1, "final-index"));
								}}/>
								<Button ml={3} mdIcon="arrow-down-bold" size={20} enabled={index < gridEntityNames.length - 1} onClick={()=>{
									ChangeMyGridEntityNames(names=>names.Move(name, index + 1, "final-index"));
								}}/>
								<Button ml={3} mdIcon="delete" size={20} onClick={()=>{
									ChangeMyGridEntityNames(names=>names.RemoveAt(index));
								}}/>
							</Row>
						);
					})}
				</Column></DropDownContent>
			</DropDown>
		);
	}
}