import {Column, Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {store} from "../../../../Store/index.js";
import {EngineSessionInfo} from "../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {GetSelectedSessionData} from "../../../../Store/main/timeline.js";
import {GetSessionProcessor_Active} from "../../../@Shared/Processors/@ProcessorInstances.js";
import {DetailChart} from "./DetailsUI/DetailChart.js";
import {GetSessionSubpath} from "../../../../Engine/FBASession.js";
import {InDesktop} from "../../../../Utils/Bridge/Bridge_Native.js";
import {ES} from "../../../../Utils/UI/GlobalStyles.js";
import {Observer} from "web-vcore";
import {DetailPanelSettingsUI} from "./DetailsUI/DetailPanelSettingsUI.js";
import {VideoRecordingPlayer} from "./DetailsUI/VideoRecordingPlayer.js";
import {SeekBar} from "./SeekBar.js";
import {SessionUI_SharedProps} from "../SessionUI.js";

export class SessionUI_DetailsUI extends BaseComponent<{} & SessionUI_SharedProps, {}> {
	render() {
		const {session} = this.props;
		return (
			<Column style={{flex: 1}}>
				<DetailsPanel session={session}/>
				<SeekBar session={session}/>
			</Column>
		);
	}
}

@Observer
export class DetailsPanel extends BaseComponent<{session: EngineSessionInfo}, {}> {
	render() {
		const {session} = this.props;
		const uiState = store.main.timeline.sessions;
		const processor = GetSessionProcessor_Active();
		if (processor.eegProcessor?.options == null) return (setTimeout(()=>this.Update(), 100), null); // wait 100ms, then retry
		if (!InDesktop()) return <Row m={10} style={ES({flex: 1})}>Detail-panel only usable in the desktop app. (raw eeg data not available elsewhere)</Row>;
		const sessionData = GetSelectedSessionData();
		if (sessionData == null) return null;
		//if (detailPanelEEGProcessor.options == null) return null; // wait
		//if (detailPanelEEGProcessor_reflector?.options == null) return null; // wait
		if (processor.loadedSessionData?.session != session) return (setTimeout(()=>this.Update(), 100), null); // wait 100ms, then retry

		if ((processor.loadedSessionData?.eegSampleFiles.left.samplesBySecond.VKeys().length ?? 0) == 0) {
			return <Row m={10} style={ES({flex: 1})}>Files containing raw eeg data for session not found. (or still loading)</Row>;
		}
		if ((processor.loadedSessionData?.gyroSampleFiles.x.samplesBySecond.VKeys().length ?? 0) == 0) {
			return <Row m={10} style={ES({flex: 1})}>Files containing raw gyro data for session not found. (or still loading)</Row>;
		}

		const camRecordingPeriods_final = sessionData.CamRecordingPeriods_Adjusted(uiState.videoLatency);
		const lastRecordingForTime_period = camRecordingPeriods_final.filter(a=>a.end > uiState.currentTime && a.start <= uiState.currentTime).LastOrX();
		if (lastRecordingForTime_period) {
			var lastRecordingForTime_filename = sessionData.generalHistory.camFilenames[camRecordingPeriods_final.indexOf(lastRecordingForTime_period)];
			var lastRecordingForTime_path = GetSessionSubpath(session!._folderName!, `Camera/${lastRecordingForTime_filename}`);
		}

		return (
			<Row mt={10} style={ES({flex: 1})}>
				<Column style={ES({flex: 1, position: "relative"})}>
					{lastRecordingForTime_path! && uiState.video_show &&
						<VideoRecordingPlayer session={session} period={lastRecordingForTime_period} path={lastRecordingForTime_path}/>}
					<DetailChart processor={processor} overlay={true} session={session}
						//startTime={visibleRange_firstSecond} endTime={visibleRange_endSecond}
						initialViewRange={uiState.initialViewRange * 1000}
						maxViewRange={uiState.maxViewRange * 1000}
						//startSample={visibleRange_startSampleIndex} endSample={visibleRange_endSampleIndex} processedExtent={detailPanelEEGProcessor.processedExtentFromCenter}
					/>
				</Column>
				<DetailPanelSettingsUI session={session}/>
			</Row>
		);
	}
}