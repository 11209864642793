import {Light, LightType} from "../../Store/firebase/lights/@Light.js";
import {PlayLightConfig_LIFX, ResetLight_LIFX} from "../Services/LIFX_Web.js";
import {SleepAsync, WaitXThenRun, Timer, E} from "js-vextensions";
import {LogWarning, RunInAction} from "web-vcore";
import {runInAction} from "mobx";
import {store} from "../../Store/index.js";
import {RunUserScript_CallArgsObj} from "../../Utils/UserScripts/ScriptRunner.js";
import {ScreenLight_PromptInfo, ScreenLight_Frame} from "../../Store/main/effects/lights.js";
import {SetScreenBrightness} from "../../Utils/Bridge/Bridge_Preload.js";
import {PlayState, PlayStateListener} from "./SoundPlayer.js";
import {PlayLightConfig_Kasa, ResetLight_Kasa} from "../Bridge/Bridge_Native/Kasa.js";
import {EffectPlayExtras, EffectPlayer} from "./EffectPlayer.js";
import {GetUserEntityTags, MeID} from "../../Store/firebase/users.js";
import {GetLights_WithUserTag} from "../../Store/firebase/lights.js";

export interface LightPlayController {
	stop: ()=>void;
}

export let lastActiveLightPlayer: LightPlayer;
export class LightPlayer extends EffectPlayer {
	constructor(light: Light|n = null) {
		super();
		this.light = light;
	}

	private light_: Light|n;
	get light() { return this.light_; }
	set light(val: Light|n) {
		if (val == this.light_) return;
		this.Stop();
		this.light_ = val;
	}
	FindLight(tag: string, keepSoundIfStillValid = false) {
		const isOldLightValid = GetUserEntityTags(MeID(), "lights", this.light?._key).includes(tag);
		if (!isOldLightValid || !keepSoundIfStillValid) {
			this.light = GetLights_WithUserTag(tag).Random();
		}
		return this;
	}

	_playState: PlayState = "stopped"; // flag is not necessarily real-world accurate (effect may be overridden by other code)
	get playState() { return this._playState; }
	set playState(val: PlayState) { this._playState = val; this.stateListeners.forEach(a=>a(val, ()=>this.stateListeners.Remove(a))); }
	stateListeners = [] as PlayStateListener[];
	//WaitTillState(waitForStates: PlayState[], allowExistingTrigger = false) {
	WaitTillState(...waitForStates: PlayState[]) {
		return new Promise<void>((resolve, reject)=>{
			//if (allowExistingTrigger && waitForStates.includes(this.playState)) return resolve();
			this.stateListeners.push((state, removeListener)=>{
				if (waitForStates.includes(state)) {
					resolve();
					removeListener();
				}
			});
		});
	}

	/*get PlayingIndefinitely() {
		return this == lastActiveLightPlayer && this.playState == "unknown" && !this.light_.temp;
	}*/

	//loopNext = false;
	lastPlay_controller?: LightPlayController|n;
	stopTimer: Timer;
	async Play(intensity = 1, extras?: Partial<EffectPlayExtras>) {
		const {allowReapply} = new EffectPlayExtras(extras);
		if (this.light == null) return; //void LogWarning("Failed to play light, since it is null.");
		if (lastActiveLightPlayer && lastActiveLightPlayer.playState != "stopped") {
			lastActiveLightPlayer.Stop(false); // don't reset externals, as we're about to send new command anyway (which could cause race conditions)
		}
		this.playState = "playing";
		lastActiveLightPlayer = this;

		const brightness = intensity;
		if (this.light.type == LightType.Screen) {
			this.lastPlay_controller = null;
			RunUserScript_CallArgsObj(this.light.effectJSON_functionStr, {brightness}).then((promptInfo: ScreenLight_PromptInfo)=>{
				// todo: handle race condition properly
				FinalizeScreenLightPromptInfo(promptInfo);
				if (promptInfo.screenBrightness != null) {
					SetScreenBrightness(promptInfo.screenBrightness);
				}
				// show screen-light overlay
				RunInAction("LightPlayer.Play", ()=>{
					store.main.effects.lights.screenLight_currentPlayer = this;
					store.main.effects.lights.screenLight_currentPromptInfo = promptInfo as any;
				});
			});
		} else if (this.light.type == LightType.LIFXBulb) {
			this.lastPlay_controller = null;
			PlayLightConfig_LIFX(this.light, brightness);
		} else if (this.light.type == LightType.KasaBulb) {
			const cancelInfo = {cancel: false};
			this.lastPlay_controller = {stop: ()=>cancelInfo.cancel = true};
			await PlayLightConfig_Kasa(this.light, brightness, allowReapply, cancelInfo);
		}

		if (this.light.temp) {
			this.stopTimer = new Timer(this.light.tempLength * 1000, ()=>this.Stop(), 1).Start();
			await this.WaitTillState("stopped");
		} else {
			//await new Promise(()=>{}); // await a promise that never resolves (since light persists)
			//await WaitTillLightReset();
			await this.WaitTillState("stopped");
		}
	}
	Stop(resetExternals = true) {
		if (this.playState == "stopped") return;

		// stop any "cycling" effects in last-played light-config
		if (this.lastPlay_controller) {
			this.lastPlay_controller.stop();
		}

		// There's no safe way to "stop" a played light-config, since we aren't certain of the resting state. (off if sleeping; on if awake)
		// That said, we'll assume the caller means: stop any in-progress light effects, and play the "reset light" config (from settings)
		this.stopTimer?.Stop();
		this.playState = "stopped";
		if (resetExternals) {
			//if (this.light?.type == LightType.Screen) ResetScreenBrightness();
			if (this.light?.type == LightType.LIFXBulb) ResetLight_LIFX();
			if (this.light?.type == LightType.KasaBulb) ResetLight_Kasa();
		}
	}
}

export function FinalizeScreenLightPromptInfo(promptInfo: ScreenLight_PromptInfo) {
	promptInfo.frameInterval = promptInfo.frameInterval ?? 1;
	promptInfo.framesLoop = promptInfo.framesLoop ?? true;
	promptInfo.frames = promptInfo.frames ?? [];

	const finalFrames = [] as ScreenLight_Frame[];
	for (let i = 0; i < promptInfo.frames.length; i++) {
		const frame = promptInfo.frames[i];
		finalFrames.push(frame);
		if (frame.repeat_count != null && frame.repeat_count >= 1 && frame.repeat_from != null) {
			const block = promptInfo.frames.slice(i + frame.repeat_from, i + 1);
			for (let repeatI = 0; repeatI < frame.repeat_count; repeatI++) {
				const blockCopy = block.map(frame2=>({...frame2}));
				finalFrames.push(...blockCopy);
			}
		}
	}
	promptInfo.frames_orig = promptInfo.frames;
	promptInfo.frames = finalFrames;
	return promptInfo;
}
/*export function ResetScreenBrightness() {
}*/