import {GetDoc, GetDocs, StoreAccessor} from "mobx-firelink";
import {EffectVisibility, Sound} from "./sounds/@Sound";
import {MeID, GetUserEntityTags, Me} from "./users";
import {IsUserCreatorOrMod} from "./users/$user";

export const GetSounds = StoreAccessor(s=>(filterOutHidden = true): Sound[]=>{
	let result = GetDocs({}, a=>a.sounds).map(a=>a?.Cast(Sound));
	if (filterOutHidden) result = result.filter(a=>a && (a.visibility == EffectVisibility.Visible || IsUserCreatorOrMod(MeID(), a)));
	return result;
});
export const GetSounds_WithUserTag = StoreAccessor(s=>(tagName: string|n, filterOutHidden = true): Sound[]=>{
	const sounds = GetSounds(filterOutHidden);
	const userSoundTags = Me()?.entityTags?.sounds ?? {};
	return sounds.filter(a=>userSoundTags[a._key]?.includes(tagName as any));
});
export const GetSound = StoreAccessor(s=>(id: string): Sound|n=>{
	return GetDoc({}, a=>a.sounds.get(id))?.Cast(Sound);
});

/*const msInHour = 1000 * 60 * 60;
export function GetSoundSessions(id: number): number {
	let events = GetEvents();
	let activities = GetActivities(MeID());

	let count = 0;
	for (let event of events) {
		let activity = activities.find(a=>a._id == event.activity);
		if (activity && activity.name == "FBA session" && event.fbaConfig && event.fbaConfig.soundID == id) {
			if (event.endTime == null) continue;
			let hours = (event.endTime - event.startTime) / msInHour;
			if (hours < 1) continue; // ignore sessions less than 1 hour long
			count++;
		}
	}
	return count;
}
export function GetSoundHours(id: number): number {
	let events = GetEvents();
	let activities = GetActivities(MeID());

	let totalTime = 0;
	for (let event of events) {
		let activity = activities.find(a=>a._id == event.activity);
		if (activity && activity.name == "FBA session" && event.fbaConfig && event.fbaConfig.soundID == id) {
			if (event.endTime == null) continue;
			totalTime += event.endTime - event.startTime;
		}
	}
	return totalTime / msInHour;
}
export function GetSoundInductions(id: number): number {
	let events = GetEvents();
	let activities = GetActivities(MeID());

	let count = 0;
	for (let event of events) {
		let activity = activities.find(a=>a._id == event.activity);
		if (activity && activity.name.startsWith("LD") && event.fbaConfig && event.fbaConfig.soundID == id) {
			count++;
		}
	}
	return count;
}
export function GetSoundInductionsPerSession(id: number): number {
	return GetSoundInductions(id) / GetSoundSessions(id);
}
export function GetSoundInductionsPerHour(id: number): number {
	return GetSoundInductions(id) / GetSoundHours(id);
}*/