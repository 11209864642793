import {AddSchema, NewSchema, GetSchemaJSON, AssertValidate, AV, Command} from "mobx-firelink";
import {GetActivity} from "../../Store/firebase/activities";
import {Activity} from "../../Store/firebase/activities/@Activity";
import {UserEdit} from "../CommandMacros";

AddSchema("UpdateActivity_payload", ["Activity"], ()=>({
	properties: {
		id: {type: "number"},
		updates: NewSchema({
			properties: GetSchemaJSON("Activity").properties!.IncludeKeys("name", "color"),
		}),
	},
	required: ["id", "updates"],
}));

@UserEdit
export class UpdateActivity extends Command<{id: string, updates: Partial<Activity>}> {
	oldData: Activity;
	newData: Activity;
	Validate() {
		AssertValidate("UpdateActivity_payload", this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = AV.NonNull = GetActivity.NN(id);
		this.newData = {...this.oldData, ...updates};
		AssertValidate("Activity", this.newData, `New data invalid`);
	}

	GetDBUpdates() {
		const {id, updates} = this.payload;
		const dbUpdates = {};
		dbUpdates[`activities/${id}`] = this.newData;
		return dbUpdates;
	}
}