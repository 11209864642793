import {AddSchema, NewSchema, GetSchemaJSON, AssertValidate, Command} from "mobx-firelink";
import {Light} from "../../Store/firebase/lights/@Light";
import {GetLight} from "../../Store/firebase/lights";
import {EngineSessionInfo} from "../../Store/firebase/sessions/@EngineSessionInfo";
import {GetSession} from "../../Store/firebase/sessions";

type MainType = EngineSessionInfo;
const MTName = "EngineSessionInfo";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.IncludeKeys("events"),
		}),
	},
	required: ["id", "updates"],
}));

export class UpdateSession extends Command<{id: string, updates: Partial<MainType>}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = GetSession.NN(id);
		this.newData = {...this.oldData, ...updates} as MainType;
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`sessions/${id}`] = this.newData;
		return updates;
	}
}