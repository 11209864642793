import {FBASession, TriggerPackage} from "../../../Engine/FBASession.js";
import {clientConnection} from "../../../Engine/Remoting/ClientConnection.js";
import {hostConnection} from "../../../Engine/Remoting/HostConnection.js";
import {SessionCoreData} from "../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {LogOptions_ForGroupX} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {AudioFileEntry} from "../../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {AlarmsPhase} from "./AlarmsComp.js";

/** If a component's method is tagged with this, it will be called even when the component is disabled. */
export function AlwaysCall(target: Object, key: string) {
	target[key].callEvenWhenCompDisabled = true;
}

/*export interface FBASessionComponent {
	PreSnooze?(options: SnoozeOptions);
}*/
export class EngineSessionComp<ConfigType = {}> {
	constructor(
		session: FBASession, config: ConfigType, triggersEnabled_requirement: (session: FBASession)=>boolean,
		/** For behaviorEnabled to end up true, this must return true, *and* triggersEnabled must be true. */
		behaviorEnabled_additionalRequirement: (session: FBASession)=>boolean
	) {
		this.s = session;
		this.c = config;
		this.triggersEnabled = triggersEnabled_requirement(session);
		this.behaviorEnabled = this.triggersEnabled && behaviorEnabled_additionalRequirement(session);
	}
	s: FBASession;
	c: ConfigType;
	/** Whether comp's triggers get activated. (only actually applied because the comp's GetTriggerPackages() function checks this field) */
	triggersEnabled = true; // todo: maybe rename to "baseEnabled"
	/** Whether comp receives normal broadcasts. (also toggles some other behaviors, eg. snoozing) */
	behaviorEnabled = true;

	async TryCallOnHostComp(methodName: string, ...args: any[]) {
		const compTypeName = this.constructor.name;
		if (!this.s.IsClient()) return; // we're already on local/host, so do nothing
		//if (this.s.IsLocal()) return this.s.remoteBridge.CallInternal("TryCallOnComp", compTypeName, methodName, ...args); // we're already on local/host, so call within this instance*/

		return await hostConnection!.bridge.Call("TryCallOnComp", compTypeName, methodName, ...args);
	}
	async TryCallOnClientComp(methodName: string, ...args: any[]) {
		const compTypeName = this.constructor.name;
		if (!this.s.IsLocal()) return; // we're already on client, so do nothing
		//if (this.s.IsRemote()) return this.s.remoteBridge.CallInternal("TryCallOnComp", compTypeName, methodName, ...args); // we're already on remote, so call within this instance*/

		return await clientConnection!.bridge.Call("TryCallOnComp", compTypeName, methodName, ...args);
	}

	/*get c() { return this.s.c.snoozeAndPrompts.memoryPrompt; }
	get Enabled() { return this.s.c.snoozeAndPrompts.enabled && this.c.enabled; }*/

	Log(message: string, type = LogType.Event_Small, opt?: LogOptions_ForGroupX) {
		this.s.Log(`[${this.constructor.name}] ${message}`, type, opt);
	}

	// we'll need a more thoughtful approach to this whole system/block, once expanded to more comps
	IsSuspended() {
		if (this.c["enabled"] == false) return false; // if in-config disabled, don't consider suspended

		return this.behaviorEnabled && !this.triggersEnabled;
	}
	Suspend() {
		if (this.c["enabled"] == false) return; // if in-config-disabled, suspend/unsuspend do nothing

		//throw new Error("Method not implemented.");
		this.triggersEnabled = false;
		const resetActivatorStates = ()=>{
			for (const pack of this.triggerPackages) {
				if (pack.activator == null) continue;
				pack.activator.ResetStateForSuspend();
			}
		}
		resetActivatorStates();
		// kinda inelegant, but reset-state again in one tick; needed since, even though this comp's trigger-packages were just disabled,
		// ...their activators may still receive one final input-event which can mutate state
		// (namely, the input-event that is currently being processed / triggered this comp.Suspend() call -- if indeed that is the source)
		setTimeout(resetActivatorStates, 0);
	}
	Unsuspend() {
		if (this.c["enabled"] == false) return; // if in-config disabled, suspend/unsuspend do nothing

		//throw new Error("Method not implemented.");
		this.triggersEnabled = true;
	}

	// methods that are supposed to be overriden, in many component classes
	// (rather than optional methods, just have an empty implementation for each -- makes calling code simpler)
	// ==========


	triggerPackages = [] as TriggerPackage[]; // populated by FBASession.AddTriggerPackage
	GetTriggerPackages(): TriggerPackage[] { return []; }
	GetStatusUI?(): JSX.Element|string;

	/** Called prior to general init. Used for, eg. code that must run right after a user action (start-session click). */
	OnStart_Early() {}
	/** Note: This gets called even if the component starts out suspended (due to ModeSwitcherComp). */
	OnStart(recoveryInfo: SessionCoreData|n) {}
	OnStop(saveLocalData: boolean) {}
	OnInitialDelayCompleted() {}

	OnStartPhase(oldPhase: AlarmsPhase, newPhase: AlarmsPhase) {}
	OnLeavePhase(newPhase: AlarmsPhase, oldPhase: AlarmsPhase) {}

	OnStartPhase_Sleep(oldPhase: AlarmsPhase) {}
	OnLeavePhase_Sleep(newPhase: AlarmsPhase) {}
	OnStartPhase_Alarm(oldPhase: AlarmsPhase) {}
	OnLeavePhase_Alarm(newPhase: AlarmsPhase) {}
	OnStartPhase_Solving(oldPhase: AlarmsPhase) {}
	OnLeavePhase_Solving(newPhase: AlarmsPhase) {}

	//PreSnooze(options: SnoozeOptions) {}
}