import jailed from "jailed";
import {StringifyError} from "web-vcore";

/*export function RunUserScript(code: string, ...args: any[]) {
	//return eval(this.c.narrationTemplate);
	const func = new Function("brightness", `
		"use strict";
		${light.effectJSON_functionStr}
	`);
	return func(...args);
}*/

export async function RunUserScript_CallArgsObj(funcBody: string, callArgs: Object = {}, wrapErrorMessages = true, useStrict = true) {
	const result = await RunUserScript(`function(${callArgs.VKeys().join(", ")}) {
		${useStrict ? `"use strict";` : ""}
		${funcBody}
	}`, callArgs.VValues(), wrapErrorMessages);
	return result;
}
export const plugins_byCode = new Map<string, jailed.DynamicPlugin>();
export function GetPluginForCode(code: string) {
	if (!plugins_byCode.has(code)) {
		const plugin = new jailed.DynamicPlugin(code);
		plugin.whenConnected(()=>{
			plugin.connected = true;
			plugin.onConnected?.();
		});
		plugins_byCode.set(code, plugin);
	}
	return plugins_byCode.get(code);
}
export async function RunUserScript(funcStr: string, callArgs: any[], wrapErrorMessages = true) {
	return new Promise((resolve, reject)=>{
		var code = `
			const userFunc = ${funcStr};
			var api = {
				callAndReportResult: (callArgs, callback, errorCallback)=>{
					try {
						const result = userFunc.apply(null, callArgs);
						callback(result);
					} catch (ex) {
						errorCallback(ex);
					}
				}
			};
			// exports the api to the application environment
			application.setInterface(api);
		`;
		const plugin = GetPluginForCode(code);
		plugin.onConnected = ()=>{
			try {
				//const result = plugin.remote.userFunc(...callArgs);
				plugin.remote.callAndReportResult(callArgs,
					result=>{
						//console.log("Got result from user-script:", result);
						resolve(result);
					},
					error=>{
						//console.log("Got error from user-script:", error);
						if (wrapErrorMessages) error = WrapErrorMessage(error, `Error from user-script (possibly custom message): `);
						reject(error);
					});
			} catch (ex) {
				if (wrapErrorMessages) ex = WrapErrorMessage(ex, `Wrapper/parsing error from user-script (possibly custom message): `);
				reject(ex);
			}
		};
		if (plugin.connected) {
			plugin.onConnected();
		}
	});
}

function WrapErrorMessage(error: any, prefixText: string) {
	if (typeof error == "object" && error instanceof Error) {
		/*error.name = ""; // remove the "ErrorType: " prefix, because: 1) it's redundant, 2) it could have been modified by the user-script to obscure our warning below
		Object.setPrototypeOf(error, Error.prototype);
		error.message = prefixText + error.message;*/
		//error.toString = function() { return prefixText + Error.prototype.toString.call(this); };

		// due to variance in how browsers stringify errors, we'll just stringify it ourselves (else we can't guarantee our prefix-text shows up first)
		return prefixText + StringifyError(error, false);
	}
	return prefixText + error;
}