import {emptyArray} from "js-vextensions";
import {StoreAccessor, GetDocs, GetDoc, WhereOp} from "mobx-firelink";
import {Entity} from "./entities/@Entity";
import {Me, MeID} from "./users.js";

export const GetEntity = StoreAccessor(s=>(id: string|n): Entity|n=>{
	return GetDoc({}, a=>a.entities.get(id!))?.Cast(Entity);
});
export const GetEntities = StoreAccessor(s=>(): Entity[]=>{
	return GetDocs({
		queryOps: [
			new WhereOp("visibleToGroups", "array-contains-any", [...(MeID() ? [MeID()] : []), "public"]),
		],
	}, a=>a.entities).map(a=>a?.Cast(Entity));
});
export const GetEntities_WithUserTag = StoreAccessor(s=>(tagName: string|n): Entity[]=>{
	const entities = GetEntities();
	const userEntityTags = Me()?.entityTags?.entities ?? {};
	return entities.filter(a=>userEntityTags[a._key]?.includes(tagName as any));
});

export function ExcludeMetaEntitiesFromList(entities: Entity[]) {
	return entities.filter(a=>!IsMetaEntity(a));
}
export function IsMetaEntity(entity: Entity) {
	return entity.name.startsWith("[") && entity.name.endsWith("]");
}

export function IsMetaTerm(term: string) {
	return term.startsWith("[") && term.endsWith("]");
}