import {XYZ} from "muse-js";
import {GyroProcessingLevel} from "../../../../UI/@Shared/Processors/GyroProcessor";

export const Muse_gyroSamplesPerSecond_raw = 51.2; // consistently observed, in what appears to be 256 samples per 5s, fsr (this page's 52 is wrong/imprecise: https://mind-monitor.com/FAQ.php)
export const GyroSet_sampleCount = 3; // consistently observed

/** Warning: Interval is small enough (19.53125ms), that timestamp+interval addition results in rounding. See SampleHelpers.ts for more info. */
export const GyroSample_interval = 1000 / Muse_gyroSamplesPerSecond_raw; // 19.53125ms

export type GyroSet = {
	index: number; // from "sequenceId" prop
	//resolveTime: number;
	sourceTime: number;
	processTime: number;
	samples: XYZ[];
};
export type GyroSample = XYZ & {
	time: number;
	// added during processing (not displayed)
	alignedIndex: number; // can be -1, if sample came before first eeg sample
	//time_uplot_aligned: number; // can be null, if sample came before first eeg sample
	processingLevel: GyroProcessingLevel;
	x_sumOverNormalizationWindow: number;
	y_sumOverNormalizationWindow: number;
	z_sumOverNormalizationWindow: number;
	x_sumForSmoothing: number;
	y_sumForSmoothing: number;
	z_sumForSmoothing: number;
	//time: number;
	x_norm: number;
	y_norm: number;
	z_norm: number;
	x_smoothed: number;
	y_smoothed: number;
	z_smoothed: number;

	// added during processing (displayed)
	/*x_final: number;
	y_final: number;
	z_final: number;*/
	//combinedDeviation: number;
	triggerSamplePercent: number; // not *yet* displayed
};
export type GyroListener = (sample: GyroSample)=>void;