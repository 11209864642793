import {Command, AssertV} from "mobx-firelink";
import {GetTestSegment} from "../../Store/firebase/testSegments";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";
import {GetEntity} from "../../Store/firebase/entities";

export class DeleteEntity extends Command<{id: string}> {
	Validate() {
		const {id} = this.payload;
		const oldData = GetEntity(id);
		AssertV(IsUserCreatorOrMod(this.userInfo?.id, oldData), "Insufficient permissions.");
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`entities/${id}`] = null;
		return updates;
	}
}