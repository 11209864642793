import {AddSchema, AssertValidate, GetSchemaJSON, NewSchema, Command_Old, GetAsync, Command, AssertV} from "mobx-firelink";
import {GetUser} from "../../Store/firebase/users";
import {Clone, DeepSet} from "js-vextensions";
import {User} from "../../Store/firebase/users/@User";

type MainType = User;
const MTName = "User";

export const EffectGroup_values = ["sounds", "shakes", "lights", "scripts"] as const;
export type EffectGroup = typeof EffectGroup_values[number];
export const ContentGroup_values = ["scenes", "entities"] as const;
export type ContentGroup = typeof ContentGroup_values[number];
export type TagTargetGroup = EffectGroup|ContentGroup;

//export class SetUserData extends Command<{id: string, updates: Partial<MainType>, allowPrevious?: boolean}, {}> {
export class SetUserEntityTags extends Command<{entityGroup: TagTargetGroup, entityID: string, entityTags: string[]}, {}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		const {entityGroup, entityID, entityTags} = this.payload;
		AssertV(this.userInfo != null, "User must be signed in.");
		this.oldData = GetUser.NN(this.userInfo!.id);
		this.newData = {...this.oldData, entityTags: Clone(this.oldData.entityTags ?? {})};
		DeepSet(this.newData.entityTags, `${entityGroup}/${entityID}`, entityTags, "/", true);
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {} = this.payload;
		const updates = {};
		updates[`users/${this.userInfo!.id}`] = this.newData;
		return updates;
	}
}