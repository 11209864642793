import {O} from "web-vcore";
import {ignore} from "mobx-sync";
import {makeObservable} from "mobx";
import {SamplesGraphYType} from "../../UI/@Shared/NavBar/SettingsPanel/Transcribe/SamplesGraph.js";

export enum SettingsTab {
	general = "general",
	audio = "audio",
	transcribe = "transcribe",
	info = "info",
}

export enum DragDirection {
	leftToRight = "leftToRight",
	rightToLeft = "rightToLeft",
	either = "either",
}

export class SettingsState {
	constructor() { makeObservable(this); }

	@O tab = SettingsTab.general;

	@O audio = new AudioSettingsState();
	@O transcribe = new TranscribeSettingsState();

	@O lockOverlay_dragDirection = DragDirection.either;

	@O appStartupScriptTag = null as any;
	@O lightTagForReset = null as any;
	@O lightReapplyDelays = [] as number[];
	@O sequence_itemWait = 1;
	@O keyHoldDuration = 1;
	// on my device, the actual key-repeat threshold was *about* .125s, but I'll default it to .2s to be safe
	@O keyRepeatThreshold = .2;
	@O maxSampleTimeDrift = .5;
	@O testSegmentStartBuffer = 1;
	@O fakeSampleGeneratorSeed = -1;
	@O anthropicAPIKey = null as string|n;
	@O anthropicModel = "claude-3-5-sonnet-20240620";

	@O autoRestartService = true;
	@O sessionCrashCheck_enabled = true;
	@O sessionCrashCheck_interval = 5; // in minutes
	@O sessionJSWake_enabled = false;
	@O sessionJSWake_interval = 1; // in seconds // probably todo = remove this, since we fixed root problem (using fullWakeJS_repeatTimer)

	// android-specific
	// ==========

	@O android_showNavBar = false;
}

export enum AudioSettingsSubpanel {
	config = "config",
	files = "files",
}

export class AudioSettingsState {
	constructor() { makeObservable(this); }
	
	@O subpanel = AudioSettingsSubpanel.config;

	@O mainMicrophoneID = null as any;
	@O mainVoiceName = null as any;
	@O voiceSpeedMultiplier = 1;
	@O voiceOnRingtoneChannel = false;
	
	@O sessions_systemStartVolume_media_enabled = false;
	@O sessions_systemStartVolume_media = .5; // currently only applied on Android
	@O sessions_systemStartVolume_call_enabled = false;
	@O sessions_systemStartVolume_call = .5; // currently only applied on Android
	@O sessions_systemStartVolume_ringtone_enabled = false;
	@O sessions_systemStartVolume_ringtone = .5; // currently only applied on Android
	/*@O sessions_systemStartVolume_notification_enabled = false;
	@O sessions_systemStartVolume_notification = .5; // currently only applied on Android
	@O sessions_systemStartVolume_alarm_enabled = false;
	@O sessions_systemStartVolume_alarm = .5; // currently only applied on Android*/

	// from testing, either 1 or 1.5 seems to be the most perceptually-linear (at least around orig-volume range of ~20%)
	// (1.5 actually seems best, HOWEVER, it might differ based on eg. audio frequency/pitch -- and I don't want a non-int default unless confirmed)
	// (confirm by: eg. record TTS as sound, play both, match loudness, adjust media-player curve till any volume +/- preserves loudness match)
	@O loudnessCurve_mediaPlayer = 1;
	// from testing, ~2 seems to be the most perceptually-linear (at least around orig-volume range of ~20%)
	// (I know at least that it is *way* more perceptually-linear than 1, ie. the default for the android TTS engine)
	@O loudnessCurve_systemTTS = 2;

	@O audioFilesFilter = "";
}

export enum TranscribeSettingsSubpanel {
	config = "config",
	model = "model",
	test = "test",
}

export class TranscribeSettingsState {
	constructor() { makeObservable(this); }

	@O subpanel = TranscribeSettingsSubpanel.config;

	// config
	@O chunk_minDuration = 5; // in seconds; min duration of chunk to send to whisper to transcribe
	@O chunk_maxDuration = 30; // in seconds; max duration of chunk to send to whisper to transcribe (30s is the hard-max)
	@O chunk_minPeakVolume = 0; // range: 0 (no sound at all) - 1 (mic-samples maxed out)
	//@O chunk_minAverageVolume = 0; // range: 0 (no sound at all) - 1 (mic-samples maxed out)
	@O silence_minDuration = 2; // in seconds
	@O silence_maxPeakVolume = .01; // range: 0 (no sound at all) - 1 (mic-samples maxed out)
	//@O silence_maxAverageVolume = .002; // range: 0 (no sound at all) - 1 (mic-samples maxed out)
	@O excludeEffectNotations = true;
	@O voice_avoidTranscribePadding = 3; // in seconds

	// model
	// (none; data is read/written on android side)

	// test
	@O graphTimeToShow = 30; // in seconds
	@O yType = SamplesGraphYType.absAvg;
	@O smoothing = 0;
	@O @ignore samplesGraph_active = false;
}