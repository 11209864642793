import {Clone, GetEntries} from "js-vextensions";
import {Button, Column, Row, Select} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {GetUpdates, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {UpdateSession} from "../../../Server/Commands/UpdateSession.js";
import {EngineSessionInfo} from "../../../Store/firebase/sessions/@EngineSessionInfo";
import {store} from "../../../Store/index.js";
import {GetSelectedSessionData, SessionUITab} from "../../../Store/main/timeline.js";
import {SessionUI_DetailsUI} from "./SessionUI/DetailsUI.js";
import {SessionUI_EventsUI} from "./SessionUI/EventsUI.js";
import {SessionUI_GeneralUI} from "./SessionUI/GeneralUI.js";

export type SessionChangerFunc = (session: EngineSessionInfo)=>any;
export type SessionUI_SharedProps = {session: EngineSessionInfo, ChangeSession: (changerFunc: SessionChangerFunc)=>void};

@Observer
export class SessionUI extends BaseComponentPlus({} as {session: EngineSessionInfo}, {}) {
	render() {
		const {session} = this.props;
		const uiState = store.main.timeline.sessions;
		if (GetSelectedSessionData()?.sessionID != uiState.selectedSession) return; // if derivative-data hasn't loaded yet, wait

		const ChangeSession = (changerFunc: SessionChangerFunc)=>{
			const newSession = Clone(session);
			changerFunc(newSession);
			new UpdateSession({id: session.id, updates: GetUpdates(session, newSession, true)}).Run();
		};

		const sharedProps = {session, ChangeSession};
		return (
			<Column style={{height: "100%"}}>
				<Row>
					<Button text="Back" onClick={()=>{
						RunInAction("SessionUI.Back.onClick", ()=>uiState.selectedSession = null);
					}}/>
					<Select ml={5} displayType="button bar" options={GetEntries(SessionUITab, "ui")} value={uiState.tab} onChange={val=>RunInAction_Set(this, ()=>uiState.tab = val)}/>
					{/*<Row ml="auto" mr={5}>
						<CheckBox text="Detail panel" value={uiState.showDetailPanel} onChange={val=>RunInAction_Set(this, ()=>uiState.showDetailPanel = val)}/>
						<CheckBox ml={10} text="Settings" enabled={uiState.showDetailPanel} value={uiState.showDetailPanelSettings} onChange={val=>RunInAction_Set(this, ()=>uiState.showDetailPanelSettings = val)}/>
						<CheckBox ml={10} text="Seek-bar" value={uiState.showSeekBar} onChange={val=>RunInAction_Set(this, ()=>uiState.showSeekBar = val)}/>
					</Row>*/}
				</Row>
				{uiState.tab == "general" && <SessionUI_GeneralUI {...sharedProps}/>}
				{uiState.tab == "details" && <SessionUI_DetailsUI {...sharedProps}/>}
				{uiState.tab == "events" && <SessionUI_EventsUI {...sharedProps}/>}
			</Column>
		);
	}
}

/*function SleepStageTest() {
	// get only first hour of data, for now
	const samples_left_simple = new Float32Array(sessionProcessor_orig.eegProcessor.samples_left); //.slice(0, Muse_eegSamplesPerSecond_raw * 60 * 60));
	const samples_right_simple = new Float32Array(sessionProcessor_orig.eegProcessor.samples_right); //.slice(0, Muse_eegSamplesPerSecond_raw * 60 * 60));
	const samples_left_ear_simple = new Float32Array(sessionProcessor_orig.eegProcessor.samples_left_ear); //.slice(0, Muse_eegSamplesPerSecond_raw * 60 * 60));
	const samples_right_ear_simple = new Float32Array(sessionProcessor_orig.eegProcessor.samples_right_ear); //.slice(0, Muse_eegSamplesPerSecond_raw * 60 * 60));

	const blockSleepStages = CalculateSleepStagesForEEGSamples(samples_left_simple, samples_right_simple, samples_left_ear_simple, samples_right_ear_simple);
	console.log("BlockSleepStages:", blockSleepStages);
	// 3: awake, 2: rem, 1: light, 0: deep (from: extractSleepStagesUserSession)
}*/