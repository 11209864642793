import {Switch} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ProposalsUI} from "../UI/Feedback/ProposalsUI";
import {Observer, SubNavBarButton, SubNavBar} from "web-vcore";
import {store} from "../Store/index.js";

@Observer
export class FeedbackUI extends BaseComponent<{}, {}> {
	render() {
		const currentSubpage = store.main.feedback.subpage;
		const page = "feedback";
		return (
			<>
				{/*<SubNavBar_Auto page={page}/>*/}
				<SubNavBar>
					<SubNavBarButton page={page} subpage="proposals" text="Proposals"/>
					{/* <SubNavBarButton page={page} subpage="roadmap" text="Roadmap"/>
					<SubNavBarButton page={page} subpage="neutrality" text="Neutrality"/> */}
				</SubNavBar>
				<Switch>
					<ProposalsUI/>
					{/*currentSubpage == "roadmap" && <RoadmapUI/>}
					{currentSubpage == "neutrality" && <NeutralityUI/>*/}
				</Switch>
			</>
		);
	}
}