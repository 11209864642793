import {HSL, HSLA} from "web-vcore";

// use "Color Highlight" vscode extension to preview the colors (with "dot-after" marker-type)
const eegActivityLine = `hsl(200,100%,50%)`;
export const sessionChartColors = {
	// lines
	// ==========

	/*eeg_left: `hsl(0,0%,100%)`,
	eeg_right: `hsl(0,0%,50%)`,*/
	eeg_left: `hsl(0,100%,50%)`,
	eeg_right: `hsl(60,100%,50%)`,
	eeg_left_ear: `hsl(250,100%,50%)`,
	eeg_right_ear: `hsl(280,100%,50%)`,
	eegActivity: eegActivityLine,
	//sleepStage: `hsl(230,100%,50%)`,
	sleepStage: `hsl(0,100%,50%)`,

	gyro_x: `hsl(0,100%,50%)`,
	gyro_y: `hsl(30,100%,50%)`,
	gyro_z: `hsl(60,100%,50%)`,
	gyro_combinedDeviation: `hsl(300,100%,50%)`,
	gyro_triggerSamplePercent: `hsl(120,100%,50%)`,

	// annotations
	// ==========

	baseline: `hsla(0,0%,100%,.3)`,
	currentTimeLine: `hsl(60,100%,50%)`,
	viewRangeRegion: "hsla(0,0%,100%,.3)",
	gyroMotionColor: `hsla(240,100%,70%,1)`,
	testSegmentRegion: `hsla(60,50%,50%,.05)`,
	camRecordingRegion: `hsla(60,100%,50%,.1)`,
	eyeMoveDeclaration_required: `hsla(240,100%,70%,1)`,
	eyeMoveDeclaration_optional: `hsla(39,100%,50%,1)`,
	eyeMoveDetection_passed: `hsla(120,100%,50%,.07)`,
	eyeMoveDetection_failed: `hsla(0,100%,50%,.07)`,

	// special (ie. bases)
	promptThreshold_baseColor: eegActivityLine, // for now, have color match "EEG activity" line
};
export function GetPromptThresholdColor(index: number, promptCount: number) {
	return sessionChartColors.promptThreshold_baseColor;
	// todo: make this adjust base-color based on index
}