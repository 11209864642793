import {runInAction} from "mobx";
import {observer} from "mobx-react";
import {Button, CheckBox, Row, RowLR, Spinner, Text, TimeSpanInput} from "react-vcomponents";
import {ScrollView} from "react-vscrollview";
import {store} from "../../../../../Store/index.js";
import {EngineSessionInfo} from "../../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {SetCurrentTime} from "../../../../../Store/main/timeline.js";
import {InfoButton, RunInAction, RunInAction_Set} from "web-vcore";
import {useEffect, useState} from "react";
import {Timer} from "js-vextensions";
import {GetSessionProcessor_Active} from "../../../../@Shared/Processors/@ProcessorInstances.js";
import {VideoRecordingPlayer_instanceInfo} from "./VideoRecordingPlayer.js";
import {BaseComponent} from "react-vextensions";

function AdjustTime(session: EngineSessionInfo, adjustment: number) {
	const uiState = store.main.timeline.sessions;
	RunInAction("AdjustTime", ()=>{
		//const newTime = (uiState.currentTime + adjustment).KeepBetween(session.startTime, session.endTime);
		const newTime = uiState.currentTime + adjustment;
		//uiState.currentTime = newTime;
		SetCurrentTime(newTime, session);
	});
}

function TimeShiftRow(p: {session: EngineSessionInfo, text: string, shift: number}) {
	const {session, text, shift} = p;
	return (
		<Row mt={5}>
			<Button width={75} text={`<- ${text}`} onClick={()=>AdjustTime(session, -shift)}/>
			<Button ml={5} width={75} text={`-> ${text}`} onClick={()=>AdjustTime(session, shift)}/>
		</Row>
	);
}

export class DetailPanelSettingsUI extends BaseComponent<{session: EngineSessionInfo}, {}> {
	render() {
		const {session} = this.props;
		const uiState = store.main.timeline.sessions;
		const sessionProcessor = GetSessionProcessor_Active();

		const [rawProcessingProgress, setRawProcessingProgress] = useState<number>(0);
		const [simProcessingProgress, setSimProcessingProgress] = useState<number>(0);
		useEffect(()=>{
			//let lastTick_processingProgress = detailPanelEEGProcessor.ProcessingProgress;
			const timer = new Timer(250, ()=>{
				if (sessionProcessor.RawProcessing_Progress != rawProcessingProgress) {
					setRawProcessingProgress(sessionProcessor.RawProcessing_Progress);
				}
				if (sessionProcessor.Simulation_Progress != simProcessingProgress) {
					setSimProcessingProgress(sessionProcessor.Simulation_Progress);
				}
			}).Start();
			return ()=>timer.Stop();
		});

		const splitAt = 170;
		return (
			<ScrollView ml={5} style={{width: 280}}>
				<Row>
					<Text style={{fontSize: 18}}>Data</Text>
					{!sessionProcessor.processingComplete &&
						//<Text ml={10} style={{fontSize: 12}}>(processing: {detailPanelEEGProcessor.ProcessingProgress.ToPercentStr(0)})</Text>}
						<Text ml={5} style={{fontSize: 12}}>(processing: {rawProcessingProgress.ToPercentStr(0)}, simulating: {simProcessingProgress.ToPercentStr(0)})</Text>}
				</Row>
				<Row mt={5} center>
					<CheckBox text="Use new config" value={uiState.processing_useCurrentConfig} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.processing_useCurrentConfig = val);
					}}/>
					<InfoButton ml={5} text={`
						If selected, the raw eeg/gyro data is reprocessed according to the currently selected engine-config, rather than the engine-config stored in the loaded session.

						The session data is then also resimulated according to the currently-selected engine-config.

						This lets you preview the effects of changed processing settings, on an existing night's data.

						Caveats:
						* The resimulation may be incorrect if some of the relevant data was not recorded. (eg. gyro-data can affect eeg-activity simulation)
						* User-scripts (and effects in general) are not rerun during resimulation, so any operation-manipulating effects they had won't be re-performed.
					`.AsMultiline(0)}/>
					{/*<CheckBox ml={10} text="Resim" enabled={uiState.processing_useCurrentConfig && sessionProcessor.processingComplete}/>*/}
				</Row>
				<Row mt={5} center>
					<CheckBox text="Normalize" value={uiState.eeg_normalize} onChange={val=>RunInAction_Set(this, ()=>uiState.eeg_normalize = val)}/>
					<InfoButton ml={5} text="Whether to display the left/right eeg channels with normalization applied. (normalization settings retrieved from session config's eeg section)"/>
				</Row>
				<Row mt={5} center>
					<CheckBox text="Smooth" value={uiState.eeg_smooth} onChange={val=>RunInAction_Set(this, ()=>uiState.eeg_smooth = val)}/>
					<InfoButton ml={5} text="Whether to display the left/right eeg channels with smoothing applied. (smoothing settings retrieved from session config's eeg section)"/>
				</Row>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Processing cpu-limit:</Text>
						<InfoButton ml={5} text="Max percentage of the cpu-time to use for background processing of the eeg-data. (starts at view-center and progresses in both directions)"/>
					</Row>
					<Spinner min={1} max={100} enforceRange={true} style={{width: 50}}
						value={uiState.processing_cpuLimit.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.processing_cpuLimit = val.FromPercent())}/>
					<Text ml={3}>%</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Simulation cpu-limit:</Text>
						<InfoButton ml={5} text={`
							Max percentage of the cpu-time to use for background resimulation of the eeg-data. (also does raw-processing, if not yet done so)

							Note that simulation processing always progresses sequentially. (thus, setting processing cpu-limit of 0 will make raw-processing go purely start-to-end as well)
						`.AsMultiline(0)}/>
					</Row>
					<Spinner min={1} max={100} enforceRange={true} style={{width: 50}}
						value={uiState.simulation_cpuLimit.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.simulation_cpuLimit = val.FromPercent())}/>
					<Text ml={3}>%</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Rendering cpu-limit:</Text>
						<InfoButton ml={5} text="Max percentage of the cpu-time to use for rendering UI updates (eg. chart refreshes), while processing is active."/>
					</Row>
					<Spinner min={1} max={100} enforceRange={true} style={{width: 50}}
						value={uiState.rendering_cpuLimit.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.rendering_cpuLimit = val.FromPercent())}/>
					<Text ml={3}>%</Text>
				</RowLR>

				<Row mt={15} style={{fontSize: 18}}>Navigation</Row>
				<Row mt={5}>
					<Text>Video:</Text>
					<CheckBox ml={10} text="Show" value={uiState.video_show} onChange={val=>RunInAction_Set(this, ()=>uiState.video_show = val)}/>
					<CheckBox ml={10} text="Auto-play" value={uiState.video_autoPlay} onChange={val=>RunInAction_Set(this, ()=>uiState.video_autoPlay = val)}/>
				</Row>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Playback speed:</Text>
					<Spinner value={uiState.playbackSpeed} step={.25} max={16} // max: 16 (Electron, 2020-10-21)
						onChange={val=>RunInAction_Set(this, ()=>uiState.playbackSpeed = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Camera latency:</Text>
						<InfoButton ml={5} text={`
							Enter your camera's stream-latency here; estimate by waving, on Tools/Monitor/Camera page. (or compare video and graph timestamps, assuming device clocks match [unlikely])
							
							Playback will then correct for that latency, by considering videos to have started/ended X seconds earlier than the original reception times.
						`.AsMultiline(0)}/>
					</Row>
					<TimeSpanInput style={{width: 60}} largeUnit={undefined} value={uiState.videoLatency} min={0} onChange={val=>RunInAction_Set(this, ()=>uiState.videoLatency = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Max view-range:</Text>
						<InfoButton ml={5} text="The max view-range length of the detail-panel overlay, in minutes/seconds."/>
					</Row>
					<TimeSpanInput style={{width: 60}} value={uiState.maxViewRange} min={1} onChange={val=>RunInAction_Set(this, ()=>uiState.maxViewRange = val)}/>
					<Button ml={5} p="1px 7px" text="Go" onClick={()=>SetCurrentTime(uiState.currentTime, session, {adjustViewRange: "force", viewRangeOverride: uiState.maxViewRange * 1000})}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Initial view-range:</Text>
						<InfoButton ml={5} text="The initial view-range length of the detail-panel overlay, in minutes/seconds."/>
					</Row>
					<TimeSpanInput style={{width: 60}} value={uiState.initialViewRange} min={1} onChange={val=>RunInAction_Set(this, ()=>uiState.initialViewRange = val)}/>
					<Button ml={5} p="1px 7px" text="Go" onClick={()=>SetCurrentTime(uiState.currentTime, session, {adjustViewRange: "force", viewRangeOverride: uiState.initialViewRange * 1000})}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Move time with video:</Text>
						<InfoButton ml={5} text="Whether to move the current-time forward, as a recorded video segment is played."/>
					</Row>
					<CheckBox value={uiState.moveTimeWithVideo} onChange={val=>RunInAction_Set(this, ()=>uiState.moveTimeWithVideo = val)}/>
					<Button ml={5} p="1px 7px" text="Go" onClick={()=>{
						const {videoEl, period} = VideoRecordingPlayer_instanceInfo ?? {};
						if (videoEl && period) {
							const newTime = period.start + (videoEl.currentTime * 1000);
							SetCurrentTime(newTime, session, {fromVideoPlayer: true});
						}
					}}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Move view with time:</Text>
						<InfoButton ml={5} text="Whether to move the view-range forward/backward, as the current-time increases/decreases. (eg. from seek-bar click)"/>
					</Row>
					<CheckBox value={uiState.moveViewWithTime} onChange={val=>RunInAction_Set(this, ()=>uiState.moveViewWithTime = val)}/>
					<Button ml={5} p="1px 7px" text="Go" onClick={()=>SetCurrentTime(uiState.currentTime, session, {adjustViewRange: "force"})}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Anchor-pos for time:</Text>
						<InfoButton ml={5} text="When a new current-time is set (eg. using seek bar), and the view-range is updated, what portion of the view-range should be prior to the current-time."/>
					</Row>
					<Spinner min={0} max={100} enforceRange={true} style={{width: 50}}
						value={uiState.viewAnchorPosForTime.ToPercent()} onChange={val=>RunInAction_Set(this, ()=>uiState.viewAnchorPosForTime = val.FromPercent())}/>
					<Text ml={3}>%</Text>
				</RowLR>
				<TimeShiftRow session={session} text="10s" shift={10000}/>
				<TimeShiftRow session={session} text="30s" shift={30000}/>
				<TimeShiftRow session={session} text="1m" shift={60000}/>
				<TimeShiftRow session={session} text="5m" shift={5 * 60000}/>
				<TimeShiftRow session={session} text="10m" shift={10 * 60000}/>

				{/*<Row mt={15} style={{fontSize: 18}}>Seek bar</Row>
				<Row mt={5}>
					<Text>Video:</Text>
					<CheckBox ml={10} text="Show" value={uiState.video_show} onChange={val=>RunInAction_Set(this, ()=>uiState.video_show = val)}/>
					<CheckBox ml={10} text="Auto-play" value={uiState.video_autoPlay} onChange={val=>RunInAction_Set(this, ()=>uiState.video_autoPlay = val)}/>
				</Row>*/}
			</ScrollView>
		);
	}
}