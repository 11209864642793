import {GetValues_ForSchema} from "js-vextensions";
import {AddSchema} from "mobx-firelink";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {EffectVisibility} from "../sounds/@Sound";

export const LIFXBulb_DefaultEffectJSONFunctionStr = `
return {
	url: \`https://vproxy1.herokuapp.com/https://api.lifx.com/v1/lights/all/effects/breathe\`,
	method: \`POST\`,

	power_on: brightness >= .01,
	from_color: \`green brightness:\${brightness}\`,
	color: \`red brightness:\${brightness}\`,
	cycles: 99999,
	persist: false,
}
`.AsMultiline(0);

// For some reason, even when you call setColor with saturation "0", the hue still changes things!
// Through experimentation, a hue of 0.5 (ie. 50% around color-wheel) seems to result in approximately pure white. (at 0 saturation anyway)
export const kasa_hueForPureWhiteAt0Sat = .5;
export const KasaBulb_DefaultEffectJSONFunctionStr = `
return {
	calls: [
		{method: "setPowered", args: {powered: brightness >= .01}},
		{method: "setBrightness", args: {brightness: brightness * 100}},
		{method: "setColor", args: {hue: ${kasa_hueForPureWhiteAt0Sat}, saturation: 0}}
	]
}
`.AsMultiline(0);

export enum LightType {
	Screen = 5,
	LIFXBulb = 10,
	KasaBulb = 20,
	//WizBulb = 30,
	//WyzeBulb = 40,
}

export class Light {
	constructor(initialData: Partial<Light>) {
		this.Extend(initialData);
		Light.InitDefaults(this);
		this.Extend(initialData);
	}
	static InitDefaults(s: Light) {
		s.VSet({
			type: s.type ?? LightType.KasaBulb,
			visibility: s.visibility ?? EffectVisibility.Visible,
		});

		if (s.type == LightType.Screen) {
		} else if (s.type == LightType.LIFXBulb) {
			s.VSet({
				effectJSON_functionStr: LIFXBulb_DefaultEffectJSONFunctionStr,
			});
		} else if (s.type == LightType.KasaBulb) {
			s.VSet({
				effectJSON_functionStr: KasaBulb_DefaultEffectJSONFunctionStr,
			});
		}
	}

	_key: string;
	creator: string;
	createdAt: number;

	name: string;
	type: LightType;
	visibility: EffectVisibility;
	tags: string[];

	// shared
	temp: boolean;
	tempLength: number; // in seconds (-1 for persistent)
	/*loop: boolean;
	loopInterval: number;*/
	effectJSON_functionStr: string; // screen, lifx-bulb, and kasa-bulb

	// screen

	// lifx-bulb

	// kasa-bulb
}
AddSchema("Light", {
	properties: {
		creator: {type: "string"},
		createdAt: {type: "number"},

		name: {type: "string", pattern: name_general},
		type: {oneOf: GetValues_ForSchema(LightType)},
		visibility: {oneOf: GetValues_ForSchema(EffectVisibility)},
		tags: {items: {type: "string"}},

		temp: {type: "boolean"},
		tempLength: {type: "number"},
		effectJSON_functionStr: {type: "string"},
	},
});