import {O, RunInAction} from "web-vcore";
import {ignore} from "mobx-sync";
import {runInAction, autorun, computed, makeObservable} from "mobx";
import {store} from "../../Store/index.js";
import {EngineSessionInfo} from "../../Store/firebase/sessions/@EngineSessionInfo";
import {GetSessionEEGData, GetSessionGeneralHistory, GetSessionGyroSampleFiles} from "../../Utils/Bridge/Bridge_Preload";
import moment from "moment";
import {GetSession, GetOpenSession} from "../../Store/firebase/sessions";
import {OnStoreLoaded} from "../../Utils/General/GlobalHooks";
import {E, SleepAsync} from "js-vextensions";
import {StoreAccessor} from "mobx-firelink";
import {EEGProcessor} from "../../UI/@Shared/Processors/EEGProcessor";
import {FBAConfig} from "../../Store/firebase/fbaConfigs/@FBAConfig";
import {GetSelectedFBAConfig} from "../../Store/firebase/fbaConfigs";
import {GyroProcessor} from "../../UI/@Shared/Processors/GyroProcessor";
import {sessionProcessor_resim} from "../../UI/@Shared/Processors/@ProcessorInstances";
import {SamplesBySecond} from "../../Store/firebase/testSegments/@TestSegment";

export class TimelineState {
	constructor() { makeObservable(this); }
	@O subpage = "sessions";
	@O message: string;
	//@O activities = new ActivitiesState();
	@O sessions = new SessionsState();
}

export enum SessionUITab {
	general = "general",
	details = "details",
	events = "events",
	//dj = "dj",
}

/*export class ActivitiesState {
}*/
export type SessionDerivativeData_AllowingResim = SessionDerivativeData & {config: FBAConfig};
export class SessionsState {
	constructor() { makeObservable(this); }
	@O @ignore selectedSession: string|n; // don't persist selected-session for now (loading might be unwanted, and takes too long with no way to cancel)
	@O tab = SessionUITab.general;
	//@O showDetailPanel = true;
	//@O showDetailPanelSettings = true;
	//@O showSeekBar = true;

	// chart legend
	@O disabledSeries = [] as number[];

	// data
	@O eeg_normalize = true;
	@O eeg_smooth = true;
	@O @ignore processing_useCurrentConfig = false;
	/*@O @ignore processing_useCurrentConfig_resimData_currentProcessingStartTime: number;
	@O @ignore processing_useCurrentConfig_resimData_currentProcessingProgress: number;
	@O @ignore processing_useCurrentConfig_resimData: SessionDerivativeData_AllowingResim;
	@O @ignore processing_useCurrentConfig_resimData_use = false;*/
	@O processing_cpuLimit = .45;
	@O simulation_cpuLimit = .45;
	@O rendering_cpuLimit = .1;

	// navigation
	@O video_show = true;
	@O video_autoPlay = false;
	@O playbackSpeed = 1;
	@O videoLatency = 0;
	@O maxViewRange = 10 * 60; // in seconds
	@O initialViewRange = 60; // in seconds
	@O moveTimeWithVideo = true;
	@O moveViewWithTime = true;
	@O viewAnchorPosForTime = .75;

	//@O @ignore currentTime = 0;
	@O currentTime = 0;
	@O @ignore currentTime_nonVideoPlayerSets = 0; // convenience field (eg. so video-player can know if time-change was external and thus necessitates remount)
	@O viewRange_startTime = 0;
	@O viewRange_endTime = 0;
	//@ignore viewRange_changePendingEEGChartReflect = false;
}

let GetSelectedSessionData_lastResult: SessionDerivativeData|n;
export const IsSessionUIOpen = StoreAccessor(s=>()=>{
	return store.main.page == "timeline" && store.main.timeline.subpage == "sessions" && store.main.timeline.sessions.selectedSession != null;
});
export const GetSelectedSessionData = StoreAccessor(s=>()=>{
	const uiState = store.main.timeline.sessions;
	const sessionID = uiState.selectedSession;
	if (sessionID == null) return null;
	// if selected-session derivative-data isn't loaded yet, delay the loading till on a page that actually uses it
	if (GetSelectedSessionData_lastResult == null && !IsSessionUIOpen()) {
		return GetSelectedSessionData_lastResult;
	}

	const result = GetSessionDerivativeData(sessionID);
	GetSelectedSessionData_lastResult = result;
	return result;
});
export const GetSelectedSessionData_AllowingResim = StoreAccessor(s=>(): SessionDerivativeData_AllowingResim|n=>{
	const uiState = store.main.timeline.sessions;
	const sessionID = uiState.selectedSession;
	const session = GetSession(sessionID);
	if (session == null) return null;

	const realData = E(GetSelectedSessionData(), {config: session.config});
	if (store.main.timeline.sessions.processing_useCurrentConfig) {
		return E(realData, sessionProcessor_resim.GetSessionDataOverrides(realData));
	}
	return realData;
});

export type SessionDerivativeData = Exclude<ReturnType<typeof GetSessionDerivativeData>, null>;
export const GetSessionDerivativeData = StoreAccessor(s=>(sessionID: string)=>{
	const session = GetSession(sessionID);
	const ableToLoadNewDerivativeData = session != null;
	// if we can't yet load in new derivative data, just clear that derivative data
	if (!ableToLoadNewDerivativeData) return null;

	const generalHistory = GetSessionGeneralHistory(session._folderName) as GeneralHistory;
	const camRecordingPeriods = generalHistory.camFilenames.map(name=>{
		// example: 02.32.39-02.33.25 @Triggered(00.16-00.35).webm
		// Note that conversion of this string into a date-value, is wrong for some edge-cases (eg. DST).
		// 	But that's fine; those offset issues are rare, and are not worth the dev-time at this point.
		const periodMatch = name.match(/^(\d\d)\.(\d\d)\.(\d\d)-(\d\d)\.(\d\d)\.(\d\d) /)!;
		let sessionStartTime = moment(session.startTime);
		if (session.localOffsetFromUTC != null) { // behind conditional, in case user deleted the timezone-offset field
			sessionStartTime = sessionStartTime.utcOffset(session.localOffsetFromUTC);
		}
		let startTime = sessionStartTime.clone().set("h", periodMatch[1].ToInt()).set("m", periodMatch[2].ToInt()).set("s", periodMatch[3].ToInt());
		if (!startTime.valueOf().IsBetween(session.startTime, session.endTime + 10000)) startTime = startTime.add("day", 1);
		let endTime = sessionStartTime.clone().set("h", periodMatch[4].ToInt()).set("m", periodMatch[5].ToInt()).set("s", periodMatch[6].ToInt());
		if (!endTime.valueOf().IsBetween(session.startTime, session.endTime + 10000)) endTime = endTime.add("day", 1);
		return {start: startTime.valueOf(), end: endTime.valueOf()};
	});
	const camRecordingPeriods_adjustedArrays = {}; // we need to return the same array each time (for a given video-latency), so store a map
	const CamRecordingPeriods_Adjusted = (videoLatencyInS: number)=>{
		if (camRecordingPeriods_adjustedArrays[videoLatencyInS] == null) {
			camRecordingPeriods_adjustedArrays[videoLatencyInS] = camRecordingPeriods.map(a=>({start: a.start - (videoLatencyInS * 1000), end: a.end - (videoLatencyInS * 1000)}));
		}
		return camRecordingPeriods_adjustedArrays[videoLatencyInS];
	};

	//uiState.eegSampleFiles = GetSessionEEGSamples_LeftAndRightFiles(session._folderName);
	//const eegData = GetSessionEEGData(session._folderName, true);
	const eegData = GetSessionEEGData(session._folderName); // sleep-stage calculation disabled for now, since the model doesn't seem good enough to be useful atm
	function EEGOrGyroArrayToData(array: Float64Array) {
		const result = {samplesBySecond: {} as SamplesBySecond};
		let currentSecond_key: number, currentSecond_samples = [] as number[];
		for (let i = 0; i < array.length + 1; i++) {
			const val = array[i] ?? 1_000_000; // 1-million for fake key at end, to prompt adding of last second's data
			if (val >= 1_000_000) {
				if (i > 0) {
					result.samplesBySecond[currentSecond_key!] = currentSecond_samples;
				}
				currentSecond_key = val;
				currentSecond_samples = [];
			} else {
				currentSecond_samples.push(val);
			}
		}
		return result;
	}
	const eegSampleFiles = {
		left: EEGOrGyroArrayToData(eegData.left),
		right: EEGOrGyroArrayToData(eegData.right),
		sleepStages: eegData.sleepStages,
	};

	const gyroSampleFiles_arrays = GetSessionGyroSampleFiles(session._folderName);
	const gyroSampleFiles = {x: EEGOrGyroArrayToData(gyroSampleFiles_arrays.x), y: EEGOrGyroArrayToData(gyroSampleFiles_arrays.y), z: EEGOrGyroArrayToData(gyroSampleFiles_arrays.z)};

	const result = {sessionID, generalHistory, camRecordingPeriods, CamRecordingPeriods_Adjusted, eegSampleFiles, gyroSampleFiles};
	return result;
});

export type GyroSampleFile = {
	samplesBySecond: SamplesBySecond;
};
export type EEGSampleFile = {
	samplesBySecond: SamplesBySecond;
};
export type GeneralHistory = {
	eegActivity: {activityByTime: {[key: string]: number}};
	gyroMotion: {motionsByTime: {[key: string]: boolean}};
	camFilenames: string[];
};

export function GetDefaultViewRangeForTime(time: number, session: EngineSessionInfo, viewRangeOverride?: number, shiftViewRangeToKeepInRange = false) {
	const uiState = store.main.timeline.sessions;
	const oldViewRangeInMS = uiState.viewRange_endTime - uiState.viewRange_startTime;
	//const viewRangeInMS = uiState.initialViewRange * 1000;
	const viewRangeInMS = viewRangeOverride ?? oldViewRangeInMS;

	let startTime = time - (viewRangeInMS * uiState.viewAnchorPosForTime);
	let endTime = (time + (viewRangeInMS * (1 - uiState.viewAnchorPosForTime))).KeepAtLeast(startTime + 1000);
	if (shiftViewRangeToKeepInRange) {
		const keepInRangeShifts = [
			KeepTimeInSessionRange(startTime, session) - startTime,
			KeepTimeInSessionRange(endTime, session).KeepAtLeast(startTime + 1000) - endTime,
		];
		const shiftToApply = keepInRangeShifts.OrderByDescending(shift=>Math.abs(shift))[0];
		startTime += shiftToApply;
		endTime += shiftToApply;
	}
	return {startTime, endTime};
}
//export function SetCurrentTime(time: number, session: EngineSessionInfo, adjustViewRange = true) {
export class SetCurrentTime_Options {
	adjustViewRange = "allow" as "force" | "allow" | "none";
	fromVideoPlayer = false;
	viewRangeOverride: number;
}
export function SetCurrentTime(time: number, session: EngineSessionInfo, opt?: Partial<SetCurrentTime_Options>) {
	opt = E(new SetCurrentTime_Options(), opt);
	const uiState = store.main.timeline.sessions;
	const adjustViewRange = opt.adjustViewRange == "force" || (opt.adjustViewRange == "allow" && uiState.moveViewWithTime);

	time = KeepTimeInSessionRange(time, session);
	RunInAction("SetCurrentTime", ()=>{
		opt = opt!;
		uiState.currentTime = time;
		if (!opt.fromVideoPlayer) {
			uiState.currentTime_nonVideoPlayerSets++;
		}
		if (adjustViewRange) {
			const newViewRange = GetDefaultViewRangeForTime(time, session, opt.viewRangeOverride, opt.viewRangeOverride != null);
			uiState.viewRange_startTime = newViewRange.startTime;
			uiState.viewRange_endTime = newViewRange.endTime;
			//uiState.viewRange_changePendingEEGChartReflect = true;
		}
	});
}
export function KeepTimeInSessionRange(time: number, session: EngineSessionInfo) {
	return time.KeepBetween(session.startTime, session.endTime);
}