import {InfoButton, minuteInMS, Observer, secondInMS, TextPlus} from "web-vcore";
import React from "react";
import {Button, CheckBox, Column, Row, RowLR, Select, Spinner, Text, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {GetLights_WithUserTag} from "../../../../Store/firebase/lights.js";
import {ControlInput} from "../../../@Shared/ControlInput.js";
import {EffectPreviewButton} from "../../../@Shared/TagTargetEntryUI.js";
import {E, GetEntries} from "js-vextensions";
import {TranscribeTarget} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamTranscribe.js";
import {EnumerationSubmission, FBAConfig_Memory, OptionPoolLayer, OptionPoolOrdering, OptionPoolSource, SpeechMatchSubmission} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Memory.js";
import {PromptPanel_splitAt} from "./Alarms_ConfigUI.js";
import {GetCountries} from "../../../../Utils/Geography/CountryInfo.js";
import {ShowMessageBox} from "react-vmessagebox";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.memory, (c, v)=>c.memory = v);
type SharedProps = ReturnType<typeof GetSharedProps>;

const splitAt = 150;

@Observer
export class Memory_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Memory (solver)" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					{!client &&
					<>
						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text>Option-pool size:</Text>
								<InfoButton ml={5} text="If only one term-layer, the pool size is limited to that layer's number of items."/>
							</Row>
							<Spinner enabled={enabled} value={config.optionPoolSize} onChange={val=>ChangeConfig(c=>c.optionPoolSize = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Text>Option-pool ordering:</Text>
							<Select options={GetEntries(OptionPoolOrdering)} enabled={enabled} value={config.optionPoolOrdering} onChange={val=>ChangeConfig(c=>c.optionPoolOrdering = val)}/>
						</RowLR>
						<Row mt={5}>
							<Text>Option-pool source:</Text>
							<Select ml={10} displayType="button bar" options={GetEntries(OptionPoolSource)} enabled={enabled} value={config.optionPoolSource} onChange={val=>ChangeConfig(c=>c.optionPoolSource = val)}/>
						</Row>
						<Column ml={15}>
							{config.optionPoolSource == OptionPoolSource.Manual && <OptionPool_ManualUI {...sharedProps}/>}
							{config.optionPoolSource == OptionPoolSource.Geography && <OptionPool_GeographyUI {...sharedProps}/>}
						</Column>

						<RowLR mt={5} splitAt={splitAt}>
							<Text>On target hit:</Text>
							<Text>Trigger a snooze. (duration adjusted based on number of target misses this cycle)</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Text>On target miss:</Text>
							<Text>Decrease length of target-hit snooze by </Text>
							<TimeSpanInput enabled={enabled} value={config.targetMiss_snoozePenalty!} onChange={val=>ChangeConfig(c=>c.targetMiss_snoozePenalty = val)}/>
							<Text>, unless at minimum: </Text>
							<TimeSpanInput enabled={enabled} value={config.targetMiss_snoozeMin!} onChange={val=>ChangeConfig(c=>c.targetMiss_snoozeMin = val)}/>
						</RowLR>

						<RowLR mt={5} splitAt={splitAt}>
							<Row center>
								<Text>Min hint interval:</Text>
								<InfoButton ml={5} text="After a hint is revealed, how long until a new hint can be given."/>
							</Row>
							<TimeSpanInput enabled={enabled} value={config.minHintInterval!} onChange={val=>ChangeConfig(c=>c.minHintInterval = val)}/>
						</RowLR>
					</>}

					<RowLR mt={5} splitAt={splitAt}>
						<Text>Next hint:</Text>
						<ControlInput enabled={enabled} value={config.nextHint_triggerSet!} onChange={val=>ChangeConfig(c=>c.nextHint_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Previous hint:</Text>
						<ControlInput enabled={enabled} value={config.previousHint_triggerSet!} onChange={val=>ChangeConfig(c=>c.previousHint_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Give up on pick:</Text>
							<InfoButton ml={5} text="Give up on trying to find the target option. (thus activating the minimum snooze duration)"/>
						</Row>
						<ControlInput enabled={enabled} value={config.giveUp_triggerSet!} onChange={val=>ChangeConfig(c=>c.giveUp_triggerSet = val)}/>
					</RowLR>

					<EnumerationSubmissionUI {...sharedProps}/>
					<SpeechMatchSubmissionUI {...sharedProps}/>
				</>}
			</Column>
		);
	}
}

class OptionPool_ManualUI extends BaseComponent<{} & SharedProps, {}> {
	render() {
		const {enabled, config, ChangeConfig} = this.props;

		const sharedProps = this.props;
		return (
			<>
				<Row center mt={5} mb={3}>
					<Text>Term-layers:</Text>
					<InfoButton ml={5} text={`
						When a new memory-config cycle is activated, a new set of options is constructed. (with one designated as the "target")

						Each option consists of: [random term from layer 1] + [random term from layer 2] + [...]
					`.AsMultiline(0)}/>
				</Row>
				{(config.optionPoolLayers ?? []).map((layer, index)=>{
					return <OptionPoolLayerUI key={index} {...sharedProps} layer={layer} layers={config.optionPoolLayers ?? []}/>;
				})}
				<Row>
					<Button mt={2} p="5px 12px" enabled={enabled} text="Add option layer" style={{fontSize: 13}} onClick={()=>{
						ChangeConfig(p=>{
							p.optionPoolLayers ??= [];
							p.optionPoolLayers.push(new OptionPoolLayer());
						});
					}}/>
				</Row>
			</>
		);
	}
}
class OptionPool_GeographyUI extends BaseComponent<{} & SharedProps, {}> {
	render() {
		const {enabled, config, ChangeConfig} = this.props;
		if (config.optionPool_countriesEnabled == null) return null;

		const sharedProps = this.props;
		return (
			<>
				<Text>Hint-progression: Continent, quadrant(s) in continent, near country-A, near country-B, [...], starts with the letter ?</Text>
				<Text>Target: One of the countries enabled below.</Text>
				<Row style={{flexWrap: "wrap"}}>
					{GetCountries().OrderByDescending(a=>a.population).map((country, index)=>{
						const countryName = country.names.en;
						return <CheckBox key={countryName} ml={5} text={countryName} enabled={enabled} value={config.optionPool_countriesEnabled!.Contains(countryName)} onChange={val=>{
							ChangeConfig(c=>{
								if (val) c.optionPool_countriesEnabled!.push(countryName);
								else c.optionPool_countriesEnabled!.Remove(countryName);
							});
						}}/>;
					})}
				</Row>
			</>
		);
	}
}

class EnumerationSubmissionUI extends BaseComponent<{} & SharedProps, {}> {
	render() {
		const {client, enabled, config, ChangeConfig} = this.props;
		const subconfig = config.enumerationSubmission;
		if (subconfig == null) return null;
		const ChangeSubconfig = (subconfigChangerFunc: (subconfig: EnumerationSubmission)=>any)=>{
			ChangeConfig(c=>subconfigChangerFunc(c.enumerationSubmission!));
		};

		const splitAt = PromptPanel_splitAt;
		return (
			<>
				<Row mt={5} style={{fontSize: 15}}>
					<CheckBox text="Enumeration submission" enabled={enabled} value={subconfig.enabled} onChange={val=>ChangeSubconfig(c=>c.enabled = val)}/>
				</Row>
				{subconfig.enabled &&
				<Column ml={15}>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Submit pick:</Text>
						<ControlInput enabled={enabled} value={subconfig.submitPick_triggerSet} onChange={val=>ChangeSubconfig(c=>c.submitPick_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Next option:</Text>
						<ControlInput enabled={enabled} value={subconfig.nextOption_triggerSet} onChange={val=>ChangeSubconfig(c=>c.nextOption_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Previous option:</Text>
						<ControlInput enabled={enabled} value={subconfig.previousOption_triggerSet} onChange={val=>ChangeSubconfig(c=>c.previousOption_triggerSet = val)}/>
					</RowLR>
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						{/*<Text>Change-pick micro-snooze:</Text>*/}
						<Row center>
							<CheckBox enabled={enabled} style={{whiteSpace: "pre"}} labelStyle={{marginLeft: 5}} value={subconfig.changePick_snooze_enabled} onChange={val=>ChangeSubconfig(c=>c.changePick_snooze_enabled = val)}
								text={`
									Change-pick
									micro-snooze:
								`.AsMultiline(0)}/>
							{/*<Text ml={5}>:</Text>*/}
							<InfoButton ml={5} text={`
								Applies a special "micro" snooze when selecting the next/previous option or submitting your pick.

								Micro-snoozes merely pause the config-effects for the given duration, then resume the previous loudness, brightness, etc.
							`.AsMultiline(0)}/>
						</Row>
						<TimeSpanInput enabled={enabled} value={subconfig.changePick_snooze_duration} onChange={val=>ChangeSubconfig(c=>c.changePick_snooze_duration = val)}/>
					</RowLR>}
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<CheckBox enabled={enabled} style={{whiteSpace: "pre"}} labelStyle={{marginLeft: 5}} value={subconfig.changePick_snooze_enabled} onChange={val=>ChangeSubconfig(c=>c.changePick_snooze_enabled = val)}
								text={`
									Min pick
									interval:
								`.AsMultiline(0)}/>
							<InfoButton ml={5} text="After a pick is submitted, how long until a new pick can be submitted."/>
						</Row>
						<TimeSpanInput enabled={enabled} value={subconfig.minPickInterval} onChange={val=>ChangeSubconfig(c=>c.minPickInterval = val)}/>
					</RowLR>}
				</Column>}
			</>
		);
	}
}

class SpeechMatchSubmissionUI extends BaseComponent<{} & SharedProps, {}> {
	render() {
		const {client, enabled, config, ChangeConfig} = this.props;
		const subconfig = config.speechMatchSubmission;
		if (subconfig == null) return null;
		const ChangeSubconfig = (configChangerFunc: (config: SpeechMatchSubmission)=>any)=>{
			ChangeConfig(c=>configChangerFunc(c.speechMatchSubmission!));
		};

		const splitAt = 200;
		return (
			<>
				<Row center mt={5} style={{fontSize: 15}}>
					<CheckBox text="Speech-match submission" enabled={enabled} value={subconfig.enabled} onChange={val=>ChangeSubconfig(c=>c.enabled = val)}/>
					{/*<InfoButton ml={5} text={
						"Speech-matching currently only works for the digits 0-9. If your target has other items, speech-matching will be unable to hit it."
						//"Speech-matching currently only works for the digits 1, 2, 8, 9. If your target has other items, speech-matching will be unable to hit it."
					}/>*/}
				</Row>
				{subconfig.enabled &&
				<Column ml={15}>
					{client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Record audio on:</Text>
							<InfoButton ml={5} text={`Setting this to "Remote" currently only enables recording on the remote; it doesn't disable the host's recording. (If unwanted, just disable/mangle the host's listen/submit trigger.)`}/>
						</Row>
						<Select options={[{name: "Host (other device)", value: false}, {name: "Remote (this device)", value: true}]} enabled={enabled}
							value={subconfig.remote_recordAudioOnRemote} onChange={val=>ChangeSubconfig(c=>c.remote_recordAudioOnRemote = val)}/>
					</RowLR>}
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Listen / Submit pick:</Text>
							<InfoButton ml={5} text="If not listening, starts listening. Else, submits text from listen-segment (max 60s) as pick. (counts as hit if even *contains* the target)"/>
						</Row>
						<ControlInput enabled={enabled} value={subconfig.listenOrSubmitPick_triggerSet} onChange={val=>ChangeSubconfig(c=>c.listenOrSubmitPick_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Listening sound-tag:</Text>
							<InfoButton ml={5} text="When in listen mode, a sound with the given tag will be selected and played."/>
						</Row>
						<TextInput enabled={enabled} style={{width: 200}} value={subconfig.listen_backgroundSoundTag} onChange={val=>ChangeSubconfig(c=>c.listen_backgroundSoundTag = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Listening sound-volume: </Text>
						<Spinner enabled={enabled} min={0} max={100} value={(subconfig.listen_backgroundSoundVolume * 100).RoundTo(1)} onChange={val=>ChangeSubconfig(c=>c.listen_backgroundSoundVolume = val / 100)}/>
						<Text> %</Text>
					</RowLR>
					{/*<RowLR mt={5} splitAt={splitAt}>
						<Text>Max delay between items:</Text>
						<TimeSpanInput enabled={enabled} value={config.maxDelayBetweenItems} onChange={val=>ChangeConfig(c=>c.maxDelayBetweenItems = val)}/>
					</RowLR>*/}
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Valid-term sensitivity:</Text>
							<InfoButton ml={5} text={`
								How much to "boost" the sensitivity of the speech-recognizer to valid terms (those enabled in the option-pool term-layers).

								Recommended range: 0-20 (0 means no boost; beyond 20 tends to cause too many false-positives)
							`.AsMultiline(0)}/>
						</Row>
						<Spinner enabled={enabled} min={0} value={subconfig.validTermSensitivity} onChange={val=>ChangeSubconfig(c=>c.validTermSensitivity = val)}/>
					</RowLR>}
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Max chars between terms:</Text>
							<InfoButton ml={5} text={`
								The max number of characters to tolerate between the speaking of each term in the target sequence.
								(Recommended to keep 7+, due to speech-to-text mistakes, spaces between words, and digits that get inserted for sound-alike words.)
							`.AsMultiline(0)}/>
						</Row>
						<Spinner enabled={enabled} value={subconfig.maxCharsBetweenItems} onChange={val=>ChangeSubconfig(c=>c.maxCharsBetweenItems = val)}/>
					</RowLR>}
				</Column>}
			</>
		);
	}
}

class OptionPoolLayerUI extends BaseComponent<{layer: OptionPoolLayer, layers: OptionPoolLayer[]} & SharedProps, {}> {
	render() {
		const {client, enabled, layer, layers, config, ChangeConfig} = this.props;
		if (config.optionPoolLayers == null) return null;
		const index = layers.indexOf(layer);
		const ChangeLayer = (configChangerFunc: (layer: OptionPoolLayer)=>any)=>{
			ChangeConfig(c=>configChangerFunc(c.optionPoolLayers![index]));
		};

		return (
			<Row>
				<CheckBox enabled={enabled} title="Enabled" value={layer.enabled} onChange={val=>ChangeLayer(l=>l.enabled = val)}/>
				<TextInput ml={5} enabled={enabled && layer.enabled} style={{flex: 1}}
					value={(layer.items || []).join(", ")} onChange={val=>{
						const layerItems = val.split(",").map(a=>a.trim()).filter(a=>a.length);
						ChangeLayer(l=>l.items = layerItems);
					}}/>
				<Button p="3px 10px" enabled={enabled} text="X" style={{borderRadius: "0 5px 5px 0"}} onClick={()=>{
					ShowMessageBox({
						title: "Delete option layer?", cancelButton: true,
						message: "Permanently delete this option layer?",
						onOK: ()=>{
							ChangeConfig(c=>c.optionPoolLayers!.RemoveAt(index));
						},
					});
				}}/>
			</Row>
		);
	}
}