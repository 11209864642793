import {Range, SleepAsync, Timer} from "js-vextensions";
import Moment from "moment";
import {Button, CheckBox, Column, Row, Spinner, Text, TextArea} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {TimelineRow} from "../../UI/Timeline/Timeline/TimelineRow.js";
import {GetFBAccessToken, PostImageToFacebook} from "../../Utils/Services/Facebook.js";
import {PostImageToGooglePlus} from "../../Utils/Services/GooglePlus.js";
import {dataURItoBlob, TakeScreenshot} from "../../Utils/UI/ScreenshotTaker.js";
import {StopCurrentTimelineEvents} from "../../Server/Commands/StopCurrentTimelineEvents.js";
import {AddTimelineEvent} from "../../Server/Commands/AddTimelineEvent.js";
import {store} from "../../Store/index.js";
import {User} from "../../Store/firebase/users/@User.js";
import {Observer, RunInAction} from "web-vcore";
import {runInAction} from "mobx";
import {GetActivities} from "../../Store/firebase/activities.js";
import {Activity} from "../../Store/firebase/activities/@Activity.js";
import {TimelineEvent} from "../../Store/firebase/timelineEvents/@TimelineEvent.js";
import {MeID} from "../../Store/firebase/users.js";

function CalculateMainTimes(days, dayShift = 0) {
	var now = Moment();
	if (dayShift != 0) {
		now = now.add(dayShift, "days");
	}

	var rangeStart = now.clone().startOf("day").subtract(days, "day");
	var rangeEnd = now.clone().endOf("day");
	var dayStart = now.clone().startOf("day");
	//var dayEnd = now.clone().endOf("day");
	var dayEnd = dayStart.clone().add(1, "day");
	return {now, rangeStart, rangeEnd, dayStart, dayEnd};
}

@Observer
export class TimelineUI extends BaseComponentPlus({} as {user: User, days: number}, {dayShift: 0, screenshotMode: false, shareTo_FB: true, shareTo_GP: true}) {
	refreshTimer: Timer;
	ComponentDidMount() {
		// refresh every minute
		this.refreshTimer = new Timer(60 * 1000, ()=>this.Update()).Start();
	}
	ComponentWillUnmount() {
		this.refreshTimer.Stop();
	}

	render() {
		const {user, days} = this.props;
		const activities = GetActivities(MeID());
		const message = store.main.timeline.message;
		const {dayShift, screenshotMode, shareTo_FB, shareTo_GP} = this.state;
		const timeRanges = CalculateMainTimes(days, dayShift);
		const {now, rangeStart, rangeEnd, dayStart, dayEnd} = timeRanges;

		const rows = [] as JSX.Element[];
		for (let offset = -(days - 1); offset <= 0; offset++) {
			const startTime = dayStart.clone().add(offset, "days");
			const endTime = dayEnd.clone().add(offset, "days");
			rows.push(
				<TimelineRow key={startTime.toString()} {...{timelineID: user._key!, startTime: startTime.valueOf(), endTime: endTime.valueOf(),
					index: rows.length, last: offset == 0}}/>,
			);
		}

		return (
			<Row style={{height: "100%"}}>
				<Column id="graph" style={{position: "relative", /*margin: "20px auto 20px auto",*/ flex: 1, height: screenshotMode ? "" : "100%", filter: "drop-shadow(rgb(0, 0, 0) 0px 0px 10px)"}}>
					{screenshotMode &&
						<Row style={{padding: 10}}>
							<Row style={{fontSize: 25, fontWeight: 500, color: "white"}}>3000 Weeks</Row>
							{activities.map((activity, index)=>{
								return (
									<Row key={index} ml={index == 0 ? "auto" : 10} style={{padding: 7, background: `rgba(${activity.color})`, border: "1px solid black", color: "white"}}>
										{activity.name}
									</Row>
								);
							})}
						</Row>}
					<Header timeRanges={timeRanges} screenshotMode={screenshotMode}/>
					{screenshotMode && rows}
					{!screenshotMode &&
						<ScrollView style={{flex: 1}} contentStyle={{flex: 1}}>
							{rows}
						</ScrollView>}
				</Column>
				<Column style={{width: 300, height: "100%", padding: 5, background: "rgba(0,0,0,.5)"}}>
					<Column>
						<Row ml={3}>Set current activity:</Row>
						<Column mt={5}>
							{activities.map((activity, index)=>{
								return <Button key={index} text={activity.name} mt={index ? 5 : 0} onClick={async()=>{
									await new StopCurrentTimelineEvents({timelineID: user._key!}).Run();
									const event = new TimelineEvent({timeline: user._key, activity: activity._key, startTime: Date.now()});
									new AddTimelineEvent({event}).Run();
								}}/>;
							})}
							<Button text="None" mt={5} onClick={()=>{
								new StopCurrentTimelineEvents({timelineID: user._key!}).Run();
							}}/>
						</Column>
						{/*<Row ml={3} mt={10}>Actions:</Row>*/}
						<div style={{marginTop: 7, height: 1, background: "rgba(255,255,255,.3)"}}/>
						<Column mt={5}>
							<Row>
								<Text>Day shift: </Text>
								<Spinner min={-Infinity} value={dayShift} onChange={val=>this.SetState({dayShift: val})}/>
							</Row>
							<Row>
								<Text>Screenshot mode: </Text>
								<CheckBox value={screenshotMode} onChange={val=>this.SetState({screenshotMode: val})}/>
							</Row>
							<Row><Text>Message:</Text></Row>
							<Row>
								<TextArea value={message} onChange={val=>RunInAction("Timeline.message.onChange", ()=>store.main.timeline.message = val)}/>
							</Row>
							<Row mt={5}>
								<Text>Share to: </Text>
								<CheckBox text="Facebook" value={shareTo_FB} onChange={val=>this.SetState({shareTo_FB: val})}/>
								<CheckBox ml={5} text="Google+" value={shareTo_GP} onChange={val=>this.SetState({shareTo_GP: val})}/>
							</Row>
							<Button text="Take screenshot and share" mt={5} onClick={async()=>{
								this.SetState({screenshotMode: true});
								await SleepAsync(1000);
								const screenshotDataURI = await TakeScreenshot(document.querySelector("#graph"));
								this.SetState({screenshotMode: false});

								//window.open(screenshot, "_blank");
								//window.open(screenshot);

								if (shareTo_FB) {
									/*const screenshotBlob = dataURItoBlob(screenshotDataURI);
									const fbToken = await GetFBAccessToken();
									await PostImageToFacebook(fbToken, "3000 Weeks - Graph", "image/png", screenshotBlob, message);*/
									await PostImageToFacebook(screenshotDataURI, message);
								}

								if (shareTo_GP) {
									await PostImageToGooglePlus(screenshotDataURI, message);
								}
							}}/>
						</Column>
					</Column>
				</Column>
			</Row>
		);
	}
}

class Header extends BaseComponent<{timeRanges: any, screenshotMode: boolean}, {}> {
	/*refreshTimer: Timer;
	ComponentDidMount() {
		// refresh header every minute
		this.refreshTimer = new Timer(60 * 1000, ()=>this.Update()).Start();
	}
	ComponentWillUnmount() {
		this.refreshTimer.Stop();
	}*/

	render() {
		const {timeRanges, screenshotMode} = this.props;
		const {now, rangeStart, rangeEnd, dayStart, dayEnd} = timeRanges;

		const currentPercentThroughDay = now.diff(dayStart) / dayEnd.diff(dayStart);
		return (
			<div style={{position: "relative", height: 20, borderBottom: "1px solid rgba(255,255,255,.3)"}}>
				{Range(0, 23).map(hour=>{
					return (
						<Row key={hour} style={{position: "absolute", left: `${(hour / 24) * 100}%`, height: "100%"}}>
							<div style={{width: 1, height: "100%", background: "rgba(128,128,128,.25)"}}/>
							<div style={{marginLeft: 3, fontSize: 11}}>{hour}</div>
						</Row>
					);
				})}
				{!screenshotMode &&
					<div style={{position: "absolute", left: `calc(${currentPercentThroughDay * 100}% - 10px)`, width: 20, height: "100%"}}>
						<div className="triangle down" style={{backgroundColor: "rgba(200,200,200,1)"}}/>
					</div>}
			</div>
		);
	}
}