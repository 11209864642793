import {AddSchema} from "mobx-firelink";
import {TriggerSet} from "../@TriggerSet";

export class FBAConfig_Background {
	constructor(initialData: Partial<FBAConfig_Background>) { this.VSet(initialData); }

	enabled: boolean;
	tag?: string;
	startVolume: number; // 0-1

	increaseVolume_triggerSet: TriggerSet;
	increaseVolume_amount: number; // 0-1
	decreaseVolume_triggerSet: TriggerSet;
	decreaseVolume_amount: number; // 0-1
}
AddSchema("FBAConfig_Background", {
	properties: {
		enabled: {type: "boolean"},
		tag: {type: "string"},
		startVolume: {type: "number"},

		increaseVolume_triggerSet: {$ref: "TriggerSet"},
		increaseVolume_amount: {type: "number"},
		decreaseVolume_triggerSet: {$ref: "TriggerSet"},
		decreaseVolume_amount: {type: "number"},
	},
});