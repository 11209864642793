import {Annotation, AnnotationsPlugin, GroupedBarsPlugin, GroupedBarsPluginOptions} from "uplot-vplugins";
import {store} from "../../../../Store/index.js";
import {ShowBarText} from "./StatsChartOptions.js";

export function GetGroupedBarsPluginConfig(): GroupedBarsPluginOptions {
	const uiState = store.main.tools.journey.stats;
	return new GroupedBarsPluginOptions({
		color: !ShowBarText() ? "transparent" : "white",
		gapBetweenVisualGroups: .3,
		//gapBetweenVisualGroups: uiState.grouping == StatsGrouping.none ? .4 : .3, // add a bit larger gaps between visual-groups, if each only has one bar
		gapBetweenBars: 0,
	});
}

export class GroupedBarDisplayCalc {
	constructor(data: Pick<GroupedBarDisplayCalc, "xValueCount" | "dataGroupCount" | "config" | "plotAreaWidth">) {
		//Assert(data.config.visualGroupDistribution == "spaceBetween" && data.config.barDistribution == "spaceBetween", `GroupedBarsPluginOptions.visualGroupDistribution and barDistribution must be 'spaceBetween' for this function to work.`);
		Object.assign(this, data);

		const fixNaN = (fallback: number, val: number)=>{
			if (Number.isFinite(val)) return val;
			return fallback;
		};

		const visualGroupGapCount = this.xValueCount - 1;
		const percentOfPlotAreaWidthFor_visualGroups = 1 - this.config.gapBetweenVisualGroups;
		this.visualGroupWidth_pixels = fixNaN(0, this.plotAreaWidth * (percentOfPlotAreaWidthFor_visualGroups / this.xValueCount));
		this.visualGroupGap_pixels = fixNaN(0, this.plotAreaWidth * ((1 - percentOfPlotAreaWidthFor_visualGroups) / visualGroupGapCount));

		const withinEachVisualGroup_barGapCount = this.dataGroupCount - 1;
		const percentOfVisualGroupWidthFor_bars = 1 - this.config.gapBetweenBars;
		this.barWidth_pixels = fixNaN(0, this.visualGroupWidth_pixels * (percentOfVisualGroupWidthFor_bars / this.dataGroupCount));
		this.barGap_pixels = fixNaN(0, this.visualGroupWidth_pixels * ((1 - percentOfVisualGroupWidthFor_bars) / withinEachVisualGroup_barGapCount));

		/*const vals = [this.visualGroupWidth_pixels, this.visualGroupGap_pixels, this.barWidth_pixels, this.barGap_pixels];
		Assert(vals.every(a=>Number.isFinite(a)), "One of the vals is non-finite: " + vals);*/
	}
	xValueCount: number;
	dataGroupCount: number;
	config: GroupedBarsPluginOptions;
	plotAreaWidth: number;

	// calculated at init
	private visualGroupWidth_pixels: number;
	private visualGroupGap_pixels: number;
	private barWidth_pixels: number;
	private barGap_pixels: number;

	private GetPriorVisualGroupGaps_InPixels(priorVisualGroups: number) {
		return this.visualGroupGap_pixels * priorVisualGroups;
	}
	private GetPriorBarGaps_InPixels(priorVisualGroups: number, priorBarsInThisGroup: number) {
		const barGapsInPriorVisualGroups = priorVisualGroups * (this.dataGroupCount - 1); // - 1, since the first bar in each visual-group doesn't have a gap before it
		const barGapsInThisVisualGroup = priorBarsInThisGroup;
		return this.barGap_pixels * (barGapsInPriorVisualGroups + barGapsInThisVisualGroup);
	}

	/** The rect returned is horizontal only, and is relative to the uplot plotting-bounds. (ie. for drawing to uplot's canvas, add uplotInstance.bbox.left to the x-coordinate) */
	GetBarRect(thisVisualGroupIndex: number, thisBarIndexInVisualGroup: number) {
		const gapsBeforeThisBar_pixels = this.GetPriorVisualGroupGaps_InPixels(thisVisualGroupIndex) + this.GetPriorBarGaps_InPixels(thisVisualGroupIndex, thisBarIndexInVisualGroup);
		const barsBeforeThisBar = (thisVisualGroupIndex * this.dataGroupCount) + thisBarIndexInVisualGroup;
		const left = gapsBeforeThisBar_pixels + (this.barWidth_pixels * barsBeforeThisBar);
		const width = this.barWidth_pixels;
		const center = left + (width / 2);
		const right = left + width;
		return {left, width, center, right};
	}
}