import {GetValues_ForSchema} from "js-vextensions";
import {YoutubeSpeed, YoutubeQuality, GetVoices} from "web-vcore";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {AddSchema} from "mobx-firelink";

export enum ScriptType {
	Privileged = 10,
	//Normal = 20,
}

export class Script {
	constructor(initialData: Partial<Script>) {
		this.VSet(initialData);
	}

	_key: string; // for local, actually stored in json file
	_type: ScriptType; // derived from source (local: Privileged, web: Normal)
	creator: string;
	createdAt: number;
	name: string; // for local, filename (no ext) [and non-enumerable]

	// web
	//code: string;

	tags: string[];
}
AddSchema("Script", {
	properties: {
		id: {type: "string"},
		creator: {type: "string"},
		createdAt: {type: "number"},
		name: {type: "string", pattern: name_general},

		//code: {type: "string"},

		tags: {items: {type: "string"}},
	},
});