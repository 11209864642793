export class EffectPlayExtras {
	constructor(data?: Partial<EffectPlayExtras>) {
		Object.assign(this, data);
	}
	
	// SoundPlayer
	allowLog = true;

	// LightPlayer
	allowReapply = true;

	// ShakePlayer
	shake_stopPrevious = true;
}

export abstract class EffectPlayer {
	abstract Play(intensity?: number, extras?: Partial<EffectPlayExtras>): Promise<void>;
	//abstract Stop(disownInternalPlayer?: boolean): void;
}