import {AssertValidate} from "mobx-firelink";
import {FirebaseDBShape} from "../../Store/firebase";

export function ValidateDBData(data: FirebaseDBShape) {
	function ValidateCollection(collection, itemType: string) {
		(collection || {}).VValues(1).forEach(entry=>{
			AssertValidate(itemType, entry, `${itemType} invalid`);
		});
	}

	//ValidateCollection(data.attributes, "Attribute");
}