import {Observer, PageContainer, SubNavBar_Auto} from "web-vcore";
import React from "react";
import {Switch} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {Me} from "../Store/firebase/users.js";
import {store} from "../Store/index.js";
import {EntitiesUI} from "./Content/Entities";
import {ScenesUI} from "./Content/Scenes.js";
import {StoriesUI} from "./Content/Stories.js";

export const columnWidths = [.64, .06, .12, .18];

@Observer
export class ContentUI extends BaseComponentPlus({}, {}) {
	render() {
		const currentSubpage = store.main.content.subpage;
		const page = "content";

		return (
			<>
				<SubNavBar_Auto page={page}/>
				<Switch>
					<ScenesUI/>
					{currentSubpage == "entities" && <EntitiesUI/>}
					{currentSubpage == "stories" && <StoriesUI_Outer/>}
				</Switch>
			</>
		);
	}
}

@Observer
class StoriesUI_Outer extends BaseComponent<{}, {}> {
	render() {
		const user = Me();
		if (user == null) return <PageContainer>You must be signed in to access this page.</PageContainer>;
		
		return <StoriesUI user={user}/>;
	}
}