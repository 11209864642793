import {GetAsync} from "mobx-firelink";
import {Button, CheckBox, Column, Row, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Observer, RunInAction_Set, UseWindowEventListener} from "web-vcore";
import React from "react";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {StoryExploreComp} from "../../../../Engine/FBASession/Components/StoryExploreComp.js";
import {GetStoryMessages} from "../../../../Store/firebase/storyMessages.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {store} from "../../../../Store/index.js";
import {GetDefaultLLMPresets, LLMPreset} from "../../../../Store/main/tools/journey.js";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";
import {JSessionUI_Full} from "../JSessionUI.js";
import {GetLiveLocalSession_Reactive} from "../../../../Engine/FBASession.js";

@Observer
export class LLMPanel_Header extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey.llm;
		return (
			<>
				<Button p="0 5px" text="40%" onClick={()=>RunInAction_Set(this, ()=>uiState.panelHeight = 40)}/>
				<Button p="0 5px" text="60%" onClick={()=>RunInAction_Set(this, ()=>uiState.panelHeight = 60)}/>
				<Button p="0 5px" text="80%" onClick={()=>RunInAction_Set(this, ()=>uiState.panelHeight = 80)}/>
				<Button p="0 5px" text="100%" onClick={()=>RunInAction_Set(this, ()=>uiState.panelHeight = 100)}/>
				{/*<Text ml={5}>Recordings: (n/a atm)</Text>*/}
			</>
		);
	}
}

@Observer
export class LLMPanel extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.journey.llm;
		const fullMode = JSessionUI_Full();

		if (fullMode) {
			return (
				<Row style={{minHeight: 0}}>
					<LLMPanel_Side/>
					<LLMPanel_Main/>
				</Row>
			);
		}

		return <LLMPanel_Main/>;
	}
}


@Observer
export class LLMPanel_Side extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.journey.llm;
		const liveLocalSession = GetLiveLocalSession_Reactive();
		const storyComp = liveLocalSession?.Comp(StoryExploreComp);
		const targetMessageProto = storyComp?.GetCurrentMessage();
		return (
			<ScrollView style={{padding: `10px 0`, flex: 1, height: window.innerHeight - 100}}>
				<Text sel style={{whiteSpace: "pre-wrap"}}>{targetMessageProto?.content ?? "<n/a>"}</Text>
			</ScrollView>
		);
	}
}


@Observer
export class LLMPanel_Main extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.journey.llm;
		const liveLocalSession = GetLiveLocalSession_Reactive();
		const storyComp = liveLocalSession?.Comp(StoryExploreComp);

		const showResponseX = (stepsBack: number)=>{
			if (storyComp == null) return;
			ShowMessageBox({
				title: `LLM message <latest> - ${stepsBack}`,
				message: ()=>{
					const targetMessageProto = storyComp.GetCurrentMessage(-stepsBack);
					if (targetMessageProto == null) return null;
					// todo: maybe merge this with the "Message details" dialog in StoryMessageUI.tsx
					return (
						<ScrollView style={{padding: `10px 0`, width: window.innerWidth - 50, height: window.innerHeight - 100}}>
							<Text sel style={{whiteSpace: "pre-wrap"}}>{targetMessageProto.content}</Text>
							<Row>
								<Button text="Reset story to this step" onClick={async()=>{
									const uploadedMessages = await GetAsync(()=>GetStoryMessages(MeID()!, storyComp.story!._key));
									const targetUploadedMessage = uploadedMessages.find(a=>a._key == targetMessageProto.uploadedId);
									storyComp.LoadStoryForChain(storyComp.story!, uploadedMessages, targetUploadedMessage!);
								}}/>
							</Row>
						</ScrollView>
					);
				},
			});
		};

		return (
			<Column style={{flex: 1}}>
				<Row>
					<Text>Msg:</Text>
					<Button p="5px 10px" text="X-0" onClick={()=>showResponseX(0)}/>
					<Button p="5px 10px" text="X-1" onClick={()=>showResponseX(1)}/>
					<Button p="5px 10px" text="X-2" onClick={()=>showResponseX(2)}/>
					<Button p="5px 10px" text="X-3" onClick={()=>showResponseX(3)}/>
					<Button p="5px 10px" text="X-4" onClick={()=>showResponseX(4)}/>
					<Button p="5px 10px" text="X-5" onClick={()=>showResponseX(5)}/>
					<Button p="5px 10px" text="X-6" onClick={()=>showResponseX(6)}/>
					{!InAndroid(0) && <CheckBox ml={5} text="On side" value={uiState.showLLMOutputOnSide} onChange={val=>RunInAction_Set(this, ()=>uiState.showLLMOutputOnSide = val)}/>}
				</Row>
				<Text>Presets:</Text>
				<Row style={{flexWrap: "wrap", gap: 5}}>
					<Button p="0 5px" text="(manage)" onClick={()=>{
						const controller = ShowMessageBox({
							title: "LLM presets",
							okOnEnterKey: false,
							message: ()=><PresetsUI/>,
						});
					}}/>
					{uiState.llmPresets.map((preset, i)=>{
						return <Button key={i} p="0 5px" text={preset.name} style={{fontSize: 12}} onClick={()=>{
							let newInput = uiState.customLLMInput;
							// if input ends with non-whitespace, and preset starts with non-whitespace, add a space between them
							if (newInput.length && newInput[newInput.length - 1].match(/\S/) && preset.text[0].match(/\S/)) {
								newInput += " ";
							}
							newInput += preset.text;
							RunInAction_Set(this, ()=>uiState.customLLMInput = newInput);
						}}/>;
					})}
				</Row>
				<Row>
					<Text>Input:</Text>
					<Row ml="auto">
						<Button mdIcon="refresh" enabled={storyComp != null} onClick={async()=>{
							if (storyComp == null) return;
							const targetMessageProto = storyComp.GetCurrentMessage(-1)!;
							const uploadedMessages = await GetAsync(()=>GetStoryMessages(MeID()!, storyComp.story!._key));
							const targetUploadedMessage = uploadedMessages.find(a=>a._key == targetMessageProto.uploadedId);
							await storyComp.LoadStoryForChain(storyComp.story!, uploadedMessages, targetUploadedMessage!);

							await storyComp.SetLatestStoryInput(storyComp.GetCurrentMessageIndex()! - 1, targetMessageProto.content, true, newMessage_firstSegmentIndex=>{
								if (storyComp.storyProtoMessages_textSegments_index != newMessage_firstSegmentIndex) return; // if user has already moved away from the current segment, don't voice it
								storyComp.SpeakCurrentText();
							}, uiState.customLLMInput_includeSystemPrompt);
						}}/>
						<CheckBox ml={5} text="Sys-prompt" value={uiState.customLLMInput_includeSystemPrompt} onChange={val=>{
							RunInAction_Set(this, ()=>uiState.customLLMInput_includeSystemPrompt = val);
						}}/>
						<Button ml={5} text="Send" enabled={storyComp != null} onClick={async()=>{
							if (storyComp == null) return;
							const lastMessage = storyComp.GetCurrentMessage();
							const lastMessageIsUserInput = lastMessage?.role == "user";

							let currentMessageIndex = storyComp.GetCurrentMessageIndex();
							if (currentMessageIndex == null) return;

							if (lastMessageIsUserInput) {
								currentMessageIndex -= 1;
							}

							await storyComp.SetLatestStoryInput(currentMessageIndex, uiState.customLLMInput, true, newMessage_firstSegmentIndex=>{
								if (storyComp.storyProtoMessages_textSegments_index != newMessage_firstSegmentIndex) return; // if user has already moved away from the current segment, don't voice it
								storyComp.SpeakCurrentText();
							}, uiState.customLLMInput_includeSystemPrompt);

							RunInAction_Set(this, ()=>uiState.customLLMInput = "");
						}}/>
					</Row>
				</Row>
				<TextArea instant style={{flex: 1}} value={uiState.customLLMInput} onChange={val=>RunInAction_Set(this, ()=>uiState.customLLMInput = val)}/>
			</Column>
		);
	}
}

@Observer
class PresetsUI extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.journey.llm;
		
		/*const Change = (..._)=>controller.UpdateUI();
		const RunInAction_Set_PlusChange = (classInstance: Object, setterFunc: ()=>any)=>{
			RunInAction_Set(classInstance, setterFunc);
			Change();
		};*/

		// when window-size changes, update canvas size to match it (needed, eg. if nav-button toolbar gets hidden just after overlay closes)
		UseWindowEventListener("resize", ()=>this.Update());

		return (
			<ScrollView style={{padding: `10px 0`, width: window.innerWidth - 50, height: window.innerHeight - 100}}>
				<Row>
					<Button text="Refresh standard presets" onClick={()=>{
						RunInAction_Set(this, ()=>{
							const newPresets = uiState.llmPresets.slice();
							for (const [i, basePreset] of GetDefaultLLMPresets().entries()) {
								const presetAtSlot = newPresets[i] as LLMPreset|n;
								if (presetAtSlot?.name != basePreset.name) {
									newPresets.Insert(i, basePreset);
								}
							}
							uiState.llmPresets = newPresets;
						});
					}}/>
				</Row>
				{uiState.llmPresets.map((preset, i)=>{
					return <Row key={i}>
						<TextInput value={preset.name} onChange={val=>RunInAction_Set(this, ()=>uiState.llmPresets[i].name = val)}/>
						<TextArea autoSize={true} value={preset.text} onChange={val=>RunInAction_Set(this, ()=>uiState.llmPresets[i].text = val)}/>
						<Button mdIcon="delete" onClick={()=>{
							ShowMessageBox({
								title: "Delete preset", cancelButton: true,
								message: "Are you sure you want to delete this preset?",
								onOK: ()=>{
									RunInAction_Set(this, ()=>uiState.llmPresets.Remove(preset));
								},
							});
						}}/>
					</Row>;
				})}
				<Button text="Add preset" onClick={()=>{
					RunInAction_Set(this, ()=>uiState.llmPresets.push({name: "New preset", text: ""}));
				}}/>
			</ScrollView>
		)
	}
}