export function createRef_withHooks<T>(opt: {postSet: (val: T)=>void}) {
	const ref = {
		_current: null as T,
		get current() {
			return ref._current;
		},
		set current(val: T) {
			ref._current = val;
			opt.postSet(val);
		},
	};
	return ref;
}