import {AssertV, Command} from "mobx-firelink";
import {GetStory} from "../../Store/firebase/stories.js";
import {GetStoryMessages} from "../../Store/firebase/storyMessages.js";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user";

export class DeleteStory extends Command<{id: string}> {
	Validate() {
		const {id} = this.payload;
		const oldData = GetStory.NN(id);
		AssertV(IsUserCreatorOrMod(this.userInfo?.id, oldData), "Insufficient permissions.");

		const messages = GetStoryMessages(oldData.creator, id);
		AssertV(messages.length == 0, "Cannot delete story while it still has messages.");
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`stories/${id}`] = null;
		return updates;
	}
}