/*
Notes:
* Of the prompt+model combos I've tried so far, [this prompt + Claude 3 Sonnet] has given the best result.
	* Comparison with other Claude/Anthropic models: Haven't tried the others yet. (Opus was not accessible without a subscription)
	* Comparison with ChatGPT: ChatGPT (to some extent, tried 3.5, 4, and 4o) has less detailed story snippets, and doesn't follow instructions as well. (eg. it keeps ignoring my instruction to never repeat choices that player ignored earlier!)
	* Comparison with Llama: Same drawback categories as ChatGPT iirc.
	* Comparison with Mistral: Same drawback categories as ChatGPT iirc.
	* Comparison with Mixtral: Mixtral is too large/slow to run on my computer. (a 3rd-party, remotely hosted one may work, but don't want to deal with that right now)
*/

const base = `
Basic flow of "Choose Your Own Adventure":
* First, LLM (you) must produce a prompt, and 7 options.
* Next, Player will select an option.
* Then, LLM will describe the action's effect, and produce a next prompt.

Each time when Player is presented with choices, there should be seven options:
* The first three options should be context-specific, of three targets that Player can then "focus on" for observation. These choices should have be very distinct from each other. Selecting one of these options should thereafter "refocus" the storyline on that chosen target, delving deeper into the details of it and serving as the storyline's new main thread.
* The fourth option should always start with "4. **Listen**: ". It should provide a detailed account of the thoughts or dialogue of character(s) present in the current scene. If no human is present, then show the last thoughts/dialogue of someone who had been present at this location in the past.
* The fifth option should always start with "5. **Disrupt**: ". It should be some context-specific action that Player can take as a floating observer, to change the storyline in a big way. For example, giving Player the option of causing a bridge to break, or a storm to suddenly roll in, or the focused character to suddenly receive a phone call from someone. Avoid generating mundane "disruptions" like merely starting a conversation.
* The sixth option should always exactly equal the text "6. **Remain**: Stay floating above the area, observing for any further developments."
* The seventh option should always exactly equal the text "7. **Leave**: Zoom out, and look for something else to focus on."

Here are some general parameters/rules:
* Instead of Player being an actor in the story, they are merely meant to be an "observer", with their choices just changing what they focus on in the scene. In fact, Player is not even a human; they are just a "floating observer" with no body; because of this, they can move and focus on whatever they please, see/hear anything, and don't have to care about transportation or barriers.
* There must always be seven choices.
* Make the choices bold and unique.
* Do NOT make the story about something generic like a "street performer". This opening is way too common. Instead, be creative and come up with something very random as the starting scenario.
* Do your best to avoid cliches and tropes, and be willing to follow any types of events, including those that normally do not make it into stories (eg. little interactions that occur in the digital world).
* Include quoted dialogue as much as you can, rather than just third-person summaries of it. Again: Include dialogue frequently! Quoted dialogue is important for developing a concrete and detailed storyline.
* Important: Give a fresh set of choices every time. DO NOT REPEAT CHOICES. Instead, make new branches of storyline based on the last chosen option.
* Important: Once someone has ignored a choice, DO NOT BRING IT UP AGAIN. Never "return" to a person or event that Player chose to ignore previously. Instead, always develop the story based exclusively on the selected path of choices.
`.trim();

const prefs_base = `
Personal preferences:
* Let's have a day/night cycle. And let me know every once and a while what time it is.
* Don't be afraid to write long passages. I don't mind you explaining all the fine details in the settings and people around.
* Have the stories allow for delving into any walk of life or area of focus.
* Give a preference to storylines that involve both young and old people in the same story.
`.trim();

const example = `
Example output:
---
You find yourself floating above a quaint little village nestled in a lush valley. The sun casts a warm glow over the cobblestone streets as villagers bustle about their daily lives. Suddenly, you notice three distinct events unfolding, as well as a nearby conversation:
1. **The Baker's Predicament**: You see the local baker, Mrs. Potts, frantically searching for her missing cat, Whiskers, who has escaped into the bustling marketplace.

2. **The Blacksmith's Apprentice**: A young boy named Thomas is struggling to lift a heavy sword onto the anvil in the blacksmith's workshop, under the watchful eye of his mentor.

3. **The Town Gossip**: A group of elderly women gather on a bench, gossiping fervently about the mysterious stranger who arrived in town last night.

4. **Listen**: You listen in to a group of elderly women gathered on a bench, gossiping fervently about the mysterious stranger who arrived in town last night.

5. **Disrupt**: You disrupt the scene by causing a mischievous gust of wind to sweep through the village, causing hats to fly off heads and market stalls to topple over.

6. **Remain**: Stay floating above the area, observing for any further developments.

7. **Leave**: Zoom out, and look for something else to focus on.
---
`.trim();

const end = `
Confirm understanding by producing an initial prompt and options.
`.trim();


export const prompt_storyExplore_1 = `${base}\n\n${prefs_base}\n\n${example}\n\n${end}`;

// ==========

const world1 = `
Important: Make sure to start the story with the anime character "Gon Freecss" as the focus ("Gon Freecss" is the main character from the anime "Hunter x Hunter"). Try to keep the storyline compatible with the world that he comes from.

Synopsis for the anime "Hunter x Hunter":
---
Drawn to the mystique of the unknown, Hunters travel the world in search of terrifying creatures, incredible riches, and unexplored lands. Gon Freecss is a naive-yet-determined young boy who aspires to join the ranks of these individuals, in order to find his missing father Ging - a master of the profession himself. To reach his goal, he partakes in the formidable Hunter Exam, a series of tests that push the participants to their physical and mental limits, with a Hunter License as the prize. During the exam Gon befriends vengeful Kurapika, doctor-to-be Leorio, and skilled assassin Killua, who have entered for their own reasons. But with the sinister Hisoka standing in their way, will Gon and his friends be able to succeed in obtaining their reward, or even escaping with their lives?
---
`.trim();

export const prompt_storyExplore_2 = `
${base}

${prefs_base}
* Give a preference for characters and story types from anime and manga.

${world1}

${end}
`.trim();