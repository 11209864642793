import _, {first} from "lodash";
import {liveFBASession} from "../../Engine/FBASession";
import {ModifyString, Assert, Vector2, VRect, Timer, WaitXThenRun} from "js-vextensions";
import moment from "moment";
import React from "react";
import {autorun, IAutorunOptions} from "mobx";
import * as Sentry from "@sentry/react";
import {AddErrorMessage} from "web-vcore";
import {GetStackTraceStr} from "mobx-firelink";

export function UpdateWindowTitle() {
	document.title = liveFBASession != null ? `Lucid Frontier (engine active, ${liveFBASession.IsLocal() ? "local" : "remote"})` : "Lucid Frontier";
}

export function PropNameToTitle(propName: string) {
	return ModifyString(propName, m=>[m.lowerUpper_to_lowerSpaceLower, m.startLower_to_upper]);
}

export function Throttle(waitTime: number, func: (...args: any[])=>any);
export function Throttle(waitTime: number, options: _.ThrottleSettings, func: (...args: any[])=>any);
export function Throttle(...args) {
	let waitTime: number, options: _.ThrottleSettings, func: (...args: any[])=>any;
	if (args.length == 2) [waitTime, func] = args;
	else [waitTime, options, func] = args;
	return _.throttle(func, waitTime, options!);
}

export function ReplaceProp_KeepingIndex(obj: Object, oldPropName: string, newPropName: string, newPropValue: any) {
	const pairs = obj.Pairs();
	const propIndex = pairs.findIndex(a=>a.key == oldPropName);
	Assert(pairs.length > propIndex, `No prop exists at index ${propIndex}. Props: ${pairs.map(a=>a.key)}`);
	const oldPairsAtOrAfterIndex = pairs.Skip(propIndex);

	for (const pair of oldPairsAtOrAfterIndex) {
		delete obj[pair.key];
	}
	obj[newPropName] = newPropValue;
	for (const pair of oldPairsAtOrAfterIndex.Skip(1)) {
		if (pair.key == newPropName) continue; // ignore old-pair with same key as new-pair
		obj[pair.key] = pair.value;
	}
}
/** Use as "someVar = RequireNonNull(someVar)", after which point var will narrow to non-null. *#/
export function RequireNonNull<ValueType>(input: ValueType|null) {
	if (input == null) throw Error("value is required!");
	return input;
}*/

/*let _hScrollBarHeight;
export function GetHScrollBarHeight() {
	if (!_hScrollBarHeight) {
		let outer = $(`<div style="visibility: hidden; position: absolute; left: -100; top: -100; height: 100; overflow: scroll;"/>`).appendTo("body");
		let heightWithScroll = $("<div>").css({height: "100%"}).appendTo(outer).outerHeight();
		outer.remove();
		_hScrollBarHeight = 100 - heightWithScroll;
		//V._hScrollBarHeight = outer.children().height() - outer.children()[0].clientHeight;
	}
	return _hScrollBarHeight;
}
let _vScrollBarWidth;
export function GetVScrollBarWidth() {
	if (!_vScrollBarWidth) {
		let outer = $(`<div style="visibility: hidden; position: absolute; left: -100; top: -100; width: 100; overflow: scroll;"/>`).appendTo("body");
		let widthWithScroll = $("<div>").css({width: "100%"}).appendTo(outer).outerWidth();
		outer.remove();
		_vScrollBarWidth = 100 - widthWithScroll;
		//vScrollBarWidth = outer.children().width() - outer.children()[0].clientWidth + 1;
	}
	return _vScrollBarWidth;
}
export function HasScrollBar(control) { return HasVScrollBar(control) || HasHScrollBar(control); }
export function HasVScrollBar(control) { return control[0].scrollHeight > control[0].clientHeight; }
export function HasHScrollBar(control) { return control[0].scrollWidth > control[0].clientWidth; }*/

export function GetMousePosRelativeToElementForEvent(e: React.MouseEvent<any, MouseEvent>) {
	const rect = (e.target as Element).getBoundingClientRect();
	return new Vector2((e.clientX - rect.left) / rect.width, (e.clientY - rect.top) / rect.height);
}
// note: this seems to be slightly off, near the right edge, idk why
/*export function GetMousePosRelativeToChartAreaForEvent(e: React.MouseEvent<any, MouseEvent>, chartArea: {left: number, top: number, right: number, bottom: number}) {
	const elRect = (e.target as Element).getBoundingClientRect();
	const chartSubRect = new VRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top));
	const chartFinalRect = new VRect(elRect.x + chartSubRect.x, elRect.y + chartSubRect.y, chartSubRect.width, chartSubRect.height);
	return new Vector2((e.clientX - chartFinalRect.x) / chartFinalRect.width, (e.clientY - chartFinalRect.y) / chartFinalRect.height);
}*/

export function GetChartXYCoordForEvent(chart: any, e: React.MouseEvent<any, MouseEvent>) {
	var ytop = chart.chartArea.top;
	var ybottom = chart.chartArea.bottom;
	var ymin = chart.scales["y-axis-0"].min;
	var ymax = chart.scales["y-axis-0"].max;
	var newY: number;
	var showstuff = 0;
	if (e.nativeEvent.offsetY <= ybottom && e.nativeEvent.offsetY >= ytop) {
		newY = Math.abs((e.nativeEvent.offsetY - ytop) / (ybottom - ytop));
		newY = (newY - 1) * -1;
		newY = newY * (Math.abs(ymax - ymin)) + ymin;
		showstuff = 1;
	}

	var xtop = chart.chartArea.left;
	var xbottom = chart.chartArea.right;
	var xmin = chart.scales["x-axis-0"].min;
	var xmax = chart.scales["x-axis-0"].max;
	var newX: number;
	if (e.nativeEvent.offsetX <= xbottom && e.nativeEvent.offsetX >= xtop && showstuff == 1) {
		newX = Math.abs((e.nativeEvent.offsetX - xtop) / (xbottom - xtop));
		newX = newX * (Math.abs(xmax - xmin)) + xmin;
	}
	return new Vector2(newX!, newY!);
}

/** Clones the object-tree (well, objects and arrays inside the tree), with each object-clone having newly-sorted keys. */
export function SortKeysInObjectTree(obj, sortArrays = false) {
	if (!obj || typeof obj !== "object") return obj;

	if (Array.isArray(obj)) {
		const newArr = obj.map(item=>SortKeysInObjectTree(item, sortArrays));
		if (sortArrays) newArr.sort();
		return newArr;
	}

	const ordered = {};
	Object.keys(obj).sort().forEach(key=>{
		ordered[key] = SortKeysInObjectTree(obj[key], sortArrays);
	});
	return ordered;
}

export type TypedArray =
	Int8Array | Uint8Array | Int16Array | Uint16Array | Int32Array | Uint32Array
	| Float32Array | Float64Array
	| BigInt64Array | BigUint64Array;

export type BinarySearchHints = {
	minI?: number|n;
	maxI?: number|n;
	firstCheckIndex?: number|n;
};
/**
Binary search, within a sorted array.
If matching entry is found (ie. where compareFunc returns 0), its index is returned: {indexOfMatch}
Else, {matchInsertPoint} is returned, where matchInsertPoint is the insertion point for where a new (matching) element should be inserted.
Parameters:
	array - A sorted array
	compareFunc - A comparator function. The function takes one argument -- the entry in the array being checked. Should return:
		a negative number, if match/divider-point is before the checked entry
		0, if checked entry is a match (ie. compareFunc returns 0)
		a positive number, if match/divider-point is after the checked entry
The array may contain duplicate entries. If more than one matching entries, the returned value can be the index of any of them.
*/
export function BinarySearch<T>(array: T[] | TypedArray, compareFunc: (entry: T)=>number, hints?: BinarySearchHints) {
	let minI = hints?.minI ?? 0;
	let maxI = hints?.maxI ?? array.length - 1;
	while (minI <= maxI) {
		//var iToCheck = Math.floor((n + m) / 2);
		let iToCheck = (maxI + minI) >> 1; // eslint-disable-line
		// if first-check-index is specified, use it as such (rather than the mid-point of array)
		if (hints?.firstCheckIndex != null) {
			iToCheck = hints.firstCheckIndex;
			hints.firstCheckIndex = null;
		}

		const compareFuncResult = compareFunc(array[iToCheck] as T);
		if (compareFuncResult > 0) {
			minI = iToCheck + 1;
		} else if (compareFuncResult < 0) {
			maxI = iToCheck - 1;
		} else {
			return {indexOfMatch: iToCheck};
		}
	}
	//return -minI - 1;
	return {matchInsertPoint: minI};
}
/**
Finds an index matching the given function, using binary search for optimization.
If multiple entries match, the return value can be the index of any of them.
Make sure the matchesAreTowardEnd argument is accurate, else actually-matching entries may fail to be found.
*/
export function FindIndex_BinarySearch<T>(array: T[] | TypedArray, matchFunc: (entry: T)=>boolean, matchesAreTowardEnd: boolean, hints?: BinarySearchHints) {
	const {indexOfMatch, matchInsertPoint} = BinarySearch(array, entry=>{
		const matches = matchFunc(entry);
		if (matchesAreTowardEnd) {
			return matches ? -1 : 1;
		}
		return matches ? 1 : -1;
	}, hints);

	//Assert(indexOfMatch == null, "No match should be found, for the compareFunc used here.");
	//const indexMostCertainToMatch = matchesAreTowardEnd ? array.length - 1 : 0;

	const matchInsertPointIfNoMatches = matchesAreTowardEnd ? array.length : 0;
	// if match was not found, insert-point will equal the value determined above
	if (matchInsertPoint == matchInsertPointIfNoMatches) return -1;
	// else, match must have been found, so return the matching entry's index
	// if matchesAreTowardEnd, our received insert-point is the match-index (so return directly); else, it's the match-index + 1 (so adjust)
	return matchesAreTowardEnd ? matchInsertPoint : matchInsertPoint! - 1;
}

export function BinarySearch_RunTests() {
	const now = Date.now();
	const a1 = [1, 2, 3, 4, 5];
	Assert(FindIndex_BinarySearch(a1, a=>a >= 3, true, {firstCheckIndex: a1.length - 1}) == 2);

	const a2 = [now - 5000, now - 3000, now - 450];
	const {indexOfMatch} = BinarySearch(a2, triggerTime=>{
		if (triggerTime > now) return -1; // this trigger is too late; check to the left
		if (triggerTime < now - 1000) return 1; // this trigger is too early; check to the right
		return 0; // we found a matching trigger
	}, {firstCheckIndex: a2.length - 1});
	Assert(indexOfMatch == 2);
}

export function moment_local(timeVal: number, localOffsetFromUTC: number|n) {
	let time = moment(timeVal);
	if (localOffsetFromUTC != null) {
		time = time.utcOffset(localOffsetFromUTC);
	}
	return time;
}
export function Moment_ToDate_WithTimeZonePreApplied(time_withTimeZone: /*moment.Moment*/ any) {
	// .toDate() loses time-zone data, but same moment
	const result = time_withTimeZone.toDate();

	const dateObj_timeZoneOffset = -result.getTimezoneOffset(); // in minutes; east of utc is positive (after -)
	// modify date, such that stringification shows value at utc time-zone (Date obj thus technically target a different moment, but that's fine)
	result.setTime(result.valueOf() - (dateObj_timeZoneOffset * 60000));

	const source_timeZoneOffset = time_withTimeZone.utcOffset(); // in minutes east of utc is positive
	// modify date, such that stringification shows value at source time-zone (Date obj thus technically target a different moment, but that's fine)
	result.setTime(result.valueOf() + (source_timeZoneOffset * 60000));

	return result;
}

export function ModifyIntervalOfPossiblyActiveTimer(
	timer: Timer, newIntervalInMS: number,
	intervalSetterFunc: (newIntervalInMS: number, timer: Timer)=>any
		= (newIntervalInMS, timer)=>timer.intervalInMS = newIntervalInMS
) {
	const initialDelayOverride_ifWasActive = CalcInitialDelayOverrideAfterIntervalChange_IfTimerActive(timer, newIntervalInMS);
	intervalSetterFunc(newIntervalInMS, timer);
	if (initialDelayOverride_ifWasActive != null) {
		timer.Start(initialDelayOverride_ifWasActive);
	}
}
export function CalcInitialDelayOverrideAfterIntervalChange_IfTimerActive(timer: Timer, newIntervalInMS: number) {
	// if timer is disabled, no need for override; return undefined/no-override
	if (timer.Enabled == false) return undefined;
	
	const intervalChange = newIntervalInMS - timer.intervalInMS;
	const oldTimeTillTick = timer.nextTickTime! - Date.now();
	const newTimeTillTick = oldTimeTillTick + intervalChange;
	const initialDelayOverride = newTimeTillTick.KeepAtLeast(0);
	return initialDelayOverride;
}

export function AutoRun(opts: {wait?: number}, func: ()=>any, autorunOpts?: IAutorunOptions) {
	if (opts.wait != null) {
		WaitXThenRun(opts.wait, ()=>{
			autorun(func, autorunOpts);
		});
	} else {
		return autorun(func, autorunOpts);
	}
}

// for things which shouldn't happen (with notification and sentry-capture if it does), but should not cause error/crash
// todo: remove this once integrated into newest web-vcore
export function AssertNotify(condition, messageOrMessageFunc?: string | Function) {
	if (condition) return;

	var message = messageOrMessageFunc instanceof Function ? messageOrMessageFunc() : messageOrMessageFunc;
	const finalMessage = `Assert[notify] failed) ${message}`;
	const finalMessage_withStackTrace = `${finalMessage}\n\nStackTrace) ${GetStackTraceStr()}`;
	console.warn(finalMessage);
	AddErrorMessage(finalMessage, GetStackTraceStr());
	Sentry.captureMessage(finalMessage_withStackTrace, "warning");
	//Sentry.captureException(new Error(finalMessage), "warning", {stacktrace: true});
}