import {AddTestSegment} from "../../../../Server/Commands/AddTestSegment.js";
import {DeleteTestSegment} from "../../../../Server/Commands/DeleteTestSegment.js";
import {UpdateTestSegment} from "../../../../Server/Commands/UpdateTestSegment.js";
import {store} from "../../../../Store/index.js";
import {GetSelectedFBAConfig} from "../../../../Store/firebase/fbaConfigs.js";
import {FBAConfig} from "../../../../Store/firebase/fbaConfigs/@FBAConfig.js";
import {GetSession} from "../../../../Store/firebase/sessions.js";
import {EyeMoveDeclarationType, TestSegment} from "../../../../Store/firebase/testSegments/@TestSegment.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {IsUserCreatorOrMod} from "../../../../Store/firebase/users/$user.js";
import {SetCurrentTime} from "../../../../Store/main/timeline.js";
import {SelectSession} from "../../../../UI/Timeline/Sessions.js";
import {EEGSample, EEGSample_interval, Muse_eegSamplesPerSecond_raw} from "../../../../UI/Tools/@Shared/MuseInterface/EEGStructs.js";
import {moment_local} from "../../../../Utils/General/General.js";
import {InfoButton, Observer, RunInAction, TextPlus} from "web-vcore";
import {Clone, CloneWithPrototypes, E, GetEntries, SleepAsync} from "js-vextensions";
import moment from "moment";
import {Button, CheckBox, Column, Row, RowLR, Select, Spinner, Text} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {useMemo} from "react";
import {EEGProcessor} from "../../Processors/EEGProcessor.js";
import {Detection_ForUI, RunTestSegment, TestResult} from "../../Processors/TestSegmentRunner.js";
import {ShowSignInPopup} from "../UserPanel.js";
import {SegmentChart} from "./SegmentChart.js";

function useProcessor(segment: TestSegment, config: FBAConfig) {
	const {processor, samples} = useMemo(()=>{
		const processor = new EEGProcessor({options: E(config.eeg, {
			samplesProcessedPerSecond: Muse_eegSamplesPerSecond_raw,
			calc_normalize: true,
			calc_smooth: true,
			calc_combinedDeviation: true,
			calc_triggerSamplePercent: true,

			uplotData_normalize: true,
			uplotData_smooth: true,

			clearOutOfRangeSamples: false,
		})}).Init_LiveSession();

		const realSamples = [] as EEGSample[];
		//let nextSampleTime = segment.startTime.FloorTo(EEGSample_interval);
		//let nextSampleTime = segment.startTime;
		let nextSampleTime = segment.bufferStartTime;
		for (let i = 0; i < segment.channelSamples.eeg_left.length; i++) {
			const sample: EEGSample = {
				time: nextSampleTime,
				left: segment.channelSamples.eeg_left[i],
				right: segment.channelSamples.eeg_right[i],
			};
			realSamples.push(sample);
			nextSampleTime += EEGSample_interval;
		}
		//const firstSampleTime = realSamples[0].time;
		const firstBufferSampleTime = segment.bufferStartTime;

		// add X seconds of specified baselines at start (so final line-shapes roughly match that in full session)
		// Note: This approach will yield lines that differ slightly from orig. (since left of window has homogenous entries, rather than varying)
		// 	However, there's no way to avoid this, other than including entire norm-window, which would bloat storage costs for tiny gains.
		const fakeSamples = [] as EEGSample[];
		//const samplesInNormWindow_excludingFirstSample = processor.SamplesPerNormalizationWindow - 1; // exclude first-sample itself, since already in realSamples array
		for (let i = -processor.SamplesPerNormalizationWindow; i < 0; i++) {
			const sample: EEGSample = {
				time: firstBufferSampleTime + (EEGSample_interval * i),
				left: segment.channelBaselines.eeg_left,
				right: segment.channelBaselines.eeg_right,
			};
			fakeSamples.push(sample);
		}

		// now combine the fake and real samples, then process
		const samples = fakeSamples.concat(realSamples);
		for (const [i, sample] of samples.entries()) {
			processor.AddAndProcessEEGSample(sample);
		}

		return {processor, samples};
	}, [config.eeg, segment.bufferStartTime, segment.channelBaselines.eeg_left, segment.channelBaselines.eeg_right, segment.channelSamples.eeg_left, segment.channelSamples.eeg_right]);
	return {processor, samples};
}

export enum TestSegmentTab {
	Chart = 10,
	Others = 20,
}

@Observer
export class TestSegmentUI extends BaseComponentPlus(
	{} as {segment: TestSegment, index: number, onTestResult: (result: TestResult, segment: TestSegment, index: number)=>void},
	{tab: TestSegmentTab.Chart, showList: null as "shapes" | "detections" | n},
	) {
	render() {
		const {segment, index, onTestResult} = this.props;
		const {tab, showList} = this.state;
		const uiState = store.main.testing;
		const config = GetSelectedFBAConfig();
		if (config == null) return null;

		const canEdit = IsUserCreatorOrMod(MeID(), segment);
		const totalSamples = segment.channelSamples.eeg_left.length;

		const {processor, samples} = useProcessor(segment, config);
		const testResult = useMemo(()=>{
			if (!uiState.runTests) return null;
			const result = RunTestSegment(segment, config, processor, uiState.falseDetectionPenalty);
			onTestResult(result, segment, index);
			return result;
		}, [config, index, onTestResult, processor, segment, uiState.falseDetectionPenalty, uiState.runTests]);

		/*const secondTimes = segment.channelSamples.eeg_left.VKeys().map(a=>ToNumber(a));
		const rangeStart = secondTimes.Min();*/
		//const rangeEnd_default = secondTimes.Min() + (uiState.xRange * 1000); // try to have a consistent time-range (for easy comparison between entries)
		//const rangeEnd_final = Math.max(rangeEnd_default, secondTimes.Max() + 1000);

		function ChangeBaseline(channelName: string, newBaseline: number) {
			const newBaselines = CloneWithPrototypes(segment.channelBaselines) as typeof segment.channelBaselines;
			newBaselines[channelName] = newBaseline;
			new UpdateTestSegment({
				id: segment._key,
				updates: {channelBaselines: newBaselines},
			}).Run();
		}
		//const sessions = GetSessions(MeID());
		const session = GetSession.NN(segment.sourceSession);
		const segmentStartTime_creatorLocal = moment_local(segment.startTime, session?.localOffsetFromUTC); // session set in EEGChart.chart hook's postSet handler
		const segmentEndTime_creatorLocal = moment_local(segment.endTime, session?.localOffsetFromUTC); // session set in EEGChart.chart hook's postSet handler
		//return Moment_ToDate_WithTimeZonePreApplied(segmentStartTime_creatorLocal);
		return (
			<Column mt={index == 0 ? 0 : 10} p={5} style={{background: "rgba(30,30,30,1)", borderRadius: 5}}>
				<Row>
					<Row center>
						<Text>Time: {segmentStartTime_creatorLocal.format("YYYY-MM-DD HH:mm:ss")}-{segmentEndTime_creatorLocal.format("HH:mm:ss")}</Text>
						{segmentStartTime_creatorLocal.utcOffset() != moment().utcOffset() &&
							<InfoButton ml={5} text="Shown time is session-creator local."/>}
						<Button ml={5} p="3px 7px" text="Go" enabled={session != null} onClick={async()=>{
							// workaround for detail-panel not loading issue! // todo: remove need for this workaround
							const sessionsState = store.main.timeline.sessions;
							if (segment.sourceSession != sessionsState.selectedSession) {
								RunInAction("TestSegmentUI.Go.clear", ()=>sessionsState.selectedSession = null);
								await SleepAsync(100);
							}

							RunInAction("TestSegmentUI.Go", ()=>{
								/*const matchingSessions = sessions.filter(a=>segment.sourceTime.IsBetween(a.startTime, a.endTime));
								const session = matchingSessions.find(a=>a.creator == MeID()) ?? matchingSessions[0];
								if (session == null) return void ShowMessageBox({message: "Could not find session for the given time-range."});*/

								store.main.page = "timeline";
								store.main.timeline.subpage = "sessions";
								SelectSession(segment.sourceSession);
								SetCurrentTime(segment.startTime, session); //, {adjustViewRange: "force", viewRangeOverride: viewRangeInMS});
							});
						}}/>
						<Select ml={10} displayType="button bar" style={{flexShrink: 0}} options={GetEntries(TestSegmentTab)} value={tab} onChange={val=>this.SetState({tab: val})}/>
					</Row>
					<Row ml="auto">
						<CheckBox ml={5} text="Enabled" value={segment.enabled} onChange={val=>{
							new UpdateTestSegment({
								id: segment._key,
								updates: {enabled: val},
							}).Run();
						}}/>
						<Button ml={5} faIcon="clone" title="Clone" enabled={segment.creator != MeID()} onClick={()=>{
							if (MeID() == null) return ShowSignInPopup();
							ShowMessageBox({
								title: "Clone test-segment?",
								cancelButton: true,
								message: `
									After cloning, switch to your list to see the new entry.
								`.AsMultiline(0),
								onOK: ()=>{
									const newEntry = Clone(segment);
									new AddTestSegment({entry: newEntry}).Run();
								},
							});
						}}/>
						<Button ml={5} faIcon="trash" title="Delete" enabled={canEdit} onClick={()=>{
							ShowMessageBox({
								title: "Delete test-segment?", cancelButton: true,
								message: "Permanently delete this test-segment?",
								onOK: ()=>{
									new DeleteTestSegment({id: segment._key}).Run();
								},
							});
						}}/>
					</Row>
				</Row>
				{segment.enabled && tab == TestSegmentTab.Chart &&
				<>
					<SegmentChart segment={segment} processor={processor} testResult={testResult}/>
					{testResult &&
					<>
						<Row center>
							<Text>Required: {testResult.required_hits}/{segment.eyeMoveDeclarations.filter(a=>a.type == EyeMoveDeclarationType.Required).length}</Text>
							<Text ml={5}>False: {testResult.falseDetections}</Text>
							<Text ml={5}>Optional: {testResult.optional_hits}/{segment.eyeMoveDeclarations.filter(a=>a.type == EyeMoveDeclarationType.Optional).length}</Text>
							<Text ml={5}>Score: {testResult.score.ToPercentStr(.1)}</Text>
							<InfoButton ml={5} text="Score formula: 1 - (requiredHitsNotMet / requiredHitsTotal.KeepAtLeast(1)) - (falseDetections * falseDetectionPenalty)"/>
						</Row>
						<Row>
							<Text>Show:</Text>
							<CheckBox ml={5} text="Shapes" value={showList == "shapes"} onChange={val=>this.SetState({showList: val ? "shapes" : null})}/>
							<CheckBox ml={5} text="Detections" value={showList == "detections"} onChange={val=>this.SetState({showList: val ? "detections" : null})}/>
						</Row>
						{showList != null &&
						<Column sel>
							{(showList == "shapes" ? testResult.possibleDetections : testResult.detections).map((det, detectionIndex)=>{
								return <DetectionInfoUI key={detectionIndex} detection={det} rangeStart={segment.startTime}/>;
							})}
						</Column>}
					</>}
				</>}
				{segment.enabled && tab == TestSegmentTab.Others &&
				<>
					<RowLR splitAt={150}>
						<Text>EEG samples:</Text>
						<Text>{totalSamples}</Text>
					</RowLR>
					<RowLR splitAt={150}>
						<TextPlus info="Baseline for left channel. (should almost never be changed)">Baseline.Left:</TextPlus>
						<Spinner style={{width: 150}} min={undefined} value={segment.channelBaselines.eeg_left} onChange={val=>ChangeBaseline("eeg_left", val)}/>
					</RowLR>
					<RowLR splitAt={150}>
					<TextPlus info="Baseline for right channel. (should almost never be changed)">Baseline.Right:</TextPlus>
						<Spinner style={{width: 150}} min={undefined} value={segment.channelBaselines.eeg_right} onChange={val=>ChangeBaseline("eeg_right", val)}/>
					</RowLR>
				</>}
			</Column>
		);
	}
}

export class DetectionInfoUI extends BaseComponentPlus({} as {detection: Detection_ForUI, rangeStart: number}, {}) {
	render() {
		const {detection: det, rangeStart} = this.props;
		return (
			<Row>
				<Text>Time:<span style={{width: 80}}>{((det.startTime - rangeStart) / 1000).toFixed(1)}-{((det.endTime - rangeStart) / 1000).toFixed(1)}</span></Text>
				<Text ml={5}>Width:<span style={{width: 45}}>{det.shape.width.toFixed(2)}</span></Text>
				<Text ml={5}>Height:<span style={{width: 45}}>{det.shape.height.toFixed(2)}</span></Text>
				<Text ml={5}>Area:<span style={{width: 50}}>{det.shape.area.toFixed(2)}</span></Text>
				<Text ml={5} title="Distance from baseline/zero-point.">DistFromBL:<span style={{width: 50}}>{det.shape.distFromBaseline.toFixed(2)}</span></Text>
				<Text ml={5}>Pattern:<span style={{width: 30}}>{det.matchedPatternIndex != -1 ? `#${det.matchedPatternIndex + 1}` : "none"}</span></Text>
			</Row>
		);
	}
}