/*
Some geo-data sources found: (* means currently used)
1* [country names, XXX country-codes, bounding boxes, optionally-imported high-res polygons]: https://github.com/geobase/js-countries
2 [country names, XXX country-codes, high-res polygons]: https://github.com/datasets/geo-countries
2 [XXX country codes, polygons of chosen res]: https://github.com/simonepri/geo-maps/blob/master/info/countries-land.md
3 [XXX country codes, high-res polygons]: https://github.com/tripviss/osm-countries-geojson
4 [lakes/oceans/etc]: https://github.com/etpinard/sane-topojson
*/

import {ToInt, Clone, VRect, ToNumber, E} from "js-vextensions";
//import rawCountryDatas from "smart-geo/storage/countries/countries.json";
const rawCountryDatas = require("smart-geo/storage/countries/countries.json");

export interface ContinentData {
	name: string,
	code: string, // XX continent-code
	west: number,
	east: number,
	north: number,
	south: number,
	countryCount: number;
}

// I manually entered this data using Google Maps. (right-clicking edges of each continent's mainland/largest-continuous-landmass and checking coords)
let continentDatas: ContinentData[];
export function GetContinents() {
	if (continentDatas == null) {
		continentDatas = [
			{name: "North America", code: "NA", west: -165, east: -77, north: 74, south: 8},
			{name: "South America", code: "SA", west: -81, east: -35, north: 12, south: -54},
			// The eastern edge of Europe isn't neatly defined, so I went with the northernmost point of the Caspian Sea. (en.wikipedia.org/wiki/Boundaries_between_the_continents_of_Earth)
			{name: "Europe", code: "EU", west: -10, east: 51, north: 71, south: 36},
			{name: "Africa", code: "AF", west: -17, east: 51, north: 37, south: -35},
			{name: "Asia", code: "AS", west: 26, east: -170, north: 78, south: 1},
			{name: "Australia", code: "OC", west: 113, east: 153, north: -11, south: -39},
			//{name: "Antarctica", code: "AN", west: 0, east: 0, north: 0, south: 0},
		].map(continent=>{
			return E(continent, {countryCount: GetCountries().filter(country=>country.continent == continent.code).length});
		});
	}
	return continentDatas;
}
export function GetContinent(continentName: string) {
	return GetContinents().find(a=>a.name == continentName);
}
export function GetCountryContinent(countryName: string) {
	const continentCode = GetCountry(countryName)!.continent;
	return GetContinents().find(a=>a.code == continentCode);
}

export interface CountryData {
	shortCode: string, // XX country-code (ex: "US")
	code: string, // XXX country-code (ex: "USA")
	names: {
		en: string,
		fr: string,
		de: string,
	},
	currency: string, // ex: "EUR"
	continent: string, // XX continent-code (ex: "EU")
	population: number, // ex: "84000"
	area: number, // ex: "468.0"
	capital: string,
	latitude: number,
	longitude: number,
	north: number,
	east: number,
	south: number,
	west: number,
	timezone: string, // ex: "Europe/Andorra"
}

let countryDatas: CountryData[];
export function GetCountries() {
	if (countryDatas == null) {
		countryDatas = rawCountryDatas.map(rawData=>{
			const result = Clone(rawData);
			for (const prop of ["population", "area", "latitude", "longitude", "north", "east", "south", "west"]) {
				result[prop] = ToNumber(rawData[prop]);
			}
			return result as CountryData;
		});
		// removed some countries, because...
		countryDatas.Remove(countryDatas.find(a=>a.names.en == "U.S. Minor Outlying Islands")); // bounding-box covers basically whole globe
	}
	return countryDatas;
}
export function GetCountry(countryName: string) {
	return GetCountries().find(a=>a.names.en == countryName);
}

/** Gets list of each country whose boundary-box intersects the given country's boundary-box. */
export function GetNearbyCountries(countryName: string): CountryData[] {
	const targetCountry = GetCountry(countryName)!;
	return GetCountries().Exclude(targetCountry).filter(country=>CountryBoundingBoxesIntersect(country, targetCountry));
}
export function GetContinentOrCountryRect(country: ContinentData | CountryData, normalizeRect = true) {
	const result = new VRect(country.west, country.south, country.east - country.west, country.north - country.south);
	if (normalizeRect && result.width < 0) result.width += 360; // we only need to normalize width, since the y-axis/latitude terminates/doesn't-wrap at poles
	return result;
}
export function CountryBoundingBoxesIntersect(countryA: CountryData, countryB: CountryData) {
	return GetContinentOrCountryRect(countryA).Intersects_Advanced(GetContinentOrCountryRect(countryB), {xWrappedBy: 360});
}

type MapQuadrant = "northwest" | "northeast" | "southwest" | "southeast" | "extension";
export function GetContinentQuadrantsIntersectedByCountry(countryName: string): MapQuadrant[] {
	const continent = GetCountryContinent(countryName)!;
	const country = GetCountry(countryName)!;
	const countryRect = GetContinentOrCountryRect(country);
	const continentQuadrants = GetQuadrantRects(GetContinentOrCountryRect(continent));
	const quadrantsIntersecting = continentQuadrants.filter(a=>a.rect.Intersects_Advanced(countryRect, {xWrappedBy: 360})).map(a=>a.quadrant);
	// if country-rect doesn't actually intersect the mainland quadrant-rects, we consider it part of the continent "extension"
	if (quadrantsIntersecting.length == 0) return ["extension"];
	return quadrantsIntersecting;
}
function GetQuadrantRects(baseRect: VRect) {
	// return four quadrants: base (bottom-left), +x (bottom-right), +y (top-left), +x and +y (top-right)
	return [
		{quadrant: "southwest" as MapQuadrant, rect: new VRect(baseRect.x, baseRect.y, baseRect.width / 2, baseRect.height / 2)},
		{quadrant: "southeast" as MapQuadrant, rect: new VRect(baseRect.x + (baseRect.width / 2), baseRect.y, baseRect.width / 2, baseRect.height / 2)},
		{quadrant: "northwest" as MapQuadrant, rect: new VRect(baseRect.x, baseRect.y + (baseRect.height / 2), baseRect.width / 2, baseRect.height / 2)},
		{quadrant: "northeast" as MapQuadrant, rect: new VRect(baseRect.x + (baseRect.width / 2), baseRect.y + (baseRect.height / 2), baseRect.width / 2, baseRect.height / 2)},
	];
}