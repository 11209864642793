import {Button, Text, Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";

export class ReturnBar_Android extends BaseComponent<{text: string, onBack: ()=>void}, {}> {
	render() {
		let {text, onBack} = this.props;
		return (
			<Row style={{position: "relative", padding: 5}}>
				<Button size={32} mdIcon="chevron-left" style={{fontSize: 28, backgroundColor: "none !important", ":hover": "", border: "none"}} onClick={()=>{
					onBack();
				}}/>
				<Text style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", fontSize: 20, fontWeight: "bold"}}>{text}</Text>
			</Row>
		);
	}
}