import {BaseComponent, SimpleShouldUpdate} from "react-vextensions";
import {VReactMarkdown, PageContainer} from "web-vcore";

const pageText = `
TODO
`;

@SimpleShouldUpdate
export class AboutUI extends BaseComponent<{}, {}> {
	render() {
		return (
			<PageContainer scrollable={true}>
				<article>
					<VReactMarkdown className="selectable" source={pageText}
						/*markdownOptions={{breaks: false}} rendererOptions={{breaks: false}}
						rendererOptions={{
							components: {
								br: ()=><span> </span>
							}
						}}*/
						/*renderers={{
							Text: props=> {
								return <span style={{color: "rgba(255,255,255,.7)"}}>{props.literal}</span>;
							}
						}}*/
					/>
				</article>
			</PageContainer>
		);
	}
}