import {JournalEntry} from "../../Store/firebase/journalEntries/@JournalEntry";
import {AddSchema, NewSchema, GetSchemaJSON, AssertValidate, Command} from "mobx-firelink";
import {GetJournalEntry} from "../../Store/firebase/journalEntries";

type MainType = JournalEntry;
const MTName = "JournalEntry";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.IncludeKeys("title", "tags", "sleepTime", "wakeTime", "wakePosition", "notes", "segments"),
		}),
	},
	required: ["id", "updates"],
}));

export class UpdateJournalEntry extends Command<{id: string, updates: Partial<MainType>}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, "Payload invalid");

		const {id, updates} = this.payload;
		this.oldData = GetJournalEntry.NN(id);
		this.newData = {...this.oldData, ...updates};
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`journalEntries/${id}`] = this.newData;
		return updates;
	}
}