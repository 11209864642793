import {O, RunInAction} from "web-vcore";
import {ignore} from "mobx-sync";
import {LightPlayer} from "../../../Utils/EffectPlayers/LightPlayer";
import {store} from "../../../Store/index.js";
import {makeObservable, runInAction} from "mobx";

export enum LIFXConnectionType {
	Auto = 10,
	WebProxy = 20,
	WebDirect = 30,
	LAN = 40,
}

// returned by effect-json function
export type ScreenLight_Frame = {
	color: string;
	repeat_from?: number;
	repeat_count?: number;
};
export type ScreenLight_PromptInfo = {
	screenBrightness: number;
	frameInterval: number;
	framesLoop?: boolean;
	frames: ScreenLight_Frame[];
	// added during finalization
	frames_orig?: ScreenLight_Frame[];
};

export class LightsState {
	constructor() { makeObservable(this); }

	@O lifxConnection = LIFXConnectionType.Auto;
	// web
	@O lifx_webToken = "";
	// lan
	@O lifx_ipAddress = "";
	@O lifx_macAddress = "";

	@O kasa_lights = [] as KasaDevice[];

	@O filter = "";
	
	@O @ignore screenLight_currentPlayer: LightPlayer|n;
	@O @ignore screenLight_currentPromptInfo: ScreenLight_PromptInfo|n;
}
export function ClearScreenLight() {
	//SetFullScreen(false);
	const uiState2 = store.main.effects.lights;
	if (uiState2.screenLight_currentPlayer) {
		//uiState2.screenLight_currentPlayer.playState = "stopped";
		uiState2.screenLight_currentPlayer.Stop();
	}
	RunInAction("ClearScreenLight", ()=>{
		uiState2.screenLight_currentPlayer = null;
		uiState2.screenLight_currentPromptInfo = null;
	});
}

export class KasaDevice {
	constructor(data?: Partial<KasaDevice>) { Object.assign(this, data); }

   name: string;
   deviceId: string;
   host: string; // this is the only one that's actually needed to send commands
   port: number;

	//rawData: Object;
   //isPowered: boolean;
}