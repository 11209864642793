import moment from "moment";
import {Button, Column, Div, Row, Text, TextInput, CheckBox} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {DeleteRecordingSession} from "../../../Server/Commands/DeleteRecordingSession";
import {store} from "../../../Store";
import {EyeMoveType, RecordingSessionInfo} from "../../../Store/main/tools/training";
import {GetRecordingSessions} from "../../../Store/firebase/recordingSessions";
import {RecordingSession, GetSessionSampleCount} from "../../../Store/firebase/recordingSessions/@RecordingSession";
import {InDesktop, nativeBridge} from "../../../Utils/Bridge/Bridge_Native";
import {Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {runInAction} from "mobx";
import {ToJSON, E} from "js-vextensions";

export function SessionPropsToString(session: RecordingSession, propSepStr = ", ") {
	return session.sessionProps.Pairs().map(pair=>{
		return `${pair.key}: ${pair.value}${session.sessionProps_disabled.Contains(pair.key) ? " [disabled]" : ""}`;
	}).join(propSepStr);
}

export function SimplifyDateToSecond(date: number) {
	// todo: Couldn't we just return .FloorTo(1000)? Or maybe there are complications with leap-seconds or something?
	const createdAt_toSecondStr = moment(date).format("YYYY-MM-DD-HH-mm-ss");
	const createdAt_toSecondMoment = moment(createdAt_toSecondStr, "YYYY-MM-DD-HH-mm-ss");
	const createdAt_toSecond = createdAt_toSecondMoment.valueOf();
	return createdAt_toSecond;
}
export function IsSessionDownloaded(session: RecordingSession) {
	//const createdAtStr = moment(session.createdAt).format("YYYY-MM-DD-HH-mm-ss");
	//const createdAt_toSecond = moment(createdAtStr, "YYYY-MM-DD-HH-mm-ss").valueOf();
	/*return store.main.tools.training.downloadedSessionDateStrings.has(createdAtStr);*/
	return store.main.tools.training.localSessionInfos.Any(a=>a.createdAt_toSecond == SimplifyDateToSecond(session.createdAt));
}

export async function UpdateDownloadedSessionList() {
	const uiState = store.main.tools.training;
	const sessionFileNames = await nativeBridge.Call("GetDownloadedSessionFileNames", uiState.sessionStorageFolder) as string[];
	RunInAction("TrainingPanel_ComponentWillMount", ()=>{
		//uiState.downloadedSessionDateStrings = new Set(downloadedSessionDateStrings);
		uiState.localSessionInfos = sessionFileNames
		.filter(fileName=>fileName.match(/_(....-..-..-..-..-..)_/) != null && fileName.match(/_Samples-(\d+)\.json/) != null)
		.map(fileName=>{
			const fileNameMatch = fileName.match(/_(....-..-..-..-..-..)_/)!;
			const createdAtStr = fileNameMatch[1];
			return {
				localCopy: true,
				//createdAt_toSecond: SimplifyDateToSecond(moment(createdAtStr, "YYYY-MM-DD-HH-mm-ss")),
				createdAt_toSecond: moment(createdAtStr, "YYYY-MM-DD-HH-mm-ss").valueOf(),
				sampleCount: fileName.match(/_Samples-(\d+)\.json/)![1].ToInt(),
				fileName,
			};
		}).filter(a=>a);
	});
}

@Observer
export class SessionsPanel extends BaseComponentPlus({}, {}) {
	ComponentWillMount() {
		UpdateDownloadedSessionList();
	}

	render() {
		const uiState = store.main.tools.training;
		const webSessions = GetRecordingSessions().OrderBy(a=>a.createdAt);
		const localSessions = uiState.localSessionInfos;
		const sessionInfos = webSessions.map(session=>{
			return {
				webCopy: true,
				createdAt_toSecond: SimplifyDateToSecond(session.createdAt),
				sampleCount: GetSessionSampleCount(session),
				session,
			} as RecordingSessionInfo;
		});
		for (const localSession of localSessions) {
			const matchingSessionInfo = sessionInfos.find(a=>a.createdAt_toSecond == localSession.createdAt_toSecond);
			if (matchingSessionInfo) {
				matchingSessionInfo.localCopy = true;
				matchingSessionInfo.fileName = localSession.fileName;
			} else {
				sessionInfos.push(localSession);
			}
		}

		return (
			<>
				{InDesktop() &&
				<Row>
					<Text>Session storage folder:</Text>
					<TextInput ml={5} style={{flex: 1}} value={uiState.sessionStorageFolder} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.sessionStorageFolder = val);
					}}/>
				</Row>}
				<Row style={{fontWeight: "bold"}}>Sessions:</Row>
				{sessionInfos.map((sessionInfo, index)=>{
					async function GetSessionData() {
						const session = sessionInfo.session ?? await nativeBridge.Call("GetRecordingSessionData", uiState.sessionStorageFolder, sessionInfo.fileName) as RecordingSession;
						return session;
					}
					return (
						<Column key={index} mt={5} sel>
							<Text>Created at: {moment(sessionInfo.createdAt_toSecond).format("YYYY-MM-DD HH:mm:ss")}</Text>
							{sessionInfo.webCopy &&
							<Row style={{fontSize: 10}}>
								<Text>Props: </Text>
								{SessionPropsToString(sessionInfo.session!)}
							</Row>}
							<Row>
								<Text>Samples: {sessionInfo.sampleCount}</Text>
								<Text ml={10}>Actions:</Text>
								<Button ml={5} text="Props" onClick={async()=>{
									const session = await GetSessionData();
									ShowMessageBox({title: "Session props", message: ()=>(
										<Div sel>{`
											ID: ${session?._key ?? session["id"]}
											Props:
											${SessionPropsToString(session, "\n")}
										`.AsMultiline(0)}</Div>
									)});
								}}/>
								<Button ml={5} text="Graph" onClick={async()=>{
									const session = await GetSessionData();
									RunInAction_Set(this, ()=>uiState.showGraphForSession = session);
								}}/>
								{InDesktop() &&
								<Button ml={5} text="Download (local)" enabled={!sessionInfo.localCopy} onClick={async()=>{
									//let json = ToJSON(session);
									//await nativeBridge.Call("DownloadRecordingSession", uiState.sessionStorageFolder, `EyeMove-${session.sessionProps.eyeMoveType}`, session);
									await nativeBridge.Call("DownloadRecordingSession", uiState.sessionStorageFolder, E({id: sessionInfo.session!._key}, sessionInfo.session));
									UpdateDownloadedSessionList();
								}}/>}
								<Button ml={5} text="Delete (web)" enabled={!!sessionInfo.webCopy} onClick={()=>{
									ShowMessageBox({
										title: "Delete session (web)?", cancelButton: true,
										message: `
											Permanently delete the web copy of this recording session?

											Local file exists? ${sessionInfo.localCopy ? "yes" : "NO (recommended to download local copy first!)"}
										`.AsMultiline(0),
										onOK: async()=>{
											await new DeleteRecordingSession({id: sessionInfo.session!._key}).Run();
										},
									});
								}}/>
								{InDesktop() &&
								<Button ml={5} text="Delete (local)" enabled={!!sessionInfo.localCopy} onClick={()=>{
									ShowMessageBox({
										title: "Delete session (local)?", cancelButton: true,
										message: "Permanently delete the local copy of this recording session?",
										onOK: async()=>{
											await nativeBridge.Call("DeleteRecordingSessionFile", uiState.sessionStorageFolder, sessionInfo.fileName);
											UpdateDownloadedSessionList();
										},
									});
								}}/>}
							</Row>
						</Column>
					);
				})}
			</>
		);
	}
}