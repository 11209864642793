import {AssertWarn} from "mobx-firelink";
import {InfoButton, Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {Clone, E} from "js-vextensions";
import {Button, CheckBox, Row, RowLR, Select, Text, TextInput} from "react-vcomponents";
import {BaseComponentPlus} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {AddRecordingSession} from "../../../Server/Commands/AddRecordingSession";
import {store} from "../../../Store";
import {GetSessionSampleCount, RecordingSession} from "../../../Store/firebase/recordingSessions/@RecordingSession";
import {SessionPropTypes} from "../../../Store/main/tools/training";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native";
import {ReplaceProp_KeepingIndex} from "../../../Utils/General/General";
import {ES} from "../../../Utils/UI/GlobalStyles";
import {currentMuse} from "../@Shared/MuseInterface";
import {liveFBASession} from "../../../Engine/FBASession";
import {SessionPropsToString} from "./SessionsPanel";

nativeBridge.RegisterFunction("NotifyVolumeKeyDown", (keyCode: number)=>{
	const uiState = store.main.tools.training;
	if (!uiState.recordWithVolumeKeys) return;
	if (keyCode == -1) { // volume-down
		SetRecording(false);
	} else if (keyCode == 1) { // volume-up
		SetRecording(true);
	}
}, false);
/*nativeBridge.RegisterFunction("NotifyVolumeKeyUp", (keyCode: number)=>{
}, false);*/

nativeBridge.RegisterFunction("EEGTraining_NotifySampleCount", (sampleCount: number)=>{
	RunInAction("EEGTraining_NotifySampleCount", ()=>store.main.tools.training.sampleCount = sampleCount);
});
nativeBridge.RegisterFunction("EEGTraining_DataReady", (channelSamples: number[][])=>{
	//alert(`Got recording data:${ToJSON(channelSamples).KeepAtMost(500)}`);
	RunInAction("EEGTraining_DataReady", ()=>{
		const uiState = store.main.tools.training;

		const session = new RecordingSession();
		session.sessionProps = Clone(uiState.sessionProps);
		session.sessionProps_disabled = Clone(uiState.sessionProps_disabled);

		// to save on db storage-space, only store samples of channels we haven't marked as "ignore"
		session.eegChannelSamples = E(
			session.sessionProps.eegSignalQuality_left != "Ignore" && {af7: channelSamples[0]},
			session.sessionProps.eegSignalQuality_right != "Ignore" && {af8: channelSamples[1]},
		) as any;

		uiState.draftRecordingSessions.push(session);
		//uiState.currentRecordingSessions.push(session.Strip()); // strip required for mobx-based field-observation
	});
});

function SetRecording(recording: boolean) {
	AssertWarn(InAndroid(0), "SetRecording will not actually connect to Muse unless on android.");
	const uiState = store.main.tools.training;
	if (uiState.recording == recording) return;

	RunInAction_Set(()=>{
		uiState.recording = recording;
		uiState.sampleCount = 0;
		if (uiState.recording) {
			if (currentMuse.museStatus == "disconnected") {
				nativeBridge.Call("Muse_Connect", "training");
			}
		} else {
			if (currentMuse.museStatus != "disconnected") {
				nativeBridge.Call("Muse_Disconnect", "training");
			}
		}
	});
}

//var volumeKeyListenerActive = false;
export function UpdateVolumeKeyListenerActive() {
	const newValue = liveFBASession != null || store.main.tools.training.recordWithVolumeKeys;
	/*if (newValue != volumeKeyListenerActive) {
		volumeKeyListenerActive = newValue;*/
	// it's safe to call >once with same value; ForegroundService.kt checks state
	nativeBridge.Call("SetVolumeKeyListenerActive", newValue);

	// todo: make sure this works correctly with session start/end changes (probably just disallow co-existing of engine and recording sessions)
}

@Observer
export class RecordingPanel extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.tools.training;
		const sessionProps = uiState.sessionProps;

		const splitAt = 160, width = 150;
		return (
			<>
				<Row center>
					<CheckBox text="Record with volume-keys" value={uiState.recordWithVolumeKeys} onChange={val=>{
						RunInAction_Set(()=>uiState.recordWithVolumeKeys = val);
						UpdateVolumeKeyListenerActive();
					}}/>
					<InfoButton ml={5} text="Use volume-up key to start recording; volume-down key to stop."/>
					<Button ml={10} text={uiState.recording ? "Stop recording" : "Start recording"} onClick={()=>SetRecording(!uiState.recording)}/>
				</Row>
				<Row mt={5}>
					<Text>Live) Recording: {uiState.recording ? "yes" : "no"} Status: {currentMuse.museStatus} Samples: {uiState.sampleCount}</Text>
				</Row>
				<Row mt={5} style={{fontWeight: "bold"}}>Draft sessions:</Row>
				{uiState.draftRecordingSessions.map((session, index)=>{
					return (
						<Row key={index} mt={5}>
							<Text>Samples: {GetSessionSampleCount(session)}</Text>
							<Button ml={5} text="Props" onClick={()=>{
								ShowMessageBox({title: "Session props", message: `Props: ${SessionPropsToString(session, "\n")}`});
							}}/>
							<Button ml={5} text="Graph" onClick={()=>{
								RunInAction_Set(this, ()=>uiState.showGraphForSession = session);
							}}/>
							<Button ml={5} text="Upload" enabled={!uiState.uploadedRecordingSessions.has(session)} onClick={async()=>{
								await new AddRecordingSession({session}).Run();
								RunInAction_Set(this, ()=>uiState.uploadedRecordingSessions.add(session));
							}}/>
							<Button ml={5} text="Clear" onClick={()=>RunInAction_Set(this, ()=>uiState.draftRecordingSessions.Remove(session))}/>
						</Row>
					);
				})}
				<Row mt={5}>
					<Text style={{fontWeight: "bold"}}>Session properties:</Text>
					<Button ml={5} p="3px 7px" text="+" onClick={()=>{
						let unusedAutoPropName_num = 1;
						while (sessionProps[`newProp${unusedAutoPropName_num}`] != null) {
							unusedAutoPropName_num++;
						}
						sessionProps[`newProp${unusedAutoPropName_num}`] = "value";
					}}/>
				</Row>
				{Object.keys(sessionProps).map((propName, propIndex)=>{
					const propInfo = SessionPropTypes[propName];
					const showCheckbox = !propInfo?.required;
					return (
						<RowLR key={propName} splitAt={splitAt}>
							<Row center>
								{showCheckbox &&
									<CheckBox ml={10} value={!uiState.sessionProps_disabled.Contains(propName)} onChange={val=>{
										RunInAction_Set(this, ()=>{
											if (val) {
												uiState.sessionProps_disabled.Remove(propName);
											} else {
												uiState.sessionProps_disabled.push(propName);
											}
										});
									}}/>}
								{propInfo &&
									<Text ml={showCheckbox ? 5 : 10}>{propInfo.name}:</Text>}
								{propInfo == null &&
									<TextInput ml={5} style={{width: "100%"}} value={propName} onChange={val=>{
										RunInAction_Set(this, ()=>{
											/*sessionProps[val] = sessionProps[propName];
											delete sessionProps[propName];*/
											ReplaceProp_KeepingIndex(sessionProps, propName, val, sessionProps[propName]);
										});
									}}/>}
							</Row>
							{propInfo &&
								<Select ml={5} options={propInfo.values} style={{width}} value={sessionProps[propName]} onChange={val=>RunInAction_Set(this, ()=>sessionProps[propName] = val)}/>}
							{!propInfo &&
								<TextInput ml={5} style={ES({flex: 1})} value={sessionProps[propName]} onChange={val=>RunInAction_Set(this, ()=>sessionProps[propName] = val)}/>}
							{!propInfo &&
								<Button text="X" style={{padding: "3px 5px", borderRadius: "0 5px 5px 0"}} onClick={()=>{
									RunInAction_Set(this, ()=>{
										delete sessionProps[propName];
										uiState.sessionProps_disabled.Remove(propName);
									});
								}}/>}
						</RowLR>
					);
				})}
			</>
		);
	}
}