import {FBAConfig_JourneyTraining} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyTraining.js";
import {EngineSessionComp} from "./EngineSessionComp.js";

export class JourneyTrainingComp extends EngineSessionComp<FBAConfig_JourneyTraining> {}

/*import {E} from "react-vextensions/Dist/Internals/FromJSVE";
import {TextSpeaker} from "web-vcore";
import {Text} from "react-vcomponents";
import {IsMetaEntity} from "../../../Store/firebase/entities.js";
import {Entity} from "../../../Store/firebase/entities/@Entity.js";
import {FBAConfig_JourneyTraining} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyTraining.js";
import {Sequence, SequenceItem, TriggerSet} from "../../../Store/firebase/fbaConfigs/@TriggerSet.js";
import {GetUserEntityTags, MeID} from "../../../Store/firebase/users.js";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {FBASession, TriggerPackage} from "../../../Engine/FBASession.js";
import {DreamRecallComp, NarrateText} from "./DreamRecallComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {JourneyComp, JourneyInputMode} from "./JourneyComp.js";

export class JourneyTrainingComp extends EngineSessionComp<FBAConfig_JourneyTraining> {
	constructor(session: FBASession, config: FBAConfig_JourneyTraining) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	targetJournalEntryIndex: number;
	targetSegmentIndex: number;
	targetEntityIndex: number; // index of -1 signifies the segment's anchor/reference entity
	InitializeTargetsIfNotYet() {
		if (this.targetJournalEntryIndex != null) return;
		this.SeekToEnd();
	}
	SeekToEnd(speakEntity = true) {
		const dreamRecallComp = this.s.Comp(DreamRecallComp);
		this.targetJournalEntryIndex = dreamRecallComp.journalEntries_sorted.length - 1;
		this.targetSegmentIndex = 1000;
		this.targetEntityIndex = 1000;
		this.GoPrevEntity(speakEntity);
	}
	AreTargetsValid() {
		const {segment, entity, entityIsRef} = ResolveTargets(this);
		// these conditions differ from JourneyEntryComp (eg. we don't accept anchor-entities)
		return entity != null && !entityIsRef;
	}
	GoPrevEntity(speakEntity = true) {
		GoPrevEntity(this, speakEntity);
	}
	GoNextEntity() {
		GoNextEntity(this);
	}
	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}
	SpeakCurrentEntity(prefixTextToSpeak = "", justInsertedEntities = [] as Entity[], entityOverride = null as Entity|n) {
		//let {journalEntry, segment, entityID, entity, entityIsRef} = ResolveTargets(this, justInsertedEntities);
		const entity = entityOverride
			? entityOverride
			: ResolveTargets(this, justInsertedEntities).entity;
		if (entity != null) {
			this.NarrateText(`${prefixTextToSpeak} ${this.c.prefixText ?? ""} ${entity.name} ${this.c.postfixText ?? ""}`);
		} else {
			this.NarrateText(`${prefixTextToSpeak} Target entity missing.`);
		}
	}

	/** Note: If "seeds_orderKey" is set, then the entity chosen will *not* be random. *#/
	SeekToAndSpeakRandomValidEntity() {
		const dreamRecallComp = this.s.Comp(DreamRecallComp);
		const allEntityIDsInDreams = dreamRecallComp.journalEntries_sorted.SelectMany(a=>a.segments).SelectMany(a=>a.entitiesSequence!);
		const validEntityIDs = new Set(dreamRecallComp.entities_all.filter(a=>{
			// never random-seek to a meta-entity (even if it's a valid target moving forward/backward)
			if (IsMetaEntity(a)) return false;
			// exclude entities that don't have all the required tags
			const userTags = GetUserEntityTags(MeID(), "entities", a._key);
			for (const tag of this.c.entityTags) {
				if (!userTags?.includes(tag)) return false;
			}
			// exclude entities that don't have a high enough count within the dream/journal-entry pool
			if (this.c.entityMinCount > 0 && allEntityIDsInDreams.map(b=>b == a._key).length < this.c.entityMinCount) return false;
			return true;
		}).map(a=>a._key));

		type TargetInfo = {entityID: string, fromDreams: boolean, journalEntryIndex: number, segmentIndex: number, entityIndex: number};
		let validTargetInfos = [] as TargetInfo[];

		if (this.c.seeds_addFromDreams) {
			for (const [i_dream, dream] of dreamRecallComp.journalEntries_sorted.entries()) {
				for (const [i_segment, segment] of dream.segments.entries()) {
					if (segment.wakeTime != null) continue;
					for (const [i_entity, entityID] of segment.entitiesSequence!.entries()) {
						if (validEntityIDs.has(entityID)) {
							validTargetInfos.push({entityID, fromDreams: true, journalEntryIndex: i_dream, segmentIndex: i_segment, entityIndex: i_entity});
						}
					}
				}
			}
		}

		if (this.c.seeds_basePerEntity > 0) {
			for (const entityID of validEntityIDs) {
				const targetInfosForEntityFromDreams = validTargetInfos.filter(a=>a.entityID == entityID);
				for (let i = 0; i < this.c.seeds_basePerEntity; i++) {
					const randomTargetInfoForEntity = targetInfosForEntityFromDreams.Random();
					// for adding the "base seeds" for each entity (ie. not part of regular "add from dreams" pool), try to just copy a random seed for it that had been from a dream
					if (randomTargetInfoForEntity) {
						validTargetInfos.push({...randomTargetInfoForEntity}); // make new actual object, so each copy is unique (needed for validTargetInfos.indexOf found below)
					}
					// if no such from-dream seeds exist, just set the target-indexes to a random location within the dreams (makes things more interesting, for if doing manual seeking later)
					else {
						validTargetInfos.push({entityID, fromDreams: false, journalEntryIndex: dreamRecallComp.journalEntries_sorted.map((a, i)=>i).Random(), segmentIndex: 0, entityIndex: -1});
					}
				}
			}
		}

		const chosenTargetInfo = validTargetInfos.Random();
		const chosenTargetInfo_entity = dreamRecallComp.entities_all.find(a=>a._key == chosenTargetInfo.entityID);
		this.targetJournalEntryIndex = chosenTargetInfo.journalEntryIndex;
		this.targetSegmentIndex = chosenTargetInfo.segmentIndex;
		this.targetEntityIndex = chosenTargetInfo.entityIndex;
		// explicitly supply the entity to speak; this way the call works even if the target-info is not "from dream" (ie. unable to be resolved from the target indexes)
		this.SpeakCurrentEntity(undefined, undefined, chosenTargetInfo_entity);
	}

	// players
	//soundPlayer = new SoundPlayer(); // we use SoundPlayer (not TextSpeaker), since it's project-specific so understands Sound-objects directly
	textSpeaker = new TextSpeaker();

	GetTriggerPackages() {
		return [
			new TriggerPackage("JourneyTraining_VolUpTap", new TriggerSet({sequences: [new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {})]}), this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Training) return;

				this.SeekToAndSpeakRandomValidEntity();
			}),
			new TriggerPackage("JourneyTraining_VolDownTap", new TriggerSet({sequences: [new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {})]}), this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Training) return;

				// if no seeds from dreams, then moving forward/backward is conceptually meaningless; so replace with just a random seek+speak
				if (!this.c.seeds_addFromDreams) return void this.SeekToAndSpeakRandomValidEntity();
				this.GoNextEntity();
			}),
			// NOTE: Atm, these triggers have their inputs hard-coded.
			new TriggerPackage("JourneyTraining_VolUpHold", new TriggerSet({sequences: [new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {})]}), this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Training) return;

				// if no seeds from dreams, then moving forward/backward is conceptually meaningless; so replace with just a random seek+speak
				if (!this.c.seeds_addFromDreams) return void this.SeekToAndSpeakRandomValidEntity();
				this.GoPrevEntity();
			}),
			new TriggerPackage("JourneyTraining_VolDownHold", new TriggerSet({sequences: [new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {})]}), this, {}, async triggerInfo=>{
				if (this.s.Comp(AlarmsComp).inputMode != JourneyInputMode.Training) return;

				if (!this.c.seeds_addFromDreams) return; // if no seeds from dreams, then moving forward/backward is conceptually meaningless; so block to avoid confusion
				this.SeekToEnd(false);
			}),
		];
	}
	GetStatusUI() {
		return <Text style={{whiteSpace: "pre"}}>{`TODO`.AsMultiline(1)}</Text>;
	}

	OnStop() {
		this.StopTextSpeaker();
	}

	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}
}*/