import {StoreAccessor} from "mobx-firelink";
import {O} from "web-vcore";
import {InAndroid} from "../../../Utils/Bridge/Bridge_Native";
import {makeObservable} from "mobx";

export class SoundsState {
	constructor() { makeObservable(this); }
	@O youtubePlayersToKeepBuffered = InAndroid(0) ? 1 : 3;
}

export const GetYoutubePlayersToKeepBuffered = StoreAccessor(s=>()=>{
	return s.main.effects.sounds.youtubePlayersToKeepBuffered;
});