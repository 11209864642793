import {Bridge, FromJSON, Assert, SleepAsync} from "js-vextensions";
import ReconnectingWebSocket from "reconnecting-websocket";
import {hostConnection, DisconnectFromHost, ConnectToHost} from "../../Engine/Remoting/HostConnection";
import {store} from "../../Store";
import {remoteUserProcessesBridge} from "./Bridge_RemoteUserProcesses";
import {AppInstanceInfo} from "./SiteInstanceBridgeManager";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";

export function RemoteDesktop_IsInitialized() {
	return remoteDesktopConnection != null;
}
export async function RemoteDesktop_InitBridge(hostInfo: AppInstanceInfo) {
	Assert(remoteDesktopConnection == null);
	await remoteUserProcessesBridge.Call("PrepareForClientConnectionOverWS", hostInfo); // have host create ws-server
	await RemoteDesktop_CreateWebSocketConnection(`${hostInfo.ipAddress}:${hostInfo.port}`);
	remoteDesktopBridge.SetUpReceiver();
}
export function RemoteDesktop_DestroyBridgeAndConnection() {
	Assert(remoteDesktopConnection);
	Assert(remoteDesktopBridge);
	// note that by closing the websocket connection, it also "nullifies"/undoes the remoteDesktopBridge.SetUpReceiver() call
	remoteDesktopConnection.close();
	remoteDesktopConnection = null;
}

// websocket with remote lucid-frontier-desktop
// ==========

remoteUserProcessesBridge.RegisterFunction("HostSessionStarted_AllowingClientConnections", async(hostInfo: AppInstanceInfo)=>{
	// if we had connection to host, but its process was restarted, destroy old connection (since known invalid)
	const oldHost = hostConnection?.hostInfo;
	if (oldHost && hostInfo.ipAddress == oldHost.ipAddress && hostInfo.processID != oldHost.processID) {
		SessionLog("Disconnected");
		await DisconnectFromHost(false);
	}

	const uiState = store.main.tools.engine;
	if (hostConnection == null && uiState.remote_autoConnect) {
		await ConnectToHost(hostInfo, uiState.remote_channel);
	}
});

var remoteDesktopConnection: ReconnectingWebSocket|n;
export async function RemoteDesktop_CreateWebSocketConnection(ipAddressAndPort: string) {
	//g.WebSocket = g.WebSocket || g.MozWebSocket;

	//remoteDesktopConnection = new WebSocket('ws://127.0.0.1:5011');
	//remoteDesktopConnection = new ReconnectingWebSocket(`wss://${ipAddressAndPort}`); // hard-coded for now
	const connection = new ReconnectingWebSocket(`ws://${ipAddressAndPort}`); // hard-coded for now
	console.log(`Creating remote-desktop websocket connection... (to: ${ipAddressAndPort})`);

	// give websocket 5 seconds to connect; if too slow, cancel connection attempt
	var result = await Promise.race([
		SleepAsync(5000),
		new Promise((resolve, reject)=>{
			const listener = ()=>{
				connection.removeEventListener("open", listener);
				resolve(true);
			};
			connection.addEventListener("open", listener);
			/*connection.addEventListener("error", error=>{
				// don't log these "errors", since they trigger for mere connect and reconnect failures (which happens often due to connection-retrier)
				//console.error("Remote-desktop websocket error:", error);
				reject(error);
			});*/
		}),
	]);
	if (result != true) {
		connection.close();
		return;
	}

	// don't react to WS disconnect, since might just be temp
	/*connection.addEventListener("close", ()=>{
		console.log("Remote-desktop websocket disconnected. (not by user)");
		if (hostConnection) {
			DisconnectFromHost();
		}
	});*/
	// if websocket reconnects
	connection.addEventListener("open", ()=>{
		console.log("Remote-desktop websocket automatically reconnected.");
	});

	console.log("Remote-desktop websocket opened!");
	remoteDesktopConnection = connection;

	/*remoteDesktopConnection.addEventListener("message", message=>{
		console.log(`Received: ${message.data}`);
		const json = JSON.parse(message.data);
	});*/
}

// bridge with remote lucid-frontier-desktop
// ==========

export const remoteDesktopBridge = new Bridge({
	receiveChannelMessageFunc_adder: receiveChannelMessageFunc=>{
		remoteDesktopConnection!.addEventListener("message", event=>{
			//receiveChannelMessageFunc(FromJSON(event.data));
			receiveChannelMessageFunc(event.data);
		});
	},
	sendChannelMessageFunc: (channelMessage: string)=>{
		//remoteDesktopConnection.send(JSON.stringify(data));
		remoteDesktopConnection!.send(channelMessage);
	},
	//channel_stringifyChannelMessageObj: false,
	receiveChannelMessageFunc_addImmediately: false,
});