import {HSLA, InfoButton, Observer, RunInAction, RunInAction_Set, Slider, VDateTime} from "web-vcore";
import {Assert, Clone, DelIfFalsy, GetEntries} from "js-vextensions";
import {Button, CheckBox, Column, Row, Select, Spinner, Text, TextArea, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {UpdateJournalEntry} from "../../../Server/Commands/UpdateJournalEntry.js";
import {GetTermsInDreamSegmentText, JournalEntry, JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../../Store/index.js";
import {ClarityForm, llmTemplateDefault_summarize, SummarizeTab} from "../../../Store/main/journal.js";
import {StarsRating} from "../../../Utils/ReactComponents/StarsRating.js";
import Moment from "moment";
import React, {useState} from "react";
import {GetEntities_WithUserTag, GetEntity} from "../../../Store/firebase/entities.js";
import {TermCell} from "../../Tools/Journey/TermCell.js";
import {GetJournalEntries} from "../../../Store/firebase/journalEntries.js";
import {MeID} from "../../../Store/firebase/users.js";
import {StoryMessage, StoryMessageProto} from "../../../Store/firebase/storyMessages.js";
import {GenerateNextMessage, GenerateNextMessageOptions} from "../../../Utils/Services/Anthropic.js";
import {SegmentSummarizeUI} from "./SegmentUI/SegmentSummarizeUI.js";
import {SegmentCleanupUI} from "./SegmentUI/SegmentSimplifyUI.js";

interface SharedProps {
	entry: JournalEntry;
}

type JournalSegmentUIProps = {segment: JournalSegment, segments: JournalSegment[], showEntitiesSequence: boolean} & SharedProps;
@Observer
export class JournalSegmentUI extends BaseComponent<JournalSegmentUIProps, {}> {
	render() {
		const {segment, segments, entry, showEntitiesSequence} = this.props;
		const uiState = store.main.journal;
		const [summarizeMode, setSummarizeMode] = useState(false);
		const [cleanupMode, setCleanupMode] = useState(false);
		const inJourneyUI = !showEntitiesSequence;
		const clarityForm = store.main.journal.clarityForm;
		const index = segments.indexOf(segment);
		Assert(index != -1, "Segment not found in entry's segments.");
		
		const anchorEntities = GetEntities_WithUserTag("anchor");
		const anchorEntity = GetEntity(segment.anchorEntity);

		// if clarity doesn't match any of the star-values, force it to show as a percent (since otherwise the ui can't display the real value)
		//const clarityForm_final = IsNumber(segment.clarity) && segment.clarity % .2 != 0 ? ClarityForm.Percent : clarityForm;
		const clarity_final = segment.clarity || 0;
		const clarityForm_final = !clarity_final.IsMultipleOf(.2, .001) ? ClarityForm.Percent : clarityForm;

		const UpdateSegment = (changerFunc: (data: JournalSegment)=>any)=>{
			const segments_new = Clone(entry.segments);
			const segment_newData = segments_new[index];
			changerFunc(segment_newData);
			new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
		};

		const segmentLength = segment.length || 0;
		return (
			<>
				<Row center mt={index == 0 ? 0 : 3} pl={5} style={{background: HSLA(0, 0, 1, .3), borderRadius: "5px 5px 0 0", flexWrap: "wrap", gap: 5}}>
					<Row>
						<CheckBox text="Lucid" value={segment.lucid} onChange={val=>{
							const segments_new = Clone(entry.segments);
							segments_new[index] = segment.Extended({lucid: val});
							new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
						}}/>
						<InfoButton ml={5} text={`
							If you knew you were dreaming for at least part of the segment. (ideally, extract lucid periods into their own segments)
							Precisely: You were "lucid" if you knew (or assumed) that the world was internal to you. (ie. neither real, nor an [external] simulation)
						`.AsMultiline(0)}/>
					</Row>
					<Row>
						<CheckBox text="Semi-lucid" value={segment.semiLucid} onChange={val=>{
							const segments_new = Clone(entry.segments);
							segments_new[index] = segment.Extended({semiLucid: val});
							new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
						}}/>
						<InfoButton ml={5} text={`
							If not a lucid (see definition), but "felt like one", and had a similar outcome. (eg. where you can see how someone might call it one)
							Examples: Knowing it's not real life (without knowing it's a dream/internal); knowing/using major control/powers; remembering dream goals.
						`.AsMultiline(0)}/>
					</Row>
					<Row>
						<CheckBox text="Ordered" value={segment.ordered} onChange={val=>{
							const segments_new = Clone(entry.segments);
							segments_new[index] = segment.Extended({ordered: val});
							new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
						}}/>
						<InfoButton ml={5} text={`
							Mark true if:
							* The order of events in the short-text (events separated by ";" or new-line) matches that of the actual dream, and...
							* Each such event is unique, ie. given perfect memory of the dream, you could reliably know which of two randomly selected events happened after.
							Note: A dream-segment must be "ordered" for it to be included in the dream-quiz system, when using quiz type "comesAfter".
						`.AsMultiline(0)}/>
					</Row>
					<Button size={32} mdIcon="delete" style={{padding: "5px 12px", fontSize: 12}} onClick={()=>{
						ShowMessageBox({
							title: "Delete segment?", cancelButton: true,
							message: "Permanently delete this segment of the journal entry?",
							onOK: ()=>{
								new UpdateJournalEntry({id: entry._key, updates: {segments: segments.Exclude(segment)}}).Run();
							},
						});
					}}/>
					<CheckBox text="LT->ST" enabled={segment.longText.trim().length > 0} style={{fontSize: 12}}
						value={summarizeMode} onChange={val=>setSummarizeMode(val)}/>
					<CheckBox text="Cleanup" enabled={segment.longText.trim().length > 0} style={{fontSize: 12}}
						value={cleanupMode} onChange={val=>setCleanupMode(val)}/>
					<CheckBox text="Extras" value={uiState.extraControls} onChange={val=>RunInAction_Set(this, ()=>uiState.extraControls = val)}/>
					{uiState.extraControls && <>
						<Row>
							<Text>Length:</Text>
							<InfoButton ml={5} text="If the length specified includes unlisted events, please add placeholders (eg: [...]), so the listed events aren't misunderstood as spanning the whole duration."/>
							<TimeSpanInput ml={5} style={{width: 50}} largeUnit="minute" smallUnit="second" value={segmentLength} onChange={totalSeconds=>UpdateSegment(a=>a.length = totalSeconds)}/>
						</Row>
						<Row>
							<Text mr={5}>Wake:</Text>
							<VDateTime dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" value={segment.wakeTime ? Moment(segment.wakeTime) : null} onChange={val=>{
								UpdateSegment(a=>a.wakeTime = val?.valueOf() ?? null);
							}}/>
						</Row>
						<Row>
							<Text>Clarity:</Text>
							<InfoButton ml={5} text={`
								Richness/clarity of thoughts and perceptions, relative to your typical awake state. (eg. taking a stroll)

								General guidelines: (if descriptions don't match the given clarity-% for you, feel free to ignore)
								0 stars (0%): Completely unconscious (if exactly 0, field considered unspecified)
								1 stars (20%): Base non-lucid
								2 stars (40%): Enhanced non-lucid, or base lucid
								3 stars (60%): Enhanced lucid (eg. remembering goals)
								4 stars (80%): Enhanced lucid, taking charge
								5 stars (100%): Enhanced lucid, taking charge, life-like
								100+%: Lucid, and even richer/clearer than your typical awake state
								`.AsMultiline(0)}/>
							{clarityForm_final == ClarityForm.Percent &&
							<Spinner ml={5} min={0} max={500} value={(clarity_final * 100).RoundTo(.01)} onChange={val=>UpdateSegment(a=>a.clarity = val / 100)}/>}
							{clarityForm_final == ClarityForm.Stars &&
							<StarsRating allowRightClickFor0={true} titleFunc={starValue=>`Set clarity to ${starValue} stars (${starValue * 20}%) [right-click to clear]`}
								value={clarity_final * 5} onChange={val=>UpdateSegment(a=>a.clarity = val / 5)}/>}
							<Button ml={3} p="3px 5px" text={clarityForm_final == ClarityForm.Percent ? "%" : "S"} title={`Showing clarity as: ${ClarityForm[clarityForm_final]}`}
								onClick={()=>{
									RunInAction("JournalSegmentUI.clarityForm.onChange", ()=>store.main.journal.clarityForm = clarityForm_final == ClarityForm.Percent ? ClarityForm.Stars : ClarityForm.Percent);
									if (!clarity_final.IsMultipleOf(.2, .001)) {
										//UpdateSegment(a=>a.clarity = clarity_final.RoundTo(.2).KeepBetween(.2, 1));
										UpdateSegment(a=>a.clarity = clarity_final.RoundTo(.2).KeepBetween(0, 1));
									}
								}}/>
						</Row>
						<Row>
							<CheckBox text="No integration" value={segment.excludeFromIntegration ?? false} onChange={val=>UpdateSegment(a=>a.excludeFromIntegration = val)}/>
							<InfoButton ml={5} text="Excludes this segment from integration in engine components like dream-recall, dream-quiz, etc."/>
						</Row>
					</>}
				</Row>
				{summarizeMode &&
				<SegmentSummarizeUI entry={entry} segment={segment} segmentIndex={index}/>}
				{cleanupMode &&
				<SegmentCleanupUI entry={entry} segment={segment} segmentIndex={index}/>}
				<Row>
					<TextArea style={{border: "1px solid black"}} autoSize={true} /*autoSize_minHeight={true}*/ value={segment.shortText} onChange={val=>{
						//new UpdateJournalEntry({id: entry._key, updates: {[`segments/${index}/shortText`]: val}}).Run();
						const segments_new = Clone(entry.segments);
						segments_new[index] = segment.Extended({shortText: val});
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
				</Row>
				<Row>
					<TextArea style={{border: "1px solid black"}} autoSize={true} /*autoSize_minHeight={true}*/ value={segment.longText} onChange={val=>{
						const segments_new = Clone(entry.segments);
						segments_new[index] = segment.Extended({longText: val});
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
				</Row>
				<Row>
					<Text>Anchor entity:</Text>
					<TextInput ml={5} value={segment.anchorEntity} onChange={val=>{
						const segments_new = Clone(entry.segments);
						segments_new[index].VSet({anchorEntity: DelIfFalsy(val)});
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>
					{(!inJourneyUI || store.main.tools.journey.showAnchorEntities) && anchorEntity && <Text ml={5} style={{flex: 1}}>{anchorEntity?.name ?? ""}</Text>}
					{(!inJourneyUI || store.main.tools.journey.showAnchorEntities) && !anchorEntity && <Button ml={5} text="Random" onClick={()=>{
						const segments_new = Clone(entry.segments);
						segments_new[index] = segment.Extended({anchorEntity: anchorEntities.Random()?._key});
						new UpdateJournalEntry({id: entry._key, updates: {segments: segments_new}}).Run();
					}}/>}
				</Row>
				{showEntitiesSequence && // for now, simply show the count
				<Row>
					{/*<Text style={
						//segment.entitiesSequence.length > 0 && {color: "red"}
						segment.entitiesSequence!.length > 0 && {background: "red"}
					}>
						Entities sequence: (count: {segment.entitiesSequence!.length})
					</Text>*/}
					Short-text as term boxes: TODO
				</Row>}
			</>
		);
	}
}