import {Device, DeviceInfo} from "@capacitor/device";
import {minuteInMS, secondInMS} from "web-vcore";
import {SleepAsync, Timer, ToJSON} from "js-vextensions";
import {TriggerInfo} from "../Store/firebase/fbaConfigs/@TriggerSet_Activator";
import {EngineSessionInfo} from "../Store/firebase/sessions/@EngineSessionInfo.js";
import {LogType} from "../UI/Tools/@Shared/LogEntry.js";
import {FBASession} from "./FBASession";
import {hostConnection} from "./Remoting/HostConnection";

export class FBASession_Client extends FBASession {
	/*constructor(ourRemoteConfig: FBAConfig, hostConfig: FBAConfig) {
		super(ourRemoteConfig);
		this.hostConfig = hostConfig;
	}
	hostConfig: FBAConfig;*/

	RunTriggerActionOnHost(triggerName: string, triggerInfo: TriggerInfo) {
		this.Log(`Running trigger-action on host: ${triggerName} TriggerInfo: ${ToJSON(triggerInfo)}`, LogType.Action);
		hostConnection!.bridge.Call("RunTriggerAction", triggerName, triggerInfo);
	}

	// timers
	statusReportTimer: Timer;

	CreateTimers() {
		// todo: maybe ms the android foreground-service records its own status-reporting, so if this web code is suspended, it can still show it was running (by sharing its status-report info once this web code wakes again)
		let statusReportTimerTicks = 0;
		this.statusReportTimer = new Timer(minuteInMS, async()=>{
			statusReportTimerTicks++;

			const tickIndex = statusReportTimerTicks - 1;
			if (tickIndex % 5 == 0) {
				this.Log(`Status report #${tickIndex} (heavy, part 1). @PageOnline(${navigator.onLine})`, LogType.Event_Large);

				const waitTimeInSeconds = 30;
				let timeoutOccurred = false;

				let deviceInfo: DeviceInfo;
				const deviceInfoPromise = Device.getInfo().then(info=>{
					deviceInfo = info;
					if (timeoutOccurred) LogHeavy_Part2();
				});

				let fetchResult = `n/a (didn't respond within ${waitTimeInSeconds}s)`;
				//let fetchPromise = fetch("/Images/Basic/Black_1x1.png")
				const fetchPromise = fetch("/Images/Basic/Black_1x1.png", {cache: "no-cache"})
				//const fetchPromise = fetch("/Images/Basic/Black_1x1.png", {cache: "no-cache", headers: {pragma: "no-cache", "cache-control": "no-cache"}})
					.then(response=>{
						if (!response.ok) fetchResult = `fail (status: ${response.status}, ${response.statusText})`;
						//else fetchResult =  response.text();
						else fetchResult = "success";
						if (timeoutOccurred) LogHeavy_Part2();
					})
					.catch(error=>{
						fetchResult = `fail (error: ${error})`;
						if (timeoutOccurred) LogHeavy_Part2();
					});

				let hostResponse = `n/a (didn't respond within ${waitTimeInSeconds}s)`;
				const hostPromise = hostConnection?.bridge.Call("Ping").then(response=>{
					hostResponse = response as string;
					if (timeoutOccurred) LogHeavy_Part2();
				});

				// todo: if possible, also add way to see if firebase specifically (the db, etc.) is connected
				await Promise.race([SleepAsync(waitTimeInSeconds * secondInMS), Promise.all([deviceInfoPromise, fetchPromise, hostPromise])]);
				const LogHeavy_Part2 = ()=>{
					deviceInfo = deviceInfo || {} as any; // just make sure non-null, for easier code below
					//const batteryLevelStr = deviceInfo == null ? null : deviceInfo.batteryLevel.ToPercentStr();
					const batteryLevelStr = "<unknown>";
					const memUsedStr = deviceInfo == null ? null : `${deviceInfo.memUsed! / 1048576}mb`;
					if (timeoutOccurred) {
						this.Log(`	Status report #${tickIndex} (heavy, part 2, late). @Battery(${batteryLevelStr}) @MemUsed(${memUsedStr}) @ImageFetch(${fetchResult}) @HostPing(${hostResponse})`, LogType.Event_Large);
					} else {
						this.Log(`Status report #${tickIndex} (heavy, part 2). @Battery(${batteryLevelStr}) @MemUsed(${memUsedStr}) @ImageFetch(${fetchResult}) @HostPing(${hostResponse})`, LogType.Event_Large);
					}
				};
				LogHeavy_Part2();

				timeoutOccurred = true;
			} else {
				this.Log(`Status report #${tickIndex} (light). @PageOnline(${navigator.onLine})`);
			}
		}).SetContext(this.timerContext);
	}
}