import {Clone, CloneWithPrototypes, GetStackTraceStr, IsString, Assert} from "js-vextensions";
import Moment from "moment";
import {GetJournalEntries} from "../../../Store/firebase/journalEntries";
import {JournalEntry} from "../../../Store/firebase/journalEntries/@JournalEntry";
import {GetSounds, GetSounds_WithUserTag} from "../../../Store/firebase/sounds";
import {MeID} from "../../../Store/firebase/users";
import {SoundPlayer} from "../../../Utils/EffectPlayers/SoundPlayer";
import {AddErrorMessage, StopAllSpeech} from "web-vcore";
import {GetAsync} from "mobx-firelink";
import {RunUserScript, RunUserScript_CallArgsObj} from "../../../Utils/UserScripts/ScriptRunner";
import {TriggerPackage, FBASession} from "../../../Engine/FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp";
import {FBAConfig_DreamNarration} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamNarration";

export const dreamNarrationIgnoreStr = "[ignore]";

export class DreamNarrationComp extends EngineSessionComp<FBAConfig_DreamNarration> {
	constructor(session: FBASession, config: FBAConfig_DreamNarration) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("DreamNarration_RepeatDream", this.c.repeatDream_triggerSet, this, {}, async triggerInfo=>{
				if (this.CurrentDream == null) {
					this.journalEntries_toNarrate.push(await this.RandomDream());
				}
				if (this.journalEntries_toNarrate_currentIndex == -1) this.journalEntries_toNarrate_currentIndex = 0;
				this.NarrateDream(this.CurrentDream);
			}),
			new TriggerPackage("DreamNarration_NextDream", this.c.nextDream_triggerSet, this, {}, async triggerInfo=>{
				if (this.journalEntries_toNarrate_currentIndex >= this.journalEntries_toNarrate.length - 1) {
					this.journalEntries_toNarrate.push(await this.RandomDream());
				}
				this.journalEntries_toNarrate_currentIndex++;
				this.NarrateDream(this.CurrentDream);
			}),
			new TriggerPackage("DreamNarration_PreviousDream", this.c.previousDream_triggerSet, this, {}, async triggerInfo=>{
				if (this.journalEntries_toNarrate_currentIndex == 0) {
					this.journalEntries_toNarrate.Insert(0, await this.RandomDream());
				}
				this.journalEntries_toNarrate_currentIndex--;
				this.NarrateDream(this.CurrentDream);
			}),
			new TriggerPackage("DreamNarration_StopNarration", this.c.stopNarration_triggerSet, this, {}, triggerInfo=>{
				StopAllSpeech();
			}),
			new TriggerPackage("DreamNarration_IncreaseVolume", this.c.increaseVolume_triggerSet, this, {}, triggerInfo=>{
				this.volume = (this.volume + this.c.increaseVolume_amount).KeepAtMost(1);
				this.DreamNarration_PlayVolumePreviewSound();
			}),
			new TriggerPackage("DreamNarration_DecreaseVolume", this.c.decreaseVolume_triggerSet, this, {}, triggerInfo=>{
				//this.volume = (this.volume - this.c.decreaseVolume_amount).KeepAtLeast(0);
				this.volume = (this.volume - this.c.decreaseVolume_amount).KeepAtLeast(0.01); // we have to keep this above 0, otherwise it won't even play
				this.DreamNarration_PlayVolumePreviewSound();
			}),
		];
	}

	OnStart() {
		/*this.dreamNarration_volumePreviewSound_lingerTimer = new Timer(this.c.volumeChangeBackgroundSound_lingerTime * 1000, ()=>{
			if (this.effectSoundPlayer.sound == volumeChangeBackgroundSound) {
				this.effectSoundPlayer.Stop();
			}
		}, 1).SetContext(this.timerContext);*/
	}
	OnStop() {
		this.dreamNarrationSoundPlayer.Stop();
	}

	//dreamNarration_volumePreviewSound_lingerTimer: Timer;
	dreamNarrationSoundPlayer = new SoundPlayer(); // we use SoundPlayer (not TextSpeaker), since it's project-specific so understands Sound-objects directly

	journalEntries_all: JournalEntry[];
	journalEntries_toNarrate: JournalEntry[] = [];
	journalEntries_toNarrate_currentIndex = -1;
	volume = 1; // 0-1

	// dream narration
	// ==========

	get CurrentDream() {
		return this.journalEntries_toNarrate[this.journalEntries_toNarrate_currentIndex];
	}
	async RandomDream(excludeScriptsIgnoredByDreamNarrator = true) {
		//this.dreamList = this.dreamList || await GetAsync(()=>GetJournalEntries(MeID()));
		//const journalEntries_all = await GetAsync(()=>GetJournalEntries(MeID())); // re-get list each time, to prevent "found only 1 the first time (since still loading), so now locked on it" issue
		// the length-check is due to issue had before where the retrieved-list only had 1 item the first time called; not sure if still an issue
		this.journalEntries_all = this.journalEntries_all && this.journalEntries_all.length > 1 ? this.journalEntries_all : await GetAsync(()=>GetJournalEntries(MeID()));

		let dreamList_filtered = this.journalEntries_all;
		if (excludeScriptsIgnoredByDreamNarrator) {
			// don't cache filtered list (the filtering function might change filtering based on time or the like, eg. more of certain types of dreams toward end of session)
			const dreamList_narrationTexts = await Promise.all(this.journalEntries_all.map(dream=>this.GetDreamNarrationText(dream)));
			dreamList_filtered = this.journalEntries_all.filter((dream, index)=>{
				// err on the side of caution; if any segment is marked to be excluded from integration, skip the entire journal-entry
				if (dream.segments.some(a=>a.excludeFromIntegration)) return false;
				const narrationText = dreamList_narrationTexts[index];
				//return narrationText !== false;
				// if return-value of narration-template function is exactly the narration-ignore-string, then exclude this dream from the list
				return narrationText != dreamNarrationIgnoreStr;
			});
		}
		return dreamList_filtered.Random();
	}
	async GetDreamNarrationText(dream: JournalEntry) {
		const dreamCopy = Clone(dream) as JournalEntry;
		dreamCopy.tags = dreamCopy.tags || []; // make existence of tags prop consistent for user narration-template-func
		const date = Moment(dream.createdAt);
		const dateStr = date.format("YYYY-MM-DD HH:mm");

		let narrationText;
		// wrap with try, so that buggy user-script doesn't break engine execution
		try {
			narrationText = await RunUserScript_CallArgsObj(this.c.narrationTemplate, {session: this, dream: dreamCopy, date, dateStr});
			//Assert(IsString(narrationText) || IsBool(narrationText) || narrationText == null, "Narration-text must be string, bool, or null.");
			Assert(IsString(narrationText) || narrationText == null, "Narration-text must be string or null.");
		} catch (ex) {
			const message = "Failed to generate dream-narration text.";
			console.warn(message, ex);
			// only show notification if user is the config's creator (else, user-script could throw error that gives malicious instructions)
			//if (this.s.c.creator == MeID()) AddNotificationMessage(`${message} ${ex}`);
			AddErrorMessage(`${message} ${ex}`, ex?.stack ?? GetStackTraceStr());
			return null;
		}

		return narrationText as string;
	}
	async NarrateDream(dream: JournalEntry, honorNarrationIgnoreStr = true) {
		if (!this.s.IsLocal() || dream == null) return;

		const narrationText = await this.GetDreamNarrationText(dream);
		if (honorNarrationIgnoreStr && narrationText == dreamNarrationIgnoreStr) return;
		this.DreamNarration_NarrateText(narrationText!);

		/*this.dreamNarrationSpeaker.Speak({
			voice: this.c.voiceSoundTag || GetMainVoiceName(),
			text: narrationText,
			volume: this.dreamNarrationVolume,
		});*/
	}
	DreamNarration_NarrateText(text: string, player = this.dreamNarrationSoundPlayer) {
		const baseSound = GetSounds_WithUserTag(this.c.voiceSoundTag).Random();
		if (baseSound == null) return; // todo: show message or something
		player.sound = CloneWithPrototypes(baseSound).VSet({text, name: "Dream narration text"});
		/*if (this.effectSoundPlayer.youtubePlayer) {
			this.effectSoundPlayer.youtubePlayer.loop = this.c.volumeChangeBackgroundSound_loop;
		}*/
		player.Play(this.volume);
	}

	DreamNarration_PlayVolumePreviewSound() {
		// start playing volume-preview sound
		/*this.PlaySoundEffect(this.c.volumeChangeBackgroundSound, this.dreamNarrationVolume, this.c.volumeChangeBackgroundSound_loop);
		const volumeChangeBackgroundSound = this.effectSoundPlayer.sound;*/

		this.DreamNarration_NarrateText(this.c.volumeChangePreviewText, this.s.effectSoundPlayer);

		/*if (this.dreamNarration_volumePreviewSound_lingerTimer) this.dreamNarration_volumePreviewSound_lingerTimer.Stop();

		// wait for linger-period, then if preview-sound is still playing, stop it
		if (this.c.volumeChangeBackgroundSound_lingerTime != 0) {
			this.dreamNarration_volumePreviewSound_lingerTimer = new Timer(this.c.volumeChangeBackgroundSound_lingerTime * 1000, ()=>{
				if (this.effectSoundPlayer.sound == volumeChangeBackgroundSound) {
					this.effectSoundPlayer.Stop();
				}
			}, 1).SetContext(this.timerContext).Start();
		}*/
	}
}