import {ToInt} from "js-vextensions";
import {Command, MergeDBUpdates} from "mobx-firelink";
import {GetUser} from "../Store/firebase/users";

// todo: maybe remove
export function UserEdit(targetClass: Function) {
	/*if (targetClass.prototype instanceof Command_Old) {
		const oldPrepare = targetClass.prototype.Prepare;
		targetClass.prototype.Prepare = async function () {
			await oldPrepare.apply(this);
			this.user_oldEditCount = (await GetAsync(() => GetUserExtraInfo(this.userInfo.id)))?.edits ?? 0;
		};
	}*/

	if (targetClass.prototype instanceof Command) {
		const oldValidate = targetClass.prototype["Validate"];
		targetClass.prototype["Validate"] = function() {
			const result = oldValidate.apply(this);
			const user = GetUser(this.userInfo.id);
			if (user) {
				this.user_oldEditCount = user.edits ?? 0;
			}
			return result;
		};
	}

	const oldGetDBUpdates = targetClass.prototype.GetDBUpdates;
	targetClass.prototype.GetDBUpdates = function() {
		const updates = oldGetDBUpdates.apply(this);
		const newUpdates = {};
		if (this.user_oldEditCount != null) {
			newUpdates[`users/${this.userInfo.id}/.edits`] = this.user_oldEditCount + 1;
			newUpdates[`users/${this.userInfo.id}/.lastEditAt`] = Date.now();
		}
		return MergeDBUpdates(updates, newUpdates);
	};
}