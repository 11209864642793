import {Clone, GetEntries, GetErrorMessagesUnderElement, CloneWithPrototypes, E} from "js-vextensions";
import Moment from "moment";
import {Column, Pre, RowLR, Select, Spinner, TextInput, Text, Row, Button} from "react-vcomponents";
import {BaseComponent, BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {Sound, SoundType} from "../../../Store/firebase/sounds/@Sound";
import {InfoButton, YoutubeSpeed, YoutubeQuality, GetVoices, Observer, observer_simple} from "web-vcore";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {User} from "../../../Store/firebase/users/@User";
import {IDAndCreationInfoUI} from "../../@Shared/CommonPropUIs/IDAndCreationInfoUI";
import {BoxController, ShowMessageBox} from "react-vmessagebox";
import {AddSound} from "../../../Server/Commands/AddSound";
import {AddScene} from "../../../Server/Commands/AddScene";
import {Scene} from "../../../Store/firebase/scenes/@Scene";
import {TagsInput} from "../../../Utils/ReactComponents/TagsInput";
import {GetUser} from "../../../Store/firebase/users";
import React from "react";
import {PreviewImageSquare} from "../../@Shared/PreviewImageSquare.js";
import {DialogStyle} from "../../../Utils/UI/GlobalStyles.js";

export function ShowAddSceneDialog(initialData?: Partial<Scene>, postAdd?: (id: string)=>any) {
	let newEntry = new Scene(E({
		name: "",
	}, initialData));
	const getCommand = ()=>new AddScene({scene: newEntry});

	const boxController: BoxController = ShowMessageBox({
		title: "Add scene", cancelButton: true,
		message: observer_simple(()=>{
			const tempCommand = getCommand();
			boxController.UpdateOptions({okButtonProps: {
				enabled: tempCommand.Validate_Safe() == null,
				title: tempCommand.validateError,
			}});

			return (
				<Column style={DialogStyle({width: 600})}>
					<SceneDetailsUI baseData={newEntry} forNew={true}
						onChange={(val, error)=>{
							newEntry = val;
							boxController.UpdateUI();
						}}/>
				</Column>
			);
		}),
		onOK: async()=>{
			const id = await getCommand().Run();
			if (postAdd) postAdd(id);
		},
	});
}

const splitAt = 150, width = "100%";

@Observer
export class SceneDetailsUI extends BaseComponentPlus(
	{enabled: true} as {baseData: Scene, forNew: boolean, enabled?: boolean, style?, onChange?: (newData: Scene, ui: SceneDetailsUI)=>void},
	{newData: null as any as Scene, previewing: false},
) {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.baseData != this.props.baseData) { // if base-data changed
			this.SetState({newData: CloneWithPrototypes(props.baseData)});
		}
	}

	render() {
		const {baseData, forNew, enabled, style, onChange} = this.props;
		const {newData, previewing} = this.state;
		const Change = (..._)=>{
			if (onChange) onChange(this.GetNewData(), this);
			this.Update();
		};
		const creator = !forNew && GetUser(newData.creator);

		const propsEnhanced = {...this.props, Change, ...this.state, SetState: this.SetState};
		return (
			<Column style={style}>
				{!forNew &&
					<IDAndCreationInfoUI id={baseData._key} creatorID={newData.creator} createdAt={newData.createdAt}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Name:</Text>
					<TextInput pattern={name_general} required
						enabled={enabled} style={{width: "100%"}}
						value={newData.name} onChange={val=>Change(newData.name = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>URL:</Text>
					<TextInput required
						enabled={enabled} style={{width: "100%"}}
						value={newData.url} onChange={val=>Change(newData.url = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Suggested tags:</Text>
					<TagsInput enabled={enabled} style={{width: "100%"}} value={newData.tags} onChange={val=>Change(newData.tags = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Preview square:</Text>
					<Text>Center (x, y):</Text>
					<Spinner ml={5} enabled={enabled} min={0} max={100}
						value={(newData.square_center.x * 100).RoundTo(1)} onChange={val=>Change(newData.square_center.x = val / 100)}/>
					<Spinner enabled={enabled} min={0} max={100}
						value={(newData.square_center.y * 100).RoundTo(1)} onChange={val=>Change(newData.square_center.y = val / 100)}/>
					<Text ml={5}>Size:</Text>
					<Spinner ml={5} enabled={enabled} min={1} max={100}
						value={(newData.square_size * 100).RoundTo(1)} onChange={val=>Change(newData.square_size = val / 100)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Ground-edge (x, y):</Text>
					<Text>Left/top:</Text>
					<Spinner ml={5} enabled={enabled} min={0} max={100}
						value={(newData.groundEdge_left.x * 100).RoundTo(1)} onChange={val=>Change(newData.groundEdge_left.x = val / 100)}/>
					<Spinner enabled={enabled} min={0} max={100}
						value={(newData.groundEdge_left.y * 100).RoundTo(1)} onChange={val=>Change(newData.groundEdge_left.y = val / 100)}/>
					<Text ml={5}>Right/bottom:</Text>
					<Spinner ml={5} enabled={enabled} min={0} max={100}
						value={(newData.groundEdge_right.x * 100).RoundTo(1)} onChange={val=>Change(newData.groundEdge_right.x = val / 100)}/>
					<Spinner enabled={enabled} min={0} max={100}
						value={(newData.groundEdge_right.y * 100).RoundTo(1)} onChange={val=>Change(newData.groundEdge_right.y = val / 100)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Door:</Text>
					<Text>Base-pos:</Text>
					<Spinner ml={5} enabled={enabled} min={0} max={100}
						value={(newData.door_basePos.x * 100).RoundTo(1)} onChange={val=>Change(newData.door_basePos.x = val / 100)}/>
					<Spinner enabled={enabled} min={0} max={100}
						value={(newData.door_basePos.y * 100).RoundTo(1)} onChange={val=>Change(newData.door_basePos.y = val / 100)}/>
					<Text ml={5}>Height:</Text>
					<Spinner ml={5} enabled={enabled} min={1} max={100}
						value={(newData.door_height * 100).RoundTo(1)} onChange={val=>Change(newData.door_height = val / 100)}/>
				</RowLR>
				<Button text={previewing ? "Hide preview" : "Preview scene"} mt={5} width={200} onClick={()=>{
					this.SetState({previewing: !previewing});
				}}/>
				{previewing &&
				<div style={{position: "relative"}}>
					<PreviewImageSquare entry={newData} style={{width: "100%", paddingBottom: "100%"}}/>
				</div>}
			</Column>
		);
	}
	GetValidationError() {
		return GetErrorMessagesUnderElement(this.DOM)[0];
	}

	GetNewData() {
		const {newData} = this.state;
		return CloneWithPrototypes(newData) as Scene;
	}
}