import {AddSchema} from "mobx-firelink";
import {AlarmConfigBase, AlarmConfigBase_SchemaProps} from "./@AlarmConfig.js";

export class FBAConfig_CountdownAlarm extends AlarmConfigBase {
	//constructor(data: FBAConfig_CountdownAlarm) { super(data); }
}
AddSchema("FBAConfig_CountdownAlarm", {
	properties: {
		...AlarmConfigBase_SchemaProps as any,
	},
});