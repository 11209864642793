import {Bridge} from "js-vextensions";
import {runInAction} from "mobx";
import {store} from "../../Store/index.js";
import {OnStoreLoaded} from "../../Utils/General/GlobalHooks";
import {ShouldErrorBeIgnored, HandleError, RunInAction, AddNotificationMessage} from "web-vcore";
import {InDesktop, nativeBridge} from "./Bridge_Native";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";

// these are wrappers around the constants/functions exposed by lf-desktop, in Start_WebviewPreload.ts
// (we could just use preloadBridge, but for these we use electron's "contextBridge" system directly, for func-declarations and less overhead)
// ==========

export type Func<RT, T1, T2, T3, T4, T5, T6> = (arg1?: T1, arg2?: T2, arg3?: T3, arg4?: T4, arg5?: T5, arg6?: T6)=>RT;
function ExposeFunc<RT = any, T1 = unknown, T2 = unknown, T3 = unknown, T4 = unknown, T5 = unknown, T6 = unknown>(
	funcName: string,
	webFallback: Func<RT, T1, T2, T3, T4, T5, T6> = (()=>{}) as any,
): Func<RT, T1, T2, T3, T4, T5, T6> {
	/*const wrapperFunc = (...args)=>{
		const funcFromLFDesktop = g.preloadShares[funcName];
		Assert(funcFromLFDesktop, `Cannot function supplied by lf-desktop with the name "${funcName}".`);
		return funcFromLFDesktop(...args);
	}
	return wrapperFunc;*/
	if (!InDesktop()) return webFallback;
	return g.preloadShares[funcName] as (...args)=>any;
}

// BridgeSetup.ts
export const NodeBridge_AddChannelMessageReceiver = ExposeFunc("NodeBridge_AddChannelMessageReceiver");
export const NodeBridge_SendChannelMessage = ExposeFunc("NodeBridge_SendChannelMessage");
export const PreloadBridge_AddChannelMessageReceiver = ExposeFunc("PreloadBridge_AddChannelMessageReceiver");
export const PreloadBridge_SendChannelMessage = ExposeFunc("PreloadBridge_SendChannelMessage");
/*export const RemoteSiteBridge_RegisterFunction = ExposeFunc("RemoteSiteBridge_RegisterFunction");
export const RemoteSiteBridge_UnregisterFunction = ExposeFunc("RemoteSiteBridge_UnregisterFunction");
export const RemoteSiteBridge_Call = ExposeFunc("RemoteSiteBridge_Call");*/
//export const remoteSiteBridge = g.preloadShares?.remoteSiteBridge as Bridge;
export const RemoteSiteBridge_Init = ExposeFunc("RemoteSiteBridge_Init");
export const RemoteSiteBridge_UnregisterFunction = ExposeFunc("RemoteSiteBridge_UnregisterFunction");
export const RemoteSiteBridge_RegisterFunction = ExposeFunc("RemoteSiteBridge_RegisterFunction");
export const RemoteSiteBridge_Call = ExposeFunc("RemoteSiteBridge_Call");

// Others.ts
export const OpenWebSignInHelper = ExposeFunc("OpenWebSignInHelper");
export const IPCamera_StartStreaming = ExposeFunc("IPCamera_StartStreaming");
export const IPCamera_StopStreaming = ExposeFunc("IPCamera_StopStreaming");
export const ShowFolderInOSExplorer = ExposeFunc("ShowFolderInOSExplorer");
export const ShowFileInOSExplorer = ExposeFunc("ShowFileInOSExplorer");
export const GetLocalIPAddresses = ExposeFunc("GetLocalIPAddresses", ()=>[]);
/*export const GetLIFXBulbs = ExposeFunc("GetLIFXBulbs", ()=>[]);
export const RunLIFXCommand_LAN_Desktop = ExposeFunc("RunLIFXCommand_LAN_Desktop");*/
export const SetScreenBrightness = ExposeFunc<number>("SetScreenBrightness");
export const SetFullScreen = ExposeFunc<boolean>("SetFullScreen");
export const RequestMuseDevice_Reliable = ExposeFunc<Promise<BluetoothDevice>>("RequestMuseDevice_Reliable");

// Scripts.ts
export const GetLocalScripts = ExposeFunc("GetLocalScripts", ()=>[]);
export const ResetSessionScriptContext = ExposeFunc("ResetSessionScriptContext");
export const PlayScriptConfig_Backend = ExposeFunc("PlayScriptConfig_Backend");
export const StopScriptConfig_Backend = ExposeFunc("StopScriptConfig_Backend");
export const NotifyInterceptFuncCalled = ExposeFunc("NotifyInterceptFuncCalled");

// SessionReading.ts
export const GetNewLocalSessions = ExposeFunc("GetNewLocalSessions", ()=>[]);
export const GetSessionGeneralHistory = ExposeFunc("GetSessionGeneralHistory", ()=>({eegActivity: {activityByTime: {}}, gyroMotion: {motionsByTime: {}}, camFilenames: []}));
export const GetSessionEEGData = ExposeFunc("GetSessionEEGData", ()=>({left: new Float64Array(), right: new Float64Array(), sleepStages: [] as number[]}));
export const GetSessionGyroSampleFiles = ExposeFunc("GetSessionGyroSampleFiles", ()=>({x: new Float64Array(), y: new Float64Array(), z: new Float64Array()}));
export const CalculateSleepStagesForEEGSamples = ExposeFunc("CalculateSleepStagesForEEGSamples", ()=>[] as number[]);

// SessionWriting.ts
export const AppendSessionLogEntry = ExposeFunc("AppendSessionLogEntry");
export const AppendSessionHeadbandEEGSamplesForSecond = ExposeFunc("AppendSessionHeadbandEEGSamplesForSecond");
export const AppendSessionHeadbandGyroSamplesForSecond = ExposeFunc("AppendSessionHeadbandGyroSamplesForSecond");
export const AppendSessionGyroMotion = ExposeFunc("AppendSessionGyroMotion");
export const AppendSessionEEGActivity = ExposeFunc("AppendSessionEEGActivity");
export const SaveSessionGeneralData = ExposeFunc("SaveSessionGeneralData");
export const VerifySessionGeneralData = ExposeFunc("VerifySessionGeneralData");
export const DeleteSessionFolder = ExposeFunc("DeleteSessionFolder");
export const SaveStandaloneRecording = ExposeFunc("SaveStandaloneRecording");
export const SaveSessionRecording = ExposeFunc("SaveSessionRecording");
//export const ConvertJPGBufferToRawBuffer = ExposeFunc("ConvertJPGBufferToRawBuffer");
export const ExportSessionData = ExposeFunc("ExportSessionData");

// this is the actual Bridge instance; use this for communication needs that are harder to use the direct/contextBridge approach for
// 	(eg. registering funcs from site, that are callable from preload)
// ==========

export const preloadBridge = new Bridge({
	receiveChannelMessageFunc_adder: receiveChannelMessageFunc=>{
		if (!InDesktop()) return;
		PreloadBridge_AddChannelMessageReceiver(receiveChannelMessageFunc);
	},
	sendChannelMessageFunc: channelMessage=>{
		if (!InDesktop()) return;
		PreloadBridge_SendChannelMessage(channelMessage);
	},
	channel_stringifyChannelMessageObj: false,
	requireMainFuncForCalls: false, // for, eg. NotifyVolumeKeyDown listener in RecordingPanel.tsx
});
preloadBridge.RegisterFunction("FromPreload_HandleGlobalError", (errorStr: string)=>{
	// some frontend errors (specified in vwaf/Errors.ts), are received by preload's global error-handler as well, so filter them out
	if (ShouldErrorBeIgnored({message: errorStr} as ErrorEvent)) return;

	HandleError(errorStr as any); // todo: fix error<>error-str mismatch
});

preloadBridge.RegisterFunction("HandlePageReloadIncompatibleActionPerformed", (actionType: string)=>{
	//if (store.main.activePageReloadIncompatibleAction) return; // commented; update type to most recent
	RunInAction("HandlePageReloadIncompatibleActionPerformed", ()=>store.main.lastPageReloadIncompatibleAction = actionType);
});
if (InDesktop()) {
	OnStoreLoaded(async()=>{
		if (store.main.lastPageReloadIncompatibleAction != null) {
			const pageLoads = (await nativeBridge.Call("GetPageLoadCountForThisAppLaunch")) as number;
			if (pageLoads > 1) {
				AddNotificationMessage(`
					Before this page reload (load count: ${pageLoads}), an action was performed that has conflicts with page-reloads:
					==========
					${store.main.lastPageReloadIncompatibleAction}
					==========
					To ensure issues aren't hit, a full reload of the app can be performed (Alt+R). 
				`.AsMultiline(0));
			}
			RunInAction("onLoad_clearField_activePageReloadIncompatibleAction", ()=>store.main.lastPageReloadIncompatibleAction = null);
		}
	});

	preloadBridge.RegisterFunction("AddInterceptFunc", (funcPath: string, interceptFuncID: number, interceptFunc: Function)=>{
		if (!interceptNodes.has(funcPath)) {
			const node = new InterceptNode({path: funcPath});
			interceptNodes.set(funcPath, node);

			const pathParts = funcPath.split(".");
			let containerVal = g;
			for (const pathPart of pathParts.slice(0, -1)) {
				containerVal = containerVal[pathPart];
			}
			node.origFunc = containerVal[pathParts.Last()];
			node.rootInterceptFunc = function(...args) {
				//try {
				//console.warn("Root intercept func called.", args, node);
				for (const userInterceptFunc of node.userInterceptFuncs.values()) {
					try {
						userInterceptFunc.apply(this, args);
					} catch (ex) {
						SessionLog(`Hit error while running intercept func. @path:${funcPath} @interceptFunc:${interceptFunc} @error:${ex}`);
					}
				}
				/*} catch {
					// if we hit error just in the looping/logging code, then probably we are intercepting console.log, and thus can't safely log anything
				}*/
				return node.origFunc.apply(this, args);
			};

			// add root intercept-func
			containerVal[pathParts.Last()] = node.rootInterceptFunc;
		}

		interceptNodes.get(funcPath)!.userInterceptFuncs.set(interceptFuncID, interceptFunc);
	});
	preloadBridge.RegisterFunction("RemoveInterceptFunc", (funcPath: string, interceptFuncID: number)=>{
		//interceptFuncs.get(funcPath).RemoveAt(interceptFuncID);
		interceptNodes.get(funcPath)!.userInterceptFuncs.delete(interceptFuncID);
		// don't actually remove the root intercept-func; it causes no real harm, so just leave it
	});
}
export const interceptNodes = new Map<string, InterceptNode>();
class InterceptNode {
	constructor(data: Partial<InterceptNode>) { this.VSet(data); }
	path: string;
	origFunc: Function;
	rootInterceptFunc: Function;
	userInterceptFuncs = new Map<number, Function>();
}