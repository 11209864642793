import {GetValues_ForSchema} from "js-vextensions";
import {AddSchema} from "mobx-firelink";
import {TriggerSet} from "../@TriggerSet";

export enum OptionPoolOrdering {
	Alphabetically = 10,
	IndexInList = 20,
	StringLength = 30,
	Random = 40,
}
AddSchema("OptionPoolOrdering", {oneOf: GetValues_ForSchema(OptionPoolOrdering)});
export enum OptionPoolSource {
	Manual = 10,
	Geography = 20,
}
AddSchema("OptionPoolSource", {oneOf: GetValues_ForSchema(OptionPoolSource)});

export class FBAConfig_Memory {
	constructor(data?: Partial<FBAConfig_Memory>) {
		Object.assign(this, data);
	}

	enabled = true;

	startPoint = 0; // in seconds (if time trigger); in activity points (if eye-move trigger)
	tag?: string;
	intensityStart = 0; // 0-1
	intensityMax = 1; // 0-1
	intensityIncreasePerStep = 0; // 0-1
	stepSize = 1; // in seconds (if time trigger); in activity points (if eye-move trigger)

	// if sound
	// ==========

	separate?: boolean;
	//loop: boolean;

	// if memory
	// ==========

	voiceSoundTag?: string;

	optionPoolSize?: number;
	optionPoolOrdering?: OptionPoolOrdering;
	optionPoolSource?: OptionPoolSource;
	// if source is: Manual
	optionPoolLayers?: OptionPoolLayer[];
	// if source is: Geography
	optionPool_countriesEnabled?: string[];

	minHintInterval?: number; // seconds
	nextHint_triggerSet?: TriggerSet;
	previousHint_triggerSet?: TriggerSet;
	giveUp_triggerSet?: TriggerSet;
	targetMiss_snoozeMin?: number; // seconds
	targetMiss_snoozePenalty?: number; // seconds

	enumerationSubmission?: EnumerationSubmission;
	speechMatchSubmission?: SpeechMatchSubmission;
}
AddSchema("FBAConfig_Memory", {
	properties: {
		enabled: {type: "boolean"},

		startPoint: {type: "number"},
		tag: {type: "string"},
		intensityStart: {type: "number"},
		intensityMax: {type: "number"},
		intensityIncreasePerStep: {type: "number"},
		stepSize: {type: "number"},

		// if sound
		separate: {type: "boolean"},
		//loop: {type: "boolean"},

		// if memory
		voiceSoundTag: {type: "string"},
		optionPoolSize: {type: "number"},
		optionPoolOrdering: {oneOf: GetValues_ForSchema(OptionPoolOrdering)},
		optionPoolSource: {oneOf: GetValues_ForSchema(OptionPoolSource)},
		optionPoolLayers: {items: {$ref: "OptionPoolLayer"}},
		optionPool_countriesEnabled: {items: {type: "string"}},
		minHintInterval: {type: "number"},
		nextHint_triggerSet: {$ref: "TriggerSet"},
		previousHint_triggerSet: {$ref: "TriggerSet"},
		giveUp_triggerSet: {$ref: "TriggerSet"},
		targetMiss_snoozeMin: {type: "number"},
		targetMiss_snoozePenalty: {type: "number"},
		enumerationSubmission: {$ref: "EnumerationSubmission"},
		speechMatchSubmission: {$ref: "SpeechMatchSubmission"},
	},
});

// todo: finish RVP system, then integrate into FBA engine
/*export class FBAConfig_RVPPrompt {
	@O interval = 300;
	@O voicesEnabled = "{}";
	@O minVolume = 1;
	@O maxVolume = 1;
	@O minPitch = .5;
	@O maxPitch = 1.5;
	@O smoothing = 7;
}*/

export class EnumerationSubmission {
	enabled: boolean;
	submitPick_triggerSet: TriggerSet;
	nextOption_triggerSet: TriggerSet;
	previousOption_triggerSet: TriggerSet;
	changePick_snooze_enabled: boolean;
	changePick_snooze_duration: number; // seconds
	minPickInterval: number; // seconds
}
AddSchema("EnumerationSubmission", {
	properties: {
		enabled: {type: "boolean"},
		submitPick_triggerSet: {$ref: "TriggerSet"},
		nextOption_triggerSet: {$ref: "TriggerSet"},
		previousOption_triggerSet: {$ref: "TriggerSet"},
		changePick_snooze_enabled: {type: "boolean"},
		changePick_snooze_duration: {type: "number"},
		minPickInterval: {type: "number"},
	},
});

export class SpeechMatchSubmission {
	enabled: boolean;

	remote_recordAudioOnRemote: boolean;

	listenOrSubmitPick_triggerSet: TriggerSet;
	listen_backgroundSoundTag: string;
	listen_backgroundSoundVolume: number;
	validTermSensitivity: number; // Example testing sentence: He [ate] the pies he [won], in order [to] sue [for] peace.
	//maxDelayBetweenItems: number; // seconds
	maxCharsBetweenItems: number;
}
AddSchema("SpeechMatchSubmission", {
	properties: {
		enabled: {type: "boolean"},

		remote_recordAudioOnRemote: {type: "boolean"},

		listenOrSubmitPick_triggerSet: {$ref: "TriggerSet"},
		listen_backgroundSoundTag: {type: "string"},
		listen_backgroundSoundVolume: {type: "number"},
		validTermSensitivity: {type: "number"},
		//maxDelayBetweenItems: {type: "number"},
		maxCharsBetweenItems: {type: "number"},
	},
});

export class OptionPoolLayer {
	constructor(initialData?: Partial<OptionPoolLayer>, ...items: string[]) {
		this.VSet(initialData);
		this.enabled = true;
		this.items = items;
	}
	enabled: boolean;
	items: string[];
}
AddSchema("OptionPoolLayer", {
	properties: {
		enabled: {type: "boolean"},
		items: {items: {type: "string"}},
	},
});