import {ToJSON, E} from "js-vextensions";
import {GetAccessToken, ClaimSet} from "./GoogleAuth";
import {RobustFetch} from "./RobustFirebaseSet";

// todo: remove this, and change its caller to use the Whisper transcription system instead

export interface AudioConfig {
	enableWordInfo: boolean,
	speechContexts: SpeechContext[];
}
export interface SpeechContext {
	phrases: string[];
	boost: number;
}

const projectID = "lucid-frontier-prod";
// session-id can apparently be set to an arbitrary value, eg. "abcd1234-abcd-abcd-abcd-abcd12345678".
const sessionID = "aed4bee9-a076-f317-4878-9b1aabe048ee";
const serviceAccountEmail = `dialogflow-client@${projectID}.iam.gserviceaccount.com`;
// stored on multiple-lines, so horizontal-scrolling isn't needed
const serviceAccountPrivateKey = `
	-----BEGIN PRIVATE KEY-----
	MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDIPgA+wZUvi4Ei
	+ZKknK2vGoCjwQ58RirG2Py2yuBjQY73S4mDGW/4K691LSX9bMzQHS303IpquzLm
	y4Nm8iwA+l2lOwpqpRK02pqXpfbAjSsg5sTgulwI7RiP6ZXFzyUriU3vxvvpXqsW
	c54f4tLHZkvQE5xXGzJJAcOIDp7rXEQ5ojelnDoIECGmhK0sztt8tuQ7YZzcyrJ6
	bCEm6/W1g3LhuOML/3++T3Xr5yDJ66ZAj9aoivCXzoNpaKAb6fZEg4owWYSp39mM
	3Cabai4gQV8SNHU4Dgu0mtgHz3Elx/RRjr6QPTcvN1R2cJu4KlyWmKz5bj40dhPs
	8DajFThDAgMBAAECggEAIdS71N5+9pPywvZ4jKA+Be4L2t7UtDFkq8jgVX/MTmi2
	n1hfSMuE6+BxmfIBT6JQrj6n041pQ2QSUwUCU+PJhHbIWJCzj1YJZY3jfYiYOmr/
	ZOUoUTGbOBTayAG2gL+hL4xYoxCy4fhRHo91IT0A0JibaXHOwbbCFaybHQMfXXMh
	/nuCRi8GypgmB3LO+CCoQ6iJyO4jZKjcEz8o5e1yzKY7tpdSnGaFOzt4lTtB8BtW
	ZT74AJG8jT4RGr16MKG4HsOKPdqbDHkmQYBQxHh4elWbkm3CDX3WvEAIO+y7wWXH
	Uh1CgNIGWUcX+IfyKtXElAgR+iZwVMY9/1vRhM5nJQKBgQD1v2pjygyGtNcS5aA3
	vD/DErZTKrh+UYwTntn8+r0BJdsruGgTSFs2xXObpHiiuzNMR7WI/hQHdU/dt93o
	0hGfJcbGy1PQk1bI9AJ3e6TVBv9XEJDIJtcq5N2C5KyNVtUpJ0/wKuuTnAqYJsrq
	6hT7PYKlJ8xyo7C+azAP8x07HQKBgQDQmJYqCMcb5UaFuoDeqW6dIQzv9pgeTksl
	dj2TRn93d15ggV5g6Ji4ya1TxZF+IUeGLR6Sb7u5wAW+MalOSO8VvHL2hxf4hb1b
	lZyg6+34v8e9BrrR51uEx2YE2lTBnEGbFrfldNrpftGQ+xA5f18usipRhXyy8vAo
	fTwdGeKC3wKBgQCQpotJEbUt1WSDiwYzyGr/uBP6ADt4nyeEpQxLDIwUEORhZ7pb
	H0wRgUf7ZWRc5TPcwpjoeiXv/e105LWKRZx5wRKwER7UtuVug7KwAeeUPPovlWCj
	b7lXVj/MvC2VRHdUMf+rrpfb2w2hYbQvcnnHKP2tmS+OiyuN6inwOXoLQQKBgQDO
	b1OdAdbauoqSacKDmTZI9p5xFJ/yzhT8XAoQKGKZfl6+LwmR6l7J8X1BPHMCWjkI
	hrqMvVsRNwH3+fnN6cwCvN8hLwz//IsrieLaNuLpnBI19eUJOmeN/i/ueYwLl3It
	48vWn1DOZ51KV8z0uiMeeV/8EZkZF+eoilfljOpoDQKBgEmHHDUzWvP6KACbLGpl
	CTx14VJgCXORr5fX0DFpLqnIQQa+m4Nvj1aElqMc7AVOoG2s8qjYnWRBBbT5S97r
	5KMTMx0Q1ryWo37+JdKl0FyJfok2k5pXcsDtyGQEogNNVLqdmZNMq7y6ogL1yeJH
	SSjxPJcqkKfpoHjbU6c6ySPW
	-----END PRIVATE KEY-----
`.AsMultiline(0);

export async function ConvertSpeechToText(audioDataStr_opus: string, audioConfig?: Partial<AudioConfig>): Promise<string> {
	const claimSet = new ClaimSet(["https://www.googleapis.com/auth/cloud-platform"], serviceAccountEmail);
	const accessToken = await GetAccessToken(claimSet, serviceAccountPrivateKey);

	//const response = await RobustFetch("https://console.dialogflow.com/api/v2proxy/sessions/${sessionID}:detectIntent", {
	//const response = await RobustFetch(`https://dialogflow.googleapis.com/v2beta1/projects/${projectID}/agent/sessions/${sessionID}:detectIntent?access_token=${accessToken}`, {
	//const response = await RobustFetch(`https://dialogflow.googleapis.com/v2beta1/projects/${projectID}/agent/sessions/${sessionID}:detectIntent?key=${accessToken}`, {
	//const response = await RobustFetch(`https://dialogflow.googleapis.com/v2/{session=projects/${projectID}/agent/sessions/${sessionID}}:detectIntent`, {
	//const response = await RobustFetch(`https://dialogflow.googleapis.com/v2beta1/{session=projects/${projectID}/agent/sessions/${sessionID}}:detectIntent`, {
	const response = await RobustFetch(`https://dialogflow.googleapis.com/v2beta1/projects/${projectID}/agent/sessions/${sessionID}:detectIntent`, {
		method: "POST",
		headers: {
			authorization: `Bearer ${accessToken}`,
			"content-type": "application/json",
		},
		body: ToJSON({
			queryInput: {
				audioConfig: E(
					{
						/*audioEncoding: "AUDIO_ENCODING_LINEAR_16",
						sampleRateHertz: 48000,*/
						audioEncoding: "AUDIO_ENCODING_OGG_OPUS",
						sampleRateHertz: 16000,
						languageCode: "en",
					},
					audioConfig,
				),
			},
			queryParams: {
				timeZone: "America/Los_Angeles",
			},
			inputAudio: audioDataStr_opus,
		}),
	});

	const responseJSON = await response.json();
	console.log("Got responseJSON: ", responseJSON);
	const speechText = responseJSON.queryResult.queryText || "";
	return speechText;
}