import {Clone, GetEntries, GetErrorMessagesUnderElement, CloneWithPrototypes, E} from "js-vextensions";
import Moment from "moment";
import {Column, Pre, RowLR, Select, Spinner, TextInput, Text, Row, CheckBox, TimeSpanInput} from "react-vcomponents";
import {BaseComponent, BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {Sound, SoundType, EffectVisibility, WaveType} from "../../../Store/firebase/sounds/@Sound";
import {InfoButton, YoutubeSpeed, YoutubeQuality, GetVoices, Observer, observer_simple} from "web-vcore";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {User} from "../../../Store/firebase/users/@User";
import {IDAndCreationInfoUI} from "../../../UI/@Shared/CommonPropUIs/IDAndCreationInfoUI";
import {BoxController, ShowMessageBox} from "react-vmessagebox";
import {AddSound} from "../../../Server/Commands/AddSound";
import {TagsInput} from "../../../Utils/ReactComponents/TagsInput";
import {GetUser} from "../../../Store/firebase/users";
import {EffectPreviewButton} from "../../@Shared/TagTargetEntryUI";
import {DialogStyle} from "../../../Utils/UI/GlobalStyles.js";

export function ShowAddSoundDialog(initialData?: Partial<Sound>, postAdd?: (id: string)=>any) {
	let newEntry = new Sound(E({
		name: "",
		type: SoundType.YoutubeClip,
	}, initialData));
	const getCommand = ()=>new AddSound({sound: newEntry});

	const boxController: BoxController = ShowMessageBox({
		title: "Add sound", cancelButton: true,
		message: observer_simple(()=>{
			const tempCommand = getCommand();
			boxController.UpdateOptions({okButtonProps: {
				enabled: tempCommand.Validate_Safe() == null,
				title: tempCommand.validateError,
			}});

			return (
				<Column style={DialogStyle({width: 600})}>
					<SoundDetailsUI baseData={newEntry} forNew={true}
						onChange={(val, error)=>{
							newEntry = val;
							boxController.UpdateUI();
						}}/>
				</Column>
			);
		}),
		onOK: async()=>{
			const id = await getCommand().Run();
			if (postAdd) postAdd(id);
		},
	});
}

type Props = {baseData: Sound, forNew: boolean, enabled?: boolean, style?, onChange?: (newData: Sound, ui: SoundDetailsUI)=>void} & Partial<{creator: User}>;
type Props_Enhanced = Props & {newData: Sound, Change};

const splitAt = 120, width = "100%";

@Observer
export class SoundDetailsUI extends BaseComponentPlus({enabled: true} as Props, {} as {newData: Sound}) {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.baseData != this.props.baseData) { // if base-data changed
			const newData = CloneWithPrototypes(props.baseData);
			//Object.setPrototypeOf(newData, Sound.prototype); // MS newData's class is "Sound", so we can use Sound's class methods directly
			//Object.setPrototypeOf(newData, Object.getPrototypeOf(new Sound({}))); // MS newData's class is "Sound", so we can use Sound's class methods directly
			this.SetState({newData});
		}
	}

	render() {
		const {baseData, forNew, enabled, style, onChange} = this.props;
		const {newData} = this.state;
		const Change = (..._)=>{
			if (onChange) onChange(this.GetNewData(), this);
			this.Update();
		};
		const creator = !forNew && GetUser(newData.creator);

		const propsEnhanced = {...this.props, Change, ...this.state, SetState: this.SetState};
		return (
			<Column style={style}>
				{!forNew &&
					<IDAndCreationInfoUI id={baseData._key} creatorID={newData.creator} createdAt={newData.createdAt}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Name: </Text>
					<TextInput pattern={name_general} required
						enabled={enabled} style={{width: "100%"}}
						value={newData.name} onChange={val=>Change(newData.name = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Type: </Text>
					<Select options={GetEntries(SoundType)} enabled={enabled} style={{width: "100%"}}
						value={newData.type} onChange={val=>Change(newData.type = val, Sound.InitDefaults(newData))}/>
				</RowLR>
				{newData.type == SoundType.Beep &&
				<BeepUI {...propsEnhanced}/>}
				{newData.type == SoundType.YoutubeClip &&
				<YoutubeVideoUI {...propsEnhanced}/>}
				{newData.type == SoundType.Speech &&
				<SpeechUI {...propsEnhanced}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Row center>
						<Pre>Visibility:</Pre>
						<InfoButton ml={5} text={`
							Visible: Shown publicly in the list of sounds.
							Hidden: Hidden in sound list (other than to mods), but still accessible through reading of raw db contents. (so not guarantee of privacy)
						`.AsMultiline(0)}/>
					</Row>
					<Select options={GetEntries(EffectVisibility)} enabled={enabled} value={newData.visibility} onChange={val=>Change(newData.visibility = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Suggested tags:</Text>
					<TagsInput enabled={enabled} style={{width: "100%"}} value={newData.tags} onChange={val=>Change(newData.tags = val)}/>
				</RowLR>
				<EffectPreviewButton mt={5} group="sounds" entry={newData} startText="Preview sound" stopText="Stop preview" width={200}/>
			</Column>
		);
	}
	GetValidationError() {
		return GetErrorMessagesUnderElement(this.DOM)[0];
	}

	GetNewData() {
		const {newData} = this.state;
		return CloneWithPrototypes(newData) as Sound;
	}
}

class BeepUI extends BaseComponent<Props_Enhanced, {}> {
	render() {
		const {newData, enabled, Change} = this.props;
		return (
			<>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Volume: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} min={0} max={100} step={0.1}
						value={(newData.volume * 100).RoundTo(.1)} onChange={val=>Change(newData.volume = val / 100)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Frequency: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} min={0}
						value={newData.waveFrequency} onChange={val=>Change(newData.waveFrequency = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Type: </Text>
					<Select options={GetEntries(WaveType)} enabled={enabled} style={{width: "100%"}}
						value={newData.waveType} onChange={val=>Change(newData.waveType = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Duration: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} min={0}
						value={newData.waveDuration} onChange={val=>Change(newData.waveDuration = val)}/>
				</RowLR>
			</>
		);
	}
}

class YoutubeVideoUI extends BaseComponent<Props_Enhanced, {}> {
	render() {
		const {newData, enabled, Change} = this.props;
		return (
			<>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Video ID: </Text>
					<TextInput enabled={enabled} style={{width: "100%"}}
						value={newData.videoID} onChange={val=>Change(newData.videoID = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Start time: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} step={.1}
						value={newData.startTime} onChange={val=>Change(newData.startTime = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>End time: <InfoButton text="Enter 0 to have the video play to the end."/></Text>
					<Spinner enabled={enabled} style={{width: "100%"}} step={.1}
						value={newData.endTime} onChange={val=>Change(newData.endTime = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Volume: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} min={0} max={100}
						value={(newData.volume * 100).RoundTo(1)} onChange={val=>Change(newData.volume = val / 100)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Speed: </Text>
					<Select options={GetEntries(YoutubeSpeed)} enabled={enabled} style={{width: "100%"}}
						value={newData.speed} onChange={val=>Change(newData.speed = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Quality: </Text>
					<Select options={GetEntries(YoutubeQuality)} enabled={enabled} style={{width: "100%"}}
						value={newData.quality} onChange={val=>Change(newData.quality = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row>
						<CheckBox text="Loop" enabled={enabled} value={newData.loop} onChange={val=>Change(newData.loop = val)}/>
						<Text>, interval:</Text>
					</Row>
					<TimeSpanInput enabled={enabled && newData.loop} style={{width: "100%"}}
						value={newData.loopInterval} onChange={val=>Change(newData.loopInterval = val)}/>
					<InfoButton ml={5} text="Enter 0 to have video-segment loop as soon as its completed."/>
				</RowLR>
			</>
		);
	}
}

//@ObserveVoices
class SpeechUI extends BaseComponent<Props_Enhanced, {}> {
	render() {
		const {newData, enabled, Change} = this.props;
		const voiceOptions = [{name: "", value: null} as any].concat(GetVoices().map(a=>a.name));
		return (
			<>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Text: </Text>
					<TextInput enabled={enabled} style={{width: "100%"}}
						value={newData.text} onChange={val=>Change(newData.text = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Volume: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} min={0} max={100}
						value={(newData.volume * 100).RoundTo(1)} onChange={val=>Change(newData.volume = val / 100)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Speed: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} min={10} max={1000}
						value={(newData.speed * 100).RoundTo(1)} onChange={val=>Change(newData.speed = val / 100)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Voice: </Text>
					<Select options={voiceOptions} enabled={enabled} style={{width: "100%"}}
						value={newData.voice} onChange={val=>Change(newData.voice = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Text>Pitch: </Text>
					<Spinner enabled={enabled} style={{width: "100%"}} min={1} max={2000}
						value={(newData.pitch * 100).RoundTo(1)} onChange={val=>Change(newData.pitch = val / 100)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row>
						<CheckBox text="Loop" enabled={enabled} value={newData.loop} onChange={val=>Change(newData.loop = val)}/>
						<Text>, interval:</Text>
					</Row>
					<TimeSpanInput enabled={enabled && newData.loop} style={{width: "100%"}}
						value={newData.loopInterval} onChange={val=>Change(newData.loopInterval = val)}/>
				</RowLR>
			</>
		);
	}
}