import {GetErrorMessagesUnderElement, Clone, CloneWithPrototypes} from "js-vextensions";
import {Column, Pre, RowLR, TextInput, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {User} from "../../Store/firebase/users/@User";
import {Activity} from "./../../Store/firebase/activities/@Activity";

type Props = {baseData: Activity, forNew: boolean, enabled?: boolean, style?, onChange?: (newData: Activity)=>void};
export class ActivityDetailsUI extends BaseComponent<Props, {newData: Activity}> {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.baseData != this.props.baseData) { // if base-data changed
			this.SetState({newData: CloneWithPrototypes(props.baseData)});
		}
	}

	render() {
		const {forNew, enabled, style, onChange} = this.props;
		const {newData} = this.state;
		const Change = _=>{
			if (onChange) { onChange(this.GetNewData()); }
			this.Update();
		};

		const splitAt = 170, width = 600;
		return (
			<Column style={style}>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Name: </Text>
					<TextInput required
						enabled={enabled} style={{width: "100%"}}
						value={newData.name} onChange={val=>Change(newData.name = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Color: </Text>
					<TextInput required
						enabled={enabled} style={{width: "100%"}}
						value={newData.color} onChange={val=>Change(newData.color = val)}/>
				</RowLR>
			</Column>
		);
	}
	GetValidationError() {
		return GetErrorMessagesUnderElement(this.DOM)[0];
	}

	GetNewData() {
		const {newData} = this.state;
		return CloneWithPrototypes(newData) as Activity;
	}
}