import {Sound, SoundType, WaveType} from "../../Store/firebase/sounds/@Sound";
import {GetYoutubePlayersToKeepBuffered} from "../../Store/main/effects/sounds";
import {YoutubePlayer, GetUnownedYoutubePlayerReady, YoutubePlayerState, TextSpeaker, LogWarning} from "web-vcore";
import {SleepAsync, Timer} from "js-vextensions";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {Assert} from "mobx-firelink";
import {EffectPlayExtras, EffectPlayer} from "./EffectPlayer.js";
import {GetUserEntityTags, MeID} from "../../Store/firebase/users.js";
import {GetSounds_WithUserTag} from "../../Store/firebase/sounds.js";

export type PlayState = "playing" | "stopped" | "unknown";
export type PlayStateListener = (state: PlayState, removeListener: ()=>void)=>void;

export class SoundPlayer extends EffectPlayer {
	constructor(sound: Sound|n = null) {
		super();
		this.sound = sound as any; // we trust caller to supply a value before calling stuff on this object
	}

	private sound_: Sound;
	get sound() { return this.sound_; }
	set sound(val: Sound) {
		if (val == this.sound_) return;
		this.Stop();
		this.sound_ = val;
		this.prepared = false; // preparation is sound-specific
	}
	FindSound(tag: string, keepSoundIfStillValid = false) {
		const isOldSoundValid = GetUserEntityTags(MeID(), "sounds", this.sound?._key).includes(tag);
		if (!isOldSoundValid || !keepSoundIfStillValid) {
			this.sound = GetSounds_WithUserTag(tag).Random();
		}
		return this;
	}
	//ownerSession: FBASession;

	audioContext?: AudioContext|n;
	youtubePlayer?: YoutubePlayer|n;
	//youtubePlayer_lastPlayClipInfo?: YoutubeClipInfo|n;
	textSpeaker?: TextSpeaker|n;

	DisownInternalPlayer() {
		if (this.youtubePlayer) {
			this.youtubePlayer.hasOwner = false;
			this.youtubePlayer = null;
			this.prepared = false;
		}
	}

	SetLiveVolume(volumeMultiplier: number) {
		// we can only adjust the volume of a currently-playing sound, if it's using a youtube-player
		if (this.youtubePlayer) {
			const finalVolume = this.sound.volume * volumeMultiplier;
			this.youtubePlayer.SetVolume(finalVolume);
		}
	}

	prepared = false;
	async PrepareToPlay() {
		if (this.sound.type == SoundType.YoutubeClip) {
			if (this.youtubePlayer == null) {
				this.youtubePlayer = await GetUnownedYoutubePlayerReady(this.sound);
			}
			this.youtubePlayer.name = this.sound.name;

			/*let clipInfo = this.sound.IncludeKeys(...YoutubeClipInfo_props);
			if (!ShallowEquals(clipInfo, this.youtubePlayer_lastPlayClipInfo)) {*/
			await this.youtubePlayer.LoadVideo(this.sound);
			this.youtubePlayer?.SetPlaybackRate(this.sound.speed);
		}
		this.prepared = true;
	}

	playState: PlayState = "stopped"; // flag is not necessarily real-world accurate (effect may be overridden by other code)
	// Why using playIndex, not state-tracking helpers in other players? Because SoundPlayer has inherently reliable stop-tracking. (plus yt-player sub already has similar state-tracking)
	// (this class could still use some cleanup eventually, but best to leave good enough alone for now)
	playIndex = -1;

	//loopTimer: Timer;
	async Play(intensity = 1, extras?: Partial<EffectPlayExtras>) {
		const {allowLog} = new EffectPlayExtras(extras);
		if (this.sound == null) return; //void LogWarning("Failed to play sound, since it is null.");
		//if (this.playState == "playing") this.Stop(); // commented; no reason to call stop; starting next intrinsically overrides previous play
		const playIndex = ++this.playIndex;
		this.playState = "playing";

		const finalVolume = this.sound.volume * intensity;
		if (allowLog) SessionLog(`Playing sound:"${this.sound.name}" Volume:${this.sound.volume} FinalVolume:${finalVolume}`);

		if (!this.prepared) await this.PrepareToPlay();
		if (this.playState as PlayState == "stopped") return; // maybe stopped while prepping

		if (this.sound.type == SoundType.Beep) {
			if (this.audioContext == null) {
				this.audioContext = new AudioContext();
			}
			await Beep(this.audioContext, this.sound.waveFrequency, this.sound.waveType, this.sound.waveDuration, finalVolume);
		} else if (this.sound.type == SoundType.YoutubeClip) {
			Assert(this.youtubePlayer != null, "Sound-type is youtube-clip, and yet youtubePlayer is null!");
			this.youtubePlayer!.SetVolume(finalVolume);
			this.youtubePlayer!.Play();
			await this.youtubePlayer!.WaitTillState(YoutubePlayerState.PLAYING);

			// ensure that youtube-player is still attached (could have been removed by an external call to this.Stop())
			if (this.youtubePlayer) {
				if (this.sound.loop) {
					if (this.sound.loopInterval == 0) {
						await this.youtubePlayer.WaitTillState(YoutubePlayerState.ENDED, YoutubePlayerState.PAUSED, YoutubePlayerState.CUED);
					} else {
						await SleepAsync(this.sound.loopInterval * 1000);
					}
					if (this.playState as any == "stopped" || this.playIndex != playIndex) return; // if play stopped or superseded, return now
					await this.Play(intensity, {allowLog: false});
				} else {
					await this.youtubePlayer.WaitTillState(YoutubePlayerState.ENDED, YoutubePlayerState.PAUSED, YoutubePlayerState.CUED);
				}
			}
		} else if (this.sound.type == SoundType.Speech) {
			if (this.textSpeaker == null) {
				this.textSpeaker = new TextSpeaker();
			}

			await this.textSpeaker.Speak({text: this.sound.text, voice: this.sound.voice, volume: finalVolume, rate: this.sound.speed, pitch: this.sound.pitch});
		}
		this.playState = "stopped";
	}
	///** Returns true if there was an internal-player existant for us to call Stop() on. */
	Stop(disownInternalPlayer = true) {
		if (this.playState == "stopped") return;

		this.playState = "stopped";
		if (this.sound?.type == SoundType.Beep) {
			this.audioContext?.close();
			this.audioContext = null;
		} else if (this.sound?.type == SoundType.YoutubeClip && this.youtubePlayer) {
			this.youtubePlayer.PauseOrStop();
			if (disownInternalPlayer) {
				// disown internal youtube-player as soon as we're stopped, since usually our owner doesn't care-about/access our internal-player (and this helps replenish pool, unless/until we get played again)
				this.DisownInternalPlayer();
			}
			//this.prepared = false;
			//return true;
		} else if (this.sound?.type == SoundType.Speech && this.textSpeaker) {
			this.textSpeaker.Stop();
			//return true;
		}
	}
}

async function Beep(audioContext: AudioContext, freq: number, type: WaveType, duration: number, volume: number) {
	const oscillator = audioContext.createOscillator();
	const gainNode = audioContext.createGain();
	oscillator.connect(gainNode);
	gainNode.connect(audioContext.destination);

	gainNode.gain.value = volume;
	oscillator.frequency.value = freq;
	oscillator.type = type;
	oscillator.start();

	//oscillator.stop(context.currentTime + duration / 1000);
	//setTimeout(()=>oscillator.stop(), duration);
	/*const stopTimer = new Timer(duration, ()=>oscillator.stop());
	stopTimer.Start();
	return stopTimer;*/
	return new Promise<void>((resolve, reject)=>{
		setTimeout(()=>{
			oscillator.stop();
			resolve();
		}, duration);
	});
}