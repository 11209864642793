import {O} from "web-vcore";
import {makeObservable} from "mobx";
import {EntryVisibility} from "../firebase/entities/@Entity.js";
import {StoreAccessor} from "mobx-firelink";
import {GetStory} from "../firebase/stories.js";

export class ContentState {
	constructor() { makeObservable(this); }
	@O subpage = "scenes";

	@O lastEntityVisibility = EntryVisibility.public;
	
	// stories page
	@O selectedStory: string|n;
	@O hideSummarizedMessages = true;
	@O hideSummaryMessages_enabled = true;
	@O hideSummaryMessages_keepLastX = 2;
	@O hideSideBranchMessages_enabled = true;
	@O hideSideBranchMessages_keepLastX = 2;
	@O mark_1: string|n;
	@O mark_2: string|n;
}

export const GetSelectedStoryID = StoreAccessor(s=>()=>{
	return s.main.content.selectedStory;
});
export const GetSelectedStory = StoreAccessor(s=>()=>{
	const selectedID = GetSelectedStoryID();
	return GetStory(selectedID);
});