import {CheckBox, Column, Row, RowLR, Spinner, Text, TextInput, Select, TextArea} from "react-vcomponents";
import {ControlInput} from "../../../@Shared/ControlInput";
import {BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {InfoButton, Observer} from "web-vcore";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.dreamNarration, (c, v)=>c.dreamNarration = v);

const splitAt = 150;

@Observer
export class DreamNarration_ConfigUI extends BaseComponentPlus({} as {remote?: boolean} & FBASessionPanels_SharedProps, {}) {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Dream Narration" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					{/*remote &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Play audio on:</Text>
						<Select enabled={enabled} options={[{name: "Host (other device)", value: false}, {name: "Remote (this device)", value: true}]} value={config.playAudioOnRemote}
							onChange={val=>ChangeConfig(c=>c.playAudioOnRemote = val)}/>
					</RowLR>*/}
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Start volume:</Text>
						<Spinner enabled={enabled} value={config.startVolume} onChange={val=>ChangeConfig(c=>c.startVolume = val)} min={0} max={1000}/>
						<Text> %</Text>
					</RowLR>}
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						<Row center>
							<Text>Voice sound-tag:</Text>
							<InfoButton ml={5} mr={10} text="The dream-narration voice properties are copied from a random sound-effect configuration with the given tag."/>
						</Row>
						<TextInput enabled={enabled} style={{width: 200}} value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>
						{/*<Select enabled={enabled} options={[{name: "", value: null} as any].concat(GetVoices().map(a=>a.name))} style={{width: "100%"}}
							value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>*/}
					</RowLR>}
					{!client &&
					<>
						<Text mt={5}>Dream narration template:</Text>
						<TextArea enabled={enabled} autoSize={true} value={config.narrationTemplate} onChange={val=>ChangeConfig(c=>c.narrationTemplate = val)}/>
					</>}

					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Repeat dream:</Text>
						<ControlInput enabled={enabled} value={config.repeatDream_triggerSet} onChange={val=>ChangeConfig(c=>c.repeatDream_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Next dream:</Text>
						<ControlInput enabled={enabled} value={config.nextDream_triggerSet} onChange={val=>ChangeConfig(c=>c.nextDream_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Previous dream:</Text>
						<ControlInput enabled={enabled} value={config.previousDream_triggerSet} onChange={val=>ChangeConfig(c=>c.previousDream_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Stop narration:</Text>
						<ControlInput enabled={enabled} value={config.stopNarration_triggerSet} onChange={val=>ChangeConfig(c=>c.stopNarration_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Increase volume:</Text>
						<ControlInput enabled={enabled} value={config.increaseVolume_triggerSet} onChange={val=>ChangeConfig(c=>c.increaseVolume_triggerSet = val)}/>
						{!client &&
						<>
							<Text ml={10}>Amount: </Text>
							<Spinner enabled={enabled} min={0} max={100} value={(config.increaseVolume_amount * 100).RoundTo(1)} onChange={val=>ChangeConfig(c=>c.increaseVolume_amount = val / 100)}/>
							<Text> %</Text>
						</>}
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={10}>Decrease volume:</Text>
						<ControlInput enabled={enabled} value={config.decreaseVolume_triggerSet} onChange={val=>ChangeConfig(c=>c.decreaseVolume_triggerSet = val)}/>
						{!client &&
						<>
							<Text ml={10}>Amount: </Text>
							<Spinner enabled={enabled} min={0} max={100} value={(config.decreaseVolume_amount * 100).RoundTo(1)} onChange={val=>ChangeConfig(c=>c.decreaseVolume_amount = val / 100)}/>
							<Text> %</Text>
						</>}
					</RowLR>
					{!client &&
					<RowLR mt={5} splitAt={splitAt}>
						{/*<Text mr={10}>Volume change background sound:</Text>
						<TextInput enabled={enabled} style={{flex: 1}} value={config.volumeChangeBackgroundSound} onChange={val=>ChangeConfig(c=>c.volumeChangeBackgroundSound = val)}/>
						<CheckBox ml={5} enabled={enabled} text="Loop" checked={config.volumeChangeBackgroundSound_loop} onChange={val=>ChangeConfig(c=>c.volumeChangeBackgroundSound_loop = val)}/>
						<Text ml={10}>Linger time: </Text>
						<Spinner enabled={enabled} min={0} max={100} value={config.volumeChangeBackgroundSound_lingerTime} onChange={val=>ChangeConfig(c=>c.volumeChangeBackgroundSound_lingerTime = val)}/>
						<Text> seconds</Text>*/}
						<Text mr={10}>Volume change preview-text:</Text>
						<TextInput enabled={enabled} style={{flex: 1}} value={config.volumeChangePreviewText} onChange={val=>ChangeConfig(c=>c.volumeChangePreviewText = val)}/>
					</RowLR>}
				</>}
			</Column>
		);
	}
}