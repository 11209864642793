import {Assert, IsNaN, Range} from "js-vextensions";
//import {Line} from "react-chartjs-2";
import {BaseComponentPlus} from "react-vextensions";
import {HSL, HSLA} from "web-vcore";

const lineTypes = [
	{name: "Trigger-pixels", color: HSL(0, 0, 1)},
];

export class CameraMonitorChart extends BaseComponentPlus({} as {framesPerSecond: number}, {}) {
	graphedFramesPerChartWidth: number;
	render() {
		const {framesPerSecond} = this.props;
		const chartWidthInSeconds = 10;
		this.graphedFramesPerChartWidth = framesPerSecond * chartWidthInSeconds;

		const ticks = Range(0, this.graphedFramesPerChartWidth);
		const chartData = {
			labels: ticks.map(tickIndex=>{
				const time = tickIndex / framesPerSecond;
				return `${time < 10 ? "-" : ""}${(10 - time).toFixed(2)}`;
			}),
			datasets: [] as any[],
		};
		const chartOptions = {
			//tooltips: {mode: "index"},
			maintainAspectRatio: false,
			animation: false,
			elements: {point: {radius: 7}},
			legend: {
				// disabled for now, since only 1 thing being graphed; if bringing back, add option to choose alignment of legend
				display: false,
				//align: "end",
				labels: {
					boxWidth: 10,
					//fontColor: HSL(0, 0, 1),
					fontColor: HSLA(180, 1, .7, .7),
				},
			},
			scales: {
				xAxes: [{
					ticks: {
						fontColor: HSL(0, 0, 1),
					},
				}],
				yAxes: [{
					//display: true,
					ticks: {
						//min: 0, suggestedMin: 0, max: 200, suggestedMax: 200
						fontColor: HSL(0, 0, 1),
					},
				}],
			},
		};
		function AddLine(label: string, color: string) {
			const dataset = {
				label, backgroundColor: color, borderColor: color, borderWidth: 1, lineTension: 0, fill: false,
				pointBackgroundColor: "transparent", pointHoverRadius: 7, pointHoverBackgroundColor: color, pointBorderWidth: 0, pointBorderColor: "transparent",
				data: [], // this gets populated later on by ProcessBufferedSamples()
			};
			chartData.datasets.push(dataset);
			return dataset.data;
		}
		/*const baseMarker = AddLine("baseline", "white");
		baseMarker.AddRange(ticks.map(i=>0));*/
		for (const type of lineTypes) {
			//AddLine(type, sessionTypeColors[type]);
			AddLine(type.name, type.color);
		}

		return (
			<div style={{position: "absolute", top: 0, width: "100%", height: "100%", pointerEvents: "none"}}>
				{/*<ReactResizeDetector handleHeight> {/* fixes that line-chart wouldn't auto-size at-first/until-page-resize */}
				{/*<Line ref={c=>this.line = c} data={chartData} options={chartOptions as any} />*/}
				<div>TODO (replace chart lib)</div>
				{/*</ReactResizeDetector>*/}
			</div>
		);
	}
	//line: Line|n;

	triggeredPixelCounts = [] as number[];
	ProcessNextTriggeredPixelCount(deltaValue: number) {
		this.triggeredPixelCounts.push(deltaValue);
		const unprocessedFrames = (this.triggeredPixelCounts.length - 1) - this.currentFrameIndex;
		if (unprocessedFrames >= this.props.framesPerSecond * .5) {
			this.ProcessBufferedEntries();
		}
	}

	currentFrameIndex = 0;
	ProcessBufferedEntries() {
		// TODO (after replacing chart lib)
		/*if (this.line == null) return;
		const chartDataSets = this.line.chartInstance.data.datasets;

		let addedData = false;
		for (; this.currentFrameIndex < this.triggeredPixelCounts.length; this.currentFrameIndex++) {
			//if (this.currentSampleIndex % config.segmentLength != 0) continue;
			const frameDelta = this.triggeredPixelCounts[this.currentFrameIndex];

			for (const subtype of lineTypes) {
				const dataSet = chartDataSets.find(a=>a.label == subtype.name);
				//const newEntry = frameDeltas[subtype.name];
				Assert(!IsNaN(frameDelta), "Cannot add NaN as a point!");
				dataSet.data.push(frameDelta);
				if (dataSet.data.length > this.graphedFramesPerChartWidth) {
					dataSet.data.shift();
				}
			}
			addedData = true;
		}

		//let farthestDeviation = [0].concat(currentSession.lineData.VValues().SelectMany(a=>a.totalDeviation_atSamples)).Max(a=>Math.abs(a));
		/*const farthestDeviation = [0].concat(chartDataSets.SelectMany(a=>a.data as number[])).Max(a=>Math.abs(a));
		this.line.chartInstance.config.options.scales = {
			yAxes: [{
				display: true,
				ticks: {min: -Math.abs(farthestDeviation), suggestedMin: -Math.abs(farthestDeviation), max: Math.abs(farthestDeviation), suggestedMax: Math.abs(farthestDeviation)},
			}],
		};*#/

		if (addedData && this.line) this.line.chartInstance.update();
		/*requestAnimationFrame(()=>{
			if (addedData && this.line) this.line.chartInstance.update();
			//this.UpdateVisualizationUI();
		});*/
	}
}