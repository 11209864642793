import {AddSchema, NewSchema, GetSchemaJSON, AssertValidate, Command} from "mobx-firelink";
import {GetSound} from "../../Store/firebase/sounds";
import {Sound} from "../../Store/firebase/sounds/@Sound";

type MainType = Sound;
const MTName = "Sound";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.IncludeKeys(
				"name", "type", "visibility", "tags",
				"volume", "speed", "loop", "loopInterval",
				"waveFrequency", "waveType", "waveDuration",
				"videoID", "startTime", "endTime", "quality",
				"text", "voice", "pitch",
			),
		}),
	},
	required: ["id", "updates"],
}));

export class UpdateSound extends Command<{id: string, updates: Partial<MainType>}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = GetSound.NN(id);
		this.newData = {...this.oldData, ...updates} as MainType;
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`sounds/${id}`] = this.newData;
		return updates;
	}
}