import {EffectPointerPlayer} from "../../../../Store/firebase/@Shared/EffectPointerPlayer.js";
import {AlarmSequence} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {FBAConfig_LightAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@EC_BasicAlarms.js";
import {ClearScreenLight} from "../../../../Store/main/effects/lights";
import {ResetLight_Kasa} from "../../../../Utils/Bridge/Bridge_Native/Kasa.js";
import {FBASession} from "../../../FBASession";
import {AlarmComp} from "./@AlarmComp";

export class LightAlarmComp extends AlarmComp<FBAConfig_LightAlarm> {
	constructor(session: FBASession, sequence: AlarmSequence, config: FBAConfig_LightAlarm) {
		super(session, sequence, config);
	}

	promptPlayer = new EffectPointerPlayer();

	override OnStart() {
		this.promptPlayer.effectPointer = this.c.effectPointer;
		this.promptPlayer.OnStart();
	}
	override OnStop() {
		// in addition to the regular LightPlayer.Stop() behavior (through this.StopAlarm()), we also want to...
		// ...*ensure* lifx lights stopped
		//ResetLight_LIFX(); // commented; can cause fetch error
		// ...*ensure* kasa lights stopped
		ResetLight_Kasa();
		// ...*ensure* screen-light cleared
		ClearScreenLight();
		return super.OnStop();
	}

	override async PlayAlarmEffect() {
		await this.promptPlayer.Play(this.intensity);
	}
	override UpdatePrompt_ForReducedIntensity() {
		this.promptPlayer.NotifyReducedIntensity(this.intensity);
	}
	override StopAlarm(...args: Parameters<AlarmComp<any>["StopAlarm"]>) {
		this.promptPlayer.Stop();
		return super.StopAlarm(...args);
	}
}