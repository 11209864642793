import {E, ToJSON} from "js-vextensions";
import {Text} from "react-vcomponents";
import {FBAConfig_EEG} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_EEG.js";
import {EEGActivityProcessor} from "../../../UI/@Shared/Processors/EEGActivityProcessor.js";
import {EEGProcessor} from "../../../UI/@Shared/Processors/EEGProcessor.js";
import {AppendSessionEEGActivity, AppendSessionHeadbandEEGSamplesForSecond} from "../../../Utils/Bridge/Bridge_Preload.js";
import {currentMuse} from "../../../UI/Tools/@Shared/MuseInterface.js";
import {Muse_eegSamplesPerSecond_raw, EEGSample} from "../../../UI/Tools/@Shared/MuseInterface/EEGStructs.js";
import {FBASession} from "../../../Engine/FBASession.js";
import {CameraComp} from "./CameraComp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {GyroComp} from "./GyroComp.js";
import {AlarmComp} from "./AlarmComps/@AlarmComp.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";

export class EEGComp extends EngineSessionComp<FBAConfig_EEG> {
	constructor(session: FBASession, config: FBAConfig_EEG) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
		this.InitProcessors();
	}

	GetStatusUI() {
		return <Text style={{whiteSpace: "pre"}}>{`
			EEG activity: ${this.eegActivityProcessor.EEGActivity}
		`.AsMultiline(1)}</Text>;
	}

	OnStart_Early() {
		currentMuse.EnsureDeviceObtained();
	}
	OnStart() {
		currentMuse.eegListeners.push(this.eegListener);
		currentMuse.AddConnectRequester(this);
	}
	eegListener = (sample: EEGSample)=>{
		if (this.c.useSampleRounding) {
			sample = E(sample, {
				left: Math.round(sample.left),
				right: Math.round(sample.right),
				left_ear: Math.round(sample.left_ear ?? 0),
				right_ear: Math.round(sample.right_ear ?? 0),
			});
		}
		this.eegProcessor.AddAndProcessEEGSample(sample);
		this.eegActivityProcessor.RespondToSample(this.eegProcessor.samples_left.length - 1);
	};
	OnStop() {
		// commit any uncommitted log-entries
		//this.currentMotionTrigger_log = null;

		currentMuse.eegListeners.Remove(this.eegListener);
		currentMuse.RemoveConnectRequester(this);
	}

	eegProcessor: EEGProcessor;
	eegActivityProcessor: EEGActivityProcessor;
	InitProcessors() {
		this.eegProcessor = new EEGProcessor({options: E(this.c, {
			samplesProcessedPerSecond: Muse_eegSamplesPerSecond_raw,
			calc_normalize: true,
			calc_smooth: true,
			calc_combinedDeviation: true,
			calc_triggerSamplePercent: true,

			// EEGComp doesn't use uplotData, so set true (so customized arrays aren't created)
			uplotData_normalize: true,
			uplotData_smooth: true,

			clearOutOfRangeSamples: true,
		})}).Init_LiveSession();
		this.eegActivityProcessor = new EEGActivityProcessor({
			eegProcessor: this.eegProcessor,
			gyroMotionProcessor: this.s.Comp(GyroComp).gyroMotionProcessor, // atm this works (since GyroComp added before EEGComp), but this may change
			/*postRespondToNewSample: (sample, index, motionTriggering)=>{
				//const sampleTime = sample.time_uplot * 1000;
				const triggerSamplePercent_fraction = this.eegProcessor.samples_triggerSamplePercent[index] / 100; // sample's prop is as percentage, for display purposes

				if (motionTriggering) {
					function GetMotionTriggerLogMessage(eegActivity: number, triggerSamplePercent_peak: number) {
						return `EEG motion detected. @eegActivity:${eegActivity} @triggerSamplePercent_peak:${triggerSamplePercent_peak.ToPercentStr(0)}`;
					}
					const logEntry = this.currentMotionTrigger_log;
					if (logEntry) {
						logEntry.VSet("triggerSamplePercent_peak", // store non-enumerably, avoiding schema reject
							Math.max(triggerSamplePercent_fraction, ToNumber(logEntry["triggerSamplePercent_peak"], 0)));
						logEntry.message = GetMotionTriggerLogMessage(logEntry["eegActivity"], logEntry["triggerSamplePercent_peak"]);
					}
				} else {
					this.currentMotionTrigger_log = null;
				}

				// if camera-comp is supposed to record periods of eeg-motion, notify it when the specified conditions are met
				const camComp = this.s.Comp(CameraComp);
				if (camComp.behaviorEnabled && camComp.c.eegMotion_record && this.eegActivityProcessor.EEGActivity >= camComp.c.eegMotion_minActivity) {
					camComp.OnMotionTrigger();
				}
			},*/
			postSamplesBySecondEntryCompleted: (secondTime, secondSamples)=>{
				// record each second's samples to disk once it's over
				if (this.c.recordData_raw || this.c.recordData_extended) {
					AppendSessionHeadbandEEGSamplesForSecond(this.s.folderName, secondTime, secondSamples, this.c.recordData_raw, this.c.recordData_extended);
				}
			},
			postMotionTrigger: (sampleTime, shape, matchedPattern)=>{
				/*this.currentMotionTrigger_log = SessionLog("EEG motion detected. [details pending]", LogType.Event_Small, {blockAutoCommit: true});
				this.currentMotionTrigger_log.VSet("eegActivity", this.eegActivityProcessor.EEGActivity); // store non-enumerably, avoiding schema reject*/

				// if camera-comp is supposed to record periods of eeg-motion, notify it when the specified conditions are met
				const camComp = this.s.Comp(CameraComp);
				if (camComp.behaviorEnabled && camComp.c.eegMotion_record && this.eegActivityProcessor.EEGActivity >= camComp.c.eegMotion_minActivity) {
					camComp.OnMotionTrigger();
				}

				SessionLog(`Eye-move detected. @Shape:${ToJSON(shape)} @PatternIndex:${this.c.motion_eegPatterns.indexOf(matchedPattern)}`);
			},
			postEEGActivityChange: (eegActivity, sampleTime)=>{
				const eegPromptComps = this.s.components.filter(comp=>comp instanceof AlarmComp && comp.behaviorEnabled && comp.group == "eeg") as AlarmComp<any>[];
				for (const comp of eegPromptComps) {
					comp.NotifyEEGActivity(eegActivity);
				}

				// store eeg-activity over time (could be derived, but it's slow to change, so could otherwise require very long scan-backs)
				if (this.c.recordData_activity) {
					AppendSessionEEGActivity(this.s.folderName, eegActivity, sampleTime);
				}
			},
		});
	}

	/*_currentMotionTrigger_log: LogEntry;
	get currentMotionTrigger_log() { return this._currentMotionTrigger_log; }
	set currentMotionTrigger_log(val: LogEntry) {
		if (this._currentMotionTrigger_log && this._currentMotionTrigger_log != val) {
			this._currentMotionTrigger_log?.Commit();
		}
		this._currentMotionTrigger_log = val;
	}*/
}