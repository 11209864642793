import {Button, CheckBox, Column, Row, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {GetVoices, InfoButton, Observer, RunInAction, RunInAction_Set, TextPlus} from "web-vcore";
import {store} from "../../../../Store/index.js";
import {runInAction} from "mobx";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";
import {GetLights, GetLights_WithUserTag} from "../../../../Store/firebase/lights.js";
import {lifx_lightReset_defaultConfig} from "../../../../Utils/Services/LIFX_Web.js";
import {EffectPreviewButton} from "../../TagTargetEntryUI.js";
import {GetScripts_WithUserTag} from "../../../../Store/firebase/scripts.js";
import React from "react";
import {GetEntries, ToNumber} from "js-vextensions";
import {DragDirection} from "../../../../Store/main/settings.js";

function UpdateCurrentURLParam(key: string, value: string) {
	var params = new URLSearchParams(window.location.search);
	params.set(key, value);
	var newUrl = window.location.origin + window.location.pathname + "?" + params.toString();
	//if (history.pushState) window.history.pushState({path: newUrl}, "", newUrl);
	//if (history.replaceState) window.history.replaceState({path: newUrl}, "", newUrl);
	window.location.href = newUrl;
}

@Observer
export class SettingsUI_General extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.settings;
		const lightForReset = uiState.lightTagForReset ? GetLights_WithUserTag(uiState.lightTagForReset).Random() : lifx_lightReset_defaultConfig;
		const startupScript_preview = uiState.appStartupScriptTag ? GetScripts_WithUserTag(uiState.appStartupScriptTag).Random() : null;

		return (
			<>
				<Row center>
					<Text mr={5}>Reload page to env:</Text>
					<Button ml={5} text="dev" onClick={()=>UpdateCurrentURLParam("db", "dev")}/>
					<Button ml={5} text="prod" onClick={()=>UpdateCurrentURLParam("db", "prod")}/>
					<Button ml={5} text="[hard-reload]" style={{fontSize: 12}} onClick={()=>UpdateCurrentURLParam("random", `${Math.random()}`)}/>
				</Row>
				{InAndroid(1) &&
				<Row center>
					<Text mr={5}>Show nav-bar:</Text>
					<CheckBox ml={5} value={uiState.android_showNavBar} onChange={val=>RunInAction_Set(this, ()=>uiState.android_showNavBar = val)}/>
				</Row>}
				<Row center>
					<Text mr={5}>Lock-overlay drag direction:</Text>
					<Select ml={5} options={GetEntries(DragDirection)} value={uiState.lockOverlay_dragDirection} onChange={val=>RunInAction_Set(this, ()=>uiState.lockOverlay_dragDirection = val)}/>
				</Row>
				<Row center>
					<Text mr={5}>App-startup script tag:</Text>
					<TextInput value={uiState.appStartupScriptTag} onChange={val=>RunInAction_Set(this, ()=>uiState.appStartupScriptTag = val)}/>
					<InfoButton ml={5} text={`A random script with the given tag will be run (and have its effect played, with 100% intensity) when the app is launched.`}/>
					<EffectPreviewButton group="scripts" entry={startupScript_preview} enabled={startupScript_preview != null} style={{marginLeft: 5}}/>
				</Row>
				<Row mt={5} center>
					<Text mr={5}>Light tag for reset:</Text>
					<TextInput value={uiState.lightTagForReset} onChange={val=>RunInAction_Set(this, ()=>uiState.lightTagForReset = val)}/>
					<InfoButton ml={5} text={`
						When a session completes, it normally just turns the light off.
					
						If set, this tag makes it instead activate the given light effect (with brightness set to exactly 0.99999).
					`.AsMultiline(0)}/>
					<EffectPreviewButton group="lights" entry={lightForReset} enabled={lightForReset != null} style={{marginLeft: 5}}/>
				</Row>
				<Row mt={5} center>
					<Text mr={5}>Light re-apply delays:</Text>
					<TextInput style={{flex: 1}} value={(uiState.lightReapplyDelays || []).join(", ")} onChange={val=>{
						RunInAction_Set(this, ()=>uiState.lightReapplyDelays = val.split(",").map(a=>ToNumber(a.trim(), undefined)).filter(a=>a));
					}}/>
					<InfoButton ml={5} text={`
						If set, light-configs (of type Kasa) applied during sessions get applied multiple times.
						* Each number is the delay-duration (in seconds) for a re-apply operation, and they are applied sequentially.
						* Example: "1, 1, 1" means the light-config is re-applied 3 times (applied 4 times in total), with 1 second between each.
						* Useful for lights with unreliable commands, eg. where sequence [off -> 0.1s -> on] often results in light being off.
					`.AsMultiline(0)}/>
				</Row>
				<Row center mt={5}>
					<Text>Sequence item-wait:</Text>
					<Spinner ml={5} step={.05} min={0} max={1000} value={uiState.sequence_itemWait} onChange={val=>RunInAction_Set(this, ()=>uiState.sequence_itemWait = val)}/>
					<Text> seconds</Text>
					<InfoButton ml={5} text="After an item in a trigger-sequence is activated, how long to wait for the next item."/>
				</Row>
				<Row center mt={5}>
					<Text>Key-hold duration:</Text>
					<Spinner ml={5} step={.05} min={0} max={1000} value={uiState.keyHoldDuration} onChange={val=>RunInAction_Set(this, ()=>uiState.keyHoldDuration = val)}/>
					<Text> seconds</Text>
					<InfoButton ml={5} text="If a key is held for X seconds, it triggers a KeyHold event. (rather than the regular Key event on release)"/>
				</Row>
				<Row center mt={5}>
					<Text>Key-repeat threshold:</Text>
					<Spinner ml={5} step={.05} min={-1} max={1000} value={uiState.keyRepeatThreshold} onChange={val=>RunInAction_Set(this, ()=>uiState.keyRepeatThreshold = val)}/>
					<Text> seconds</Text>
					<InfoButton ml={5} text={`
						Android (and most OS's) repeats the key-down event for a key, when the key is held.
						Specify the point at which repeating should definitely have started, so that if repeating *doesn't* happen, we know the key hadn't actually been held down.
						(needed because the key-up event sometimes does not get sent, causing spurious key-hold events; set value to -1 to disable this attempted fix)
					`.AsMultiline(0)}/>
				</Row>
				<Row center mt={5}>
					<Text>Max sample-time drift:</Text>
					<Spinner ml={5} step={.05} min={0} max={10} value={uiState.maxSampleTimeDrift} onChange={val=>RunInAction_Set(this, ()=>uiState.maxSampleTimeDrift = val)}/>
					<Text> seconds</Text>
					<InfoButton ml={5} text={`How much drift/delay must be noticed between the (rigidly-progressing) "source time" of samples, and their processing-times, for the source-time counter to be reset/realigned.`}/>
				</Row>
				<Row center mt={5}>
					<Text>Test-segment start-buffer:</Text>
					<Spinner ml={5} step={.05} min={0} max={10} value={uiState.testSegmentStartBuffer} onChange={val=>RunInAction_Set(this, ()=>uiState.testSegmentStartBuffer = val)}/>
					<Text> seconds</Text>
					<InfoButton ml={5} text={`How many seconds of EEG samples prior to test-segment region, to include with test-segment. (try to keep this >= smoothed-deviation window duration)`}/>
				</Row>
				<Row center mt={5}>
					<Text>Fake sample-generator seed:</Text>
					<Spinner ml={5} min={undefined} value={uiState.fakeSampleGeneratorSeed} onChange={val=>RunInAction_Set(this, ()=>uiState.fakeSampleGeneratorSeed = val)}/>
					<InfoButton ml={5} text={`
						What seed to use for the fake eeg/gyro sample-generator (for testing). Set to -1, to hide the sample-generator feature.

						Note: If you need 100% sample replicability, make sure to wait a good few seconds after page load. (sample-generator options take a while to load/settle)
					`.AsMultiline(0)}/>
				</Row>
				<Row center mt={5}>
					<Text>Anthropic API key:</Text>
					<input type="password" style={{marginLeft: 5, flex: 1}} value={uiState.anthropicAPIKey ?? ""} onChange={e=>RunInAction_Set(this, ()=>uiState.anthropicAPIKey = e.target.value)}/>
					<InfoButton ml={5} text={`
						Used for generating text for the "Story Explore" feature. (using the Anthropic API)

						Note: This key is not stored in the database; it's only stored in your browser's local storage.
					`.AsMultiline(0)}/>
				</Row>
				<Row center mt={5}>
					<TextPlus info="See model list here: https://docs.anthropic.com/en/docs/about-claude/models">Anthropic model:</TextPlus>
					<TextInput ml={5} value={uiState.anthropicModel} onChange={val=>RunInAction_Set(this, ()=>uiState.anthropicModel = val)}/>
					<Button p="0 5px" text="3.5s" onClick={()=>RunInAction_Set(this, ()=>uiState.anthropicModel = "claude-3-5-sonnet-20240620")}/>
					<Button p="0 5px" text="3o" onClick={()=>RunInAction_Set(this, ()=>uiState.anthropicModel = "claude-3-opus-20240229")}/>
					<Button p="0 5px" text="3s" onClick={()=>RunInAction_Set(this, ()=>uiState.anthropicModel = "claude-3-sonnet-20240229")}/>
					<Button p="0 5px" text="3h" onClick={()=>RunInAction_Set(this, ()=>uiState.anthropicModel = "claude-3-haiku-20240307")}/>
				</Row>

				{InAndroid(1) &&
				<>
					<Row center mt={5}>
						<CheckBox text="Auto restart service" value={uiState.autoRestartService} onChange={val=>RunInAction_Set(this, ()=>uiState.autoRestartService = val)}/>
						<InfoButton ml={5} text={`
							The service is only restarted if it was closed by the system, while a session was in progress. (ie. the service still closes if session ended by user)

							Note: This setting alone might not be enough to keep the service functional. Also consider:
							* App Settings -> enable Autostart. (on Xiaomi) [can also "pin" app in recent-apps list, but needed every reboot, and shouldn't be necessary]
							* App Settings -> Battery saver -> No restrictions. (on Xiaomi)
							* Settings -> Additional -> Privacy -> Special App Access -> Battery optimization -> Lucid Frontier -> Don't optimize. (on Xiaomi)
							* Enable "Protect app". (on Huawei)
						`.AsMultiline(0)}/>
					</Row>
					<Row center mt={5}>
						<CheckBox text="Sessions: Check for crash every:" value={uiState.sessionCrashCheck_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.sessionCrashCheck_enabled = val)}/>
						<Spinner ml={5} enabled={uiState.sessionCrashCheck_enabled} step={.1} value={uiState.sessionCrashCheck_interval} onChange={val=>{
							RunInAction_Set(this, ()=>uiState.sessionCrashCheck_interval = val);
						}}/>
						<Text> minutes</Text>
						<InfoButton ml={5} text={`
							Android can kill off Lucid Frontier's activity and service in some cases, even if the app has a session running.
							This setting will schedule alarm-clock timers every X minutes; on triggering, if it detects the app had been killed, it restarts it and resumes the previous session. (as best as it can anyway)
							Note: There's a hard-limit of 500 alarms per app, so if interval is set to 1 minute, check-range will only extend to 8.33 hours; so a 2+ minute interval is recommended.
						`.AsMultiline(0)}/>
					</Row>
					<Row center mt={5}>
						<CheckBox text="Sessions: Wake js timers every:" value={uiState.sessionJSWake_enabled} onChange={val=>RunInAction_Set(this, ()=>uiState.sessionJSWake_enabled = val)}/>
						<Spinner ml={5} enabled={uiState.sessionJSWake_enabled} step={.1} value={uiState.sessionJSWake_interval} onChange={val=>{
							RunInAction_Set(this, ()=>uiState.sessionJSWake_interval = val);
						}}/>
						<Text> seconds</Text>
						<InfoButton ml={5} text={`
							Due to Android-webviews disabling timers when the screen is off, this enables manual triggering of overdue js-timer functions, from two sources:
							* Every X seconds. (during engine sessions)
							* Whenever an input-event is received by engine session, OR phone tilt data is received (~2 times per second).
							WARNING: Don't use this unless you have to! It's had bugs in the past where it could cause double-firings of timers, leading to hard-to-diagnose logic bugs!
							(Actually, current code also has this issue; will remove this note once resolved [from latest jsve getting published and integrated].)
						`.AsMultiline(0)}/>
					</Row>
				</>}
			</>
		);
	}
}