import {AddSchema, AssertValidate, Command, GetSchemaJSON, NewSchema} from "mobx-firelink";
import {GetShake} from "../../Store/firebase/shakes";
import {Shake} from "../../Store/firebase/shakes/@Shake";

type MainType = Shake;
const MTName = "Shake";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.IncludeKeys(
				"name", "type", "visibility", "tags",
				"loop", "loopInterval", "subtype",
				"levels",
			),
		}),
	},
	required: ["id", "updates"],
}));

export class UpdateShake extends Command<{id: string, updates: Partial<MainType>}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = GetShake.NN(id);
		this.newData = {...this.oldData, ...updates} as MainType;
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`shakes/${id}`] = this.newData;
		return updates;
	}
}