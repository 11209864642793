import {AddSchema} from "mobx-firelink";
import {Text} from "react-vcomponents";
import {CompState, CycleEntry, FBAConfig_ModeSwitcher} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_ModeSwitcher.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";

// technically we could make all comps suspendable, but let's keep it simple to implement/confirm for the moment
export const SwitchableCompID_values = [
	"DreamQuiz",
	"DreamTranscribe",
	"ConceptLink",
	"RealityCheck",
] as const;
export type SwitchableCompID = typeof SwitchableCompID_values[number];
AddSchema("SwitchableCompID", {
	oneOf: SwitchableCompID_values.map(a=>({const: a})),
});

/*export function JourneyInputMode_IsEnabled(inputMode: JourneyInputMode, config: FBAConfig) {
	if (inputMode == JourneyInputMode.DreamRecall) return config.dreamRecall.enabled;
	//if (inputMode == JourneyInputMode.Training) return config.journeyTraining.enabled;
	if (inputMode == JourneyInputMode.Visualization) return config.journeyVisualization.enabled;
	if (inputMode == JourneyInputMode.Grid) return config.journeyGrid.enabled;
	//if (inputMode == JourneyInputMode.Entry) return config.journeyEntry.enabled;
	Assert(false, `Invalid journey input-mode:${inputMode}`);
}
export function GetNextJourneyInputMode(currentModeIndex: number, config: FBAConfig) {
	const validInputModes = JourneyInputMode_values.filter(a=>JourneyInputMode_IsEnabled(a, config));
	const currentIndex = currentInputMode ? validInputModes.indexOf(currentInputMode).IfN1Then(-1) : -1;
	return validInputModes[(currentIndex + 1) % validInputModes.length]
}*/

export class ModeSwitcherComp extends EngineSessionComp<FBAConfig_ModeSwitcher> {
	constructor(session: FBASession, config: FBAConfig_ModeSwitcher) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
		this.cycleEntries_forLaunchType = this.c.cycleEntries.filter(entry=>{
			if (entry.requiredLaunchType && entry.requiredLaunchType != session.coreData.launchType) return false;
			return true;
		});
	}

	cycleEntries_forLaunchType: CycleEntry[];

	GetTriggerPackages() {
		return [
			new TriggerPackage("CycleActiveComp", this.c.cycleActiveComp_triggerSet, this, {}, async triggerInfo=>{
				const oldCompIndex = this.activeCompIndex;
				const newCompIndex = (oldCompIndex + 1) % this.cycleEntries_forLaunchType.length;
				this.SetActiveComp(newCompIndex, true);
			}),
		];
	}
	GetStatusUI() {
		return <Text style={{whiteSpace: "pre"}}>{`TODO`.AsMultiline(1)}</Text>;
	}
	
	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.s.c.general.defaultVoice_volumeMultiplier * volumeMultiplier);
	}

	OnStart() {
		for (const entry of this.c.startStates) {
			const comp = this.s.components.find(a=>a.constructor.name == `${entry.compID}Comp`)!;
			const startState = this.s.coreData.launchType == "day" ? entry.startState_day : entry.startState_night;
			ApplyCompState(comp, startState);
		}
	}

	private activeCompIndex = 0;
	SetActiveComp(newCompIndex: number, announce: boolean) {
		const oldCompIndex = this.activeCompIndex;
		const oldEntry = this.cycleEntries_forLaunchType[oldCompIndex] as CycleEntry|n;
		const newEntry = this.cycleEntries_forLaunchType[newCompIndex] as CycleEntry|n;
		this.activeCompIndex = newCompIndex;

		const oldComp = oldEntry ? this.s.components.find(a=>a.constructor.name == `${oldEntry.compID}Comp`) : null;
		const newComp = newEntry ? this.s.components.find(a=>a.constructor.name == `${newEntry.compID}Comp`) : null;
		// if no change in active-comp, don't suspend/unsuspend anything (nicer for eg. daytime mode, when all but one comp may be disabled)
		if (newComp == oldComp) return;

		oldComp?.Suspend();
		newComp?.Unsuspend();

		this.Log(`Mode-switcher set active comp: ${newEntry?.compID}`, LogType.Event_Large);
		if (announce && newEntry) this.NarrateText(newEntry.compID);
	}
	SetActiveComp_ByCompID(newCompID: SwitchableCompID, announce: boolean) {
		const newEntryIndex = this.cycleEntries_forLaunchType.findIndex(a=>a.compID == newCompID);
		if (newEntryIndex == -1) return void this.Log(`Failed to set active comp by ID: ${newCompID}`, LogType.Event_Large);
		this.SetActiveComp(newEntryIndex, announce);
	}
}

function ApplyCompState(comp: EngineSessionComp, state: CompState) {
	if (state.enabled && comp.IsSuspended()) {
		comp.Unsuspend();
	} else if (!state.enabled && !comp.IsSuspended()) {
		comp.Suspend();
	}
}