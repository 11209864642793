import {BaseComponent} from "react-vextensions";
import {FBAConfig_Alarms, FBAConfig_XAlarm} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Alarms.js";
import {FBAConfig_Background} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Background.js";
import {FBAConfig_Camera} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Camera.js";
import {FBAConfig_DreamNarration} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamNarration.js";
import {FBAConfig_DreamQuiz} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {FBAConfig_DreamRecall} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamRecall.js";
import {FBAConfig_DreamTranscribe} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamTranscribe.js";
import {FBAConfig_EEG} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_EEG.js";
import {FBAConfig_General} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_General.js";
import {FBAConfig_Gyro} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Gyro.js";
import {FBAConfig_JourneyEntry} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyEntry.js";
import {FBAConfig_JourneyGrid} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyGrid.js";
import {FBAConfig_JourneyTraining} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyTraining.js";
import {FBAConfig_JourneyVisualization} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_JourneyVisualization.js";
import {FBAConfig_Memory} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Memory.js";
import {FBAConfig_MicroSnooze} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_MicroSnooze.js";
import {FBAConfig_ModeSwitcher} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_ModeSwitcher.js";
import {FBAConfig_StoryExplore} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_StoryExplore.js";
import {AlarmSequence, AlarmType} from "../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {Alarms_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/Alarms_ConfigUI.js";
import {Background_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/Background_ConfigUI.js";
import {CameraPanel} from "../../UI/Tools/Engine/SessionTabPanels/Camera_ConfigUI.js";
import {DreamNarration_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/DreamNarration_ConfigUI.js";
import {DreamQuiz_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/DreamQuiz_ConfigUI.js";
import {DreamRecall_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/DreamRecall_ConfigUI.js";
import {DreamTranscribe_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/DreamTranscribe_ConfigUI.js";
import {EEG_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/EEG_ConfigUI.js";
import {EffectCountsPanel} from "../../UI/Tools/Engine/SessionTabPanels/EffectCountsPanel.js";
import {General_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/General_ConfigUI.js";
import {Gyro_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/Gyro_ConfigUI.js";
import {JourneyEntry_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/JourneyEntry_ConfigUI.js";
import {JourneyGrid_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/JourneyGrid_ConfigUI.js";
import {JourneyTraining_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/JourneyTraining_ConfigUI.js";
import {JourneyVisualization_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/JourneyVisualization_ConfigUI.js";
import {Memory_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/Memory_ConfigUI.js";
import {MicroSnooze_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/MicroSnooze_ConfigUI.js";
import {ModeSwitcher_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/ModeSwitcher_ConfigUI.js";
import {StoryExplore_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/StoryExplore_ConfigUI.js";
import {FBASession} from "../FBASession.js";
import {CountdownAlarmComp} from "./Components/AlarmComps/CountdownAlarmComp.js";
import {LightAlarmComp} from "./Components/AlarmComps/LightAlarmComp.js";
import {ScriptAlarmComp} from "./Components/AlarmComps/ScriptAlarmComp.js";
import {ShakeAlarmComp} from "./Components/AlarmComps/ShakeAlarmComp.js";
import {SoundAlarmComp} from "./Components/AlarmComps/SoundAlarmComp.js";
import {AlarmsComp} from "./Components/AlarmsComp.js";
import {BackgroundComp} from "./Components/BackgroundComp.js";
import {CameraComp} from "./Components/CameraComp.js";
import {DreamNarrationComp} from "./Components/DreamNarrationComp.js";
import {DreamQuizComp} from "./Components/DreamQuizComp.js";
import {DreamRecallComp} from "./Components/DreamRecallComp.js";
import {DreamTranscribeComp} from "./Components/DreamTranscribeComp.js";
import {EEGComp} from "./Components/EEGComp.js";
import {EngineSessionComp} from "./Components/EngineSessionComp.js";
import {GeneralComp} from "./Components/GeneralComp.js";
import {GyroComp} from "./Components/GyroComp.js";
import {JourneyEntryComp} from "./Components/JourneyEntryComp.js";
import {JourneyGridComp} from "./Components/JourneyGridComp.js";
import {JourneyTrainingComp} from "./Components/JourneyTrainingComp.js";
import {JourneyVisualizationComp} from "./Components/JourneyVisualizationComp.js";
import {MemoryComp} from "./Components/MemoryComp.js";
import {MicroSnoozeComp} from "./Components/MicroSnoozeComp.js";
import {ModeSwitcherComp} from "./Components/ModeSwitcherComp.js";
import {StoryExploreComp} from "./Components/StoryExploreComp.js";
import {FBAConfig_ConceptLink} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_ConceptLink.js";
import {ConceptLinkComp} from "./Components/ConceptLinkComp.js";
import {ConceptLink_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/ConceptLink_ConfigUI.js";
import {RealityCheck_ConfigUI} from "../../UI/Tools/Engine/SessionTabPanels/RealityCheck_ConfigUI.js";
import {FBAConfig_RealityCheck} from "../../Store/firebase/fbaConfigs/@EngineConfig/@EC_RealityCheck.js";
import {RealityCheckComp} from "./Components/RealityCheckComp.js";

// Main purpose of this collection: To ensure each fba-config script file "executes at runtime", so the AJV definitions get registered.
// Secondary purpose: To allow "finding" of an engine-session component-class by name. (without needing a live session)
export const engineCompClasses: Array<[any, new(..._)=>EngineSessionComp]> = [
	[FBAConfig_General, GeneralComp],
	[FBAConfig_ModeSwitcher, ModeSwitcherComp],
	[FBAConfig_Background, BackgroundComp],
	[FBAConfig_Camera, CameraComp],
	[FBAConfig_Gyro, GyroComp],
	[FBAConfig_EEG, EEGComp],
	[FBAConfig_Alarms, AlarmsComp],
	[FBAConfig_MicroSnooze, MicroSnoozeComp],
	[FBAConfig_Memory, MemoryComp],
	[FBAConfig_DreamTranscribe, DreamTranscribeComp],
	[FBAConfig_DreamNarration, DreamNarrationComp],
	[FBAConfig_DreamRecall, DreamRecallComp],
	[FBAConfig_DreamQuiz, DreamQuizComp],
	[FBAConfig_ConceptLink, ConceptLinkComp],
	[FBAConfig_RealityCheck, RealityCheckComp],
	[FBAConfig_StoryExplore, StoryExploreComp],
	[FBAConfig_JourneyTraining, JourneyTrainingComp],
	[FBAConfig_JourneyVisualization, JourneyVisualizationComp],
	[FBAConfig_JourneyGrid, JourneyGridComp],
	[FBAConfig_JourneyEntry, JourneyEntryComp],
];

// we extract this into a standalone function, for close proximity to collection above (so we don't forget updating one with the other)
export function FBASession_CreateComponents(this: FBASession) {
	this.AddComponent(new GeneralComp(this, this.c.general));
	this.AddComponent(new ModeSwitcherComp(this, this.c.modeSwitcher));
	this.AddComponent(new BackgroundComp(this, this.c.background));
	this.AddComponent(new CameraComp(this, this.c.camera));
	this.AddComponent(new GyroComp(this, this.c.gyro));
	this.AddComponent(new EEGComp(this, this.c.eeg));
	this.AddComponent(new AlarmsComp(this, this.c.alarms));
	this.AddComponent(new MicroSnoozeComp(this, this.c.microSnooze));
	this.AddComponent(new MemoryComp(this, this.c.memory, this.hc.memory));
	this.AddComponent(new DreamTranscribeComp(this, this.c.dreamTranscribe));
	this.AddComponent(new DreamNarrationComp(this, this.c.dreamNarration));
	this.AddComponent(new DreamRecallComp(this, this.c.dreamRecall));
	this.AddComponent(new DreamQuizComp(this, this.c.dreamQuiz));
	this.AddComponent(new ConceptLinkComp(this, this.c.conceptLink));
	this.AddComponent(new RealityCheckComp(this, this.c.realityCheck));
	this.AddComponent(new StoryExploreComp(this, this.c.storyExplore));
	//this.AddComponent(new JourneyTrainingComp(this, this.c.journeyTraining)); // needs updating to work with entitySequence->shortText change
	this.AddComponent(new JourneyVisualizationComp(this, this.c.journeyVisualization));
	this.AddComponent(new JourneyGridComp(this, this.c.journeyGrid));
	//this.AddComponent(new JourneyEntryComp(this, this.c.journeyEntry)); // needs updating to work with entitySequence->shortText change

	// non-singleton comp-types at end (just for cleaner ordering atm)
	// ==========

	const AddComponentForAlarm = (sequence: AlarmSequence, alarm: FBAConfig_XAlarm)=>{
		if (alarm.type == AlarmType.Sound) {
			this.AddComponent(new SoundAlarmComp(this, sequence, alarm));
		} else if (alarm.type == AlarmType.Countdown) {
			this.AddComponent(new CountdownAlarmComp(this, sequence, alarm));
		} else if (alarm.type == AlarmType.Shake) {
			this.AddComponent(new ShakeAlarmComp(this, sequence, alarm));
		} else if (alarm.type == AlarmType.Light) {
			this.AddComponent(new LightAlarmComp(this, sequence, alarm));
		} else if (alarm.type == AlarmType.Script) {
			this.AddComponent(new ScriptAlarmComp(this, sequence, alarm));
		}
	};
	
	if (this.c.eeg.enabled) {
		// Why don't we check this.c.eeg.alarmSequence.enabled here? Because it's supposed to always be enabled. (ui doesn't support disabling, since no point)
		this.c.eeg.alarmSequence.alarms.forEach(alarm=>AddComponentForAlarm(this.c.eeg.alarmSequence, alarm));
	}
	if (this.c.alarms.enabled) {
		for (const sequence of this.c.alarms.phaseAlarm_sequences.filter(a=>a.enabled)) {
			for (const alarm of sequence.alarms.filter(a=>a.enabled)) {
				AddComponentForAlarm(sequence, alarm);
			}
		}
	}
}

export function GetFBASessionPanelsToShow(journeyUI: boolean) {
	return [
		!journeyUI && EffectCountsPanel,
		General_ConfigUI,
		ModeSwitcher_ConfigUI,
		!journeyUI && Background_ConfigUI,
		!journeyUI && CameraPanel,
		!journeyUI && Gyro_ConfigUI,
		!journeyUI && EEG_ConfigUI,
		
		// alarms and solvers
		Alarms_ConfigUI,
		MicroSnooze_ConfigUI,
		!journeyUI && Memory_ConfigUI,
		DreamTranscribe_ConfigUI,

		!journeyUI && DreamNarration_ConfigUI,
		DreamRecall_ConfigUI,
		DreamQuiz_ConfigUI,
		ConceptLink_ConfigUI,
		RealityCheck_ConfigUI,
		StoryExplore_ConfigUI,
		JourneyTraining_ConfigUI,
		JourneyVisualization_ConfigUI,
		JourneyGrid_ConfigUI,
		JourneyEntry_ConfigUI,
	].filter(a=>a) as Array<new()=>BaseComponent>;
}