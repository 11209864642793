import {BaseComponent, SimpleShouldUpdate, BaseComponentPlus} from "react-vextensions";
import {VReactMarkdown_Remarkable, PageContainer, Observer, O, RunInAction} from "web-vcore";
import {StoreAccessor, GetDocs} from "mobx-firelink";
import {Row, Text, Button, CheckBox, Column} from "react-vcomponents";
import {ShowMessageBox} from "react-vmessagebox";
import semver, {ReleaseType} from "semver";
import {InDesktop, nativeBridge} from "../../Utils/Bridge/Bridge_Native.js";
import {runInAction, autorun, makeObservable} from "mobx";

function CompareVersionAToB(versionA: string, versionB: string) {
	/*const oldParts = versionA.split(".");
	const newParts = versionB.split(".");
	for (let i = 0; i < newParts.length; i++) {
	  const a = parseInt(newParts[i]) || 0;
	  const b = parseInt(oldParts[i]) || 0;
	  if (a > b) return 1;
	  if (a < b) return -1;
	}
	return 0;*/

	if (semver.gt(versionA, versionB)) return 1;
	if (semver.lt(versionA, versionB)) return -1;
	return 0;
}
function IsVersionAGreaterThanB(versionA: string, versionB: string) {
	return CompareVersionAToB(versionA, versionB) == 1;
}

interface VersionInfo {
	_key: string;
	active?: boolean;
	downloadURL?: string;
	notes?: string;
}
export const GetVersionInfos = StoreAccessor(s=>()=>{
	const versionInfos = GetDocs({inLinkRoot: false}, "versions/all/releases") as VersionInfo[];
	for (const info of versionInfos) {
		// temp; firebase console doesn't let you enter line-breaks through its UI, so convert "\n" into actual line-breaks
		if (info.notes) info.notes = info.notes.replace(/\\n/g, "\n");
	}
	// sort version-infos from the start (newest last)
	versionInfos.sort((a, b)=>CompareVersionAToB(a._key, b._key));
	return versionInfos;
});
export const GetNewestValidVersionInfo = StoreAccessor(s=>()=>{
	const versions = GetVersionInfos();
	//return versions[versions.length - 1];
	// ignore version-infos with no download-url (must still be adding/editing entry)
	return versions.filter(a=>a.active == true).slice(-1)[0];
});

// if in desktop-app, monitor releases-collection for new entries; if new version is added, tell desktop-app to check for updates (and thus notify of it)
if (InDesktop()) {
	let lastVersionInfos = [] as VersionInfo[];
	autorun(()=>{
		const newVersionInfos = GetVersionInfos();
		if (newVersionInfos.length && lastVersionInfos.length) {
			// ignore version-infos with no download-url (must still be adding/editing entry)
			const latestVersionWithDownloadURL = newVersionInfos.filter(a=>a.active == true).LastOrX();
			const oldLatestVersionWithDownloadURL = lastVersionInfos.filter(a=>a.active == true).LastOrX();
			if (latestVersionWithDownloadURL && oldLatestVersionWithDownloadURL && IsVersionAGreaterThanB(latestVersionWithDownloadURL._key, oldLatestVersionWithDownloadURL._key)) {
				nativeBridge.Call("CheckForUpdates");
			}
		}
		lastVersionInfos = newVersionInfos;
	});
}

//export let desktopApp_ourVersion: string;
class DesktopAppInfoHolder {
	constructor() { makeObservable(this); }
	@O ourVersion: string;
}
const desktopAppInfoHolder = new DesktopAppInfoHolder();
async function RequestDesktopAppVersion() {
	const ourVersion = await nativeBridge.Call("GetDesktopAppVersion") as string;
	RunInAction("RequestDesktopAppVersion", ()=>desktopAppInfoHolder.ourVersion = ourVersion);
}
export function GetDesktopApp_OurVersion() {
	RequestDesktopAppVersion(); // this starts retrieval
	return desktopAppInfoHolder.ourVersion; // this subscribes caller to the results
}

@Observer
export class DownloadUI extends BaseComponentPlus({}, {showOldVersions: false}) {
	render() {
		const {showOldVersions} = this.state;

		const versions = GetVersionInfos();
		const newestValidVersionInfo = GetNewestValidVersionInfo();
		const otherVersions_valid = versions.Exclude(newestValidVersionInfo).filter(a=>a.active == true).Reversed();
		const versionsToShow = [newestValidVersionInfo].filter(a=>a).concat(showOldVersions ? otherVersions_valid : []); // reverse, so newest are first
		return (
			<PageContainer scrollable={true}>
				<Row style={{fontSize: 18, fontWeight: "bold"}}>Desktop app</Row>
				<Row mt={5}>The Lucid Frontier desktop app provides additional functionality, particularly for dream-engine inputs and effects.</Row>
				{InDesktop() &&
				<Row mt={10}>It appears that you are in the desktop app now (v{GetDesktopApp_OurVersion()}). App will notify of available updates at launch.</Row>}
				<Row mt={10}>
					<Text>Versions: (</Text>
					<CheckBox text="Show old versions" value={showOldVersions} onChange={val=>this.SetState({showOldVersions: val})}/>
					<Text>)</Text>
				</Row>
				{versionsToShow.map((info, index)=>{
					return (
						<Column key={index} mt={5} p="1px 5px" style={{background: "rgba(255,255,255,.2)", borderRadius: 3}}>
							<Row center>
								<Text style={{whiteSpace: "pre", fontSize: 18}}>{info._key} (</Text>
								<a href={info.downloadURL}>download</a>
								<Text>)</Text>
								{/*<Button ml={10} text="Show notes" enabled={info.notes != null} onClick={()=>{
									ShowMessageBox({
										title: `Version ${info._key} notes`,
										message: info.notes,
									});
								}}/>*/}
							</Row>
							{info.notes &&
							<>
								<Row mt={5}>Notes:</Row>
								<div style={{marginTop: 3, background: "rgba(255,255,255,.7)", width: "100%", height: 2}}/>
								<Text mt={3} style={{whiteSpace: "pre-wrap"}}>{info.notes}</Text>
							</>}
						</Column>
					);
				})}
			</PageContainer>
		);
	}
}