import React from "react";
import {Button, Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Observer, RunInAction} from "web-vcore";
import {store} from "../../Store/index.js";
import {JourneyTab} from "../../Store/main/tools/journey.js";
import {colors} from "../../Utils/UI/GlobalStyles";
import {zIndexes} from "../../Utils/UI/ZIndexes";

//const navBar_android_height = 50;

@Observer
export class NavBar_Android extends BaseComponent<{}, {}> {
	render() {
		return (
			<nav style={{
				position: "relative", zIndex: zIndexes.navBar,
				boxShadow: colors.navBarBoxShadow,
				background: "rgba(0,0,0,1)",
			}}>
				<Row center style={{height: 50, justifyContent: "space-around"}}>
					<NavBar_Android_Button page="home" subpage="home" text="Home"/>
					<NavBar_Android_Button page="journal" text="Journal"/>
					<NavBar_Android_Button page="tools" subpage="journey" journeyTab={JourneyTab.config} text="Config"/>
					<NavBar_Android_Button page="tools" subpage="journey" journeyTab={JourneyTab.engine} text="Engine"/>
					<NavBar_Android_Button page="tools" subpage="journey" journeyTab={JourneyTab.stats} text="Stats"/>
					<NavBar_Android_Button page="settings" text="Settings"/>
				</Row>
			</nav>
		);
	}
}

class NavBar_Android_Button extends BaseComponent<{page: string, subpage?: string, journeyTab?: JourneyTab, text: string}, {}> {
	render() {
		let {page, subpage, journeyTab, text} = this.props;
		return (
			<Button text={text} style={{width: 50, height: 50}} onClick={()=>{
				RunInAction("NavBar_Android_Button.onClick", ()=>{
					store.main.page = page;
					if (subpage) store.main[page].subpage = subpage;
					if (journeyTab) store.main.tools.journey.tab = journeyTab;
				});
			}}/>
		);
	}
}