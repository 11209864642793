import {Vector2, VRect} from "js-vextensions";

export function CopyImageData(context: CanvasRenderingContext2D, sourceImageData: ImageData) {
	const imageDataCopy = context.createImageData(sourceImageData.width, sourceImageData.height);
	imageDataCopy.data.set(sourceImageData.data);
	return imageDataCopy;
}

export function FitSourceSizeIntoContainerSize(sourceSize: Vector2, containerSize: Vector2, allowScaleUp = false) {
	const scalesToFit = new Vector2(containerSize.x / sourceSize.x, containerSize.y / sourceSize.y);
	let scaleToFit = Math.min(scalesToFit.x, scalesToFit.y);
	if (!allowScaleUp) {
		scaleToFit = scaleToFit.KeepAtMost(1);
	}
	const videoSize_rescaledToFit = sourceSize.Times(scaleToFit);

	const gapArea = containerSize.Minus(videoSize_rescaledToFit);
	return new VRect(gapArea.x / 2, gapArea.y / 2, videoSize_rescaledToFit.x, videoSize_rescaledToFit.y);
}
/*export function DrawImage_Fit(context: CanvasRenderingContext2D, imageOrVideo: HTMLImageElement|HTMLVideoElement, targetRect: VRect, allowScaleUp = false) {
	const imageOrVideoSize = imageOrVideo instanceof HTMLImageElement
		? new Vector2(imageOrVideo.width, imageOrVideo.height)
		: new Vector2(imageOrVideo.videoWidth, imageOrVideo.videoHeight);
	const drawRect = FitSourceSizeIntoContainerSize(imageOrVideoSize, targetRect.Size, allowScaleUp).NewPosition(pos=>pos.Plus(targetRect.Position));
	context.drawImage(imageOrVideo,
		0, 0, imageOrVideoSize.x, imageOrVideoSize.y,
		drawRect.x, drawRect.y, drawRect.width, drawRect.height);
}*/