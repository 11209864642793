import {AddSchema, AssertValidate, Command, GetSchemaJSON, NewSchema} from "mobx-firelink";
import {GetStoryMessage, StoryMessage} from "../../Store/firebase/storyMessages.js";

type MainType = StoryMessage;
const MTName = "StoryMessage";

AddSchema(`Update${MTName}_payload`, [MTName], ()=>({
	properties: {
		id: {type: "string"},
		updates: NewSchema({
			properties: GetSchemaJSON(MTName).properties!.IncludeKeys("parent", "role", "content", "summary_chainEnd", "summary_enabled"),
		}),
	},
	required: ["id", "updates"],
}));

export class UpdateStoryMessage extends Command<{id: string, updates: Partial<MainType>}> {
	oldData: MainType;
	newData: MainType;
	Validate() {
		AssertValidate(`Update${MTName}_payload`, this.payload, `Payload invalid`);

		const {id, updates} = this.payload;
		this.oldData = GetStoryMessage.NN(id);
		this.newData = {...this.oldData, ...updates} as MainType;
		AssertValidate(MTName, this.newData, `New ${MTName.toLowerCase()}-data invalid`);
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`storyMessages/${id}`] = this.newData;
		return updates;
	}
}