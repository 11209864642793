import {Button, Column, Row, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponentWithConnector, BaseComponentPlus, BaseComponent} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {GetSounds} from "../../Store/firebase/sounds";
import {MeID} from "../../Store/firebase/users";
import {GetYoutubePlayersToKeepBuffered} from "../../Store/main/effects/sounds";
import {InfoButton, PageContainer, Observer} from "web-vcore";
import {ES} from "../../Utils/UI/GlobalStyles";
import {GetEntities} from "../../Store/firebase/entities";
import {ShowSignInPopup} from "../@Shared/NavBar/UserPanel";
import {ShowAddEntityDialog} from "./Entities/EntityDetailsUI";
import {TagTargetEntryUI} from "../@Shared/TagTargetEntryUI.js";
import {useState} from "react";

export const columnWidths = [.25, .1, .1, .3, .25];

@Observer
export class EntitiesUI extends BaseComponent<{}, {}> {
	render() {
		const entities_raw = GetEntities().filter(a=>a && a.creator == MeID());
		const userID = MeID();

		const [filter, setFilter] = useState("");
		const entities = entities_raw.filter(entity=>{
			if (entity.name.toLowerCase().includes(filter.toLowerCase())) return true;
			if (entity.tags.Any(a=>a.toLowerCase().includes(filter.toLowerCase()))) return true;
			return false;
		});

		return (
			<PageContainer style={{margin: "20px auto 20px auto", padding: 0}}>
				<Column className="clickThrough" style={{height: 80, borderRadius: "10px 10px 0 0"}}>
					<Row center style={{height: 40, padding: 10}}>
						<Text>Filter:</Text>
						<TextInput ml={5} style={{width: 200}} instant value={filter} onChange={val=>setFilter(val)}/>
						<Button text="Add entity" ml="auto" onClick={()=>{
							if (MeID() == null) return ShowSignInPopup();
							ShowAddEntityDialog();
						}} />
					</Row>
					<Row style={{height: 40, padding: 10}}>
						<Text style={{flex: columnWidths[0], fontWeight: 500, fontSize: 16}}>Name</Text>
						<Text style={{flex: columnWidths[1], fontWeight: 500, fontSize: 16}}>Preview</Text>
						<Text style={{flex: columnWidths[2], fontWeight: 500, fontSize: 16}}>Creator</Text>
						<Text style={{flex: columnWidths[3], fontWeight: 500, fontSize: 16}}><Text>Tags (click tags to </Text><span style={{color: "rgba(100,200,100,.7)"}}>accept</span>)</Text>
						<Text style={{flex: columnWidths[4], fontWeight: 500, fontSize: 16}}></Text>
					</Row>
				</Column>
				<ScrollView style={ES({flex: 1})} contentStyle={ES({flex: 1})}>
					{entities.length == 0 && <div style={{textAlign: "center", fontSize: 18}}>Loading...</div>}
					{entities.map((entity, index)=>{
						return <TagTargetEntryUI key={index} index={index} last={index == entities.length - 1} group="entities" entry={entity} />;
					})}
				</ScrollView>
			</PageContainer>
		);
	}
}