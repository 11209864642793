export const ALL_GROUP_NAME = "All";
export class MetricCollector<SampleValType> {
	samplesForEachXValue = new Map<number, Array<Sample<SampleValType>>>();
	AddSample(xValue: number, val: SampleValType, group: string) {
		if (!this.samplesForEachXValue.has(xValue)) this.samplesForEachXValue.set(xValue, []);
		this.samplesForEachXValue.get(xValue)!.push(new Sample({value: val, group}));
	}
	GetSampleValues(xValue: number, group: string) {
		let matchingSamples = this.samplesForEachXValue.get(xValue) ?? [];
		if (group && group != ALL_GROUP_NAME) matchingSamples = matchingSamples.filter(a=>a.group == group);
		return matchingSamples.map(a=>a.value);
	}
}
export class Sample<SampleValType> {
	constructor(data?: Partial<Sample<SampleValType>>) {
		Object.assign(this, data);
	}
	value: SampleValType;
	group: string;
}