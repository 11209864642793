import {AssertV, Command} from "mobx-firelink";
import {GetStoryMessage, GetStoryMessages} from "../../Store/firebase/storyMessages.js";
import {IsUserCreatorOrMod} from "../../Store/firebase/users/$user.js";

export class DeleteStoryMessage extends Command<{id: string}> {
	//sub_updateStory: UpdateStory|n;
	Validate() {
		const {id} = this.payload;
		const oldData = GetStoryMessage.NN(id);
		AssertV(IsUserCreatorOrMod(this.userInfo?.id, oldData), "Insufficient permissions.");

		const childMessages = GetStoryMessages(this.userInfo!.id, oldData.story, id);
		AssertV(childMessages.length == 0, "Cannot delete story message while it still has child messages.");

		/*if (oldData.parent == null) {
			const story = GetStory.NN(oldData.story);
			this.sub_updateStory = new UpdateStory({id: story._key, updates: {
				startMessageIds: story.startMessageIds.Exclude(id),
			}}).MarkAsSubcommand(this);
			this.sub_updateStory.Validate();
		}*/
	}

	GetDBUpdates() {
		const {id} = this.payload;
		const updates = {};
		updates[`storyMessages/${id}`] = null;
		//updates = MergeDBUpdates(updates, this.sub_updateStory.GetDBUpdates());
		return updates;
	}
}