import {Clone, GetEntries, GetErrorMessagesUnderElement, CloneWithPrototypes, E} from "js-vextensions";
import Moment from "moment";
import {Column, Pre, RowLR, Select, TextArea, TextInput, Text, Row, CheckBox, TimeSpanInput} from "react-vcomponents";
import {BaseComponent, BaseComponentWithConnector, BaseComponentPlus} from "react-vextensions";
import {KasaBulb_DefaultEffectJSONFunctionStr, kasa_hueForPureWhiteAt0Sat, Light, LightType} from "../../../Store/firebase/lights/@Light";
import {name_general} from "../../../Utils/General/SharedPatterns";
import {IDAndCreationInfoUI} from "../../../UI/@Shared/CommonPropUIs/IDAndCreationInfoUI";
import {AddLight} from "../../../Server/Commands/AddLight";
import {BoxController, ShowMessageBox} from "react-vmessagebox";
import {observer_simple, InfoButton, TextPlus} from "web-vcore";
import {EffectVisibility} from "../../../Store/firebase/sounds/@Sound";
import {MeID} from "../../../Store/firebase/users";
import {EffectPreviewButton} from "../../@Shared/TagTargetEntryUI";
import {DialogStyle} from "../../../Utils/UI/GlobalStyles.js";

export function ShowAddLightDialog(initialData?: Partial<Light>, postAdd?: (id: string)=>any) {
	let newEntry = new Light(E({
		creator: MeID(), // unnecessary, since set by server (only set here so PlayLightConfig() knows we're the creator, and allows error-notifications)
		name: "",
		type: LightType.KasaBulb,
	}, initialData));
	const getCommand = ()=>new AddLight({light: newEntry});

	const boxController: BoxController = ShowMessageBox({
		title: "Add light", cancelButton: true,
		message: observer_simple(()=>{
			const tempCommand = getCommand();
			boxController.UpdateOptions({okButtonProps: {
				enabled: tempCommand.Validate_Safe() == null,
				title: tempCommand.validateError,
			}});

			return (
				<Column style={DialogStyle({width: 600})}>
					<LightDetailsUI baseData={newEntry} forNew={true}
						onChange={(val, error)=>{
							newEntry = val;
							boxController.UpdateUI();
						}}/>
				</Column>
			);
		}),
		onOK: async()=>{
			const id = await getCommand().Run();
			if (postAdd) postAdd(id);
		},
	});
}

type Props = {baseData: Light, forNew: boolean, enabled?: boolean, style?, onChange?: (newData: Light, ui: LightDetailsUI)=>void};
type Props_Enhanced = Props & State & {Change};
type State = {newData: Light};

const splitAt = 120, width = "100%";

export class LightDetailsUI extends BaseComponentPlus({enabled: true} as Props, {} as State) {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.baseData != this.props.baseData) { // if base-data changed
			const newData = CloneWithPrototypes(props.baseData);
			//Object.setPrototypeOf(newData, Light.prototype); // MS newData's class is "Light", so we can use Light's class methods directly
			//Object.setPrototypeOf(newData, Object.getPrototypeOf(new Light({}))); // MS newData's class is "Light", so we can use Light's class methods directly
			this.SetState({newData});
		}
	}

	render() {
		const {baseData, forNew, enabled, style, onChange} = this.props;
		const {newData} = this.state;
		const Change = (..._)=>{
			if (onChange) onChange(this.GetNewData(), this);
			this.Update();
		};

		const propsEnhanced = {...this.props, Change, ...this.state, SetState: this.SetState};

		return (
			<Column style={style}>
				{!forNew &&
					<IDAndCreationInfoUI id={baseData._key} creatorID={newData.creator} createdAt={newData.createdAt}/>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Name: </Text>
					<TextInput pattern={name_general} required
						enabled={enabled} style={{width: "100%"}}
						value={newData.name} onChange={val=>Change(newData.name = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Type: </Text>
					<Select options={GetEntries(LightType)} enabled={enabled} style={{width: "100%"}}
						value={newData.type} onChange={val=>Change(newData.type = val, Light.InitDefaults(newData))}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt}>
					<Row>
						<CheckBox text="Temp" enabled={enabled} value={newData.temp} onChange={val=>Change(newData.temp = val)}/>
						<Text>, length:</Text>
					</Row>
					<TimeSpanInput enabled={enabled && newData.temp} style={{width: "100%"}}
						value={newData.tempLength} onChange={val=>Change(newData.tempLength = val)}/>
				</RowLR>
				{newData.type.IsOneOf(LightType.Screen, LightType.LIFXBulb, LightType.KasaBulb) &&
				<RowLR mt={5} splitAt={splitAt}>
					<Row center>
						<Text>Effect details:</Text>
						<InfoButton ml={5} sel text={
							newData.type == LightType.Screen ? `
								Example
								==========
								return {
									screenBrightness: .5 + (brightness * .5), // default: null (no change)
									frameInterval: 1/60, // default: 1
									framesLoop: true, // default: true
									frames: [
										{color: "black"},
										{color: "rgba(0,255,0,.5)", repeat_from: -1, repeat_count: 100}, // repeats black+green frames/block 100 times
									],
								};
							`.AsMultiline(0) :
							newData.type == LightType.LIFXBulb ? `
								For return-value shape, see: https://api.developer.lifx.com
							`.AsMultiline(0) :
							`Example\n==========\n${KasaBulb_DefaultEffectJSONFunctionStr}`
						}/>
					</Row>
					<TextArea autoSize={true} enabled={enabled} style={{width: "100%"}}
						value={newData.effectJSON_functionStr} onChange={val=>Change(newData.effectJSON_functionStr = val)}/>
				</RowLR>}
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Row center>
						<Pre>Visibility:</Pre>
						<InfoButton ml={5} text={`
							Visible: Shown publicly in the list of lights.
							Hidden: Hidden in light list (other than to mods), but still accessible through reading of raw db contents. (so not guarantee of privacy)
						`.AsMultiline(0)}/>
					</Row>
					<Select options={GetEntries(EffectVisibility)} enabled={enabled} value={newData.visibility} onChange={val=>Change(newData.visibility = val)}/>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt} style={{width}}>
					<Text>Suggested tags:</Text>
					<TextInput enabled={enabled} style={{width: "100%"}}
						value={(newData.tags || []).join(", ")} onChange={val=>Change(newData.tags = val.split(",").map(a=>a.trim()).filter(a=>a))}/>
				</RowLR>
				<EffectPreviewButton mt={5} group="lights" entry={newData} startText="Preview light" stopText="Stop preview" width={200}/>
			</Column>
		);
	}
	GetValidationError() {
		return GetErrorMessagesUnderElement(this.DOM)[0];
	}

	GetNewData() {
		const {newData} = this.state;
		return CloneWithPrototypes(newData) as Light;
	}
}